import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    BASKET_LEAD_CAPTURE_FORM_ID,
    BRAND,
    isBrandAC,
    isBrandAP,
    isBrandDS,
    isBrandOV,
    LEAD_FORM_ID,
    ModalVersion,
} from '../../constants/main';
import { SC, theme } from '../../styles/theme';
import formService from '../../services/digital/form.service';
import Spinner from '../Spinner';
import { captureException } from '@utils/sentry.utils';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { FEATURES_LIST, FeatureSwitchContext } from '../../context/featureSwitchApp';
import { UserService } from '../../services';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useGTM } from '@hooks/useGTM';
import { GtmData } from '../../types/gtm';
import { useWidgetCompatibility } from '@hooks/useWidgetCompatibility';
import { JQueryDependency } from '@utils/externalDependencies/dependencies';
import AssetManager from '@utils/externalDependencies/AssetManager';
import { IConfigurableCar } from '../../interfaces/Car';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';
import { usePlausible, UserEvent } from '@hooks/usePlausible';

export interface IDigitalFormProps extends SC {
    formId: number | string;
    formData?: { [key: string]: number | string };
    currentCarDetails: IConfigurableCar;
}

const AGREEMENT_IDS = ['legal_agreement1', 'legal_agreement2', 'legal_agreement3'];

//@ts-ignore
const brandTemplate = { AP: 'peugeot', AC: 'citroen', DS: 'ds', OV: 'vauxhall' }[BRAND];

let loadAssets = 0;
const defaultForm = '';
const lang = 'en-gb';
const model = '';

const DigitalForm: React.FC<IDigitalFormProps> = ({
    formId,
    formData = {},
    className,
    currentCarDetails,
    ...props
}) => {
    const containerRef = useRef<HTMLDivElement>();
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenAgreementDescription, setIsOpenAgreementDescription] = useState(false);
    const [openSections, setOpenSections] = useState([]);
    const context = useContext(FeatureSwitchContext);
    const { plausibleUserEvent } = usePlausible();

    const { saveWindowProperty, restoreWindowProperty } = useWidgetCompatibility();

    const isAllowedFormLeadsToCRMStock = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_STOCK
    );

    const isAllowedFormLeadsToCRMMTO = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_MTO
    );
    const isCollectConsentEnabledMTO = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ENABLE_COLLECT_CONSENT_FORM_LEADS_TO_CRM_MTO
    );
    const isCollectConsentEnabledStock = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ENABLE_COLLECT_CONSENT_FORM_LEADS_TO_CRM_STOCK
    );

    const legalConsentVersion = getFeatureSwitch(FEATURES_LIST.FEATURE_SWITCH_LEGAL_CONSENT_VERSION, context);

    const { isStockJourney } = useCarStockJourneyCheck();
    const { pushToDataLayer, getBodyStyleLabel, pageGTMTitle } = useGTM();

    const sendDataToCRM = (crmData: any) => {
        UserService.sendDataToCRM(getSessionIdCookie(), crmData).catch((err) => {
            console.log(err.message || 'Problem with CRM udpate');
        });
    };

    const getFormCheckedCheckboxesTexts = () => {
        const checkBoxes = document.getElementsByClassName('checkbox-field') as unknown as NodeListOf<HTMLInputElement>;
        const checkboxes = [];
        for (let i = 0; i < checkBoxes.length; i++) {
            if (checkBoxes[i].checked) {
                checkboxes.push(checkBoxes[i].value);
            }
        }

        return checkboxes.length ? checkboxes.join(';') : '';
    };

    const getFormElementValue = (selector: string, isId?: boolean) => {
        const getSingleCheckboxValue = (): boolean => {
            const checkboxes = document.getElementsByName(selector) as NodeListOf<HTMLInputElement>;

            return checkboxes.length === 1 && checkboxes[0].checked;
        };
        const getAgreementValue = (selectorName: string = ''): boolean | null => {
            const agreementRadioButtons = document.getElementsByName(selectorName) as NodeListOf<HTMLInputElement>;
            if (agreementRadioButtons[0]?.checked) {
                return true;
            }

            // Check if the second checkbox is selected
            if (agreementRadioButtons[1]?.checked) {
                return false;
            }
            return null;
        };

        switch (selector) {
            case 'comment':
                return (
                    document
                        .getElementsByClassName(selector)?.[0]
                        ?.getElementsByTagName('textarea')?.[0] as HTMLTextAreaElement
                )?.value;
            case 'civility':
                const selectField = document.querySelector('select[name="civility"]') as HTMLInputElement;
                const inputField = document.querySelector('input[name="civility"]:checked') as HTMLInputElement;

                if (selectField) {
                    return selectField.value || '';
                } else if (inputField) {
                    return inputField.value || '';
                }
                return '';
            case 'preferredContactTypeEmail[]':
            case 'preferredContactTypeSMS[]':
            case 'preferredContactTypePhone[]':
                return getSingleCheckboxValue();
            case 'agreement1':
            case 'agreement2':
            case 'agreement3':
                return getAgreementValue(selector);
            default:
                return isId
                    ? document.getElementById(selector)?.getElementsByTagName('input')?.[0]?.value
                    : document.getElementsByClassName(selector)?.[0]?.getElementsByTagName('input')?.[0]?.value;
        }
    };

    const collectDataForCRM: any = (formData: any) => {
        const { lcdv16, type } = formData;

        const data = {
            salutation: getFormElementValue('civility'),
            firstName: getFormElementValue('firstName', true),
            lastName: getFormElementValue('lastName', true),
            mobilePersonalTelephone: getFormElementValue('phone', true),
            postalCode: getFormElementValue('postCode', true),
            email: getFormElementValue('emailCust'),
            customerComments: getFormElementValue('comment'),
            lcdvInterest: lcdv16,
            ...(legalConsentVersion === ModalVersion.v2 && {
                checkBox1Meaning: 'MkgFCA',
                checkBox1Value: getFormElementValue('agreement1'),
                checkBox2Meaning: 'ProfFCA',
                checkBox2Value: getFormElementValue('agreement2'),
                checkBox3Meaning: 'MkgThirdPartyFCA',
                checkBox3Value: getFormElementValue('agreement3'),
            }),
            ...((!legalConsentVersion || legalConsentVersion != ModalVersion.v2) && {
                checkBox1Meaning: 'MkgBrandMail',
                checkBox1Value: !!getFormElementValue('preferredContactTypeEmail[]'),
                checkBox2Meaning: 'MkgBrandSMS',
                checkBox2Value: !!getFormElementValue('preferredContactTypeSMS[]'),
                checkBox3Meaning: 'MkgBrandPhone',
                checkBox3Value: !!getFormElementValue('preferredContactTypePhone[]'),
            }),
            checkedCheckboxesTexts: getFormCheckedCheckboxesTexts(),
        };
        //custom form data validation
        let noError = true;
        for (const [key, value] of Object.entries(data)) {
            // validate only text inputs that are present - their value is gonna be a string not undefined/null
            if (typeof value !== 'string') continue;

            if (
                !value.length &&
                ![
                    'customerComments',
                    'checkedCheckboxesTexts',
                    type === 'LEAD_COLLECT_CONSENT_FORM' && 'mobilePersonalTelephone',
                ].includes(key)
            ) {
                noError = false;
            }
        }

        if (document.getElementsByClassName('emailErr').length) noError = false;

        return noError ? data : noError;
    };

    function hookSubmit(btnTxt: string) {
        // WARNING!!! Collecting the CRM data needs to be here, sometimes hapens that after form sending the form inputs values cannot be obtained
        const crmData = collectDataForCRM(formData);
        plausibleUserEvent(UserEvent.SubmitLeadFormAfterShareBasket);
        formService
            .sendForm({ dealId: formData.dealId })
            .then((res) => {
                if (
                    (isStockJourney && !isAllowedFormLeadsToCRMStock && formId === LEAD_FORM_ID) ||
                    (!isStockJourney && !isAllowedFormLeadsToCRMMTO && formId === LEAD_FORM_ID) ||
                    (isStockJourney && !isCollectConsentEnabledStock && formId === BASKET_LEAD_CAPTURE_FORM_ID) ||
                    (!isStockJourney && !isCollectConsentEnabledMTO && formId === BASKET_LEAD_CAPTURE_FORM_ID)
                )
                    return;
                const { status } = res;

                if (status === 200) {
                    if (crmData) {
                        const { type, mopId } = formData;
                        sendDataToCRM({ ...crmData, type, mopId });
                    } else {
                        console.error('There is still validation error so CRM data is not sent.');
                    }
                }
            })
            .catch((e) => {
                console.error('Siebel update was not correct.', e);
            });
    }

    useEffect(() => {
        const IS_OPEN_CLASS_NAME = 'isOpen';
        const IS_CLOSE_CLASS_NAME = 'isClose';

        if (!isLoading) {
            AGREEMENT_IDS.map((id: string) => {
                const agreementDescription = document.getElementById(`${id}_description`);
                const agreementTitle = document.getElementById(`${id}_title`);

                if (agreementTitle) {
                    agreementDescription.style.display = 'none';
                    agreementTitle.classList.add(IS_CLOSE_CLASS_NAME);

                    agreementTitle.addEventListener('click', () => {
                        agreementDescription.style.display =
                            agreementDescription.style.display === 'none' ? 'block' : 'none';
                        if (agreementDescription.style.display === 'none') {
                            agreementTitle.classList.add(IS_CLOSE_CLASS_NAME);
                            agreementTitle.classList.remove(IS_OPEN_CLASS_NAME);
                        } else {
                            agreementTitle.classList.add(IS_OPEN_CLASS_NAME);
                            agreementTitle.classList.remove(IS_CLOSE_CLASS_NAME);
                        }
                    });

                    if (isBrandAC || isBrandAP || isBrandDS) {
                        agreementTitle.innerHTML +=
                            '<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                            '<path fill-rule="evenodd" clip-rule="evenodd" d="M0.585266 11.5841L5.1713 6.99807L0.585266 2.41205L1.99927 0.998047L7.9993 6.99807L1.99927 12.9981L0.585266 11.5841Z" fill="white"/>\n' +
                            '</svg>';
                    }

                    if (isBrandOV) {
                        agreementTitle.innerHTML +=
                            '<svg class="isOpen" width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 3H14V1H1V3Z" fill="white"/></svg>';
                        agreementTitle.innerHTML +=
                            "<svg class='isClose' width='100%' height='100%' viewBox='0 0 17 17' fill='#da291c' version='1.1' xmlns='http://www.w3.org/2000/svg'><g transform='matrix(1,0,0,1,-7.5,-7.5)'><path d='M24.48,16.96L16.96,16.96L16.96,24.48L15.04,24.48L15.04,16.96L7.52,16.96L7.52,15.04L15.04,15.04L15.04,7.52L16.96,7.52L16.96,15.04L24.48,15.04L24.48,16.96Z'/></g></svg>";
                    }
                }
            });
        }
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(true);
        const params = {
            form_id: formId,
            model,
            template: brandTemplate,
            default_form: defaultForm,
            lang,
            loadAssets,
            ...formData,
        };
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                (containerRef.current.querySelector('.submit-button') as HTMLButtonElement).click();
            }
        };
        Promise.all([AssetManager.instance.addScripts([JQueryDependency]), formService.getForm(params)])
            .then(([_, res]) => {
                const { data } = res;
                if (data && data.html) {
                    const { email } = formData;
                    saveWindowProperty('jQuery');
                    restoreWindowProperty('$');

                    const html: string = data.html.replace(/<link.*>/g, '').replace(/<style.*>[\s\S]*<\/style>/g, '');
                    const container = containerRef.current;

                    (window as any).$(container).html(html);

                    const submitButton = container.querySelector('.submit-button');
                    const formField = container.querySelectorAll('.form-field');

                    // In BO form builder there can just info box set instead of form
                    if (submitButton) {
                        container.querySelector('.submit-button').setAttribute('href', '#');
                        container.addEventListener('keydown', handleKeyDown);
                        submitButton.addEventListener('click', () => hookSubmit(submitButton.textContent));
                    }

                    if (Boolean(formField.length)) {
                        for (let i = 0; i <= formField.length; i++) {
                            if (typeof formField[i] === 'undefined') continue;
                            const input = formField[i].querySelector('input');
                            const textarea = formField[i].querySelector('textarea');
                            const h4 = formField[i].querySelector('h4');
                            const emailField = formField[i].querySelector("[name='emailCust']");

                            if (emailField && email) {
                                (emailField as HTMLInputElement).value = email as string;
                            }
                            const eventLabel = h4?.innerText || input?.innerText || input?.value;

                            if (textarea) {
                                textarea.addEventListener('blur', () => {
                                    pushToDataLayer({
                                        eventCategory: `SolRetail::${pageGTMTitle}`,
                                        eventAction: `Select::Field::CommercialOffer::${getBodyStyleLabel(
                                            currentCarDetails
                                        )}`,
                                        eventLabel,
                                    } as GtmData);
                                });
                            }
                            if (input && input.type !== 'hidden') {
                                input.addEventListener(
                                    ['checkbox', 'radio'].includes(input.type) ? 'click' : 'blur',
                                    () => {
                                        pushToDataLayer({
                                            eventCategory: `SolRetail::${pageGTMTitle}`,
                                            eventAction: `Select::Field::CommercialOffer::${getBodyStyleLabel(
                                                currentCarDetails
                                            )}`,
                                            eventLabel,
                                        } as GtmData);
                                    }
                                );
                            }
                        }
                    }

                    setIsLoading(false);
                } else {
                    console.error('Digital form data empty', res);
                }
            })
            .catch((e) => {
                captureException(e);
                console.error("Couldn't load digital form", e);
            });
        loadAssets++;

        return () => {
            containerRef.current.removeEventListener('keydown', handleKeyDown);
        };
    }, [formId, ...Object.values(formData)]);
    return (
        <React.Fragment>
            {isLoading ? <Spinner size={20} border={3} color={theme.colors.black} /> : null}
            <div
                ref={containerRef}
                className={`ap-forms ${className}`}
                data-form-id={formId}
                data-model={model}
                data-template={brandTemplate}
                data-lang={lang}
                data-default-form={defaultForm}
                {...props}
            />
        </React.Fragment>
    );
};

export default DigitalForm;
