import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import FinanceWidgetDuck from '../../../redux/financeWidget/financeWidget.duck';
import { IReduxFinanceQuote } from '../../../redux/financeWidget/financeWidget.duck.interface';
import { NETWORK_STATUS_START, NETWORK_STATUS_ERROR, Redux } from '../../../redux/redux.interface';
import Spinner from '../../Spinner';
import { CheckoutFinanceWidget } from '../../CheckoutFinanceWidget';
import { theme } from '../../../styles/theme';

export interface IFinanceSummary {
    id: string;
    actionFetchFinanceQuoteToList: (id: string) => void;
    financeQuoteList: IReduxFinanceQuote[];
}

const FinanceSummary: FC<IFinanceSummary> = ({
    id,
    actionFetchFinanceQuoteToList,
    financeQuoteList,
}: IFinanceSummary) => {
    const reduxFinanceQuote = financeQuoteList.find((financeQuote: IReduxFinanceQuote) => financeQuote.id === id);

    useEffect(() => {
        if (!reduxFinanceQuote) {
            actionFetchFinanceQuoteToList(id);
        }
    }, [actionFetchFinanceQuoteToList, id, reduxFinanceQuote]);

    if (!reduxFinanceQuote || reduxFinanceQuote.status === NETWORK_STATUS_START) {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner size={50} border={5} color={theme.colors.grey10} />
                </div>
            </div>
        );
    }

    if (reduxFinanceQuote.status === NETWORK_STATUS_ERROR) {
        return <div>FAILED TO LOAD FINANCE QUOTE</div>;
    }

    return <CheckoutFinanceWidget financialSummaryData={reduxFinanceQuote.financeQuote} />;
};

const mapStateToProps = (state: Redux) => ({
    financeQuoteList: FinanceWidgetDuck.getOwnState(state).financeQuoteList,
});

const mapDispatchToProps = { actionFetchFinanceQuoteToList: FinanceWidgetDuck.actionFetchFinanceQuoteToList };

export default connect(mapStateToProps, mapDispatchToProps)(FinanceSummary);
