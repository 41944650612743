import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalNeedHelpStyledAC } from './AC/ModalNeedHelpStyled.AC';
import { ModalNeedHelpStyledAP } from './AP/ModalNeedHelpStyled.AP';
import { ModalNeedHelpStyledDS } from './DS/ModalNeedHelpStyled.DS';
import { ModalNeedHelpStyledOV } from './OV/ModalNeedHelpStyled.OV';
import { ModalNeedHelpStyledOV as ModalNeedHelpStyledOVGB } from './OV/GB/ModalNeedHelpStyled.OV';

export interface IModalNeedHelp extends SC {
    onClose: () => void;
}

export const ModalNeedHelp = (props: IModalNeedHelp) => {
    if (BRAND === 'AP') return <ModalNeedHelpStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalNeedHelpStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalNeedHelpStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalNeedHelpStyledOVGB {...props} /> : <ModalNeedHelpStyledOV {...props} />;
};
