import { rem } from 'polished';
import styled from 'styled-components';
import { GlobalTooltipTemplate } from './GlobalTooltipTemplate';

export const GlobalTooltipStyled = styled(GlobalTooltipTemplate)`
    .tooltip-box {
        background: ${({ theme }) => theme.colors.blue6};
        border-radius: ${rem(8)};
        color: ${({ theme }) => theme.colors.white};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        line-height: ${rem(20)};
        align-items: center;
        padding: ${rem(11)} ${rem(16)};
        opacity: 1 !important;
        max-width: ${rem(280)};
        z-index: 9999999999 !important;
        text-align: center;
        font-weight: normal;

        &::after {
            width: ${rem(12)};
            height: ${rem(12)};
            background-color: ${({ theme }) => theme.colors.blue6};

            right: ${rem(24)};
            top: ${rem(-6)};
            transform: rotate(-45deg);
        }

        &.place-top {
            margin-top: ${rem(-10)} !important;
            margin-left: ${rem(2)} !important;
            &::after {
                top: calc(100% - 6px) !important;
                bottom: auto !important;
                border: 0 !important;
            }
        }
    }
`;
