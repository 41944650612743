import styled from 'styled-components';
import { SelectStyled } from '../../SelectStyled';

export const SelectStyledOVGB = styled(SelectStyled)`
    .Select__option--is-focused {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
    }

    .Select__option:active {
        background-color: ${props => props.theme.colors.primary};
    }

    .Select__option--is-selected {
        background-color: ${props => props.theme.colors.black};
        color: ${props => props.theme.colors.white};
    }
`;
