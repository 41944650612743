import React from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalSave } from '../shared/types';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { useSelector } from 'react-redux';
import { SaveToAccountButton } from 'src/components/SaveToCTA/SaveToAccountButton';
import { useFeatureSwitch } from '@hooks/useFeatureSwitch';
import { Form } from '@components/ShareConfigurationBox/Form';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { isBrandDS } from '../../../../constants/main';

export const ModalSaveTemplate = ({ className, page }: IModalSave) => {
    const { t } = useTranslations();

    const router = useRouter();

    const { isShareConfigurationEnabled, isSaveBasketCTADisabled } = useFeatureSwitch();

    const isDealSaved = useSelector((state) => UIDuck.getOwnState(state).isDealSaved);

    return (
        <div className={className}>
            <p className="description">{t('modal.save.description')}</p>
            {isShareConfigurationEnabled && (
                <div className="share">
                    <IconTemplate className="icon" name={Icons.Mail} />
                    <div className="title">{t('shareConfiguration.getEmail')}</div>
                    <div className="label">{t('shareConfiguration.getEmailModalDescription')}</div>
                    <Form page={page} />
                </div>
            )}
            {!isSaveBasketCTADisabled && (
                <div className="save">
                    <IconTemplate className="icon" name={isBrandDS ? Icons.Account : Icons.Profile} />
                    <div className="title">{t('save.title')}</div>
                    <div className="label">{t('save.description')}</div>
                    {(router.query?.saved || isDealSaved) && <div className="saved">{t('save.alreadySaved')}</div>}
                    <div className="link">
                        <SaveToAccountButton link />
                    </div>
                </div>
            )}
        </div>
    );
};
