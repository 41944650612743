import React from 'react';
import { SC } from '../../styles/theme';
import { FAQsStyled } from './FAQsStyled';
import { FAQsStyledAP } from './AP/FAQsStyled.AP';
import { FAQsStyledAC } from './AC/FAQsStyled.AC';
import { FAQsStyledDS } from './DS/FAQsStyled.DS';
import { FAQsStyledOV } from './OV/FAQsStyled.OV';
import { FAQsStyledOV as FAQsStyledOV_GB } from './OV/GB/FAQsStyled';
import { isBrandAP, isBrandOV, isMarketGB, isBrandDS, isBrandAC } from '../../constants/main';

type IFAQProps = SC;
export const faqsTemplateId = 'faqs';
export const paymentTemplateId = 'payments';
export const dealerTemplateId = 'dealer';
export const mandatoryFeesTemplateId = 'mandatoryFees';
export const registrationFeesTemplateId = 'fees';
export const optionalFeesTemplateId = 'optionalFees';
export const optionalAccessoriesTemplateId = 'optionalAccessories';
export const commentTemplateId = 'comment';
export const userTemplateId = 'user';
export const summaryTemplateId = 'summary';
export const continueButtonId = 'continue';
export const pricesTemplateId = 'prices';
export const pxTemplateId = 'px';
export const scrappageTemplateId = 'scrappage';
export type FAQsProps = IFAQProps;

const FAQs = (props: FAQsProps) => {
    if (isBrandAP) return <FAQsStyledAP {...props} />;
    if (isBrandDS) return <FAQsStyledDS {...props} />;
    if (isBrandAC) return <FAQsStyledAC {...props} />;
    if (isBrandOV) return isMarketGB ? <FAQsStyledOV_GB {...props} /> : <FAQsStyledOV {...props} />;
    return <FAQsStyled {...props} />;
};

export default FAQs;
