import React from 'react';

import { BRAND, isMarketGB } from 'src/constants/main';
import { ModalTrimStyledAP } from './AP/ModalTrimStyled.AP';
import { ModalTrimStyledDS } from './DS/ModalTrimStyled.DS';
import { ModalTrimStyledAC } from './AC/ModalTrimStyled.AC';
import { ModalTrimStyledOV } from './OV/ModalTrimStyled.OV';
import { ModalTrimStyledOV as ModalTrimStyledOVGB } from './OV/GB/ModalTrimStyled.OV';
import { ModalTrimStyled } from './ModalTrimStyled';

export const ModalTrimCompare = (props: any) => {
    if (BRAND === 'AP') return <ModalTrimStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalTrimStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalTrimStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalTrimStyledOVGB {...props} /> : <ModalTrimStyledOV {...props} />;
    return <ModalTrimStyled {...props} />;
};

export default ModalTrimCompare;
