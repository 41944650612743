import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalPostalCodeStyledAC } from './AC/ModalPostalCodeStyled.AC';
import { ModalPostalCodeStyledAP } from './AP/ModalPostalCodeStyled.AP';
import { ModalPostalCodeStyledDS } from './DS/ModalPostalCodeStyled.DS';
import { ModalPostalCodeStyledOV } from './OV/ModalPostalCodeStyled.OV';
import { ModalPostalCodeStyledOV as ModalPostalCodeStyledOVGB } from './OV/GB/ModalPostalCodeStyled.OV';
import { IState } from '@components/Modal/ModalTemplate';
import {SEARCH_TYPES} from "@components/Modal/ModalPostalCode/ModalPostalCodeTemplate";

export interface IModalPostalCode extends SC {
    onClose?: () => void;
    setModal: Dispatch<SetStateAction<IState>>;
    searchType?: SEARCH_TYPES;
}

export const ModalPostalCode = (props: IModalPostalCode) => {
    if (BRAND === 'AP') return <ModalPostalCodeStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalPostalCodeStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalPostalCodeStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalPostalCodeStyledOVGB {...props} /> : <ModalPostalCodeStyledOV {...props} />;
};
