import React from 'react';
import { BRAND } from '../../constants/main';
import { SpinnerStyledAC } from './AC/SpinnerStyled.AC';
import { SpinnerStyledAP } from './AP/SpinnerStyled.AP';
import { SpinnerStyledDS } from './DS/SpinnerStyled.DS';
import { SpinnerStyledOV } from './OV/SpinnerStyled.OV';
import { SpinnerStyled } from './SpinnerStyled';
import { SC } from '../../styles/theme';

export interface SpinnerProps extends SC {
    size?: number;
    border?: number;
    color?: string;
}

const Spinner = (props: SpinnerProps) => {
    if (BRAND === 'AC') return <SpinnerStyledAC {...props} />;
    if (BRAND === 'OV') return <SpinnerStyledOV {...props} />;
    if (BRAND === 'AP') return <SpinnerStyledAP {...props} />;
    if (BRAND === 'DS') return <SpinnerStyledDS {...props} />;
    return <SpinnerStyled {...props} />;
};

export default Spinner;
