import styled from 'styled-components';
import { ModalTrimStyled } from '../ModalTrimStyled';
import { rem } from 'polished';

export const ModalTrimStyledAP = styled(ModalTrimStyled)`
    .enginesAvailable {
        .sections {
            .engines {
                .engine {
                    color: ${({ theme }) => theme.colors.grey17};
                }
            }
        }
    }
`;
