import React, { FC, ButtonHTMLAttributes, memo } from 'react';
import { isBrandAP, isBrandDS, isBrandAC, isBrandOV, isMarketGB } from '../../constants/main';
import { ButtonStyledAC } from './AC/ButtonStyled.AC';
import { ButtonStyledOV } from './OV/ButtonStyled.OV';
import { ButtonStyledOV as ButtonStyledOV_GB } from './OV/GB/ButtonStyled.OV';
import { ButtonStyledAP } from './AP/ButtonStyled.AP';
import { ButtonStyledDS } from './DS/ButtonStyled.DS';
import { ButtonStyled, IButtonStyles } from './ButtonStyled';
import { SC } from '../../styles/theme';

export interface IButton extends IButtonStyles, Pick<SC, 'className'>, ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: FC<IButton> = (props) => {
    if (isBrandAC) return <ButtonStyledAC {...props} />;
    if (isBrandAP) return <ButtonStyledAP {...props} />;
    if (isBrandDS) return <ButtonStyledDS {...props} />;
    if (isBrandOV) return isMarketGB ? <ButtonStyledOV_GB {...props} /> : <ButtonStyledOV {...props} />;
    return <ButtonStyled {...props} />;
};

export default memo(Button);
