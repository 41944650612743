import React, { FC, useCallback, useEffect, useState } from 'react';
import { SelectProps } from '.';
import Select, { components, ControlProps, ValueType } from 'react-select';
import useTranslations from '@hooks/useTranslations';
import styled from 'styled-components';
interface ISelectObject {
    label: string;
    value: string;
}

const CustomControl = (isValid: boolean) => (props: any) => {
    return (
        <components.Control {...props} className={isValid ? '' : 'Select__invalid'}>
            {props.children}
        </components.Control>
    );
};

export const SelectTemplate: FC<SelectProps> = ({ isRequired, onChange, onValidate, fullWidth = false, withBorder = false, ...props }) => {
    const { t } = useTranslations();
    const [isValid, setIsValid] = useState(true);

    const handleOnChange = useCallback(
        (values: ValueType<ISelectObject, any>) => {
            onChange?.(values);

            const requiredValuesAreEmpty =
                isRequired && ((Array.isArray(values) && values.length === 0) || values === null);
            if (requiredValuesAreEmpty) {
                setIsValid(false);
                onValidate?.(false);
            } else if (!isValid) {
                setIsValid(true);
                onValidate?.(true);
            }
        },
        [isValid, isRequired, onChange, onValidate]
    );

    return (
        <>
            <Select
                openMenuOnFocus
                closeMenuOnSelect
                placeholder={<div>Type to search</div>}
                classNamePrefix={'Select'}
                components={{ Control: CustomControl(isValid) }}
                {...props}
                onChange={handleOnChange}
            />
            {!isValid && <ErrorMessage> {t(`select.isRequiredMsg`)}</ErrorMessage>}
        </>
    );
};

const ErrorMessage = styled.p`
    color: ${props => props.theme.colors.red};
`;
