import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { IconButtonStyled } from '../IconButtonStyled';

const hoverAndActive = css`
    h2 {
        color: #fff;
    }

    svg {
        path:first-of-type {
            fill: ${({ theme }) => theme.colors.primary};
            stroke: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const IconButtonStyledAP = styled(IconButtonStyled)`
    .horizontal {
        border: 1px solid ${({ theme }) => theme.colors.grey13};

        h2 {
            color: ${({ theme }) => theme.colors.primaryBlack};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }

        .icon-wrapper {
            path {
                stroke: #e0e622;
            }
        }
    }

    h2 {
        color: ${({ theme }) => theme.colors.white};
        text-transform: none;
        font-weight: 300;
    }

    :hover {
        ${hoverAndActive};
    }
    ${({ active }) => active && hoverAndActive};
`;
