import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { rem } from 'polished';
import { HeaderTemplate } from './HeaderTemplate';

export const HeaderStyled = styled(HeaderTemplate)`
    .header {
        display: flex;
        justify-content: center;
        background: ${({ theme }) => theme.colors.black};
        height: ${rem(68)};
        align-items: center;
        position: relative;

        a {
            color: ${({ theme }) => theme.colors.white};
            text-decoration: none;
        }

        @media (min-width: ${breakpoints.md}px) {
            height: ${rem(116)};
        }

        .logoWrapper {
            margin-left: ${rem(15)};
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.colors.white};
            gap: ${rem(20)};

            .titles {
                display: none;

                @media (min-width: ${breakpoints.md}px) {
                    display: block;
                }
            }
        }

        .logo {
            svg {
                width: ${rem(100)};
                height: ${rem(100)};
            }

            @media (min-width: ${breakpoints.md}px) {
                svg {
                    width: ${rem(175)};
                    height: ${rem(175)};
                }
            }
        }

        .desktopLeftPart {
            display: none;
            position: absolute;
            left: 0;

            @media (min-width: ${breakpoints.md}px) {
                display: flex;
            }
        }

        .desktopRightPart {
            display: flex;
            position: absolute;
            right: 0;

            .icon-wrapper {
                height: auto;
            }

            svg {
                width: ${rem(30)};
                height: ${rem(30)};
            }

            @media (min-width: ${breakpoints.md}px) {
                top: ${rem(25)};
            }

            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                margin-right: ${rem(25)};
                display: flex;
                font-size: ${rem(16)};
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                color: ${({ theme }) => theme.colors.white};
                cursor: pointer;
                text-align: center;
                background: none;
                border: none;
                padding: 0;

                .label {
                    display: none;
                    flex-direction: column;
                    text-align: center;

                    &.isBold {
                        font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    }

                    @media (min-width: ${breakpoints.md}px) {
                        display: flex;
                    }

                    .phone {
                        padding-top: ${rem(5)};
                        font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    }
                }
            }

            .pro {
                display: none;

                @media (min-width: ${breakpoints.md}px) {
                    display: block;
                }
            }
        }

        .logoutBox {
            background: ${({ theme }) => theme.colors.white};
            margin-top: ${rem(20)};
            padding: ${rem(5)} ${rem(15)};
            position: absolute;
            bottom: ${rem(-70)};
            display: flex;
            flex-direction: column;
            z-index: 99999999;

            &.without-account {
                bottom: ${rem(-30)};
            }

            a {
                color: black;
                padding: ${rem(5)} 0;

                &:hover,
                &:focus-visible {
                    color: ${({ theme }) => theme.colors.primary};
                    text-decoration: underline;
                }
            }

            ::before {
                content: '';
                align-self: center;
                border-left: ${rem(10)} solid transparent;
                border-right: ${rem(10)} solid transparent;
                border-bottom: ${rem(10)} solid ${({ theme }) => theme.colors.white};
                position: absolute;
                right: calc(50% - 10px);
                top: -${rem(10)};
            }
        }
    }

    .mobileMenu {
        width: 100%;
        height: 100vh;
        background: ${({ theme }) => theme.colors.black};
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999999;

        &.isOpen {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.5s, opacity 0.5s;
        }

        &.isClose {
            transform: translateX(-100%);
            opacity: 0;
            transition: transform 1s, opacity 1s;
        }

        .logo {
            display: flex;
            justify-content: center;
            margin-top: ${rem(30)};
        }

        .close {
            background: none;
            color: ${({ theme }) => theme.colors.white};
            border: none;
            cursor: pointer;
            position: absolute;
            top: ${rem(10)};
            right: ${rem(10)};
        }

        .b2b-switch {
            margin: ${rem(40)} 0 ${rem(40)} ${rem(50)};
        }

        .navigation {
            padding: ${rem(40)} 0;
            border-top: 1px solid ${({ theme }) => theme.colors.grey29};
            margin: ${rem(20)};
            color: ${({ theme }) => theme.colors.white};

            svg {
                width: ${rem(30)};
                height: ${rem(30)};
            }

            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: ${rem(20)};
                position: relative;
                background: none;
                border: none;
                padding: 0;
                color: ${({ theme }) => theme.colors.white};

                .label {
                    display: flex;
                    flex-direction: column;
                    padding-left: ${rem(10)};
                    height: 100%;

                    .phone {
                        font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    }
                }

                .link {
                    position: absolute;
                    right: 0;
                    text-decoration: underline;
                }
            }
        }
    }

    .mobileMenuIcon {
        display: block;
        position: absolute;
        left: ${rem(20)};
        top: ${rem(20)};
        cursor: pointer;

        svg {
            width: ${rem(30)};
            height: ${rem(30)};
        }

        @media (min-width: ${breakpoints.md}px) {
            display: none;
        }
    }

    .b2b-switch {
        margin: 0 0 0 ${rem(50)};
    }

    .b2b-switch {
        position: relative;

        input {
            position: absolute;
            top: 0;
            z-index: 2;
            opacity: 0;
            cursor: pointer;

            &:checked {
                z-index: 1;
            }
        }

        button {
            width: ${rem(120)};
            height: ${rem(22)};
        }
    }

    .b2b-switch input:checked {
        z-index: 1;
    }

    .b2b-switch input:checked + button {
        label {
            color: ${({ theme }) => theme.colors.white};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .b2b-switch label {
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
    }

    .b2b-switch .toggle-outside {
        height: 100%;
        border-radius: ${rem(17)};
        border: 2px solid ${({ theme }) => theme.colors.grey3};
        padding: ${rem(11)};
        transition: opacity 0.3s ease-in-out;
    }

    .b2b-switch .toggle-inside {
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.coffeeDisabled};
        background: ${({ theme }) => theme.colors.coffeeDisabled};
        position: absolute;
        transition: all 0.3s ease-in-out;
    }

    .switch-vertical {
        width: 0;
        height: ${rem(50)};
    }

    .switch-vertical input {
        height: 100%;
        width: ${rem(50)};
        right: 0;
        margin: 0;
    }

    .switch-vertical label {
        font-size: ${rem(20)};
        line-height: ${rem(28)};
        width: ${rem(80)};
        padding-left: ${rem(10)};
        text-transform: capitalize;
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        white-space: nowrap;
        letter-spacing: 0;
        display: flex;
        align-items: center;
    }

    .switch-vertical .toggle-outside {
        position: absolute;
        width: ${rem(23)};
        height: 100%;
        right: 0;
        top: 0;
    }

    .switch-vertical .toggle-inside {
        height: ${rem(20)};
        left: ${rem(1)};
        top: ${rem(1)};
        width: ${rem(20)};
    }

    .switch-vertical input:checked ~ .toggle-outside .toggle-inside {
        top: ${rem(1)};
    }

    .switch-vertical input ~ input:checked ~ .toggle-outside .toggle-inside {
        top: ${rem(25)};
    }
`;
