import styled from 'styled-components';
import { rem } from 'polished';

const MainStyled = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    min-width: ${rem('320px')};
`;

export default MainStyled;
