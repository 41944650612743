import styled from 'styled-components';
import { HeaderStyled } from '../HeaderStyled';
import { breakpoints } from '../../../../styles/theme';
import { rem } from 'polished';

export const HeaderStyledOV = styled(HeaderStyled)`
    .header {
        background: ${({ theme }) => theme.colors.pureWhite};

        .logoWrapper {
            color: ${({ theme }) => theme.colors.black2};

            span {
                font-size: ${({ theme }) => theme.fontSizes.h1};
            }

            h1 {
                font-size: ${({ theme }) => theme.fontSizes.h4};
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                line-height: ${rem(22)};
            }
        }

        .logo {
            svg {
                width: ${rem(70)};
                height: ${rem(80)};
            }

            @media (min-width: ${breakpoints.md}px) {
                svg {
                    width: ${rem(100)};
                    height: ${rem(100)};
                }
            }
        }

        .desktopCenterPart {
            @media (min-width: ${breakpoints.md}px) {
                position: absolute;
                left: 0;
            }
        }

        .desktopRightPart {
            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                font-size: ${({ theme }) => theme.fontSizes.textBase};

                .label {
                    color: ${({ theme }) => theme.colors.black2};
                    margin-top: ${rem(4)};

                    .phone {
                        font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    }
                }

                svg {
                    width: ${rem(25)};
                    height: ${rem(25)};
                }
            }
        }

        .mobileMenu {
            background: ${({ theme }) => theme.colors.pureWhite};

            .navigation {
                .link-wrapper,
                .help,
                .account,
                .basket,
                .pro {
                    .label {
                        color: ${({ theme }) => theme.colors.black2};
                    }
                }
            }
        }

        .mobileMenuIcon {
            svg {
                g {
                    path {
                        fill: ${({ theme }) => theme.colors.black2} !important;
                    }
                }
            }
        }
    }

    .b2b-switch input:checked + button {
        label {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            color: ${({ theme }) => theme.colors.black2};
        }
    }

    .b2b-switch .toggle-inside {
        border-color: ${({ theme }) => theme.colors.black2};
        background: ${({ theme }) => theme.colors.black2};
    }

    .switch-vertical label {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        color: ${({ theme }) => theme.colors.black2};
    }
`;
