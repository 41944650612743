import styled from 'styled-components';
import { ModalShareConfigurationPersonalizeOfferStyled } from '../ModalShareConfigurationPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalShareConfigurationPersonalizeOfferStyledDS = styled(ModalShareConfigurationPersonalizeOfferStyled)`
    .share-wrapper {
        .input-wrapper {
            .dropdown,
            input {
                border-color: ${({ theme }) => theme.colors.black1};
                border-radius: ${rem(4)};
                font-size: ${({ theme }) => theme.fontSizes.h5};

                &:disabled {
                    background: ${({ theme }) => theme.colors.grey16};
                }
            }

            .input-section-label {
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }
        }

        .mandatory {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }
    }

    .checkboxSection {
        &-title p {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        .container label {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }
    }

    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.primary};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
            }
        }
    }
`;
