import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { SelectStyledAC } from './AC/SelectStyled.AC';
import { SelectStyledAP } from './AP/SelectStyled.AP';
import { SelectStyledDS } from './DS/SelectStyled.DS';
import { SelectStyledOV } from './OV/SelectStyled.OV';
import { SelectStyledOVGB } from './OV/GB/SelectStyled.OV';
import { SelectStyled } from './SelectStyled';
import { SC } from '../../styles/theme';
import { Props, OptionTypeBase } from 'react-select';

export interface SelectProps extends SC, Omit<Props<OptionTypeBase>, 'className'> {
    arrowColor?: string;
    isRequired?: boolean;
    onValidate?: (value: boolean) => void;
    fullWidth?: boolean;
    withBorder?: boolean;
}

/**
 *  @see Documentation
 *  @link https://react-select.com/
 */

const Select = (props: SelectProps) => {
    if (BRAND === 'AC') return <SelectStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <SelectStyledOVGB {...props} /> : <SelectStyledOV {...props} />;
    if (BRAND === 'AP') return <SelectStyledAP {...props} />;
    if (BRAND === 'DS') return <SelectStyledDS {...props} />;
    return <SelectStyled {...props} />;
};

export default Select;
