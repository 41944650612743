import styled from 'styled-components';
import React from 'react';
import Button from '../Button';

export interface IModalMissingParts {
    message: string;
    additionalMessage?: string;
    buttonText: string;
    onClick: () => void;
}

const StyledWrapper = styled.div`
    width: 100%;

    & .button-container {
        margin-top: 40px;
    }
`;

const ModalMissingParts = ({ message, additionalMessage, buttonText, onClick }: IModalMissingParts) => {
    return (
        <StyledWrapper>
            <p dangerouslySetInnerHTML={{ __html: message }} />
            {additionalMessage && <p dangerouslySetInnerHTML={{ __html: additionalMessage }} />}
            <div className="button-container">
                <Button primary onClick={() => onClick?.()}>{buttonText}</Button>
            </div>
        </StyledWrapper>
    );
};

export default ModalMissingParts;
