import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FooterStyled } from '../FooterStyled';
import { rem } from 'polished';

export const FooterStyledAP = styled(FooterStyled)`
    .orderNowSection {
        @media (min-width: ${breakpoints.sm}px) {
            padding: ${rem(70)} 0 ${rem(110)} 0;
        }

        .itemsWrapper {
            width: 75%;
            margin: 0 auto;

            .footerItem {
                max-width: unset;

                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(300)};
                }

                @media (min-width: ${breakpoints.md}px) {
                    max-width: ${rem(160)};
                }

                .textWrap {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    line-height: ${({ theme }) => theme.lineHeights.textMedium};
                    color: ${({ theme }) => theme.colors.default};
                    align-items: flex-start;

                    @media (min-width: ${breakpoints.sm}px) {
                        text-align: left;
                    }

                    @media (min-width: ${breakpoints.md}px) {
                        text-align: center;
                    }
                }

                .imageWrap {
                    padding-bottom: ${rem(5)};

                    svg {
                        width: ${rem(50)};
                        height: ${rem(50)};
                    }
                }

                .imageWrap {
                    padding-bottom: ${rem(5)};
                }

                &:nth-child(3) {
                    svg {
                    }
                }
            }
            .footerItem:nth-child(1) {
                .textWrap {
                    max-width: ${rem(130)};
                }
            }
        }

        .orderNowTitle {
            color: ${({ theme }) => theme.colors.blue7};
            font-size: ${rem(18)};
            width: 70%;
            margin: 0 auto;

            @media (min-width: ${breakpoints.sm}px) {
                width: 100%;
            }
        }
    }

    .footer ul li {
        padding: ${rem(5)} 0;

        @media (min-width: ${breakpoints.sm}px) {
            padding: 0 ${rem(15)} 0 0;
        }
    }

    .footerScrollToTopAnchor {
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        svg {
            height: ${rem(18)};
            margin-bottom: ${rem(5)};
        }
    }
`;
