import { useSelector } from 'react-redux';
import { ContentDuck } from '../redux/content/content.duck';

export const useContentDuck = () => {
    const faqs = useSelector((state) => ContentDuck.getFaq(state));

    return { faqs };
};

export const useTranslationKeyProvided = (key: string = '') => {
    const translations = useSelector((state) => ContentDuck.getTranslations(state));
    const translationValue = translations[key];
    return !!translationValue && !/^\s$/.test(translationValue); // space is char which is put-in as empty value in BO (only way how to delete translation value from BO now)
};
