import React from 'react';

const handleOnClick = (e: any, props: any) => {
    e.preventDefault();
    console.log('CLICKED', props);
};

export const LinkMock = (props: any) => {
    console.log('MOCK LINK');

    return (
        <span {...props} onClick={e => handleOnClick(e, props)}>
            {props.children}
        </span>
    );
};
