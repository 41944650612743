import { DealService } from '../services';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import carDetailsDuck from '../redux/carDetails/carDetails.duck';
import FilterDuck from '../redux/filters/filter.duck';
import useCustomRouter from './useCustomRouter';
import userDuck from '../redux/user/user.duck';
import UIDuck from '../redux/commonDucks/ui.duck';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useExperiment } from './useExperiment';

/**
 * hook for saving deal(logging in if nescessary) && showing if user has same configuration in deal as is loaded in redux
 */
interface IData {
    form_type: string;
}

export const useLoginAndSaveDeal = (data: IData | null) => {
    const router = useCustomRouter();
    const dispatch = useDispatch();
    const { pushTrigger } = useExperiment();

    const currentDeal = useSelector((state) => carDetailsDuck.getOwnState(state).currentDeal);
    const isLoggedIn = useSelector((state) => userDuck.getOwnState(state).userAuth.isLoggedIn);
    const paymentJourney = useSelector((state) => FilterDuck.getPaymentJourneyType(state));
    const carConfigurationId: string | undefined = useSelector((state) => {
        const { currentCarDetails } = carDetailsDuck.getOwnState(state);
        return currentCarDetails?._id ?? currentCarDetails?.externalId;
    });
    const carModelId: string | undefined = useSelector((state) => {
        const { currentCarDetails } = carDetailsDuck.getOwnState(state);
        return currentCarDetails?.model?.id;
    });
    const carSpecPackId: string | undefined = useSelector((state) => {
        const { currentCarDetails } = carDetailsDuck.getOwnState(state);
        return currentCarDetails?.specPack?.id;
    });

    const getRedirectToUrl = (financeQuoteId: string) => {
        const hasQueryParameters = window.location.href.includes('?');
        const financeQuoteParameter = hasQueryParameters
            ? `&financeQuoteId=${financeQuoteId}`
            : `?financeQuoteId=${financeQuoteId}`;

        const savedParameter = financeQuoteId || hasQueryParameters ? `&saved=true` : `?saved=true`;

        return financeQuoteId
            ? `${window.location.href}${financeQuoteParameter}${savedParameter}`
            : `${window.location.href}${savedParameter}`;
    };

    // dealSaved means i have the same configuration loaded as is saved in the deal
    const [dealSaved, setDealSaved] = useState(false);
    useEffect(() => {
        if (
            carConfigurationId &&
            currentDeal?.saved &&
            carModelId === currentDeal?.fullProductConfiguration?.model.id &&
            carSpecPackId === currentDeal?.fullProductConfiguration?.specPack.id
        ) {
            setDealSaved(true);
        } else {
            setDealSaved(false);
        }
    }, [carConfigurationId, currentDeal, carModelId, carSpecPackId]);

    // saves deal, logs user in if nescessary
    const loginAndSaveDeal: (financeQuoteId?: string, page?: string) => void = useCallback(
        async (financeQuoteId = null, page = 'basket') => {
            // if logged in && saved && configurations the same, then there is nothing to be done here
            if (isLoggedIn && currentDeal?.saved && carConfigurationId === currentDeal.configuration) {
                return;
            }
            dispatch(UIDuck.setLoading(5));
            const extraField = data && {
                form_submissions: {
                    form_type: data?.form_type,
                },
            };
            await DealService.saveDeal(getSessionIdCookie(), extraField ?? undefined);
            dispatch(UIDuck.setLoading(-5));
            if (isLoggedIn) {
                dispatch(carDetailsDuck.setCurrentDeal({ ...currentDeal, saved: true }));
                pushTrigger('save', page, 'save', 'logged-in');
                setDealSaved(true);
            } else {
                // redirect to login so deal can be linked to account
                router.push({
                    pathname: '/login',
                    query: {
                        redirectTo: getRedirectToUrl(financeQuoteId),
                        saveDeal: true, // show save texts on login page
                    },
                });
            }
        },
        [isLoggedIn, paymentJourney, carConfigurationId, data]
    );

    return [dealSaved, loginAndSaveDeal] as [boolean, (financeQuoteId?: string, page?: string) => void];
};
