import React from 'react';
import { SC } from '../../styles/theme';
import { BRAND, isBrandOV } from '../../constants/main';
import { CheckoutTipsSidebarStyledAC } from './AC/CheckoutTipsSidebarStyled.AC';
import { CheckoutTipsSidebarStyledAP } from './AP/CheckoutTipsSidebarStyled.AP';
import { CheckoutTipsSidebarStyledDS } from './DS/CheckoutTipsSidebarStyled.DS';
import { CheckoutTipsSidebarStyledOV } from './OV/CheckoutTipsSidebarStyled.OV';
import { CheckoutTipsSidebarStyled } from './CheckoutTipsSidebarStyled';

export interface IItem {
    text?: string;
    title?: string;
    withoutSuffix?: boolean;
}
export interface CheckoutTipsSidebarProps extends SC {
    title: string;
    items: Array<IItem>;
    branchDifferences?: boolean;
    isStockCar?: boolean;
}

const CheckoutTipsSidebar = (props: CheckoutTipsSidebarProps) => {
    if (isBrandOV) return <CheckoutTipsSidebarStyledOV {...props} />;
    if (BRAND === 'AC') return <CheckoutTipsSidebarStyledAC {...props} />;
    if (BRAND === 'AP') return <CheckoutTipsSidebarStyledAP {...props} />;
    if (BRAND === 'DS') return <CheckoutTipsSidebarStyledDS {...props} />;
    return <CheckoutTipsSidebarStyled {...props} />;
};

export default CheckoutTipsSidebar;
