import { css } from 'styled-components';

export const ACStyles = css`
    .needHelpPhone {
        .needHelpPhoneNumber,
        .needHelpPhoneTitle {
            font-weight: bold;
        }
        .needHelpPhoneNumber {
            color: ${({ theme }) => theme.colors.red};
        }
    }
`;
