import styled from 'styled-components';
import { TextAreaStyled } from '../TextAreaStyled';
import { rem } from 'polished';

export const TextAreaStyledAC = styled(TextAreaStyled)`
    span {
        font-size: ${({ theme }) => theme.fontSizes.h4};
    }

    textarea {
        border: 1px solid ${({ theme }) => theme.colors.grey3};
        color: ${({ theme }) => theme.colors.grey1};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        border-radius: ${rem(12)};
    }
`;
