import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { ButtonStyled } from '../ButtonStyled';
import { rem } from 'polished';

export const ButtonStyledAP = styled(ButtonStyled)`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    text-transform: uppercase;
    padding: 0 ${rem(24)};
    height: ${rem(48)};
    font-size: ${({ theme }) => theme.fontSizes.textTiny};

    ${({ primary }) =>
        primary &&
        css`
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.black};
            }

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.white};
                }
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            background: ${({ theme }) => theme.colors.white};
            border: 1px solid ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.black};

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};
                border-color: ${({ theme }) => theme.colors.primary};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }
        `}

    ${({ primary, disabled }) =>
        primary &&
        disabled &&
        css`
            background: ${({ theme }) => theme.colors.grey12};
        `}

    ${({ secondary, disabled }) =>
        secondary &&
        disabled &&
        css`
            opacity: 0.4;
        `}
`;
