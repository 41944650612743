import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { IconButtonStyled } from '../IconButtonStyled';

export const IconButtonStyledAC = styled(IconButtonStyled)`
    h2 {
        color: ${({ theme }) => theme.colors.default};
        font-weight: 300;
    }

    &:hover {
        h2 {
            color: ${({ theme }) => theme.colors.secondary};
        }
        svg {
            path {
                stroke: ${({ theme }) => theme.colors.secondary};
            }
            rect {
                fill: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    svg {
        path {
            stroke: ${({ theme }) => theme.colors.primary};
        }
        rect {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }

    .horizontal:hover .icon-wrapper path {
        stroke: ${({ theme }) => theme.colors.secondary};
    }
`;
