import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import Select from '../Select';
import { ValueType } from 'react-select';
import ContentService from '../../services/content/content.service';
import ContentDuck from '../../redux/content/content.duck';
import { connect } from 'react-redux';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { useTranslation } from '../../../i18n';
import Link from '../CustomNextLink';
import { rem } from 'polished';

interface Props {
    dispatch: any;
    setTranslationKeyView: any;
    translationKeysView: any;
    hidden?: boolean;
}

const LanguageBar = styled.div`
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 99;
    display: flex;
    width: 100%;
    height: ${rem(40)};

    @media (max-width: 540px) {
        height: ${rem(65)};
        position: inherit;
        margin-top: ${rem(-38)};
    }

    button {
        width: auto;
    }

    .close {
        margin-left: auto;
        text-decoration: none;
        padding: ${rem(10)};
    }
`;

const NavLink = styled.div`
    padding: ${rem(10)};

    @media (max-width: ${breakpoints.sm}px) {
        padding: ${rem(8)};
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: ${rem(3)};
        font-size: ${rem(8)};
    }
`;

const LanguageSelect = styled(Select)`
    min-width: ${rem(80)};
    margin-left: ${rem(10)};
`;

const LanguageControls: React.FunctionComponent<Props> = ({
    dispatch,
    setTranslationKeyView,
    translationKeysView,
    hidden,
}) => {
    const { i18n } = useTranslation();

    const changeLanguage = async (lang: string) => {
        const content = await ContentService.getContent(lang);
        if (content) dispatch(ContentDuck.setContent(content, lang, i18n));
    };

    const onLanguageChange = async (option: ValueType<any, any>) => {
        option.value !== i18n.language && changeLanguage(option.value);
    };

    const openFeatureSwitchesModal: MouseEventHandler = e => {
        e.preventDefault();
        dispatch(
            UIDuck.openModal({
                data: { data: {} },
                modalType: UIDuck.MODAL_TYPES.FEATURE_SWITCHES,
            })
        );
    };

    const languages = (i18n.languages || []).map(l => ({ value: l, label: l.toUpperCase() }));
    if (hidden) return null;

    return (
        <LanguageBar>
            <>
                <LanguageSelect
                    instanceId="languageSelect"
                    options={languages}
                    defaultValue={languages.find(it => it.value === i18n.language)}
                    onChange={onLanguageChange}
                />

                <NavLink>
                    <Link href="#">
                        <a
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setTranslationKeyView(!translationKeysView));
                            }}
                        >
                            {' '}
                            {!translationKeysView ? 'Show translation keys' : 'Hide translation keys'}
                        </a>
                    </Link>
                </NavLink>

                <NavLink>
                    <Link href="/docs/index.html">
                        <a target="_blank">Docs</a>
                    </Link>
                </NavLink>
                <NavLink>
                    <Link href="/reports/cypress/index.html">
                        <a target="_blank">E2E tests</a>
                    </Link>
                </NavLink>
                <NavLink>
                    <Link href="/reports/unit/index.html">
                        <a target="_blank">Unit tests</a>
                    </Link>
                </NavLink>
                <NavLink>
                    <Link href="#">
                        <a onClick={openFeatureSwitchesModal} data-testid="TESTING_OPEN_FEATURE_SWITCHES_BUTTON">Open feature switches</a>
                    </Link>
                </NavLink>
                <Link href="#">
                    <a
                        className="close"
                        onClick={e => {
                            e.preventDefault();
                            dispatch(UIDuck.hideDevBar());
                        }}
                    >
                        x
                    </a>
                </Link>
            </>
        </LanguageBar>
    );
};

const MapStateToProps = (state: any) => ({
    loadContent: ContentDuck.loadContent,
    translationKeysView: UIDuck.getOwnState(state).translationKeysView,
    hidden: UIDuck.getOwnState(state).devBarHidden,
    setTranslationKeyView: UIDuck.setTranslationKeysViewer,
});

export default connect(MapStateToProps)(LanguageControls);
