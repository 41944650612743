import React from 'react';
import { BRAND, isMarketGB } from '../../../../constants/main';
import { FaqItemStyledAC } from './AC/FaqItemStyled.AC';
import { FaqItemStyledAP } from './AP/FaqItemStyled.AP';
import { FaqItemStyledDS } from './DS/FaqItemStyled.DS';
import { FaqItemStyledOV } from './OV/FaqItemStyled.OV';
import { FaqItemStyledOV as FaqItemStyledOV_GB } from './OV/GB/FaqItemStyled.OV';
import { FaqItemStyled } from './FaqItemStyled';
import { SC } from '../../../../styles/theme';
import { CollapsibleProps } from 'react-collapsible';

interface IAdditionals {
    isRoot?: boolean;
}
export type FaqItemProps = SC & Omit<CollapsibleProps, 'className' | 'as' | 'ref'> & IAdditionals;

/**
 *  @see Documentation
 *  @link https://www.npmjs.com/package/react-collapsible
 */
const FaqItem = (props: FaqItemProps) => {
    if (BRAND === 'OV') {
        if (isMarketGB) {
            return <FaqItemStyledOV_GB {...props} />;
        }

        return <FaqItemStyledOV {...props} />;
    }
    if (BRAND === 'AP') return <FaqItemStyledAP {...props} />;
    if (BRAND === 'DS') return <FaqItemStyledDS {...props} />;
    if (BRAND === 'AC') return <FaqItemStyledAC {...props} />;
    return <FaqItemStyled {...props} />;
};

export default FaqItem;
