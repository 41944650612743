import React from 'react';
import { LegalTermsAndConditionsConsentStyled } from './LegalTermsAndConditionsConsentStyled';
import { SC } from '../../styles/theme';
import { BRAND, isMarketGB } from '../../constants/main';
import { LegalTermsAndConditionsConsentStyledAC } from './AC/LegalTermsAndConditionsConsentStyled.AC';
import { LegalTermsAndConditionsConsentStyledAP } from './AP/LegalTermsAndConditionsConsentStyled.AP';
import { LegalTermsAndConditionsConsentStyledDS } from './DS/LegalTermsAndConditionsConsentStyled.DS';
import { LegalTermsAndConditionsConsentStyledOV } from './OV/LegalTermsAndConditionsConsentStyled.OV';
import { LegalTermsAndConditionsConsentStyledOV as LegalTermsAndConditionsConsentStyledOV_GB } from './OV/GB/LegalTermsAndConditionsConsentStyled.OV';

export type LegalTermsAndConditionsConsentProps = SC & {
    showRequiredErrors?: boolean;
    setSelectedLegalValues?: any;
    withoutDescription?: boolean;
};

const LegalTermsAndConditionsConsent = (props: LegalTermsAndConditionsConsentProps) => {
    if (BRAND === 'AC') return <LegalTermsAndConditionsConsentStyledAC {...props} />;
    if (BRAND === 'AP') return <LegalTermsAndConditionsConsentStyledAP {...props} />;
    if (BRAND === 'DS') return <LegalTermsAndConditionsConsentStyledDS {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <LegalTermsAndConditionsConsentStyledOV_GB {...props} /> : <LegalTermsAndConditionsConsentStyledOV {...props} />;
    return <LegalTermsAndConditionsConsentStyled {...props} />;
};

export default LegalTermsAndConditionsConsent;
