import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { rem } from 'polished';
import { CheckoutMyDetailsStyled } from '../CheckoutMyDetailsStyled';

export const CheckoutMyDetailsStyledAP = styled(CheckoutMyDetailsStyled)`
    .wrapper {

        &-title {
            text-align: center;
            margin: ${rem(15)} auto;
            font-size: ${({ theme }) => theme.fontSizes.h5};

            @media (min-width: ${breakpoints.md}px) {
                text-align: left;
                font-size: ${({ theme }) => theme.fontSizes.h2};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                margin: -${rem(10)} 0 ${rem(10)} 0;
            }
        }

        .text p {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }

        .buttons {
            justify-content: unset;

            button {
                width: 100%;

                @media (min-width: ${breakpoints.sm}px) {
                    width: auto;

                    &:first-child {
                        margin-right: ${rem(10)};
                    }
                }

                &:last-child {
                    border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
                }

                p {
                    font-size: ${({ theme }) => theme.fontSizes.textTiny};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }
            }
        }
    }
`;
