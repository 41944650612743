import styled from 'styled-components';
import { rem } from 'polished';
import { ModalPostalCodeStyled } from '../ModalPostalCodeStyled';

export const ModalPostalCodeStyledAC = styled(ModalPostalCodeStyled)`
    .title {
        font-size: ${rem(22)};
        color: ${({ theme }) => theme.colors.grey5};
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
    }

    .input {
        > div {
            .geolocation-input--container .geolocation-input--textfield-container input {
                border-radius: ${rem(12)};
                font-size: ${({ theme }) => theme.fontSizes.h5};
                color: ${({ theme }) => theme.colors.grey1};
                border: 1px solid ${({ theme }) => theme.colors.grey3};

                &::placeholder {
                    color: ${({ theme }) => theme.colors.grey1};
                }
            }
        }
    }

    .type-select {
        .container {
            &.isSelected {
                color: ${({ theme }) => theme.colors.blue2};
            }
        }
    }

    .submit {
        button {
            border-radius: ${rem(24)};

            &:disabled {
                background: ${({ theme }) => theme.colors.grey6};
                color: ${({ theme }) => theme.colors.grey3};
            }
        }

        .home {
            &:hover:enabled,
            &:focus-visible:enabled {
                border-color: ${({ theme }) => theme.colors.grey5};
                color: ${({ theme }) => theme.colors.grey5};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.grey5};
                    }
                }
            }
        }

        .validate {
            background: ${({ theme }) => theme.colors.red};

            &:hover:enabled,
            &:focus-visible:enabled {
                background: ${({ theme }) => theme.colors.grey5};
            }
        }
    }
`;
