import { B2B_FIELD } from 'src/constants/enums';
import { MARKET, MARKETS, IS_B2B, B2B_FILEDS } from '../constants/main';

const TEXT_NAME_MAX_LENGTH: number = 32;
const TEXT_NAME_MIN_LENGTH: number = 2;
const USER_DETAILS_FIELDS: string[] = ['firstname', 'lastname'];
const TEXT_MAX_LENGTH: number = 128;
export const NUMBER_FIELD_REGEX: RegExp = /^[0-9\b]+$/;
const TEXT_FIELD_REGEX: RegExp = /^[a-zA-Z_ÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿíóúñÍÓÚÑ.'-\s]*$/;
const BILLING_BUSINESS_NAME_REGEX: RegExp = /^([^\?&;<>=\|]{0,120})$/;
const BILLING_BUSINESS_ID_REGEX: RegExp = /^([^\?&;<>=\|]{0,50})$/;
const BILLING_BUSINESS_VAT_REGEX: RegExp = /^([^\?&;<>=\|]{0,50})$/;
export const EMAIL_REGEX: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getMandatoryAddressFields = (market: MARKETS): string[] => {
    switch (market) {
        case MARKETS.FR:
            return ['address1', 'city'];
        case MARKETS.GB:
        default:
            return ['address1', 'city'];
    }
};

const getPostcodeRegex = (market: MARKETS): RegExp => {
    switch (market) {
        case MARKETS.FR:
            return /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/;
        case MARKETS.GB:
            return /[A-Z]{1,2}[0-9][0-9A-Z]{0,2} ?[0-9][A-Z]{2}/i;
        default:
            return /.*/;
    }
};

const getPhoneRegex = (market: MARKETS): RegExp => {
    switch (market) {
        case MARKETS.FR:
            return /^(?:(?:\(?(?:0(?:0|10)\)?[\s-]?\(?|\+)33\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
        case MARKETS.GB:
            return /^(\+44\s?(1|2|3|7|8)\d{3}|\(?01\d{3}|\(?02\d{3}|\(?03\d{3}|\(?07\d{3}|\(?08\d{3}\)?)\s?\d{3}\s?\d{2}\d?$/i;
        default:
            return /.*/;
    }
};

const getPhoneMaxLength = (market: MARKETS): number => {
    switch (market) {
        case MARKETS.GB:
            return 11;
        case MARKETS.ES:
            return 9;
        case MARKETS.FR:
        default:
            return 10;
    }
};

export const POSTCODE_REGEX: RegExp = getPostcodeRegex(MARKET as MARKETS);
const USER_ADDRESS_FIELDS: string[] = getMandatoryAddressFields(MARKET as MARKETS);
export const PHONE_REGEX: RegExp = getPhoneRegex(MARKET as MARKETS);
export const PHONE_MAX_LENGTH: number = getPhoneMaxLength(MARKET as MARKETS);
export const NIF_REGEX = /^[a-zA-Z0-9]{9}$/;
export const BUSINESS_NAME_REGEX = /^([^\?&;<>=\|]{0,120})$/;
export const BUSINESS_ID_REGEX = /^[0-9]{14}$/;
export const BUSINESS_VAT_REGEX = /^([^\?&;<>=\|]{0,120})$/;

export const userDetailsValidation = (values: any, t: any) => {
    const errors: { [k: string]: any } = {};
    USER_DETAILS_FIELDS.forEach((field: any) => {
        if (!values[field]) {
            errors[field] = t('checkout.error.required');
        } else if (values[field] && values[field].length > TEXT_NAME_MAX_LENGTH) {
            errors[field] = t('checkout.error.textNameMaxLength');
        } else if (values[field] && values[field].length < TEXT_NAME_MIN_LENGTH) {
            errors[field] = t('checkout.error.textNameMinLength');
        } else if (values[field] && !TEXT_FIELD_REGEX.test(values[field])) {
            errors[field] = t('checkout.error.alpha');
        } else if (values[field] && !values[field].trim().length) {
            errors[field] = t('checkout.error.emptySpaces');
        }
    });
    if (!values.businessName && B2B_FILEDS.includes(B2B_FIELD.COMPANY_NAME) && IS_B2B) {
        errors.businessName = t('checkout.error.required');
    }
    if (!values.businessID && B2B_FILEDS.includes(B2B_FIELD.COMPANY_NUMBER) && IS_B2B) {
        errors.businessID = t('checkout.error.required');
    }
    if (!values.businessVAT && B2B_FILEDS.includes(B2B_FIELD.COMPANY_VAT) && IS_B2B) {
        errors.businessVAT = t('checkout.error.required');
    }

    if (values.businessName && !BILLING_BUSINESS_NAME_REGEX.test(values.businessName)) {
        errors.businessName = t('checkout.error.businessName');
    }
    if (
        values.businessID &&
        B2B_FILEDS.includes(B2B_FIELD.COMPANY_NUMBER) &&
        !BILLING_BUSINESS_ID_REGEX.test(values.businessID)
    ) {
        errors.businessID = t('checkout.error.businessID');
    }
    if (
        values.businessVAT &&
        B2B_FILEDS.includes(B2B_FIELD.COMPANY_VAT) &&
        !BILLING_BUSINESS_VAT_REGEX.test(values.businessVAT)
    ) {
        errors.businessVAT = t('checkout.error.businessVAT');
    }
    if (!values.telephone) {
        errors.telephone = t('checkout.error.required');
    } else if (values.telephone && !NUMBER_FIELD_REGEX.test(values.telephone)) {
        errors.telephone = t('checkout.error.numbersOnly');
    } else if (values.telephone && values.telephone.length !== PHONE_MAX_LENGTH) {
        errors.telephone = t('checkout.error.phonenumber.length');
    } else if (values.telephone && !PHONE_REGEX.test(values.telephone)) {
        errors.telephone = t('checkout.error.phonenumber');
    }
    return errors;
};

export const userAddressValidation = (values: any, t: any, options: any = {}) => {
    const errors: { [k: string]: any } = {};

    USER_ADDRESS_FIELDS.forEach((field: string) => {
        if (!values[field]) {
            errors[field] = t('checkout.error.required');
        } else if (values[field] && values[field].length > TEXT_MAX_LENGTH) {
            errors[field] = t('checkout.error.textNameMaxLength');
        }
    });

    if (values.address2 && values.address2.length > TEXT_MAX_LENGTH) {
        errors.address2 = t('checkout.error.textNameMaxLength');
    }

    if (values.city && !TEXT_FIELD_REGEX.test(values.city)) {
        errors.city = t('checkout.error.onlyLetters');
    }

    if (!values.postcode) {
        errors.postcode = t('checkout.error.required');
    } else if (values.postcode && !POSTCODE_REGEX.test(values.postcode)) {
        errors.postcode = t('checkout.error.postcode');
    }

    if (options.isNifEnabled) {
        if (!values.nif) {
            errors.nif = t('checkout.error.required');
        } else if (values.nif && !NIF_REGEX.test(values.nif)) {
            errors.nif = t('checkout.error.nif');
        }
    }

    return errors;
};
