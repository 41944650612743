import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { getSessionIdCookie, removePromoCodeCookie } from '@utils/Session.utils';
import { useDispatch, useSelector } from 'react-redux';
import parser from 'bbcode-to-react';
import CarDetailsDuck from '../../redux/carDetails/carDetails.duck';
import FinanceWidgetDuck from '../../redux/financeWidget/financeWidget.duck';
import { IPromoCode } from './index';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { IPriceV2, IPriceV2Types, PROMO_CODE_DISCOUNT_KEYS } from '../../services';
import { getTotalPriceObjByType } from '@utils/Price.utils';
import { Redux } from 'src/redux/redux.interface';
import { ICarDetail } from '../../redux/carDetails/carDetails.duck.interface';
import { useJourneyType } from '@hooks/useJourneyType';
import { DealService } from '../../services';
import Icon, { Icons } from '@components/Icon';
import { isBrandAC } from '../../constants/main';
import { Link } from '@components/v2/link';

const PromoCodeTemplate: FC<IPromoCode> = memo(
    ({ className, isBasketSummary, promoCode, showTitle, hideValue = false }) => {
        const { t } = useTranslations();
        const dispatch = useDispatch();
        const { formatMoney } = useMoneyFormatter();

        const parsedPromoCode = promoCode && JSON.parse(promoCode);
        const legalText = parsedPromoCode?.legalText ?? '';
        const [legalTextOpen, setLegalTextOpen] = useState(!!legalText);

        const { isFinance } = useJourneyType();
        const carDetails: ICarDetail = useSelector((state: Redux) => CarDetailsDuck.getCurrentCarDetails(state));
        const promoCodeDiscountAmount = useMemo<number>(() => {
            const totalB2CPrice: IPriceV2 = getTotalPriceObjByType(
                carDetails?.pricesV2 || carDetails?.extraFields?.pricesV2,
                isFinance ? IPriceV2Types.TOTAL_B2C_FINANCE : IPriceV2Types.TOTAL_B2C_CASH
            );
            const promoCodeDiscount = totalB2CPrice?.discounts?.find(({ key }) =>
                PROMO_CODE_DISCOUNT_KEYS.includes(key)
            );
            return (
                promoCodeDiscount?.absoluteAmount ?? promoCodeDiscount?.amount ?? parsedPromoCode?.discountAmount ?? 0.0
            );
        }, [carDetails, isFinance]);

        const removePromoCode = useCallback(async () => {
            const { data } = await DealService.removePromoCode(JSON.parse(promoCode).code, getSessionIdCookie());
            if (data.success) {
                dispatch(CarDetailsDuck.setCurrentDeal(data.deal));
                dispatch(CarDetailsDuck.setCurrentCarDetails(data.deal.fullProductConfiguration));
                dispatch(FinanceWidgetDuck.setFinanceQuote(data.financeQuote));
                removePromoCodeCookie();
            }
        }, [promoCode, dispatch]);
        const toggleLegalText = () => setLegalTextOpen(!legalTextOpen);

        return (
            <div className={className}>
                {promoCode && (
                    <>
                        {showTitle && <div className="promoCode__title">{t('promoCode.label.title')}</div>}
                        <div className="promoCode__content">
                            <span className="result">
                                {t('promoCode.label.discount').trim() !== '' && (
                                    <span>
                                        {t('promoCode.label.discount')}
                                        {' | '}
                                    </span>
                                )}
                                <span data-testid="TESTING_PROMO_CODE_CODE">{parsedPromoCode.code}</span> {' | '}
                                {parsedPromoCode.label}
                                {isBasketSummary && (
                                    <span>
                                        {' | '}
                                        <Link isUnderline txt={t('promoCode.button.remove')} onClick={removePromoCode}>
                                            <button className="promoCode__removeBtn" onClick={removePromoCode}>
                                                {t('promoCode.button.remove')}
                                            </button>
                                        </Link>
                                    </span>
                                )}
                                {legalText && (
                                    <span onClick={toggleLegalText}>
                                        &nbsp;
                                        <Icon
                                            className={
                                                legalTextOpen ? 'promoCode__legalTextOpen' : 'promoCode__legalTextClose'
                                            }
                                            name={legalTextOpen ? Icons.ChevronUp : Icons.ChevronDown}
                                        />
                                    </span>
                                )}
                            </span>
                            {!hideValue && (
                                <span className="price" data-testid="TESTING_PROMO_CODE_PRICE">
                                    {promoCodeDiscountAmount > 0 ? '-' : ''} {formatMoney(promoCodeDiscountAmount)}
                                </span>
                            )}
                        </div>
                        {legalText && legalTextOpen && (
                            <div className="promoCode__legalText">{parser.toReact(legalText)}</div>
                        )}
                    </>
                )}
            </div>
        );
    }
);
PromoCodeTemplate.displayName = 'PromoCodeTemplate';

export default PromoCodeTemplate;
