import styled from 'styled-components';
import { ModalNeedHelpStyled } from '../ModalNeedHelpStyled';
import { rem } from 'polished';

export const ModalNeedHelpStyledAP = styled(ModalNeedHelpStyled)`
    .description {
        font-size: ${rem(12)};
        color: black;
    }

    .request,
    .testDrive,
    .phone,
    .faq {
        .title {
            font-size: ${rem(16)};
            font-weight: bold;
        }

        .description {
            font-size: ${rem(12)};
        }
    }
`;
