import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
    Fragment,
} from 'react';
import { useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';
import { ScrollSyncPaneProps, ScrollSyncProps } from 'react-scroll-sync';

import useTranslations from '@hooks/useTranslations';
import useAccessoryMonthlyPrices, { IAccessoryMonthlyPrice } from '@hooks/useAccessoryPrices';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { useGetNotEscapedTranslationMarkup } from '@hooks/useGetNotEscapedTranslationMarkup';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { IFeature, IPriceV2Types } from 'src/services';
import { getTotalPriceObjByType } from '@utils/Price.utils';
import { ITechnicalSpecificationsData } from '@utils/Characteristics.utils';
import {
    CategoryTitle,
    LegalMentions,
    Row,
    RowCell,
    RowTitle,
    Separator,
    Title,
    Wrapper,
    CategoriesSection,
} from './ModalEngineCompareStyled';
import { CloseButton } from '@components/Modal/ModalComparators/shared/CloseButton';
import { TabButton } from '@components/Modal/ModalComparators/shared/TabButton';
import { getCategoriesArrowColor } from '@components/Modal/ModalComparators/shared/helpers';

import PriceSuffix from '@components/PriceSuffix';
import Button from '@components/Button';

import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { isBrandAC, isBrandDS, isBrandOV, isMarketGB, ModalVersion } from 'src/constants/main';
import { IState } from '@components/Modal/ModalTemplate';
import Select from '@components/Select';
import Icon, { Icons } from '@components/Icon';
import { getFuelTypeByCode } from '@utils/Fuel.utils';

export const TESTING_PREFIX = 'engine-comparator-';

interface IModalEngineCompare {
    motorizations: IFeature[];
    disableSelection?: boolean;
    displayBackBtn?: boolean;
    onSubmit: (motorization: IFeature) => void;
    setModal: Dispatch<SetStateAction<IState>>;
    modal: IState;
}

interface IEngineComparatorRowTitleObject {
    key: string;
    label: string;
    start?: number;
    end?: number;
}

const { ScrollSync, ScrollSyncPane } = {
    ScrollSync: dynamic<ScrollSyncProps>(() => import('react-scroll-sync').then((mod) => mod.ScrollSync), {
        ssr: false,
    }),
    ScrollSyncPane: dynamic<ScrollSyncPaneProps>(() => import('react-scroll-sync').then((mod) => mod.ScrollSyncPane), {
        ssr: false,
    }),
};

const NAMES_ROW_ID = 'engineNameRowId';
const BUTTONS_ROW_ID = 'engineButtonRowId';

export const ModalEngineCompare: React.FC<IModalEngineCompare> = ({
    motorizations,
    onSubmit,
    disableSelection,
    displayBackBtn,
}) => {
    const dispatch = useDispatch();
    const { t, hasValue } = useTranslations();
    const { getNotEscapedTranslationMarkup } = useGetNotEscapedTranslationMarkup();
    const { formatMoney } = useMoneyFormatter();
    const { getOptionMonthlyPrice } = useAccessoryMonthlyPrices();

    const [engineNameRowHeight, setEngineNameRowHeight] = useState(74);
    const [buttonsRowShadow, setButtonsRowShadow] = useState(false);
    const [tabsHeight, setTabsHeight] = useState<number>(null);

    const namesRef = useRef(null);
    const buttonsRef = useRef(null);

    const { carDetails } = useCarDetailsDuck();

    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);
    const isVisibleCO2Images = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_CO2_IMAGE);
    const count = motorizations.length;

    const engineNameRowId = document.getElementById(NAMES_ROW_ID);
    const engineButtonRowId = document.getElementById(BUTTONS_ROW_ID);

    const [selected, setSelected] = useState<string>(NAMES_ROW_ID);

    useEffect(() => {
        const calculateHeight = () => {
            const newHeight = engineNameRowId?.offsetHeight + engineButtonRowId?.offsetHeight;
            setTabsHeight(newHeight - 15);
        };

        const timeoutId = setTimeout(calculateHeight, 500);

        return () => clearTimeout(timeoutId);
    }, [carDetails, motorizations, engineNameRowId, engineButtonRowId]);

    const stickyHeaderHeight = useMemo(() => {
        if (engineNameRowId?.offsetHeight) {
            return engineNameRowId.offsetHeight + 77.5 + 42.09;
        }
        return 200;
    }, [engineNameRowId]);

    const scrollToSection = (sectionId?: string) => {
        const element = document?.getElementById('modal_wrapper');

        if (element && !sectionId) {
            element.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (element && sectionId) {
            const section = document.getElementById(sectionId);

            const yOffset = -stickyHeaderHeight;
            const y = section.getBoundingClientRect().top + element.scrollTop + yOffset;
            element.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    useLayoutEffect(() => {
        const element = document.getElementById('modal_wrapper');
        const sections = document.getElementsByClassName('scrollableSection');
        let previousSectionIndex = -1;

        const handleScroll = () => {
            const elementScrollTop = element.scrollTop;

            if (element.scrollHeight - element.clientHeight === element.scrollTop) {
                setSelected(sections[sections.length - 1].id);
                return;
            }

            for (let i = 0; i < sections.length; i++) {
                const section = sections[i] as HTMLDivElement;
                const sectionTop = section.offsetTop - 0.736 * stickyHeaderHeight;
                const sectionBottom = sectionTop + section.offsetHeight;
                const scrollThreshold = 200;

                if (
                    elementScrollTop >= sectionTop - scrollThreshold &&
                    elementScrollTop <= sectionBottom &&
                    selected !== sections[i].id
                ) {
                    setSelected(sections[i].id);
                }

                if (elementScrollTop < sectionTop && previousSectionIndex !== -1) {
                    setSelected(sections[previousSectionIndex].id);
                    break;
                }

                previousSectionIndex = i;
            }
        };

        element?.addEventListener('scroll', handleScroll);

        return () => {
            element?.removeEventListener('scroll', handleScroll);
        };
    }, [carDetails, stickyHeaderHeight, selected]);

    const getTechnicalSpecifications = (motorization: IFeature, key: string) => {
        const { technicalSpecifications } = motorization;
        return technicalSpecifications.find((spec) => spec.key === key)?.data ?? null;
    };

    const getTechnicalSpecificationsAll = (key: string) => {
        return motorizations.map((motorization) => getTechnicalSpecifications(motorization, key));
    };

    const getCategoryTitle = (key: string) => {
        return motorizations?.[0].technicalSpecifications.find((spec) => spec.key === key)?.category ?? '';
    };

    const getStartEnd = (specs: ITechnicalSpecificationsData[], label: string) => {
        const start = specs.findIndex((spec) => spec?.label === label) + 1;
        const end =
            specs.length -
            specs
                .slice(start - 1)
                .reverse()
                .findIndex((spec) => spec?.label === label);

        return { start, end };
    };

    const checkCategoryRowsNotEmpty = (firstKey: string, secondKey: string | string[]): boolean => {
        const localSecondKey = Array.isArray(secondKey) ? secondKey : [secondKey];
        const firstKeyData = getTechnicalSpecificationsAll(firstKey);

        return (
            firstKeyData?.some((motorizationFirstKey) =>
                motorizationFirstKey?.some(
                    (spec: ITechnicalSpecificationsData) => localSecondKey.includes(spec?.key) && spec?.value
                )
            ) ?? false
        );
    };

    const getCo2BoardImage = motorizations.map((item) => (item as unknown as { co2BoardImage: string }).co2BoardImage);

    const renderRow = (firstKey: string, secondKey: string | string[]) => {
        const localSecondKey = Array.isArray(secondKey) ? secondKey : [secondKey];
        const firstKeyData = getTechnicalSpecificationsAll(firstKey);
        const { isHybrid } = getFuelTypeByCode(carDetails.fuelCode);

        const specs =
            firstKeyData?.map((motorization) => motorization?.find((spec) => localSecondKey.includes(spec.key))) || [];

        const hasNonEmptySpecs = specs.some((spec) => spec?.value);
        if (!hasNonEmptySpecs) {
            return null;
        }

        const titles: IEngineComparatorRowTitleObject[] = specs.reduce(
            (acc: IEngineComparatorRowTitleObject[], spec) => {
                if (spec?.label && spec.label !== acc[acc.length - 1]?.label) {
                    const { start, end } = getStartEnd(specs, spec.label);
                    acc.push({
                        key: spec.key,
                        label: spec.label,
                        start,
                        end,
                    });
                }
                return acc;
            },
            []
        );

        return (
            <ScrollSyncPane key={`row-${firstKey}-${secondKey}`}>
                <Row items={count} hasTitle dark={!isBrandDS}>
                    {titles.map(({ key, label, start, end }) => (
                        <RowTitle start={start} end={end} key={`title-${start}-${end}`}>
                            {hasValue(`technicalFeatures.environmentalDetails.consumption.${key}`) ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(`technicalFeatures.environmentalDetails.consumption.${key}`),
                                    }}
                                />
                            ) : (
                                label
                            )}
                        </RowTitle>
                    ))}
                    {specs.map((spec, idx: number) => {
                        const value = spec?.value || t('configurator.engineComparator.notAvailable');
                        return (
                            <RowCell key={idx + 1} idx={idx + 1}>
                                {isHybrid ? spec?.local_value ?? value : value}
                            </RowCell>
                        );
                    })}
                </Row>
            </ScrollSyncPane>
        );
    };

    // Abomination warning!
    // react-scroll-sync has internal flaw using cloneNode on its children
    // That makes std use of refs impossible, since references are lost during cloning.
    // Therefore we're accessing targets via ids & queryselectors
    const getRowPointers = () => {
        const namesRow = document.querySelector(`#${NAMES_ROW_ID}`);
        const buttonsRow = document.querySelector(`#${BUTTONS_ROW_ID}`);

        // elements may not be ready yet
        if (!namesRow || !buttonsRow) {
            setTimeout(getRowPointers, 100);
            return;
        }

        namesRef.current = namesRow;
        buttonsRef.current = buttonsRow;

        // Let's give the browser some time to render nameRow correctly before reading it's height
        // it is probably just only a dev issue connected to disabled cache and/or jit compilation
        // as custom fonts and lack of styles are larger than unstyled fallback font
        setTimeout(() => setEngineNameRowHeight(namesRow.clientHeight - 10), 200);
    };

    let scrollDebounceTimeout: NodeJS.Timeout | number = null;
    const scrollHandlerDebounce = () => {
        clearTimeout(scrollDebounceTimeout as NodeJS.Timeout);
        scrollDebounceTimeout = setTimeout(() => scrollHandler(), 20);
    };

    const scrollHandler = () => {
        const { x, y } = buttonsRef.current?.getBoundingClientRect();
        const target = document.elementFromPoint(x, y);
        const overlap = target == namesRef.current;
        setButtonsRowShadow(overlap);
    };

    useEffect(() => {
        getRowPointers();

        const scrollWrap = document.querySelector('.modalWindow__wrap');
        scrollWrap?.addEventListener('scroll', scrollHandlerDebounce, { passive: true });

        return () => scrollWrap?.removeEventListener('scroll', scrollHandlerDebounce);
    }, []);

    const MOTORIZATION_KEY = '0001';
    const PERFORMANCE_KEY = '0005';
    const WLTP_KEY = '9111';

    const categories = useMemo(
        () =>
            [
                {
                    key: WLTP_KEY,
                    title: getCategoryTitle(WLTP_KEY),
                    rows: [
                        'consumptionCombined',
                        'co2Combined',
                        ['cycleLow', 'electricRange'],
                        'cycleMid',
                        'cycleHigh',
                        'cycleEhigh',
                    ],
                },
                {
                    key: PERFORMANCE_KEY,
                    title: getCategoryTitle(PERFORMANCE_KEY),
                    rows: ['0001', '0002'],
                },
                {
                    key: MOTORIZATION_KEY,
                    title: getCategoryTitle(MOTORIZATION_KEY),
                    rows: ['0001', '0004', '0005', '0006'],
                },
                {
                    key: '0006',
                    title: t('configurator.engineComparator.categoryTitle.capacity'),
                    rows: ['0005'],
                },
            ].filter(({ title, key, rows }) => title && checkCategoryRowsNotEmpty(key, rows.flat(1))),
        [motorizations]
    );

    const EnginePrice: React.FC<{ m: IFeature; enginePrice: IAccessoryMonthlyPrice }> = ({ m, enginePrice }) => {
        const moneyFormatter = isSOLEnabled
            ? getTotalPriceObjByType(m.pricesV2, IPriceV2Types.TOTAL_B2C_CASH)?.breakdown
                  .finalCustomerCashPriceOnTheRoad
            : getTotalPriceObjByType(m.pricesV2, IPriceV2Types.TOTAL_B2C_CASH)?.finalPriceInclTax;
        const formattedMoney = formatMoney(enginePrice.price || enginePrice.defaultPrice, true);

        return (
            <RowCell head>
                <div className="engine-title">{m.title}</div>
                <div className="engine-price">
                    {moneyFormatter}{' '}
                    {(enginePrice.price || enginePrice.defaultPrice) && (
                        <span>
                            <span className="or">{t('configurator.optionPrice.suffix')}</span> {formattedMoney}{' '}
                            <PriceSuffix isMonthlyPrice hideAsterisk />
                        </span>
                    )}
                </div>
            </RowCell>
        );
    };

    const Categories = () => {
        const categoryItems = [{ key: NAMES_ROW_ID, title: t('modal.features.keys.all') }, ...categories];

        const isDesktop = motorizations?.length >= 3;

        return (
            <CategoriesSection style={{ top: tabsHeight }}>
                {isDesktop && (
                    <div className="categories desktop">
                        {categoryItems.map(({ title, key }, k) => (
                            <TabButton
                                key={`${1 + k}`}
                                className={selected === key ? 'selected' : ''}
                                onClick={() => scrollToSection(key === NAMES_ROW_ID ? undefined : key)}
                            >
                                {title}
                                {isBrandAC && <div className="border" />}
                            </TabButton>
                        ))}
                    </div>
                )}
                <div data-testid={`${TESTING_PREFIX}filters`} className={`mobile ${isDesktop ? 'hideOnDesktop' : ''}`}>
                    <Select
                        value={{
                            value: selected,
                            label: categoryItems.find(({ key }) => key === selected)?.title,
                        }}
                        onChange={(selectedOption: { value: string; label: string }) =>
                            scrollToSection(selectedOption.value === NAMES_ROW_ID ? undefined : selectedOption.value)
                        }
                        options={categoryItems.map(({ title, key }) => ({
                            value: key,
                            label: title,
                        }))}
                        fullWidth
                        withBorder
                        arrowColor={getCategoriesArrowColor()}
                        isSearchable={false}
                    />
                </div>
            </CategoriesSection>
        );
    };

    const motorizationRows = motorizations.map((m, idx) => (
        <RowCell className="motorization" gearbox key={idx}>
            {m.fuel}
            <br />
            {m.gearboxTitle}
        </RowCell>
    ));

    const categoryRows = categories.map(({ key, title, rows }, categoryIndex) => (
        <Fragment key={key}>
            <CategoryTitle id={key} className="scrollableSection">
                {title}
            </CategoryTitle>
            {rows.map((secondKey) => renderRow(key, secondKey))}
            {isVisibleCO2Images && getCo2BoardImage.length && (
                <ScrollSyncPane>
                    <Row items={count}>
                        {title === getCategoryTitle(WLTP_KEY) && (
                            <>
                                <RowTitle>{t('engine.label.taxBand')}</RowTitle>
                                {getCo2BoardImage.map((item, i) => (
                                    <RowCell key={i + 1} idx={i + 1}>
                                        <img key={i} style={{ height: '200px' }} src={item} />
                                    </RowCell>
                                ))}
                            </>
                        )}
                    </Row>
                </ScrollSyncPane>
            )}
            {categories.map(({ key }) => key).length > 1 && categoryIndex !== categories.length - 1 && <Separator />}
        </Fragment>
    ));

    return (
        <Wrapper>
            <CloseButton
                className="closeButton"
                id="closeButton"
                onClick={() => dispatch(UIDuck.closeModal())}
                data-testid="TESTING_CLOSE_MODAL"
            >
                &#10005;
            </CloseButton>
            {displayBackBtn && (
                <button
                    className="backBtn"
                    onClick={() =>
                        dispatch(
                            UIDuck.openModal({
                                data: { carDetails },
                                callbacks: {},
                                modalType: UIDuck.MODAL_TYPES.FEATURES,
                                modalVersion: ModalVersion.v2,
                            })
                        )
                    }
                >
                    <Icon name={Icons.chevronLeftV2} width={45} height={45} />
                </button>
            )}
            <Title>{t('configurator.engineComparator.title')}</Title>
            <div className="gridWrap">
                <ScrollSync>
                    <div className="grid">
                        <ScrollSyncPane>
                            <Row className="scrollableSection" items={count} sticky={0} id={NAMES_ROW_ID}>
                                {motorizations.map((m, idx) => {
                                    const enginePrice = getOptionMonthlyPrice(m);
                                    return <EnginePrice m={m} enginePrice={enginePrice} key={idx} />;
                                })}
                            </Row>
                        </ScrollSyncPane>
                        <ScrollSyncPane>
                            <Row items={count} dark={!isBrandDS}>
                                {motorizationRows}
                            </Row>
                        </ScrollSyncPane>
                        {renderRow(WLTP_KEY, 'consumptionCombined')}
                        {renderRow(WLTP_KEY, 'co2Combined')}
                        {renderRow(MOTORIZATION_KEY, '0004')}
                        <ScrollSyncPane>
                            <Row
                                items={count}
                                sticky={engineNameRowHeight}
                                shadow={buttonsRowShadow}
                                id={BUTTONS_ROW_ID}
                            >
                                {!disableSelection &&
                                    motorizations.map((m, idx) => (
                                        <RowCell key={idx}>
                                            <Button
                                                className="select-btn"
                                                primary={!(isBrandOV && isMarketGB)}
                                                secondary={isBrandOV && isMarketGB}
                                                onClick={() => onSubmit(m)}
                                            >
                                                {t('configurator.select')}
                                            </Button>
                                        </RowCell>
                                    ))}
                            </Row>
                        </ScrollSyncPane>
                        {!!categories.length && <Categories />}
                        {categoryRows}
                    </div>
                </ScrollSync>
            </div>
            <Separator large />
            <LegalMentions
                dangerouslySetInnerHTML={getNotEscapedTranslationMarkup('configurator.engineComparator.legalText')}
            />
        </Wrapper>
    );
};
