import React from 'react';
import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalShareOfferSummaryStyledAP } from './AP/ModalShareOfferSummaryStyled.AP';
import { ModalShareOfferSummaryStyledDS } from './DS/ModalShareOfferSummaryStyled.DS';
import { ModalShareOfferSummaryStyledAC } from './AC/ModalShareOfferSummaryStyled.AC';
import { ModalShareOfferSummaryStyledOV } from './OV/ModalShareOfferSummaryStyled.OV';
import { ModalShareOfferSummaryStyledOVGB } from './OV/GB/ModalShareOfferSummaryStyled.OV';
import { ModalShareOfferSummaryStyled } from './ModalShareOfferSummaryStyled';

export const ModalShareOfferSummary = (props: any) => {
    if (BRAND === 'AP') return <ModalShareOfferSummaryStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalShareOfferSummaryStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalShareOfferSummaryStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalShareOfferSummaryStyledOVGB {...props} /> : <ModalShareOfferSummaryStyledOV {...props} />;
    return <ModalShareOfferSummaryStyled {...props} />;
};

export default ModalShareOfferSummary;
