import styled from 'styled-components';
import { css } from '../../styles/theme';
import { FilterButtonProps } from '.';
import rem from 'polished/lib/helpers/rem';

export const FilterButtonStyled = styled.button<FilterButtonProps>`
    display: inline-block;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.4rem;
    margin: 0.5rem 0.5rem 0 0;
    background: transparent;
    transition: 0.1s ease;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors.grey4};
    color: ${({ theme }) => theme.colors.grey2};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${({ theme }) => theme.lineHeights.textBase};
    min-height: 3rem;
    min-width: ${rem('96px')};
    max-height: ${rem('52px')};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `}

    ${({ active, theme }) =>
        active &&
        css`
            border-color: ${theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `};

    ${({ isMobile, theme }) =>
        isMobile
            ? css`
                  :active {
                      border-color: ${({ theme }) => theme.colors.primary};
                      color: ${({ theme }) => theme.colors.primary};
                  }
              `
            : css`
                  :hover,
                  :focus-visible {
                      border-color: ${({ theme }) => theme.colors.primary};
                      color: ${({ theme }) => theme.colors.primary};
                  }
              `};
`;
