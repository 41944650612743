import styled from 'styled-components';
import LoadingButtonTemplate from './LoadingButtonTemplate';

export const LoadingButtonStyled = styled(LoadingButtonTemplate)`
    position: relative;
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};

    .loading {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
`;
