import sanitize from 'sanitize-html';
import { useTranslations } from '@hooks/useTranslations';

export function useGetNotEscapedTranslationMarkup() {
    const { t } = useTranslations();

    const getNotEscapedTranslationMarkup = (translationKey: string) => {
        return {
            __html: sanitize(t(translationKey, { interpolation: { escapeValue: false } })),
        };
    };

    return { getNotEscapedTranslationMarkup };
}
