import { useSelector } from 'react-redux';
import UIDuck from '../redux/commonDucks/ui.duck';

export const useUIDuck = () => {
    const ui = useSelector((state) => UIDuck.getOwnState(state));
    const isModalOpen = useSelector((state) => UIDuck.isModalOpen(state));
    const isLoading = useSelector((state) => !!UIDuck.getOwnState(state).loading);
    const isOptionsLoading = useSelector((state) => !!UIDuck.getOwnState(state).optionsLoading);
    const showBasketInHeader = useSelector((state) => !!UIDuck.getOwnState(state).showBasketInHeader);
    const modalData = useSelector((state) => UIDuck.getModalData(state));
    const modalCallbacks = useSelector((state) => UIDuck.getModalCallbacks(state));
    const modalType = useSelector((state) => UIDuck.getModalType(state));
    const characteristicsOpen = useSelector((state) => UIDuck.areCharacteristicsOpen(state));
    const isLoadingFullScreen = useSelector((state) => UIDuck.isLoadingFullscreen(state));
    const carOptionsAccordionState = useSelector((state) => UIDuck.getCarOptionsAccordionState(state));
    const carteGriseIsLoading = useSelector((state) => UIDuck.getOwnState(state).carteGriseLoading);
    const isLoadingNow = useSelector((state) => UIDuck.getOwnState(state).isLoadingNow);
    const isNoCarError = useSelector((state) => !!UIDuck.getOwnState(state).isNoCarError);

    return {
        ui,
        isModalOpen,
        modalData,
        modalCallbacks,
        modalType,
        isLoading,
        isOptionsLoading,
        showBasketInHeader,
        isLoadingFullScreen,
        carOptionsAccordionState,
        characteristicsOpen,
        carteGriseIsLoading,
        isLoadingNow,
        isNoCarError,
    };
};
