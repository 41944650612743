import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import PartExchangeTemplate from './PartExchangeTemplate';
import { rem } from 'polished';

export const PartExchangeStyled = styled(PartExchangeTemplate)`
    padding-bottom: ${rem('10px')};
    .autobiz-info {
        width: 100%;
        &-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-size: ${rem('14px')};
                margin: ${rem('6px')} 0;
                line-height: ${rem('20px')};
            }
        }
    }

    .autobiz-description {
        color: ${({ theme }) => theme.colors.grey1};
        font-size: ${rem('12px')};
        padding: ${rem('10px')} 0;

        p {
            margin: 0;
        }
    }
`;
