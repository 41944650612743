import { MouseEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useConfiguratorDuck } from '@hooks/useConfiguratorDuck';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useGTM } from '@hooks/useGTM';
import { useUILoadingOnConfigurator } from '@hooks/useUILoadingOnConfigurator';
import { ModalVersion } from '../constants/main';
import ConfiguratorDuck from 'src/redux/configurator/configurator.duck';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { usePlausible, UserEvent } from '@hooks/usePlausible';
import { useRouter } from 'next/router';
import Routes from '../constants/routes';

export const useCompareEngines = (disableSelection?: boolean, displayBackBtn?: boolean) => {
    const dispatch = useDispatch();
    const { motorizations } = useConfiguratorDuck();
    const router = useRouter();
    const { carDetails } = useCarDetailsDuck();
    const { pushUserAction, pushToDataLayer } = useGTM();
    const { plausibleUserEvent } = usePlausible();
    const { uiIsLoading } = useUILoadingOnConfigurator();

    const onClickEngineComparator = useCallback(
        (motorisation) => {
            if (!uiIsLoading) {
                pushUserAction({
                    action: 'Choose::Motorization',
                    eventName: 'uaevent',
                    label: motorisation.title,
                });
                dispatch(ConfiguratorDuck.setMotorization(motorisation));
            }
        },
        [carDetails, motorizations, uiIsLoading, dispatch]
    );

    const openEngineModal: MouseEventHandler = () => {
        plausibleUserEvent(
            router?.pathname?.includes(Routes.CAR)
                ? UserEvent.EngineComparatorOpenFromConfigPage
                : UserEvent.EngineComparatorOpenFromFeatures
        );
        pushToDataLayer({
            event: 'uaevent',
            eventCategory: 'Content',
            eventAction: 'Display:Comparator',
            eventLabel: 'Trim',
        });
        dispatch(
            UIDuck.openModal({
                data: { motorizations, disableSelection, displayBackBtn },
                callbacks: { onClick: (status: string, ...args) => onClickEngineComparator(args[0]) },
                modalType: UIDuck.MODAL_TYPES.ENGINE_COMPARE,
                modalVersion: ModalVersion.v2,
                modalProperties: {
                    withoutPadding: true,
                    fullWidthOnDesktop: motorizations?.length >= 4,
                    middleWidthOnDesktop: motorizations?.length === 3,
                },
            })
        );
    };

    return {
        openEngineModal,
        onClickEngineComparator,
    };
};
