import { useCallback } from 'react';
import { useCarStockJourneyCheck } from 'src/hooks/useCarStockJourneyCheck';
import { useJourneyType } from 'src/hooks/useJourneyType';
import { useTranslations } from 'src/hooks/useTranslations';

export const useTranslationByAllowedJourney = () => {
    const { t } = useTranslations();
    const { isStockJourney } = useCarStockJourneyCheck();
    const { paymentJourneyType } = useJourneyType();

    const translationByAllowedJourney = useCallback(
        (key: string, boxNumber: number = null, ec = '') => {
            let basePath = `configurator.interestedBox${boxNumber ? boxNumber : ''}`;

            if (isStockJourney) {
                basePath = `${basePath}.stock`;
            }

            const fullPath = `${basePath}.${key}.${paymentJourneyType}${ec}`;

            switch (fullPath) {
                case 'configurator.interestedBox.stock.title.cash':
                    return t('configurator.interestedBox.stock.title.cash');
                case 'configurator.interestedBox.stock.title.finance':
                    return t('configurator.interestedBox.stock.title.finance');
                case 'configurator.interestedBox.title.cash':
                    return t('configurator.interestedBox.title.cash');
                case 'configurator.interestedBox.title.finance':
                    return t('configurator.interestedBox.title.finance');
                case 'configurator.interestedBox1.link.cash':
                    return t('configurator.interestedBox1.link.cash');
                case 'configurator.interestedBox1.link.finance':
                    return t('configurator.interestedBox1.link.finance');
                case 'configurator.interestedBox1.stock.link.cash':
                    return t('configurator.interestedBox1.stock.link.cash');
                case 'configurator.interestedBox1.stock.link.finance':
                    return t('configurator.interestedBox1.stock.link.finance');
                case 'configurator.interestedBox1.stock.title.cash':
                    return t('configurator.interestedBox1.stock.title.cash');
                case 'configurator.interestedBox1.stock.title.finance':
                    return t('configurator.interestedBox1.stock.title.finance');
                case 'configurator.interestedBox1.stock.titleDescription.cash':
                    return t('configurator.interestedBox1.stock.titleDescription.cash');
                case 'configurator.interestedBox1.stock.titleDescription.finance':
                    return t('configurator.interestedBox1.stock.titleDescription.finance');
                case 'configurator.interestedBox1.title.cash':
                    return t('configurator.interestedBox1.title.cash');
                case 'configurator.interestedBox1.title.finance':
                    return t('configurator.interestedBox1.title.finance');
                case 'configurator.interestedBox1.titleDescription.cash':
                    return t('configurator.interestedBox1.titleDescription.cash');
                case 'configurator.interestedBox1.titleDescription.finance':
                    return t('configurator.interestedBox1.titleDescription.finance');
                case 'configurator.interestedBox2.link.cash':
                    return t('configurator.interestedBox2.link.cash');
                case 'configurator.interestedBox2.link.finance':
                    return t('configurator.interestedBox2.link.finance');
                case 'configurator.interestedBox2.savedLink':
                    return t('configurator.interestedBox2.savedLink');
                case 'configurator.interestedBox2.savedLink.cash':
                    return t('configurator.interestedBox2.savedLink.cash');
                case 'configurator.interestedBox2.savedLink.finance':
                    return t('configurator.interestedBox2.savedLink.finance');
                case 'configurator.interestedBox2.savedTitleDescription.cash':
                    return t('configurator.interestedBox2.savedTitleDescription.cash');
                case 'configurator.interestedBox2.savedTitleDescription.finance':
                    return t('configurator.interestedBox2.savedTitleDescription.finance');
                case 'configurator.interestedBox2.stock.link.cash':
                    return t('configurator.interestedBox2.stock.link.cash');
                case 'configurator.interestedBox2.stock.link.finance':
                    return t('configurator.interestedBox2.stock.link.finance');
                case 'configurator.interestedBox2.stock.savedLink':
                    return t('configurator.interestedBox2.stock.savedLink');
                case 'configurator.interestedBox2.stock.savedLink.cash':
                    return t('configurator.interestedBox2.stock.savedLink.cash');
                case 'configurator.interestedBox2.stock.savedLink.finance':
                    return t('configurator.interestedBox2.stock.savedLink.finance');
                case 'configurator.interestedBox2.stock.savedTitleDescription.cash':
                    return t('configurator.interestedBox2.stock.savedTitleDescription.cash');
                case 'configurator.interestedBox2.stock.savedTitleDescription.finance':
                    return t('configurator.interestedBox2.stock.savedTitleDescription.finance');
                case 'configurator.interestedBox2.stock.title.cash':
                    return t('configurator.interestedBox2.stock.title.cash');
                case 'configurator.interestedBox2.stock.title.finance':
                    return t('configurator.interestedBox2.stock.title.finance');
                case 'configurator.interestedBox2.stock.titleDescription.cash':
                    return t('configurator.interestedBox2.stock.titleDescription.cash');
                case 'configurator.interestedBox2.stock.titleDescription.finance':
                    return t('configurator.interestedBox2.stock.titleDescription.finance');
                case 'configurator.interestedBox2.title.cash':
                    return t('configurator.interestedBox2.title.cash');
                case 'configurator.interestedBox2.title.finance':
                    return t('configurator.interestedBox2.title.finance');
                case 'configurator.interestedBox2.titleDescription.cash':
                    return t('configurator.interestedBox2.titleDescription.cash');
                case 'configurator.interestedBox2.titleDescription.finance':
                    return t('configurator.interestedBox2.titleDescription.finance');
                case 'configurator.interestedBox3.link':
                    return t('configurator.interestedBox3.link');
                case 'configurator.interestedBox3.link.cash':
                    return t('configurator.interestedBox3.link.cash');
                case 'configurator.interestedBox3.link.finance':
                    return t('configurator.interestedBox3.link.finance');
                case 'configurator.interestedBox3.link.finance.b2c':
                    return t('configurator.interestedBox3.link.finance.b2c');
                case 'configurator.interestedBox3.stock.link.finance.b2c':
                    return t('configurator.interestedBox3.stock.link.finance');
                case 'configurator.interestedBox3.stock.link.cash.b2c':
                    return t('configurator.interestedBox3.stock.link.cash');
                case 'configurator.interestedBox3.link.cash.b2c':
                    return t('configurator.interestedBox3.link.cash.b2c');
                case 'configurator.interestedBox3.stock.link':
                    return t('configurator.interestedBox3.stock.link');
                case 'configurator.interestedBox3.stock.link.cash':
                    return t('configurator.interestedBox3.stock.link.cash');
                case 'configurator.interestedBox3.stock.link.finance':
                    return t('configurator.interestedBox3.stock.link.finance');
                case 'configurator.interestedBox3.stock.title.cash':
                    return t('configurator.interestedBox3.stock.title.cash');
                case 'configurator.interestedBox3.stock.title.finance':
                    return t('configurator.interestedBox3.stock.title.finance');
                case 'configurator.interestedBox3.stock.titleDescription.cash':
                    return t('configurator.interestedBox3.stock.titleDescription.cash');
                case 'configurator.interestedBox3.stock.titleDescription.finance':
                    return t('configurator.interestedBox3.stock.titleDescription.finance');
                case 'configurator.interestedBox3.title.cash':
                    return t('configurator.interestedBox3.title.cash');
                case 'configurator.interestedBox3.title.finance':
                    return t('configurator.interestedBox3.title.finance');
                case 'configurator.interestedBox3.titleDescription.cash':
                    return t('configurator.interestedBox3.titleDescription.cash');
                case 'configurator.interestedBox3.titleDescription.finance':
                    return t('configurator.interestedBox3.titleDescription.finance');
                case 'configurator.interestedBox3.title.dealerLayer':
                    return t('configurator.interestedBox3.title.dealerLayer');
                case 'configurator.interestedBox3.titleDescription.dealerLayer':
                    return t('configurator.interestedBox3.titleDescription.dealerLayer');
                case 'configurator.interestedBox3.link.dealerLayer':
                    return t('configurator.interestedBox3.link.dealerLayer');
                case 'configurator.interestedBox3.link.dealerLayer.b2c':
                    return t('configurator.interestedBox3.link.dealerLayer.b2c');
                default:
                    return fullPath;
            }
        },
        [t, isStockJourney, paymentJourneyType]
    );

    return { translationByAllowedJourney };
};
