import styled from 'styled-components';
import { FooterStyled } from '../FooterStyled';
import { rem } from 'polished';
import { defaultBreakpoints } from '../../../styles/theme';
import { breakpoints } from '../../../styles/theme';

export const FooterStyledAC = styled(FooterStyled)`
    .orderNowSection {
        color: ${({ theme }) => theme.colors.grey5};
        padding-top: ${rem(61)};
        padding-bottom: ${rem(41)};

        .orderNowTitle {
            font-size: ${rem(22)};
            line-height: 1.2;
            color: ${({ theme }) => theme.colors.grey5};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            padding-bottom: ${rem(30)};
        }

        .itemsWrapper {
            justify-content: center;

            .footerItem {

                .textWrap {
                    font-size: ${({ theme }) => theme.fontSizes.h4};
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    display: flex;
                    align-items: baseline;
                    color: ${({ theme }) => theme.colors.grey5};
                }

                @media (min-width: ${defaultBreakpoints.md}px) {
                    flex-direction: column-reverse;
                    border-right: none;
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    max-width: ${rem(260)};

                    :first-child {
                        .textWrap {
                            padding: 0 ${rem(40)};
                            display: block;
                        }
                    }

                    :nth-child(3) {
                        padding: 0 ${rem(20)};
                    }
                }

                .imageWrap {
                    svg {
                        rect {
                            stroke: ${({ theme }) => theme.colors.grey1};
                        }
                        path {
                            fill: ${({ theme }) => theme.colors.blue2} !important;
                        }
                    }
                }
            }
        }
    }

    .footer {
        background: ${({ theme }) => theme.colors.white};
        padding: ${rem(29)} 0 ${rem(39)};
        border-top: 1px solid ${({ theme }) => theme.colors.grey8};

        ul {
            li {
                line-height: normal;
                border: none;
                font-family: ${({ theme }) => theme.fonts.fontBase};
                text-transform: capitalize;
                
                a {
                    font-size: ${rem(12)};   
                    color: ${({ theme }) => theme.colors.grey5};
                    
                    &:hover {
                        color: ${({ theme }) => theme.colors.red};
                    }
                }

                @media (min-width: ${breakpoints.sm}px) {
                    position: relative;

                    &:not(:last-child) {
                        &::after {
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 0;
                            width: ${rem(1)};
                            height: 80%;
                            top: 50%;
                            transform: translateY(-40%);
                            background: ${({ theme }) => theme.colors.grey5};
                        }
                    }
                }
            }
        }

        .logo {
            display: block;
            svg {
                width: ${rem(100)};
                height: ${rem(96)};
                path {
                    fill: ${({ theme }) => theme.colors.grey5} !important;
                }
            }
            margin-bottom: ${rem(34)};
        }
    }

    .footerScrollToTopAnchor {
        font-size: ${({ theme }) => theme.fontSizes.h3};

        .icon-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        svg {
            path {
                stroke: ${({ theme }) => theme.colors.red};
            }
        }
    }
    .cookiesPreferences {
        text-transform: uppercase;
    }
    .scrollToTopIcon {
        background: ${({ theme }) => theme.colors.blue2};
        
        &:hover {
            background: ${({ theme }) => theme.colors.grey5};
        }
    }
`;
