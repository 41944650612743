import styled from 'styled-components';
import { MarketingStickerStyled } from '../MarketingStickerStyled';
import { rem } from 'polished';

export const MarketingStickerStyledAC = styled(MarketingStickerStyled)`
    background: rgba(87, 113, 138, 0.2);
    color: ${({ theme }) => theme.colors.grey5};
    border-radius: ${rem(4)};
    
    .infoIcon {
        svg {
            circle {
                fill: ${({ theme }) => theme.colors.black};
                stroke: ${({ theme }) => theme.colors.black};
            }
               
            g {            
                line {
                    stroke: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }
`;
