import styled from 'styled-components';
import { SelectStyled } from '../SelectStyled';

export const SelectStyledAC = styled(SelectStyled)`
    .Select__option--is-focused {
        background-color: ${props => props.theme.colors.blue2};
        color: ${props => props.theme.colors.white};
    }

    .Select__option:active {
        background-color: ${props => props.theme.colors.blue2};
    }

    .Select__option--is-selected {
        background-color: ${props => props.theme.colors.blue2};
    }

    .Select__control {
        ${({ withBorder }) =>
            withBorder && `
                border-radius: 12px;
        `}
    }
`;
