import React, { FC, useCallback } from 'react';
import { RedirectionModalProps } from '.';
import Button from '../Button';
import { Row, Col } from 'react-styled-flexboxgrid';
import useRouter from '@hooks/useCustomRouter';
import useTranslations from '@hooks/useTranslations';
import { useDispatch } from 'react-redux';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { isBrandOV, isMarketGB } from '../../constants/main';
import { Icon, Icons } from '../Icon';

const RedirectionModalTemplate: FC<RedirectionModalProps> = ({ className, redirectTo = '/' }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTranslations();

    const redirectToLogin = useCallback(() => {
        dispatch(UIDuck.resetLoading());
        router.push(redirectTo);
    }, [dispatch, router, redirectTo]);

    return (
        <div className={className}>
            <div className="messageContainer">
                <Row className="messageContent">
                    <Col className="container">
                        {isBrandOV && !isMarketGB && (
                            <div className="error-icon">
                                <Icon name={Icons.Alert} width={100} height={100} />
                            </div>
                        )}
                        <p data-testid="TESTING_EMPTY_BASKET_TEXT">{t('basket.empty.message')}</p>
                        <div className="messageButton">
                            <Button
                                type="button"
                                className="iconButton"
                                data-testid="TESTING_EMPTY_BASKET"
                                onClick={redirectToLogin}
                                primary
                            >
                                {t('basket.empty.chooseCar')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RedirectionModalTemplate;
