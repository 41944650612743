import styled from 'styled-components';
import { ModalShareConfigurationPersonalizeOfferStyled } from '../ModalShareConfigurationPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalShareConfigurationPersonalizeOfferStyledAC = styled(ModalShareConfigurationPersonalizeOfferStyled)`
    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.grey2};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
                background: ${({ theme }) => theme.colors.blue2};
            }

            > .civility {
                color: ${({ theme }) => theme.colors.grey5};
            }
        }
    }
    .checkboxSection {
        button {
            color: ${({ theme }) => theme.colors.grey5};
        }
    }
    .share-wrapper {
        .mandatory {
            color: ${({ theme }) => theme.colors.grey1};
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        .input-wrapper {
            .input-section {
                .section-header {
                    font-size: ${(props) => props.theme.fontSizes.h4};
                    color: ${({ theme }) => theme.colors.grey5};
                }
            }

            .dropdown,
            input {
                border-color: ${(props) => props.theme.colors.grey3};
                border-radius: ${rem(12)};
                font-size: ${(props) => props.theme.fontSizes.h4};

                .Select__indicators {
                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.blue2};
                        }
                    }
                }
            }
        }
    }

    .react-datepicker__day--selected {
        background: ${(props) => props.theme.colors.blue2};
    }
`;
