import styled from 'styled-components';
import { css } from '../../styles/theme';
import { rem } from 'polished';

export interface IButtonStyles {
    primary?: boolean;
    secondary?: boolean;
    withoutBackground?: boolean;
    disabled?: boolean;
    id?: string;
    fullWidth?: boolean;
    fitContentWidth?: boolean;
    centerMode?: boolean;
    marginTop?: number;
    marginBottom?: number;
    hasEditIcon?: boolean;
}

export const ButtonStyles = css<IButtonStyles>`
    border: none;
    cursor: pointer;
    position: relative;

    ${({ centerMode }) =>
        centerMode &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        `}

    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: ${rem(marginTop)};
        `}

    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${rem(marginBottom)};
        `}

    ${({ hasEditIcon }) =>
        hasEditIcon &&
        css`
            padding-left: ${rem(40)} !important;

            svg {
                position: absolute;
                width: ${rem(30)} !important;
                height: ${rem(30)} !important;
                left: ${rem(4)};
                top: 15%;
            }
        `}

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}

    ${({ fitContentWidth }) =>
        fitContentWidth &&
        css`
            width: auto;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;
            pointer-events: none;
        `}
`;

export const ButtonStyled = styled.button<IButtonStyles>`
    ${ButtonStyles}
`;
