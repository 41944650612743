import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import DealerDuck from '../../../redux/dealer/dealer.duck';
import { IDealerDTO } from '../../../redux/dealer/dealer.duck.interface';
import { NETWORK_STATUS_START, NETWORK_STATUS_ERROR, Redux } from '../../../redux/redux.interface';
import DealerInfo from '../../CheckoutDealerInfo';
import { SC, theme } from '../../../styles/theme';
import Spinner from '../../Spinner';

interface IDealerInfoWrapper extends SC {
    id: string;
    dealers: IDealerDTO[];
    fetchDealerToList: (id: string) => void;
}

const DealerInfoWrapper: FC<IDealerInfoWrapper> = ({ dealers, fetchDealerToList, id }: IDealerInfoWrapper) => {
    const dealerDTO: IDealerDTO = dealers.find((dealer: IDealerDTO) => dealer.id === id);

    useEffect(() => {
        if (!dealerDTO) {
            fetchDealerToList(id);
        }
    }, [dealerDTO, fetchDealerToList, id]);

    if (!dealerDTO || dealerDTO.status === NETWORK_STATUS_START) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner size={50} border={5} color={theme.colors.grey10} />
            </div>
        );
    }

    if (dealerDTO.status === NETWORK_STATUS_ERROR) {
        return <div>FAILED TO LOAD DEALER</div>;
    }

    return <DealerInfo dealer={dealerDTO.dealer} />;
};

const mapStateToProps = (state: Redux) => ({
    dealers: DealerDuck.getOwnState(state).dealers,
});

const mapDispatchToProps = { fetchDealerToList: DealerDuck.fetchDealerToList };

export default connect(mapStateToProps, mapDispatchToProps)(DealerInfoWrapper);
