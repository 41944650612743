import styled from 'styled-components';
import { GeoLocationInputStyled } from '../GeoLocationInputStyled';
import { rem } from 'polished';

export const GeoLocationInputStyledOV = styled(GeoLocationInputStyled)`
    .geolocation-title {
        font-weight: bold;
    }

    .Select__control {
        background: ${({ theme }) => theme.colors.black} !important;
        border: 1px solid ${({ theme }) => theme.colors.black} !important;
    }

    .geolocation-input--container {
        .geolocation-input--textfield-container {
            .geolocation-input--textfield-icon {
                color: ${({ theme }) => theme.colors.black};
            }

            .dropDown ul li {
                font-size: ${({ theme }) => theme.fontSizes.h5};
                padding: ${rem(10)};
                background: ${({ theme }) => theme.colors.pureWhite};

                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.grey18};
                }
            }
        }
    }
`;