import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslations from '@hooks/useTranslations';
import { IModalPostalCode } from './index';
import LocationInput from '@components/FilterBox/Components/LocationInput';
import { Redux } from 'src/redux/redux.interface';
import FilterDuck from 'src/redux/filters/filter.duck';
import { modifySortFilter } from 'src/services/filters/utils/Filters.utils';
import { SortOrder, SortType } from 'src/services/stock/models/stockItems.service.model';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { addressAutocomplete } from '@utils/Address.utils';
import Select, { components } from 'react-select';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { ISelectOption } from '@components/GeoLocationInput/GeoLocationInputTemplate';
import { createOption, generateOptions } from '@components/GeoLocationInput/utils';
import { DEFAULT_DISTANCE_OPTIONS_STEP, DISTANCE_OPTIONS, ParametrizedPathRegExp } from '../../../constants/main';
import { theme } from '../../../styles/theme';
import Spinner from '@components/Spinner';
import { CarJourneyType, dealerApi } from '../../../services';
import { IDealer } from '../../../interfaces/Dealer';
import DealerDuck from '../../../redux/dealer/dealer.duck';
import { buildParameterizedPathname } from '@utils/url.utils';
import Routes from '../../../constants/routes';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';
import routes from '../../../constants/routes';
import TrimSelectorDuck from '../../../redux/trimSelector/trimSelector.duck';
import OfferListDuck from '../../../redux/carList/offerlist.duck';
import { useRouter } from 'next/router';
import { isNumber } from 'lodash';
import { useUserDuck } from '@hooks/useUserDuck';
import { createTimeoutPromise } from '@utils/timeoutPromise';
import { useGlobalDuck } from '@hooks/useGlobalDuck';
import { EUserLoginType } from 'src/interfaces/User';

const SECTION_ID = 'location';

export const DEALER_ID_IN_URL_ID = 'idsitegeo';

const DEFAULT_DISTANCE_STEP = DEFAULT_DISTANCE_OPTIONS_STEP;

export enum SEARCH_TYPES {
    LOCATION = 'location',
    DEALER = 'dealer',
}

export const ModalPostalCodeTemplate = ({ className, searchType }: IModalPostalCode) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();
    const router = useRouter();
    const { userAuth } = useUserDuck();
    const filters = useSelector((state: Redux) => FilterDuck.getFilters(state));
    const { carJourney } = useGlobalDuck();
    const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
    const distanceRadiusValue = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));
    const selectedDealerId = useSelector((state: Redux) => FilterDuck.getOwnState(state).dealerId);
    const dealer = useSelector((state: Redux) => DealerDuck.getOwnState(state).dealer);
    const isRouting = useSelector((state: Redux) => UIDuck.getIsRouting(state));
    const stockTrimPageVehiclesCount = useSelector(
        (state: Redux) => TrimSelectorDuck.getOwnState(state).stock.totalFilteredItems
    );
    const stockHomePageVehiclesCount = useSelector((state: Redux) =>
        OfferListDuck.getStockMatchingVehiclesCount(state)
    );

    const isLocalizationEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_FORCE_LOCALIZATION_ENABLED);

    const [selectedSearchType, setSelectedSearchType] = useState(searchType || SEARCH_TYPES.LOCATION);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDealer, setSelectedDealer] = useState(null);
    const [dealers, setDealers] = useState(null);
    const [userInput, setUserInput] = useState(null);
    const [showRequiredError, setShowRequiredError] = useState(false);
    const [showInvalidError, setShowInvalidError] = useState(false);
    const [distanceRadius, setDistanceRadius] = useState(
        createOption(isNumber(distanceRadiusValue) ? distanceRadiusValue : DEFAULT_DISTANCE_STEP, t)
    );

    const formattedPathname = buildParameterizedPathname(router.asPath.replace(ParametrizedPathRegExp, ''), carJourney);

    const parseDealerIdFromUrl = () => {
        const dealerIdIndexInUrl = window.location.search.indexOf(DEALER_ID_IN_URL_ID);

        return window.location.search.slice(dealerIdIndexInUrl + 10, dealerIdIndexInUrl + 20);
    };

    const formatDealerNameToSelect = (dealer: IDealer) => {
        return `${dealer?.city} ${
            geoLocation
                ? `(${(dealer?.distance / 1000).toFixed(2)} ${t('checkout.delivery.dealer.item.distance')})`
                : ''
        } - ${dealer?.name}`;
    };

    const handleSelectDealer = (dealer: IDealer) => {
        setSelectedDealer(dealer);
        dispatch(DealerDuck.setDealer(dealer));
        dispatch(FilterDuck.setDealerId(dealer.externalId));
    };

    const handleRemoveDealer = () => {
        const dealerId = parseDealerIdFromUrl();

        const queryStringWithoutDealerId = window.location.search
            .replace(`&${DEALER_ID_IN_URL_ID}=${dealerId}`, '')
            .replace(`?${DEALER_ID_IN_URL_ID}=${dealerId}&`, '?');

        setSelectedDealer(null);

        dispatch(DealerDuck.setDealer(null));

        const query: Record<string, string> = {};
        new URLSearchParams(queryStringWithoutDealerId).forEach((value, key) => (query[key] = value));
        router.replace({
            pathname: formattedPathname,
            query,
        });
    };

    useEffect(() => {
        if (!selectedDealerId) {
            dispatch(DealerDuck.setDealer(null));
            setSelectedDealer(null);
        }
    }, [selectedDealerId]);

    useEffect(() => {
        if (window.location.search.includes(DEALER_ID_IN_URL_ID) && selectedSearchType === SEARCH_TYPES.LOCATION) {
            handleRemoveDealer();
        }

        if (window.location.search.includes(DEALER_ID_IN_URL_ID) && selectedSearchType === SEARCH_TYPES.DEALER) {
            setSelectedDealer(dealer);
        }
    }, [selectedSearchType]);

    useEffect(() => {
        if (selectedSearchType === SEARCH_TYPES.DEALER && (geoLocation || selectedDealerId) && !selectedDealer) {
            setIsLoading(true);

            createTimeoutPromise(1000).then(() => {
                dealerApi
                    .getAllDealers(
                        String(geoLocation?.lat || dealer?.latitude),
                        String(geoLocation?.lng || dealer?.longitude),
                        userAuth.loginType === EUserLoginType.DEALER ? userAuth?.detail?.dealerCode : ''
                    )
                    .then(({ data }) => {
                        setDealers(data?.items);

                        if (selectedDealerId) {
                            handleSelectDealer(
                                data.items?.find((item: IDealer) => item.externalId === selectedDealerId)
                            );
                        } else {
                            handleSelectDealer(data?.items?.[0]);
                        }

                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            });
        }
    }, [selectedSearchType, geoLocation]);

    const onHome = async () => {
        dispatch(UIDuck.closeModal());

        if (!geoLocation && !dealer?.externalId && router.pathname.includes(Routes.SELECTOR)) {
            return router.push(buildParameterizedPathname(routes.ROOT, CarJourneyType.STOCK));
        }
    };

    const onSubmit = async () => {
        setShowRequiredError(false);
        setShowInvalidError(false);

        if (
            (!userInput || userInput === '') &&
            !geoLocation &&
            router.pathname.includes(Routes.SELECTOR) &&
            isLocalizationEnabled
        ) {
            return setShowRequiredError(true);
        }

        if (userInput && !geoLocation) {
            const firstAddress = (await addressAutocomplete(userInput)).predictions;
            if (!firstAddress?.[0]) {
                return setShowInvalidError(true);
            }

            const firstAddressDetails = (await addressAutocomplete(userInput, firstAddress?.[0].place_id)).result;
            if (!firstAddressDetails) {
                return setShowInvalidError(true);
            }

            dispatch(
                FilterDuck.setFilters(
                    modifySortFilter(filters, SortType.PROXIMITY, SortOrder.ASC),
                    firstAddressDetails?.geometry?.location,
                    distanceRadiusValue,
                    firstAddressDetails?.formatted_address
                )
            );
        }

        dispatch(UIDuck.closeModal());
    };

    const options = useMemo(() => generateOptions(DISTANCE_OPTIONS ? DISTANCE_OPTIONS.split(',') : [], t), []);

    const isDisabledValidateButton =
        (selectedSearchType === SEARCH_TYPES.LOCATION && !geoLocation) ||
        (selectedSearchType === SEARCH_TYPES.DEALER && !selectedDealer);

    const handleSelectChange = (value: ISelectOption) => {
        setDistanceRadius(value);
        dispatch(FilterDuck.setDistanceRadius(value.value));
    };

    const DropdownIndicator = (props: any) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <IconTemplate name={Icons.ChevronDown} />
                </components.DropdownIndicator>
            )
        );
    };

    return (
        <div className={className}>
            <div className="description">{t('modal.postalCode.description')}</div>
            <div className="type-select">
                <label
                    onClick={() => setSelectedSearchType(SEARCH_TYPES.LOCATION)}
                    className={`container ${selectedSearchType === SEARCH_TYPES.LOCATION ? 'isSelected' : ''}`}
                >
                    {t('modal.postalCode.searchByLocation')}
                    <input type="radio" checked={selectedSearchType === SEARCH_TYPES.LOCATION} name="location" />
                    <span className="checkmark"></span>
                </label>
                <label
                    onClick={() => setSelectedSearchType(SEARCH_TYPES.DEALER)}
                    className={`container ${selectedSearchType === SEARCH_TYPES.DEALER ? 'isSelected' : ''}`}
                >
                    {t('modal.postalCode.searchByDealer')}
                    <input type="radio" checked={selectedSearchType === SEARCH_TYPES.DEALER} name="dealer" />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div
                id={SECTION_ID}
                className={`input ${showRequiredError || showRequiredError || showInvalidError ? 'hasError' : ''}`}
            >
                <LocationInput setUserInput={setUserInput} showOnlyInput isModal />
            </div>
            {showRequiredError && <span className="error">{t('modal.postalCode.error.required')}</span>}
            {showInvalidError && <span className="error">{t('modal.postalCode.error.invalid')}</span>}
            {selectedSearchType === SEARCH_TYPES.LOCATION && (
                <div className="select">
                    <Select
                        value={distanceRadius}
                        onChange={handleSelectChange}
                        options={[...options, { value: 0, label: t('geoLocationInput.allVehicles') }]}
                        arrowRender={false}
                        classNamePrefix="distance-select"
                        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
                        theme={(theme) => ({ ...theme })}
                    />
                </div>
            )}
            {selectedSearchType === SEARCH_TYPES.DEALER && (
                <div className={`select ${!geoLocation && !dealer?.externalId ? 'disabled' : ''}`}>
                    <Select
                        value={
                            selectedDealer
                                ? {
                                      value: selectedDealer,
                                      label: formatDealerNameToSelect(selectedDealer),
                                  }
                                : {
                                      label: t('modal.postalCode.selectDealer'),
                                  }
                        }
                        onChange={(e) => handleSelectDealer(e.value)}
                        options={dealers?.map((dealer: IDealer) => ({
                            value: dealer,
                            label: formatDealerNameToSelect(dealer),
                        }))}
                        arrowRender={false}
                        classNamePrefix="distance-select"
                        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
                        theme={(theme) => ({ ...theme })}
                    />
                </div>
            )}
            {selectedSearchType === SEARCH_TYPES.DEALER && selectedDealer && (
                <div className="selected-dealer">
                    <span className="label">{t('modal.postalCode.dealerSelected')}</span>
                    <span className="name">{selectedDealer?.name}</span>
                    <span className="address-line1">{selectedDealer?.streetName}</span>
                    <span className="address-line2">
                        {selectedDealer?.postCode}, {selectedDealer?.city}
                    </span>
                </div>
            )}
            <div className="submit">
                {(isRouting || isLoading) && <Spinner size={30} border={3} color={theme.colors.black} />}
                {!isRouting && !isLoading && (
                    <>
                        <button className="home" onClick={onHome}>
                            <IconTemplate name={Icons.ChevronRight} />
                            {t('modal.postalCode.home')}
                        </button>
                        <button className="validate" disabled={isDisabledValidateButton} onClick={onSubmit}>
                            {t('modal.postalCode.button')}
                            {!router.pathname.includes(Routes.SELECTOR) && !isDisabledValidateButton
                                ? ` (${stockHomePageVehiclesCount})`
                                : ''}
                            {router.pathname.includes(Routes.SELECTOR) && !isDisabledValidateButton
                                ? ` (${stockTrimPageVehiclesCount})`
                                : ''}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
