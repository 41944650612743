import React, { FC, useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import {
    GOOGLE_MAP_DEFAULT_LAT,
    GOOGLE_MAP_DEFAULT_LNG,
    GOOGLE_MAP_DEFAULT_ZOOM,
    GOOGLE_MAP_MAX_ZOOM,
    isBrandAP,
    isBrandDS,
    isBrandOV,
    MARKET,
    MARKETS,
} from '../../constants/main';
import { DealerStateType, IDealerState } from '../../interfaces/Dealer';
import { dealerApi } from '../../services';
import { getSessionIdCookie } from '@utils/Session.utils';
import DealerDuck from '../../redux/dealer/dealer.duck';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { ACTIONS, CheckoutDealerDispatch, CheckoutDeliveryProps, IAction } from './';
import useTranslations from '@hooks/useTranslations';
import { Col, Grid, Row } from '../Grid';
import CheckoutTipsSidebar from '@components//CheckoutTipsSidebar';
import CheckoutMap from './components/CheckoutMap';
import CheckoutDealerSearch from './components/CheckoutDealerSearch';
import CheckoutDealerList from './components/CheckoutDealerList';
import CheckoutSelectedDealer from './components/CheckoutSelectedDealer';
import Spinner from '@components/Spinner';
import CheckoutMySummary from '@components/CheckoutMySummary';
import FinanceWidgetDuck from '../../redux/financeWidget/financeWidget.duck';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import CarDetailsDuck from '../../redux/carDetails/carDetails.duck';
import useCustomRouter from '@hooks/useCustomRouter';
import routes from '../../constants/routes';
import { useCreateMopAndGoToCheckout } from '@hooks/useCreateMopAndGoToCheckout';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { getOfferSidebarItems, getSidebarItems } from '@utils/page.utils';
import { MySavedDealJourneyType } from 'src/redux/user/user.duck.interface';
import { skipPaymentDeposit } from '@utils/Deal.utils';

const initialState: IDealerState = {
    dealers: [],
    error: false,
    selectedPlace: {
        lat: Number(GOOGLE_MAP_DEFAULT_LAT),
        lng: Number(GOOGLE_MAP_DEFAULT_LNG),
    },
    selectedDealer: {
        externalId: '',
        city: '',
        distance: null,
        name: '',
        postCode: '',
        streetName: '',
    },
    type: DealerStateType.place,
};

const reducer = (state: IDealerState, action: IAction) => {
    switch (action.type) {
        case ACTIONS.SET_ERROR:
            return { ...state, error: action.payload };
        case ACTIONS.SET_DEALERS:
            return { ...state, dealers: action.payload };
        case ACTIONS.SET_SELECTED_DEALER:
            return { ...state, selectedDealer: action.payload, type: DealerStateType.dealer };
        case ACTIONS.SET_SELECTED_PLACE:
            return { ...state, selectedPlace: action.payload, type: DealerStateType.place };
        default:
            return state;
    }
};

const CheckoutDeliveryTemplate: FC<CheckoutDeliveryProps> = ({ className, dispatchToStore, goToNextPage }) => {
    const { t } = useTranslations();
    const router = useCustomRouter();
    const createMopUpdateDealAndGoToCheckout = useCreateMopAndGoToCheckout();
    const { currentDeal } = useCarDetailsDuck();
    const [state, dispatch] = useReducer(reducer, initialState);
    const financeQuoteId = useSelector(FinanceWidgetDuck.getFinanceQuote);
    const isOffersJourney = router.asPath.includes(routes.OFFERS_DELIVERY);

    const isCarDealerLayerEnabled = useSelector((state) => CarDetailsDuck.getCurrentDealDealerLayerEnabled(state));
    const isDealerLayerEnabledFeatureSwitch = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED
    );
    const isDealerLayerEnabled = isCarDealerLayerEnabled && isDealerLayerEnabledFeatureSwitch;

    const isDealerSelectionCheckoutRedirectAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT
    );

    const { lat, lng } = state.selectedPlace;

    let mapLat: number = state.selectedPlace.lat;
    let mapLng: number = state.selectedPlace.lng;
    let zoom: number = Number(GOOGLE_MAP_DEFAULT_ZOOM);

    useEffect(() => {
        (async () => {
            try {
                dispatch({ type: ACTIONS.SET_ERROR, payload: false });
                const response = await dealerApi.getAllDealers(lat, lng);

                if (response.status !== 200) {
                    throw { message: 'Dealers request error' };
                }

                dispatch({ type: ACTIONS.SET_DEALERS, payload: response.data.items.slice(0, 100) });
            } catch (err: any) {
                dispatch({ type: ACTIONS.SET_ERROR, payload: true });
            }
        })();
    }, [lat, lng]);

    const handleDealer = useCallback(async () => {
        dispatchToStore(DealerDuck.setDealer(state.selectedDealer));
        try {
            dispatchToStore(UIDuck.setLoading(5));
            if (isDealerSelectionCheckoutRedirectAllowed) {
                // for purpose of FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT
                // we need to update dealer and deal after MOP is created,
                // so passing data via updateDeal object as argument
                createMopUpdateDealAndGoToCheckout({
                    dealerId: state.selectedDealer.externalId,
                    token: getSessionIdCookie(),
                });
            } else {
                await dealerApi.updateDealer(state.selectedDealer.externalId, getSessionIdCookie());
                dispatchToStore(UIDuck.setLoading(-5));
                goToNextPage();
            }
        } catch (err: any) {
            console.log(err);
        } finally {
            dispatchToStore(UIDuck.setLoading(-5));
        }
    }, [state.selectedDealer, dispatchToStore, goToNextPage]);

    if (state.selectedDealer && state.selectedDealer.latitude && state.type === DealerStateType.dealer) {
        mapLat = state.selectedDealer.latitude;
        mapLng = state.selectedDealer.longitude;
        zoom = Number(GOOGLE_MAP_MAX_ZOOM);
    }

    return (
        <div className={className}>
            <CheckoutDealerDispatch.Provider value={dispatch}>
                <Grid className="wrapper">
                    <Row>
                        <Col className="col" xs={12}>
                            <div className="mainTitle">{t('checkout.delivery.title')}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col" xs={12} sm={12} md={9}>
                            {state.dealers.length > 0 && !state.error ? (
                                <>
                                    <CheckoutDealerSearch />
                                    <div className="dalerMapWrapper">
                                        <CheckoutDealerList dealers={state.dealers} />
                                        <CheckoutMap
                                            dealers={state.dealers}
                                            latitude={mapLat}
                                            longitude={mapLng}
                                            zoom={zoom}
                                            zoomControl
                                        />
                                    </div>
                                    {state.selectedDealer.externalId !== '' && (
                                        <CheckoutSelectedDealer
                                            name={state.selectedDealer.name}
                                            streetName={state.selectedDealer.streetName}
                                            city={state.selectedDealer.city}
                                            postCode={state.selectedDealer.postCode}
                                            handleDealer={handleDealer}
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="dealerSpinner">
                                    <Spinner size={90} border={10} />
                                </div>
                            )}
                            <CheckoutMySummary
                                displayPx={MARKET === MARKETS.FR}
                                hideAdditionalInfo
                                isSecondaryLayout={isBrandOV || isBrandDS || isBrandAP || isDealerLayerEnabled}
                                isCheckout
                                financeQuoteId={financeQuoteId?.token}
                            />
                        </Col>

                        <Col className="col" sm={12} md={3}>
                            <CheckoutTipsSidebar
                                title={'checkout.delivery.tips.header'}
                                items={
                                    isOffersJourney
                                        ? getOfferSidebarItems()
                                        : getSidebarItems(
                                              currentDeal.journeyType === MySavedDealJourneyType.BUY_ONLINE_FINANCE,
                                              skipPaymentDeposit(currentDeal)
                                          )
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </CheckoutDealerDispatch.Provider>
        </div>
    );
};

export default CheckoutDeliveryTemplate;
