import React from 'react';
import { LoaderOverlayProps } from './';
import { theme } from '../../styles/theme';
import Spinner from '@components/Spinner';

const LoaderOverlayTemplate = ({ className, selectionMode = false }: LoaderOverlayProps) => {
    return (
        <div className={className}>
            {selectionMode
                ? <Spinner size={25} border={3} color={theme.colors.black}/>
                : <div className="loader"/>
            }
        </div>
    );
};

export default LoaderOverlayTemplate;
