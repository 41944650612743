import styled from 'styled-components';
import { ModalPostalCodeStyled } from '../../ModalPostalCodeStyled';
import { rem } from 'polished';

export const ModalPostalCodeStyledOV = styled(ModalPostalCodeStyled)`
    .description {
        font-size: ${rem(16)};
        color: ${({ theme }) => theme.colors.black2};
    }

    .type-select {
        .container {
            font-size: ${rem(16)};
            color: ${({ theme }) => theme.colors.black2};

            &.isSelected {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }

        .checkmark {
            border-color: ${({ theme }) => theme.colors.grey1};
        }

        .container .checkmark:after {
            background: ${({ theme }) => theme.colors.primary};
        }
    }

    .select {
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primary};
            }
        }

        .distance-select {
            &__single-value {
                color: ${({ theme }) => theme.colors.black2};
            }

            &__option {
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            &__option--is-focused {
                background: ${({ theme }) => theme.colors.grey16};
            }

            &__option--is-selected {
                background: ${({ theme }) => theme.colors.black2};
                color: ${({ theme }) => theme.colors.white};
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            &__control {
                border-radius: 0;
                border: 1px solid ${({ theme }) => theme.colors.grey4};
            }
        }
    }

    .submit {
        button {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            background: ${({ theme }) => theme.colors.primary};
            text-transform: uppercase;

            &:disabled {
                background: ${({ theme }) => theme.colors.primaryDisabled};
            }
        }

        .home {
            border-color: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }

            &:hover:enabled,
            &:focus-visible:enabled {
                background: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.white};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.white};
                    }
                }
            }
        }

        .validate {
            &:hover:enabled,
            &:focus-visible:enabled {
                background: ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.primary};
                border: 2px solid ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .selected-dealer {
        .label,
        .name,
        .address-line1,
        .address-line2 {
            font-size: ${rem(16)};
            color: ${({ theme }) => theme.colors.black2};
        }
    }
`;
