import React from 'react';
import Button from '../Button';
import useTranslations from '@hooks/useTranslations';
import { PaymentStatuses } from '../../constants/paymentStatuses';

export interface IModalPaymentMock {
    onClose: () => void;
    onClick: (status: string) => void;
}

const ModalPaymentMock = ({ onClick, onClose }: IModalPaymentMock) => {
    const { t } = useTranslations();

    return (
        <div>
            <p>{t('checkout.payment.modal.body')}</p>
            <div>
                {Object.keys(PaymentStatuses).map((status: string, i: number) => (
                    <Button
                        key={i}
                        onClick={() => {
                            onClick(Object.keys(PaymentStatuses)[i]);
                        }}
                        style={{ marginTop: '1rem' }}
                        data-testid={`TESTING_${status}`}
                    >
                        {status}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default ModalPaymentMock;
