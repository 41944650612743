import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { RedirectionModalStyledAC } from './AC/RedirectionModalStyled.AC';
import { RedirectionModalStyledAP } from './AP/RedirectionModalStyled.AP';
import { RedirectionModalStyledDS } from './DS/RedirectionModalStyled.DS';
import { RedirectionModalStyledOV } from './OV/RedirectionModalStyled.OV';
import { RedirectionModalStyledOV as RedirectionModalStyledOV_GB } from './OV/GB/RedirectionModalStyled.OV';
import { RedirectionModalStyled } from './RedirectionModalStyled';
import { SC } from '../../styles/theme';

export interface RedirectionModalProps extends SC {
    redirectTo?: string;
}

const RedirectionModal = (props: RedirectionModalProps) => {
    if (BRAND === 'AC') return <RedirectionModalStyledAC {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <RedirectionModalStyledOV_GB {...props} /> : <RedirectionModalStyledOV {...props} />;
    if (BRAND === 'AP') return <RedirectionModalStyledAP {...props} />;
    if (BRAND === 'DS') return <RedirectionModalStyledDS {...props} />;
    return <RedirectionModalStyled {...props} />;
};

export default RedirectionModal;
