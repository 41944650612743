import React, { FC } from 'react';
import { PartExchangeSummaryProps } from '.';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { formatDateByLang } from '@utils/Date.utils';

export const PartExchangeTemplate: FC<PartExchangeSummaryProps> = ({ className, pxData, isCashJourney }) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();
    return (
        <div className={className}>
            <div className="autobiz-info">
                {isCashJourney && (
                    <div className="autobiz-info-row">
                        <p>
                            <strong>{t('px.subTitle')}</strong>:
                        </p>
                    </div>
                )}
                <div className="autobiz-info-row">
                    <p>{t('px.brand')}:</p>
                    <p>{pxData.vehicle.makeName}</p>
                </div>
                <div className="autobiz-info-row">
                    <p>{t('px.model')}:</p>
                    <p>{pxData.vehicle.modelName}</p>
                </div>
                {pxData?.vehicle?.regNumber && (
                    <div className="autobiz-info-row">
                        <p>{t('px.number')}:</p>
                        <p>{pxData.vehicle.regNumber}</p>
                    </div>
                )}
                <div className="autobiz-info-row">
                    <p>{t('px.vin')}:</p>
                    <p>{pxData.vehicle.vin}</p>
                </div>
                {pxData?.vehicle?.registrationDate && (
                    <div className="autobiz-info-row">
                        <p>{t('px.date')}:</p>
                        <p>{formatDateByLang(pxData.vehicle.registrationDate)}</p>
                    </div>
                )}
                <div className="autobiz-info-row">
                    <p>{t('px.km')}:</p>
                    <p>{pxData.vehicle.overallMilleage}</p>
                </div>
            </div>
            {pxData.prices && (
                <div className="autobiz-info">
                    <div className="autobiz-info-row">
                        <p>{t('px.TTC')}</p>
                        <p>
                            <strong>{formatMoney(pxData.prices.dealerValuation)}</strong>
                        </p>
                    </div>
                    {pxData?.prices?.dealerBonusAmount && (
                        <div className="autobiz-info-row">
                            <p>{t('px.price1')}</p>
                            <p>
                                <strong>{formatMoney(pxData.prices.dealerBonusAmount)}</strong>
                            </p>
                        </div>
                    )}
                    {pxData?.prices?.governmentContributionAmount && (
                        <div className="autobiz-info-row">
                            <p>{t('px.price2')}</p>
                            <p>
                                <strong>{formatMoney(pxData.prices.governmentContributionAmount)}</strong>
                            </p>
                        </div>
                    )}
                    <div className="separatorLine" />
                    <div className="autobiz-info-row total-price">
                        <p>
                            <strong>{t('px.totalPrice')}</strong>
                        </p>
                        <p>
                            <strong>
                                {formatMoney(
                                    [
                                        pxData.prices.dealerValuation ? Number(pxData.prices.dealerValuation) : 0,
                                        pxData.prices.dealerBonusAmount ? Number(pxData.prices.dealerBonusAmount) : 0,
                                        pxData.prices.governmentContributionAmount
                                            ? Number(pxData.prices.governmentContributionAmount)
                                            : 0,
                                    ].reduce((acc, price) => {
                                        return acc + price;
                                    }, 0)
                                )}
                            </strong>
                        </p>
                    </div>
                </div>
            )}
            {!isCashJourney && (
                <div className="autobiz-description">
                    <p>{t('px.description1')}</p>
                    <p>{t('px.description2')}</p>
                    <p>{t('px.description3')}</p>
                </div>
            )}
        </div>
    );
};

export default PartExchangeTemplate;
