import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalCarOptionDetailStyledAP } from './AP/ModalCarOptionStyled.AP';
import { ModalCarOptionDetailStyledDS } from './DS/ModalCarOptionStyled.DS';
import { ModalCarOptionDetailStyledAC } from './AC/ModalCarOptionStyled.AC';
import { ModalCarOptionDetailStyledOV } from './OV/ModalCarOptionStyled.OV';
import { ModalCarOptionDetailStyledOV as ModalCarOptionDetailStyledOVGB } from './OV/GB/ModalCarOptionStyled.OV';
import { ModalCarOptionDetailStyled } from './ModalCarOptionDetailStyled';
import React from 'react';

export const ModalCarOptionDetail = (props: any) => {
    if (BRAND === 'AP') return <ModalCarOptionDetailStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalCarOptionDetailStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalCarOptionDetailStyledAC {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalCarOptionDetailStyledOVGB {...props} /> : <ModalCarOptionDetailStyledOV {...props} />;
    return <ModalCarOptionDetailStyled {...props} />;
};

export default ModalCarOptionDetail;
