import { css } from 'src/styles/theme';
import { rem } from 'polished';

export const StyledOVGB = css`
    .description {
        font-size: ${rem(16)};
    }

    .linkButton {
        display: flex;
        flex-direction: row-reverse;
        font-weight: 800;
        font-size: ${rem('14px')};
        line-height: 1.29;

        .icon-wrapper {
            margin-right: 5px;
        }
    }

    .save,
    .share {
        .link {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            color: ${({ theme }) => theme.colors.black2};
            margin-top: ${rem(10)};

            button {
                text-transform: uppercase;
            }

            svg {
                width: ${rem(12)};
                height: ${rem(10)};

                path {
                    fill: ${({ theme }) => theme.colors.black2};
                }
            }

            &:hover,
            &:focus-visible {
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        }

        .form {
            input {
                border-radius: 0;
                border: 1px solid ${({ theme }) => theme.colors.grey16};
                color: ${({ theme }) => theme.colors.black2};
            }
        }
    }
`;
