import styled from 'styled-components';
import { CheckoutMySummaryStyled } from '../CheckoutMySummaryStyled';

export const CheckoutMySummaryStyledAC = styled(CheckoutMySummaryStyled)`
    .Collapsible {
        &.checkout {
            background: white;
            display: flex;
            flex-direction: column;
            border: 2px solid #ececee;

            &__trigger {
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
                color: #012642;
            }
        }
        .secondaryText {
            font-family: ${({ theme }) => theme.fonts.fontBase};
        }
    }
`;
