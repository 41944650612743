import styled from 'styled-components';
import { rem } from 'polished';
import { ModalSessionChangeStyled } from '../ModalSessionChangeStyled';

export const ModalSessionChangeStyledAC = styled(ModalSessionChangeStyled)`
    .description {
        font-size: ${({ theme }) => theme.fontSizes.h4};
    }

    .section {
        .title {
            font-size: ${rem(22)};
        }
    }
`;
