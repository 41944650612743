import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { IconButtonStyled } from '../IconButtonStyled';

const hoverAndActive = css`
    h2 {
        color: ${({ theme }) => theme.colors.primary};
    }
    svg {
        rect {
            fill: ${({ theme }) => theme.colors.primary};
            stroke: ${({ theme }) => theme.colors.primary};
        }
        path {
            fill: ${({ theme }) => theme.colors.secondaryDisabled};
        }
    }
`;

export const IconButtonStyledDS = styled(IconButtonStyled)`
    :hover {
        ${hoverAndActive};
    }
    ${({ active }) => active && hoverAndActive};
`;
