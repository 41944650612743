import styled from 'styled-components';
import { breakpoints } from '../../../../../styles/theme';
import { FaqItemStyled } from '../FaqItemStyled';
import { rem } from 'polished';

const maxWidth: string = '900px';

export const FaqItemStyledAP = styled(FaqItemStyled)`
    &.faqCategory {
        > .Collapsible {
            background-color: ${({ theme }) => theme.colors.grey8};

            > .Collapsible__trigger {
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey12};

                > .faqHeader {
                    color: ${({ theme }) => theme.colors.primaryBlack};
                    padding-left: ${rem(1)};
                    text-transform: uppercase;
                    font-size: ${({ theme }) => theme.fontSizes.textSupBase};

                    @media (min-width: ${breakpoints.sm}px) {
                        padding-left: ${rem(16)};
                    }

                    &:after {
                        right: ${rem(22)};
                        background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5.99999V0.827608V0H9V0.827608V5.99999H14.1725H15.0001V8.99999H14.1725H9V14.1724V15H6V14.1724V8.99999H0.827615H0V5.99999H0.827615H6Z' fill='%23152032'/%3E%3C/svg%3E");

                        @media (min-width: ${breakpoints.sm}px) {
                            right: ${rem(36)};
                        }
                    }
                }

                &.is-closed {
                    transition-delay: 400ms;
                }

                &.is-open {
                    border-bottom-color: transparent;

                    > .faqHeader {
                        &:after {
                            background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.827615 9H14.1725H15.0001V6H14.1725H0.827615H0V9' fill='%23152032'/%3E%3C/svg%3E");
                        }
                    }
                }
            }

            > .Collapsible__contentOuter {
                background-color: ${({ theme }) => theme.colors.white};

                > .Collapsible__contentInner {
                    max-width: ${rem(maxWidth)};
                    margin: 0 auto;

                    div {
                        p {
                            span {
                                font-family: ${({ theme }) => theme.fonts.fontBase} !important;
                                font-size: ${({ theme }) => theme.fontSizes.textBase} !important;
                                color: ${({ theme }) => theme.colors.primaryBlack} !important;
                            }
                        }

                        ul {
                            li {
                                span {
                                    font-family: ${({ theme }) => theme.fonts.fontBase} !important;
                                    font-size: ${({ theme }) => theme.fontSizes.textBase} !important;
                                    color: ${({ theme }) => theme.colors.primaryBlack} !important;
                                }
                            }
                        }
                    }

                    > .categoryContent {
                        margin: 0 ${rem(16)};
                        padding: ${rem(16)} 0;

                        p {
                            span {
                                font-family: ${({ theme }) => theme.fonts.fontBase} !important;
                                font-size: ${({ theme }) => theme.fontSizes.textBase} !important;
                            }
                        }

                        @media (min-width: ${breakpoints.md}px) {
                            margin: 0 ${rem(80)} 0 0;
                        }

                        @media (min-width: ${breakpoints.sm}px) {
                            margin: 0 ${rem(80)} 0 ${rem(16)};
                        }
                    }
                }
            }
        }
    }

    &.faqGroup {
        > .Collapsible {
            > .Collapsible__trigger {
                border: 2px solid ${({ theme }) => theme.colors.grey8};
                padding: ${rem(13)} ${rem(30)};
                margin: 0 ${rem(16)} 0 ${rem(16)};

                > .faqHeader {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    color: ${({ theme }) => theme.colors.blue6};
                    padding-right: ${rem(32)};
                    font-size: ${({ theme }) => theme.fontSizes.textSupBase};

                    &:after {
                        right: ${rem(32)};
                        height: ${rem(14)};
                        width: ${rem(26)};
                        transform: rotate(180deg);
                        margin-top: ${rem(-7)};
                        background-image: url("data:image/svg+xml,%3Csvg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 13L13 1L25 13' stroke='%23CDCDCD' stroke-width='2' stroke-linejoin='bevel' /%3E%3C/svg%3E");
                    }
                }

                &.is-open {
                    > .faqHeader {
                        &:after {
                            transform: rotate(0);
                        }
                    }
                }
            }

            > .Collapsible__contentOuter {
                background-color: ${({ theme }) => theme.colors.white};

                > .Collapsible__contentInner {
                    margin-right: 0;
                    padding: ${rem(16)} ${rem(16)} 0 ${rem(16)};

                    @media (min-width: ${breakpoints.sm}px) {
                        padding: ${rem(16)} ${rem(30)} 0 ${rem(30)};
                    }
                }
            }
        }
    }
`;
