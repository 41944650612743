import { FaqItemProps } from './index';
import React, { FC, memo, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useGTM } from '@hooks/useGTM';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { isBrandAC } from 'src/constants/main';

export const FaqItemTemplate: FC<FaqItemProps> = memo(
    ({
        trigger,
        className,
        children,
        onOpen,
        onClose,
        open,
        onOpening,
        onClosing,
        contentContainerTagName,
        overflowWhenOpen,
        id,
        tabIndex = 0,
        isRoot = false,
    }) => {
        const { pushUserAction } = useGTM();
        const [opened, setOpened] = useState(open);
        const getClassName = (classname: string | undefined) => {
            if (classname?.includes('faqCategory')) return classname;
            else return classname?.concat(' faqGroup');
        };

        const handleOpen = () => {
            setOpened(true);
            if (typeof trigger === 'string') {
                pushUserAction({
                    action: 'Display::Expandbar',
                    label: trigger,
                });
            }
            onOpen?.();
        };

        const handleClose = () => {
            setOpened(false);
            if (typeof trigger === 'string') {
                pushUserAction({
                    action: 'Close::Expandbar',
                    label: trigger,
                });
            }
            onClose?.();
        };

        return (
            <div className={className && getClassName(className)} id={id}>
                <Collapsible
                    contentContainerTagName={contentContainerTagName}
                    trigger={
                        <div className="faqHeader" data-testid={id}>
                            {trigger}
                            {isRoot && opened && isBrandAC && (
                                <IconTemplate className="icon opened" name={Icons.Dash} />
                            )}
                            {isRoot && !opened && isBrandAC && (
                                <IconTemplate className="icon closed" name={Icons.PlusIcon} />
                            )}
                        </div>
                    }
                    tabIndex={tabIndex}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    open={open}
                    onOpening={onOpening}
                    onClosing={onClosing}
                    overflowWhenOpen={overflowWhenOpen}
                >
                    <div data-testid={`${id}_CONTENT`}>{children}</div>
                </Collapsible>
            </div>
        );
    }
);
FaqItemTemplate.displayName = 'FaqItemTemplate';
