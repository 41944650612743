import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalDisableCheckoutStyled } from '../ModalDisableCheckoutStyled';

export const ModalDisableCheckoutStyledOV = styled(ModalDisableCheckoutStyled)`
    .input {
      
        button {
            font-size: ${(props) => props.theme.fontSizes.textBase};
            color: ${(props) => props.theme.colors.black2};

            &:hover,
            &:focus-visible {
                color: ${(props) => props.theme.colors.white};
            }
        }
    }
`;
