import styled from 'styled-components';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV } from 'src/constants/main';
import { EmailInputTemplate } from './EmailInputTemplate';
import { StylesAC } from './Styles.AC';
import { StylesAP } from './Styles.AP';
import { StylesDS } from './Styles.DS';
import { StylesOV } from './Styled.OV';

export const EmailInput = styled(EmailInputTemplate)`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${isBrandAC && StylesAC};
    ${isBrandAP && StylesAP};
    ${isBrandDS && StylesDS};
    ${isBrandOV && StylesOV};
`;
