import React from 'react';
import Button from '../../Button';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';
import useTranslations from '@hooks/useTranslations';

interface Props {
    wrapperClassName: string;
    textClassName: string;
    className: string;
    data?: string[];
}

const InvalidConfigurationModalContent = ({ wrapperClassName, textClassName, className, data }: Props) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();

    const handleOKButtonClick = () => {
        dispatch(UIDuck.closeModal());
    };

    return (
        <div className={className}>
            <p className={textClassName}>{t('modal.configurator.invalidConfiguration.textContent', data)}</p>
            <Button onClick={handleOKButtonClick}>{t('modal.configurator.invalidConfiguration.ok')}</Button>
        </div>
    );
};

export default InvalidConfigurationModalContent;
