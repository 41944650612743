import { rem } from 'polished';
import { css } from 'styled-components';

export const RowCellMainStylesOV = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
    line-height: ${({ theme }) => theme.lineHeights.textSupBig};

    .engine-title {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        line-height: ${({ theme }) => theme.lineHeights.h5};
    }

    .engine-price {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        line-height: ${({ theme }) => theme.lineHeights.textMedium};
    }
`;

export const RowCellGearboxStylesOV = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const RowTitleStylesOV = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${rem(16)};
`;

export const CategoryTitleStylesOV = css`
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
`;
