import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import useTranslations from '@hooks/useTranslations';

interface IModalDeleteDeal {
    onClose: () => void;
}

export const ModalDealerFeesPostalCodeError: React.FC<IModalDeleteDeal> = () => {
    const { t } = useTranslations();

    return (
        <Wrapper>
            <Content dangerouslySetInnerHTML={{ __html: t('basket.dealerFee.carteGrise.error.content') }} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    padding-bottom: ${rem('10px')};
`;
const Content = styled.div`
    color: ${props => props.theme.colors.primaryBlack};
`;
