import { useUIDuck } from './useUIDuck';
import { useFinanceWidgetDuck } from './useFinanceWidgetDuck';

export const useUILoadingOnConfigurator = () => {
    const { isLoading, isLoadingFullScreen } = useUIDuck();
    const { isPricesListLoading } = useFinanceWidgetDuck();

    const uiIsLoading = isLoading || isLoadingFullScreen || isPricesListLoading;

    const uiIsFinanceLoading = isLoadingFullScreen || isPricesListLoading;
    const uiLoadingDisabledClass = uiIsLoading ? 'disabled' : '';

    return { uiIsLoading, uiIsFinanceLoading, uiLoadingDisabledClass };
};
