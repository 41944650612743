import { BRAND, isMarketGB } from './main';
import { GtmDataVariables } from 'src/types/gtmDataVariables';

export enum GTM_MAIN_STEP {
    HOME = 'home page',
    TRIM = 'finition',
    STOCK = 'select vehicle',
    CONFIGURATOR = 'personnalization',
    BASKET = 'basket',
    FIND_DEALER = 'find dealer',
    PERSONAL_DETAIL = 'personal details',
    PRE_ORDER = 'preorder',
    ORDER_REVIEW = 'order review',
    CONFIRMATION = 'confirmation',
    REQUEST_FOR_FINANCING_LLD = 'request for financing LLD',
    MY_ACCOUNT = 'my account',
    MY_SAVES = 'my saves',
    MY_ORDERS = 'my orders',
    SUMMARY = 'summary',
}

export enum GTM_VIRTUAL_PAGE_URL {
    HOME = '/SOLRetail/home',
    STOCK_HOME = '/SOLRetail/stock/home',
    TRIM = '/SOLRetail/new-cars/configurator/step1/fintion',
    STOCK_TRIM = '/SOLRetail/stock/step1/select-vehicle',
    CONFIGURATOR = '/SOLRetail/new-cars/configurator/step2/personnalization',
    BASKET = '/SOLRetail/new-cars/configurator/step${step}/basket-page/',
    BASKET_STOCK = '/SOLRetail/stock/step${step}/basket-page/',
    ERROR = '/SOLRetail/new-cars/error/',
    FIND_DEALER = '/SOLRetail/checkout/step1/find-a-dealer',
    PERSONAL_DETAIL = '/SOLRetail/checkout/step2/personal-details',
    PRE_ORDER = '/SOLRetail/checkout/step3/preOrder',
    ORDER_REVIEW = '/SOLRetail/checkout/step4/order-review',
    CONFIRMATION = '/SOLRetail/checkout/step5/confirmation',
    REQUEST_FOR_FINANCING_LLD = '/SOLRetail/checkout/step3b/request-for-financing-LLD-sent',
    CONNECTION = '/SOLRetail/new-cars/connection',
    MY_ACCOUNT = '/SOLRetail/new-cars/my-account',
    MY_SAVES = '/SOLRetail/new-cars/my-saved-cars',
    MY_ORDERS = '/SOLRetail/new-cars/my-orders',
    SUMMARY_CONFIGURATOR = '/SOLRetail/new-cars/configurator/step${step}/summary',
    SUMMARY_STOCK = '/SOLRetail/stock/step${step}/summary',
    DEALER = '/SOLRetail/new-cars/configurator/step${step}/find-a-dealer',
}

export enum GTM_PAGE_CATEGORY {
    HOME = 'home page',
    TRIM = 'configurator',
    STOCK = 'car selector page',
    STOCK_SUMMARY = 'information page',
    DEALER = 'configurator',
    CONFIGURATOR = 'configurator',
    BASKET = 'basket page',
    ERROR = 'error page',
    CHECKOUT = 'checkout page',
    CONNECTION = 'connection page',
    MY_ACCOUNT = 'selection assistance page',
    MY_SAVES = 'selection assistance page',
    MY_ORDERS = 'selection assistance page',
}

export enum GTM_ORDER_VARIANT {
    SOL = 'MTO',
    STOCK = 'stock',
}

export enum GTM_PAYMENT_JOURNEY {
    CASH = 'cash-deal',
    FINANCE = 'finance-offer',
}

export enum GTM_OFFER_TYPE {
    CUSTOM_OFFER = 'custom-offer',
    CLASSIC_ORDER = 'classic-order',
}

export type EngineTypes = 'ICE' | 'MHEV' | 'BEV' | 'PHEV' | 'petrol' | 'diesel';

export enum VehicleCategories {
    VP = 'PC',
    VU = 'LCV',
}

export const GTM_ENGINE_FUEL_CODE_TYPE: { [key: string]: EngineTypes } = Object.freeze({
    '01': 'MHEV',
    '02': 'ICE',
    '03': 'ICE',
    '04': 'ICE',
    '05': 'BEV',
    '10': 'PHEV',
});

export const GTM_BRAND = (() => {
    switch (BRAND) {
        case 'AC':
            return 'citroen';
        case 'DS':
            return 'ds';
        case 'OV':
            return isMarketGB ? 'vauxhall' : 'opel';
        case 'AP':
            return 'peugeot';
        default:
            return 'default';
    }
})();

export const LEASYS_BRAND = (() => {
    switch (BRAND) {
        case 'AC':
            return 'Citroen';
        case 'DS':
            return 'DS';
        case 'OV':
            return 'Opel';
        case 'AP':
            return 'Peugeot';
        default:
            return 'default';
    }
})();

export const GTM_DATA_VARIABLES: GtmDataVariables = Object.freeze({
    SHARE_LINK_DEALERS: {
        eventCategory: 'SolRetail::Basket',
        eventAction: 'Display::SaveBasketDealer',
        eventLabel: '{{Local Label}}',
    },
    SEND_CTA_BUTTON: {
        eventCategory: 'SolRetail::Basket',
        eventAction: 'Validate::SaveBasketDealer::Send',
        eventLabel: '{{Local Label}}',
    },
    GET_THE_LINK_CTA: {
        eventCategory: 'SolRetail::Basket',
        eventAction: 'Validate::SaveBasketDealer::GetTheLink',
        eventLabel: '{{Local Label}}',
    },
});

export const GTM_CURRENCY = 'currency';
export const GTM_COUNTRY = 'country';
export const GTM_LANGUAGE = 'language';
export const GTM_EVENT_CATEGORY = 'Ecommerce';
export const GTM_SITE_FAMILY = 'new cars';
export const GTM_SITE_OWNER = 'central';
export const GTM_SITE_TARGET = 'B2C';
export const GTM_SITE_TYPE_LEVEL_1 = 'SOL Retail';
export const GTM_SITE_TYPE_LEVEL_2 = 'selling online';
export const GTM_EVENT_AB_TEST = 'ABTest';
