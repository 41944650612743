import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { LoadingButtonStyled } from '../LoadingButtonStyled';
import { rem } from 'polished';

export const LoadingButtonStyledAP = styled(LoadingButtonStyled)`
    button {
        font-size: ${({ theme }) => theme.fontSizes.textTiny};
    }
`;
