import { MARKET } from 'src/constants/main';
import { i18n } from 'i18next';

export const getCurrentCulture = (i18n: i18n) => {
    const currentCulture = `${i18n?.language?.toLowerCase() ?? 'fr'}-${MARKET?.toUpperCase() ?? 'FR'}`;

    return {
        currentCulture,
    };
};
