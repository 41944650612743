import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CustomErrorStyled } from '../CustomErrorStyled';
import { rem } from 'polished';
import { publicAssets } from '@utils/url.utils';

export const CustomErrorStyledAP = styled(CustomErrorStyled)`
    background-size: cover;
    background-position: center center;
    background-image: url('${publicAssets('/AP/ap_maintenance_bg.jpeg')}');

    .errorPage {
        &__content {
            padding: ${rem(20)};
            margin: ${rem(30)} ${rem(10)};
            max-width: ${rem(450)};
            background-color: ${({ theme }) => theme.colors.white};

            @media (min-width: ${breakpoints.sm}px) {
                padding: ${rem(40)} ${rem(20)};
                margin-left: ${rem(30)};
            }
        }

        &__title {
            color: ${({ theme }) => theme.colors.primaryBlack};
            font-size: ${({ theme }) => theme.fontSizes.h2};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        &__description {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }

        &__link a {
            color: ${({ theme }) => theme.colors.white};
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            border-color: ${({ theme }) => theme.colors.primary};
            background: ${({ theme }) => theme.colors.primary};

            &:hover {
                opacity: 1;
                background: ${({ theme }) => theme.colors.primaryBlack};
                border-color: ${({ theme }) => theme.colors.primaryBlack};
            }
        }
    }

    &.stock {
        background-image: none;
        min-height: ${rem(350)};
        max-width: 100%;
        .errorPage {
            &__content {
                padding-top: ${rem(50)};
                margin: 0 auto;
                background-color: ${({ theme }) => theme.colors.grey14};
                text-align: center;
            }
        }
    }
`;
