import React, { FC, PropsWithChildren } from 'react';
// eslint-disable-next-line no-restricted-imports
import { LinkProps } from 'next/link';
import { Link } from '../../../i18n';
import { LinkMock } from './__mocks__/Link.mock';
import { extractRouteFromUrl, addBasePath } from '@utils/url.utils';
import { isStorybook } from '@utils/runtime';

export interface CustomLinkProps extends Omit<LinkProps, 'as'> {
    href: string;
}

const CustomNextLink: FC<PropsWithChildren<CustomLinkProps>> = ({ href = '', ...rest }) => {
    const route = extractRouteFromUrl(href);
    const query = href.indexOf('?') >= 0 ? href.substring(href.indexOf('?'), href.length) : '';
    const fullPath = `${route}${query}`;

    if (isStorybook()) {
        return <LinkMock href={addBasePath(fullPath)} as={addBasePath(href)} {...rest} />;
    }
    return <Link href={addBasePath(fullPath)} as={addBasePath(href)} {...rest} />;
};

export default CustomNextLink;
