import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { rem } from 'polished';
import { ModalSessionChangeTemplate } from './ModalSessionChangeTemplate';

export const ModalSessionChangeStyled = styled(ModalSessionChangeTemplate)`
    .description {
        margin-bottom: ${rem(25)};
    }

    .title {
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-size: ${rem(20)};
    }

    .border {
        height: ${rem(1)};
        background: ${({ theme }) => theme.colors.milkshake};
    }

    .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: ${rem(20)};

        .section {
            margin-top: ${rem(10)};
            margin-bottom: ${rem(30)};
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .vehicle {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .name {
            display: block;
            padding-bottom: ${rem(10)};
            font-size: ${rem(20)};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .image {
            width: ${rem(255)};
            padding: ${rem(30)} 0;
            margin: 0 auto;
        }
    }
`;
