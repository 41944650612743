import styled from 'styled-components';
import { ModalFeaturesStyled } from '../../ModalFeaturesStyled';
import { rem } from 'polished';

export const ModalFeaturesStyledOV = styled(ModalFeaturesStyled)`
    .categories {
        span {
            &.selected,
            &:hover {
                background: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .engines,
    .colors,
    .interiors,
    .features {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }
    }

    .engines {
        .configurations {
            > div {
                .configuration {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                .engine,
                .price {
                    color: ${({ theme }) => theme.colors.grey31};
                }
            }
        }
    }
`;
