import React, { useState, FC, useRef, useEffect, useContext } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { decodeFromBase64 } from '@utils/misc.utils';
import useTranslations from '@hooks/useTranslations';
import { Col, Grid, Row } from '../Grid';
import { batch, connect } from 'react-redux';
import RedirectionModal from '@components/RedirectionModal';
import CarDetailsDuck from '../../redux/carDetails/carDetails.duck';
import FilterDuck from '../../redux/filters/filter.duck';
import DealerDuck from '../../redux/dealer/dealer.duck';
import { Props } from '.';
import CheckoutTipsSidebar from '@components/CheckoutTipsSidebar';
import { IGlobalState } from '../../redux/redux.interface';
import {
    EMAIL_REGEX,
    NUMBER_FIELD_REGEX,
    PHONE_REGEX,
    PHONE_MAX_LENGTH,
    POSTCODE_REGEX,
    NIF_REGEX,
    BUSINESS_NAME_REGEX,
    BUSINESS_ID_REGEX,
} from '@utils/checkoutInputsValidation';
import { useObjectUtils } from '@hooks/useObjectUtils';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { getSidebarItems } from '@utils/page.utils';
import { MySavedDealJourneyType } from '../../redux/user/user.duck.interface';
import {
    IS_B2B,
    isBrandAP,
    isMarketES,
    isMarketGB,
    isMarketFR,
    isBrandOV,
    B2B_FILEDS,
    ModalVersion,
    FEATURE_SWITCHES_COOKIE_NAME,
} from '../../constants/main';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from '../../context/featureSwitchApp';
import DealService from '../../services/deal/deal.service';
import { skipPaymentDeposit } from '@utils/Deal.utils';
import Select from 'react-select';
import {
    getCustomerTitlesTranslated,
    getCustomerTitlesTranslatedUK,
} from '@components/CheckoutMySummary/helpers/constatnts';
import Spinner from '@components/Spinner';
import { getSessionIdCookie, setSessionIdCookie } from '@utils/Session.utils';
import { brandIdRedirect } from '@utils/url.utils';
import {
    BRANDID_CLIENT_ID,
    BRANDID_LOGIN_URL,
    BRANDID_REDIRECT_BOUNCE_URL,
    BRANDID_RETURN_URL,
} from '../../constants/myAccount';
import { useUserDuck } from '@hooks/useUserDuck';
import { sessionInfoApi } from '../../services';
import UserServices from 'src/services/user/user.service';
import userDuck from '../../redux/user/user.duck';
import { EUserLoginType } from 'src/interfaces/User';
import Checkbox from '@components/Checkbox';
import { useGetNotEscapedTranslationMarkup } from '@hooks/useGetNotEscapedTranslationMarkup';
import { B2B_FIELD, LEGAL_CONSENTS_VALUES } from 'src/constants/enums';
import UserDuck from '../../redux/user/user.duck';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';
import LegalTermsAndConditionsConsent from '@components/LegalTermsAndConditionsConsent';
import Button from '@components/Button';

const CheckoutMyDetails: FC<Props> = ({ carDetails, userDetails, userAddress, className, isLoading, dispatch }) => {
    const { t } = useTranslations();
    const router = useRouter();
    const { currentDeal } = useCarDetailsDuck();
    const context = useContext(FeatureSwitchContext);
    const { isUserLoggedIn, isUserLoggedInAsDealer, userAuth } = useUserDuck();
    const { replaceStringValuesInObject } = useObjectUtils();
    const isGigyaEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_GIGYA_ENABLED);
    userDetails = replaceStringValuesInObject(userDetails);
    userAddress = replaceStringValuesInObject(userAddress);

    const companyNameAllowed = IS_B2B && B2B_FILEDS.includes(B2B_FIELD.COMPANY_NAME);
    const companyNumberAllowed = IS_B2B && B2B_FILEDS.includes(B2B_FIELD.COMPANY_NUMBER);
    const companyVATAllowed = IS_B2B && B2B_FILEDS.includes(B2B_FIELD.COMPANY_VAT);

    const { getNotEscapedTranslationMarkup } = useGetNotEscapedTranslationMarkup();

    const [showLegalRequiredErrors, setShowLegalRequiredErrors] = useState(false);
    const [selectedLegalValues, setSelectedLegalValues] = useState([]);

    const isNIFEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_NIF_ENABLED);

    const [isCreatingAccount, setIsCreatingAccount] = useState(false);

    const emailInputRef = useRef<HTMLInputElement>();
    const emailConfirmationInputRef = useRef<HTMLInputElement>();
    const firstNameInputRef = useRef<HTMLInputElement>();
    const lastNameInputRef = useRef<HTMLInputElement>();
    const phoneInputRef = useRef<HTMLInputElement>();
    const address1InputRef = useRef<HTMLInputElement>();
    const address2InputRef = useRef<HTMLInputElement>();
    const cityInputRef = useRef<HTMLInputElement>();
    const postalCodeInputRef = useRef<HTMLInputElement>();
    const nifInputRef = useRef<HTMLInputElement>();
    const businessIdInputRef = useRef<HTMLInputElement>();
    const businessNameInputRef = useRef<HTMLInputElement>();
    const businessVatInputRef = useRef<HTMLInputElement>();

    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [usedEmailError, setUsedEmailError] = useState(false);
    const [invalidPhoneErrorMessageKey, setInvalidPhoneErrorMessageKey] = useState(null);
    const [invalidEmailConfirmationError, setInvalidEmailConfirmationError] = useState(false);
    const [notEqualEmailConfirmationError, setNotEqualEmailConfirmationError] = useState(false);
    const [emailRequiredError, setEmailRequiredError] = useState(false);
    const [confirmationEmailRequiredError, setConfirmationEmailRequiredError] = useState(false);
    const [phoneRequiredError, setPhoneRequiredError] = useState(false);
    const [firstNameRequiredError, setFirstNameRequiredError] = useState(false);
    const [lastNameRequiredError, setLastNameRequiredError] = useState(false);
    const [addressRequiredError, setAddressRequiredError] = useState(false);
    const [cityRequiredError, setCityRequiredError] = useState(false);
    const [postCodeRequiredError, setPostCodeRequiredError] = useState(false);
    const [nifCodeRequiredError, setNifCodeRequiredError] = useState(false);
    const [nifCodeInvalidError, setInvalidNifCodeError] = useState(false);
    const [invalidPostCodeError, setInvalidPostCodeError] = useState(false);
    const [businessIdError, setBusinessIdError] = useState(false);
    const [businessNameError, setBusinessNameError] = useState(false);

    const [contactByEmail, setContactByEmail] = useState(false);
    const [contactByMessage, setContactByMessage] = useState(false);
    const [contactByPhone, setContactByPhone] = useState(false);
    const [contactByLetter, setContactByLetter] = useState(false);

    const [hasUserUserDetail, setHasUserUserDetail] = useState(false);
    const [hasUserUserAddress, setHasUsedUserAddress] = useState(false);

    const customerTitles = isMarketGB ? getCustomerTitlesTranslatedUK(t) : getCustomerTitlesTranslated(t);
    const CUSTOMER_TITLES = customerTitles.map(({ value, translation }) => ({
        value,
        label: translation,
    }));
    const [selectedTitle, setSelectedTitle] = useState(CUSTOMER_TITLES[0]);

    const [legalConsentVersion, setLegalConsentVersion] = useState(null);

    useEffect(() => {
        if (context) {
            const legalConsentVersionCookie = getFeatureSwitch(
                FEATURES_LIST.FEATURE_SWITCH_LEGAL_CONSENT_VERSION,
                Cookies.get(FEATURE_SWITCHES_COOKIE_NAME)
                    ? decodeFromBase64(Cookies.get(FEATURE_SWITCHES_COOKIE_NAME))
                    : {}
            );

            const legalConsentVersionDefault = getFeatureSwitch(
                FEATURES_LIST.FEATURE_SWITCH_LEGAL_CONSENT_VERSION,
                context
            );

            setLegalConsentVersion(legalConsentVersionCookie || legalConsentVersionDefault);
        }
    }, [context]);

    console.log('TEST VERSION 2', legalConsentVersion);

    useEffect(() => {
        if (userDetails && !hasUserUserDetail) {
            emailInputRef.current.value = emailInputRef.current.value || userDetails.email;
            emailConfirmationInputRef.current.value = emailConfirmationInputRef.current.value || userDetails.email;
            firstNameInputRef.current.value = firstNameInputRef.current.value || userDetails.firstname;
            lastNameInputRef.current.value = lastNameInputRef.current.value || userDetails.lastname;
            phoneInputRef.current.value = phoneInputRef.current.value || userDetails.telephone;
            if (companyNumberAllowed) {
                businessIdInputRef.current.value = String(userDetails.businessID || '');
            }
            if (companyNameAllowed) {
                businessNameInputRef.current.value = String(userDetails.businessName || '');
            }
            if (companyVATAllowed) {
                businessVatInputRef.current.value = String(userDetails.businessVAT || '');
            }
            setSelectedTitle(
                userDetails.title
                    ? CUSTOMER_TITLES.find(({ value }) => +value === +userDetails.title)
                    : CUSTOMER_TITLES[0]
            );
            setHasUserUserDetail(true);
        }
        if (userAddress && !hasUserUserAddress) {
            address1InputRef.current.value = address1InputRef.current.value || userAddress.address1;
            address2InputRef.current.value = address2InputRef.current.value || userAddress.address2;
            cityInputRef.current.value = cityInputRef.current.value || userAddress.city;
            postalCodeInputRef.current.value = postalCodeInputRef.current.value || userAddress.postcode;
            if (isMarketES && isNIFEnabled) {
                nifInputRef.current.value = userAddress.nif;
            }
            setHasUsedUserAddress(true);
        }
    }, [userDetails, userAddress]);

    const postCodeValidation = () => {
        setInvalidPostCodeError(false);
        setPostCodeRequiredError(false);

        if (postalCodeInputRef?.current?.value === '') {
            setPostCodeRequiredError(true);
            return true;
        }

        if (!POSTCODE_REGEX.test(postalCodeInputRef?.current?.value)) {
            setInvalidPostCodeError(true);
            return true;
        }
        return false;
    };

    const businessNameValidation = () => {
        setBusinessNameError(false);

        if (
            companyNameAllowed &&
            (!BUSINESS_NAME_REGEX.test(businessNameInputRef?.current?.value) || !businessNameInputRef?.current?.value)
        ) {
            setBusinessNameError(true);
            return true;
        }
        return false;
    };

    const businessIdValidation = () => {
        setBusinessIdError(false);

        if (
            companyNumberAllowed &&
            (!BUSINESS_ID_REGEX.test(businessIdInputRef?.current?.value) || !businessIdInputRef?.current?.value)
        ) {
            setBusinessIdError(true);
            return true;
        }
        return false;
    };

    const nifCodeValidation = () => {
        setInvalidNifCodeError(false);
        setNifCodeRequiredError(false);

        if (isNIFEnabled) {
            const nif = nifInputRef?.current?.value;
            if (!nif) {
                setNifCodeRequiredError(true);
                return;
            } else if (nif && !NIF_REGEX.test(nif)) {
                setInvalidNifCodeError(true);
                return;
            }
        }
    };

    const phoneValidation = () => {
        setInvalidPhoneErrorMessageKey(null);
        setPhoneRequiredError(false);
        const phoneNumber = phoneInputRef?.current?.value;

        if (phoneNumber === '') {
            setPhoneRequiredError(true);
            return true;
        }

        if (!NUMBER_FIELD_REGEX.test(phoneNumber)) {
            setInvalidPhoneErrorMessageKey('checkout.error.numbersOnly');
            return true;
        }

        if (phoneNumber?.length !== PHONE_MAX_LENGTH) {
            setInvalidPhoneErrorMessageKey('checkout.error.phonenumber.length');
            return true;
        }

        if (!PHONE_REGEX.test(phoneNumber)) {
            setInvalidPhoneErrorMessageKey('checkout.myDetails.input.phone.error.invalid');
            return true;
        }

        return false;
    };

    const emailConfirmationValidation = async () => {
        const email = emailInputRef?.current?.value;
        const emailConfirmation = emailConfirmationInputRef?.current?.value;

        !EMAIL_REGEX.test(emailConfirmation)
            ? setInvalidEmailConfirmationError(true)
            : setInvalidEmailConfirmationError(false);

        email !== emailConfirmation
            ? setNotEqualEmailConfirmationError(true)
            : setNotEqualEmailConfirmationError(false);
    };

    const emailValidation = async () => {
        const email = emailInputRef?.current?.value;
        const emailConfirmation = emailConfirmationInputRef?.current?.value;

        setEmailRequiredError(false);
        setInvalidEmailError(false);

        if (!EMAIL_REGEX.test(email)) {
            return setInvalidEmailError(true);
        }
        if (emailConfirmation) {
            if (emailConfirmation !== email) {
                return setNotEqualEmailConfirmationError(true);
            } else {
                setNotEqualEmailConfirmationError(false);
            }
        }

        if (!emailRequiredError && !invalidEmailError) {
            await isEmailUsed();
        }
    };

    const isEmailUsed = async (): Promise<boolean> => {
        setUsedEmailError(false);
        if (isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST) {
            // do not validate already used email when user is already logged in
            return false;
        }
        const { data } = await UserServices.validateEmail(emailInputRef?.current?.value);

        if (data.exists) {
            setUsedEmailError(true);
            return true;
        }
        return false;
    };

    const inputValidation = async (inputRef: any = null, setInputError: any = null) => {
        if (inputRef && setInputError) {
            return setInputError(inputRef?.current?.value === '');
        }

        setInvalidEmailError(false);
        setEmailRequiredError(false);
        setConfirmationEmailRequiredError(false);
        setInvalidEmailConfirmationError(false);
        setFirstNameRequiredError(false);
        setLastNameRequiredError(false);
        setAddressRequiredError(false);
        setCityRequiredError(false);
        setPostCodeRequiredError(false);
        setPhoneRequiredError(false);
        setNotEqualEmailConfirmationError(false);
        setNifCodeRequiredError(false);
        setBusinessIdError(false);
        setBusinessNameError(false);

        let hasError = false;

        if (emailInputRef?.current?.value === '') {
            hasError = true;
            setEmailRequiredError(true);
        }
        if (emailConfirmationInputRef?.current?.value === '') {
            hasError = true;
            setConfirmationEmailRequiredError(true);
        }
        if (emailInputRef?.current?.value !== emailConfirmationInputRef?.current?.value) {
            hasError = true;
            setNotEqualEmailConfirmationError(true);
        }
        if (firstNameInputRef?.current?.value === '') {
            hasError = true;
            setFirstNameRequiredError(true);
        }
        if (lastNameInputRef?.current?.value === '') {
            hasError = true;
            setLastNameRequiredError(true);
        }
        if (address1InputRef?.current?.value === '') {
            hasError = true;
            setAddressRequiredError(true);
        }
        if (cityInputRef?.current?.value === '') {
            hasError = true;
            setCityRequiredError(true);
        }

        if (IS_B2B) {
            hasError = businessNameValidation() || hasError;
            hasError = businessIdValidation() || hasError;
        }

        const postCodeError = postCodeValidation();
        hasError = postCodeError || hasError;

        const phoneError = phoneValidation();
        hasError = phoneError || hasError;

        if (nifInputRef?.current?.value === '' && isNIFEnabled) {
            hasError = true;
            setNifCodeRequiredError(true);
        }

        return hasError;
    };

    const handleTitleChange = (selectedOption: any) => {
        setSelectedTitle(selectedOption);
    };

    const nextPageRedirect = async () => {
        await sessionInfoApi.updateMopCustomer(getSessionIdCookie());
        router.push('/checkout/pre-order');
    };

    const saveUserData = async () => {
        if (await inputValidation()) {
            return;
        }

        setShowLegalRequiredErrors(false);
        if (
            legalConsentVersion === ModalVersion?.v2 &&
            selectedLegalValues.filter(
                (value) =>
                    !value.includes(LEGAL_CONSENTS_VALUES.AGREE) && !value.includes(LEGAL_CONSENTS_VALUES.NOT_AGREE)
            ).length > 0
        ) {
            setShowLegalRequiredErrors(true);
            return;
        }

        const emailUsed = await isEmailUsed();

        setIsCreatingAccount(true);
        const { data } = await DealService.saveUser(currentDeal.token, {
            email: emailInputRef?.current?.value,
            firstName: firstNameInputRef?.current?.value,
            lastName: lastNameInputRef?.current?.value,
            phoneNumber: phoneInputRef?.current?.value,
            address1: address1InputRef?.current?.value,
            address2: address2InputRef?.current?.value,
            city: cityInputRef?.current?.value,
            postCode: postalCodeInputRef?.current?.value,
            ...(isMarketES && isNIFEnabled && { nif: nifInputRef?.current?.value }),
            ...(companyNumberAllowed && {
                businessID: businessIdInputRef?.current?.value,
            }),
            ...(companyNameAllowed && { businessName: businessNameInputRef?.current?.value }),
            ...(companyVATAllowed && {
                businessVAT: businessVatInputRef?.current?.value,
            }),
            createAccount: !emailUsed, // only create account when email not used
            title: selectedTitle?.value,
            ...((!legalConsentVersion || legalConsentVersion != ModalVersion?.v2) && {
                privacyConsentEmail: contactByEmail,
                privacyConsentPhone: contactByPhone,
                privacyConsentPost: contactByLetter,
                privacyConsentSms: contactByMessage,
            }),
            ...(legalConsentVersion === ModalVersion?.v2 && {
                privacyConsentStayInTouch: selectedLegalValues[0].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
                privacyConsentGetBetterDeals: selectedLegalValues[1].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
                privacyConsentJoinOurPartners: selectedLegalValues[2].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
            }),
        });
        setSessionIdCookie(data?.jwt);

        dispatch(
            userDuck.setUserAddress({
                address1: address1InputRef?.current?.value,
                address2: address2InputRef?.current?.value,
                city: cityInputRef?.current?.value,
                postcode: postalCodeInputRef?.current?.value,
                ...(isMarketES && isNIFEnabled && { nif: nifInputRef?.current?.value }),
            })
        );
        dispatch(
            userDuck.setUserDetails({
                firstname: firstNameInputRef?.current?.value,
                lastname: lastNameInputRef?.current?.value,
                email: emailInputRef?.current?.value,
                telephone: phoneInputRef?.current?.value,
                title: selectedTitle?.value,
                ...(companyNumberAllowed && {
                    businessID: String(businessIdInputRef?.current?.value),
                }),
                ...(companyNameAllowed && {
                    businessName: businessNameInputRef?.current?.value,
                }),
                ...(companyVATAllowed && {
                    businessVAT: String(businessVatInputRef?.current?.value),
                }),
            })
        );
        dispatch(UserDuck.actionSetUserLogged());

        await nextPageRedirect();
        setIsCreatingAccount(false);
    };

    const brandIdRedirectLogin = () => {
        const brandIdState: any = {
            redirectTo: process.env.NEXT_PUBLIC_CHECKOUT_AFTER_LOGIN_REDIRECT,
        };

        // Add redirect bounce to state if its defined
        if (BRANDID_REDIRECT_BOUNCE_URL) {
            brandIdState.redirectBounce = BRANDID_REDIRECT_BOUNCE_URL;
        }

        const brandIdStateJson: string = JSON.stringify(brandIdState);
        const url = BRANDID_LOGIN_URL.replace('{clientId}', BRANDID_CLIENT_ID).replace(
            '{redirectURL}',
            BRANDID_RETURN_URL
        );
        brandIdRedirect(url, brandIdStateJson, {
            isLogin: true,
            isGigyaEnabled,
        });
    };

    if (!isLoading && Object.entries(carDetails).length === 0) {
        return <RedirectionModal />;
    }

    const getB2BComponent = (fieldName: B2B_FIELD) => {
        switch (fieldName) {
            case B2B_FIELD.COMPANY_NAME:
                return (
                    <div data-testid="companyName" id="companyName" className="input-section">
                        <span className="input-section-title">{t('checkout.myDetails.input.businessName.title')}</span>
                        <input
                            ref={businessNameInputRef}
                            onKeyUp={() => businessNameValidation()}
                            className={`input-section-input ${businessNameError ? 'error' : ''}`}
                            placeholder={t('checkout.myDetails.input.businessName.placeholder')}
                        />
                        {businessNameError && (
                            <span className="input-section-error" data-testid="companyNameError">
                                {t('checkout.myDetails.input.businessName.error')}
                            </span>
                        )}
                    </div>
                );
            case B2B_FIELD.COMPANY_NUMBER:
                return (
                    <div className="input-section">
                        <span className="input-section-title">{t('checkout.myDetails.input.businessID.title')}</span>
                        <input
                            ref={businessIdInputRef}
                            onKeyUp={() => businessIdValidation()}
                            className={`input-section-input ${businessIdError ? 'error' : ''}`}
                            placeholder={t('checkout.myDetails.input.businessID.placeholder')}
                        />
                        {businessIdError && (
                            <span className="input-section-error">
                                {t('checkout.myDetails.input.businessID.error')}
                            </span>
                        )}
                    </div>
                );
            case B2B_FIELD.COMPANY_VAT:
                return (
                    <div className="input-section">
                        <span className="input-section-title">{t('checkout.myDetails.input.businessVat.title')}</span>
                        <input
                            ref={businessVatInputRef}
                            className="input-section-input"
                            placeholder={t('checkout.myDetails.input.businessVat.placeholder')}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className={className}>
            <Grid className="wrapper">
                <div className="wrapper-space">
                    <h1 className="wrapper-title">{t('checkout.user.details.header')}</h1>
                </div>
                {!isUserLoggedIn && (
                    <div className="wrapper-login">
                        <span>
                            {t('checkout.myDetails.login.description')}{' '}
                            <button onClick={brandIdRedirectLogin}>{t('checkout.myDetails.login.button')}</button>
                        </span>
                    </div>
                )}
                <Row className="wrapper-rows">
                    <Col lg={9} md={12} sm={12} xs={12} className="wrapper-cols">
                        <Col xs={12} md={12} lg={12}>
                            <div className="email-section">
                                <div className="email-section-row">
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.email.title')}
                                        </span>
                                        <input
                                            ref={emailInputRef}
                                            onKeyUp={emailValidation}
                                            disabled={!!userDetails?.email}
                                            data-testid="checkout-user-email"
                                            className={`input-section-input ${
                                                emailRequiredError || invalidEmailError ? 'error' : ''
                                            }`}
                                            placeholder={t('checkout.myDetails.input.email.placeholder')}
                                        />
                                        {invalidEmailError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.email.error.invalid')}
                                            </span>
                                        )}
                                        {emailRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.email.error.required')}
                                            </span>
                                        )}
                                        {!isUserLoggedInAsDealer && usedEmailError && (
                                            <span className="input-section-info-text">
                                                {t('checkout.myDetails.input.email.error.used')}&nbsp;
                                                <span onClick={brandIdRedirectLogin} className="link-outside">
                                                    {t('checkout.myDetails.input.email.error.usedLink')}
                                                </span>
                                            </span>
                                        )}
                                        {!isUserLoggedIn && !usedEmailError && (
                                            <span className="input-section-info">
                                                {t('checkout.myDetails.input.email.info')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.emailConfirmation.title')}
                                        </span>
                                        <input
                                            ref={emailConfirmationInputRef}
                                            disabled={!!userDetails?.email}
                                            onKeyUp={emailConfirmationValidation}
                                            data-testid="checkout-user-confirmation-email"
                                            className={`input-section-input ${
                                                confirmationEmailRequiredError ||
                                                invalidEmailConfirmationError ||
                                                notEqualEmailConfirmationError
                                                    ? 'error'
                                                    : ''
                                            }`}
                                            placeholder={t('checkout.myDetails.input.emailConfirmation.placeholder')}
                                        />
                                        {invalidEmailConfirmationError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.email.error.invalid')}
                                            </span>
                                        )}
                                        {notEqualEmailConfirmationError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.emailConfirmation.error.notEqual')}
                                            </span>
                                        )}
                                        {confirmationEmailRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.email.error.required')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {IS_B2B && B2B_FILEDS && B2B_FILEDS?.length !== 0 && (
                                <div className="b2b-section">
                                    <div className="title">{t('checkout.myDetails.b2bSection.title')}</div>
                                    <div className="b2b-section-row">
                                        {B2B_FILEDS.slice(0, 2).map((fieldName) => getB2BComponent(fieldName))}
                                    </div>
                                    {B2B_FILEDS.length > 2 && (
                                        <div className="b2b-section-row">
                                            {B2B_FILEDS.slice(2, 4).map((fieldName) => getB2BComponent(fieldName))}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="personal-section">
                                <div className="title">{t('checkout.myDetails.personalSection.title')}</div>
                                <div className="personal-section-row">
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.civility.title')}
                                        </span>
                                        <Select
                                            placeholder={''}
                                            value={selectedTitle}
                                            options={CUSTOMER_TITLES}
                                            onChange={handleTitleChange}
                                            arrowRender={false}
                                            className="input-section-select"
                                            components={
                                                isBrandAP && {
                                                    IndicatorSeparator: () => null,
                                                }
                                            }
                                            theme={(theme) => ({ ...theme })}
                                        />
                                    </div>
                                </div>
                                <div className="personal-section-row">
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.firstName.title')}
                                        </span>
                                        <input
                                            ref={firstNameInputRef}
                                            onKeyUp={() =>
                                                inputValidation(firstNameInputRef, setFirstNameRequiredError)
                                            }
                                            className={`input-section-input ${firstNameRequiredError ? 'error' : ''}`}
                                            data-testid="checkout-user-firstName"
                                            placeholder={t('checkout.myDetails.input.firstName.placeholder')}
                                        />
                                        {firstNameRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.firstName.error.required')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.lastName.title')}
                                        </span>
                                        <input
                                            ref={lastNameInputRef}
                                            onKeyUp={() => inputValidation(lastNameInputRef, setLastNameRequiredError)}
                                            data-testid="checkout-user-lastName"
                                            className={`input-section-input ${lastNameRequiredError ? 'error' : ''}`}
                                            placeholder={t('checkout.myDetails.input.lastName.placeholder')}
                                        />
                                        {lastNameRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.lastName.error.required')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="personal-section-row">
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.phone.title')}
                                        </span>
                                        <input
                                            ref={phoneInputRef}
                                            onKeyUp={phoneValidation}
                                            className={`input-section-input ${phoneRequiredError ? 'error' : ''}`}
                                            data-testid="checkout-user-phoneNumber"
                                            placeholder={t('checkout.myDetails.input.phone.placeholder')}
                                        />
                                        {invalidPhoneErrorMessageKey && (
                                            <span className="input-section-error">
                                                {t(invalidPhoneErrorMessageKey)}
                                            </span>
                                        )}
                                        {phoneRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.phone.error.invalid')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="address-section">
                                <div className="title">{t('checkout.myDetails.addressSection.title')}</div>
                                <div className="label">{t('checkout.myDetails.addressSection.label')}</div>
                                <div className="address-section-column">
                                    <div className="input-section address">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.address1.title')}
                                        </span>
                                        <input
                                            ref={address1InputRef}
                                            onKeyUp={() => inputValidation(address1InputRef, setAddressRequiredError)}
                                            className={`input-section-input address ${
                                                phoneRequiredError ? 'error' : ''
                                            }`}
                                            data-testid="checkout-user-address1"
                                            placeholder={t('checkout.myDetails.input.address1.placeholder')}
                                        />
                                        {addressRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.address1.error.required')}
                                            </span>
                                        )}
                                        <input
                                            ref={address2InputRef}
                                            className="input-section-input address optional"
                                            data-testid="checkout-user-address2"
                                            placeholder={t('checkout.myDetails.input.address2.placeholder')}
                                        />
                                    </div>
                                </div>
                                <div className="address-section-row">
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.city.title')}
                                        </span>
                                        <input
                                            ref={cityInputRef}
                                            onKeyUp={() => inputValidation(cityInputRef, setCityRequiredError)}
                                            className={`input-section-input ${cityRequiredError ? 'error' : ''}`}
                                            data-testid="checkout-user-city"
                                            placeholder={t('checkout.myDetails.input.city.placeholder')}
                                        />
                                        {cityRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.city.error.required')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="input-section">
                                        <span className="input-section-title">
                                            {t('checkout.myDetails.input.postalCode.title')}
                                        </span>
                                        <input
                                            ref={postalCodeInputRef}
                                            onKeyUp={postCodeValidation}
                                            className={`input-section-input ${postCodeRequiredError ? 'error' : ''}`}
                                            data-testid="checkout-user-postalCode"
                                            placeholder={t('checkout.myDetails.input.postalCode.placeholder')}
                                        />
                                        {postCodeRequiredError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.postalCode.error.required')}
                                            </span>
                                        )}
                                        {invalidPostCodeError && (
                                            <span className="input-section-error">
                                                {t('checkout.myDetails.input.postalCode.error.invalid')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {isMarketES && isNIFEnabled && (
                                    <div className="address-section-row">
                                        <div className="input-section">
                                            <span className="input-section-title">
                                                {t('checkout.myDetails.input.nif.title')}
                                            </span>
                                            <input
                                                ref={nifInputRef}
                                                onKeyUp={nifCodeValidation}
                                                className="input-section-input"
                                                placeholder={t('checkout.myDetails.input.nif.placeholder')}
                                            />
                                            {nifCodeRequiredError && (
                                                <span className="input-section-error">
                                                    {t('checkout.error.required')}
                                                </span>
                                            )}
                                            {nifCodeInvalidError && (
                                                <span className="input-section-error">{t('checkout.error.nif')}</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="required-section">
                                <p>{t('checkout.myDetails.required.label')}</p>
                            </div>
                            <div className="privacy">
                                <p className="firstLegalMention">
                                    <span
                                        dangerouslySetInnerHTML={getNotEscapedTranslationMarkup(
                                            'checkout.myDetails.legalMention.first'
                                        )}
                                    ></span>
                                </p>
                                <span
                                    className="title"
                                    dangerouslySetInnerHTML={getNotEscapedTranslationMarkup(
                                        'checkout.myDetails.legalMention.title'
                                    )}
                                ></span>
                                <p className="subTitle">
                                    <span
                                        dangerouslySetInnerHTML={getNotEscapedTranslationMarkup(
                                            'checkout.myDetails.legalMention.subTitle'
                                        )}
                                    ></span>
                                </p>
                                {legalConsentVersion === ModalVersion?.v1 && (
                                    <div>
                                        <div className="flags">
                                            <Checkbox
                                                name="mail"
                                                checked={contactByEmail}
                                                onChange={() => setContactByEmail(!contactByEmail)}
                                            >
                                                {t('checkout.myDetails.legalMention.contact.email')}
                                            </Checkbox>
                                            <Checkbox
                                                name="phone"
                                                checked={contactByPhone}
                                                onChange={() => setContactByPhone(!contactByPhone)}
                                            >
                                                {t('checkout.myDetails.legalMention.contact.phone')}
                                            </Checkbox>
                                            <Checkbox
                                                name="sms"
                                                checked={contactByMessage}
                                                onChange={() => setContactByMessage(!contactByMessage)}
                                            >
                                                {t('checkout.myDetails.legalMention.contact.message')}
                                            </Checkbox>
                                            <Checkbox
                                                name="letter"
                                                checked={contactByLetter}
                                                onChange={() => setContactByLetter(!contactByLetter)}
                                            >
                                                {t('checkout.myDetails.legalMention.contact.letter')}
                                            </Checkbox>
                                        </div>
                                    </div>
                                )}
                                {legalConsentVersion === ModalVersion?.v2 && (
                                    <div>
                                        <LegalTermsAndConditionsConsent
                                            showRequiredErrors={showLegalRequiredErrors}
                                            setSelectedLegalValues={setSelectedLegalValues}
                                        />
                                    </div>
                                )}
                                <div className="otherLegalMention1">
                                    <span
                                        dangerouslySetInnerHTML={getNotEscapedTranslationMarkup(
                                            'checkout.myDetails.legalMention.contact.terms1'
                                        )}
                                    />
                                </div>
                                <div className="otherLegalMention2">
                                    <span
                                        dangerouslySetInnerHTML={getNotEscapedTranslationMarkup(
                                            'checkout.myDetails.legalMention.contact.terms2'
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="confirm-section">
                                <Button
                                    onClick={saveUserData}
                                    disabled={isCreatingAccount}
                                    primary
                                    data-testId="myDetailsSubmit"
                                >
                                    {isCreatingAccount ? (
                                        <Spinner
                                            size={12}
                                            border={2}
                                            color={(isMarketES || isMarketFR) && isBrandOV ? 'black' : 'white'}
                                        />
                                    ) : (
                                        t('checkout.myDetails.createAccount.button')
                                    )}
                                </Button>
                            </div>
                        </Col>
                    </Col>
                    <Col className="wrapper-cols" xs={12} sm={12} md={12} lg={3}>
                        <CheckoutTipsSidebar
                            title={'checkout.myDetails.tips.header'}
                            items={getSidebarItems(
                                currentDeal.journeyType === MySavedDealJourneyType.BUY_ONLINE_FINANCE,
                                skipPaymentDeposit(currentDeal)
                            )}
                            isStockCar={carDetails.stock}
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

const mapStateToProps: (state: IGlobalState) => Props = (state) => ({
    carDetails: CarDetailsDuck.getOwnState(state).currentCarDetails,
    budget: FilterDuck.getPaymentJourneyType(state),
    // @ts-ignoreTS
    dealer: DealerDuck.getOwnState(state),
});

export default connect(mapStateToProps)(CheckoutMyDetails);
