import styled from 'styled-components';
import { ModalDealerSearchStyled } from '../ModalDealerSearchStyled';
import { rem } from 'polished';

export const ModalDealerSearchStyledAC = styled(ModalDealerSearchStyled)`
   
    .dealerSearch {
        height: auto;
        
        button {
            font-size: ${(props) => props.theme.fontSizes.h4};
            font-family: ${(props) => props.theme.fonts.fontBase};
        }
        
        &__input {
            input {
                border: 1px solid ${(props) => props.theme.colors.grey3};
                border-radius: ${rem(12)};
            }
        }
    }
    
    .dealerList {
        .dealerItem {
            color: ${(props) => props.theme.colors.grey5};
            
            &__icon {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxNCAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMEMzLjEzMzU5IDAgMCAzLjEyNDQgMCA2Ljk3OTQ3QzAgMTEuNzM0MiA1LjM2NDg0IDEzLjQ2ODIgNi41MzI0MiAyMC44MzQ4QzYuNjA4OTggMjEuMzA5MiA2LjYxNDQ1IDIxLjYzNjQgNyAyMS42MzY0QzcuMzg1NTUgMjEuNjM2NCA3LjM5MTAyIDIxLjMwOTIgNy40Njc1OCAyMC44MzQ4QzguNjM1MTYgMTMuNDY4MiAxNCAxMS43MzQyIDE0IDYuOTc5NDdDMTQgMy4xMjQ0IDEwLjg2NjQgMCA3IDBaIiBmaWxsPSIjRDczRjM1Ii8+Cjwvc3ZnPg==')
                    top center no-repeat;
            }
        
            &__footer {
                button {
                    font-size: ${(props) => props.theme.fontSizes.h4};
                    color: ${(props) => props.theme.colors.blue2};
                    background: ${(props) => props.theme.colors.white};
                    border: 1px solid ${(props) => props.theme.colors.blue2};
                    border-radius: ${rem(24)};
                    text-transform: capitalize;
                    
                    &:hover {
                        color: ${(props) => props.theme.colors.grey5};
                        border-color: ${(props) => props.theme.colors.grey5};
                    }
                }
            }
        }
    }
`;
