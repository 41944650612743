import styled from 'styled-components';
import { CheckoutFinanceWidgetStyled } from '../CheckoutFinanceWidgetStyled';

export const CheckoutFinanceWidgetStyledAP = styled(CheckoutFinanceWidgetStyled)`
    .finance-summary {
        &--col-1,
        &--col-2,
        &--col-3,
        &__legalTitle,
        &__legalText,
        &__legalText2 {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            line-height: ${({ theme }) => theme.lineHeights.textMedium};

            &.sized-up-style {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }
        }
    }

    a {
        color: ${({ theme }) => theme.colors.primary};

        &:hover {
            color: ${({ theme }) => theme.colors.primaryBlack};
        }
    }
`;
