import styled from 'styled-components';
import { ModalLeadFormStyled } from '../ModalLeadFormStyled';
import { rem } from 'polished';

export const ModalLeadFormStyledDS = styled(ModalLeadFormStyled)`
    .btn-primary {
        width: 100%;
        padding: ${rem(6)} ${rem(20)};
    }
    .legal_description {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        line-height: ${rem(18)};
        margin: ${rem(32)} 0 ${rem(16)} 0;
    }
    .legal_agreement_title {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
        padding: ${rem(16)} 0;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0;

        &.isOpen {
            svg {
                transform: rotate(-90deg);
            }
        }

        &.isClose {
            svg {
                transform: rotate(90deg);
            }
        }
    }
    .legal_agreement_description {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        padding: 0 ${rem(16)} ${rem(20)} ${rem(16)};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
    }
    .agreement1 div div,
    .agreement2 div div,
    .agreement3 div div {
        display: flex;
        gap: ${rem(30)};
    }
    .form-field {
        svg {
            width: ${rem(20)};
            height: ${rem(20)};
            position: absolute;
            right: 0;
            top: ${rem(12)};

            path {
                fill: ${({ theme }) => theme.colors.primaryBlack};
                stroke: ${({ theme }) => theme.colors.grey7};
                stroke-width: 1;
            }
        }
    }
`;
