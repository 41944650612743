import React from 'react';
import { isBrandAC } from 'src/constants/main';
import { Icon } from '@components/Icon';
import Button from '@components/Button';
import { LinkProps } from './index';
import Spinner from '@components/Spinner';
import parser from 'bbcode-to-react';

export const LinkTemplate: React.FC<LinkProps> = ({
    className,
    onClick,
    txt,
    iconName,
    iconClassName,
    iconAltTxt,
    iconAltId,
    iconWidth = 12,
    iconHeight = 10,
    dataTestid,
    href,
    style,
    isLoading,
    loader,
    withParent = true,
    ignoreParent= false,
    id,
    tabIndex,
    children, // TODO: This needs to be removed once all brands will be switched to using generic component
    ...rest
}) => {
    const formatLinkText = (txt: string | React.ReactElement) => {
        try {
            return parser.toReact(txt);
        } catch {
            return txt;
        }
    };

    const Content = () => (
        <>
            {isLoading ? (
                loader || <Spinner size={12} border={2} color="black" />
            ) : (
                <>
                    <span className="linkText">{formatLinkText(txt)}</span>
                    {iconName && (
                        <Icon
                            name={iconName}
                            altText={iconAltTxt}
                            altId={iconAltId}
                            className={`${iconClassName} linkIcon`}
                            width={iconWidth}
                            height={iconHeight}
                        />
                    )}
                </>
            )}
        </>
    );

    const Component = () => (
        <>
            {!href ? (
                <Button
                    {...rest}
                    tabIndex={tabIndex}
                    id={id}
                    data-testid={dataTestid}
                    className={className}
                    onClick={(e) => onClick?.(e)}
                >
                    <Content />
                </Button>
            ) : (
                <a {...rest} id={id} href={href} data-testid={dataTestid} className={className} onClick={(e) => onClick?.(e)}>
                    <Content />
                </a>
            )}
        </>
    );

    const Parent = () => (
        <div style={style}>
            <Component />
        </div>
    );

    if (isBrandAC && !ignoreParent) {
        return withParent ? <Parent /> : <Component />;
    }
    return children;
};
