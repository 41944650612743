/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SC, theme } from 'src/styles/theme';
import Button from '@components/Button';
import useTranslations from '@hooks/useTranslations';
import { Icon, Icons } from '@components/Icon';
import { EMAIL_REGEX } from '@utils/checkoutInputsValidation';
import Spinner from '@components/Spinner';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import moment from 'moment';
import { IModalDealerSearchState } from '@components/Modal/ModalDealerSearch/ModalDealerSearchTemplate';
import Checkbox from '@components/Checkbox';
import { B2B_FILEDS, civilities, IS_B2B, isMarketGB, LANGUAGE, LANGUAGES, ModalVersion } from 'src/constants/main';
import { Pages } from '../../../types/pages';
// @ts-ignore
import DatePicker from 'react-datepicker';
import { getDateFormatByLang } from '@utils/Date.utils';
import sessionInfoApi from '../../../services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { B2B_FIELD, LEGAL_CONSENTS_VALUES } from '../../../constants/enums';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';
import { FEATURES_LIST, FeatureSwitchContext } from '../../../context/featureSwitchApp';
import LegalTermsAndConditionsConsent from '@components/LegalTermsAndConditionsConsent';
import { MODAL_WRAPPER_ID } from '@components/Modal/ModalTemplate';
import Link from '@components/Link';
import { TESTING_PREFIX } from '@components/Modal/ModalShareConfigurationDealerCode/ModalShareConfigurationDealerCodeTemplate';

const ModalIcon = styled(Icon)``;

const Row = styled.div<{ isSalesManIdEnabled?: boolean }>`
    display: flex;
    width: 100%;
    flex-direction: column;

    .row-inner {
        width: 100%;
    }
`;

const Cancel = styled(Button)``;

export interface ModalShareConfigurationDealerCodeProps extends SC {
    closeModal: () => void;
    previousState?: IModalDealerSearchState;
    page?: Pages;
}

const ModalShareConfigurationPersonalizeOfferTemplate: FC<ModalShareConfigurationDealerCodeProps> = ({
    closeModal,
    className,
    previousState,
}) => {
    const emailInputRef = useRef<HTMLInputElement>();
    const firstNameInputRef = useRef<HTMLInputElement>();
    const lastNameInputRef = useRef<HTMLInputElement>();
    const context = useContext(FeatureSwitchContext);
    const companyNameInputRef = useRef<HTMLInputElement>();
    const { t } = useTranslations();

    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [contactByEmail, setContactByEmail] = useState(Boolean(previousState?.contactByEmail));
    const [contactByMessage, setContactByMessage] = useState(Boolean(previousState?.contactByMessage));
    const [contactByPhone, setContactByPhone] = useState(Boolean(previousState?.contactByPhone));
    const [agreement, setAgreement] = useState(Boolean(previousState?.agreement));
    const [civility, setCivility] = useState(previousState?.civility);
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [showLegalRequiredErrors, setShowLegalRequiredErrors] = useState(false);
    const [validityDate, setValidityDate] = useState(null);

    const modalRef = useRef(null);

    const [isSend, setIsSend] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const [emailInvalid, setEmailInvalid] = useState(false);
    const [firstNameRequired, setFirstNameRequired] = useState(false);
    const [lastNameRequired, setLastNameRequired] = useState(false);
    const [civilityRequired, setCivilityRequired] = useState(false);
    const [validityRequired, setValidityRequired] = useState(false);
    const [companyNameRequired, setCompanyNameRequired] = useState(false);
    const [selectedLegalValues, setSelectedLegalValues] = useState([]);

    const legalConsentVersion = getFeatureSwitch(FEATURES_LIST.FEATURE_SWITCH_LEGAL_CONSENT_VERSION, context);

    const copyLink = async () => {
        setValidityRequired(false);
        if (!validityDate) {
            setValidityRequired(true);
            return;
        }

        setIsLoading(true);

        const { data } = await sessionInfoApi.copyLinkAgentPortal(
            moment(validityDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SS') + 'Z',
            getSessionIdCookie()
        );

        if (!data?.success) {
            setIsFailed(true);
        }

        if (navigator.clipboard && window.isSecureContext) {
            setIsLoading(false);
            setIsCopied(true);
            return await navigator.clipboard.writeText(data?.link);
        }
    };

    const submitAssistedLeadForm = async () => {
        let isValidationErrors = false;

        setShowLegalRequiredErrors(true);

        setCivilityRequired(false);
        if (!civility) {
            setCivilityRequired(true);
            isValidationErrors = true;
        }

        setEmailInvalid(false);
        const email = emailInputRef?.current?.value || '';
        if (!EMAIL_REGEX.test(email)) {
            setEmailInvalid(true);
            isValidationErrors = true;
        }
        if (email) setCustomerEmail(email);

        setFirstNameRequired(false);
        if (!firstNameInputRef?.current?.value) {
            setFirstNameRequired(true);
            isValidationErrors = true;
        }

        setLastNameRequired(false);
        if (!lastNameInputRef?.current?.value) {
            setLastNameRequired(true);
            isValidationErrors = true;
        }

        setValidityRequired(false);
        if (!validityDate) {
            setValidityRequired(true);
            isValidationErrors = true;
        }

        setCompanyNameRequired(false);
        if (
            !companyNameInputRef?.current?.value &&
            IS_B2B &&
            B2B_FILEDS?.find((fieldName) => fieldName === B2B_FIELD.COMPANY_NAME)
        ) {
            setCompanyNameRequired(true);
            isValidationErrors = true;
        }

        if (
            legalConsentVersion === ModalVersion.v2 &&
            selectedLegalValues.filter(
                (value) =>
                    !value.includes(LEGAL_CONSENTS_VALUES.AGREE) && !value.includes(LEGAL_CONSENTS_VALUES.NOT_AGREE)
            ).length > 0
        ) {
            return;
        }

        if (isValidationErrors) {
            const modal = document.getElementById(MODAL_WRAPPER_ID);
            modal.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            return;
        }

        setIsLoading(true);

        const legalConsentData = {
            ...(legalConsentVersion === ModalVersion.v2 && {
                checkBox1Meaning: 'MkgFCA',
                checkBox1Value: selectedLegalValues[0].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
                checkBox2Meaning: 'ProfFCA',
                checkBox2Value: selectedLegalValues[1].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
                checkBox3Meaning: 'MkgThirdPartyFCA',
                checkBox3Value: selectedLegalValues[2].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
            }),
            ...((!legalConsentVersion || legalConsentVersion != ModalVersion.v2) && {
                checkBox1Meaning: 'MkgBrandMail',
                checkBox1Value: !!contactByEmail,
                checkBox2Meaning: 'MkgBrandSMS',
                checkBox2Value: !!contactByMessage,
                checkBox3Meaning: 'MkgBrandPhone',
                checkBox3Value: !!contactByPhone,
            }),
        };

        try {
            const { data } = await sessionInfoApi.shareConfigurationEmailAgentPortal(
                emailInputRef?.current?.value,
                getSessionIdCookie(),
                civility,
                firstNameInputRef?.current?.value,
                lastNameInputRef?.current?.value,
                moment(validityDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SS') + 'Z',
                companyNameInputRef?.current?.value,
                legalConsentData.checkBox1Meaning,
                legalConsentData.checkBox1Value,
                legalConsentData.checkBox2Meaning,
                legalConsentData.checkBox2Value,
                legalConsentData.checkBox3Meaning,
                legalConsentData.checkBox3Value
            );

            if (!data?.success) {
                setIsFailed(true);
            }

            setIsLoading(false);
            setIsSend(true);
        } catch (e: any) {
            setIsLoading(false);
            setIsFailed(true);
        }
    };

    return (
        <div ref={modalRef} className={className}>
            {!isSend ? (
                <>
                    <div className="share-wrapper">
                        <div className="input-wrapper">
                            <Row>
                                <div className="input-section row-inner">
                                    <span className="input-section-label">
                                        {t('modal.personalizeOfferShareConfiguration.civility.label')}
                                    </span>
                                    <div className="civilitySection">
                                        {civilities[LANGUAGE as LANGUAGES]?.map(
                                            (civilityLabel: string, index: number) => (
                                                <div key={index} className="civility">
                                                    <div
                                                        onClick={() => {
                                                            setCivilityRequired(false);
                                                            setCivility(civilityLabel);
                                                        }}
                                                        className={`check ${civilityRequired ? 'checkError' : ''}`}
                                                    >
                                                        {civility === civilityLabel && <div className="selected" />}
                                                    </div>
                                                    <span>{civilityLabel}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="civilitySectionError">
                                        {civilityRequired && (
                                            <span className="civilityError">
                                                {t('modal.personalizeOfferShareConfiguration.civility.required.error')}
                                            </span>
                                        )}
                                    </div>
                                    <span className="input-section-label">
                                        {t('modal.personalizeOfferShareConfiguration.email.label')}
                                    </span>
                                    <input
                                        ref={emailInputRef}
                                        placeholder={t('modal.personalizeOfferShareConfiguration.email.placeholder')}
                                        onChange={() => setEmailInvalid(false)}
                                        className={emailInvalid ? 'error' : ''}
                                    />
                                    {emailInvalid && (
                                        <span className="emailError">
                                            {t('modal.personalizeOfferShareConfiguration.email.required.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <div className="input-section row-inner">
                                    <span className="input-section-label">
                                        {t('modal.personalizeOfferShareConfiguration.firstName.label')}
                                    </span>
                                    <input
                                        ref={firstNameInputRef}
                                        placeholder={t(
                                            'modal.personalizeOfferShareConfiguration.firstName.placeholder'
                                        )}
                                        onChange={() => setFirstNameRequired(false)}
                                        className={firstNameRequired ? 'error' : ''}
                                    />
                                    {firstNameRequired && (
                                        <span className="emailError">
                                            {t('modal.personalizeOfferShareConfiguration.firstName.required.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <div className="input-section row-inner">
                                    <span className="input-section-label">
                                        {t('modal.personalizeOfferShareConfiguration.lastName.label')}
                                    </span>
                                    <input
                                        ref={lastNameInputRef}
                                        placeholder={t('modal.personalizeOfferShareConfiguration.lastName.placeholder')}
                                        onChange={() => setLastNameRequired(false)}
                                        className={lastNameRequired ? 'error' : ''}
                                    />
                                    {lastNameRequired && (
                                        <span className="emailError">
                                            {t('modal.personalizeOfferShareConfiguration.lastName.required.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            {IS_B2B &&
                                B2B_FILEDS &&
                                B2B_FILEDS?.length !== 0 &&
                                B2B_FILEDS.find((fieldName) => fieldName === B2B_FIELD.COMPANY_NAME) && (
                                    <Row>
                                        <div className="input-section row-inner">
                                            <span className="input-section-label">
                                                {t('modal.dealerShareConfiguration.companyName.placeholder')}
                                            </span>
                                            <input
                                                ref={companyNameInputRef}
                                                placeholder={t(
                                                    'modal.dealerShareConfiguration.companyName.placeholder'
                                                )}
                                                onChange={() => setCompanyNameRequired(false)}
                                                className={companyNameRequired ? 'error' : ''}
                                            />
                                            {companyNameRequired && (
                                                <span className="emailError">
                                                    {t('modal.dealerShareConfiguration.companyName.required.error')}
                                                </span>
                                            )}
                                        </div>
                                    </Row>
                                )}
                            <Row>
                                <div className="input-section row-inner">
                                    <span className="input-section-label">
                                        {t('modal.personalizeOfferShareConfiguration.validity.label')}
                                    </span>
                                    <div className={`datePickerSection ${validityRequired ? 'error' : ''}`}>
                                        <DatePicker
                                            selected={validityDate}
                                            onChange={(date: Date) => {
                                                setValidityRequired(false);
                                                setValidityDate(date);
                                            }}
                                            dateFormat={getDateFormatByLang()}
                                            placeholderText={t('dateFormat.default')}
                                            minDate={moment().toDate()}
                                            calendarStartDay={isMarketGB ? 0 : 1}
                                        />
                                        {validityRequired && (
                                            <span className="validityError">
                                                {t('modal.personalizeOfferShareConfiguration.validity.required.error')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <p className="mandatory">{t('modal.personalizeOfferShareConfiguration.mandatory')}</p>
                            </Row>
                            {!legalConsentVersion ||
                                (legalConsentVersion === ModalVersion.v1 && (
                                    <>
                                        <div className="checkboxSection-title">
                                            <p>{t('modal.dealerShareConfiguration.contact.title')}</p>
                                        </div>
                                        <div className="checkboxSection">
                                            <Checkbox
                                                name="mail"
                                                checked={contactByEmail}
                                                onChange={() => setContactByEmail(!contactByEmail)}
                                            >
                                                {t('modal.dealerShareConfiguration.contact.email')}
                                            </Checkbox>
                                            <Checkbox
                                                name="sms"
                                                checked={contactByMessage}
                                                onChange={() => setContactByMessage(!contactByMessage)}
                                            >
                                                {t('modal.dealerShareConfiguration.contact.message')}
                                            </Checkbox>
                                            <Checkbox
                                                name="phone"
                                                checked={contactByPhone}
                                                onChange={() => setContactByPhone(!contactByPhone)}
                                            >
                                                {t('modal.dealerShareConfiguration.contact.phone')}
                                            </Checkbox>
                                        </div>
                                    </>
                                ))}
                            {legalConsentVersion === ModalVersion.v2 && (
                                <LegalTermsAndConditionsConsent
                                    showRequiredErrors={showLegalRequiredErrors}
                                    setSelectedLegalValues={setSelectedLegalValues}
                                />
                            )}
                            <div className="checkboxSection agreement">
                                <Checkbox
                                    name="agreement"
                                    checked={agreement}
                                    onChange={() => setAgreement(!agreement)}
                                >
                                    {t('modal.personalizeOfferShareConfiguration.contact.verify')}
                                </Checkbox>
                            </div>
                        </div>
                        <Button className="submit" onClick={submitAssistedLeadForm} primary disabled={isLoading}>
                            {isLoading ? (
                                <Spinner size={16} border={2} color="white" />
                            ) : (
                                <span>{t('modal.personalizeOfferShareConfiguration.confirm')}</span>
                            )}
                        </Button>
                        <div className="footer">
                            <p>{t('modal.dealerShareConfiguration.copyLink.description')}</p>
                            {isLoading ? (
                                <Spinner size={16} border={2} color="black" />
                            ) : (
                                <Link
                                    marginTop={5}
                                    marginBottom={5}
                                    label={t('modal.dealerShareConfiguration.copyLink.link')}
                                    onClick={copyLink}
                                    withArrowIcon
                                    primary
                                    dataTestId={`${TESTING_PREFIX}copyLink_link`}
                                />
                            )}
                        </div>
                        {isCopied && (
                            <div className="copiedLinkText">
                                <Icon height={10} name={Icons.Check} />{' '}
                                {t('modal.dealerShareConfiguration.copyLink.success')}
                            </div>
                        )}
                    </div>
                </>
            ) : isFailed ? (
                <div className="sent-wrapper">
                    <ModalIcon name={Icons.AlertOutlined} width={72} height={72} />
                    <div className="text">{t('modal.personalizeShareConfiguration.message.error')}</div>
                    <Cancel onClick={closeModal}>{t('shareConfiguration.modal.closeBtn')}</Cancel>
                </div>
            ) : (
                <div className="sent-wrapper">
                    <ModalIcon name={Icons.ShareConfigurationModalIcon} width={72} height={72} />
                    <div className="text">
                        {t('modal.personalizeShareConfiguration.message', { email: customerEmail || '' })}
                    </div>
                    <Cancel onClick={closeModal}>{t('shareConfiguration.modal.closeBtn')}</Cancel>
                </div>
            )}
        </div>
    );
};

export default ModalShareConfigurationPersonalizeOfferTemplate;
