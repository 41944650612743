import styled from 'styled-components';
import { ModalCarOptionDetailStyled } from '../ModalCarOptionDetailStyled';
import { rem } from 'polished';

export const ModalCarOptionDetailStyledAC = styled(ModalCarOptionDetailStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h4};

    .option-detail {
        &__category {
            color: ${({ theme }) => theme.colors.grey5};
        }

        &__title {
            font-size: ${rem(30)};
            color: ${({ theme }) => theme.colors.grey5};
            line-height: ${rem(38)};
        }

        &__description {
            color: ${({ theme }) => theme.colors.grey5};
            line-height: ${rem(20)};
        }
    }

    .tires-detail {
        background: ${({ theme }) => theme.colors.grey6};
        color: ${({ theme }) => theme.colors.grey5};

        &__description {
            border-color: ${({ theme }) => theme.colors.grey3};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            line-height: ${rem(20)};
        }

        &__image {
            border-color: ${({ theme }) => theme.colors.grey3};
        }

        &__link {
            color: ${({ theme }) => theme.colors.blue2};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                width: ${rem(10)};
                height: ${rem(10)};
                margin-left: ${rem(5)};
                margin-bottom: ${rem(1)};

                path {
                    fill: ${({ theme }) => theme.colors.blue2};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.grey5};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.grey5};
                    }
                }
            }
        }
    }
`;
