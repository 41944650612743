import styled from 'styled-components';
import { ModalCarOptionDetailStyled } from '../ModalCarOptionDetailStyled';
import { rem } from 'polished';

export const ModalCarOptionDetailStyledDS = styled(ModalCarOptionDetailStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h5};

    .option-detail {
        &__category {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        &__title {
            font-size: ${rem(30)};
            line-height: ${rem(38)};
        }

        &__description {
            line-height: ${rem(20)};
        }
    }

    .tires-detail {
        background: ${({ theme }) => theme.colors.grey16};

        &__title {
            text-transform: uppercase;
        }

        &__description {
            border-color: #b5b6b6;
            font-size: ${({ theme }) => theme.fontSizes.h5};
            line-height: ${rem(20)};
        }

        &__image {
            border-color: #b5b6b6;
        }

        &__link {
            color: ${({ theme }) => theme.colors.black1};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                width: ${rem(8)};
                height: ${rem(8)};
                margin-left: ${rem(5)};
                margin-bottom: ${rem(1)};
                transform: rotate(-90deg);

                path {
                    fill: ${({ theme }) => theme.colors.black1};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.black1};
                text-decoration: underline;
            }
        }
    }
`;
