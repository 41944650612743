import { rem } from 'polished';
import styled from 'styled-components';
import PromoCodeTemplate from './PromoCodeTemplate';
import { IPromoCode } from './index';

export const PromoCodeStyled = styled(PromoCodeTemplate)`
    .promoCode {
        &__title {
            ${(prop) =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          padding: '15px',
                          background: '#F7F7F7',
                      }
                    : {
                          padding: '15px 0',
                          background: 'unset',
                      }};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        &__content {
            display: flex;
            justify-content: space-between;
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            ${(prop) =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          padding: '10px 15px',
                      }
                    : {
                          padding: '10px 0',
                      }};
        }

        &__removeBtn {
            background: unset;
            border: none;
            text-decoration: underline;
            cursor: pointer;
            padding: 0;

            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }

        &__legalTextOpen {
            cursor: pointer;
            svg {
                width: ${rem(14)};
                height: ${rem(14)};
                path {
                    stroke: ${({ theme }) => theme.colors.black};
                    stroke-width: 2;
                }
            }
        }

        &__legalTextClose {
            cursor: pointer;
            svg {
                width: ${rem(14)};
                height: ${rem(14)};
                path {
                    stroke: ${({ theme }) => theme.colors.black};
                    stroke-width: 1;
                }
            }
        }

        &__legalText {
            padding-top: ${rem(10)};

            a {
                color: ${({ theme }) => theme.colors.primaryBlack};
            }
        }
    }
`;
