import styled from 'styled-components';
import { rem } from 'polished';
import { ModalIncompletePxStyled } from '../ModalIncompletePxStyled';

export const ModalIncompletePxStyledAC = styled(ModalIncompletePxStyled)`
    .description {
        font-size: ${({ theme }) => theme.fontSizes.h3};
        line-height: ${rem(26)};
    }
`;
