import styled from 'styled-components';
import { rem } from 'polished';
import media from '../../styles/media';
import CheckoutDealerInfoTemplate from './CheckoutDealerInfoTemplate';

export const CheckoutDealerInfoStyled = styled(CheckoutDealerInfoTemplate)`
    display: flex;
    flex-direction: column;

    ${media.sm`
            flex-direction: row;
            justify-content: space-between;
        `}

    .dealerInfoWrapper {
        margin-top: ${rem(16)};
    }

    .mapPointIcon,
    .phoneIcon {
        width: ${rem(30)};
        margin-right: 0;
    }

    .mapPointIcon {
        svg {
            height: ${rem(18)};
            width: ${rem(18)};
        }
    }

    .mailIcon {
        height: ${rem(22)};
        width: ${rem(30)};
        svg {
            height: ${rem(24)};
            width: ${rem(24)};
        }
    }

    .phoneIcon {
        position: relative;
        right: ${rem(2)};
        display: flex;
        flex-direction: column;
        justify-content: center;

        svg {
            height: ${rem(24)};
            width: ${rem(24)};
        }
    }

    .mailWrapper,
    .addressWrapper,
    .phoneWrapper {
        display: flex;
        margin-top: ${rem(10)};

        p {
            margin: 0;
            font-size: ${rem(16)};
            line-height: ${rem(20)};
        }
    }

    .addressWrapper {
        padding-right: ${rem(13)};
    }

    .infoIcon {
        display: contents;
        cursor: pointer;

        svg {
            height: ${rem(20)};
            width: ${rem(20)};
        }

        & > div {
            position: relative;
            left: ${rem(5)};
        }
    }

    .addressPhoneWrapper {
        padding-left: ${rem(5)};
    }

    .phoneWrapper,
    .mailWrapper {
        align-items: center;
    }

    .dealerInfoTitle {
        font-weight: bold;
        text-transform: uppercase;
        font-size: ${rem(16)};
        line-height: ${rem(24)};
        padding-bottom: ${rem(15)};
    }

    .dealerAddressTitle {
        font-weight: bold;
        font-size: ${rem(16)};
        line-height: ${rem(24)};
    }

    .checkoutDealer .map {
        width: 100%;
        height: ${rem(200)};
        margin-top: ${rem(10)};

        ${media.sm`
            width: 400px;
            margin-top: 0;
        `}
    }

    .addressPhoneWrapperEC {
        .phoneWrapper,
        .mailWrapper {
            margin-top: 0;
            margin-bottom: ${rem(10)};
        }
    }

    .mailWrapper {
        margin-top: 0;
        a {
            text-decoration: none;
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
