import React, { FunctionComponent } from 'react';
import { Props } from '.';
import useTranslations from '@hooks/useTranslations';
import { getCustomerTitlesTranslated, getCustomerTitlesTranslatedUK } from '../CheckoutMySummary/helpers/constatnts';
import Collapsible from 'react-collapsible';
import userDuck from '../../redux/user/user.duck';
import { connect } from 'react-redux';
import { B2B_FILEDS, IS_B2B, isMarketGB } from '../../constants/main';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { B2B_FIELD } from 'src/constants/enums';

const CheckoutPersonalDetailsTemplate: FunctionComponent<Props> = ({
    userDetails,
    userAddress,
    personalDetailsButton,
    className,
    isOpenedByDefault,
}) => {
    const { t } = useTranslations();
    const customerTitles = isMarketGB ? getCustomerTitlesTranslatedUK(t) : getCustomerTitlesTranslated(t);
    const isNifEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_NIF_ENABLED);
    const CUSTOMER_TITLES = customerTitles.map(({ value, translation }) => ({
        value,
        label: translation,
    }));

    if (
        !userDetails ||
        !userAddress ||
        Object.entries(userDetails).length === 0 ||
        Object.entries(userAddress).length === 0
    ) {
        return null;
    }

    const userTitle = CUSTOMER_TITLES.reduce((title, option) => {
        if (userDetails.title === option.value) {
            return option.label;
        }

        return title;
    }, '');
    return (
        <div className={className}>
            <Collapsible trigger={t('checkout.personalDetails.title')} open={isOpenedByDefault}>
                <div className="summaryContent">
                    <div className="summaryRow">
                        <div className="left">
                            <span className="summaryRowLabel">{t('checkout.personalDetails.full_name')}</span>
                            <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_NAME">
                                {userDetails &&
                                    `${userDetails && userTitle} ${userDetails && userDetails.firstname} ${
                                        userDetails && userDetails.lastname
                                    }`}
                            </span>
                            <span className="summaryRowLabel">{t('checkout.personalDetails.phone')}</span>
                            <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_PHONENUMBER">
                                {userDetails && `${userDetails.telephone}`}
                            </span>
                            <span className="summaryRowLabel">{t('checkout.personalDetails.email')}</span>
                            <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_EMAIL">
                                {userDetails && `${userDetails.email}`}
                            </span>
                        </div>
                        <div className="right">
                            <span className="summaryRowLabel">{t('checkout.personalDetails.address')}</span>
                            <span>
                                <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_ADDRESS2">
                                    {userAddress.address1} {userAddress.address2}
                                </span>
                                <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_CITY">
                                    {userAddress.city}
                                </span>
                                <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_POSTCODE">
                                    {userAddress.postcode}
                                </span>
                                {isNifEnabled && (
                                    <span className="summaryRowInfo" data-testid="TESTING_CUSTOMER_NIF">
                                        {userAddress.nif}
                                    </span>
                                )}
                                {IS_B2B && (
                                    <>
                                        {B2B_FILEDS.includes(B2B_FIELD.COMPANY_NAME) && (
                                            <>
                                                <span>{t('checkout.user.details.businessName')}</span>
                                                <span
                                                    className="summaryRowInfo"
                                                    data-testid="TESTING_CUSTOMER_BUSINESS_NAME"
                                                >
                                                    {userDetails.businessName || ''}
                                                </span>
                                            </>
                                        )}
                                        {B2B_FILEDS.includes(B2B_FIELD.COMPANY_NUMBER) && (
                                            <>
                                                <span>{t('checkout.user.details.businessID')}</span>
                                                <span
                                                    className="summaryRowInfo"
                                                    data-testid="TESTING_CUSTOMER_BUSINESS_ID"
                                                >
                                                    {userDetails.businessID || ''}
                                                </span>
                                            </>
                                        )}
                                        {B2B_FILEDS.includes(B2B_FIELD.COMPANY_VAT) && (
                                            <>
                                                <span>{t('checkout.user.details.businessVAT')}</span>
                                                <span
                                                    className="summaryRowInfo"
                                                    data-testid="TESTING_CUSTOMER_BUSINESS_VAT"
                                                >
                                                    {userDetails.businessVAT || ''}
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                    {personalDetailsButton && personalDetailsButton.isVisible && (
                        <a
                            href={personalDetailsButton.link}
                            className="editPersonalDetailsButton"
                            data-testid="TESTING_CUSTOMER_EDIT_LINK"
                        >
                            {personalDetailsButton.label}
                        </a>
                    )}
                </div>
            </Collapsible>
        </div>
    );
};

const mapStateToProps: (state: any) => Props = (state) => ({
    userDetails: userDuck.getOwnState(state).userDetails,
    userAddress: userDuck.getOwnState(state).userAddress,
});

export default connect(mapStateToProps)(CheckoutPersonalDetailsTemplate);
