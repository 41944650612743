import styled from 'styled-components';
import { FAQsStyled } from '../FAQsStyled';

export const FAQsStyledAC = styled(FAQsStyled)`
    background: white;
    .container {
        max-width: 1200px;
        margin: auto;
    }
`;
