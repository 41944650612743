import styled from 'styled-components';
import { CustomErrorStyled } from '../CustomErrorStyled';
import { rem } from 'polished';
import { publicAssets } from '@utils/url.utils';

export const CustomErrorStyledDS = styled(CustomErrorStyled)`
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('${publicAssets('/DS/maintenence_mobile.jpg')}');

    @media (min-width: ${rem(500)}) {
        background-size: cover;
        background-position: center center;
        background-image: url('${publicAssets('/DS/maintanence_desktop.jpg')}');
    }

    .errorPage {
        &__content-wrapper {
            margin-top: 100vw;
            width: unset;
            @media (min-width: ${rem(500)}) {
                max-width: unset;
                justify-content: flex-start;
                margin-left: ${rem('32px')};
                margin-top: unset;
            }
        }

        &__content {
            background-color: ${({ theme }) => theme.colors.white};
            margin-top: unset;
            max-width: unset;
            text-align: center;
            @media (min-width: ${rem(500)}) {
                text-align: left;
                max-width: ${rem('396px')};
                margin-top: ${rem('32px')};
            }
        }

        &__title {
            color: ${({ theme }) => theme.colors.black};
            font-size: ${rem('32px')};
        }

        &__description {
            color: ${({ theme }) => theme.colors.black};
            font-size: ${rem('16px')};
        }

        &__link a {
            border-radius: ${rem('24px')};
            background-color: #1a1b1b;
            border-color: unset;
            color: ${({ theme }) => theme.colors.white};
            font-size: ${rem('16px')};
            text-decoration: none;
            text-transform: unset;
            &:hover {
                text-decoration: none;
                background-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.white};
            }

            @media (min-width: ${rem(500)}) {
                width: fit-content;
            }
        }
    }
`;
