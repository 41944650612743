import React, { FC } from 'react';
import useTranslations from '@hooks/useTranslations';
import CheckoutMap from '../CheckoutDelivery/components/CheckoutMap';
import Icon, { Icons } from '../Icon';
import { CheckoutDealerInfoProps } from '.';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { isBrandAC } from 'src/constants/main';
import { IPointOfSale } from 'src/services/stock/models/stockItems.service.model';
import { getCurrentCulture } from '@utils/getCurrentCulture';

const CheckoutDealerInfoTemplate: FC<CheckoutDealerInfoProps> = ({
    className,
    dealer,
    displayTitle = true,
    displayIcons = true,
}) => {
    const { t, i18n } = useTranslations();
    const { currentCulture } = getCurrentCulture(i18n);

    const isDealerLayerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED);

    const formattedDealer =
        'externalId' in dealer
            ? dealer
            : {
                  name: (dealer as IPointOfSale).name?.[currentCulture] ?? dealer.dealerName,
                  postCode: (dealer as IPointOfSale).address?.[currentCulture]?.zipCode ?? dealer.address.zipCode,
                  streetName: (dealer as IPointOfSale).address?.[currentCulture]?.line1 ?? dealer.address.line1,
                  city: (dealer as IPointOfSale).address?.[currentCulture]?.city ?? dealer.address.city,
                  contactNumber: dealer.phoneNumber ?? '',
                  latitude: dealer.coordinates.lat ?? null,
                  longitude: dealer.coordinates.lon ?? null,
                  email: dealer.email ?? '',
                  numSiret: dealer.siretNumber ?? '',
              };

    return (
        <>
            <div className={className}>
                <div>
                    {displayTitle && (
                        <div className="dealerInfoTitle">
                            {isDealerLayerEnabled ? t('checkout.dealer.info.title2') : t('checkout.dealer.info.title')}
                        </div>
                    )}
                    <div className={`addressPhoneWrapper ${isDealerLayerEnabled ? 'addressPhoneWrapperEC' : ''}`}>
                        <div className="dealerAddressTitle">{t('checkout.dealer.address_title')}</div>
                        <div className="addressWrapper">
                            {displayIcons && (
                                <Icon
                                    className="mapPointIcon"
                                    name={isBrandAC ? Icons.MapPointTypeTwo : Icons.MapPoint}
                                />
                            )}
                            <div className="address" data-testid="TESTING_DEALER_ADDRESS">
                                <p data-testid="TESTING_DEALER_ADDRESS_NAME">{formattedDealer.name}</p>
                                <p data-testid="TESTING_DEALER_ADDRESS_STREET">{formattedDealer.streetName}</p>
                                <p data-testid="TESTING_DEALER_ADDRESS_POSTCODE">{`${formattedDealer.postCode} ${formattedDealer.city}`}</p>
                                {Boolean(formattedDealer.numSiret) && (
                                    <p data-testid="TESTING_DEALER_ADDRESS_NUMSIRET">
                                        {t('myaccount.mySaves.numSiret', {
                                            numSiret: formattedDealer.numSiret,
                                        })}
                                    </p>
                                )}
                            </div>
                        </div>
                        {'externalId' in dealer && dealer.city && (
                            <>
                                <div className="phoneWrapper">
                                    {displayIcons ? (
                                        <Icon
                                            altId={`svgContactPhone`}
                                            altText={t('header.nav.callUs')}
                                            className="phoneIcon"
                                            name={
                                                isBrandAC
                                                    ? Icons.IphoneTypeTwo
                                                    : isDealerLayerEnabled
                                                    ? Icons.Iphone
                                                    : Icons.Phone
                                            }
                                        />
                                    ) : (
                                        <span className="phoneTitle">{t('checkout.dealer.info.phone')}</span>
                                    )}
                                    <p>{formattedDealer.contactNumber}</p>
                                </div>
                                {dealer.email && (
                                    <div className="mailWrapper">
                                        {displayIcons && (
                                            <Icon
                                                className="mailIcon"
                                                name={isBrandAC ? Icons.EmailTypeTwo : Icons.Mail}
                                            />
                                        )}
                                        <a href={`mailto:${formattedDealer.email}`}>{formattedDealer.email}</a>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {Boolean(formattedDealer.latitude && formattedDealer.longitude) && (
                    <div className="checkoutDealer">
                        <CheckoutMap
                            latitude={formattedDealer.latitude}
                            longitude={formattedDealer.longitude}
                            zoom={15}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CheckoutDealerInfoTemplate;
