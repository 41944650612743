import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { isBrandAC, isBrandDS, isBrandOV } from 'src/constants/main';
import { ICalculateSummaryResponse } from '../../services/calculateSummary/calculateSummary.types';
import styled, { css } from 'styled-components';
import React from 'react';
import { breakpoints, theme } from '../../styles/theme';
import { rem } from 'polished';
import { formatDisplayLine, labelsFormattingWithFG } from '@utils/FinanceWidget.utils';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { getMonthlyPriceObjByType } from '@utils/Price.utils';
import { StockFormatedPriceType } from '../../services/stock/models/stockItem.price.model';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { IState } from '@components/Modal/ModalTemplate';
import { useSelector } from 'react-redux';
import UIDuck from '../../redux/commonDucks/ui.duck';
import Spinner from '@components/Spinner';

const StyledRow = styled(Row)`
    .errorContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: ${rem('300px')};

        .title,
        .message {
            text-align: center;
            color: black;
        }

        .title {
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 30px;
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        .message {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        ${isBrandAC &&
        css`
            .title {
                font-size: ${({ theme }) => theme.fontSizes.h3};
            }
            .message {
                font-size: ${({ theme }) => theme.fontSizes.h4};
            }
        `}
        ${isBrandDS &&
        css`
            .title {
                font-size: ${({ theme }) => theme.fontSizes.h4};
            }
            .message {
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }
        `}
        ${isBrandOV &&
        css`
            .title {
                font-size: ${({ theme }) => theme.fontSizes.h4};
            }
            .message {
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }
        `}
    }

    @media (min-width: ${breakpoints.md}px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    @media (max-width: calc(${breakpoints.xs}px - 0.1px)) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

const StyledCol = styled(Col)`
    @media (max-width: calc(${breakpoints.xs}px - 0.1px)) {
        max-width: ${rem('170px')};
    }
`;

const ModalFinancialSummaryConfiguration = ({
    data,
    error,
    hideLegalNotice,
    setModal,
    modal,
}: {
    data: ICalculateSummaryResponse;
    error: Error;
    hideLegalNotice: boolean;
    setModal: Dispatch<SetStateAction<IState>>;
    modal: IState;
}) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();
    const { carDetails } = useCarDetailsDuck();

    const isLoading = useSelector((state) => !!UIDuck.getOwnState(state).loading);

    useEffect(() => {
        if (error) {
            setModal({ ...modal, isShowTitle: false });
        }
    }, [error]);

    if (error) {
        return (
            <StyledRow className="modalFinancialSummary">
                <div className="errorContainer">
                    <span className="title">{t('errorFinanceTitle')}</span>
                    <span className="message" dangerouslySetInnerHTML={{ __html: t('errorFinanceMessage') }} />
                </div>
            </StyledRow>
        );
    }

    if (isLoading) {
        return <Spinner size={20} border={3} color={theme.colors.black} />;
    } else if (Object.keys(data).length !== 0) {
        const amountRounding = getMonthlyPriceObjByType(
            carDetails.prices,
            StockFormatedPriceType.EMPLOYEE
        )?.amountRounding;
        const legalNotice = data?.globalTexts?.legalNotice?.[0]?.text || '';

        const financialProductLabel = data.globalTexts?.financialProductLabel?.text ?? '';

        return (
            <div className="modalFinancialSummary">
                {!hideLegalNotice && (
                    <div style={{ width: '100%', textAlign: 'left' }}>
                        <p className="modalFinancialSummary--description" style={{ fontSize: '1rem' }}>
                            {legalNotice}
                        </p>
                    </div>
                )}
                <div style={{ paddingTop: '0rem' }}>
                    <div>
                        <Grid
                            className="finance-summary--grid"
                            style={{
                                width: '100%',
                                paddingLeft: '0rem',
                                paddingRight: '0rem',
                            }}
                        >
                            <Row>
                                <Col className="modalFinancialSummary--title">
                                    <span>{financialProductLabel}</span>
                                </Col>
                            </Row>
                            {data.blocks.map((block, key) => (
                                <div key={key} className="modalFinancialSummary--block">
                                    {block.displayLines.map((displayLine) => {
                                        return (
                                            <StyledRow
                                                className="finance-summary--row-2"
                                                key={`${block.order}-${displayLine.order}`}
                                                style={{
                                                    width: '100%',
                                                    padding: '5px 0',
                                                    ...(!displayLine.value &&
                                                        !displayLine.displayValue && { display: 'block' }),
                                                }}
                                            >
                                                <StyledCol
                                                    className="finance-summary--col-2"
                                                    xs={8}
                                                    md={8}
                                                    style={{
                                                        fontSize: labelsFormattingWithFG({
                                                            displaySize: displayLine?.styleType?.displaySize,
                                                        }),
                                                        fontWeight:
                                                            displayLine.styleType && displayLine.styleType.bold
                                                                ? 'bold'
                                                                : 'normal',
                                                        ...(!displayLine.value &&
                                                            !displayLine.displayValue && { maxWidth: 'unset' }),
                                                    }}
                                                >
                                                    <span>
                                                        {displayLine.label === 'fees'
                                                            ? t('financialSummaryData.fees')
                                                            : displayLine.label}
                                                    </span>
                                                </StyledCol>
                                                {(displayLine.value || displayLine.displayValue) && (
                                                    <Col
                                                        className="finance-summary--col-3"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        style={{
                                                            fontSize: labelsFormattingWithFG({
                                                                displaySize: displayLine?.styleType?.displaySize,
                                                            }),
                                                            fontWeight:
                                                                displayLine.styleType && displayLine.styleType.bold
                                                                    ? 'bold'
                                                                    : 'normal',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        <span
                                                            data-testid={`TESTING_FINANCE_PRICE_${block.order}_${displayLine.order}`}
                                                        >
                                                            {formatDisplayLine(
                                                                displayLine.displayValue,
                                                                displayLine.value,
                                                                t,
                                                                formatMoney,
                                                                amountRounding
                                                            )}
                                                        </span>
                                                    </Col>
                                                )}
                                            </StyledRow>
                                        );
                                    })}
                                </div>
                            ))}
                        </Grid>
                    </div>
                    <div className="financialSummary__legalInfo">
                        {data.legalTexts.map((legalText, key) => (
                            <div
                                style={{
                                    fontSize: labelsFormattingWithFG({
                                        displaySize: legalText?.style?.displaySize,
                                    }),
                                }}
                                className="financialSummary__legalText"
                                key={key}
                            >
                                <span dangerouslySetInnerHTML={{ __html: legalText.text }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    } else {
        console.error('FinancialSummaryConfiguration data:' + JSON.stringify(data));
        return (
            <div>
                <p>Excusez-moi, pas de données disponibles.</p>
            </div>
        );
    }
};

export default ModalFinancialSummaryConfiguration;
