import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { Row } from 'react-styled-flexboxgrid';
import { RowProps } from '.';

export const RowStyled = styled(Row)<RowProps>`
    ${({ noNegativeMarginMobile }) =>
        noNegativeMarginMobile &&
        `
        @media (max-width: calc(${breakpoints.xs}px - 0.1px) ) {
            margin-left: 0;
            margin-right: 0;
        }
    `}
`;
