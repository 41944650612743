import React, { FC } from 'react';
import { LinkProps } from '.';
import { Icon, Icons } from '@components/Icon';
import parser from 'bbcode-to-react';

export const LinkTemplate: FC<LinkProps> = ({ className, onClick, dataTestId, label, withArrowIcon, href }) => {
    return href ? (
        <a href={href} onClick={(e) => onClick?.(e)} className={className} data-testid={dataTestId}>
            {parser.toReact(label)}
            {withArrowIcon && <Icon name={Icons.ChevronRight} />}
        </a>
    ) : (
        <span onClick={(e) => onClick?.(e)} className={className} data-testid={dataTestId}>
            {parser.toReact(label)}
            {withArrowIcon && <Icon name={Icons.ChevronRight} />}
        </span>
    );
};

export default LinkTemplate;
