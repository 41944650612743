import { rem } from 'polished';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslations';
import Button from '../Button';
import { Icon, Icons } from 'src/components/Icon';

interface IModalShareConfiguration {
    email: string;
    onClose: () => void;
}

export const ModalShareConfiguration: FC<IModalShareConfiguration> = memo(({ email, onClose }) => {
    const { t } = useTranslations();
    return (
        <Wrapper>
            <ModalIcon name={Icons.ShareConfigurationModalIcon} width={72} height={72} />
            <Text>{t('shareConfiguration.modal.message', { email })}</Text>
            <Cancel onClick={onClose}>{t('shareConfiguration.modal.closeBtn')}</Cancel>
        </Wrapper>
    );
});
ModalShareConfiguration.displayName = 'ModalShareConfiguration';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: auto;
    padding-bottom: ${rem('34px')};
`;
const ModalIcon = styled(Icon)``;
const Text = styled.div`
    padding: ${rem('34px')};
    font-size: ${rem('14px')};
`;
const Cancel = styled(Button)``;
