import styled from 'styled-components';
import { MarketingStickerStyled } from '../../MarketingStickerStyled';
import { rem } from 'polished';

export const MarketingStickerStyledOVGB = styled(MarketingStickerStyled)`
    background: rgba(201, 12, 15, 0.2);

    .infoText {
        font-family: Arial;
    }
`;
