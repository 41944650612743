import React from 'react';
import { Dispatch } from 'redux';
import { SC } from '../../styles/theme';
import { BRAND, isMarketGB } from '../../constants/main';
import { CheckoutDeliveryStyledAC } from './AC/CheckoutDeliveryStyled.AC';
import { CheckoutDeliveryStyledAP } from './AP/CheckoutDeliveryStyled.AP';
import { CheckoutDeliveryStyledDS } from './DS/CheckoutDeliveryStyled.DS';
import { CheckoutDeliveryStyledOV } from './OV/CheckoutDeliveryStyled.OV';
import { CheckoutDeliveryStyledOVGB } from './OV/GB/CheckoutDeliveryStyled.OV';
import { CheckoutDeliveryStyled } from './CheckoutDeliveryStyled';

export const CheckoutDealerDispatch = React.createContext(null);

export enum ACTIONS {
    SET_ERROR = 'SET_ERROR',
    SET_DEALERS = 'SET_DEALERS',
    SET_SELECTED_DEALER = 'SET_SELECTED_DEALER',
    SET_SELECTED_PLACE = 'SET_SELECTED_PLACE',
}
export interface IAction {
    type: keyof typeof ACTIONS;
    payload: any;
}

export interface CheckoutDeliveryProps extends SC {
    dispatchToStore: Dispatch;
    goToNextPage: () => void;
}

const CheckoutDelivery = (props: CheckoutDeliveryProps) => {
    if (BRAND === 'OV')
        return isMarketGB ? <CheckoutDeliveryStyledOVGB {...props} /> : <CheckoutDeliveryStyledOV {...props} />;
    if (BRAND === 'AC') return <CheckoutDeliveryStyledAC {...props} />;
    if (BRAND === 'AP') return <CheckoutDeliveryStyledAP {...props} />;
    if (BRAND === 'DS') return <CheckoutDeliveryStyledDS {...props} />;
    return <CheckoutDeliveryStyled {...props} />;
};

export default CheckoutDelivery;
