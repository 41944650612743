import { rem } from 'polished';
import styled from 'styled-components';
import AutobizModal from './AutobizModal';

export const AutobizModalStyled = styled(AutobizModal)`
    #myModal {
        /* Hide modal and let loaded Autobiz css override to show it */
        display: none;
        z-index: 99999999;
        /* Autobiz modal CSS overrides */
        .modal-dialog {
            position: relative;
          
            .reprise-ferme-value {
                line-height: normal;
            }
          
            .modal-loading {
                position: absolute;
                top: calc(50% - 50px);
                left: calc(50% - 50px);
                z-index: 9999;
            }

            .modal-body {
                margin: 0 ${rem(-15)};
                padding: ${rem(40)};

                /* fix for super thin devices with bad pixelation (old iphones/androids with 325px less) */
                max-width: 95vw;

                .info {
                    color: inherit;
                }

                .modal-content > div {
                    background-color: white;
                }

                .container-fluid {
                    > .row {
                        display: block;

                        .care-container {
                            text-align: center;
                            img {
                                display: inline-block;
                                max-width: 9rem;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default AutobizModalStyled;
