import styled from 'styled-components';
import { breakpoints } from '../../../../../styles/theme';
import { FaqItemStyled } from '../FaqItemStyled';
import { rem } from 'polished';

const maxWidth: string = '900px';

export const FaqItemStyledDS = styled(FaqItemStyled)`
    &.faqCategory {
        background-color: ${({ theme }) => theme.colors.white};

        > .Collapsible {
            max-width: ${rem(maxWidth)};
            margin: 0 auto;
            border-bottom: 1px solid ${({ theme }) => theme.colors.milkshake};
            padding: 0 ${rem(16)};

            > .Collapsible__trigger {
                border-bottom: none;
                padding: 0;

                > .faqHeader {
                    color: ${({ theme }) => theme.colors.black};
                    font-size: ${({ theme }) => theme.fontSizes.h5};
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    text-transform: uppercase;
                    &:after {
                        right: 0;
                        margin-top: ${rem(-9)};
                        width: ${rem(25)};
                        height: ${rem(25)};
                        background-image: url("data:image/svg+xml,%3Csvg data-name='0021_Plus_Outline' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath data-name='0021_Plus_Outline' d='M25.28 15.32h-8.62v-8.6h-1.32v8.6H6.72v1.36h8.62v8.6h1.32v-8.6h8.62z' style='fill:%23846f66'/%3E%3C/svg%3E%0A");
                    }
                }
                &.is-open {
                    > .faqHeader {
                        &:after {
                            background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='6' width='15' height='3' style='fill:%231A1B1B;'/%3E%3C/svg%3E%0A");
                            @media (min-width: ${breakpoints.sm}px) {
                                right: ${rem(36)};
                            }
                        }
                    }
                }
            }
            .Collapsible__contentOuter {
            }
            .Collapsible__contentInner {
                .categoryContent {
                    padding: 0 ${rem(24)};
                    p strong,
                    p span strong,
                    p span {
                        font-family: ${({ theme }) => theme.fonts.fontBase}!important;
                        color: ${({ theme }) => theme.colors.black1}!important;
                    }
                    > p:nth-child(2n + 0) {
                        padding-bottom: 2rem;
                    }
                    a,
                    a span {
                        color: ${({ theme }) => theme.colors.primary}!important;

                        :hover {
                            color: ${({ theme }) => theme.colors.primaryHover}!important;
                        }
                    }
                }
            }

            @media (min-width: ${breakpoints.md}px) {
                padding: 0;
            }
        }
    }
    &.faqGroup {
        > .Collapsible {
            > .Collapsible__trigger {
                background-color: ${({ theme }) => theme.colors.accordionSecondaryBg};
                padding: ${rem(11)} ${rem(30)} ${rem(11)} ${rem(24)};
                margin: 0;

                > .faqHeader {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    color: ${({ theme }) => theme.colors.black1};
                    padding-right: ${rem(32)};

                    &:after {
                        right: ${rem(32)};
                        height: ${rem(8)};
                        width: ${rem(12)};
                        transform: rotate(180deg);
                        margin-top: ${rem(-6)};
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5853 7.99805L5.99919 3.41202L1.41315 7.99805L-0.000865936 6.58405L5.99919 0.584017L11.9993 6.58405L10.5853 7.99805Z' fill-rule='evenodd' clip-rule='evenodd' fill='%231A1B1B' /%3E%3C/svg%3E");
                    }
                }

                &.is-open {
                    > .faqHeader {
                        &:after {
                            transform: rotate(0);
                        }
                    }
                }
            }

            > .Collapsible__contentOuter {
                background-color: ${({ theme }) => theme.colors.white};

                > .Collapsible__contentInner {
                    margin: 0;
                    padding: 0 ${rem(24)};

                    a,
                    a span {
                        color: ${({ theme }) => theme.colors.primary}!important;
                        :hover {
                            color: ${({ theme }) => theme.colors.primaryHover}!important;
                        }
                    }
                    strong {
                        font-family: ${({ theme }) => theme.fonts.fontBase};
                    }
                    span {
                        color: ${({ theme }) => theme.colors.black1}!important;
                    }
                }
            }
        }
    }

    &.secondary {
        .Collapsible__trigger {
            border: none;
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }
        .Collapsible__trigger:after {
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 13L13 1L25 13' stroke='%23AD9D91' stroke-width='1' stroke-linejoin='bevel'/%3E%3C/svg%3E ");
        }

        .question {
            font-family: ${({ theme }) => theme.fonts.fontEmphasized};
        }
        .answer {
            color: ${({ theme }) => theme.colors.accordionAnswer};
        }
    }
    &.primary {
        .Collapsible__trigger {
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            color: ${({ theme }) => theme.colors.secondary};
            font-family: ${({ theme }) => theme.fonts.fontEmphasized};
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        .Collapsible__trigger:after {
            margin-top: ${rem(-3)};
            height: ${rem(8)};
            width: ${rem(14)};

            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.25 8L0 6.7392L7 0L14 6.7392L12.75 8L7 2.4L1.25 8Z' fill='%23AD9D91'/%3E%3C/svg%3E ");
        }
        .Collapsible__trigger.is-open:after {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.25 8L0 6.7392L7 0L14 6.7392L12.75 8L7 2.4L1.25 8Z' fill='%23AD9D91'/%3E%3C/svg%3E ");
            height: ${rem(8)};
            width: ${rem(14)};
            transform: rotate(0);
        }
    }
`;
