import {
    CONSUMPTION_CO2,
    CONSUMPTION_CO2_KEY,
    CONSUMPTION_KEY,
    CONSUMPTION_KEY_ORDER,
    CONSUMPTION_KEY_WLTP,
    CONSUMPTION_MIX,
    CONSUMPTION_MIX_KEY,
    ELECTRIC_CONSUMPTION,
    ELECTRIC_CONSUMPTION_KEY,
    ELECTRIC_RANGE,
    ELECTRIC_RANGE_KEY,
    ENGINE_POWER,
    FUEL,
    GALON_IN_LITRES,
    GEARBOX,
    MILE_IN_KILOMETRES,
    NO_VALUE_DASH,
    POWER_KW_KEY,
    PROMOTED_SPECS_ORDER,
} from '../constants/characteristics';

export interface IKeyStringValueNumber {
    [key: string]: number;
}

export interface IKeyStringAbstract {
    [key: string]: number | string;
}

export interface IArrayKeyString {
    [key: string]: string;
}

export interface IEnvironmentalAndTechData {
    environmentalData: IKeyStringAbstract;
    techData: IArrayKeyString;
}

export interface IEngineSpec {
    key: string;
    value: number | string;
    label: string;
    local_value?: undefined | number;
}

export interface IEngineSpecValues {
    consumption: number | string;
    emissions: number | string;
    power: string;
    electricRange: number | string;
    electricConsumption: number | string;
}

export interface ITechnicalSpecifications {
    category: string;
    key: string;
    order: number;
    data: ITechnicalSpecificationsData[];
}

export interface ITechnicalSpecificationsData {
    label: string;
    value: string;
    local_value?: string;
    key: string;
    order: number;
}

/**
 * Method retrieves engine specs details
 *
 * @param specs
 */
export function getEngineSpecs(specs: IEngineSpec[]): IEngineSpecValues {
    const engineSpecs: IEngineSpecValues = {
        consumption: NO_VALUE_DASH,
        emissions: NO_VALUE_DASH,
        power: NO_VALUE_DASH,
        electricRange: NO_VALUE_DASH,
        electricConsumption: NO_VALUE_DASH,
    };

    specs.forEach((spec) => {
        const { value, key, local_value } = spec;
        if (POWER_KW_KEY === key && value) {
            engineSpecs.power = value.toString();
        }

        if (CONSUMPTION_MIX_KEY === key && typeof value === 'number') {
            engineSpecs.consumption = local_value ? local_value : value;
        }

        if (CONSUMPTION_CO2_KEY === key && typeof value === 'number') {
            engineSpecs.emissions = local_value ? local_value : value;
        }

        if (ELECTRIC_RANGE_KEY === key && typeof value === 'number') {
            engineSpecs.electricRange = local_value ? local_value : value;
        }

        if (ELECTRIC_CONSUMPTION_KEY === key && typeof value === 'number') {
            engineSpecs.electricConsumption = local_value ? local_value : value;
        }
    });

    return engineSpecs;
}

/**
 * Method retrieves environmental details data
 *
 * @param engineSpecs
 */
export function getEnvironmentalAndTechData(engineSpecs: IEngineSpec[]): IEnvironmentalAndTechData {
    const { consumption, emissions, power, electricRange, electricConsumption } = getEngineSpecs(engineSpecs);
    const isElectric: boolean = consumption === NO_VALUE_DASH && electricRange > 0;
    const isHybrid: boolean = consumption !== NO_VALUE_DASH && electricRange > 0;

    if (isElectric) {
        return {
            environmentalData: {
                [ELECTRIC_RANGE]: electricRange,
                [ELECTRIC_CONSUMPTION]: electricConsumption,
                [CONSUMPTION_CO2]: emissions,
            },
            techData: {
                [FUEL]: '',
                [GEARBOX]: '',
            },
        };
    }

    if (isHybrid) {
        return {
            environmentalData: {
                [ELECTRIC_RANGE]: electricRange,
                [ELECTRIC_CONSUMPTION]: electricConsumption,
                [CONSUMPTION_MIX]: consumption,
                [CONSUMPTION_CO2]: emissions,
            },
            techData: {
                [FUEL]: '',
                [GEARBOX]: '',
            },
        };
    }

    return {
        environmentalData: {
            [ENGINE_POWER]: power,
            [CONSUMPTION_MIX]: consumption,
            [CONSUMPTION_CO2]: emissions,
        },
        techData: {
            [FUEL]: '',
            [GEARBOX]: '',
        },
    };
}

export function getFilteredPromotedSpecsOrderByWltp(): IKeyStringValueNumber {
    const promotedSpecsOrder: IKeyStringValueNumber = PROMOTED_SPECS_ORDER;
    const pagesNeedOldWltpData: any = ['my_orders'];
    const showOldWltpFilter: (element: string) => boolean = () =>
        process.browser ? window.location.href.includes(pagesNeedOldWltpData) : null;
    const showOldWltp: boolean = pagesNeedOldWltpData.some(showOldWltpFilter);
    if (showOldWltp) {
        promotedSpecsOrder[CONSUMPTION_KEY] = CONSUMPTION_KEY_ORDER;
        delete promotedSpecsOrder[CONSUMPTION_KEY_WLTP];
    }
    return promotedSpecsOrder;
}

/**
 * Method sorts ASC array by order
 *
 * @param technicalSpecifications
 */
export function getSortedSpecs(technicalSpecifications: ITechnicalSpecifications[]): ITechnicalSpecifications[] {
    return technicalSpecifications.sort((a, b) => {
        return a.order - b.order;
    });
}

/**
 * Method retrieves only promoted specs in the correct order
 *
 * @param technicalSpecifications
 */
export function getPromotedTechnicalSpecifications(
    technicalSpecifications: ITechnicalSpecifications[]
): ITechnicalSpecifications[] {
    const promotedTechnicalSpecs: ITechnicalSpecifications[] = [];
    const promotedSpecsOrder: IKeyStringValueNumber = getFilteredPromotedSpecsOrderByWltp();

    technicalSpecifications.forEach((spec) => {
        const { key } = spec;
        if (promotedSpecsOrder[key]) {
            spec.order = promotedSpecsOrder[key];
            promotedTechnicalSpecs.push(spec);
        }
    });

    return getSortedSpecs(promotedTechnicalSpecs);
}

export function getFilteredSortedTechSpecs(
    technicalSpecifications: ITechnicalSpecifications[]
): ITechnicalSpecifications[] {
    return getSortedSpecs(
        technicalSpecifications.filter((spec: ITechnicalSpecifications) => spec.key !== CONSUMPTION_KEY)
    );
}

/**
 * Method converts litre per 100 kilometers to mile per gallon
 *
 * @param literPerKM
 */
export const getMPGfromLitterPerKM = (literPerKM: number | string) => {
    return (100 * GALON_IN_LITRES) / (MILE_IN_KILOMETRES * Number(literPerKM));
};
