import { useTranslation } from './../../i18n';
import { IDealer } from '../interfaces/Dealer';
import { IConfigurableCar } from '../interfaces/Car';
import { MARKET } from '../constants/main';

/**
 *  @description util function that includes hook, use it only on top level of component
 *  @param vehicle type IVehicleTransformed
 *  @param dealer type IDealer
 *  @return returns city, street, distance, email, phoneNumber, zipCode, coordinates, dealerName
 *  */

export const getDealerInformation = (vehicle: IConfigurableCar, dealer?: IDealer): IDealer => {
    const { i18n } = useTranslation();
    const culture = `${i18n.language?.toLowerCase() ?? 'fr'}-${MARKET?.toUpperCase() ?? 'FR'}`;

    if (vehicle?.dealers?.length > 0) {
        const dealerName = vehicle?.dealers?.[0]?.name?.[culture];

        const { city, line1: street, zipCode } = vehicle.dealers[0].address?.[culture];
        const { distance, email, phoneNumber, coordinates, siretNumber, openingHoursList } = vehicle.dealers[0];
        const address = { zipCode, city, line1: street };
        const openingHours = openingHoursList?.[culture];

        return {
            city,
            street,
            distance,
            email,
            phoneNumber,
            zipCode,
            coordinates,
            dealerName,
            openingHours,
            siretNumber,
            address,
        };
    }

    return {
        city: dealer?.city,
        zipCode: dealer?.postCode,
        street: dealer?.streetName,
        openingHours: dealer?.openingHours,
        siretNumber: dealer?.numSiret,
        dealerName: dealer?.name,
        coordinates: {
            lat: dealer?.latitude,
            lon: dealer?.longitude,
        },
    };
};
