import React, { useEffect, useState } from 'react';
import Input from '../../../../ui/Input';
import { SC } from '../../../../styles/theme';
import Icon, { Icons } from '@components/Icon';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { REGISTRATION_FEE, REGISTRATION_FEES_VALUES } from '../../../../constants/main';
import { IDealerFee } from '@components/DealerFees/DealerFeesTemplate';
import useTranslations from '@hooks/useTranslations';

interface IRegistrationFee extends SC {
    registrationFee: number | string;
    setRegistrationFee: any;
    postalCode: string;
    setPostalCode: any;
    hasInvalidPostalCode: boolean;
    hasRequiredPostalCode: boolean;
}

export const RegistrationFeesTemplate = ({ registrationFee, setRegistrationFee, postalCode, setPostalCode, hasInvalidPostalCode, hasRequiredPostalCode }: IRegistrationFee) => {
    const { t } = useTranslations();

    const { currentDeal } = useCarDetailsDuck();

    const isNumber = (value: string | number) => /^[0-9]+$/.test(String(value));

    const [defaultRegistrationFee, setDefaultRegistrationFee] = useState(null);

    useEffect(() => {
        if (currentDeal?.extraFields?.dealerFees) {
            const registrationFee = JSON.parse(currentDeal?.extraFields?.dealerFees)?.find(
                (fee: IDealerFee) => fee.id === REGISTRATION_FEE
            );

            if (registrationFee) {
                setDefaultRegistrationFee(registrationFee.priceInclTax);
            }
        }
    }, [currentDeal]);

    return (
        <div>
            <div className="row without-flex without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.registrationFees.input.label')}
                    value={registrationFee || ''}
                    onChange={setRegistrationFee}
                    placeholder={t('modal.personalizeOffer.registrationFees.input.placeholder')}
                    hasError={registrationFee && !isNumber(registrationFee)}
                    withCurrency
                />
                {registrationFee > 0 && !isNumber(registrationFee) && (
                    <div className="row_error">{t('modal.personalizeOffer.registrationFees.input.error')}</div>
                )}
            </div>
            <div className="row">
                <Input
                    label={t('modal.personalizeOffer.postalCode.input.label')}
                    value={postalCode || ''}
                    onChange={setPostalCode}
                    placeholder={t('modal.personalizeOffer.postalCode.input.placeholder')}
                    hasError={registrationFee && !postalCode && (defaultRegistrationFee != registrationFee)}
                />
                {((registrationFee > 0 && !postalCode && (defaultRegistrationFee != registrationFee)) || hasRequiredPostalCode) && (
                    <div className="row_error">{t('modal.personalizeOffer.postalCode.input.error')}</div>
                )}
                {postalCode && hasInvalidPostalCode && (
                    <div className="row_error">{t('modal.personalizeOffer.postalCode.input.invalid')}</div>
                )}
            </div>
            <button onClick={() => setRegistrationFee(null)} className="reset-button">
                <Icon name={Icons.Trash} />
                <span>{t('modal.personalizeOffer.registrationFees.reset')}</span>
            </button>
        </div>
    );
};
