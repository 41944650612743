import styled from 'styled-components';
import { MonthlyPriceLabelStyled } from '../MonthlyPriceLabelStyled';
import { AprSizes } from '@components/MonthlyPriceLabel';
import { rem } from 'polished';

export const MonthlyPriceLabelStyledAC = styled(MonthlyPriceLabelStyled)`
    .aprValue,
    .aprDescription {
        ${(prop) => (prop.size === AprSizes.LARGE ? `font-size: ${prop.theme.fontSizes.h5}` : '')};
        ${(prop) => (prop.size === AprSizes.MEDIUM ? `font-size: ${prop.theme.fontSizes.h4}` : '')};
        ${(prop) => (prop.size === AprSizes.SMALL ? `font-size: ${rem(12)}` : '')};
        ${(prop) => (prop.withPrimaryColor ? `color: ${prop.theme.colors.red}` : `color: ${prop.theme.colors.grey5}`)};
    }

    .aprDescription {
        text-decoration: none;

        span {
            border-bottom: 1px solid
                ${(prop) => (prop.withPrimaryColor ? prop.theme.colors.red : prop.theme.colors.grey5)};
        }
    }
`;
