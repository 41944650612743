import { getLanguageCookie, setLanguageCookie } from '@utils/Session.utils';
import React, { FC } from 'react';
import { LanguageSwitcherTemplateProps } from '.';
import { LANGUAGE, MULTI_LANGUAGES } from '../../constants/main';

const LanguageSwitcherTemplate: FC<LanguageSwitcherTemplateProps> = ({ className }) => {
    const onClick = (lang: string) => {
        setLanguageCookie(lang);
        return (window.location.href = `${window.location.origin}/${lang}`);
    };
    const lastUsedLanguage: string = getLanguageCookie() || LANGUAGE;

    return (
        <div className={className}>
            <ul>
                {MULTI_LANGUAGES.map((lang: string, index: number) => (
                    <li
                        key={index}
                        className={lang === lastUsedLanguage ? 'default' : ''}
                        onClick={() => onClick(lang)}
                    >
                        <span>{lang}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LanguageSwitcherTemplate;
