import styled from 'styled-components';
import { CustomErrorStyled } from '../CustomErrorStyled';
import { publicAssets } from '@utils/url.utils';
import { rem } from 'polished';

export const CustomErrorStyledAC = styled(CustomErrorStyled)`
    background-image: url('${publicAssets('/images/ac_not_available.jpg')}');
    background-size: cover;
    background-position: center left;

    .errorPage {
        &__content-wrapper {
            display: block;
            margin: unset;
        }

        &__content {
            margin-top: ${rem('32px')};
            margin-left: ${rem('32px')};
            max-width: ${rem('384px')};
            background: white;
        }

        &__title {
            color: ${({ theme }) => theme.colors.grey5};
            font-size: ${rem('34px')};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: 900;
        }

        &__description {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            color: ${({ theme }) => theme.colors.grey5};
            font-size: ${({ theme }) => theme.fontSizes.h5};
            margin-bottom: ${rem('30px')};
            margin-top: ${rem('16px')};
            font-weight: normal;
        }

        &__link a {
            &:hover {
                transition: all 0.3s ease;
            }
            border-radius: ${rem('24px')};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            background-color: ${({ theme }) => theme.colors.blue2};
            border: 1px solid ${({ theme }) => theme.colors.blue2};
            color: ${({ theme }) => theme.colors.white};
            font-weight: bold;
            text-decoration: none;
            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.black1};
                color: ${({ theme }) => theme.colors.white};
                border-color: ${({ theme }) => theme.colors.black1};
                text-decoration: none;
            }
        }
    }
`;
