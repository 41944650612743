import React, { FC, useEffect } from 'react';

import { CheckboxProps } from '.';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import {ENTER_KEY_CODE} from "../../constants/main";


const Checkbox: FC<CheckboxProps> = ({
    className,
    checked,
    disabled = false,
    error = false,
    errorMessage,
    name,
    dataTestId,
    onChange,
    children,
    label,
    withoutClick
}) => {
    useEffect(() => {
        const input = document.getElementById(`id-${name}`);

        input.addEventListener('keyup', function (event) {
            if (event.keyCode === ENTER_KEY_CODE) {
                event.preventDefault();
                document.getElementById(`id-${name}`).click();
            }
        });
    }, []);

    return (
        <div className={className}>
            <div className={`container ${withoutClick ? 'withoutPointerEvents' : ''}`} data-testid={dataTestId}>
                <input
                    tabIndex={0}
                    id={`id-${name}`}
                    onChange={onChange}
                    checked={checked}
                    name={name}
                    type="checkbox"
                />
                <label htmlFor={`id-${name}`}>{children ? children : label}</label>
            </div>
            {error && <div>{errorMessage}</div>}
        </div>
    );
};

export default Checkbox;
