import React from 'react';

import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalFeaturesStyledAP } from './AP/ModalFeaturesStyled.AP';
import { ModalFeaturesStyledDS } from './DS/ModalFeaturesStyled.DS';
import { ModalFeaturesStyledAC } from './AC/ModalFeaturesStyled.AC';
import { ModalFeaturesStyledOV } from './OV/ModalFeaturesStyled.OV';
import { ModalFeaturesStyledOV as ModalFeaturesStyledOVGB } from './OV/GB/ModalFeaturesStyled.OV';
import { ModalFeaturesStyled } from './ModalFeaturesStyled';

export const ModalFeatures = (props: any) => {
    if (BRAND === 'AP') return <ModalFeaturesStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalFeaturesStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalFeaturesStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalFeaturesStyledOVGB {...props} /> : <ModalFeaturesStyledOV {...props} />;
    return <ModalFeaturesStyled {...props} />;
};

export default ModalFeatures;
