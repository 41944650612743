import React, { FC } from 'react';
import { FeatureSwitchType, ISelectFSOption } from '@utils/featureSwitches.utils';
import Button from '../Button';
import Select from '../Select';
import { IFeatureSwitcherProps } from './index';
import { ValueType } from 'react-select';
import { Input } from '@components/Input';

export const FeatureSwitcherTemplate: FC<IFeatureSwitcherProps> = ({
    switcherName,
    switcherValue,
    btnLabel,
    onValueChange,
    switchType = FeatureSwitchType.BOOLEAN_VALUE,
    isRequired = true,
    className,
    onValidate,
    options,
}) => {
    const handleOnClickBoolSwitch = () => {
        onValueChange({ name: switcherName, value: !switcherValue });
    };

    const handleSelectChange = (selectedOption: ValueType<ISelectFSOption, boolean>) => {
        const singleSelectedOption = Array.isArray(selectedOption) ? selectedOption[0] : selectedOption;
        onValueChange<string>({ name: switcherName, value: singleSelectedOption.value });
    };

    const handleMultiSelectChange = (selectedOption: ValueType<ISelectFSOption, boolean>) => {
        const optionsArray = Array.isArray(selectedOption) ? selectedOption : [];

        onValueChange<string[]>({ name: switcherName, value: optionsArray.map(item => item.value) });
    };

    const createSelectValue: <ValueType>(switcherValue: ValueType) => ISelectFSOption = switcherValue => {
        return { value: String(switcherValue), label: String(switcherValue) };
    };

    const renderSwitcher = (type: FeatureSwitchType) => {
        switch (type) {
            case FeatureSwitchType.BOOLEAN_VALUE: {
                const value = switcherValue === undefined || switcherValue === null ? false : switcherValue;
                return (
                    <Button className="featureBtn" onClick={handleOnClickBoolSwitch}>
                        {(value as boolean) ? (
                            <span className="featureBtn--true">{value?.toString()}</span>
                        ) : (
                            <span className="featureBtn--false">{value?.toString()}</span>
                        )}
                    </Button>
                );
            }
            case FeatureSwitchType.MULTIPLE_VALUES:
                return (
                    <Select
                        className="select"
                        defaultValue={(switcherValue as string[])?.map(createSelectValue)}
                        value={(switcherValue as string[])?.map(createSelectValue)}
                        options={options}
                        onChange={handleMultiSelectChange}
                        onValidate={onValidate}
                        isMulti
                        isRequired={isRequired}
                    />
                );
            case FeatureSwitchType.SELECT:
                return (
                    <Select
                        className="select"
                        defaultValue={createSelectValue(switcherValue)}
                        value={createSelectValue(switcherValue)}
                        options={options}
                        onChange={handleSelectChange}
                        onValidate={onValidate}
                        isRequired={isRequired}
                    />
                );
            case FeatureSwitchType.TEXT:
                return (
                    <Input
                        defaultValue={String(switcherValue)}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                            onValueChange<string>({ name: switcherName, value: String(e.target.value) })
                        }
                    />
                );
            default:
                return <div>Unknown feature type</div>;
        }
    };

    return (
        <div className={className}>
            <div>{`${btnLabel}: `}</div>
            {renderSwitcher(switchType)}
        </div>
    );
};
