import React from 'react';
import { PromoCodeStyledAP } from './AP/PromoCodeStyled.AP';
import { PromoCodeStyledAC } from './AC/PromoCodeStyled.AC';
import { PromoCodeStyledDS } from './DS/PromoCodeStyled.DS';
import { PromoCodeStyledOV } from './OV/PromoCodeStyled.OV';
import { PromoCodeStyled } from './PromoCodeStyled';
import { SC } from '../../styles/theme';
import { isBrandAP, isBrandAC, isBrandDS, isBrandOV } from '../../constants/main';

export interface IPromoCode extends SC {
    isBasketSummary?: boolean;
    promoCode: string;
    showTitle?: boolean;
    hideValue?: boolean;
}

const PromoCode = (props: IPromoCode) => {
    if (isBrandAP) return <PromoCodeStyledAP {...props} />;
    if (isBrandAC) return <PromoCodeStyledAC {...props} />;
    if (isBrandDS) return <PromoCodeStyledDS {...props} />;
    if (isBrandOV) return <PromoCodeStyledOV {...props} />;
    return <PromoCodeStyled {...props} />;
};

export default PromoCode;
