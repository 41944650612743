import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { rem } from 'polished';
import { FilterButtonStyled } from '../FilterButtonStyled';

export const FilterButtonStyledAP = styled(FilterButtonStyled)`
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.primaryBlack};

    ${({ active, theme }) =>
        active &&
        css`
            border-color: ${({ theme }) => theme.colors.primaryBlack};
            background: ${({ theme }) => theme.colors.primaryBlack};
            color: ${({ theme }) => theme.colors.white};
        `};

    ${({ isMobile, theme }) =>
        css`
            :active,
            :hover,
            :focus-visible {
                border-color: ${({ theme }) => theme.colors.primaryBlack};
                background: ${({ theme }) => theme.colors.primaryBlack};
                color: ${({ theme }) => theme.colors.white};
            }
        `};

    .info-check {
        padding-left: ${rem(10)};
        svg {
            width: ${rem(23)} !important;

            path {
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }
`;
