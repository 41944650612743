import React from 'react';
import { SC } from '../../styles/theme';
import { BRAND, isMarketGB } from '../../constants/main';
import { CheckoutFinanceWidgetStyledAC } from './AC/CheckoutFinanceWidgetStyled.AC';
import { CheckoutFinanceWidgetStyledOV } from './OV/CheckoutFinanceWidgetStyled.OV';
import { CheckoutFinanceWidgetStyledOVGB } from './OV/CheckoutFinanceWidgetStyled.OVGB';
import { CheckoutFinanceWidgetStyledAP } from './AP/CheckoutFinanceWidgetStyled.AP';
import { CheckoutFinanceWidgetStyledDS } from './DS/CheckoutFinanceWidgetStyled.DS';
import { CheckoutFinanceWidgetStyled } from './CheckoutFinanceWidgetStyled';
import { IFinanceQuote } from '../../services/calculateSummary/calculateSummary.types';
import { Dispatch } from 'redux';
import { IDeal } from '../../interfaces/Car';
import FinanceWidgetDuck from '../../redux/financeWidget/financeWidget.duck';
import { connect } from 'react-redux';
import { Redux } from 'src/redux/redux.interface';
import { BrandTypes } from '../../types/brands';

export interface CheckoutFinanceWidgetProps extends SC {
    financialSummaryData: IFinanceQuote;
    dispatch?: Dispatch;
    currentDeal?: IDeal;
}

const CheckoutFinanceWidget = (props: CheckoutFinanceWidgetProps) => {
    if (BRAND === BrandTypes.OV)
        return isMarketGB ? (
            <CheckoutFinanceWidgetStyledOVGB {...props} />
        ) : (
            <CheckoutFinanceWidgetStyledOV {...props} />
        );
    if (BRAND === BrandTypes.AC) return <CheckoutFinanceWidgetStyledAC {...props} />;
    if (BRAND === BrandTypes.AP) return <CheckoutFinanceWidgetStyledAP {...props} />;
    if (BRAND === BrandTypes.DS) return <CheckoutFinanceWidgetStyledDS {...props} />;
    return <CheckoutFinanceWidgetStyled {...props} />;
};

const mapStateToProps: (state: Redux) => CheckoutFinanceWidgetProps = (state) => ({
    financialSummaryData: FinanceWidgetDuck.getFinanceQuote(state),
});

export default connect(mapStateToProps)(CheckoutFinanceWidget);

export { CheckoutFinanceWidget };
