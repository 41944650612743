import { FAQ_URL_STRING } from 'src/constants/main';
import { CarJourneyType } from 'src/services';
import { buildParameterizedPathname } from '@utils/url.utils';
import routes from 'src/constants/routes';

export const PAGES_WITH_FAQ = [
    routes.SELECTOR,
    routes.CAR,
    routes.BASKET,
    routes.SUMMARY,
    routes.OFFERS_DELIVERY,
    routes.OFFERS_LOGIN,
    routes.DEALER,
];

export const getFAQsURL = (carJourney: CarJourneyType) => {
    const hashURLPart = `#${FAQ_URL_STRING}`;

    if (
        !PAGES_WITH_FAQ.some((allowedRoute) => (window?.location?.pathname ?? '').includes(allowedRoute)) &&
        !(
            window?.location?.pathname.includes(CarJourneyType.CONFIGURABLE) ||
            window?.location?.pathname.includes(CarJourneyType.STOCK)
        )
    ) {
        return `${buildParameterizedPathname(routes.ROOT, carJourney ?? CarJourneyType.CONFIGURABLE)}${hashURLPart}`;
    }

    const currentURL = window?.location?.href ?? '';
    const hashCharacterIndex = currentURL.indexOf('#');
    const URLWithoutHash = hashCharacterIndex === -1 ? currentURL : currentURL.substring(0, hashCharacterIndex);

    return `${URLWithoutHash}${hashURLPart}`;
};
