import { rem } from 'polished';
import styled from 'styled-components';
import { MarketingStickerStyled } from '../MarketingStickerStyled';

export const MarketingStickerStyledDS = styled(MarketingStickerStyled)`
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    height: auto;
    top: ${rem(16)};
    border-top-left-radius: ${rem(24)};
    border-bottom-left-radius: ${rem(24)};

    .infoText {
        font-size: ${rem(16)};
        padding-left: ${rem(5)};
    }

    .infoIcon {
        top: ${rem(5)};
        height: ${rem(16)};
        width: ${rem(16)};

        svg {
            height: ${rem(16)};
            width: ${rem(16)};
            g {
                circle,
                line {
                    stroke: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }
`;
