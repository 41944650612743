import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { InputStyledAC } from './AC/InputStyled.AC';
import { InputStyledOV } from './OV/InputStyled.OV';
import { InputStyledAP } from './AP/InputStyled.AP';
import { InputStyledDS } from './DS/InputStyled.DS';
import { InputStyledOV as InputStyledOV_GB } from './OV/GB/InputStyled.OV';
import { InputStyled } from './InputStyled';
import { SC } from '../../styles/theme';
import { INPUT_TYPES } from '../../constants/enums';

export interface InputProps extends SC {
    marginTop?: number;
    marginBottom?: number;
    label?: string;
    id?: string;
    placeholder?: string;
    defaultValue?: number | string;
    onChange?: any;
    hasError?: boolean;
    tooltip?: string;
    value?: number | string;
    withCurrency?: boolean;
    type?: INPUT_TYPES;
}

const Input = (props: InputProps) => {
    if (BRAND === 'AC') return <InputStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <InputStyledOV_GB {...props} /> : <InputStyledOV {...props} />;
    if (BRAND === 'AP') return <InputStyledAP {...props} />;
    if (BRAND === 'DS') return <InputStyledDS {...props} />;
    return <InputStyled {...props} />;
};

export default Input;
