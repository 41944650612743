import styled from 'styled-components';
import { rem } from 'polished';
import { ModalFindDealerStyled } from '../ModalFindDealerStyled';
export const ModalFindDealerStyledAP = styled(ModalFindDealerStyled)`
    .geolocation-input--container > .geolocation-input--textfield-container > input {
        border: solid 1px black;
        border-radius: unset;
    }

    .modalOpeningHours {
        font-size: ${rem('12px')};
        line-height: 1.67;
    }

    .modalContent {
        margin-top: ${rem('24px')};
        line-height: 1.67;

        .modalAddr {
            font-size: ${rem('12px')};
            margin-bottom: ${rem('16px')};

            .name {
                font-weight: bold;
            }
        }
    }

    .modalDealerSelect {
        .Select__control {
            border-radius: unset;
        }
    }

    .modalTxt {
        font-size: ${rem('12px')};
    }

    .modalHeader {
        margin-top: ${rem('32px')};

        .modalTitle {
            font-size: ${rem('25px')};
        }

        .icon-wrapper {
            cursor: pointer;

            svg {
                transform: rotate(45deg);
            }
        }
    }
`;
