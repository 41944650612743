import React from 'react';
import { SC } from '../../styles/theme';
import { BRAND, isMarketGB } from '../../constants/main';
import { CheckoutMyDetailsStyledAC } from './AC/CheckoutMyDetailsStyled.AC';
import { CheckoutMyDetailsStyledAP } from './AP/CheckoutMyDetailsStyled.AP';
import { CheckoutMyDetailsStyledDS } from './DS/CheckoutMyDetailsStyled.DS';
import { CheckoutMyDetailsStyledOV } from './OV/CheckoutMyDetailsStyled.OV';
import { CheckoutMyDetailsStyledOV as CheckoutMyDetailsStyledOVGB } from './OV/GB/CheckoutMyDetailsStyled.OV';
import { CheckoutMyDetailsStyled } from './CheckoutMyDetailsStyled';
import { IUser, IUserAddress } from '../../interfaces/User';
import { IConfigurableCar } from '../../interfaces/Car';
import { Dispatch } from 'redux';

export interface Props extends SC {
    userDetails?: IUser;
    userAddress?: IUserAddress;
    carDetails?: IConfigurableCar;
    className?: string;
    isLoading?: boolean;
    dispatch?: Dispatch;
}

const CheckoutMyDetails = (props: Props) => {
    if (BRAND === 'OV') return isMarketGB ? <CheckoutMyDetailsStyledOVGB {...props} /> : <CheckoutMyDetailsStyledOV {...props} />;
    if (BRAND === 'AC') return <CheckoutMyDetailsStyledAC {...props} />;
    if (BRAND === 'AP') return <CheckoutMyDetailsStyledAP {...props} />;
    if (BRAND === 'DS') return <CheckoutMyDetailsStyledDS {...props} />;
    return <CheckoutMyDetailsStyled {...props} />;
};

export default CheckoutMyDetails;
