import React, { FC } from 'react';
import Icon from '../Icon';
import { IconButtonProps, IconButtonType } from '.';
import Spinner from '@components/Spinner';
import AccessibleButton, { IAccessibleButton } from '@components/Button/AccessibleButton';

export const IconButtonTemplate: FC<IconButtonProps> = (props) => {
    const {
        icon,
        onClick,
        name,
        className,
        type = IconButtonType.vertical,
        width = 40,
        height = 40,
        iconClass,
        dataTestId = '',
        isLoading,
        tabIndex = 0,
        altText,
        altId,
        role,
        color,
    } = props;

    const elements: React.ReactNode[] = [
        <Icon
            className={iconClass}
            key={1}
            name={icon}
            width={width}
            height={height}
            altId={altId}
            altText={altText}
            role={role}
            color={color}
        />,
        <>{name && <h2 key={2}>{name}</h2>}</>,
    ].filter(Boolean);
    return (
        <AccessibleButton
            tabIndex={tabIndex}
            onClick={onClick}
            {...({ ...props, className: undefined } as unknown as IAccessibleButton)}
        >
            <div className={className} data-testid={dataTestId}>
                {type === IconButtonType.vertical ? (
                    <div className="vertical">
                        {isLoading ? <Spinner size={15} border={2} color="black" /> : elements}
                    </div>
                ) : (
                    <div className="horizontal">
                        {isLoading ? <Spinner size={15} border={2} color="black" /> : elements.reverse()}
                    </div>
                )}
            </div>
        </AccessibleButton>
    );
};
