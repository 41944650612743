import React, { FC, useEffect, useRef, memo } from 'react';
import { IDealer } from '../../../interfaces/Dealer';
import { IS_CLIENT } from '../../../constants/main';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';

interface CheckoutMapProps {
    dealers?: IDealer[];
    latitude: number;
    longitude: number;
    zoom: number;
    zoomControl?: boolean;
}

const CheckoutMap: FC<CheckoutMapProps> = memo(function Map({
    dealers,
    latitude,
    longitude,
    zoom,
    zoomControl = false,
}) {
    const mapRef = useRef();

    const googleApi = IS_CLIENT ? (window as any).google : null;
    const showRestOfDealerMarkers = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_REST_OF_DEALER_MARKERS);

    useEffect(() => {
        if (googleApi) {
            const map = new googleApi.maps.Map(mapRef.current, {
                center: { lat: latitude, lng: longitude },
                zoom,
                disableDefaultUI: true,
                zoomControl,
            });

            if (dealers && dealers.length > 0) {
                dealers.slice(0, 11).forEach((dealer, i) => {
                    new googleApi.maps.Marker({
                        position: { lat: dealer.latitude, lng: dealer.longitude },
                        map: map,
                        label: {
                            text: `${i + 1}`,
                            color: 'white',
                        },
                    });
                });
                showRestOfDealerMarkers &&
                    setTimeout(() => {
                        dealers.slice(11, dealers.length).forEach((dealer, i) => {
                            new googleApi.maps.Marker({
                                position: { lat: dealer.latitude, lng: dealer.longitude },
                                map: map,
                                label: {
                                    text: `${i + 1}`,
                                    color: 'white',
                                },
                            });
                        });
                    }, 1000);
            } else {
                new googleApi.maps.Marker({
                    position: { lat: latitude, lng: longitude },
                    map: map,
                });
            }
        }
    }, [googleApi, latitude, longitude, dealers, zoom]);

    return <div className="map" ref={mapRef} />;
});

export default CheckoutMap;
