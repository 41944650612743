import styled from 'styled-components';
import { PromoCodeStyled } from '../PromoCodeStyled';
import { rem } from 'polished';
import { IPromoCode } from '../index';

export const PromoCodeStyledOV = styled(PromoCodeStyled)`
    .promoCode {
        &__title {
            ${prop =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          color: '#1D1D1B',
                          background: '#F2F2F2',
                          'font-size': '16px',
                          'text-transform': 'uppercase',
                          padding: '1rem 1.5rem',
                          'font-weight': 'normal',
                      }
                    : {
                          background: 'unset',
                      }};
        }

        &__content {
            ${prop =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          color: '#1D1D1B',
                          padding: '1rem',
                      }
                    : {
                          padding: '10px 0',
                      }};

            span {
                &:nth-child(2) {
                    white-space: nowrap;
                }
            }
        }

        &__removeBtn {
            &:hover {
                color: ${({ theme }) => theme.colors.primaryHover};
            }
        }
    }
`;
