import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CustomErrorStyled } from '../CustomErrorStyled';
import { rem } from 'polished';
import { publicAssets } from '@utils/url.utils';

export const CustomErrorStyledOV = styled(CustomErrorStyled)`
    position: relative;
    background-image: linear-gradient(304deg, rgba(0, 0, 0, 0) 50%, black), url('${publicAssets(
        '/OV/ov_error_bg.webp'
    )}');
    background-repeat: no-repeat, no-repeat;
    background-position: left top, center center;
    background-size: 100%, cover;
    color: ${({ theme }) => theme.colors.pureWhite};
    font-weight: ${({ theme }) => theme.fontWeights.textRegular};

    .errorPage {
        &__content {
            z-index: 2;
            padding: ${rem(20)};
            margin: ${rem(30)} ${rem(10)};
            max-width: ${rem(450)};

            @media (min-width: ${breakpoints.sm}px) {
                padding: ${rem(40)} ${rem(20)};
                margin-left: ${rem(30)};
            }
        }

        &__title {
            font-size: ${rem('40px')};
            line-height: 1.2;
            text-transform: none;
        }

        &__description {
            font-size: ${rem('18px')};
            line-height: 1.5;
        }

        &__link a {
            font-size: ${rem('16px')};
            border-color: ${({ theme }) => theme.colors.pureWhite};
            color: ${({ theme }) => theme.colors.pureWhite};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            text-transform: none;

            &:hover {
                opacity: 1;
                background: ${({ theme }) => theme.colors.primaryBlack};
                border-color: ${({ theme }) => theme.colors.primaryBlack};
                text-decoration: none;
            }
        }
    }
`;
