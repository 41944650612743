import styled from 'styled-components';
import { MonthlyPriceLabelStyled } from '../MonthlyPriceLabelStyled';
import { AprSizes } from '@components/MonthlyPriceLabel';
import { rem } from 'polished';

export const MonthlyPriceLabelStyledAP = styled(MonthlyPriceLabelStyled)`
    .aprValue,
    .aprDescription {
        ${(prop) => (prop.size === AprSizes.LARGE ? `font-size: ${prop.theme.fontSizes.h5}` : '')};
        ${(prop) => (prop.size === AprSizes.MEDIUM ? `font-size: ${prop.theme.fontSizes.h6}` : '')};
        ${(prop) => (prop.size === AprSizes.SMALL ? `font-size: ${prop.theme.fontSizes.h7}` : '')};
    }
`;
