import styled from 'styled-components';
import { rem } from 'polished';
import { ModalNeedHelpTemplate } from './ModalNeedHelpTemplate';

export const ModalNeedHelpStyled = styled(ModalNeedHelpTemplate)`
    .description {
        margin: 0 0 ${rem(32)} 0;
        line-height: ${rem(18)};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.black2};
    }

    .phone,
    .request,
    .testDrive,
    .faq {
        position: relative;
        padding-left: ${rem(60)};
        margin: ${rem(10)} 0 ${rem(28)} 0;

        .icon {
            position: absolute;
            left: 0;
            top: -${rem(10)};

            svg {
                width: ${rem(48)};
            }
        }

        .title {
            font-size: ${rem(20)};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .titleLabel {
            padding-top: ${rem(12)};
        }
    }
`;
