import React, { useCallback, useEffect, useState } from 'react';
import { stepCodes, useUpdateStepCode } from '@hooks/useUpdateStepCode';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useCheckStockCarAvailabilityCheck } from '@hooks/useStockCarAvailabilityCheck';
import { useDealerDuck } from '@hooks/useDealerDuck';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useJourneyType } from '@hooks/useJourneyType';
import { useTranslations } from '@hooks/useTranslations';
import { useUIDuck } from '@hooks/useUIDuck';
import { useUserDuck } from '@hooks/useUserDuck';
import { useGTM } from '@hooks/useGTM';
import CheckoutMyDetails from '@components/CheckoutMyDetails';
import CheckoutStepWizard from '@components/CheckoutStepWizard';
import Main from '@components/Layout/Main';
import { batch, useDispatch } from 'react-redux';
import { IUserAddress } from '../../interfaces/User';
import { CarJourneyType, CASH, dealerApi, DealService, FINANCE, UserService } from '../../services';
import { getSessionIdCookie } from '@utils/Session.utils';
import { getInitalJourneyType } from '@utils/url.utils';
import { handleFinanceQuote, updateGeneralContext } from '@utils/getInitialProps/common';
import { DEAL_PRODUCT_TYPE, PxVersion, SESSION_COOKIE_NAME } from '../../constants/main';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import FilterDuck from '../../redux/filters/filter.duck';
import { MySavedDealJourneyType } from '../../redux/user/user.duck.interface';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import isEmpty from 'lodash/isEmpty';
import RedirectionModal from '@components/RedirectionModal';
import { ContextWithStore } from '@utils/getInitialProps/@types';
import carDetailsDuck from 'src/redux/carDetails/carDetails.duck';
import dealerDuck from 'src/redux/dealer/dealer.duck';
import financeWidgetDuck from 'src/redux/financeWidget/financeWidget.duck';
import globalDuck from 'src/redux/global/global.duck';
import { NETWORK_STATUS_DONE } from 'src/redux/redux.interface';
import userDuck from 'src/redux/user/user.duck';
import routes from 'src/constants/routes';
import DealerDuck from 'src/redux/dealer/dealer.duck';
import { validateDealerId } from '@utils/Deal.utils';
import { syncPartExchange } from '../../partExchange/service/ApiService';
import ConfiguratorDuck from '../../redux/configurator/configurator.duck';
import { processMopData } from '../../partExchange/utility/general';

type IComponentProps = {
    setUserAddress?: IUserAddress;
};

export const dealStatuses = {
    checkoutDealer: 'checkout_dealer',
    checkoutLogin: 'checkout_login',
    checkoutBrandId: 'checkout_brandid',
    offerCreated: 'offer_created',
    checkoutMyDetails: 'checkout_my_details',
    sentToCustomer: 'sent_to_customer',
    redirectedToSepa: 'redirected_to_sepa',
    redirectedToLoanProvider: 'redirected_to_loan_provider', // in mop name si pending
    sepaFailed: 'sepa_failed',
    vehicleSummary: 'vehicle_summary',
    basket: 'basket',
    financeApproved: 'finance_approved',
    checkoutProforma: 'checkout_proforma',
    financeRejected: 'finance_rejected',
    cashPayment: 'cash_payment',
    cashPaymentKo: 'cash_payment_ko',
};

function MyDetails({}: IComponentProps) {
    const { t } = useTranslations();
    const { pushMyDetailsPageLoad, pushCheckoutEvent } = useGTM();
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);

    const dispatch = useDispatch();
    const { paymentJourneyType } = useJourneyType();
    const { isLoading } = useUIDuck();
    const { carDetails, currentDeal } = useCarDetailsDuck();
    const { userAuth, userDetails, userAddress, isUserLoggedInAsDealer } = useUserDuck();
    const { dealer } = useDealerDuck();
    const { isStockJourney } = useCarStockJourneyCheck();

    useUpdateStepCode(undefined, stepCodes.personalDetails);

    useCheckStockCarAvailabilityCheck(
        carDetails.model?.title,
        carDetails.nameplateBodyStyleSlug,
        paymentJourneyType,
        carDetails.stockSpecificFields?.booked
    );

    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);
    const isEC41Journey = currentDeal?.productType === DEAL_PRODUCT_TYPE.CHILD;
    const isBasketEmpty = isEmpty(carDetails);

    const setDealStatusAndUpdateMopStep = useCallback(async (): Promise<void> => {
        const { data } = await DealService.updateDeal(
            {
                status: dealStatuses.checkoutMyDetails,
                saved: userAuth.isLoggedIn,
            },
            getSessionIdCookie(),
            true
        );
        const { deal, financeQuote } = data;

        batch(() => {
            dispatch(carDetailsDuck.setCurrentDealAndCarDetail(deal));
            dispatch(financeWidgetDuck.setFinanceQuote(financeQuote));
        });
    }, [userAuth.isLoggedIn, isSOLEnabled]);
    let dealerId: string = dealer?.siteGeo ?? '';

    useEffect(() => {
        // store dealer info
        if (!isStockJourney && !dealerId) {
            DealService.currentDeal(getSessionIdCookie()).then((res) => {
                if (validateDealerId(res.data.dealerId)) {
                    dealerId = res.data.dealerId;
                    dealerApi.getDealer(res.data.dealerId).then((res) => dispatch(dealerDuck.setDealer(res.data)));
                }
            });
        }
    }, [dealerId]);

    useEffect(() => {
        if (userAuth.status === NETWORK_STATUS_DONE) {
            if (Object.entries(carDetails).length !== 0) {
                pushMyDetailsPageLoad(carDetails, dealer, paymentJourneyType, isEC41Journey);
                pushCheckoutEvent(2, carDetails);
            }

            setDealStatusAndUpdateMopStep();

            (async () => {
                try {
                    if (userAuth.isLoggedIn && !isUserLoggedInAsDealer) {
                        const userDetails = await UserService.userDetails(getSessionIdCookie());
                        const userAddress = await UserService.userAdress(getSessionIdCookie());

                        batch(() => {
                            dispatch(userDuck.setUserDetails(userDetails.data));
                            dispatch(userDuck.setUserAddress(userAddress.data.address));
                        });

                        setDealStatusAndUpdateMopStep();

                        setIsLoadingUserData(false);
                    } else {
                        setIsLoadingUserData(false);
                    }
                } catch (err: any) {
                    setIsLoadingUserData(false);
                    console.log((err as any)?.message || 'Problem with fetching user data');
                }
            })();
        }
    }, [userAuth.status]);

    return (
        <Main title={t('checkout.tabTitle')} checkout>
            {isBasketEmpty ? (
                <RedirectionModal />
            ) : (
                <>
                    <CheckoutStepWizard actualState="my-details" stock={carDetails.stock} />
                    <CheckoutMyDetails
                        userDetails={userDetails}
                        userAddress={userAddress}
                        isLoading={isLoadingUserData || isLoading}
                    />
                </>
            )}
        </Main>
    );
}

MyDetails.getInitialProps = async (context: ContextWithStore) => {
    const { store, query, req, featureContext, res } = await updateGeneralContext(context);
    try {
        const token = req?.cookies?.[SESSION_COOKIE_NAME] ?? getSessionIdCookie();
        const isUserLogged = userDuck.isLoggedIn(store.getState());
        if (
            !carDetailsDuck.getCurrentDeal(store.getState())?.token ||
            !carDetailsDuck.getCurrentCarDetails(store.getState())?.lcdv16
        ) {
            const currentDeal = await DealService.currentDeal(token, false, false);
            let carJourney: CarJourneyType;
            if (currentDeal?.data) {
                carJourney = getInitalJourneyType(currentDeal.data.fullProductConfiguration);
                const carConfiguration = currentDeal.data.fullProductConfiguration;

                // APP-24414
                const isDealerChoiceEnabled = featureContext.FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED;
                const storeDealer = DealerDuck.getOwnState(store.getState()).dealer;
                const isDealerSelected =
                    carConfiguration.dealers?.[0] ||
                    !!storeDealer?.externalId ||
                    !!currentDeal.data.dealerId ||
                    !!storeDealer.city;

                if (isDealerChoiceEnabled && !isDealerSelected) {
                    if (res) {
                        res.writeHead(307, { Location: routes.CHECKOUT_DELIVERY });
                        res.end();
                        return { query, namespacesRequired: ['common'], statusCode: 307, res };
                    } else {
                        window.location.href = window.location.origin + routes.CHECKOUT_DELIVERY;
                    }
                }

                if (carConfiguration.stock || !!carConfiguration.dealers?.length) {
                    store.dispatch(dealerDuck.setDealer(carConfiguration.dealers[0]));
                } else if (validateDealerId(currentDeal.data.dealerId)) {
                    store.dispatch(dealerDuck.setDealer((await dealerApi.getDealer(currentDeal.data.dealerId))?.data));
                }
                await handleFinanceQuote(store, currentDeal.data.financeQuoteId);
            }

            const isAgent = userDuck.isAgentLogged(store.getState());
            const userAddress = isUserLogged && !isAgent && (await UserService.userAdress(token));
            const userDetails = isUserLogged && !isAgent && (await UserService.userDetails(token));

            const pxVersion = featureContext.FEATURE_SWITCH_PX_VERSION;

            if (pxVersion === PxVersion.v2 && currentDeal?.data?.partExchangeId) {
                const { data } = await syncPartExchange(currentDeal?.data?.token);
                store.dispatch(ConfiguratorDuck.setPxState(processMopData(data?.data)));
            }

            batch(() => {
                store.dispatch(carDetailsDuck.setCurrentDeal(currentDeal.data));
                store.dispatch(carDetailsDuck.setCurrentCarDetails(currentDeal.data.fullProductConfiguration));
                store.dispatch(globalDuck.setCarJourney(carJourney));
                store.dispatch(userDuck.setUserAddress(userAddress?.data?.address));
                store.dispatch(userDuck.setUserDetails(userDetails?.data));
                store.dispatch(
                    FilterDuck.changeBudgetType(
                        currentDeal.data.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH ? CASH : FINANCE
                    )
                );
            });
        } else if (!dealerDuck.getOwnState(store.getState()).dealer?.name) {
            const currentDeal = carDetailsDuck.getCurrentDeal(store.getState());
            const carConfiguration = carDetailsDuck.getCurrentCarDetails(store.getState());

            if (carConfiguration.stock || !!carConfiguration.dealers?.length) {
                store.dispatch(dealerDuck.setDealer(carConfiguration.dealers[0]));
            } else if (validateDealerId(currentDeal.dealerId)) {
                store.dispatch(dealerDuck.setDealer((await dealerApi.getDealer(currentDeal.dealerId))?.data));
            }
        }
    } catch (e: any) {
        console.error(e);
    }
    return { query, namespacesRequired: ['common'] };
};

export default MyDetails;
