import { css } from 'styled-components';

export const RowCellMainStylesAC = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
    line-height: ${({ theme }) => theme.lineHeights.textSupBig};

    .engine-title {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        line-height: ${({ theme }) => theme.lineHeights.h5};
    }

    .engine-price {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        line-height: ${({ theme }) => theme.lineHeights.textMedium};
    }
`;

export const RowCellGearboxStylesAC = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
`;

export const RowTitleStylesAC = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.h4};
`;

export const CategoryTitleStylesAC = css`
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
`;
