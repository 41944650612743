import { useSelector } from 'react-redux';
import { Redux } from 'src/redux/redux.interface';
import carDetailsDuck from '../redux/carDetails/carDetails.duck';
import { IS_B2B } from '../constants/main';

const getSiteTarget = () => {
    if (IS_B2B) {
        return 'B2B';
    }

    return 'B2C';
};

export const useGetCustomerType = () => {
    let customerType = getSiteTarget();
    const currentDeal = useSelector((state: Redux) => carDetailsDuck.getOwnState(state).currentDeal);

    if (currentDeal?.extraFields?.promoCode) {
        customerType = JSON.parse(currentDeal?.extraFields?.promoCode)?.customerType;
    }

    return customerType;
};
