import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { ButtonStyled } from '../ButtonStyled';
import { rem } from 'polished';

export const ButtonStyledAC = styled(ButtonStyled)`
    padding: 0 ${rem(24)};
    border-radius: ${rem(24)};
    height: ${rem(48)};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};

    ${({ primary, withoutBackground }) =>
        primary &&
        css`
            background: ${({ theme }) => (withoutBackground ? theme.colors.white : theme.colors.red)};
            color: ${({ theme }) => (withoutBackground ? theme.colors.red : theme.colors.white)};
            border: ${({ theme }) => (withoutBackground ? `2px solid ${theme.colors.red}` : 'unset')};

            svg {
                path {
                    fill: ${({ theme }) => (withoutBackground ? theme.colors.red : theme.colors.white)};
                }
            }

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => (withoutBackground ? theme.colors.white : theme.colors.grey5)};
                color: ${({ theme }) => (withoutBackground ? theme.colors.grey5 : theme.colors.white)};
                border-color: ${({ theme }) => theme.colors.grey5};

                svg {
                    path {
                        fill: ${({ theme }) => (withoutBackground ? theme.colors.grey5 : theme.colors.white)};
                    }
                }
            }
        `}

    ${({ secondary, withoutBackground }) =>
        secondary &&
        css`
            background: ${({ theme }) => (withoutBackground ? theme.colors.white : theme.colors.blue2)};
            color: ${({ theme }) => (withoutBackground ? theme.colors.blue2 : theme.colors.white)};
            border: ${({ theme }) => (withoutBackground ? `2px solid ${theme.colors.blue2}` : 'unset')};

            svg {
                path {
                    fill: ${({ theme }) => (withoutBackground ? theme.colors.blue2 : theme.colors.white)};
                }
            }

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => (withoutBackground ? theme.colors.white : theme.colors.grey5)};
                color: ${({ theme }) => (withoutBackground ? theme.colors.grey5 : theme.colors.white)};
                border-color: ${({ theme }) => theme.colors.grey5};

                svg {
                    path {
                        fill: ${({ theme }) => (withoutBackground ? theme.colors.grey5 : theme.colors.white)} !important;
                    }
                }
            }
        `}

    ${({ primary, secondary, disabled }) =>
        (primary || secondary) &&
        disabled &&
        css`
            background: ${({ theme }) => theme.colors.grey6};
            color: ${({ theme }) => theme.colors.grey3};
            border-color: unset;
        `}
`;
