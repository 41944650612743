import React, { FC, useState, useEffect, useCallback, useRef, useContext, memo } from 'react';
import debounce from 'lodash/debounce';
import {
    GOOGLE_MAP_SEARCH_LIMIT,
    GOOGLE_MAP_DEFAULT_LNG,
    GOOGLE_MAP_DEFAULT_LAT,
    GOOGLE_RESTRICTED_COUNTRY,
    IS_DEV,
    MARKET,
} from '../../../constants/main';
import { CheckoutDealerDispatch, ACTIONS } from '..';
import { IPredictionedPlace } from '../../../interfaces/Dealer';
import useTranslations from '@hooks/useTranslations';
import Button from '../../Button';
import { dealerPlaces } from '../../../mockData';
import { CheckoutModalDealerDispatch } from '@components/Modal/ModalDealerSearch/ModalDealerSearchTemplate';
import { useRouter } from 'next/router';
import routes from '../../../constants/routes';

interface IPlace {
    id: string;
    name: string;
}

let sessionToken: any = undefined;
let autocompleteService: any = undefined;

const CheckoutDealerSearch: FC = memo(function DealerSearch() {
    const { t } = useTranslations();
    const router = useRouter();
    const inputRef = useRef();
    const dispatch = useContext(
        router.pathname.includes(routes.BASKET) || router.pathname.includes(routes.SUMMARY)
            ? CheckoutModalDealerDispatch
            : CheckoutDealerDispatch
    );

    const [inputData, setInputData] = useState<IPlace>({ id: '', name: '' });
    const [inputPredictions, setInputPredictions] = useState<IPredictionedPlace[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleSuggestions = (predictions: any, status: any) => {
        if (
            // @ts-ignore
            [google.maps.places.PlacesServiceStatus.OK, google.maps.places.PlacesServiceStatus.ZERO_RESULTS].includes(
                status
            )
        ) {
            setInputPredictions(predictions);
        } else if (IS_DEV) {
            setInputPredictions(dealerPlaces);
        }
    };

    const onChangeHandler = useCallback((e: any) => {
        const val = e.target.value;
        setInputData((prevState) => ({ ...prevState, name: val }));
    }, []);

    const handleInputData = useCallback(
        (place: IPlace) => () => {
            let geoInputData: { [key: string]: string } = { placeId: place.id, region: MARKET };
            setInputData(place);

            setTimeout(() => {
                setShowDropdown(false);
            }, 0);

            if (!place.id && !place.name) {
                dispatch({
                    type: ACTIONS.SET_SELECTED_PLACE,
                    payload: { lat: Number(GOOGLE_MAP_DEFAULT_LAT), lng: Number(GOOGLE_MAP_DEFAULT_LNG) },
                });
                return;
            }

            if (!place.id && inputPredictions?.length > 0) {
                geoInputData = { placeId: inputPredictions?.[0].place_id, region: MARKET };
            } else if (!place.id && place.name) {
                geoInputData = { address: place.name, region: MARKET };
            }

            // @ts-ignore
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode(geoInputData, function (results: any[], status: string) {
                if (status === 'OK') {
                    const location = results[0].geometry.location;
                    dispatch({
                        type: ACTIONS.SET_SELECTED_PLACE,
                        payload: { lat: location.lat(), lng: location.lng() },
                    });
                } else {
                    // TODO - only for testing, should be deleted
                    dispatch({
                        type: ACTIONS.SET_SELECTED_PLACE,
                        payload: { lat: 45.77786, lng: 3.14321041 },
                    });
                }
            });

            setInputPredictions([]);
        },
        [dispatch, inputPredictions]
    );

    const getPlacePredictions = useRef<any>(null);
    useEffect(() => {
        // @ts-ignore
        sessionToken = new google.maps.places.AutocompleteSessionToken();

        // @ts-ignore
        autocompleteService = new google.maps.places.AutocompleteService();
        getPlacePredictions.current = debounce(async (text: string) => {
            await autocompleteService.getPlacePredictions(
                {
                    input: text,
                    componentRestrictions: { country: GOOGLE_RESTRICTED_COUNTRY.toLowerCase() },
                    types: ['(cities)'],
                    sessionToken,
                },
                handleSuggestions
            );
        }, 350);
    }, []);

    useEffect(() => {
        if (inputData.name.length >= Number(GOOGLE_MAP_SEARCH_LIMIT)) {
            setShowDropdown(true);
            getPlacePredictions?.current(inputData.name);
        } else {
            setShowDropdown(false);
        }
    }, [inputData.name, inputData.id]);

    return (
        <div className="dealerSearch">
            <div className="dealerSearch__input" data-testid="TESTING_DEALER_SEARCH_DIV">
                <input
                    ref={inputRef}
                    value={inputData.name}
                    onChange={onChangeHandler}
                    type="text"
                    placeholder={t('checkout.delivery.dealer.input.placeholder')}
                    data-testid="TESTING_DEALER_SEARCH_INPUT"
                />
                {inputPredictions?.length > 0 && showDropdown && (
                    <div className="dealerSearch__dropDown">
                        <ul>
                            {inputPredictions.map((place) => {
                                const item = { id: place.id, place: place.description.split(',')[0] };
                                return (
                                    <li
                                        onClick={handleInputData({ id: place.place_id, name: item.place })}
                                        key={item.id}
                                    >
                                        {item.place}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
            <Button
                // @ts-ignore
                data-testid="TESTING_DEALER_SEARCH"
                onClick={handleInputData({ id: '', name: inputData.name })}
                primary
            >
                {t('checkout.delivery.dealer.searchDealer')}
            </Button>
        </div>
    );
});

export default CheckoutDealerSearch;
