import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalTrimTemplate from './ModalTrimTemplate';
import { TRIM_DESKTOP_WIDTH } from './ModalTrimTemplate';
import { isMarketES, isMarketIT } from 'src/constants/main';
import { MOBILE_AND_DESKTOP_VERSION_BREAKPOINT } from '@components/Modal/ModalComparators/ModalTrimCompare/shared';

export const ModalTrimStyled = styled(ModalTrimTemplate)`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.textBase};

    .stickyTrimAvailability {
        position: sticky;
        top: 0;
        height: ${rem(60)};
        background: ${({ theme }) => theme.colors.white};
        z-index: 9999;
        display: flex;
        align-items: center;

        @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
            height: ${rem(25)};
        }
    }

    .stickyTrimName {
        position: sticky;
        background: ${({ theme }) => theme.colors.white};
        z-index: 9999;
        top: ${rem(35)};

        @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
            top: ${rem(25)};
        }
    }

    .stickyTrimPrice {
        background: ${({ theme }) => theme.colors.white};
        z-index: 9999;

        @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
            position: sticky;
        }
    }

    .trims {
        padding-left: ${rem(20)};

        .title {
            font-size: ${rem(25)};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;

            svg {
                width: ${rem(25)};
                height: ${rem(25)};
                transform: rotate(-90deg);
                margin-top: ${rem(4)};
                margin-right: ${rem(8)};
            }

            .txt {
                padding: 0 ${rem(15)} ${rem(5)} 0;
                margin-top: ${rem(5)};
                white-space: pre-wrap;
            }

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                .txt {
                    margin-top: ${rem(35)};
                    padding: 0 ${rem(15)} 0 ${rem(75)};
                }
            }
        }

        .sections {
            display: flex;
        }

        .trim {
            width: calc(50% - 20px);
            min-width: calc(50% - 20px);
            margin-right: ${rem(20)};
            display: flex;
            flex-direction: column;

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                width: ${rem(TRIM_DESKTOP_WIDTH)};
                min-width: ${rem(TRIM_DESKTOP_WIDTH)};
            }

            .trimImage {
                width: 100%;
                padding-top: ${rem(35)};

                @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                    width: ${rem(250)};
                }
            }

            .trimPromo {
                padding-top: ${rem(50)};
                text-align: right;

                @media (min-width: ${breakpoints.md}px) {
                    padding-top: ${rem(35)};
                }
            }

            .trimPromoSpace {
                margin-top: ${rem(35)};
                height: ${rem(26)};
            }

            .trimName {
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                padding-top: ${rem(15)};
                display: block;
            }

            .trimEngine {
                padding-top: ${rem(10)};
                display: none;
            }

            .trimLabel {
                padding: ${rem(15)} 0 ${rem(5)} 0;
                display: block;
                height: ${rem(45)};
            }

            .trimDescription {
                color: ${({ theme }) => theme.colors.grey31};
                display: block;
                padding-top: ${rem(10)};
                margin-top: auto;
                margin-bottom: auto; /* Combine with .alignToBottom */
            }

            .alignToBottom,
            .trimDescription {
                margin-top: auto;
            }

            .trimPrice {
                padding: ${rem(20)} 0;
                display: block;
                line-height: ${rem(18)};

                .monthlyPrice {
                    white-space: ${isMarketES || isMarketIT ? 'wrap' : 'nowrap'};
                }
            }
        }
    }

    .properties {
        border-top: 1px solid ${({ theme }) => theme.colors.black};
        padding-top: ${rem(30)};
    }

    .options {
        margin: 0 0 0 ${rem(20)};
        padding-bottom: ${rem(30)};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};

        .label {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${rem(18)};
            text-transform: uppercase;
        }

        .description {
            width: ${rem(220)};

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                width: ${rem(280)};
            }

            @media (min-width: ${breakpoints.md}px) {
                width: 100%;
            }
        }

        .filter {
            display: none;
            flex-wrap: wrap;
            padding: ${rem(20)} 0 ${rem(20)} 0;

            button {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                border: 1px solid ${({ theme }) => theme.colors.black};
                background: none;
                padding: ${rem(10)} ${rem(15)};
                cursor: pointer;
                border-radius: ${rem(4)};
                margin-right: ${rem(10)};
                white-space: nowrap;
                margin-bottom: ${rem(10)};

                &.selected,
                &:hover {
                    background: rgba(0, 163, 224, 0.1);
                    border: 1px solid ${({ theme }) => theme.colors.primary};
                    color: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }

    .interior,
    .feature,
    .config {
        padding-bottom: ${rem(10)};

        .name,
        .configDetails {
            display: flex;
            max-width: 90vw;
        }
    }

    .keyFeatures,
    .enginesAvailable,
    .colors,
    .interiorsAvailable,
    .featuresAvailable {
        margin: 0 0 ${rem(30)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.black};
        padding-left: ${rem(20)};

        .label {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${rem(18)};
            text-transform: uppercase;
            display: block;
            padding-bottom: ${rem(35)};

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                padding-bottom: 0;
            }
        }

        .image {
            padding-top: ${rem(25)};

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                width: ${rem(TRIM_DESKTOP_WIDTH)};
            }
        }

        .sections {
            display: flex;
            background: #f4f4f4;
            padding: 5px 0;
            margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(0)};

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                background: unset;
            }
          
            &.withoutBackground {
                background: none;
            }

            .keyFeaturesConfig,
            .engines,
            .styles,
            .exterior,
            .interiors,
            .features {
                min-width: calc(50% - 20px);
                width: calc(50% - 20px);
                margin-right: ${rem(25)};

                &.center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                    min-width: ${rem(TRIM_DESKTOP_WIDTH)};
                    width: ${rem(TRIM_DESKTOP_WIDTH)};
                }

                .engine {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                }

                .config,
                .engine,
                .style,
                .interior,
                .feature {
                    position: relative;

                    @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                        margin-bottom: ${rem(25)};
                    }

                    .engineDetails {
                        width: 100%;
                    }

                    .details {
                        display: block;
                        color: ${({ theme }) => theme.colors.black};

                        @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                            padding-bottom: ${rem(5)};
                        }
                    }

                    .configDetails {
                        display: block;
                    }

                    &.noExistInConfiguration {
                        opacity: 0.25;
                    }

                    &.withPadding {
                        padding-top: ${rem(6)};
                        padding-left: ${rem(4)};
                    }

                    .name {
                        justify-content: space-between;
                        line-height: ${rem(16)};
                        width: calc(100% - 8px);

                        &.hideOnMobile {
                            display: none;

                            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                                display: flex;
                            }
                        }

                        svg {
                            width: ${rem(14)};
                            height: ${rem(14)};
                            margin-top: ${rem(2)};

                            g {
                                circle,
                                line {
                                    stroke: ${({ theme }) => theme.colors.primary};
                                }
                            }

                            path {
                                fill: ${({ theme }) => theme.colors.primary};
                            }
                        }
                    }

                    .hidden {
                        visibility: hidden;
                    }
                }
            }
        }
    }

    .featuresAvailable {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};
        margin: 0 ${rem(20)} 0 ${rem(20)};
        padding: ${rem(20)} 0 0 0;

        .label {
            font-size: ${rem(12)};
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            svg {
                width: ${rem(18)};
                height: ${rem(18)};

                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }
        }

        .Collapsible__trigger {
            &.is-open {
                svg {
                    transform: rotate(-180deg);
                }
            }

            &.is-closed {
                .label {
                    padding-bottom: ${rem(15)};
                }
            }
        }
    }

    .colors {
        .configurations {
            display: flex;
            flex-direction: column;
            padding: 0 0 ${rem(25)} ${rem(2)};

            .exterior {
                margin-bottom: ${rem(15)};
                position: relative;
            }

            .name {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: ${rem(20)};

                img {
                    width: ${rem(35)};
                    height: ${rem(35)};
                    border-radius: 50%;
                }

                &.hidden {
                    visibility: hidden;
                }
            }

            img {
                width: ${rem(120)};

                @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                    width: ${rem(250)};
                }
            }

            button {
                width: ${rem(40)};
                height: ${rem(40)};
                border-radius: 50%;
                margin: ${rem(20)} ${rem(5)} ${rem(10)} 0;
                border: 0.1rem solid ${({ theme }) => theme.colors.white};
                text-align: center;
                cursor: pointer;

                &.selected,
                &:hover {
                    border: 0.15rem solid ${({ theme }) => theme.colors.white};
                    box-shadow: 0px 0px 0px 0.1rem ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }

    .availability {
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        background: ${({ theme }) => theme.colors.white};
        position: sticky;
        margin-left: ${rem(20)};
        top: ${rem(10)};
        height: ${rem(35)};
        white-space: nowrap;
        z-index: 99999999999999;
        display: flex;
        align-items: center;

        @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
            gap: ${rem(5)};
            display: flex;
            height: auto;
            flex-direction: column;
            width: ${rem(200)};
            top: ${rem(75)};
            align-items: flex-start;
        }

        .item {
            padding-right: ${rem(10)};
        }

        .icon {
            display: inline-block;
            width: ${rem(10)};
            height: ${rem(10)};
            margin-right: ${rem(5)};
            border: 1px solid ${({ theme }) => theme.colors.grey12};
            border-radius: 50%;

            &.available {
                background: ${({ theme }) => theme.colors.black};
                border-color: ${({ theme }) => theme.colors.black};
            }

            &.optional {
                background: ${({ theme }) => theme.colors.white};
                border-color: ${({ theme }) => theme.colors.black};
            }

            &.unavailable {
                height: ${rem(1)};
                width: ${rem(12)};
                border: 1px solid ${({ theme }) => theme.colors.black};
                margin-bottom: ${rem(2)};
                border-radius: 0;
            }
        }

        .availabilityBackBtn {
            display: none;

            svg {
                width: ${rem(25)};
                height: ${rem(25)};
                transform: rotate(-90deg);
                margin-right: ${rem(12)};
            }
        }
    }

    .iconAvailability {
        width: ${rem(10)};
        height: ${rem(10)};
        margin-bottom: ${rem(2)};
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;

        @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
            top: 30%;
        }

        &.default {
            background: ${({ theme }) => theme.colors.black};
        }

        &.optional {
            background: ${({ theme }) => theme.colors.white};
            border: 1px solid ${({ theme }) => theme.colors.black};
            top: ${rem(2)};

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                top: 30%;
            }
        }

        &.noAvailable {
            border: 1px solid ${({ theme }) => theme.colors.black};
            border-radius: unset;
            height: 1px;
            width: ${rem(12)};
            margin-left: -${rem(2)};
            top: ${rem(5)};

            @media (min-width: ${MOBILE_AND_DESKTOP_VERSION_BREAKPOINT}px) {
                top: 30%;
            }
        }
    }

    .currencyLabel__suffix--TTC {
        white-space: pre-wrap;
    }

    .spinner {
        margin: ${rem(30)} 0;
    }
`;
