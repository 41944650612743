import styled from 'styled-components';
import { rem } from 'polished';
import { CheckoutDealerInfoStyled } from '../CheckoutDealerInfoStyled';

export const CheckoutDealerInfoStyledAP = styled(CheckoutDealerInfoStyled)`
    .mapPointIcon {
        svg {
            height: ${rem(27)};
            width: ${rem(27)};
            position: relative;
            bottom: ${rem(3)};
        }
    }

    .phoneIcon {
        align-items: center;

        svg {
            height: ${rem(25)};
            width: ${rem(25)};

            & > path {
                stroke: red;
            }
        }
    }

    .infoIcon {
        svg {
            height: ${rem(18)};
            width: ${rem(18)};
        }

        g > * {
            stroke: #007edb;
            stroke-width: ${rem(1)};
        }

        g > line {
            stroke-width: ${rem(2)};
        }
    }

    .mailWrapper {
        svg {
            height: ${rem(25)};
            width: ${rem(30)};
        }
    }

    svg {
        g {
            path {
                stroke: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .dealerAddressTitle,
    .dealerInfoTitle,
    .mailWrapper,
    .addressWrapper,
    .phoneWrapper {
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        p {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }
    }
`;
