import { IFeature } from '../services';
import { IAggregatedTrim, ICarDetail } from '../redux/carDetails/carDetails.duck.interface';
import { ITrimItem } from '../redux/trimSelector/trimSelector.duck.interface';
import { ICarFeature } from '../interfaces/Car';
import { STANDARD_FEATURES_FS_PREFIX } from '../constants/characteristics';

export const getUniqueInteriors = (trims: IAggregatedTrim[]): IFeature[] => {
    const uniqueInteriorColors = [] as IFeature[];

    trims?.map((trim) =>
        trim?.engines?.map((trim) => {
            trim?.interiorColors?.map((interior: IFeature) => {
                if (!uniqueInteriorColors.map((uniqueInterior) => uniqueInterior.id).includes(interior.id)) {
                    uniqueInteriorColors.push(interior);
                }
            });
        })
    );

    return uniqueInteriorColors;
};

export const getUniqueExteriorColors = (trims: IAggregatedTrim[]): IFeature[] => {
    const uniqueExteriorColors = [] as IFeature[];

    trims?.map((trim) =>
        trim?.engines?.map((trim) => {
            trim?.exteriorColors?.map((interior: IFeature) => {
                if (!uniqueExteriorColors.map((uniqueInterior) => uniqueInterior.id).includes(interior.id)) {
                    uniqueExteriorColors.push(interior);
                }
            });
        })
    );

    return uniqueExteriorColors;
};

export const getUniqueFeatures = (trims: IAggregatedTrim[]): IFeature[] => {
    const uniqueFeatures = [] as IFeature[];

    trims?.map((trim) =>
        trim?.engines?.map((trim) => {
            trim?.cheapestCar?.seriesEquipment?.map((seriesEquipment: any) => {
                const data = seriesEquipment.data;

                if (Array.isArray(data)) {
                    data.map((equipment) => {
                        if (!uniqueFeatures.includes(equipment.label)) {
                            uniqueFeatures.push(equipment.label);
                        }
                    });
                } else {
                    Object.keys(data).map((key) => {
                        if (!uniqueFeatures.includes(data[key].label)) {
                            uniqueFeatures.push(data[key].label);
                        }
                    });
                }
            });
        })
    );

    return uniqueFeatures;
};

export const getUniqueEquipments = (trims: IAggregatedTrim[], excludedCategoriesStr: string, category: string) => {
    const uniqueEquipments = [] as { key: string; label: string; category: string; description?: string; images: { url: string }[] }[];

    trims?.map((allTrim) =>
        allTrim?.engines?.map((engine) => {
            engine.cheapestCar.standardFeatures
                ?.filter(
                    (feature: { key: string }) =>
                        !excludedCategoriesStr?.includes(`${STANDARD_FEATURES_FS_PREFIX}:${feature.key}`)
                )
                ?.find((feature: { category: string }) => feature.category === category)
                ?.data?.map((configuration: any) => {
                    if (!uniqueEquipments.find((uniqueEquipment) => uniqueEquipment.key === configuration.key)) {
                        uniqueEquipments.push(configuration);
                    }
                });
        })
    );

    return uniqueEquipments;
};

export const isEquipmentAvailableInConfiguration = (
    features: ICarFeature[],
    excludedCategoriesStr: string,
    category: string,
    featureKey: string
): boolean => {
    let isAvailable = false;

    features
        ?.filter(
            (feature: { key: string }) =>
                !excludedCategoriesStr?.includes(`${STANDARD_FEATURES_FS_PREFIX}:${feature.key}`)
        )
        ?.find((feature: { category: string }) => feature.category === category)
        ?.data?.map((configuration: { label: string; key: string }) => {
            if (featureKey === configuration.key) {
                isAvailable = true;
            }
        });

    return isAvailable;
};

export const isInteriorAvailableInConfiguration = (trim: IAggregatedTrim, interiorColor: IFeature) =>
    trim?.interiorColors?.map(({ id }) => id).includes(interiorColor.id);

export const isExteriorColorAvailableInConfiguration = (trim: IAggregatedTrim, exteriorColor: IFeature) =>
    trim?.exteriorColors?.map(({ id }) => id).includes(exteriorColor.id);

export const isFeatureAvailableInConfiguration = (features: ICarFeature[], featureLabel: string) => {
    let isAvailable = false;

    features?.map((seriesEquipment: ICarFeature) => {
        const data = seriesEquipment.data;

        if (Array.isArray(data)) {
            data.map((equipment) => {
                if (equipment.label === featureLabel) {
                    isAvailable = true;
                }
            });
        } else {
            Object.keys(data).map((key) => {
                if (data[key].label === featureLabel) {
                    isAvailable = true;
                }
            });
        }
    });

    return isAvailable;
};
