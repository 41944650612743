import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutTipsSidebarStyled } from '../CheckoutTipsSidebarStyled';
import { rem } from 'polished';

export const CheckoutTipsSidebarStyledOV = styled(CheckoutTipsSidebarStyled)`
    border: ${rem('1px')} solid ${({ theme }) => theme.colors.grey23};

    .title {
        background-color: ${({ theme }) => theme.colors.grey10};
        color: ${({ theme }) => theme.colors.black6};
        font-family: ${({ theme }) => theme.fonts.fontSecondary};
        padding: ${rem('15px')};
        font-size: ${rem(18)} !important;
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    }

    .item {
        margin-bottom: ${rem('25px')};

        p {
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
            color: ${({ theme }) => theme.colors.grey12};
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        &__title {
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.grey12};
        }
    }

    .content {
        padding: ${rem('25px')} ${rem('15px')};
        padding-top: ${rem('30px')};

        ul {
            li {
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
                p {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }

        @media (max-width: ${breakpoints.sm}px) {
            padding: ${rem('15px')} ${rem('20px')};
        }
    }

    @media (min-width: ${breakpoints.sm}px) {
        margin: ${rem('16px')} auto;
    }

    @media (min-width: ${breakpoints.lg}px) {
        margin: 0 ${rem('16px')};
    }
`;
