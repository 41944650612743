import React from 'react';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from '../../constants/main';
import { CustomErrorStyledAC } from './AC/CustomErrorStyled.AC';
import { CustomErrorStyledAP } from './AP/CustomErrorStyled.AP';
import { CustomErrorStyledDS } from './DS/CustomErrorStyled.DS';
import { CustomErrorStyledOV } from './OV/CustomErrorStyled.OV';
import { CustomErrorStyledOV as CustomErrorStyledOV_GB } from './OV/GB/CustomErrorStyled.OV';
import { CustomErrorStyled } from './CustomErrorStyled';
import { SC } from '../../styles/theme';

export interface CustomErrorProps extends SC {
    titleDescription?: string;
    errorType: string;
    errorTitle?: string;
    returnLink?: string;
    returnLinkLabel?: string;
}

const CustomError = (props: CustomErrorProps) => {
    if (isBrandAC) return <CustomErrorStyledAC {...props} />;
    if (isBrandAP) return <CustomErrorStyledAP {...props} />;
    if (isBrandDS) return <CustomErrorStyledDS {...props} />;
    if (isBrandOV) return isMarketGB ? <CustomErrorStyledOV_GB {...props} /> : <CustomErrorStyledOV {...props} />;
    return <CustomErrorStyled {...props} />;
};

export default CustomError;
