import styled from 'styled-components';
import { breakpoints, css, theme } from '../../../styles/theme';
import { LegalTermsAndConditionsConsentStyled } from '../LegalTermsAndConditionsConsentStyled';
import { rem } from 'polished';

export const LegalTermsAndConditionsConsentStyledOV = styled(LegalTermsAndConditionsConsentStyled)`
    .section {
        &_title {
            border: 1px solid ${({ theme }) => theme.colors.grey21};

            span {
                color: ${({ theme }) => theme.colors.black2};
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-size: ${({ theme }) => theme.fontSizes.textBig};
            }

            .isOpen {
                svg {
                    path {
                        stroke-width: 0;
                        fill: ${({ theme }) => theme.colors.black};
                    }
                }
            }

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                    stroke-width: 0;
                }
            }
        }

        &_description {
            color: ${({ theme }) => theme.colors.black2};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            line-height: ${rem(16)};
            border: 1px solid ${({ theme }) => theme.colors.grey21};
            border-top: none;
            margin: 0;
            padding: ${rem(16)};
        }

        &_agreements {
            &_agreement {
                input {
                    accent-color: ${({ theme }) => theme.colors.black2};
                    border-color: ${({ theme }) => theme.colors.black2};
                }

                button {
                    color: ${({ theme }) => theme.colors.black2};
                }
            }
        }
    }

    .legend {
        color: ${({ theme }) => theme.colors.black2};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        line-height: ${rem(16)};
    }
`;
