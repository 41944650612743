import axios from 'axios';
import { GOOGLE_MAP_API_KEY } from '../constants/main';

const MAP_API = 'https://maps.googleapis.com/maps/api/place';

export const addressAutocomplete = async (userInput: string, placeId?: string) => {
    return (
        await axios.get(
            placeId
                ? `${MAP_API}/details/json?place_id=${placeId}&key=${GOOGLE_MAP_API_KEY}`
                : `${MAP_API}/autocomplete/json?input=${userInput}&components=country:${process.env.NEXT_PUBLIC_GOOGLE_RESTRICTED_COUNTRY}&key=${GOOGLE_MAP_API_KEY}`
        )
    ).data;
};