import styled from 'styled-components';
import { ModalFeaturesStyled } from '../ModalFeaturesStyled';
import { rem } from 'polished';

export const ModalFeaturesStyledDS = styled(ModalFeaturesStyled)`
    .title {
        .pack {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .styles,
    .equipments,
    .interiors {
        .configurations {
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .keys {
        border-top: 1px solid ${({ theme }) => theme.colors.secondary};
    }

    .engines,
    .colors,
    .interiors,
    .features {
        .label {
            font-size: ${rem(24)};
        }
    }

    .engines {
        .configurations {
            .configuration {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
          
            .engine {
                line-height: ${rem(22)} !important;
            }
        }
    }

    .categories {
        span {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            border-radius: ${rem(24)};
            color: ${({ theme }) => theme.colors.black1};
            padding: ${rem(15)} ${rem(24)};
            height: ${rem(48)};
            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.grey4};
                border-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.primary};
            }
        }
        span.selected {
            background: ${({ theme }) => theme.colors.grey4};
            color: ${({ theme }) => theme.colors.black1};
            border-color: ${({ theme }) => theme.colors.secondary};
        }
    }

    .features {
        .title {
            font-size: ${rem(24)};
            padding-bottom: 0;
        }

        .configuration {
            .label {
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            .options {
                .option {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }
    }
`;
