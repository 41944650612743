import styled from 'styled-components';
import { ModalNeedHelpStyled } from '../ModalNeedHelpStyled';
import { rem } from 'polished';

export const ModalNeedHelpStyledOV = styled(ModalNeedHelpStyled)`
    .description {
        font-size: ${rem(16)};
        color: ${({ theme }) => theme.colors.black2};
    }

    .phone,
    .request,
    .testDrive,
    .faq {
        .icon {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black2};
                }
            }
        }
    }
`;
