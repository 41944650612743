import { rem } from 'polished';
import styled from 'styled-components';
import InvalidConfigurationModalContentTemplate from './InvalidConfigurationModalContentTemplate';

const TEXT_CLASSNAME = 'invalid-configuration-modal-content-text';

export const InvalidConfigurationModalContentStyled = styled(InvalidConfigurationModalContentTemplate).attrs({
    textClassName: TEXT_CLASSNAME,
})`
    padding: ${rem(30)};

    .${TEXT_CLASSNAME} {
        margin-bottom: ${rem(30)};
        text-align: center;
    }
`;
