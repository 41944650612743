import React from 'react';
import { BRAND, isBrandDS, isBrandOV, isBrandAC } from '../../constants/main';
import { GridStyledAC } from './AC/GridStyled.AC';
import { GridStyledOV } from './OV/GridStyled.OV';
import { GridStyledAP } from './AP/GridStyled.AP';
import { GridStyledDS } from './DS/GridStyled.DS';
import { ColStyledAC } from './AC/ColStyled.AC';
import { ColStyledOV } from './OV/ColStyled.OV';
import { ColStyledAP } from './AP/ColStyled.AP';
import { ColStyledDS } from './DS/ColStyled.DS';
import { RowStyledAC } from './AC/RowStyled.AC';
import { RowStyledOV } from './OV/RowStyled.OV';
import { RowStyledAP } from './AP/RowStyled.AP';
import { RowStyledDS } from './DS/RowStyled.DS';
import { GridStyled } from './GridStyled';
import { ColStyled } from './ColStyled';
import { RowStyled } from './RowStyled';
import { ReactStyledFlexboxgrid } from 'react-styled-flexboxgrid';
import { breakpoints, SC, theme } from '../../styles/theme';
import { rem } from 'polished';
import styled from 'styled-components';

// IF necessary use this interface to add aditional props
interface CustomGridProps {
    noPaddingMobile?: boolean;
    noPadding?: boolean;
    isSummary?: boolean;
    isBasket?: boolean;
    supressMediumBpMaxWidth?: boolean;
    brand?: string;
    hideOnMobile?: boolean;
    withBorder?: boolean;
    isWide?: boolean;
    unsetMaxWidth?: boolean;
}
interface CustomColProps {
    noPaddingMobile?: boolean;
    brand?: string;
    isStockSlice?: boolean;
}

interface CustomRowProps {
    noNegativeMarginMobile?: boolean;
    brand?: string;
    id?: string;
}

export type GridProps = SC & ReactStyledFlexboxgrid.IGridProps & CustomGridProps;
export type ColProps = SC & ReactStyledFlexboxgrid.IColProps & CustomColProps;
export type RowProps = SC & ReactStyledFlexboxgrid.IRowProps & CustomRowProps;

export const Grid = (props: GridProps) => {
    if (BRAND === 'AC') return <GridStyledAC {...props} />;
    if (BRAND === 'OV') return <GridStyledOV {...props} />;
    if (BRAND === 'AP') return <GridStyledAP {...props} />;
    if (BRAND === 'DS') return <GridStyledDS {...props} />;
    return <GridStyled {...props} />;
};

export const Col = (props: ColProps) => {
    if (BRAND === 'AC') return <ColStyledAC {...props} />;
    if (BRAND === 'OV') return <ColStyledOV {...props} />;
    if (BRAND === 'AP') return <ColStyledAP {...props} />;
    if (BRAND === 'DS') return <ColStyledDS {...props} />;
    return <ColStyled {...props} />;
};

export const Row = (props: RowProps) => {
    if (BRAND === 'AC') return <RowStyledAC {...props} />;
    if (BRAND === 'OV') return <RowStyledOV {...props} />;
    if (BRAND === 'AP') return <RowStyledAP {...props} />;
    if (BRAND === 'DS') return <RowStyledDS {...props} />;
    return <RowStyled {...props} />;
};

const getSummaryBackgroundByBrand = () => {
    if (isBrandAC) return theme.colors.grey6;
    if (isBrandOV) return theme.colors.grey10;
    if (isBrandDS) return theme.colors.white;

    return theme.colors.grey8;
};

export const StickyCol = styled(Col)`
    & > div {
        @media (max-width: calc(${breakpoints.sm}px - 0.1px )) {
            min-height: 0 !important;
        }

        @media (min-width: ${breakpoints.sm}px) {
            position: sticky;
            top: 0;
        }
    }
`;

export const SecondCol = styled(Col)`
    padding: 16px;

    @media (max-width: 450px) {
        padding-left: 5px;
        padding-right: 5px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        ${isBrandDS &&
        `
        padding: 1rem 2.5rem !important;
        `}
    }
`;

export const StyledGrid = styled(Grid)`
    max-width: 100%;
    width: 100% !important;
`;

export const ConfigOrderPanelGrid = styled(Grid)`
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 100px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
`;

export const ConfigMainGrid = styled(Grid)`
    width: 100% !important;
    display: block;
    padding: 0 !important;
    margin: 0 auto !important;

    @media (min-width: ${breakpoints.md}px) {
        display: flex;
    }
`;

export const ConfigLeftSection = styled(Grid)`
    width: 100%;
    padding-left: ${rem(5)} !important;
    padding-right: ${rem(5)} !important;

    @media (min-width: ${breakpoints.md}px) {
        width: 70% !important;
    }

    ${({ hideOnMobile }) =>
        hideOnMobile &&
        `
        @media (max-width: ${breakpoints.md}px) {
            display: none;
        }
    `}
`;

export const MobileSection = styled(Grid)`
    width: 100%;
    padding-left: unset !important;
    padding-right: unset !important;

    @media (min-width: ${breakpoints.md}px) {
        display: none;
    }

    .mobileContinueButton {
        position: sticky;
        bottom: ${rem(10)};
        width: 100%;
        text-align: center;
        background: white;

        > div {
            width: 100%;
        }
    }
`;

export const Sticky = styled.div`
    padding: 0 ${rem(20)};
    @media (min-width: ${breakpoints.md}px) {
        position: sticky;
        top: ${rem(54)};
    }
`;

export const ConfigRightSection = styled(Grid)`
    width: 100%;

    @media (min-width: ${breakpoints.md}px) {
        width: 30% !important;
        max-width: 450px !important;
    }

    ${({ withBorder }) =>
        withBorder &&
        `
            border: 1px solid ${theme.colors.grey16};
            border-radius: ${rem(8)};
            margin: ${rem(32)} 0;
    `}

    ${({ hideOnMobile }) =>
        hideOnMobile &&
        `
        @media (max-width: ${breakpoints.md}px) {
            display: none;
        }
    `}

    ${({ isSummary }) =>
        isSummary &&
        `
        @media (min-width: calc(${breakpoints.md}px - 0.1px) ) {
            background: ${getSummaryBackgroundByBrand()};
        }
    `}
    
    ${({ isBasket }) =>
        isBasket &&
        `
        background: ${getSummaryBackgroundByBrand()};
        @media (max-width: calc(${breakpoints.md}px - 0.1px) ) {
            padding: 0 !important;
            margin: 0 auto;
        }
    `}
    
    .sticky {
        position: sticky;
        top: ${rem(50)};
    }
`;
