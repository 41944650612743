export type TBudgetType = null | 'finance' | 'cash';

export interface IOperatorTypes {
    IN: string;
    EQUALS: string;
    BETWEEN: string;
    NOTIN: string;
    IS_EMPTY: string;
}

export interface IAddFilter {
    name: string;
    operation: string;
    value: any;
    nesting: string[];
    parent: string | null;
}

export enum GTMFiltersActions {
    FILTER_PRICE = 'Filter::Price',
    FILTER_GEARBOX = 'Filter::Gearbox',
    FILTER_ENERGY = 'Filter::Energy',
    FILTER_PRICE_VIEW = 'Filter::PriceView',
}
