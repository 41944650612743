import styled from 'styled-components';
import { breakpoints, css } from '../../../styles/theme';
import { IconButtonStyled } from '../IconButtonStyled';
import { rem } from 'polished';

const hoverAndActive = css`
    h2 {
        color: ${({ theme }) => theme.colors.black};
    }
    svg {
        path {
            stroke: transparent;
            fill: ${({ theme }) => theme.colors.black};
        }
    }
`;

export const IconButtonStyledOV = styled(IconButtonStyled)`
    h2 {
        font-size: ${rem('16px')};
        color: ${({ theme }) => theme.colors.default};
        font-weight: ${({ theme }) => theme.fontWeights.textLight};
    }
    :hover {
        ${hoverAndActive};
    }

    svg {
        rect {
            fill: ${({ theme }) => theme.colors.primary};
        }
        path {
            fill: ${({ theme }) => theme.colors.grey22};
        }
    }
    ${({ active }) => active && hoverAndActive};
    .horizontal {
        border: ${rem(1)} solid ${({ theme }) => theme.colors.grey13};
        margin: 0 ${rem(10)};
        h2 {
            text-transform: none;
        }
        @media (min-width: ${breakpoints.xs}px) {
            margin: 0 ${rem(20)};
        }
        @media (max-width: ${breakpoints.xs}px) {
            border: none;

            .icon-wrapper {
                height: 32px;

                svg {
                    height: 32px;
                    width: 32px;
                }
            }
        }
        h2 {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            line-height: ${({ theme }) => theme.lineHeights.textMedium};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .horizontal .icon-wrapper path {
        stroke: ${({ theme }) => theme.colors.black};
    }

    .horizontal:hover .icon-wrapper path {
        stroke: ${({ theme }) => theme.colors.black};
    }
`;
