import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalLogoutStyledAC } from './AC/ModalLogoutStyled.AC';
import { ModalLogoutStyledAP } from './AP/ModalLogoutStyled.AP';
import { ModalLogoutStyledDS } from './DS/ModalLogoutStyled.DS';
import { ModalLogoutStyledOV } from './OV/ModalLogoutStyled.OV';
import { ModalLogoutStyledOV as ModalLogoutStyledOVGB } from './OV/GB/ModalLogoutStyled.OV';

export interface IModalSessionChange extends SC {}

export const ModalLogout = (props: IModalSessionChange) => {
    if (BRAND === 'AP') return <ModalLogoutStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalLogoutStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalLogoutStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalLogoutStyledOVGB {...props} /> : <ModalLogoutStyledOV {...props} />;
};
