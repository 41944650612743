import styled from 'styled-components';
import { rem } from 'polished';
import { ModalRemovePxConfirmationStyled } from '../ModalRemovePxConfirmationStyled';

export const ModalRemovePxConfirmationStyledAC = styled(ModalRemovePxConfirmationStyled)`
    .description {
        font-size: ${({ theme }) => theme.fontSizes.h3};
        line-height: ${rem(26)};
    }
`;
