import React, { useCallback } from 'react';
import useCustomRouter from '@hooks/useCustomRouter';
import styled from 'styled-components';
import useTranslations from '@hooks/useTranslations';

interface IModalStockVehicleUnavalaible {
    modelName: string;
    modelUrl: string;
    onClose: () => void;
}

const StockVehicleUnavailableModal = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    .modalRow {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .modalLabel {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .modalButton {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        cursor: pointer;
        padding-top: 10px;
        text-decoration: underline;
    }

    .modalTitle {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        text-transform: uppercase;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }
`;

const ModalStockVehicleUnavalaible: React.SFC<IModalStockVehicleUnavalaible> = ({ modelName, modelUrl, onClose }) => {
    const router = useCustomRouter();
    const { t } = useTranslations();

    const handleRedirectToModel = useCallback(() => {
        router.push(modelUrl);
        onClose();
    }, [router, modelUrl]);

    return (
        <StockVehicleUnavailableModal>
            <div className="modalRow">
                <span className="modalLabel">{t('stockVehicleUnavailable.label')}</span>
                <span className="modalButton" onClick={handleRedirectToModel}>
                    {t('stockVehicleUnavailable.button', { model: modelName })}
                </span>
            </div>
        </StockVehicleUnavailableModal>
    );
};

export default ModalStockVehicleUnavalaible;
