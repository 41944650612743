import styled from 'styled-components';
import { LinkStyled } from '../LinkStyled';
import { css } from '../../../styles/theme';
import { rem } from 'polished';

export const LinkStyledAC = styled(LinkStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h4};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};

    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.red} !important;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.red};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.grey5} !important;

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.grey5};
                    }
                }
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            color: ${({ theme }) => theme.colors.blue2};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.blue2};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.grey5};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.grey5};
                    }
                }
            }
        `}

    svg {
        width: ${rem(11)};
        height: ${rem(11)};
    }
`;
