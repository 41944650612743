import { CheckboxStyled } from '../CheckboxStyled';
import styled from 'styled-components';
import { rem } from 'polished';

export const CheckboxStyledAC = styled(CheckboxStyled)`
    .container input:focus + label::before {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blue2};
    }

    .container input + label::before {
        border-color: ${({ theme }) => theme.colors.grey2};
        border-radius: ${rem(4)};
    }

    .container input:checked + label::before {
        background: ${({ theme }) => theme.colors.blue2};
        border-color: ${({ theme }) => theme.colors.blue2};
    }
`;
