import React, { FC, useState, useEffect, useCallback, memo } from 'react';
import { CheckoutStepWizardProps } from '.';

import CheckoutStep from './CheckoutStep/CheckoutStep';

import useTranslations from '@hooks/useTranslations';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { MySavedDealJourneyType } from 'src/redux/user/user.duck.interface';
import { skipPaymentDeposit } from '@utils/Deal.utils';
import { useJourneyType } from '@hooks/useJourneyType';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';

enum CHECKOUT_STATES {
    DELIVERY = 'delivery',
    MY_DETAILS = 'my-details',
    ORDER_REVIEW = 'order-review',
    CONFIRMATION = 'confirmation',
    PRE_ORDER = 'pre-order',
}

interface ICheckoutSteps {
    name: string;
    active: boolean;
    completed: boolean;
    stepNumber: number;
    subSteps: Array<{}>;
    subStepNumber: number;
}

export const Navigation: FC<CheckoutStepWizardProps> = memo(({ className, stock, actualState }) => {
    const { t } = useTranslations();
    const [modifiedActualState, setModifiedActualState] = useState(actualState);
    const [steps, setSteps] = useState([]);
    const { isFinance } = useJourneyType();

    const { currentDeal } = useCarDetailsDuck();

    const isDealerChoiceEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED);

    const hidePaymentDepositSection = !isFinance && skipPaymentDeposit(currentDeal);
    const dealerPreselected: boolean =
        stock || !!currentDeal?.fullProductConfiguration?.dealers?.length || isDealerChoiceEnabled;

    const paymentStatus = '';

    const generateSteps = useCallback((): Array<ICheckoutSteps> => {
        const type: number = dealerPreselected ? 1 : 0;
        const maxNumberOfStates: number = hidePaymentDepositSection ? 2 : 3;
        const generatedArray: Array<ICheckoutSteps> = [];
        for (let i = type; i < maxNumberOfStates; i++) {
            generatedArray.push({
                name: Object.values(CHECKOUT_STATES)[i],
                active: modifiedActualState === Object.values(CHECKOUT_STATES)[i],
                completed: i <= Object.values(CHECKOUT_STATES).indexOf(modifiedActualState as CHECKOUT_STATES),
                stepNumber: type === 1 ? i : i + 1,
                subSteps:
                    Object.values(CHECKOUT_STATES)[i] === CHECKOUT_STATES.MY_DETAILS
                        ? [{ name: CHECKOUT_STATES.PRE_ORDER }, { name: 'checkoutDeclined' }]
                        : Object.values(CHECKOUT_STATES)[i] === CHECKOUT_STATES.ORDER_REVIEW
                        ? [
                              {
                                  name:
                                      actualState === CHECKOUT_STATES.CONFIRMATION ? CHECKOUT_STATES.CONFIRMATION : '',
                                  subStepNumber: 1,
                              },
                          ]
                        : [],
                subStepNumber:
                    Object.values(CHECKOUT_STATES)[i] === CHECKOUT_STATES.MY_DETAILS
                        ? 2
                        : Object.values(CHECKOUT_STATES)[i] === CHECKOUT_STATES.ORDER_REVIEW
                        ? 1
                        : null,
            });
        }
        return generatedArray;
    }, [actualState, modifiedActualState]);

    useEffect(() => {
        setSteps(generateSteps());
    }, [modifiedActualState]);

    useEffect(() => {
        if (actualState === CHECKOUT_STATES.PRE_ORDER) {
            setModifiedActualState(CHECKOUT_STATES.MY_DETAILS);
        } else if (actualState === CHECKOUT_STATES.CONFIRMATION) {
            setModifiedActualState(CHECKOUT_STATES.ORDER_REVIEW);
        } else {
            setModifiedActualState(actualState);
        }
    }, [actualState]);

    const checkoutType = currentDeal?.journeyType === MySavedDealJourneyType.BUY_ONLINE_FINANCE ? 'finance' : 'cash';

    const getCurrentStepNumber = () => {
        if (actualState === CHECKOUT_STATES.DELIVERY) return 1;
        if (actualState === CHECKOUT_STATES.MY_DETAILS) return dealerPreselected ? 1 : 2;
        if (actualState === CHECKOUT_STATES.ORDER_REVIEW) return dealerPreselected ? 2 : 3;
        if (actualState === CHECKOUT_STATES.CONFIRMATION) return dealerPreselected || hidePaymentDepositSection ? 3 : 4;
    };

    const getCurrentStepClass = () => {
        if (dealerPreselected) {
            return getCurrentStepNumber() === 1
                ? 'isFirstStepFromThree'
                : getCurrentStepNumber() === 2
                ? 'isSecondStepFromThree'
                : getCurrentStepNumber() === 3
                ? 'isLastStep'
                : '';
        }

        return getCurrentStepNumber() === 1
            ? 'isFirstStepFromFour'
            : getCurrentStepNumber() === 2
            ? 'isSecondStepFromFour'
            : getCurrentStepNumber() === 3
            ? 'isThirdStepFromFour'
            : 'isLastStep';
    };

    return (
        <div className={className}>
            <div className="navigationWrapper">
                <div className="container navContainer">
                    <div className="headerWrapper">
                        <span>{t('checkout.navigation.header')}</span>
                    </div>
                    <div className={`progressWrapper ${getCurrentStepClass()}`}>
                        {steps.length !== 0 &&
                            steps.map((step, key: number) => (
                                <CheckoutStep
                                    key={key}
                                    stepAlias={step.name}
                                    completed={step.completed || false}
                                    active={step.active || false}
                                    stepNumber={step.stepNumber}
                                    substep={step.subSteps[0]?.name}
                                    paymentStatus={paymentStatus}
                                    dealerPreselected={dealerPreselected}
                                    hidePaymentDepositSection={hidePaymentDepositSection}
                                />
                            ))}
                        <div
                            className={`${
                                dealerPreselected || hidePaymentDepositSection
                                    ? 'isDealerPreSelected'
                                    : 'isNotDealerPreSelected'
                            } ${
                                steps.length !== 0 &&
                                (steps[steps.length - 1].subSteps[0]?.name === 'confirmation' ||
                                    (actualState === 'confirmation' && hidePaymentDepositSection))
                                    ? 'progressBlock progressBlockActive progressBlockCompleted'
                                    : 'progressBlock'
                            }`}
                        >
                            <div className="progressTimer">{steps.length + 1}.</div>
                            <div className="progressTitle">{t(`checkout.navigation.${checkoutType}.finished`)}</div>
                            <div className="progressIcon last" />
                            <div className="progressStep">
                                <span>
                                    {t('checkout.navigation.step.name')}
                                    {getCurrentStepNumber()}/
                                    {isDealerChoiceEnabled || hidePaymentDepositSection ? 3 : 4}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
Navigation.displayName = 'Navigation';

export default Navigation;
