import { TranslateFunction } from '@hooks/useTranslations';

const config: any = {
    COOKIE_NAME: '',
};

export default config;

export const getCustomerTitlesTranslated = (t: TranslateFunction): Array<{ value: number; translation: string }> => [
    { value: 1, translation: t('CheckoutUserDetailsForm.customerTitles.mr') },
    { value: 2, translation: t('CheckoutUserDetailsForm.customerTitles.mrs') },
    { value: 3, translation: t('CheckoutUserDetailsForm.customerTitles.ms') },
];

export const getCustomerTitlesTranslatedUK = (t: TranslateFunction): Array<{ value: number; translation: string }> => [
    { value: 1, translation: t('CheckoutUserDetailsForm.customerTitles.mr') },
    { value: 2, translation: t('CheckoutUserDetailsForm.customerTitles.mrs') },
    { value: 3, translation: t('CheckoutUserDetailsForm.customerTitles.ms') },
    { value: 4, translation: t('CheckoutUserDetailsForm.customerTitles.miss') },
];
