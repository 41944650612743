import styled from 'styled-components';
import { InputStyled } from '../../InputStyled';
import { rem } from 'polished';

export const InputStyledOV = styled(InputStyled)`
    span {
        font-size: ${rem(16)};
    }

    input {
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colors.grey16};
        font-size: ${({ theme }) => theme.fontSizes.h5};
    }

    .currency {
        background: ${({ theme }) => theme.colors.grey16};
    }

    .inputToolTip {
        svg {
            g {
                circle {
                    stroke: ${({ theme }) => theme.colors.black2} !important;
                    fill: ${({ theme }) => theme.colors.black2};
                }
                line {
                    stroke: ${({ theme }) => theme.colors.white} !important;
                }
            }
        }
    }
`;
