import { rem } from 'polished';
import styled from 'styled-components';
import ModalCarOptionDetailTemplate from './ModalCarOptionDetailTemplate';
import { breakpoints } from '../../../styles/theme';

export const ModalCarOptionDetailStyled = styled(ModalCarOptionDetailTemplate)`
    width: 98%;
    margin-top: ${rem(30)};

    .option-detail {
        &__category {
            padding-bottom: ${rem(5)};
            display: flex;
            align-items: center;
        }

        &__backBtn {
            cursor: pointer;
            margin-right: ${rem(8)};
        }

        &__title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            margin: ${rem(8)} 0 0 0;
            padding-bottom: ${rem(15)};
        }

        &__image {
            width: 100%;
            padding-bottom: ${rem(15)};
        }

        &__closeBtn {
            margin: 0;
            padding: 0.1rem 0.3rem;
            cursor: pointer;
            font-size: ${rem(20)};
            background: none;
            border: none;
            position: absolute;
            top: ${rem(25)};
            right: ${rem(30)};

            @media (min-width: ${breakpoints.sm}px) {
                right: ${rem(30)};
            }
        }
    }

    .tires-detail {
        padding: ${rem(24)} ${rem(20)};
        margin-top: ${rem(30)};

        &__title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            display: block;
            padding-bottom: ${rem(10)};
        }

        &__description {
            padding-bottom: ${rem(29)};
            margin: 0;
            border-bottom: 1px solid;
        }

        &__image {
            padding: ${rem(24)} 0;
            border-bottom: 1px solid;

            img {
                max-width: 100%;
            }

            @media (min-width: ${breakpoints.sm}px) {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                gap: ${rem(25)};

                img {
                    max-width: 50%;
                }
            }
        }

        &__link {
            background: none;
            padding: ${rem(28)} 0 0 0;
            cursor: pointer;
            border: none;
            display: flex;
            text-decoration: unset;
        }
    }

    ul {
        padding: 0 ${rem(20)};
    }
`;
