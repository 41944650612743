import styled from 'styled-components';
import { ModalCarOptionDetailStyled } from '../ModalCarOptionDetailStyled';
import { rem } from 'polished';

export const ModalCarOptionDetailStyledAP = styled(ModalCarOptionDetailStyled)`
    font-size: ${({ theme }) => theme.fontSizes.textBase};

    .option-detail {
        &__category {
            color: ${({ theme }) => theme.colors.primaryBlack};
        }

        &__title {
            font-size: ${rem(25)};
            color: ${({ theme }) => theme.colors.primaryBlack};
            line-height: ${rem(30)};
        }

        &__description {
            color: ${({ theme }) => theme.colors.primaryBlack};
            line-height: ${rem(18)};
        }
    }

    .tires-detail {
        background: ${({ theme }) => theme.colors.grey7};

        &__description {
            border-color: ${({ theme }) => theme.colors.grey12};
        }

        &__image {
            border-color: ${({ theme }) => theme.colors.grey12};
        }

        &__link {
            color: ${({ theme }) => theme.colors.primary};

            svg {
                width: ${rem(8)};
                height: ${rem(8)};
                margin-left: ${rem(5)};

                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};
                text-decoration: underline;
            }
        }
    }
`;
