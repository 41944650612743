import styled from 'styled-components';
import { ModalDealerSearchStyled } from '../ModalDealerSearchStyled';
import { rem } from 'polished';

export const ModalDealerSearchStyledDS = styled(ModalDealerSearchStyled)`    
    .dealerSearch {
        &__input {
            &::before {
                background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjJwdCIgaGVpZ2h0PSIyMnB0IiB2aWV3Qm94PSIwIDAgMjIgMjIiIHZlcnNpb249IjEuMSI+CjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoNjEuNTY4NjI3JSw1NC45MDE5NjElLDUxLjM3MjU0OSUpO2ZpbGwtb3BhY2l0eToxOyIgZD0iTSAyMS44NTU0NjkgMjEuMTY0MDYyIEwgMTQuNDI5Njg4IDEzLjczNDM3NSBDIDE1LjcyNjU2MiAxMi4yNzczNDQgMTYuNTE5NTMxIDEwLjM2MzI4MSAxNi41MTk1MzEgOC4yNjE3MTkgQyAxNi41MTk1MzEgMy43MDcwMzEgMTIuODE2NDA2IDAgOC4yNjE3MTkgMCBDIDMuNzA3MDMxIDAgMCAzLjcwNzAzMSAwIDguMjYxNzE5IEMgMCAxMi44MTY0MDYgMy43MDcwMzEgMTYuNTE5NTMxIDguMjYxNzE5IDE2LjUxOTUzMSBDIDEwLjM1OTM3NSAxNi41MTk1MzEgMTIuMjczNDM4IDE1LjcyNjU2MiAxMy43MzQzNzUgMTQuNDI5Njg4IEwgMjEuMTYwMTU2IDIxLjg1NTQ2OSBDIDIxLjI1NzgxMiAyMS45NTMxMjUgMjEuMzgyODEyIDIyIDIxLjUwNzgxMiAyMiBDIDIxLjYzMjgxMiAyMiAyMS43NjE3MTkgMjEuOTUzMTI1IDIxLjg1NTQ2OSAyMS44NTU0NjkgQyAyMi4wNDY4NzUgMjEuNjY0MDYyIDIyLjA0Njg3NSAyMS4zNTU0NjkgMjEuODU1NDY5IDIxLjE2NDA2MiBaIE0gOC4yNjE3MTkgMTUuNTM5MDYyIEMgNC4yNDYwOTQgMTUuNTM5MDYyIDAuOTgwNDY5IDEyLjI3MzQzOCAwLjk4MDQ2OSA4LjI2MTcxOSBDIDAuOTgwNDY5IDQuMjQ2MDk0IDQuMjQ2MDk0IDAuOTgwNDY5IDguMjYxNzE5IDAuOTgwNDY5IEMgMTIuMjczNDM4IDAuOTgwNDY5IDE1LjUzOTA2MiA0LjI0NjA5NCAxNS41MzkwNjIgOC4yNjE3MTkgQyAxNS41MzkwNjIgMTAuMDg5ODQ0IDE0Ljg1NTQ2OSAxMS43NjE3MTkgMTMuNzM0Mzc1IDEzLjA0Mjk2OSBDIDEzLjUxOTUzMSAxMy4yODkwNjIgMTMuMjg5MDYyIDEzLjUxOTUzMSAxMy4wNDI5NjkgMTMuNzM0Mzc1IEMgMTEuNzYxNzE5IDE0Ljg1NTQ2OSAxMC4wODk4NDQgMTUuNTM5MDYyIDguMjYxNzE5IDE1LjUzOTA2MiBaIE0gOC4yNjE3MTkgMTUuNTM5MDYyICIvPgo8L3N2Zz4=')
                    no-repeat;
                background-size: contain;
            }
          
            input {
                border: 1px solid ${({ theme }) => theme.colors.grey5};
                border-radius: ${rem(3)};
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }
        }
        
        button {
            height: 100%;
        }
    }
    
    .dealerList {
        .dealerItem {
            &__icon {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxNCAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMEMzLjEzMzU5IDAgMCAzLjEyNDQgMCA2Ljk3OTQ3QzAgMTEuNzM0MiA1LjM2NDg0IDEzLjQ2ODIgNi41MzI0MiAyMC44MzQ4QzYuNjA4OTggMjEuMzA5MiA2LjYxNDQ1IDIxLjYzNjQgNyAyMS42MzY0QzcuMzg1NTUgMjEuNjM2NCA3LjM5MTAyIDIxLjMwOTIgNy40Njc1OCAyMC44MzQ4QzguNjM1MTYgMTMuNDY4MiAxNCAxMS43MzQyIDE0IDYuOTc5NDdDMTQgMy4xMjQ0IDEwLjg2NjQgMCA3IDBaIiBmaWxsPSIjOUQ4QzgzIi8+Cjwvc3ZnPg==')
                top center no-repeat;
            }
          
            &__footer {
                button {
                    background: ${({ theme }) => theme.colors.white};
                    color: ${({ theme }) => theme.colors.black1};
                    border-radius: ${rem(24)};
                    text-transform: unset;
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    width: auto;
                    padding: ${rem(15)} ${rem(24)};
                    height: ${rem(48)};
                    font-size: ${({ theme }) => theme.fontSizes.h5};
                  
                    &:hover,
                    &:focus-visible {
                        color: ${({ theme }) => theme.colors.primary};
                        border-color: ${({ theme }) => theme.colors.primary};
                        background: ${({ theme }) => theme.colors.grey4};
                    }
                }
            }
        }
    }        
`;
