import styled from 'styled-components';
import { LinkTemplate } from './LinkTemplate';
import { rem } from 'polished';
import { css } from '../../styles/theme';

export const LinkStyled = styled(LinkTemplate)`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${rem(4)};
    text-decoration: unset;

    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: ${rem(marginTop)};
        `}

    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${rem(marginBottom)};
        `}
`;
