import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalSessionChange } from './index';
import Button from '@components/Button';
import Spinner from '@components/Spinner';
import { getOfferCarTitle } from '@components/OfferListItem/utils/getOfferCartitle';
import { useExteriorPreviewImage } from '@utils/Image.utils';
import { useDispatch } from 'react-redux';
import UiDuck from '../../../redux/commonDucks/ui.duck';
import { isBrandAC } from '../../../constants/main';
import { ICarDetail } from '../../../redux/carDetails/carDetails.duck.interface';
import routes from '../../../constants/routes';
import { useRouter } from 'next/router';
import { getRedirectDestinationOnActivateDeal } from '@utils/Deal.utils';
import { useFeatureSwitch } from '@hooks/useFeatureSwitch';
import { PaymentStatuses } from '../../../constants/paymentStatuses';

export enum ModalSessionChangeVersion {
    CONTINUE_WITH_NEW_OR_HOMEPAGE = 'CONTINUE_WITH_NEW_OR_HOMEPAGE',
    CONTINUE_WITH_NEW_OR_PREVIOUS = 'CONTINUE_WITH_NEW_OR_PREVIOUS',
}

export const ModalSessionChangeTemplate = ({
    className,
    continueWithNewConfiguration,
    changeWithPreviousConfiguration,
    newConfiguration,
    previousConfiguration,
    version,
}: IModalSessionChange) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const router = useRouter();

    const { isStockSummaryEnabled, isMTOSummaryEnabled } = useFeatureSwitch();

    const [isLoading, setIsLoading] = useState(false);

    const newConfigurationImage = useExteriorPreviewImage(newConfiguration?.fullProductConfiguration?.images, 'list');

    const previousConfigurationImage = useExteriorPreviewImage(
        previousConfiguration?.fullProductConfiguration?.images,
        'list'
    );

    const getFuel = (carDetails: ICarDetail) => carDetails?.fuel;
    const getGearbox = (carDetails: ICarDetail) =>
        carDetails?.gearbox?.specs.find(({ key }: any) => key === 'type')?.label;
    const getPower = (carDetails: ICarDetail) =>
        carDetails?.characteristics?.find(({ key }: any) => key === 'maximumPowerKW')?.value;

    const hasCurrentDealInOrderProceedStatus =
        newConfiguration?.status === PaymentStatuses.redirected_to_loan_provider ||
        newConfiguration?.status === PaymentStatuses.financeApproved ||
        newConfiguration?.status === PaymentStatuses.financeRejected;

    return (
        <div className={className}>
            {version === ModalSessionChangeVersion.CONTINUE_WITH_NEW_OR_PREVIOUS && (
                <div className="buttons">
                    {isLoading && <Spinner size={36} border={2} color="black" />}
                    {!isLoading && (
                        <div className="section">
                            <p className="description">
                                {t('modal.sessionChange.continueWithNewOrPrevious.description')}
                            </p>
                            <Button
                                onClick={() => {
                                    setIsLoading(true);
                                    continueWithNewConfiguration();
                                    dispatch(UiDuck.closeModal());
                                }}
                                primary
                                fitContentWidth
                            >
                                {t('modal.sessionChange.button.new')}
                            </Button>
                        </div>
                    )}
                    {!isLoading && <div className="border" />}
                    {!isLoading && (
                        <div className="section">
                            <span className="title">{t('modal.sessionChange.continueWithNewOrPrevious.title')}</span>
                            <div className="vehicle">
                                <img className="image" src={previousConfigurationImage} />
                                <div>
                                    <span className="name">
                                        {getOfferCarTitle(previousConfiguration.fullProductConfiguration)}
                                    </span>
                                    <span className="spec">
                                        {previousConfiguration.fullProductConfiguration.specPack.title} |{' '}
                                        {getFuel(previousConfiguration?.fullProductConfiguration)} -{' '}
                                        {getGearbox(previousConfiguration?.fullProductConfiguration)}
                                        {getPower(previousConfiguration?.fullProductConfiguration)
                                            ? `- ${getPower(previousConfiguration?.fullProductConfiguration)} ${t(
                                                  'configurator.info.power.label'
                                              )}`
                                            : ''}
                                    </span>
                                </div>
                            </div>
                            <Button
                                onClick={() => {
                                    setIsLoading(true);
                                    changeWithPreviousConfiguration();
                                    dispatch(UiDuck.closeModal());
                                    setIsLoading(false);
                                }}
                                primary={isBrandAC}
                                withoutBackground={isBrandAC}
                                secondary={!isBrandAC}
                                fitContentWidth
                            >
                                {t('modal.sessionChange.button.previous')}
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {version === ModalSessionChangeVersion.CONTINUE_WITH_NEW_OR_HOMEPAGE && (
                <div className="buttons">
                    {isLoading && <Spinner size={36} border={2} color="black" />}
                    {!isLoading && (
                        <div className="section">
                            <span className="description">
                                {hasCurrentDealInOrderProceedStatus
                                    ? t('modal.sessionChange.continueWithNewOrHome.description.checkout')
                                    : t('modal.sessionChange.continueWithNewOrHome.description')}
                            </span>
                            <div className="vehicle">
                                <img className="image" src={newConfigurationImage} />
                                <div>
                                    <span className="name">
                                        {getOfferCarTitle(newConfiguration.fullProductConfiguration)}
                                    </span>
                                    <span className="spec">
                                        {newConfiguration.fullProductConfiguration.specPack.title} |{' '}
                                        {getFuel(newConfiguration?.fullProductConfiguration)} -{' '}
                                        {getGearbox(newConfiguration?.fullProductConfiguration)}
                                        {getPower(newConfiguration?.fullProductConfiguration)
                                            ? `- ${getPower(newConfiguration?.fullProductConfiguration)} ${t(
                                                  'configurator.info.power.label'
                                              )}`
                                            : ''}
                                    </span>
                                </div>
                            </div>
                            <Button
                                onClick={() => {
                                    setIsLoading(true);
                                    if (hasCurrentDealInOrderProceedStatus) {
                                        const isSummaryEnabled = newConfiguration.fullProductConfiguration.stock
                                            ? isStockSummaryEnabled
                                            : isMTOSummaryEnabled;
                                        const redirectRoute = getRedirectDestinationOnActivateDeal(
                                            newConfiguration,
                                            isSummaryEnabled
                                        );
                                        if (redirectRoute) router.push(redirectRoute);
                                    } else {
                                        continueWithNewConfiguration();
                                    }
                                    dispatch(UiDuck.closeModal());
                                    setIsLoading(false);
                                }}
                                primary
                                fitContentWidth
                            >
                                {hasCurrentDealInOrderProceedStatus
                                    ? t('modal.sessionChange.button.new.checkout')
                                    : t('modal.sessionChange.button.new')}
                            </Button>
                        </div>
                    )}
                    <div className="border" />
                    {!isLoading && (
                        <div className="section">
                            <p className="description">{t('modal.sessionChange.continueWithNewOrHome.title')}</p>
                            <Button
                                onClick={() => {
                                    setIsLoading(true);
                                    window.location.href = `${routes.ROOT}`;
                                }}
                                primary={isBrandAC}
                                withoutBackground={isBrandAC}
                                secondary={!isBrandAC}
                                fitContentWidth
                            >
                                {t('modal.sessionChange.button.home')}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
