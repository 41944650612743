import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FooterStyled } from '../FooterStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FooterStyledDS = styled(FooterStyled)`
    .orderNowSection {
        background: ${({ theme }) => theme.colors.white};

        .orderNowTitle {
            color: ${({ theme }) => theme.colors.black};
            font-size: ${rem(24)};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};

            @media (min-width: ${breakpoints.sm}px) {
                font-size: ${rem(32)};
            }
        }
        .itemsWrapper {
            @media (max-width: ${breakpoints.sm}px) {
                margin-left: 20px;
            }
            & > .footerItem {
                &:last-child .textWrap {
                    width: ${rem(154)};
                }
                &:nth-child(2) .textWrap {
                    width: ${rem(175)};
                }
                & > .textWrap {
                    display: inline-block;
                    color: ${({ theme }) => theme.colors.black};
                    line-height: ${rem(22)};
                    font-size: ${({ theme }) => theme.fontSizes.h5};
                    align-items: flex-start;
                    padding-top: ${rem(16)};
                    white-space: pre-line;
                }
            }
        }
    }
    .scrollToTopIcon {
        width: ${rem(64)};
        height: ${rem(64)};
        top: ${rem(-30)};
        background: ${({ theme }) => theme.colors.grey4};

        svg {
            color: ${({ theme }) => theme.colors.black1};
        }
    }

    .footerScrollToTopContainer {
        background: ${({ theme }) => theme.colors.white};
    }

    .footerScrollToTopAnchor {
        svg {
            height: 0.6375rem;
            width: 0.85rem;
            path {
                stroke: ${({ theme }) => theme.colors.accordionAnswer};
            }
        }
    }

    .footer {
        background-color: ${({ theme }) => theme.colors.headerBackground};
        padding: ${rem(21)} 0;

        .logo {
            display: block;
            padding-bottom: ${rem(20)};

            svg {
                margin-top: -${rem(25)};
                width: ${rem(145)};
                height: ${rem(145)};
            }
        }

        ul {
            li.divider {
                display: none;
            }

            li {
                padding: 0.3rem;

                ${media.sm`
                    padding: 0 1rem 0 0;
                `}
            }
        }
    }
    .itemsWrapper .footerItem .imageWrap svg path {
        fill: ${({ theme }) => theme.colors.secondary};
    }
    .footer ul li a {
        font-size: ${rem(14)};
        line-height: ${rem(15)};
        color: ${({ theme }) => theme.colors.white};

        &:hover {
            text-decoration: underline;
        }
    }
`;
