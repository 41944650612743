import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { ModalStyled } from '../../ModalStyled';
import { rem } from 'polished';

export const ModalStyledOV = styled(ModalStyled)`
    .modalSidebar {
        &__wrap {

            &__header {
                font-size: ${rem(26)};

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(30)};
                }
            }
        }
    }
    .modalFinancialSummary {
        &--title {
            font-size: ${rem(20)};
        }
    }
    .financialSummary__legalText {
        color: ${({ theme }) => theme.colors.black};
    }
    .option-detail {
        &__category {
            color: ${({ theme }) => theme.colors.black1};
        }

        &__title {
            color: ${({ theme }) => theme.colors.black1};
        }

        &__description {
            color: ${({ theme }) => theme.colors.black1};
        }
    }
`;
