import React from 'react';
import { BRAND } from '../../constants/main';
import { LanguageSwitcherStyledAC } from './AC/LanguageSwitcherStyled.AC';
import { LanguageSwitcherStyledAP } from './AP/LanguageSwitcherStyled.AP';
import { LanguageSwitcherStyledDS } from './DS/LanguageSwitcherStyled.DS';
import { LanguageSwitcherStyledOV } from './OV/LanguageSwitcherStyled.OV';
import { LanguageSwitcherStyled } from './LanguageSwitcherStyled';
import { SC } from '../../styles/theme';

export interface LanguageSwitcherTemplateProps extends SC {}

const LanguageSwitcher = (props: LanguageSwitcherTemplateProps) => {
    if (BRAND === 'AC') return <LanguageSwitcherStyledAC {...props} />;
    if (BRAND === 'AP') return <LanguageSwitcherStyledAP {...props} />;
    if (BRAND === 'DS') return <LanguageSwitcherStyledDS {...props} />;
    if (BRAND === 'OV') return <LanguageSwitcherStyledOV {...props} />;
    return <LanguageSwitcherStyled {...props} />;
};

export default LanguageSwitcher;
