import { useSelector } from 'react-redux';
import FinanceWidgetDuck from '../redux/financeWidget/financeWidget.duck';

export const useFinanceWidgetDuck = () => {
    const financeQuote = useSelector((state) => FinanceWidgetDuck.getOwnState(state).financeQuote);
    const financeWidgetOutput = useSelector((state) => FinanceWidgetDuck.getOwnState(state).financeWidgetOutput);
    const isPricesListLoading = useSelector((state) => FinanceWidgetDuck.getOwnState(state).isPricesListLoading);
    const financeWidgetParameters = useSelector(
        (state) => FinanceWidgetDuck.getOwnState(state).financeWidgetParameters
    );
    const globalTexts = useSelector((state) => FinanceWidgetDuck.getFinanceWidgetGlobalTexts(state));
    const pricesList = useSelector((state) => FinanceWidgetDuck.getFinancePricesList(state));

    const leasysQuote = useSelector((state) => FinanceWidgetDuck.getOwnState(state).leasysQuote);
    const defaultLeasysQuote = useSelector((state) => FinanceWidgetDuck.getOwnState(state).defaultLeasysQuote);

    return { financeQuote, financeWidgetOutput, isPricesListLoading, financeWidgetParameters, leasysQuote, defaultLeasysQuote, globalTexts, pricesList };
};
