import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalTrimStyled } from '../ModalTrimStyled';
import { rem } from 'polished';

export const ModalTrimStyledOV = styled(ModalTrimStyled)`
    .sticky {
        background: ${({ theme }) => theme.colors.pureWhite};

        .trim {
            background: ${({ theme }) => theme.colors.pureWhite};
        }
    }

    .stickyTrimAvailability {
        background: ${({ theme }) => theme.colors.pureWhite};
    }

    .stickyTrimName {
        background: ${({ theme }) => theme.colors.pureWhite};
    }

    .stickyTrimPrice {
        background: ${({ theme }) => theme.colors.pureWhite};
    }

    .stickyTrimButton {
        background: ${({ theme }) => theme.colors.pureWhite};
    }

    .keyFeatures,
    .enginesAvailable,
    .stylesAvailable,
    .colors,
    .interiorsAvailable,
    .featuresAvailable {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }

        .sections {
            .features {
                .feature {
                    .name {
                        svg {
                            width: ${rem(18)};
                            height: ${rem(18)};
                            margin-top: ${rem(-2)};
                          
                            path {
                                fill: ${({ theme }) => theme.colors.black2};
                            }
                        }
                    }
                }
            }
        }
    }

    .colors {
        .configurations {
            button {
                &.selected,
                &:hover {
                    box-shadow: 0px 0px 0px 0.1rem ${({ theme }) => theme.colors.black};
                }
            }
        }
    }

    .availability {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        background: ${({ theme }) => theme.colors.pureWhite};
    }

    .options {
        .filter {
            button {
                background: ${({ theme }) => theme.colors.pureWhite};
                color: ${({ theme }) => theme.colors.black};
                border-color: ${({ theme }) => theme.colors.black};
                font-size: ${({ theme }) => theme.fontSizes.h5};

                &:hover {
                    background: ${({ theme }) => theme.colors.white};
                    color: ${({ theme }) => theme.colors.black};
                    border-color: ${({ theme }) => theme.colors.black};
                }

                &.selected {
                    background: ${({ theme }) => theme.colors.primary};
                    color: ${({ theme }) => theme.colors.black};
                    border-color: ${({ theme }) => theme.colors.primary};
                    font-weight: bold;
                }
            }
        }
    }

    .categories {
        border-top: 1px solid ${({ theme }) => theme.colors.grey25};

        @media (min-width: ${breakpoints.md}px) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};
        }
    }
`;
