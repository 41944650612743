import styled from 'styled-components';
import { MonthlyPriceLabelTemplate } from './MonthlyPriceLabelTemplate';
import { rem } from 'polished';

export const MonthlyPriceLabelStyled = styled(MonthlyPriceLabelTemplate)`
    .aprValue,
    .aprDescription {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        margin-bottom: 0;
        ${(prop) => (prop.alignToRight ? `text-align: right` : 'text-align: left')};
        ${(prop) =>
            prop.withPrimaryColor ? `color: ${prop.theme.colors.primary}` : `color: ${prop.theme.colors.black}`};
    }

    .aprValue {
        font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        ${(prop) => (prop.withoutMarginTop ? `margin-top: 0` : `margin-top: ${rem(8)}`)};
    }

    .aprDescription {
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        margin-top: ${rem(5)};
        text-decoration: underline;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        width: 100%;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .promotionalTextWrapper {
        display: flex;
        flex-direction: row !important;
        ${(prop) => (prop.alignToRight ? `justify-content: flex-end` : '')};
    }
`;
