import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import CheckoutCarMainInfoTemplate from './CheckoutCarMainInfoTemplate';
import { rem } from 'polished';

export const CheckoutCarMainInfoStyled = styled(CheckoutCarMainInfoTemplate)`
    .mainInfo {
        position: relative;
        margin-bottom: ${rem(20)};
        box-sizing: border-box;
        background: ${({ theme }) => theme.colors.white};
        .desktop {
            display: none;
            
            .mobileCarDelivery {
                text-align: center;
            }
        }
        .mobile div {
            display: flex;
            align-items: flex-start;
        }

        @media (min-width: ${breakpoints.md}px) {
            .desktop {
                display: block;
            }
            .mobile {
                display: none;
            }
        }

        &AccordionOpened {
            border: none;
        }

        &.pre-order {
            padding: 0 ${rem(30)};
            border: ${rem(2)} solid #ececee;
        }
    }

    .carInfoHeader {
        padding: ${rem(15)} 0;
        @media (min-width: ${breakpoints.md}px) {
            display: flex;
            justify-content: space-between;

            .carInfoHeaderHolder {
                    width: 50%;
                }
            }
        }
        .title {
            font-size: ${rem(22)};
            font-weight: 500;
            margin: 0;
            line-height: ${rem(24)};
            letter-spacing: -0.00727163rem;
            color: #012642;
            text-transform: uppercase;
            padding: 0 !important;

            &-engineInfo {
                font-size: ${rem(14)};
                line-height: ${rem(26)};
            }

            &-specPack {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }
        }

        .carDelivery {
            display: block;
        }

        @media screen and (max-width: 992px) {
            .carDelivery {
                display: none;
            }
        }
    }

    .availableInWrapper {

        span {
            display: inline-block;
            vertical-align: middle;
        }
        &.stock {
            text-align: center;
            @media (min-width: ${breakpoints.md}px) {
                display: flex;
                align-items: center;
                width: unset;
                position: unset;
                right: unset;
                top: unset;
                max-width: unset;
            }
        }
    }

    .clock {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAi1JREFUSImt1stLlFEYBvDfjHnJK0RWKGrSzY3ZJqioRdSiVdC+/7FtREGLqKygNlaWlF1EKwu01Lxli/ec5mNwZhR7NjPfmfO9z/Pep6Q+WjGMo+hGRzpfwgKm8R5rtQyUapx34QxOodxAxCYm8TwRNyQYwlU0Fc5W8BWLBbVH0F+4s4G7+FiPYBTnC88zeIJvNdT34DROJkFbeIiJfKGochCX0/c13Mc4lmsYh9WkeFrkqQUD+C5y9I+gA9dFvFdwC3N1DFfjN94lT5pFmN9iPRNcRG9y8XZSUI1e3ES7qjgnrIs85XC1YbosKuZEuvQas7tQXo3ZZEMi6iqL2GU83YPxjGciEjBUFkmBeRHLvWJFJX+DZXSmh90ktRFyWXeWRdLg138kyB3dXlZptq0al6vRh4MN7mSbpXKRrcFLC6LWe3ADl8Qw3A457EtNoim68Qdv6hBsJoJZHBbFMSK6fr7q7tkkeD43xEBifSmGVj38xKtkuF+M815Mpd/bVObZRJMoq1ERt2bbd2k1tvBFeLwfnwtenEuE8KApKenBAZG8uaRyJ1gXgy4b78OFJHYKk3mZjIvQlMQu6LB7dKV3S8nWYyrTdB0/cBz70ucnO+/sY7imUlV3JK+K+2BBhGtA5GJEJH6xDlGv2CFjBVuPxKhG7ZV5JXmSMaMySlpEWR8SCc7YwD18KBqrt/THkhc7XfovbDNuahFktIpxPpxU525fFqHLf1tWaxn4Cw2wfsi0BTZtAAAAAElFTkSuQmCC')
            no-repeat;
        background-size: contain;
        padding-right: 1.5rem;
    }

    .carImageWrapper {
        width: 100%;
        margin: 0 auto;
        padding: ${rem(16)} 0 0;

        .carDelivery {
            display: none;
        }

        @media screen and (max-width: 992px) {
            padding-top: 0;
            .carDelivery {
                display: block;
            }
        }
    }

    .carImage {
        width: auto;
        max-width: 100%;
        height: auto;
    }

    .button {
        @media screen and (max-width: 540px) {
            width: 100%;
        }
        width: 22rem;
    }

    .buttonWrapper {
        @media screen and (max-width: 540px) {
            width: 100%;
        }
    }

    .price {
        @media screen and (max-width: 540px) {
            width: 100%;
            order: 2;
            padding-bottom: 0;
            text-align: right;
        }
        text-align: right;
    }

    .totalPrice {
        display: flex;
        justify-content: space-between;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    .paragraph {
        margin: 0 0 ${rem(5)} 0;
        font-size: ${rem(15)};
        line-height: ${rem(18)};
        color: #162133;
        display: flex;
        justify-content: space-between;
        span:first-child {
            font-weight: 700;
        }
        span:nth-child(2) {
            color: #162133;
        }

        .formatMoney {
            white-space: nowrap;
        }

        &.batteryIncluded {
            span:first-child {
                text-align: left;
            }
        }

        &:last-child {
            margin-bottom: ${rem(17)};
        }
    }

    .details {
        @media screen and (max-width: 540px) {
            position: inherit;
            flex-wrap: wrap;
        }
        position: absolute;
        bottom: 0;
        justify-content: space-between;
        width: 100%;
    }
    .detailsBlock {
        padding: ${rem(15)} 0 0;
        color: #000;
    }
    .detailsContent {
        padding: 0;
        .optionsWrapperTitle {
            color: #162133;
        }
        .totalPrice {
            display: flex;
            justify-content: space-between;
            margin: ${rem(15)} 0;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .priceInfoWrapper {
        font-size: ${rem(17)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        line-height: ${rem(20)};
        display: flex;
        justify-content: space-between;
        padding-top: ${rem(24)};
        &__subTitle {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }
    }
    .optionsWrapperTitle {
        font-size: ${rem(15)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        padding-bottom: ${rem(5)};
    }
    .extraInfoWrapper {
        margin-top: ${rem(8)};
    }
    .viewMore {
        margin-top: ${rem(22)};
    }
    @media screen and (max-width: 768px) {
        .availableInWrapper {
            display: none;
        }
        .availableInWrapperMobile {
            display: inline-block;
        }
    }

    @media screen and (max-width: 640px) {
        .mainInfo {
        }

        .carInfoHeader {
            .title {
                font-size: ${rem(22)};
                line-height: ${rem(26)};
                max-width: 100%;

                &-engineInfo {
                    font-size: ${rem(14)};
                    line-height: ${rem(26)};
                }
            }
        }
    }

    .carInfo {
        font-family: 'Peugeot';
        font-size: ${rem(22)};
        color: #012642;
        span {
            color: rgba(20, 32, 50, 0.5);
        }
    }

    .carSpecs {
        font-family: 'Peugeot';
        font-size: ${rem(14)};
        font-weight: ${rem(18)};
        color: #162133;
    }

    .carDelivery {
        position: absolute;
        text-align: right;
        top: ${rem(13)};
        right: ${rem('53px')};
        font-family: 'Peugeot';
        font-size: ${rem(14)};
        color: rgba(20, 32, 50, 0.5);
        font-weight: ${rem(18)};
        line-height: 131.17%;

        span {
            font-weight: bold;
            color: #152946;
        }

        @media screen and (max-width: 992px) {
            position: static;
        }
    }

    .mobileCarDelivery {
        display: flex;
        justify-content: center;

        span {
            text-align: center;
        }
    }

    .priceSummary {
        padding-bottom: ${rem(20)};

        .priceInfoWrapper {
            font-size: ${rem(16)};
            line-height: ${rem(21)};
            color: #000;

            &NoBold {
                font-weight: normal;
            }

            &.totalPriceInfo {
                font-size: ${rem(16)};
                line-height: ${rem(21)};
                font-weight: bold;
            }
        }
    }

    .dataRender {
        &__title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .separatorLine {
        border-top: ${rem('1px')} solid #cdcdcd;
        margin-bottom: ${rem(15)};
    }

    .availabilityStock {
        font-size: ${rem(16)};
        display: block;
        @media (min-width: ${breakpoints.md}px) {
            display: flex;
            flex-direction: column;
        }
        .leadTimeDate {
            position: relative;
            top: 2px;
            left: 5px;
            @media (min-width: ${breakpoints.md}px) {
                position: static;
            }
        }
        .clockIconStock {
            svg {
                height: ${rem('16px')};
                width: ${rem('16px')};
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }
            div {
                display: flex;
            }

            .clockIconStockSvg {
                margin-right: ${rem(8)};
            }
        }
    }

    .offerHeader {
        text-align: center;
        padding: 2rem 0 1rem 0;

        &__leadTime {
            margin-top: 0.8rem;
        }
    }

    .checkoutLegalText {
        width: 100%;
        color: ${({ theme }) => theme.colors.grey18};
        font-size: ${rem(12)};
        line-height: ${rem(18)};
    }

    .offerDetailBlock,
    .detailsBlock {
        .dataRender {
            &__title {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 500;
                font-size: ${rem(14)};
                color: ${({ theme }) => theme.colors.black};
            }

            &__list {
                margin-top: ${rem(3)};
                list-style-type: none;
                padding-left: 0;
            }

            &__item {
                display: flex;
                justify-content: space-between;
                padding: 0.3rem 0;
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 400;
                font-size: ${rem(13)};
                color: ${({ theme }) => theme.colors.black};
            }
        }
    }

    .offerDetailBlock {
        .detailsContent .paragraph {
            font-size: ${rem(16)};
            color: ${({ theme }) => theme.colors.black};
            font-family: ${({ theme }) => theme.fonts.fontBase};

            & > span:first-child {
                font-weight: 500;
            }

            & > span:last-child {
                font-weight: 400;
            }
        }

        .priceInfoWrapper {
            margin-bottom: ${rem(14)};
            font-family: ${({ theme }) => theme.fonts.fontBase};

            &__subTitle {
                font-size: ${rem(13)};
                color: ${({ theme }) => theme.colors.black};
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 400;
            }
        }

        .optionsWrapper {
            .optionsWrapperTitle {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-size: ${rem(14)};
                color: ${({ theme }) => theme.colors.black};
                font-weight: 500;
            }

            .paragraph {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 400;
                font-size: ${rem(13)};
                color: #162233;

                & > span:first-child {
                    font-weight: 400;
                }
            }
        }

        .infoText1_1 {
            padding-top: 1rem;
            span {
                display: block;
                color: ${({ theme }) => theme.colors.grey13};
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-size: ${rem(12)};
            }
            a {
                color: ${({ theme }) => theme.colors.grey13};
            }
        }

        .additionText {
            padding-top: 1rem;
        }

        .priceSummary {
            .customPriceStyle .priceInfoWrapper {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 500;
                font-size: ${rem(15)};
                color: ${({ theme }) => theme.colors.black};
            }

            .bonusPriceStyle .priceInfoWrapper {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 500;
                font-size: ${rem(16)};
                color: ${({ theme }) => theme.colors.black};
            }
        }
    }
    
    .scrappageWrapper {
        padding: ${rem(10)} 0;
    }

    .reservationFeeWrapper {
        background: ${({ theme }) => theme.colors.grey7};
        padding: ${rem(15)};
        display: flex;
        justify-content: space-between;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        margin: ${rem(10)} 0;
    }
`;
