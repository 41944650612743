import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { CheckoutDeliveryStyled } from '../../CheckoutDeliveryStyled';
import { rem } from 'polished';

export const CheckoutDeliveryStyledOVGB = styled(CheckoutDeliveryStyled)`
    .dealerList {
        border: 2px solid ${({ theme }) => theme.colors.grey10};
        box-sizing: border-box;
        height: ${rem(515)};
        overflow-y: hidden;
        margin: 0 ${rem(20)} 0 0;
        padding: ${rem(20)} 0 0 ${rem(10)};
        color: ${({ theme }) => theme.colors.black};
        width: 100%;
        font-family: ${({ theme }) => theme.fonts.fontSecondary};

        & > p {
            margin: 0 0 ${rem(15)};
            padding: 0;
            font-size: ${rem(16)};
            line-height: ${rem(21)};
        }

        &__scrollable {
            height: 90%;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    .mainTitle {
        font-size: ${rem(18)};

        @media (min-width: ${breakpoints.xs}px) {
            font-size: ${rem(24)};
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};
        }
    }

    .dealerItem {
        &__button {
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};
            width: ${rem(125)};
            padding: ${rem(10)} ${rem(5)};
            border: 1px solid ${({ theme }) => theme.colors.black};
            font-size: ${rem(12)};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            color: ${({ theme }) => theme.colors.black};

            &:hover {
                color: ${({ theme }) => theme.colors.white};
            }
        }

        &__icon {
            background: url("data:image/svg+xml,%3Csvg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0C3.13359 0 0 3.1244 0 6.97947C0 11.7342 5.36484 13.4682 6.53242 20.8348C6.60898 21.3092 6.61445 21.6364 7 21.6364C7.38555 21.6364 7.39102 21.3092 7.46758 20.8348C8.63516 13.4682 14 11.7342 14 6.97947C14 3.1244 10.8664 0 7 0Z' fill='%23C90C0F'/%3E%3C/svg%3E%0A")
                top center no-repeat;
        }

        &__footer {
            .btnSelected {
                background-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.white};
            }
        }

        &__address {
            p:first-child {
                font-weight: 700;
            }

            p {
                margin: 0;
                line-height: ${rem(18)};
                font-size: ${rem(14)};
            }
        }

        &__distance {
            p {
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
                line-height: ${rem(16)};
                font-size: ${rem(14)};
            }

            p:first-child {
                font-weight: 700;
            }
        }
    }

    .selectedDealer {
        span {
            color: ${({ theme }) => theme.colors.black};
            line-height: ${rem(21)};
            font-size: ${rem(14)};
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
        }

        &__info {
            p {
                margin: 0;
                color: ${({ theme }) => theme.colors.black};
                line-height: ${rem(21)};
                font-size: ${rem(14)};
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
            }

            p:first-child {
                font-weight: 700;
            }
        }

        &__header {
            span:last-child {
                font-size: ${rem(16)};
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
            }

            span:first-child {
                background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37256 18.6274 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24ZM19.5152 9.45068L10.612 18.3539C10.3096 18.6562 9.81944 18.6562 9.51707 18.3539L4.48481 13.3216C4.18248 13.0193 4.18248 12.5291 4.48481 12.2267L5.57966 11.1319C5.88203 10.8296 6.37224 10.8296 6.67456 11.1319L10.0645 14.5218L17.3254 7.26097C17.6278 6.9586 18.118 6.9586 18.4203 7.26097L19.5152 8.35582C19.8175 8.65814 19.8175 9.14835 19.5152 9.45068Z' fill='%231D1D1B'/%3E%3C/svg%3E%0A");
            }
        }

        & > div:last-child {
            width: 100%;
            button {
                width: 100%;
                font-weight: ${({ theme }) => theme.fontWeights.textMedium};
            }
            @media (min-width: ${breakpoints.xs}px) {
                width: auto;
                button {
                    width: auto;
                }
            }
        }
    }

    .dealerSearch {
        height: fit-content;
        display: flex;
        flex-direction: column;
        @media (min-width: ${breakpoints.xs}px) {
            flex-direction: row;
            align-items: center;
        }
        input {
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
            width: 100%;
            height: ${rem(48)};
            color: ${({ theme }) => theme.colors.grey1};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            line-height: ${({ theme }) => theme.lineHeights.textSupMedium};
            border: 1px solid ${({ theme }) => theme.colors.grey16};
        }

        &__input {
            ::before {
                /* background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFySURBVHgBlVJBUsIwFP1JKeOSG9gj4Amoa3SAE4A7Rotjxxm3wMqFzgSUcdyhJ+gIsgZv0CPkCLglNjFpEyi1wvBWP3n57//89xFkMHn06tiyKgh4SZ0jDl8RZouG/0ohB8gE0wfPRQU0lqGT844Ch+HZ7fMgVyCpioLNtQglQQWA7AK5m8eoV/Wf+lsCAblxbIjmurJMEhfn/mhhHmR4kPxpmsc2Z3VDMmBbpELDH1AG1kn8jaSLbprHgHEt6Ro+/huUFFkKLt71sbwtYFoTIoRd4LDQUWk2ujpOCxyMFbO/1wJy2nFlhFFlVxIuoKYOqfrS+p6DGOrYnZHrbl7yjFyWpaWtuBCsvwL6DPBJOsomV1+8AYuG1buXMLhvO8WjYlNa1zMJWRtjgazXe0CV3caxeIjGa6mutoxmM1RXmlOQxex5QNqO5v5iSjxXrbEklz9QCM3QJqTTkhXH6U4QHIi0iFyu/sECiYhXtwDVVsD6v6fBmTaOj/5oAAAAAElFTkSuQmCC')
                    no-repeat; */
                background-image: url("data:image/svg+xml,%3Csvg width='49' height='44' viewBox='0 0 49 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.5 27C18.364 27 15 23.635 15 19.5C15 15.364 18.364 12 22.5 12C26.635 12 30 15.364 30 19.5C30 23.635 26.635 27 22.5 27ZM36.743 32.536L29.083 24.875C30.28 23.411 31 21.54 31 19.5C31 14.805 27.194 11 22.5 11C17.805 11 14 14.805 14 19.5C14 24.194 17.805 28 22.5 28C24.794 28 26.873 27.086 28.402 25.609L36.036 33.243C36.133 33.34 36.261 33.389 36.389 33.389C36.517 33.389 36.645 33.34 36.743 33.243C36.938 33.047 36.938 32.731 36.743 32.536Z' fill='%23142032'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='14' y='11' width='23' height='23'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.5 27C18.364 27 15 23.635 15 19.5C15 15.364 18.364 12 22.5 12C26.635 12 30 15.364 30 19.5C30 23.635 26.635 27 22.5 27ZM36.743 32.536L29.083 24.875C30.28 23.411 31 21.54 31 19.5C31 14.805 27.194 11 22.5 11C17.805 11 14 14.805 14 19.5C14 24.194 17.805 28 22.5 28C24.794 28 26.873 27.086 28.402 25.609L36.036 33.243C36.133 33.34 36.261 33.389 36.389 33.389C36.517 33.389 36.645 33.34 36.743 33.243C36.938 33.047 36.938 32.731 36.743 32.536Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 -3H50V47H0V-3Z' fill='%23796F6E'/%3E%3C/g%3E%3C/svg%3E%0A");
                width: ${rem(32)};
                height: ${rem(32)};
                left: 0;
            }
            @media (min-width: ${breakpoints.xs}px) {
                flex: 4;
            }
        }
    }
`;
