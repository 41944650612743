import React from 'react';
import {BRAND, isMarketGB} from '../../constants/main';
import { TextAreaStyledAC } from './AC/TextAreaStyled.AC';
import { TextAreaStyledOV } from './OV/TextAreaStyled.OV';
import { TextAreaStyledAP } from './AP/TextAreaStyled.AP';
import { TextAreaStyledDS } from './DS/TextAreaStyled.DS';
import { TextAreaStyledOV as TextAreaStyledOV_GB } from './OV/GB/TextAreaStyled.OV';
import { TextAreaStyled } from './TextAreaStyled';
import { SC } from '../../styles/theme';

export interface TextAreaProps extends SC {
    marginTop?: number;
    marginBottom?: number;
    label?: string;
    placeholder?: string;
    defaultValue?: number | string;
    onChange?: any;
    rows: number;
    maxLength?: number;
}

const TextArea = (props: TextAreaProps) => {
    if (BRAND === 'AC') return <TextAreaStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <TextAreaStyledOV_GB {...props} /> : <TextAreaStyledOV {...props} />;
    if (BRAND === 'AP') return <TextAreaStyledAP {...props} />;
    if (BRAND === 'DS') return <TextAreaStyledDS {...props} />;
    return <TextAreaStyled {...props} />;
};

export default TextArea;
