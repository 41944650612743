import styled from 'styled-components';
import { css, breakpoints } from '../../../styles/theme';
import { GridStyled } from '../GridStyled';

export const GridStyledDS = styled(GridStyled)`
    ${({ noPaddingMobile }) =>
        noPaddingMobile &&
        css`
            padding-left: 1rem;
            padding-right: 1rem;
        `}
`;
