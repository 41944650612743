import styled from 'styled-components';
import { GlobalTooltipStyled } from '../GlobalTooltipStyled';
import { rem } from 'polished';

export const GlobalTooltipStyledAC = styled(GlobalTooltipStyled)`
    .tooltip-box {
        font-size: ${rem(13)} !important;
        width: fit-content !important;
        padding: ${rem(16)} !important;
        background: ${({ theme }) => theme.colors.grey5};
        color: ${({ theme }) => theme.colors.white};
        border: 0;
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${rem(14)} !important;

        &::after {
            border: 0 !important;
            background-color: ${({ theme }) => theme.colors.grey5};
            top: ${rem(-7)};
        }
    }

    .place-top::after {
        border-top: none !important;
        border-right: none !important;
    }

    .place-right::after {
        border-bottom: none !important;
        border-right: none !important;
    }

    .place-bottom::after {
        border-bottom: none !important;
        border-left: none !important;
    }

    .place-left::after {
        border-top: none !important;
        border-left: none !important;
    }
`;
