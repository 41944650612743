import React from 'react';
import {BRAND, isMarketGB} from '../../constants/main';
import { BrandTypes } from '../../types/brands';
import { GeoLocationInputStyledAC } from './AC/GeoLocationInputStyled.AC';
import { GeoLocationInputStyledOV } from './OV/GeoLocationInputStyled.OV';
import { GeoLocationInputStyledOV as GeoLocationInputStyledOVGB } from './OV/GB/GeoLocationInputStyled.OV';
import { GeoLocationInputStyledDS } from './DS/GeoLocationInputStyled.DS';
import { GeoLocationInputStyledAP } from './AP/GeoLocationInputStyled.AP';
import { GeoLocationInputStyled } from './GeoLocationInputStyled';
import { IGeoLocation } from '../../redux/filters/filter.duck.interface';

export interface ICoordinates {
    lat: number;
    lng: number;
}

export type IGeocodeResponse = Array<{
    geometry: {
        location: {
            lat(): number;
            lng(): number;
        };
    };
    formatted_address: string;
}>;

export enum GeocodeResponseStatus {
    OK = 'OK',
    ERROR = 'ERROR',
    INVALID_REQUEST = 'INVALID_REQUEST',
    OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
    ZERO_RESULTS = 'ZERO_RESULTS',
    REQUEST_DENIED = 'REQUEST_DENIED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface IGeoLocationInputProps {
    distanceInKm?: number;
    geoLocation?: IGeoLocation;
    getGeoLocationName?: string;
    onSubmit: (coords: ICoordinates, distanceInKm: number, userInput: string) => void;
    reset?: boolean;
    onClear?: () => void;
    isStockSlice?: boolean;
    showOnlyInput?: boolean;
    setUserInput?: any;
    isModal?: boolean;
    onEnterKey?: () => void;
}

const GeoLocationInput = (props: IGeoLocationInputProps) => {
    if (BRAND === BrandTypes.AC) return <GeoLocationInputStyledAC {...props} />;
    if (BRAND === BrandTypes.OV) return isMarketGB ? <GeoLocationInputStyledOVGB {...props} /> : <GeoLocationInputStyledOV {...props} />;
    if (BRAND === BrandTypes.AP) return <GeoLocationInputStyledAP {...props} />;
    if (BRAND === BrandTypes.DS) return <GeoLocationInputStyledDS {...props} />;
    return <GeoLocationInputStyled {...props} />;
};

export default GeoLocationInput;
