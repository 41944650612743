import styled from 'styled-components';
import { rem } from 'polished';
import { CheckoutDealerInfoStyled } from '../CheckoutDealerInfoStyled';

export const CheckoutDealerInfoStyledAC = styled(CheckoutDealerInfoStyled)`
    .dealerInfoTitle {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
    }

    .addressPhoneWrapper {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        color: ${({ theme }) => theme.colors.grey5};

        svg {
            width: ${rem('32px')} !important;
            height: ${rem('32px')} !important;
        }
        .dealerAddressTitle {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }
        .address > p {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            color: ${({ theme }) => theme.colors.grey5};
            font-weight: normal;
        }
    }

    .mapPointIcon {
        svg {
            height: ${rem(25)};
            width: ${rem(25)};
            position: relative;
            bottom: ${rem(3)};
        }

        path[fill] {
            fill: #9d0605;
        }

        g {
            display: none;
        }

        g {
            display: none;
        }
    }

    .phoneIcon {
        align-items: center;

        svg {
            height: ${rem(16)};
            width: ${rem(16)};

            & > * {
                stroke-width: ${rem(1)};
            }

            path {
                stroke: ${({ theme }) => theme.colors.primary} !important;
                fill: none !important;
            }
        }
    }

    .infoIcon {
        svg {
            height: ${rem(18)};
            width: ${rem(18)};
        }

        g > * {
            stroke: #9d0605;
            stroke-width: ${rem(1)};
        }

        g > line {
            stroke-width: ${rem(2)};
        }
    }

    .addressPhoneWrapperEC {
        .mailWrapper {
            display: flex;
            align-items: flex-end;

            a {
                color: ${({ theme }) => theme.colors.blue2};
            }
        }
        .phoneIcon svg {
            height: ${rem(24)};
            width: ${rem(24)};

            path {
                stroke: none !important;
                fill: ${({ theme }) => theme.colors.blue2} !important;
            }
        }

        .mapPointIcon {
            path[fill] {
                fill: ${({ theme }) => theme.colors.grey1};
            }
        }
    }
`;
