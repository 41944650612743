import { IOfferItem } from 'src/interfaces/IOfferItem';
import { getBodyStyleDescription } from 'src/services/carList/utils/Car.utils';
import { ITrimItem } from 'src/redux/trimSelector/trimSelector.duck.interface';

export const getOfferCarTitle = (
    offer: IOfferItem | ITrimItem,
    shouldIncludeBodyStyleDescription: boolean = true
): string => {
    if (!shouldIncludeBodyStyleDescription) {
        return offer?.model?.description;
    }

    return ` ${offer.model.description} ${getBodyStyleDescription(
        offer.model.description,
        offer.extraFields?.grBodyStyle?.description ?? offer?.grBodyStyle?.description ?? '',
        offer.model.id
    )}`;
};
