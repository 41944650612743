import format from '@utils/NumberFormat';
import React, { ReactNode, useCallback } from 'react';
import { PriceRounding } from '../types/priceRounding';
import useTranslations from './useTranslations';
import FinanceWidgetDuck from '../redux/financeWidget/financeWidget.duck';
import { useSelector } from 'react-redux';
import { isMarketES, isMarketPT, isMarketIT, IS_B2B } from '../constants/main';

const splitAt = (index: number, text: string) => [text.slice(0, index), text.slice(index)];

const getPatternWithoutDecimals = (pattern: string) => {
    const newPattern = splitAt(pattern.match(/[^,.]*$/).index, pattern);

    return newPattern[0] + newPattern[1].replace(/0+/gi, '#');
};

export const useMoneyFormatter = () => {
    const { t } = useTranslations();
    const financeQuote = useSelector(FinanceWidgetDuck.getFinanceQuote);

    const roundPrice = (amount: number | string, priceRounding?: PriceRounding) => {
        switch (priceRounding) {
            case PriceRounding.CEIL_INT:
                return Math.ceil(amount as number);
            case PriceRounding.NEAREST_INT:
                return Math.round(amount as number);
            case PriceRounding.NO_ROUND:
            default:
                return amount;
        }
    };

    const formatAmount = useCallback(
        (
            amount: number | string,
            suppressDecimals: boolean = false,
            formatPattern?: string,
            priceRounding?: PriceRounding
        ): string => {
            const rawPattern = formatPattern || t('price.pattern');
            const pattern =
                suppressDecimals && !(isMarketIT && IS_B2B) && !isMarketES && !isMarketPT
                    ? getPatternWithoutDecimals(rawPattern)
                    : rawPattern;
            return format(pattern, roundPrice(amount, priceRounding));
        },
        [t]
    );

    const formatMoney = useCallback(
        (
            amount: number | string,
            suppressDecimals: boolean = false,
            formatPattern?: string,
            priceRounding?: PriceRounding
        ): ReactNode => {
            const isAmountDefined = typeof amount !== 'undefined';
            const formattedString = formatAmount(
                isAmountDefined ? amount : `${financeQuote?.monthlyPayment}`,
                [PriceRounding.CEIL_INT, PriceRounding.NEAREST_INT].includes(priceRounding) || suppressDecimals,
                formatPattern || t('price.pattern.currency'),
                priceRounding
            );

            const formattedParts = formattedString
                ? formattedString.split(/([^0-9|.|,|\s])/gi).filter((item: string) => item !== '')
                : [];

            return (
                <span className="formatMoney">
                    {formattedParts.map((item: string, key: number) => {
                        if (!item.match(/[1-9]/gi)) {
                            return (
                                <span key={key} className="currency">
                                    <span className="currencyLabel">{item}</span>
                                </span>
                            );
                        }

                        return item;
                    })}
                </span>
            );
        },
        [financeQuote?.monthlyPayment, formatAmount, t]
    );

    const formatConfiguratorPrice = useCallback(
        (
            price: number,
            pricePrefix: string = '',
            replaceZeroPrice: boolean = false,
            replacement: string = '',
            suppressDecimals: boolean = false,
            priceSuffix: string = null
        ) => {
            return replaceZeroPrice && price === 0 ? (
                <>{replacement}</>
            ) : (
                <>
                    {price > 0 && <span className="pricePrefix">{pricePrefix}</span>}
                    {formatMoney(price, suppressDecimals)}
                    {priceSuffix && <span className="priceSuffix">&nbsp;{priceSuffix}&nbsp;</span>}
                </>
            );
        },
        [formatMoney]
    );

    return { formatMoney, formatAmount, formatConfiguratorPrice };
};
