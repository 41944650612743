import { BRAND, isBrandOV, isMarketGB } from '../../constants/main';
import { FooterStyledAC } from './AC/FooterStyled.AC';
import { FooterStyledAP } from './AP/FooterStyled.AP';
import { FooterStyledDS } from './DS/FooterStyled.DS';
import { FooterStyledOV } from './OV/FooterStyled.OV';
import { FooterStyledOV as FooterStyledOV_GB } from './OV/GB/FooterStyled.OV';
import { FooterStyled } from './FooterStyled';
import { SC } from '../../styles/theme';
import React from 'react';
import { IFooterItem } from '../../redux/content/content.duck.interface';

export interface FooterProps extends SC {
    /** Footer text color */
    color?: string;
    items?: IFooterItem[];
    checkout?: boolean;
}

const Footer = (props: FooterProps) => {
    if (isBrandOV) return isMarketGB ? <FooterStyledOV_GB {...props} /> : <FooterStyledOV {...props} />;
    if (BRAND === 'AC') return <FooterStyledAC {...props} />;
    if (BRAND === 'AP') return <FooterStyledAP {...props} />;
    if (BRAND === 'DS') return <FooterStyledDS {...props} />;
    return <FooterStyled {...props} />;
};

export default Footer;
