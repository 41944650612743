import React, { MouseEventHandler } from 'react';
import { Icons } from '../Icon';
import { SC } from '../../styles/theme';
import { BRAND, isBrandOV, isBrandAC, isBrandAP, isBrandDS, isMarketGB } from '../../constants/main';
import { IconButtonStyled } from './IconButtonStyled';
import { IconButtonStyledOV } from './OV/IconButtonStyled.OV';
import { IconButtonStyledOV as IconButtonStyledOV_GB } from './OV/GB/IconButtonStyled.OV';
import { IconButtonStyledDS } from './DS/IconButtonStyled.DS';
import { IconButtonStyledAP } from './AP/IconButtonStyled.AP';
import { IconButtonStyledAC } from './AC/IconButtonStyled.AC';

export enum IconButtonType {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

export interface IconButtonProps extends SC {
    type?: keyof typeof IconButtonType;
    active?: boolean;
    icon: Icons;
    onClick: MouseEventHandler<HTMLDivElement>;
    name?: string;
    iconClass?: string;
    color?: string;
    width?: number;
    height?: number;
    dataTestId?: string;
    isLoading?: boolean;
    tabIndex?: number;
    altText?: string;
    altId?: string;
    role?: string;
}

const Button = (props: IconButtonProps) => {
    if (isBrandOV) return isMarketGB ? <IconButtonStyledOV_GB {...props} /> : <IconButtonStyledOV {...props} />;
    if (isBrandAC) return <IconButtonStyledAC {...props} />;
    if (isBrandAP) return <IconButtonStyledAP {...props} />;
    if (isBrandDS) return <IconButtonStyledDS {...props} />;
    return <IconButtonStyled {...props} />;
};

export default Button;
