import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { ModalStyled } from '../ModalStyled';
import { rem } from 'polished';

export const ModalStyledDS = styled(ModalStyled)`
    .modalSidebar {
        &__wrap {
            &__header {
                font-size: ${rem(26)};

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(30)};
                }
            }
        }
    }
    .modalFinancialSummary {
        &--title {
            font-size: ${rem(20)};
        }
    }
    .financialSummary__legalInfo {
        font-size: ${({ theme }) => theme.fontSizes.h6};
    }
    .form-field,
    .financialSummary__legalText {
        a {
            color: ${({ theme }) => theme.colors.black1};

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};
            }

            &.submit-button {
                color: ${({ theme }) => theme.colors.white};
                text-decoration: unset;
                background: ${({ theme }) => theme.colors.black1};
                border-radius: ${rem(24)};
                text-transform: unset;
                font-size: ${({ theme }) => theme.fontSizes.h5};

                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }
    .modalWindow__wrap {
        &__header__title {
            color: ${({ theme }) => theme.colors.grey6};
        }

        .modalFinancialSummary {
            color: ${({ theme }) => theme.colors.grey6};
            .finance-summary--grid .finance-summary--row-1 {
                padding: 0.5rem !important;
            }
        }
        &.finance-legal {
            .button-container {
                button:hover {
                    background: ${({ theme }) => theme.colors.primaryHover};
                }
            }
        }
    }

    p.MsoNormal span {
        font-family: ${({ theme }) => theme.fonts.fontBase} !important;
    }

    p span {
        font-family: ${({ theme }) => theme.fonts.fontBase} !important;
    }
`;
