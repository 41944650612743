import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalFeaturesTemplate from './ModalFeaturesTemplate';

export const ModalFeaturesStyled = styled(ModalFeaturesTemplate)`
    width: 98%;
    color: ${({ theme }) => theme.colors.black};

    .title {
        display: flex;
        flex-direction: column;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-size: ${rem(25)};
        padding-bottom: ${rem(25)};

        .pack {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            padding-top: ${rem(10)};
            color: ${({ theme }) => theme.colors.black2};
        }
    }

    .keys {
        padding: ${rem(30)} 0 ${rem(10)} 0;
        border-top: 2px solid ${({ theme }) => theme.colors.grey7};

        .label {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${rem(18)};
            text-transform: uppercase;
            display: block;
        }
    }

    .engines,
    .styles,
    .equipments,
    .colors,
    .interiors,
    .features {
        padding: ${rem(30)} 0;
        border-top: 1px solid ${({ theme }) => theme.colors.grey7};

        .label {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            display: flex;
            flex-direction: column;

            @media (max-width: ${breakpoints.md}px) {
                padding-bottom: ${rem(20)};
            }

            @media (min-width: ${breakpoints.md}px) {
                width: 45%;
            }
        }

        .configurations {
            @media (min-width: ${breakpoints.md}px) {
                width: 55%;
            }
        }

        @media (min-width: ${breakpoints.md}px) {
            display: flex;
            justify-content: space-between;
        }
    }

    .engines {
        .configurations {
            display: flex;
            flex-direction: column;

            > div {
                display: flex;
                flex-direction: column;
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                padding-bottom: ${rem(15)};

                .engine,
                .price {
                    color: ${({ theme }) => theme.colors.grey17};
                    line-height: ${rem(16)};
                }
            }
        }
    }

    .colors {
        .configurations {
            display: flex;
            flex-direction: column;

            img {
                width: ${rem(250)};
            }

            button {
                width: ${rem(40)};
                height: ${rem(40)};
                border: none;
                border-radius: 55%;
                margin: ${rem(20)} ${rem(5)} ${rem(10)} 0;
                border: 0.1rem solid ${({ theme }) => theme.colors.white};
                text-align: center;
                cursor: pointer;

                &.selected,
                &:hover {
                    border: 0.15rem solid ${({ theme }) => theme.colors.white};
                    box-shadow: 0px 0px 0px 0.1rem ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }

    .styles,
    .equipments,
    .interiors {
        .configurations {
            display: flex;
            flex-direction: column;
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.grey31};
            line-height: ${rem(16)};

            span {
                display: block;
                padding-bottom: ${rem(10)};
                
                &.link {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    
                    &:hover,
                    &:focus-visible {
                        text-decoration: underline;
                    }
                }
                
                .icon-wrapper {
                    display: inline-block;
                }
            }
            
            svg {
                width: ${rem(12)};
                height: ${rem(10)};
                
                path {
                    fill: ${({ theme }) => theme.colors.grey31};
                }
            }
            
            img {
                margin-bottom: ${rem(20)};
            }
        }
    }

    .features {
        flex-direction: column;

        .configurations {
            width: 100%;
        }

        .title {
            text-transform: uppercase;
            font-size: ${rem(18)};
        }

        .configuration {
            padding-bottom: ${rem(10)};

            .label {
                display: block;

                @media (max-width: ${breakpoints.md}px) {
                    padding-top: ${rem(20)};
                }

                @media (min-width: ${breakpoints.md}px) {
                    width: 45%;
                }
            }

            .options {
                display: flex;
                flex-direction: column;

                .option {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    color: ${({ theme }) => theme.colors.grey31};
                    line-height: ${rem(16)};
                    display: block;
                    padding-bottom: ${rem(10)};

                    svg {
                        margin-left: ${rem(8)};
                        margin-bottom: ${rem(-2)};
                    }
                }

                @media (min-width: ${breakpoints.md}px) {
                    width: 55%;
                }
            }

            @media (min-width: ${breakpoints.md}px) {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        padding: ${rem(20)} 0 ${rem(20)} 0;

        span {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            border: 1px solid ${({ theme }) => theme.colors.black};
            text-transform: none;
            padding: ${rem(10)} ${rem(15)};
            cursor: pointer;
            border-radius: ${rem(4)};
            margin-right: ${rem(10)};
            white-space: nowrap;
            margin-bottom: ${rem(10)};

            &.selected,
            &:hover {
                background: rgba(0, 163, 224, 0.1);
                border: 1px solid ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .spinner {
        margin: 0 auto;
        padding-top: ${rem(10)};
    }
`;
