import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalModalIncompletePx } from './index';
import Button from '@components/Button';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { deletePartExchange } from '../../../partExchange/service/ApiService';
import Spinner from '@components/Spinner';
import { isBrandAC } from '../../../constants/main';
import ConfiguratorDuck from '../../../redux/configurator/configurator.duck';

export const ModalIncompletePxTemplate = ({
    className,
    onContinueClick,
    description,
    backButton,
    continueButton,
    withoutDelete = false,
}: IModalModalIncompletePx) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { currentDeal } = useCarDetailsDuck();

    const onClose = async () => {
        dispatch(UIDuck.closeModal());
    };

    const onContinue = async () => {
        try {
            setIsLoading(true);

            if (!withoutDelete) {
                await deletePartExchange(null, null, currentDeal?.partExchangeId);
            }
            dispatch(ConfiguratorDuck.setPxState(null));

            if (onContinueClick) {
                onContinueClick();
                dispatch(UIDuck.closeModal());
            } else {
                const continueBtn = document.getElementById('TESTING_TO_BASKET_BOX_INTERESTEDBOX');

                if (continueBtn) {
                    continueBtn.click();
                }
            }
        } catch {
            setIsLoading(false);
        }
    };

    return (
        <div className={className}>
            <p className="description">{description}</p>
            {isLoading && <Spinner size={25} border={2} color="black" />}
            {!isLoading && (
                <div className="button">
                    <Button onClick={onClose} primary={!isBrandAC} secondary={isBrandAC}>
                        {backButton}
                    </Button>
                    <Button onClick={onContinue} secondary withoutBackground={isBrandAC}>
                        {continueButton}
                    </Button>
                </div>
            )}
        </div>
    );
};
