import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalSessionChange } from './index';
import Button from '@components/Button';
import { buildParameterizedPathname } from '@utils/url.utils';
import routes from '../../../constants/routes';
import { CarJourneyType } from '../../../services';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { Redux } from '../../../redux/redux.interface';
import GlobalDuck from '../../../redux/global/global.duck';

export const ModalLogoutTemplate = ({ className }: IModalSessionChange) => {
    const { t } = useTranslations();

    const router = useRouter();

    const carJourney = useSelector<Redux, CarJourneyType>((state) => GlobalDuck.getCarJourney(state));

    const onHome = async () => {
        await router.push(buildParameterizedPathname(routes.ROOT, carJourney));
        router.reload();
    };

    return (
        <div className={className}>
            <p className="description">{t('modal.logout.description')}</p>
            <div className="button">
                <Button onClick={onHome} primary fitContentWidth centerMode>
                    {t('modal.logout.button.redirect')}
                </Button>
            </div>
        </div>
    );
};
