import { useSelector } from 'react-redux';
import FinanceWidgetDuck from 'src/redux/financeWidget/financeWidget.duck';
import { GetPricesListResponse, IAdditionalColorPriceResponse } from 'src/services/financePrices/financePrices.types';
import { PRICE_TYPE_EMPLOYEE } from '../services/filters/filters';
import { IFeature, IPrice, IPriceV2, IPriceV2Types } from 'src/services/features/featureList.types';
import { ICarDetail } from 'src/redux/carDetails/carDetails.duck.interface';
import { useCallback } from 'react';
import { useConfiguratorDuck } from '@hooks/useConfiguratorDuck';
import { IConfiguratorOption } from 'src/redux/configurator/configurator.duck.interface';

export interface IAccessoryMonthlyPrice {
    price: number;
    defaultPrice: number;
    currency: string;
}

export const useAccessoryMonthlyPrices = () => {
    const pricesList = useSelector(FinanceWidgetDuck.getFinancePricesList);
    const { motorizations } = useConfiguratorDuck();

    const getEngineMonthlyPrice = useCallback(
        (engine: ICarDetail): IAccessoryMonthlyPrice => {
            const matchingEngines = (pricesList || []).filter((enginePrice) => enginePrice.code === engine?.externalId);
            // @NOTE this way find the motor price (without options)
            const enginePrice = matchingEngines.reduce((shortest, engine) => {
                if (shortest?.code?.length < engine.code.length) {
                    return shortest;
                }

                return engine;
            }, null);
            const selectedEngine = motorizations?.find(
                (motorization) => motorization.externalId.split('+')[0] === engine?.externalId.split('+')[0]
            );

            const price = (selectedEngine?.prices ?? selectedEngine?.extraFields?.prices)?.find(
                (price: any) => price.type === PRICE_TYPE_EMPLOYEE
            );

            return {
                price: enginePrice?.price,
                defaultPrice: price?.monthlyPrices?.[0]?.amount,
                currency: price?.currency,
            };
        },
        [pricesList, motorizations]
    );

    const getEngineCashPrice = useCallback(
        (engine: ICarDetail): number => {
            const selectedEngine = motorizations?.find(
                (motorization) => motorization.externalId.split('+')[0] === engine?.externalId?.split('+')[0]
            );
            const price: IPriceV2 = (selectedEngine?.pricesV2 ?? selectedEngine?.extraFields?.pricesV2)?.find(
                (price) => price.type === IPriceV2Types.TOTAL_B2C_CASH
            );
            return (
                price?.breakdown?.finalCustomerCashPriceOnTheRoad ?? price?.finalPriceInclTax ?? price?.basePriceInclTax
            );
        },
        [motorizations]
    );

    const mergeAdditionalColors = useCallback((pricesList: GetPricesListResponse): IAdditionalColorPriceResponse[] => {
        return (pricesList || []).reduce((agg, price) => {
            if (!price.additionalColors) {
                return agg;
            }

            return [...agg, ...price.additionalColors];
        }, []);
    }, []);

    const getOptionMonthlyPrice = useCallback(
        (option: any): IAccessoryMonthlyPrice => {
            let optionPrice = mergeAdditionalColors(pricesList).find(
                (featurePrice) => featurePrice.code === (option._id ?? option.id)
            );

            if (!optionPrice) {
                optionPrice = (pricesList || []).find((engine) => {
                    const priceListCodeIdParts = engine.code.split('+');
                    const priceListCodeId = `${priceListCodeIdParts[0]}+${priceListCodeIdParts[1]}+${priceListCodeIdParts[2]}`;
                    return priceListCodeId === option?.externalId;
                });
            }

            const price = option.prices?.find((price: IPrice) => price.type === PRICE_TYPE_EMPLOYEE);

            return {
                price: optionPrice?.price,
                defaultPrice: price?.monthlyPrices?.[0]?.amount,
                currency: price?.currency,
            };
        },
        [mergeAdditionalColors, pricesList]
    );

    const getFeatureMonthlyPrice = useCallback(
        (feature: IFeature | IConfiguratorOption): IAccessoryMonthlyPrice => {
            const featurePrice = mergeAdditionalColors(pricesList).find(
                (featurePrice) => featurePrice.code === feature.id
            );
            const price = feature.prices.find((price: any) => price.type === PRICE_TYPE_EMPLOYEE);

            return {
                price: featurePrice?.price,
                defaultPrice: price?.monthlyPrices?.[0]?.amount,
                currency: price.currency,
            };
        },
        [mergeAdditionalColors, pricesList]
    );

    const getFeatureCashPrice = useCallback((feature: IFeature | IConfiguratorOption): number => {
        const price: IPriceV2 = feature.pricesV2?.find(
            (price: { type: IPriceV2Types }) => price.type === IPriceV2Types.B2C_CASH
        );
        return price?.breakdown?.finalCustomerCashPriceOnTheRoad ?? price?.finalPriceInclTax ?? price?.basePriceInclTax;
    }, []);

    return {
        getOptionMonthlyPrice,
        getFeatureMonthlyPrice,
        getEngineMonthlyPrice,
        getEngineCashPrice,
        getFeatureCashPrice,
    };
};

export default useAccessoryMonthlyPrices;
