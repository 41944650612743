import React, { useEffect, useState } from 'react';
import { breakpoints, SC } from 'src/styles/theme';
import ModalTrimCompareTemplateDesktop from '@components/Modal/ModalComparators/ModalTrimCompare/ModalTrimTemplateDesktop';
import ModalTrimCompareTemplateMobile from '@components/Modal/ModalComparators/ModalTrimCompare/ModalTrimTemplateMobile';
import { MOBILE_AND_DESKTOP_VERSION_BREAKPOINT } from '@components/Modal/ModalComparators/ModalTrimCompare/shared';

export const TRIM_MOBILE_WIDTH = 150;
export const TRIM_DESKTOP_WIDTH = 280;
export interface ITrimCompareStyled extends SC {
    countOfTrims?: number;
    className?: string;
}

const ModalTrimCompareTemplate = ({ className }: ITrimCompareStyled) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < MOBILE_AND_DESKTOP_VERSION_BREAKPOINT);
    }, []);

    return isMobile ? (
        <ModalTrimCompareTemplateMobile className={className} />
    ) : (
        <ModalTrimCompareTemplateDesktop className={className} />
    );
};

export default ModalTrimCompareTemplate;
