import React from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalNeedHelp } from './index';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useGTM } from '@hooks/useGTM';
import { isBrandOV, isBrandDS, isMarketGB, MARKET, ModalVersion, LEAD_FORM_ID } from 'src/constants/main';
import { GtmData } from 'src/types/gtm';
import { UIDuck } from 'src/redux/commonDucks/ui.duck';
import { Pages } from 'src/types/pages';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { CONTACT_OPTIONS } from 'src/constants/enums';
import { getFAQsURL } from '@components/Header/NeedHelpSection/helper';
import { useGlobalDuck } from '@hooks/useGlobalDuck';
import Link from '@components/Link';

export const ModalNeedHelpTemplate = ({ className, onClose }: IModalNeedHelp) => {
    const { t } = useTranslations();
    const { pushToDataLayer } = useGTM();
    const router = useRouter();
    const dispatch = useDispatch();

    const { carJourney } = useGlobalDuck();

    const isPhoneContactEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_CONTACT_OPTIONS,
        CONTACT_OPTIONS.PHONE_NUMBER
    );

    const isLeadFormContactEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_CONTACT_OPTIONS,
        CONTACT_OPTIONS.LEAD_FORM
    );

    const isTestDriveContactEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_CONTACT_OPTIONS,
        CONTACT_OPTIONS.TEST_DRIVE
    );

    const isFaqEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_CONTACT_OPTIONS, CONTACT_OPTIONS.FAQ);

    const makeARequestBlackList: string[] = ['/configurable', '/stock', '/trim/stock/[...model]', '/login'];

    const loadLeadForm = () => {
        dispatch(
            UIDuck.openModal({
                modalType: UIDuck.MODAL_TYPES.LEAD_FORM,
                data: {
                    page: Pages.basketV2,
                    leadFormId: LEAD_FORM_ID,
                    type: 'LEAD_FORM',
                },
                modalVersion: ModalVersion.v2,
            })
        );
    };

    const requestIcon = () => {
        if (isBrandDS) {
            return Icons.HeadsetV2;
        }

        return isBrandOV ? Icons.Headset : Icons.Request;
    };

    return (
        <div className={className}>
            <p className="description">{t('modal.needAHand.description')}</p>
            {isPhoneContactEnabled && (
                <div className="phone">
                    <IconTemplate className="icon" name={isBrandOV && !isMarketGB ? Icons.PhoneV2 : Icons.Phone} />
                    <div className="title">{t('header.nav.callUs')}</div>
                    <div className="titleLabel">{t('header.nav.callUsTime')}</div>
                    <Link
                        label={t('support.telephone')}
                        onClick={() =>
                            pushToDataLayer({
                                event: 'uaevent',
                                eventCategory: 'Header',
                                eventAction: `Call::SOLRetail::${MARKET}`,
                                eventLabel: t('header.nav.needHelp'),
                            } as GtmData)
                        }
                        marginTop={15}
                        primary
                        withArrowIcon
                        dataTestId="TESTING_MODAL_NEED_HELP_PHONE_LINK"
                        href={`tel:${t('support.telephone')}`}
                    />
                </div>
            )}
            {!makeARequestBlackList.includes(router.pathname) && isLeadFormContactEnabled && (
                <div className="request">
                    <IconTemplate className="icon" name={requestIcon()} />
                    <div className="title">{t('modal.needAHand.leadForm.title')}</div>
                    <div className="titleLabel">{t('modal.needAHand.leadForm.label')}</div>
                    <Link
                        label={t('modal.needAHand.leadForm.link')}
                        onClick={loadLeadForm}
                        primary
                        marginTop={15}
                        withArrowIcon
                        dataTestId="TESTING_MODAL_NEED_HELP_LEAD_FORM_LINK"
                    />
                </div>
            )}
            {isTestDriveContactEnabled && (
                <div className="testDrive">
                    <IconTemplate className="icon" name={Icons.Drive} />
                    <div className="title">{t('modal.needAHand.testDrive.title')}</div>
                    <div className="titleLabel">{t('modal.needAHand.testDrive.label')}</div>
                    <Link
                        label={t('modal.needAHand.testDrive.link')}
                        href={t('modal.needAHand.testDrive.url')}
                        onClick={loadLeadForm}
                        primary
                        marginTop={15}
                        withArrowIcon
                        dataTestId="TESTING_MODAL_NEED_HELP_TEST_DRIVE_LINK"
                    />
                </div>
            )}
            {isFaqEnabled && (
                <div className="faq">
                    <IconTemplate className="icon" name={Icons.Question} />
                    <div className="title">{t('modal.needAHand.faq.title')}</div>
                    <div className="link">
                        <Link
                            label={t('modal.needAHand.faq.link')}
                            href={getFAQsURL(carJourney)}
                            onClick={() => {
                                dispatch(UIDuck.closeModal());
                                onClose?.();
                                pushToDataLayer({
                                    event: 'uaevent',
                                    eventCategory: 'Header',
                                    eventAction: `Redirection::Internal`,
                                    eventLabel: 'Most common questions',
                                } as GtmData);
                            }}
                            primary
                            withArrowIcon
                            marginTop={15}
                            dataTestId="TESTING_MODAL_NEED_HELP_FAQ_LINK"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
