import styled from 'styled-components';
import { ModalFindDealerStyled } from '../ModalFindDealerStyled';
export const ModalFindDealerStyledOVGB = styled(ModalFindDealerStyled)`
    .geolocation-input--container > .geolocation-input--textfield-container > input {
        border: solid 1px ${({ theme }) => theme.colors.grey16};
        border-radius: unset;
    }

    .modalDealerSelect {
        .Select__control {
            border-color: ${({ theme }) => theme.colors.grey16};
            border-radius: unset;
        }
    }

    .modalAddr {
        font-family: Arial;
        line-height: 1.38;
    }
`;
