import styled from 'styled-components';
import { CheckoutTipsSidebarStyled } from '../CheckoutTipsSidebarStyled';
import { rem } from 'polished';

export const CheckoutTipsSidebarStyledAP = styled(CheckoutTipsSidebarStyled)`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .content {
        color: ${({ theme }) => theme.colors.primaryBlack};

        ul li {
            position: relative;
            margin-left: ${rem(15)};

            &::before {
                content: '';
                background: ${({ theme }) => theme.colors.primary};
                width: ${rem(8)};
                height: ${rem(8)};
                position: absolute;
                left: ${rem(-15)};
                top: ${rem(7)};
                border-radius: 50%;
            }
        }
    }
`;
