import styled from 'styled-components';
import { FeatureSwitcherTemplate } from './FeatureSwitcherTemplate';
import { rem } from 'polished';

export const FeatureSwitcherStyled = styled(FeatureSwitcherTemplate)`
    background-color: ${({ theme }) => theme.colors.grey16};
    color: ${({ theme }) => theme.colors.black};
    padding: ${rem(6)};
    margin: ${rem(2)};

    & .featureBtn {
        width: auto;
        padding: ${rem(3)};
        margin: ${rem(5)};

        &--false {
            padding: ${rem(3)};
            background-color: ${({ theme }) => theme.colors.danger};
        }
        &--true {
            padding: ${rem(3)};
            background-color: ${({ theme }) => theme.colors.success};
        }
    }

    & .select {
        display: inline-block;
        width: 100%;
    }
`;
