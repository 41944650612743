import React, { FC, useCallback } from 'react';
import useTranslations from '@hooks/useTranslations';
import { CheckoutTipsSidebarProps, IItem } from '.';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { MySavedDealJourneyType } from '../../redux/user/user.duck.interface';

const CheckoutTipsSidebarTemplate: FC<CheckoutTipsSidebarProps> = ({ className, title, items, isStockCar }) => {
    const { t } = useTranslations();
    const { currentDeal } = useCarDetailsDuck();

    const journeyType = currentDeal.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH ? 'cash' : 'finance';

    // TODO translation refactoring
    const getItemKey = useCallback((item: IItem): string => {
        switch (item.text) {
            case 'checkout.delivery.tips.item1':
                return `checkout.delivery.tips.item1`;
            case 'checkout.delivery.tips.item2':
                return `checkout.delivery.tips.item2`;
            case 'checkout.delivery.tips.item3':
                return `checkout.delivery.tips.item3`;
            case 'checkout.delivery.tips.item4':
                return `checkout.delivery.tips.item4`;
            case 'checkout.confirmation.tips.item1':
                return `checkout.confirmation.tips.item1`;
            case 'checkout.confirmation.tips.item2':
                return `checkout.confirmation.tips.item2`;
            case 'checkout.confirmation.tips.item3':
                return `checkout.confirmation.tips.item3`;
            case 'offers.delivery.tips.item1':
                return `offers.delivery.tips.item1`;
            case 'offers.delivery.tips.item2':
                return `offers.delivery.tips.item2`;
            case 'offers.delivery.tips.item3':
                return `offers.delivery.tips.item3`;
            case 'checkout.myDetails.tips.item1':
                return `checkout.myDetails.tips.item1`;
            case 'checkout.myDetails.tips.item2':
                return `checkout.myDetails.tips.item2`;
            case 'checkout.myDetails.tips.item3':
                return `checkout.myDetails.tips.item3`;
            case 'checkout.myDetails.tips.item4':
                return `checkout.myDetails.tips.item4`;
            case 'checkout.orderReview.tips.item1':
                return `checkout.orderReview.tips.item1`;
            case 'checkout.orderReview.tips.item2':
                return `checkout.orderReview.tips.item2`;
            case 'checkout.orderReview.tips.item3':
                return `checkout.orderReview.tips.item3`;
            case 'checkout.orderReview.tips.item4':
                return `checkout.orderReview.tips.item4`;
            case 'checkout.preOrder.tips.item1':
                return `checkout.preOrder.tips.item1`;
            case 'checkout.preOrder.tips.item2':
                return `checkout.preOrder.tips.item2`;
            case 'checkout.preOrder.tips.item3':
                return `checkout.preOrder.tips.item3`;
            default: {
                if (item.withoutSuffix) {
                    return item.text;
                }

                return `${item.text}`;
            }
        }
    }, []);

    const getItemTitleKey = useCallback((item: IItem): string | undefined => {
        if (!item.title) {
            return undefined;
        }

        switch (item.title) {
            case 'checkout.delivery.tips.item1.title':
                return `checkout.delivery.tips.item1.title`;
            case 'checkout.delivery.tips.item2.title':
                return `checkout.delivery.tips.item2.title`;
            case 'checkout.delivery.tips.item3.title':
                return `checkout.delivery.tips.item3.title`;
            case 'checkout.delivery.tips.item4.title':
                return `checkout.delivery.tips.item4.title`;
            case 'checkout.confirmation.tips.item1.title':
                return `checkout.confirmation.tips.item1.title`;
            case 'checkout.confirmation.tips.item2.title':
                return `checkout.confirmation.tips.item2.title`;
            case 'checkout.confirmation.tips.item3.title':
                return `checkout.confirmation.tips.item3.title`;
            case 'offers.delivery.tips.item1.title':
                return `offers.delivery.tips.item1.title`;
            case 'offers.delivery.tips.item2.title':
                return `offers.delivery.tips.item2.title`;
            case 'offers.delivery.tips.item3.title':
                return `offers.delivery.tips.item3.title`;
            case 'checkout.myDetails.tips.item1.title':
                return `checkout.myDetails.tips.item1.title`;
            case 'checkout.myDetails.tips.item2.title':
                return `checkout.myDetails.tips.item2.title`;
            case 'checkout.myDetails.tips.item3.title':
                return `checkout.myDetails.tips.item3.title`;
            case 'checkout.myDetails.tips.item4.title':
                return `checkout.myDetails.tips.item4.title`;
            case 'checkout.orderReview.tips.item1.title':
                return `checkout.orderReview.tips.item1.title`;
            case 'checkout.orderReview.tips.item2.title':
                return `checkout.orderReview.tips.item2.title`;
            case 'checkout.orderReview.tips.item3.title':
                return `checkout.orderReview.tips.item3.title`;
            case 'checkout.orderReview.tips.item4.title':
                return `checkout.orderReview.tips.item4.title`;
            case 'checkout.preOrder.tips.item1.title':
                return `checkout.preOrder.tips.item1.title`;
            case 'checkout.preOrder.tips.item2.title':
                return `checkout.preOrder.tips.item2.title`;
            case 'checkout.preOrder.tips.item3.title':
                return `checkout.preOrder.tips.item3.title`;
            default: {
                if (item.withoutSuffix) {
                    return item.title;
                }

                return `${item.title}`;
            }
        }
    }, []);

    const titleKey = React.useMemo(() => {
        switch (title) {
            case 'checkout.delivery.tips.header':
                return `checkout.delivery.tips.header`;
            case 'checkout.myDetails.tips.header':
                return `checkout.myDetails.tips.header`;
            case 'checkout.orderReview.tips.header':
                return `checkout.orderReview.tips.header`;
            case 'checkout.confirmation.tips.header':
                return `checkout.confirmation.tips.header`;
            case 'checkout.preOrder.tips.header':
                return `checkout.preOrder.tips.header`;
            default:
                return `${title}`;
        }
    }, [title]);

    return (
        <div className={className}>
            <div className="title">{t(`${titleKey}.${journeyType}${isStockCar ? '.stock' : ''}`)}</div>
            <div className="content">
                <ul>
                    {items.map((item: IItem, i: number) => (
                        <li key={i} className="item">
                            {item.title && (
                                <p className="item__title">
                                    {t(`${getItemTitleKey(item)}.${journeyType}${isStockCar ? '.stock' : ''}`)}
                                </p>
                            )}
                            {item.text && <p>{t(`${getItemKey(item)}.${journeyType}${isStockCar ? '.stock' : ''}`)}</p>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CheckoutTipsSidebarTemplate;
