import styled from 'styled-components';
import { HeaderStyled } from '../HeaderStyled';
import { breakpoints } from '../../../../styles/theme';
import { rem } from 'polished';

export const HeaderStyledAP = styled(HeaderStyled)`
    .header {
        .logoWrapper {
            span {
                font-size: ${({ theme }) => theme.fontSizes.h5};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                text-transform: uppercase;
            }

            h1 {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                line-height: ${rem(20)};
            }
        }

        .logo {
            svg {
                width: ${rem(50)};
                height: ${rem(60)};
            }

            @media (min-width: ${breakpoints.md}px) {
                svg {
                    width: ${rem(75)};
                    height: ${rem(75)};
                }
            }
        }

        .desktopCenterPart {
            @media (min-width: ${breakpoints.md}px) {
                position: absolute;
                left: 0;
            }
        }

        .desktopRightPart {
            svg {
                g {
                    path {
                        fill: ${({ theme }) => theme.colors.white};
                    }
                }

                path {
                    fill: ${({ theme }) => theme.colors.white};
                }
            }

            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                font-size: ${({ theme }) => theme.fontSizes.textBase};

                .label {
                    margin-top: ${rem(4)};

                    .phone {
                        color: ${({ theme }) => theme.colors.primary};
                    }
                }

                svg {
                    width: ${rem(25)};
                    height: ${rem(25)};

                    @media (min-width: ${breakpoints.md}px) {
                        width: ${rem(30)};
                        height: ${rem(30)};
                    }
                }
            }
        }

        .mobileMenu {
            .navigation {
                svg {
                    g {
                        path {
                            fill: ${({ theme }) => theme.colors.white};
                        }
                    }

                    path {
                        fill: ${({ theme }) => theme.colors.white};
                    }
                }

                .link-wrapper,
                .help,
                .account,
                .basket,
                .pro {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};

                    .label {
                        .phone {
                            color: ${({ theme }) => theme.colors.primary};
                            margin-top: ${rem(4)};
                        }
                    }
                }
            }
        }
    }

    .b2b-switch input:checked + button {
        label {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    .b2b-switch .toggle-inside {
        border-color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.white};
    }

    .switch-vertical label {
        font-size: ${rem(14)};
        color: ${({ theme }) => theme.colors.white};
    }
`;
