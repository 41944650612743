import styled from 'styled-components';
import { ModalPersonalizeOfferStyled } from '../ModalPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalPersonalizeOfferStyledOV = styled(ModalPersonalizeOfferStyled)`
    .section {
        border-color: ${({ theme }) => theme.colors.black2};

        &:last-child {
            border-color: ${({ theme }) => theme.colors.black2};
        }

        &_title {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
        }

        .row {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};

            &_error {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .datePickerSection {
            input {
                width: 100%;
                border: 1px solid ${({ theme }) => theme.colors.grey18};
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
                border-radius: 0;
            }

            .validityError {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .option {
            background: ${({ theme }) => theme.colors.grey10};
            border: 2px solid ${({ theme }) => theme.colors.black2};
            border-radius: 0;

            &_header {
                label {
                    font-size: ${({ theme }) => theme.fontSizes.textMedium};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                svg {
                    margin-bottom: ${rem(10)};
                }
            }
        }

        .mandatory-label {
            color: ${({ theme }) => theme.colors.grey28};
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
        }

        .reset-button {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                margin-top: ${rem(4)};
            }
        }
    }

    .buttons {
        justify-content: center;
    }
`;
