import styled from 'styled-components';
import { ModalDisableCheckoutStyled } from '../ModalDisableCheckoutStyled';
import { rem } from 'polished';

export const ModalDisableCheckoutStyledAC = styled(ModalDisableCheckoutStyled)`
    .title {
        color: ${(props) => props.theme.colors.grey5};
    }
    
    .description {
        color: ${(props) => props.theme.colors.grey5};
        font-size: ${(props) => props.theme.fontSizes.h5};
    }
    
    .input {
        input {
            font-size: ${(props) => props.theme.fontSizes.h5};
            border-radius: ${rem(12)};
        }
        
        button {
            font-size: ${(props) => props.theme.fontSizes.h4};
            background: ${(props) => props.theme.colors.blue2};
            font-weight: ${(props) => props.theme.fontWeights.textBold};
            border-radius: ${rem(24)};
            text-transform: capitalize;
            
            &:hover {
                background: ${(props) => props.theme.colors.grey5};
            }
        }
    }
`;
