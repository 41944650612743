import { rem } from 'polished';
import styled from 'styled-components';
import { css } from 'src/styles/theme';
import { isBrandAC, isBrandDS, isBrandOV, isMarketGB } from 'src/constants/main';

export const TabButton = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.grey17};
    font-size: ${({ theme }) => theme.fontSizes.textSupBase};
    padding: ${rem(10)} ${rem(5)};
    margin: 0 ${rem(10)};

    &.selected,
    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    &.selected {
        border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
    }

    ${isBrandAC &&
    css`
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
        position: relative;

        &.selected,
        &:hover {
            color: ${({ theme }) => theme.colors.red};

            .border {
                width: 100%;
                height: ${rem(4)};
                background: ${({ theme }) => theme.colors.red};
                position: absolute;
                bottom: -${rem(1)};
                left: 0;
                border-top-right-radius: ${rem(12)};
                border-top-left-radius: ${rem(12)};
            }
        }

        &.selected {
            border-bottom: unset;
        }
    `}

    ${isBrandDS &&
    css`
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.h5};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};

        &.selected {
            color: ${({ theme }) => theme.colors.black};
            border-bottom: ${rem(3)} solid ${({ theme }) => theme.colors.secondary};
        }
    `}
  
  ${!isMarketGB &&
    isBrandOV &&
    css`
        font-size: ${({ theme }) => theme.fontSizes.h5};
        color: ${({ theme }) => theme.colors.black2};

        &.selected,
        &:hover {
            color: ${({ theme }) => theme.colors.primaryHover};
        }

        &.selected {
            border-bottom: 4px solid ${({ theme }) => theme.colors.primaryHover};
        }
    `}
  
  ${isMarketGB &&
    isBrandOV &&
    css`
        font-size: ${({ theme }) => theme.fontSizes.h5};
        color: ${({ theme }) => theme.colors.black};
    `}
`;
