import React, {FC, useCallback, useContext, useState} from 'react';
import {ACTIONS, CheckoutDealerDispatch} from '..';
import {IDealer} from '../../../interfaces/Dealer';
import useTranslations from '@hooks/useTranslations';
import CheckoutDealerItem from './CheckoutDealerItem';
import {useRouter} from 'next/router';
import routes from '../../../constants/routes';
import {
    CheckoutModalDealerDispatch,
    IModalDealerSearchState,
} from '@components/Modal/ModalDealerSearch/ModalDealerSearchTemplate';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import {useDispatch} from 'react-redux';
import {ModalVersion} from '../../../constants/main';

interface CheckoutDealerListProps {
    dealers: IDealer[];
    moveToShareDealerCodeModal?: boolean;
    previousState?: IModalDealerSearchState;
}

const CheckoutDealerList: FC<CheckoutDealerListProps> = ({ dealers, moveToShareDealerCodeModal = false, previousState = {} }) => {
    const { t } = useTranslations();
    const router = useRouter();
    const dispatch = useContext((router.pathname.includes(routes.BASKET) || router.pathname.includes(routes.SUMMARY))
        ? CheckoutModalDealerDispatch
        : CheckoutDealerDispatch
    );
    const rootDispatch = useDispatch();
    const [selectedDealerId, setSelectedDealerId] = useState<string>();

    const handleSelectedDealer = useCallback(
        (selectedId: string) => {
            const dealer = dealers.find((dealer: IDealer) => dealer.externalId === selectedId);
            dispatch({ type: ACTIONS.SET_SELECTED_DEALER, payload: dealer });
            setSelectedDealerId(selectedId);
            if (moveToShareDealerCodeModal) {
                rootDispatch(
                    UIDuck.openModal({
                        modalType: UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_DEALER_CODE,
                        data: {
                            previousState: { ...previousState, selectedDealerId: selectedId },
                        },
                        modalVersion: ModalVersion.v2,
                    })
                );
            }
        },
        [dealers, dispatch]
    );

    return (
        <div className="dealerList">
            <p>{t('checkout.delivery.dealer.list.title')}</p>
            <div className="dealerList__scrollable">
                {dealers.map((dealer, i) => (
                    <CheckoutDealerItem
                        key={dealer.externalId}
                        name={dealer.name}
                        streetName={dealer.streetName}
                        city={dealer.city}
                        postCode={dealer.postCode}
                        distance={dealer.distance}
                        externalId={dealer.externalId}
                        selectedDealerId={selectedDealerId}
                        handleSelectedDealer={handleSelectedDealer}
                        identifier={i}
                    />
                ))}
            </div>
        </div>
    );
};
export default CheckoutDealerList;
