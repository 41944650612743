import styled from 'styled-components';
import { PromoCodeStyled } from '../PromoCodeStyled';
import { rem } from 'polished';
import { IPromoCode } from '../index';

export const PromoCodeStyledAC = styled(PromoCodeStyled)`
    .promoCode {
        &__title {
            ${prop =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          color: '#3b3938',
                          background: 'rgb(241,239,239)',
                          padding: '1rem 1.5rem',
                          'text-transform': 'uppercase',
                      }
                    : {
                          background: 'unset',
                      }};
        }

        &__content {
            ${prop =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          padding: '1rem 1.5rem',
                          color: '#3b3938',
                      }
                    : {
                          padding: '10px 0',
                      }};
        }
    }
`;
