import React from 'react';
import useTranslations from '@hooks/useTranslations';
import { useDispatch } from 'react-redux';
import { Form } from '@components/ShareConfigurationBox/Form';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useFeatureSwitch } from '@hooks/useFeatureSwitch';
import { Link } from '@components/v2/link';
import { ModalVersion } from '../../../constants/main';

interface IModalShareOfferSummary {
    className: string;
    page?: any;
}

const ModalShareOfferSummaryTemplate: React.FC<IModalShareOfferSummary> = ({ className, page = 'summary' }) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();
    const { isStockDealerShareConfigurationEnabled, isMTODealerShareConfigurationEnabled } = useFeatureSwitch();
    const { isStockJourney } = useCarStockJourneyCheck();

    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
    const onClick = () =>
        dispatch(
            UIDuck.openModal({
                data: {
                    page,
                },
                modalType: UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_DEALER_CODE,
                modalVersion: ModalVersion.v2,
            })
        );

    return (
        <div className={className}>
            <div className="description">{t('shareConfiguration.getEmailModalDescription')}</div>
            <div className="wrapper">
                <Form page={page} />
                {((isStockJourney && isStockDealerShareConfigurationEnabled) ||
                    (!isStockJourney && isMTODealerShareConfigurationEnabled)) && (
                    <Link
                        isUnderline
                        className="linkButton"
                        txt={t('shareConfiguration.dealerShareFeature')}
                        onClick={onClick}
                    >
                        <button className="dealerShareFeature" onClick={onClick}>
                            {t('shareConfiguration.dealerShareFeature')}
                        </button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default ModalShareOfferSummaryTemplate;
