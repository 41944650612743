import { css } from 'src/styles/theme';
import { rem } from 'polished';

export const StyledDS = css`
    .description {
        font-size: ${rem(16)};
        color: black;
    }
    .save,
    .share {
        svg path {
            fill: ${({ theme }) => theme.colors.black} !important;
        }
    }

    .share {
        .form {
            .emailInputComponent {
                background: ${({ theme }) => theme.colors.white};
            }

            input {
                display: block;
                margin: ${rem(16)} 0;
                border-radius: 4px;
                border: 1px solid ${({ theme }) => theme.colors.grey5};
                height: ${rem(48)};
                padding: ${rem(15)};
                width: 100%;
            }

            .error {
                font-size: ${rem(12)};
                margin-top: -${rem(10)};
                margin-bottom: ${rem(10)};
                display: block;
                color: ${({ theme }) => theme.colors.danger};
            }
        }
    }

    .save {
        .icon.icon-wrapper {
            svg {
                width: ${rem('40px')};
            }
        }

        .title {
            font-size: ${rem(20)};
            font-weight: bold;
        }

        .link {
            font-size: ${rem(16)};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            margin-top: ${rem(15)};

            button,
            a {
                color: ${({ theme }) => theme.colors.black};
                display: flex;

                .icon-wrapper {
                    margin-top: -1px;

                    svg {
                        width: ${rem(5)};
                        margin-left: ${rem(10)};
                        path {
                            fill: ${({ theme }) => theme.colors.black};
                        }
                    }
                }

                &:hover {
                    color: ${({ theme }) => theme.colors.primary};

                    svg path {
                        fill: ${({ theme }) => theme.colors.primary} !important;
                    }
                }
            }
        }

        .label {
            font-size: ${rem(16)};
        }
    }
`;
