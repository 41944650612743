import { MODEL_UNIQUENESS } from 'src/constants/main';

export const validUnits = ['bhp', 'l/100km', 'g/km', 'kW', 'kWh', 'Wh'];

/**
 * Function compares two strings and returns the differences from second parameter string
 * e.g.: twoStringDifference('Spacetourer Business', 'Spacetourer Business Lounge') => 'Lounge'
 * e.g.: twoStringDifference('Berlingo', 'Taille M') => 'Taille M'
 * @param original
 * @param toBeCompared
 */
export const twoStringDifference: (original: string, toBeCompared: string) => string = (
    original: string,
    toBeCompared: string
) => toBeCompared.split(original).join('');

export const getBodyStyleDescription = (modelTitle: string, grBodyStyleDescription: string, modelId: string) => {
    if (MODEL_UNIQUENESS.includes(modelId)) {
        return '';
    }

    return !grBodyStyleDescription.includes(modelTitle) ? grBodyStyleDescription.trim() + ' ' : '';
};

export const getModelTitle = (carDetails: any, shouldIncludeBodyStyleDescription: boolean = true) => {
    if (carDetails && carDetails.model) {
        if (!shouldIncludeBodyStyleDescription) {
            return carDetails.model.description;
        }

        const grBodyStyle = carDetails?.extraFields?.grBodyStyle || carDetails?.grBodyStyle;
        const bodyStyleDescription = getBodyStyleDescription(
            carDetails.model.description,
            grBodyStyle?.description ?? '',
            carDetails.model.id
        );

        return `${carDetails.model.description}${' '}${bodyStyleDescription && bodyStyleDescription}`;
    } else {
        return ``;
    }
};

export const getTrimContent = (carDetails: any) => {
    return carDetails?.extraFields?.trimContent || {};
};

export const replaceRange = (input: string, startIndex: number, endIndex: number, substitute: string) =>
    input.substring(0, startIndex) + substitute + input.substring(endIndex);

const formatUnit = (input: string, unit: string) => {
    const searchRegex = new RegExp(`\\b${unit.toLowerCase()}\\b`, 'g');
    const results = input.toLowerCase().matchAll(searchRegex);

    return Array.from(results).reduce(
        (formattedInput, result) => replaceRange(formattedInput, result.index, result.index + unit.length, unit),
        input
    );
};

export const formatUnits = (input: string) =>
    validUnits.reduce((formattedInput, unit) => formatUnit(formattedInput, unit), input);
