import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { ModalStyled } from '../ModalStyled';
import { rem } from 'polished';

export const ModalStyledOV = styled(ModalStyled)`
    .modalSidebar {
        &__wrap {
            background: ${({ theme }) => theme.colors.pureWhite};

            &__header {
                background: ${({ theme }) => theme.colors.pureWhite};
                font-size: ${rem(26)};

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(30)};
                }
            }
        }
    }  
  
    .modalWindow__wrap {
        &__header__title {
            padding-top: ${rem(20)};
            font-size: ${rem(36)};
            line-height: 1.3;
            color: ${({ theme }) => theme.colors.black2};
        }

        &__header__closeBtn {
            position: relative;
            color: ${({ theme }) => theme.colors.black2};
            left: ${rem('-10px')};
            font-size: 30px;
        }
        &.finance-legal {
            .button-container {
                button {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }
    }

    .form-field {
        font-size: ${rem(14)};

        p {
            span {
                font {
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-size: ${rem(14)};
                }
            }
        }

        h4 {
            font-size: ${rem(21)};
        }
    }

    .modalFinancialSummary {
        &--description {
            font-size: ${rem(14)} !important;
        }

        &--title {
            font-size: ${rem(18)};
            color: ${({ theme }) => theme.colors.grey5};
      } 
    }

    .finance-summary--col-1 {
        font-size: ${rem(14)};
        padding: ${rem(10)} 0;
    }

    .finance-summary--col-2,
    .finance-summary--col-3 {
        font-size: ${rem(14)};
    }

    .financialSummary__legalText {
        color: ${({ theme }) => theme.colors.black};
    }

    .btn-primary {
        padding: ${rem(5)} 0;
        color: ${({ theme }) => theme.colors.black2};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-size: ${rem(16)};

        &:hover {
            transition: all 0.3s ease;
            background: ${({ theme }) => theme.colors.black2};
            color: ${({ theme }) => theme.colors.pureWhite};
        }
    }
`;
