import React, { FC, memo, useCallback } from 'react';
import { IDealer } from '../../../interfaces/Dealer';
import useTranslations from '@hooks/useTranslations';
import Button from '../../Button';
import { isBrandOV } from '../../../constants/main';

interface CheckoutDealerItemProps extends IDealer {
    handleSelectedDealer: (externalId: string) => void;
    selectedDealerId: string;
    identifier: number;
}

const CheckoutDealerItem: FC<CheckoutDealerItemProps> = memo(function DealerItem({
    name,
    streetName,
    city,
    postCode,
    distance,
    externalId,
    handleSelectedDealer,
    selectedDealerId,
    identifier,
}) {
    const { t } = useTranslations();

    const selectDealer = useCallback(() => {
        handleSelectedDealer(externalId);
    }, [externalId, handleSelectedDealer]);

    return (
        <div className="dealerItem">
            <div className="dealerItem__header">
                <div className="dealerItem__icon">{identifier + 1}</div>
                <div className="dealerItem__address" data-testid="TESTING_DEALER_ADDRESS">
                    <p data-testid="TESTING_DEALER_ADDRESS_NAME">{name}</p>
                    <p data-testid="TESTING_DEALER_ADDRESS_STREET">{streetName}</p>
                    <p data-testid="TESTING_DEALER_ADDRESS_CITY">{city}</p>
                    <p data-testid="TESTING_DEALER_ADDRESS_POSTCODE">{postCode}</p>
                </div>
            </div>
            <div className="dealerItem__footer">
                <Button
                    className={`dealerItem__button ${externalId === selectedDealerId && 'btnSelected'}`}
                    onClick={selectDealer}
                    data-testid={`TESTING_DEALER_ITEM_${identifier}`}
                >
                    {externalId === selectedDealerId ? t('configurator.selected') : t('configurator.select')}
                </Button>
                <div className="dealerItem__distance">
                    <p>
                        {(distance / 1000).toFixed(2)} {t('checkout.delivery.dealer.item.distance')}
                    </p>
                    <p>{t('checkout.delivery.dealer.item.distance.away')}</p>
                </div>
            </div>
        </div>
    );
});

export default CheckoutDealerItem;
