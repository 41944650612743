import React, { FC, memo } from 'react';
import { HandledComponentError } from '@components/ErrorHandling';
import { useTranslations } from '@hooks/useTranslations';

export const FetchErrorMessage: FC = memo(() => {
    const { t } = useTranslations();
    try {
        return (
            <span className="error">{t('shareConfiguration.apiCallError')}</span>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});

FetchErrorMessage.displayName = 'FetchErrorMessage';
