import styled from 'styled-components';
import { ModalTrimStyled } from '../ModalTrimStyled';
import { rem } from 'polished';

export const ModalTrimStyledAC = styled(ModalTrimStyled)`
    .trims {
        .title {
            font-size: ${rem(30)};
            color: ${({ theme }) => theme.colors.grey5};
        }

        .sections {
            .trim {
                .trimName {
                    font-size: ${({ theme }) => theme.fontSizes.h3};
                    color: ${({ theme }) => theme.colors.grey5};
                }

                .trimLabel,
                .trimPrice {
                    color: ${({ theme }) => theme.colors.grey5};
                }

                .trimDescription {
                    color: ${({ theme }) => theme.colors.grey1};
                }
            }
        }
    }

    .keys {
        .label {
            font-size: ${rem(22)};
            color: ${({ theme }) => theme.colors.grey5};
        }

        .description {
            color: ${({ theme }) => theme.colors.grey5};
        }
    }

    .keyFeatures,
    .enginesAvailable,
    .stylesAvailable,
    .colors,
    .interiorsAvailable,
    .featuresAvailable {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            color: ${({ theme }) => theme.colors.grey5};
        }

        .sections {
            .engines,
            .styles,
            .interiors,
            .features {
                .engine,
                .style,
                .interior,
                .feature {
                    color: ${({ theme }) => theme.colors.grey1};

                    .details {
                        color: ${({ theme }) => theme.colors.grey5};
                    }
                }
            }
        }
    }

    .colors {
        .configurations {
            button {
                &.selected,
                &:hover {
                    box-shadow: 0px 0px 0px 0.1rem ${({ theme }) => theme.colors.blue2};
                }
            }
        }
    }

    .options {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }

        .filter {
            button {
                border-radius: ${rem(12)};
                border: 2px solid ${({ theme }) => theme.colors.grey5};
                color: ${({ theme }) => theme.colors.grey5};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};

                &.selected,
                &:hover {
                    border: 2px solid ${({ theme }) => theme.colors.blue2};
                    color: ${({ theme }) => theme.colors.blue2};
                    background: rgba(87, 113, 138, 0.2);
                }
            }
        }
    }

    .availability {
        font-size: ${({ theme }) => theme.fontSizes.h6};

        .icon {
            &.optional {
                background: ${({ theme }) => theme.colors.white};
                border-color: ${({ theme }) => theme.colors.black};
            }
        }
    }

    .categories {
        border-top: 1px solid ${({ theme }) => theme.colors.grey5};

        .desktop {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
        }
    }
`;
