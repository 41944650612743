import React from 'react';
import { PartExchangeStyled } from './PartExchangeStyled';
import { SC } from '../../styles/theme';
import { IPXSummary } from '../../interfaces/Car';

export interface PartExchangeSummaryProps extends SC {
    pxData: IPXSummary;
    isCashJourney?: boolean;
}

export const DealerPartExchangeSummary = ({ pxData, isCashJourney }: PartExchangeSummaryProps) => {
    return <PartExchangeStyled pxData={pxData} isCashJourney={isCashJourney} />;
};

export default DealerPartExchangeSummary;
