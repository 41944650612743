import styled from 'styled-components';
import { LinkStyled } from '../../LinkStyled';
import { css } from '../../../../styles/theme';
import { rem } from 'polished';

export const LinkStyledOV = styled(LinkStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h5};

    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.black2};
            text-transform: uppercase;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black2};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            color: ${({ theme }) => theme.colors.black2};
            text-decoration: underline;

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        `}

    svg {
        width: ${rem(10)};
        height: ${rem(10)};
    }
`;
