import styled from 'styled-components';
import { GeoLocationInputStyled } from '../../GeoLocationInputStyled';

export const GeoLocationInputStyledOV = styled(GeoLocationInputStyled)`
    .geolocation-input--container {
        .geolocation-input--textfield-container {
            .dropDown ul li {
                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.grey16};
                }
            }
        }
    }
`;
