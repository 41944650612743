import styled from 'styled-components';
import { rem } from 'polished';
import { ModalIncompletePxTemplate } from './ModalIncompletePxTemplate';

export const ModalIncompletePxStyled = styled(ModalIncompletePxTemplate)`
    .description {
        margin-bottom: ${rem(35)};
    }

    .button {
        width: 100%;
        display: flex;
        gap: ${rem(15)};
        justify-content: center;
    }
`;
