import Input from '../../../../ui/Input';
import React, { useEffect, useState } from 'react';
import Icon, { Icons } from '@components/Icon';
import { SC } from '../../../../styles/theme';
// @ts-ignore
import DatePicker from 'react-datepicker';
import useTranslations from '@hooks/useTranslations';
import { useIgnorePXBonus } from '../../../../partExchange/hooks';
import { getDateFormatByLang } from '@utils/Date.utils';
import moment from 'moment/moment';
import { isMarketGB } from '../../../../constants/main';

interface ITradeIn extends SC {
    px: {
        vehicle: {
            makeName: string;
            modelName: string;
            vin: string;
            overallMilleage: number;
            regNumber?: string;
            registrationDate: Date;
        };
        prices: {
            dealerValuation: number;
            dealerBonusAmount: number;
        };
        isActive: boolean;
    };
    setPx: any;
    hasRequiredError: boolean;
}

export const VIN_REQUIRED_LENGTH = 17;
export const OVERALL_MILLEAGE_MAX_LENGTH = 6;

export const TradeInTemplate = ({ px, setPx, hasRequiredError }: ITradeIn) => {
    const { t } = useTranslations();

    const { shouldIgnorePXBonus } = useIgnorePXBonus();

    const [registrationDate, setRegistrationDate] = useState(
        px?.vehicle?.registrationDate ? new Date(px?.vehicle?.registrationDate) : null
    );

    const isNumber = (value: string | number) => /^[0-9]+$/.test(String(value));

    return (
        <div>
            <div className="row without-padding-top without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.tradeIn.input.brand.label')}
                    value={px?.vehicle?.makeName || ''}
                    onChange={(value: string) => {
                        setPx({ ...px, vehicle: { ...px?.vehicle, makeName: value } });
                    }}
                    placeholder={t('modal.personalizeOffer.tradeIn.input.brand.placeholder')}
                    marginTop={20}
                    hasError={hasRequiredError && !px?.vehicle?.makeName}
                />
                {hasRequiredError && !px?.vehicle?.makeName && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.brand.error.required')}</div>
                )}
            </div>
            <div className="row without-padding-top without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.tradeIn.input.model.label')}
                    value={px?.vehicle?.modelName || ''}
                    onChange={(value: string) => {
                        setPx({ ...px, vehicle: { ...px?.vehicle, modelName: value } });
                    }}
                    placeholder={t('modal.personalizeOffer.tradeIn.input.model.placeholder')}
                    marginTop={20}
                    hasError={hasRequiredError && !px?.vehicle?.modelName}
                />
                {hasRequiredError && !px?.vehicle?.modelName && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.model.error.required')}</div>
                )}
            </div>
            <div className="row without-padding-top without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.tradeIn.input.vin.label')}
                    value={px?.vehicle?.vin || ''}
                    placeholder={t('modal.personalizeOffer.tradeIn.input.vin.placeholder')}
                    defaultValue={px?.vehicle?.vin}
                    onChange={(value: string) => {
                        setPx({ ...px, vehicle: { ...px?.vehicle, vin: value } });
                    }}
                    hasError={
                        px?.vehicle?.vin &&
                        (px?.vehicle?.vin?.length !== VIN_REQUIRED_LENGTH ||
                            !px?.vehicle?.vin?.match(/^[a-zA-Z0-9]+$/) ||
                            (hasRequiredError && !px?.vehicle?.vin))
                    }
                    marginTop={20}
                />
                {px?.vehicle?.vin && !px?.vehicle?.vin?.match(/^[a-zA-Z0-9]+$/) && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.vin.error.alphanumericError')}</div>
                )}
                {px?.vehicle?.vin &&
                    px?.vehicle?.vin?.match(/^[a-zA-Z0-9]+$/) &&
                    px?.vehicle?.vin?.length !== VIN_REQUIRED_LENGTH && (
                        <div className="row_error">
                            {t('modal.personalizeOffer.tradeIn.input.vin.error', { length: VIN_REQUIRED_LENGTH || '' })}
                        </div>
                    )}
                {hasRequiredError && !px?.vehicle?.vin && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.vin.error.required')}</div>
                )}
            </div>
            <div className="row without-padding-top without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.tradeIn.input.mileage.label')}
                    placeholder={t('modal.personalizeOffer.tradeIn.input.mileage.placeholder')}
                    value={px?.vehicle?.overallMilleage || ''}
                    onChange={(value: number) => {
                        setPx({ ...px, vehicle: { ...px?.vehicle, overallMilleage: value } });
                    }}
                    hasError={
                        px?.vehicle?.overallMilleage &&
                        (!isNumber(px?.vehicle?.overallMilleage) ||
                            String(px?.vehicle?.overallMilleage)?.length > OVERALL_MILLEAGE_MAX_LENGTH)
                    }
                    marginTop={20}
                />
                {px?.vehicle?.overallMilleage && !isNumber(px?.vehicle?.overallMilleage) && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.mileage.error')}</div>
                )}
                {px?.vehicle?.overallMilleage &&
                    isNumber(px?.vehicle?.overallMilleage) &&
                    String(px?.vehicle?.overallMilleage)?.length > OVERALL_MILLEAGE_MAX_LENGTH && (
                        <div className="row_error">
                            {t('modal.personalizeOffer.tradeIn.input.mileage.maxlengthError', {
                                length: OVERALL_MILLEAGE_MAX_LENGTH || '',
                            })}
                        </div>
                    )}
                {hasRequiredError && !px?.vehicle?.overallMilleage && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.mileage.error.required')}</div>
                )}
            </div>
            <div className="row without-padding-top without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.tradeIn.input.numberPlate.label')}
                    placeholder={t('modal.personalizeOffer.tradeIn.input.numberPlate.placeholder')}
                    value={px?.vehicle?.regNumber || ''}
                    onChange={(value: number) => {
                        setPx({ ...px, vehicle: { ...px?.vehicle, regNumber: value } });
                    }}
                    marginTop={20}
                />
            </div>
            <div className="row without-flex without-padding-bottom">
                <span className="input-section-label">
                    {t('modal.personalizeOffer.tradeIn.input.registrationDate.label')}
                </span>
                <div className={`datePickerSection ${hasRequiredError && !registrationDate ? 'error' : ''}`}>
                    <DatePicker
                        selected={registrationDate}
                        onChange={(date: Date) => {
                            setRegistrationDate(date);
                            setPx({
                                ...px,
                                vehicle: {
                                    ...px?.vehicle,
                                    registrationDate: moment(date).endOf('day').format('YYYY-MM-DD'),
                                },
                            });
                        }}
                        showYearDropdown
                        dateFormat={getDateFormatByLang()}
                        placeholderText={t('dateFormat.default')}
                        calendarStartDay={isMarketGB ? 0 : 1}
                    />
                    {hasRequiredError && !registrationDate && (
                        <span className="validityError">
                            {t('modal.personalizeOffer.tradeIn.input.registrationDate.error')}
                        </span>
                    )}
                </div>
            </div>
            <div className="row without-padding-top without-padding-bottom">
                <Input
                    label={t('modal.personalizeOffer.tradeIn.input.value.label')}
                    tooltip={t('modal.personalizeOffer.tradeIn.input.value.tooltip')}
                    value={px?.prices?.dealerValuation || ''}
                    onChange={(value: number) => {
                        setPx({ ...px, prices: { ...px?.prices, dealerValuation: value } });
                    }}
                    placeholder={t('modal.personalizeOffer.tradeIn.input.value.placeholder')}
                    hasError={
                        (px?.prices?.dealerValuation && !isNumber(px?.prices?.dealerValuation)) ||
                        (hasRequiredError && !px?.prices?.dealerValuation)
                    }
                    marginTop={15}
                />
                {px?.prices?.dealerValuation && !isNumber(px?.prices?.dealerValuation) && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.error')}</div>
                )}
                {hasRequiredError && !px?.prices?.dealerValuation && (
                    <div className="row_error">{t('modal.personalizeOffer.tradeIn.input.error.required')}</div>
                )}
            </div>
            {!shouldIgnorePXBonus && (
                <div className="row without-padding-top without-padding-bottom">
                    <Input
                        label={t('modal.personalizeOffer.tradeIn.bonus.value.label')}
                        tooltip={t('modal.personalizeOffer.tradeIn.bonus.value.tooltip')}
                        value={px?.prices?.dealerBonusAmount || ''}
                        onChange={(value: number) => {
                            setPx({ ...px, prices: { ...px?.prices, dealerBonusAmount: value } });
                        }}
                        placeholder={t('modal.personalizeOffer.tradeIn.bonus.value.placeholder')}
                        hasError={px?.prices?.dealerBonusAmount && !isNumber(px?.prices?.dealerBonusAmount)}
                        marginTop={15}
                    />
                    {px?.prices?.dealerBonusAmount && !isNumber(px?.prices?.dealerBonusAmount) && (
                        <div className="row_error">{t('modal.personalizeOffer.tradeIn.bonus.error')}</div>
                    )}
                </div>
            )}
            <span className="mandatory-label">{t('modal.personalizeOffer.tradeIn.mandatory')}</span>
            <button
                onClick={() => {
                    setPx(null);
                    setRegistrationDate(null);
                }}
                className="reset-button"
            >
                <Icon name={Icons.Trash} />
                <span>{t('modal.personalizeOffer.tradeIn.reset')}</span>
            </button>
        </div>
    );
};
