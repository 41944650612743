type TGa = { getAll: () => string[] };

export const gaCrossDomainParameter = (urlString: string): string => {
    try {
        const ga = window[(window as any)['GoogleAnalyticsObject']];
        let tracker;
        if (ga && typeof ((ga as unknown) as TGa).getAll === 'function') {
            tracker = ((ga as unknown) as TGa).getAll()[0];
            return new (window as any).gaplugins.Linker(tracker).decorate(urlString);
        }
    } catch (error: any) {
        console.error('gaCrossDomainParameter function failed!', error);
    }

    return urlString;
};
