import { BRAND, isBrandOV, isMarketGB } from 'src/constants/main';
import ReactImageFallback from 'react-image-fallback';
import React from 'react';
import Icon, { Icons } from '@components/Icon';
import { IconTemplate } from '@components/Icon/IconTemplate';
import useTranslations from '@hooks/useTranslations';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';

export interface ICarOptionDetail {
    category?: string;
    title?: string;
    image?: string;
    description?: string;
    className: string;
    upsell?: boolean;
    upsellOrder?: number;
    id?: string;
    tires?: any[];
    tiresSubtitle?: string;
    tiresDescription?: string;
    withBackButton?: boolean;
    onClose: any;
}

const ModalCarOptionDetailTemplate = ({
    category,
    title,
    image,
    description,
    tiresSubtitle,
    tiresDescription,
    withBackButton,
    tires,
    onClose,
    className,
}: ICarOptionDetail) => {
    const { t } = useTranslations();

    return (
        <div className={className}>
            <button className="option-detail__closeBtn" onClick={() => onClose()} data-testid="TESTING_CLOSE_MODAL">
                &#10005;
            </button>
            {category && (
                <span className="option-detail__category">
                    {withBackButton && (
                        <Icon
                            height={25}
                            name={Icons.chevronLeftV2}
                            onClick={onClose}
                            className="option-detail__backBtn"
                        />
                    )}{' '}
                    {category}
                </span>
            )}
            {title && <p className="option-detail__title">{title}</p>}
            {image && <ReactImageFallback className="option-detail__image" src={image} fallbackImage="" />}
            {description && description}
            {tires?.length > 0 && (
                <div className="tires-detail">
                    <span className="tires-detail__title">{tiresSubtitle}</span>
                    <p className="tires-detail__description">{tiresDescription}</p>
                    {tires.length &&
                        tires.map((tire, i) => {
                            return (
                                <div key={i} className="tires-detail__image">
                                    <ReactImageFallback src={tire.urlThumbnail} fallbackImage="" />
                                    <a
                                        href={tire.urlWeb}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="tires-detail__link"
                                    >
                                        {t('technicalFeatures.tiresDetailModal.linkText')}{' '}
                                        <IconTemplate name={Icons.chevronRightOutlined} />
                                    </a>
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default ModalCarOptionDetailTemplate;
