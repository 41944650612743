import styled from 'styled-components';
import { LinkStyled } from '../LinkStyled';
import { css } from '../../../styles/theme';
import { rem } from 'polished';

export const LinkStyledDS = styled(LinkStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h5};

    ${({ primary }) =>
        primary &&
        css`
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            color: ${({ theme }) => theme.colors.black1};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black1};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            color: ${({ theme }) => theme.colors.black};
            text-decoration: underline;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black1};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        `}

    svg {
        width: ${rem(12)};
        height: ${rem(12)};
    }
`;
