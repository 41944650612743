import { IOffer, IOfferItemWithIndex } from '../interfaces/IOfferItem';
import { IVehicleTransformed } from '../services/stock/models/stockItems.service.model';

export enum EType {
    clo = 'clo',
    mlo = 'mlo',
    homepage = 'homepage',
    list = 'list',
}

export enum EContextPage {
    home = 'home',
    trim = 'trim',
    config = 'config',
    basket = 'basket',
}

export const findPromoByPage = (offers: IOffer[], page: EContextPage) => {
    return offers
        ?.sort((a: IOffer, b: IOffer) => (a.priority > b.priority ? -1 : 1))
        ?.find((offer: IOffer) => offer?.promo)?.promo?.[page as unknown as keyof IOffer];
};

export const findPromoByPageAndType = (offers: (IOffer & { type: EType })[], page: EContextPage, isStock = false) => {
    return findPromoByPage(
        offers?.filter(({ type, campaignType }) => {
            if (isStock) {
                return (type || campaignType) === (page === EContextPage.home ? EType.homepage : EType.list);
            }
            return (type || campaignType) === (page === EContextPage.home ? EType.mlo : EType.clo);
        }),
        page
    );
};

export const hasPromoCodeOffersList = (
    list: IOfferItemWithIndex[] | IVehicleTransformed[],
    index: number,
    page = EContextPage.home
) => {
    switch (true) {
        case index % 3 === 0: {
            return Boolean(
                findPromoByPage(list[index + 1]?.offers, page) || findPromoByPage(list[index + 2]?.offers, page)
            );
        }
        case (index + 4) % 3 === 0: {
            return Boolean(
                findPromoByPage(list[index - 1]?.offers, page) || findPromoByPage(list[index - 2]?.offers, page)
            );
        }

        default:
            return Boolean(
                findPromoByPage(list[index + 1]?.offers, page) || findPromoByPage(list[index - 1]?.offers, page)
            );
    }
};
