import React from 'react';

import { CheckboxStyledAC } from './AC/CheckboxStyled.AC';
import { CheckboxStyledOV } from './OV/CheckboxStyled.OV';
import { CheckboxStyledOVGB } from './OV/GB/CheckboxStyled.OV';
import { CheckboxStyledAP } from './AP/CheckboxStyled.AP';
import { CheckboxStyledUKDS } from './DS/CheckboxStyledUK-DS';
import { CheckboxStyled } from './CheckboxStyled';
import { SC } from '../../styles/theme';
import { BRAND, MARKET, MARKETS } from '../../constants/main';
import { BrandTypes } from '../../types/brands';
import { CheckboxStyledFRDS } from './DS/CheckboxStyledFR-DS';

export interface CheckboxProps extends SC {
    name: string;
    disabled?: boolean;
    checked: boolean;
    error?: boolean;
    errorMessage?: string;
    dataTestId?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    withoutClick?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
    if (BRAND === BrandTypes.OV && MARKET === MARKETS.GB) return <CheckboxStyledOVGB {...props} />;
    if (BRAND === BrandTypes.OV) return <CheckboxStyledOV {...props} />;
    if (BRAND === BrandTypes.AP) return <CheckboxStyledAP {...props} />;
    if (BRAND === BrandTypes.DS && MARKET === MARKETS.FR) return <CheckboxStyledFRDS {...props} />;
    if (BRAND === BrandTypes.DS) return <CheckboxStyledUKDS {...props} />;
    if (BRAND === BrandTypes.AC) return <CheckboxStyledAC {...props} />;
    return <CheckboxStyled {...props} />;
};

export default Checkbox;
