import React, { useCallback } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalSave } from '../shared/types';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { SaveToAccountButton } from 'src/components/SaveToCTA/SaveToAccountButton';
import { useFeatureSwitch } from '@hooks/useFeatureSwitch';
import { Form, SubmitProps } from '@components/ShareConfigurationBox/Form';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { isBrandDS } from 'src/constants/main';
import { Pages } from 'src/types/pages';
import { ModalVersion } from 'src/constants/main';
import { useDispatch, useSelector } from 'react-redux';
import sessionInfoApi from 'src/services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useRouter } from 'next/router';
import Routes from '../../../../constants/routes';
import { useExperiment } from '@hooks/useExperiment';

export const ModalSaveYourProjectTemplate = ({ className }: IModalSave) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();
    const { carDetails } = useCarDetailsDuck();
    const router = useRouter();
    const { isShareConfigurationEnabled, isSaveBasketCTADisabled } = useFeatureSwitch();
    const isDealSaved = useSelector((state) => UIDuck.getOwnState(state).isDealSaved);
    const { pushTrigger, getPageNameByUrl } = useExperiment();

    const handleSubmit = useCallback(async ({ email, setFetchError, setIsSuccess, setIsFetching }: SubmitProps) => {
        try {
            const res = router?.pathname?.includes(Routes.CAR)
                ? await sessionInfoApi.shareConfigurationEmailDeepLink(
                      carDetails.externalId,
                      email,
                      getSessionIdCookie()
                  )
                : await sessionInfoApi.shareConfigurationEmail(email, getSessionIdCookie());
            setFetchError(false);

            if (res?.data?.success) {
                dispatch(
                    UIDuck.openModal({
                        modalType: UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_BASKET,
                        data: {
                            mopId: res?.data?.mopId,
                            email: email,
                        },
                        modalVersion: ModalVersion.v2,
                    })
                );
                pushTrigger('share', getPageNameByUrl(), 'share-send');
                setIsSuccess(true);
            } else {
                setFetchError(true);
            }
        } catch (e: unknown) {
            setFetchError(true);
        }

        setIsFetching(false);
    }, []);

    return (
        <div className={className}>
            <p className="description">{t('modal.save.description')}</p>
            {isShareConfigurationEnabled && (
                <div className="share">
                    <IconTemplate className="icon" name={Icons.Mail} />
                    <div className="title">{t('shareConfiguration.getEmail')}</div>
                    <div className="label">{t('shareConfiguration.getEmailModalDescription')}</div>
                    <Form page={Pages.basketV2} submit={handleSubmit} />
                </div>
            )}
            {!isSaveBasketCTADisabled && (
                <div className="save">
                    <IconTemplate className="icon" name={isBrandDS ? Icons.Account : Icons.Profile} />
                    <div className="title">{t('save.title')}</div>
                    <div className="label">{t('save.description')}</div>
                    {(router.query?.saved || isDealSaved) && <div className="saved">{t('save.alreadySaved')}</div>}
                    <div className="link">
                        <SaveToAccountButton link />
                    </div>
                </div>
            )}
        </div>
    );
};
