import { PriceRounding } from './../../../types/priceRounding';
export enum PriceFormat {
    DEFAULT_EUR = '€# ### ###,##',
}

export interface IMonthlyPrice {
    depositAmount: number;
    priceFormat: PriceFormat;
    apr: number;
    amount: number;
    deposit: number;
    lengthOfTerm: number;
    financeProductId: string;
    mileage: number;
    amountRounding: PriceRounding;
}

interface IPriceExtras {
    employeeDiscount?: number;
    salesDiscount?: number;
    extraDiscount?: number;
}

export enum Currencies {
    EUR = 'EUR',
}

export enum StockFormatedPriceType {
    LCDV_CATALOG = 'LCDV_Catalog',
    LCDV_MRIP = 'LCDV_Mrip',
    LCDV_EMPLOYEE = 'LCDV_Employee',
    CATALOG = 'Catalog',
    EMPLOYEE = 'Employee',
    MRIP = 'Mrip',
}

export interface IPriceStock {
    priceFormat: PriceFormat;
    margin: string;
    monthlyPrices: IMonthlyPrice[];
    endDate: string;
    bonus: string;
    vat: string;
    extras?: IPriceExtras;
    currency: Currencies;
    malus: string;
    type: StockFormatedPriceType;
    startDate: string;
    basePrice: string;
}
