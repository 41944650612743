import styled from 'styled-components';
import { ModalNeedHelpStyled } from '../../ModalNeedHelpStyled';
import { rem } from 'polished';

export const ModalNeedHelpStyledOV = styled(ModalNeedHelpStyled)`
    .description {
        font-size: ${rem(16)};
        color: ${({ theme }) => theme.colors.black2};
    }

    .phone {
        .icon {
            svg {
                width: ${rem(30)};
                margin-left: ${rem(10)};
                margin-top: ${rem(5)};
            }
        }
    }

    .phone,
    .request,
    .testDrive,
    .faq {
        .icon {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black2};
                }
            }
        }
    }
`;
