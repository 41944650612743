import styled from 'styled-components';
import { rem } from 'polished';
import { ModalPostalCodeTemplate } from './ModalPostalCodeTemplate';

export const ModalPostalCodeStyled = styled(ModalPostalCodeTemplate)`
    width: 100%;

    .title {
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        color: ${({ theme }) => theme.colors.black};
        margin-top: -${rem(45)};
        width: 95%;
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        color: ${({ theme }) => theme.colors.black};
        padding-bottom: ${rem(35)};
    }

    .type-select {
        display: flex;
        flex-direction: row;

        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: ${({ theme }) => theme.fontSizes.h4};
            padding-top: ${rem(2)};
            margin: 0 ${rem(35)} ${rem(18)} 0;
            color: ${({ theme }) => theme.colors.grey5};
        }

        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: ${rem(22)};
            width: ${rem(22)};
            border: 1px solid ${({ theme }) => theme.colors.grey3};
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.white};
        }

        .container input:checked ~ .checkmark {
            background-color: ${({ theme }) => theme.colors.white};
        }

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        .container input:checked ~ .checkmark:after {
            display: block;
        }

        .container .checkmark:after {
            top: ${rem(4)};
            left: ${rem(4)};
            width: ${rem(12)};
            height: ${rem(12)};
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.blue2};
        }
    }

    .input {
        min-height: ${rem(45)};
        margin-bottom: ${rem(20)};

        &.hasError {
            input {
                border-color: ${({ theme }) => theme.colors.danger} !important;
            }
        }
    }

    .select {
        svg {
            width: ${rem(14)};
            height: ${rem(8)};

            path {
                fill: ${({ theme }) => theme.colors.blue2};
            }
        }

        .distance-select {
            &__single-value {
                font-size: ${({ theme }) => theme.fontSizes.h5};
                color: ${({ theme }) => theme.colors.grey1};
            }

            &__option--is-focused {
                background: ${({ theme }) => theme.colors.grey7};
            }

            &__option--is-selected {
                background: ${({ theme }) => theme.colors.blue2};
                color: ${({ theme }) => theme.colors.white};
            }

            &__control {
                min-height: ${rem(45)};
                border-radius: ${rem(12)};
                border: 1px solid ${({ theme }) => theme.colors.grey3};
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .submit {
        text-align: center;
        margin-top: ${rem(25)};
        display: flex;
        justify-content: center;

        button {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            padding-left: ${rem(20)};
            padding-right: ${rem(20)};
            border: none;
            cursor: pointer;
            height: ${rem(48)};
        }

        .home {
            background: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.red};
            border: 2px solid ${({ theme }) => theme.colors.red};
            margin-right: ${rem(10)};
            display: flex;
            align-items: center;

            svg {
                width: ${rem(16)};
                height: ${rem(18)};
                margin-right: ${rem(10)};
                margin-top: ${rem(4)};
                transform: rotate(180deg);

                path {
                    fill: ${({ theme }) => theme.colors.red};
                }
            }
        }

        .validate {
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};
        }
    }

    .selected-dealer {
        padding-top: ${rem(35)};

        .label,
        .name,
        .address-line1,
        .address-line2 {
            display: block;
        }

        .name {
            margin: ${rem(5)} 0;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .error {
        color: ${({ theme }) => theme.colors.danger};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        margin-top: -${rem(18)};
        position: absolute;
    }

    .noSelect {
        color: ${({ theme }) => theme.colors.danger};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        padding-bottom: ${rem(5)};
    }

    .geolocation-title {
        display: none;
    }
`;
