import React from 'react';
import { SC } from '../../styles/theme';
import { BRAND, isMarketGB } from '../../constants/main';

import { CheckoutDealerInfoStyledAC } from './AC/CheckoutDealerInfoStyled.AC';
import { CheckoutDealerInfoStyledAP } from './AP/CheckoutDealerInfoStyled.AP';
import { CheckoutDealerInfoStyledDS } from './DS/CheckoutDealerInfoStyled.DS';
import { CheckoutDealerInfoStyledOV } from './OV/CheckoutDealerInfoStyled.OV';
import { CheckoutDealerInfoStyledOVGB } from './OV/GB/CheckoutDealerInfoStyled.OV';
import { CheckoutDealerInfoStyled } from './CheckoutDealerInfoStyled';
import { IDealer } from '../../interfaces/Dealer';
import { IPointOfSale } from '../../services/stock/models/stockItems.service.model';

export interface CheckoutDealerInfoProps extends SC {
    dealer?: IDealer | IPointOfSale;
    displayTitle?: boolean;
    displayIcons?: boolean;
}

const CheckoutDealerInfo = (props: CheckoutDealerInfoProps) => {
    if (BRAND === 'AC') return <CheckoutDealerInfoStyledAC {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <CheckoutDealerInfoStyledOVGB {...props} /> : <CheckoutDealerInfoStyledOV {...props} />;
    if (BRAND === 'AP') return <CheckoutDealerInfoStyledAP {...props} />;
    if (BRAND === 'DS') return <CheckoutDealerInfoStyledDS {...props} />;
    return <CheckoutDealerInfoStyled {...props} />;
};

export default CheckoutDealerInfo;
