import React from 'react';
import { useDispatch } from 'react-redux';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { Icon, Icons } from 'src/components/Icon';
import useTranslations from '@hooks/useTranslations';
import Button from 'src/components/Button';
import { Pages } from 'src/types/pages';
import { BASKET_LEAD_CAPTURE_FORM_ID, ModalVersion } from 'src/constants/main';
import { IModalShareConfigurationBasket } from './index';
import { usePlausible, UserEvent } from '@hooks/usePlausible';

export const ModalShareConfigurationBasketTemplate = ({ className, mopId, email }: IModalShareConfigurationBasket) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();
    const { plausibleUserEvent } = usePlausible();

    const phoneNumber = t('support.telephone');
    const loadLeadForm = () => {
        plausibleUserEvent(UserEvent.OpenLeadFormAfterShareBasket);
        dispatch(
            UIDuck.openModal({
                modalType: UIDuck.MODAL_TYPES.LEAD_FORM,
                data: {
                    page: Pages.basketV2,
                    leadFormId: BASKET_LEAD_CAPTURE_FORM_ID,
                    type: 'LEAD_COLLECT_CONSENT_FORM',
                    mopId,
                    email,
                },
                modalVersion: ModalVersion.v2,
            })
        );
    };

    return (
        <div className={className}>
            <Icon name={Icons.ShareConfigurationModalIcon} width={72} height={72} />
            <span className="text">{t('collectConsentText')}</span>
            <p className="paragraph" dangerouslySetInnerHTML={{ __html: t('collectConsentParagraph1') }} />
            <a href={`tel:${phoneNumber.trim()}`} className="phone">
                {t('support.telephone')}
            </a>
            <p className="paragraph blurry">{t('collectConsentParagraph2')}</p>
            <p className="paragraph">{t('collectConsentParagraph3')}</p>
            <Button primary onClick={loadLeadForm}>
                {t('collectConsentButton')}
            </Button>
        </div>
    );
};
