import styled from 'styled-components';
import { MarketingStickerStyled } from '../MarketingStickerStyled';
import { rem } from 'polished';

export const MarketingStickerStyledOV = styled(MarketingStickerStyled)`
    background: rgba(0, 163, 224, 0.3);

    .infoIcon svg {
        height: ${rem(20)};
        width: ${rem(20)};

        path {
            fill: ${({ theme }) => theme.colors.black};
        }
    }
`;
