import React from 'react';
import { BRAND } from '../../constants/main';
import { LoadingButtonStyledAC } from './AC/LoadingButtonStyled.AC';
import { LoadingButtonStyledOV } from './OV/LoadingButtonStyled.OV';
import { LoadingButtonStyledAP } from './AP/LoadingButtonStyled.AP';
import { LoadingButtonStyledDS } from './DS/LoadingButtonStyled.DS';
import { LoadingButtonStyled } from './LoadingButtonStyled';
import { SC } from '../../styles/theme';

export interface Props {
    /** Button primary */
    primary?: boolean;
    /** Button secondary */
    secondary?: boolean;
    /** Button disabled state */
    disabled?: boolean;
    id?: string;
    isLoading?: boolean;
    isFullWidth?: boolean;
}

export type LoadingButtonProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>;

const LoadingButton = (props: LoadingButtonProps) => {
    if (BRAND === 'OV') return <LoadingButtonStyledOV {...props} />;
    if (BRAND === 'AC') return <LoadingButtonStyledAC {...props} />;
    if (BRAND === 'AP') return <LoadingButtonStyledAP {...props} />;
    if (BRAND === 'DS') return <LoadingButtonStyledDS {...props} />;
    return <LoadingButtonStyled {...props} />;
};

export default LoadingButton;
