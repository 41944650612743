import React, { FC, memo, useCallback } from 'react';
import { batch } from 'react-redux';
import Button from '../../Button';
import { useGTM } from '@hooks/useGTM';
import { useLoginAndSaveDeal } from '@hooks/useLoginAndSaveDeal';
import { useUIDuck } from '@hooks/useUIDuck';
import { useTranslationByAllowedJourney } from '@hooks/useTranslationByAllowedJourney';
import { SC } from '../../../styles/theme';
import useCustomRouter from '@hooks/useCustomRouter';
import routes from '../../../constants/routes';
import { HandledComponentError } from '@components/ErrorHandling';
import { useDispatch, useSelector } from 'react-redux';
import {
    isBrandOV,
    isBrandAP,
    isBrandAC,
    isBrandDS,
    isMarketGB,
    isMarketFR,
    ModalVersion,
} from '../../../constants/main';
import { ICarDetail } from '../../../redux/carDetails/carDetails.duck.interface';
import { useCreateDeal } from '@hooks/useCreateDeal';
import { GtmData } from '../../../types/gtm';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import { Pages } from '../../../types/pages';
import { Icons } from '@components/Icon';
import CarDetailsDuck from '../../../redux/carDetails/carDetails.duck';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { Redux } from '../../../redux/redux.interface';
import { IconTemplate } from '@components/Icon/IconTemplate';
import modal from '@components/Modal';
import Link from "@components/Link";

export const SaveToAccountButtonTemplate: FC<SC & { page?: Pages; link?: boolean; modal?: boolean }> = memo(
    ({ className, page, link, modal }) => {
        const { getBodyStyleLabel, pushShareConfigurationSuccess, pushToDataLayer } = useGTM();
        const [dealSaved, loginAndSaveDeal] = useLoginAndSaveDeal({ form_type: 'lead' });
        const { isLoading } = useUIDuck();
        const dispatch = useDispatch();
        const isDealSaved = useSelector((state) => UIDuck.getOwnState(state).isDealSaved);
        const carDetails: ICarDetail = useSelector((state: Redux) => CarDetailsDuck.getCurrentCarDetails(state));
        const { translationByAllowedJourney } = useTranslationByAllowedJourney();
        const createDeal = useCreateDeal();

        const router = useCustomRouter();
        const lowerCaseAllWordsExceptFirstLetters = (string: string) =>
            string.replace(/\S*/g, (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`);

        const onClick = useCallback(async () => {
            if (modal) {
                dispatch(
                    UIDuck.openModal({
                        data: {},
                        modalType: UIDuck.MODAL_TYPES.SAVE,
                        modalVersion: ModalVersion.v2,
                    })
                );

                return;
            }

            dispatch(UIDuck.closeModal());

            const deal = await createDeal();

            batch(() => {
                dispatch(UIDuck.setLoading(5));
                dispatch(UIDuck.setIsDealSaved(true));
                dispatch(UIDuck.setLoading(-5));
            });

            if (page !== Pages.basket) {
                pushToDataLayer({
                    eventCategory: 'SolRetail::Basket',
                    eventAction: 'Validate::SaveMyCar',
                    event: 'uaevent',
                    eventLabel: isDealSaved
                        ? translationByAllowedJourney('savedLink', 2)
                        : translationByAllowedJourney('link', 2),
                } as GtmData);
            }

            if (isDealSaved || router.query?.saved) {
                router.push(routes.MY_ACCOUNT);
            } else {
                Boolean((isBrandOV || isBrandAP || isBrandAC || isBrandDS) && (isMarketGB || isMarketFR)) &&
                    pushShareConfigurationSuccess(
                        'basket',
                        isDealSaved
                            ? translationByAllowedJourney('savedLink', 2)
                            : translationByAllowedJourney('link', 2),
                        'clickOnSave'
                    );
                loginAndSaveDeal(deal.financeQuoteId, page);
            }
        }, [carDetails, isDealSaved, getBodyStyleLabel, loginAndSaveDeal, router, pushToDataLayer]);

        const content =
            isDealSaved || router.query?.saved
                ? translationByAllowedJourney('savedLink', 2)
                : translationByAllowedJourney('link', 2);

        try {
            return (
                <div className={className}>
                    {!link ? (
                        <Button secondary onClick={onClick} id="TESTING_SAVE_CAR_BOX" disabled={isLoading}>
                            {content}
                        </Button>
                    ) : (
                        <Link
                            label={lowerCaseAllWordsExceptFirstLetters(content)}
                            onClick={onClick}
                            primary
                            withArrowIcon
                        />
                    )}
                </div>
            );
        } catch (e: any) {
            return <HandledComponentError error={e} />;
        }
    }
);

SaveToAccountButtonTemplate.displayName = 'SaveToAccountButtonTemplate';
