import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import ModalDealerSearchTemplate from './ModalDealerSearchTemplate';

export const ModalDealerSearchStyled = styled(ModalDealerSearchTemplate)`
    width: 100%;
    padding-top: ${rem(20)};
    
    .spinner {
        margin: 0 auto;
        padding: ${rem(40)} 0;
    }
    
    .dealerSearch {
        display: flex;
        height: ${rem(50)};
        
        &__dropDown {
            background: ${(props) => props.theme.colors.white};
            position: absolute;
            width: 100%;
            height: ${rem(150)};
            overflow: scroll;
            z-index: 1000;
            
            ul {
                padding: 0;
                
                li {
                    list-style: none;
                    color: ${(props) => props.theme.colors.black};
                    padding: ${rem(5)};
                    font-size: ${(props) => props.theme.fontSizes.textBase};
                    
                    &:hover {
                        cursor: pointer;
                        font-weight: ${(props) => props.theme.fontWeights.textBold};
                    }
                }
            }
        }
        
        &__input {
            position: relative;
            display: inline-block;
            width: 60%;
            font-size: ${(props) => props.theme.fontSizes.textBase};
            
            &::before {
                content: '';
                width: ${rem(20)};
                height: ${rem(20)};
                display: inline-block;
                position: absolute;
                top: 50%;
                left: ${rem(10)};
                background-size: ${rem(25)};
                margin-top: -${rem(13)};
        }
            
            input {
                width: 100%;
                height: 100%;
                padding: ${rem(8)} ${rem(8)} ${rem(8)} ${rem(36)};
            }
        }
        
        button {
            display: inline-block;
            width: 38%;
            margin-left: 2%;
        }
    }
    
    .dealerList {
        width: 100%;
        height: 80vh;
        overflow: scroll;
        border: 1px solid ${(props) => props.theme.colors.grey7};
        margin-top: ${rem(20)};
        padding: ${rem(16)};
        color: ${(props) => props.theme.colors.black};
        
        > p {
            margin: 0;
        }
        
        .dealerItem {
            padding: ${rem(16)} 0;
            border-bottom: 2px solid ${(props) => props.theme.colors.grey7};
            position: relative;
            
            &__icon {
                background: url("data:image/svg+xml,%3Csvg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0C3.13359 0 0 3.1244 0 6.97947C0 11.7342 5.36484 13.4682 6.53242 20.8348C6.60898 21.3092 6.61445 21.6364 7 21.6364C7.38555 21.6364 7.39102 21.3092 7.46758 20.8348C8.63516 13.4682 14 11.7342 14 6.97947C14 3.1244 10.8664 0 7 0Z' fill='%23000000'/%3E%3C/svg%3E%0A")
                top center no-repeat;
                display: flex;
                justify-content: center;
                height: ${rem(22)};
                font-size: ${(props) => props.theme.fontSizes.textTiny};
                width: ${rem(22)};
                color: ${(props) => props.theme.colors.white};
                position: absolute;
                top: ${rem(20)};
                padding-top: ${rem(3)};
                left: 0;
            }
            
            &__address {
                margin-left: ${rem(30)};
                
                p:first-child {
                    font-weight: ${(props) => props.theme.fontWeights.textBold};
                }
                p {
                    margin: ${rem(5)} 0;
                }
            }
            
            &__footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: ${rem(8)} 0;
                button {
                    border: 1px solid ${(props) => props.theme.colors.black};
                    max-width: ${rem(240)};
                }
        }
    }
`;
