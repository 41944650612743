import styled from 'styled-components';
import { IThemeInterface } from 'src/styles/theme';
import { rem } from 'polished';
import { LinkTemplate } from './LinkTemplate';
import { LinkColorType } from '@components/v2/link/index';

export const colorHelper = ({
    theme,
    type,
    defaultColor,
}: {
    theme: IThemeInterface;
    type: LinkColorType;
    defaultColor: any;
}) => {
    switch (true) {
        case type === LinkColorType.BLACK: {
            return theme.colors.black;
        }

        default: {
            return defaultColor;
        }
    }
};

export const LinkStyled = styled(LinkTemplate)``;
