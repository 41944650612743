import styled from 'styled-components';
import { breakpoints } from '../../../../../styles/theme';
import { FaqItemStyled } from '../FaqItemStyled';
import { rem } from 'polished';

export const FaqItemStyledAC = styled(FaqItemStyled)`
    &.faqGroup {
        > .Collapsible {
            > .Collapsible__trigger {
                border: 1px solid ${({ theme }) => theme.colors.grey3};
                padding: ${rem(11)} ${rem(30)};
                margin: 0 ${rem(16)} 0 ${rem(16)};

                > .faqHeader {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    color: ${({ theme }) => theme.colors.black};
                    padding-right: ${rem(32)};

                    &:after {
                        right: ${rem(32)};
                        height: ${rem(8)};
                        width: ${rem(12)};
                        transform: rotate(180deg);
                        margin-top: ${rem(-6)};
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5853 7.99805L5.99919 3.41202L1.41315 7.99805L-0.000865936 6.58405L5.99919 0.584017L11.9993 6.58405L10.5853 7.99805Z' fill-rule='evenodd' clip-rule='evenodd' fill='red' /%3E%3C/svg%3E");
                    }
                }

                &.is-open {
                    > .faqHeader {
                        &:after {
                            transform: rotate(0);
                        }
                    }
                }
            }

            > .Collapsible__contentOuter {
                background-color: ${({ theme }) => theme.colors.white};

                > .Collapsible__contentInner {
                    margin-right: 0;
                    padding: ${rem(16)} ${rem(16)} 0 ${rem(16)};

                    @media (min-width: ${breakpoints.sm}px) {
                        margin: 0 ${rem(16)};
                        padding: ${rem(16)} ${rem(30)} 0 ${rem(30)};
                    }

                    a {
                        color: ${({ theme }) => theme.colors.black};

                        &:hover {
                            color: ${({ theme }) => theme.colors.primary};
                        }
                    }
                }
            }
        }
    }
    &.faqCategory {
        background-color: ${({ theme }) => theme.colors.white};

        > .Collapsible {
            margin: 0 auto;

            > .Collapsible__contentOuter {
                background-color: ${({ theme }) => theme.colors.white};

                > .Collapsible__contentInner {
                    max-width: ${rem(760)};
                    margin: 0 auto;

                    > .categoryContent {
                        margin: 0 ${rem(16)};
                        padding: ${rem(16)} 0;
                    }

                    a {
                        color: ${({ theme }) => theme.colors.black};

                        span {
                            color: ${({ theme }) => theme.colors.black} !important;

                            &:hover {
                                color: ${({ theme }) => theme.colors.red} !important;
                            }
                        }

                        &:hover {
                            color: ${({ theme }) => theme.colors.red};
                        }
                    }
                }
            }

            > .Collapsible__trigger {
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
                &:focus-visible {
                    > .faqHeader {
                        &:after {
                            outline: 3px solid ${({ theme }) => theme.colors.focus} !important;
                        }
                    }
                }
                > .faqHeader {
                    color: ${({ theme }) => theme.colors.grey5};
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    font-size: ${({ theme }) => theme.fontSizes.h4};
                    position: relative;
                    width: 100%;

                    // &:after {
                    //     height: ${rem(18)};
                    //     width: ${rem(21)};
                    //     margin-top: ${rem(-5)};
                    // }

                    .icon {
                        position: absolute;
                        right: 0;
                        top: ${rem(5)};
                        width: ${rem(21)};
                        height: ${rem(18)};

                        &.opened {
                            height: ${rem(20)};
                            width: ${rem(27)};
                            top: ${rem(2)};
                            right: ${rem(-2)};
                        }
                    }
                }
            }
        }
    }
`;
