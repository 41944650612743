import styled from 'styled-components';
import { CharacteristicsStyled } from '../CharacteristicsStyled';
import { rem } from 'polished';
import { breakpoints } from '../../../../styles/theme';

export const CharacteristicsStyledDS = styled(CharacteristicsStyled)`
    .title {
        font-size: ${rem(28)};
        padding-bottom: ${rem(15)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};

        @media (min-width: ${breakpoints.sm}px) {
            font-size: ${rem(34)};
        }
    }

    .subTitle {
        font-size: ${rem(24)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    .sectionTitle {
        font-size: ${rem(20)};
        text-transform: uppercase;
        display: block;
        margin-top: ${rem(40)};
        padding-bottom: ${rem(15)};
    }

    .sectionSubTitle {
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.black};
        font-size: ${rem(14)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};

        svg {
            width: ${rem(18)};
            height: ${rem(18)};

            path {
                fill: ${({ theme }) => theme.colors.black};
            }
        }
    }

    .primaryTechs {
        background: ${({ theme }) => theme.colors.grey4};
        border: 1px solid ${({ theme }) => theme.colors.grey7};
        border-radius: ${rem(8)};
        font-size: ${rem(14)};
    }

    .secondaryTechs {
        font-size: ${rem(14)};
    }

    .detailTechs {
        font-size: ${rem(14)};
    }

    .characteristicsTechs {
        font-size: ${rem(14)};
    }

    .legalText {
        margin-top: ${rem(45)};
        font-size: ${rem(12)};
        color: ${({ theme }) => theme.colors.grey12};

        a {
            color: ${({ theme }) => theme.colors.primary};
            padding-left: ${rem(4)};
        }
    }
`;
