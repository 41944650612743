import { Icons } from './components/Icon';
import { getAssetUrl } from './utils/assetUrl.utils';
import { UNAVAILABLE_CAR_IMAGE } from './constants/images';

interface IKeyStringAbstract {
    [key: string]: number | string;
}

export const slides = [
    {
        imageSrc: getAssetUrl('/01.png'),
        title: 'INTRODUCING THE ALL NEW DS3 RANGE',
        description: 'Description',
        buttonText: 'Button Text',
        note: 'Note',
    },
    {
        imageSrc: getAssetUrl('/01.png'),
        title: 'INTRODUCING THE ALL NEW DS3 RANGE',
        description: 'Description',
        buttonText: 'Button Text',
        note: 'Note',
    },
];

export const views = [
    { name: 'Engine', icon: Icons.Engine },
    { name: 'Exterior', icon: Icons.Exterior },
    { name: 'Interior', icon: Icons.Interior },
    { name: 'Extras', icon: Icons.Extras },
];

export const filters = [{ name: 'diesel' }, { name: 'benzin' }, { name: 'electro' }];

export const options = [
    { value: 'Tomáš', label: 'Tomáš' },
    { value: 'Jan', label: 'Jan' },
    { value: 'Tonda', label: 'Tonda' },
];

export const orderPanelCashData = 2560;

export const orderPanelDelivery = ' 15/10/2020';

export const faqs = [
    {
        title: 'faq title',
        items: [
            {
                title: 'group title',
                content: 'content question 1 ',
            },
            {
                title: 'group 2 title',
                content: 'content kvescn',
            },
        ],
    },
    {
        title: 'faq title 2',
        items: [
            {
                title: 'How to conversate',
                content: 'Kay bai',
            },
        ],
    },
];

export const characteristicsConfig = {
    technicalSpecifications: [
        {
            data: [
                {
                    label: 'Longueur  (m)',
                    value: '4,606',
                    key: '0001',
                    order: 1,
                },
                {
                    label: 'Largeur rétro ouverts / rétro rabattus (m)',
                    value: '2,204 / 2,010',
                    key: '0002',
                    order: 2,
                },
                {
                    label: 'Largeur hors rétroviseurs (m)',
                    value: '1,920',
                    key: '0012',
                    order: 3,
                },
                {
                    label: 'Hauteur  (m)',
                    value: '1,905',
                    key: '0003',
                    order: 6,
                },
                {
                    label: 'Empattement (m)',
                    value: '2,930',
                    key: '0007',
                    order: 8,
                },
                {
                    label: 'Porte-à-faux AV (m)',
                    value: '0,881',
                    key: '0008',
                    order: 9,
                },
                {
                    label: 'Porte-à-faux AR (m)',
                    value: '0,803',
                    key: '0009',
                    order: 10,
                },
                {
                    label: 'Rayon de braquage entre trottoir (m)',
                    value: '.',
                    key: '0014',
                    order: 16,
                },
                {
                    label: 'Rayon de braquage entre murs (m)',
                    value: '.',
                    key: '0010',
                    order: 20,
                },
            ],
            category: 'Dimensions extérieures',
            key: '0002',
            order: 1,
        },
        {
            data: [
                {
                    label: 'Fuel consumption combined (l/100km)',
                    value: '4.100',
                    key: 'consumptionCombined',
                    order: 0,
                },
                {
                    label: 'CO2 combined (g/km)',
                    value: '107',
                    key: 'co2Combined',
                    order: 1,
                },
                {
                    label: 'Consumption by cycle (LOW)',
                    value: '3.500',
                    key: 'cycleLow',
                    order: 2,
                },
                {
                    label: 'Consumption by cycle (MID)',
                    value: '3.800',
                    key: 'cycleMid',
                    order: 3,
                },
                {
                    label: 'Consumption by cycle (HIGH)',
                    value: '4.500',
                    key: 'cycleHigh',
                    order: 4,
                },
                {
                    label: 'Consumption by cycle (EHIGH)',
                    value: '4.500',
                    key: 'cycleEhigh',
                    order: 5,
                },
                {
                    label: 'Tax Band',
                    value: 'A',
                    key: 'taxBand',
                    order: 6,
                },
                {
                    label: 'CO2 Board Image',
                    value: 'https://visuel3d-secure.peugeot.com/V3DCentral/CO2Class/es-ES/Rollover/A-107.svg',
                    key: 'co2BoardImage',
                    order: 7,
                },
                {
                    label: 'Electric range (kms)',
                    value: 333,
                    key: 'electricRange',
                    order: 1,
                },
            ],
            category: 'Consommation WLTP',
            key: '9111',
            order: 9999,
        },

        {
            data: [
                {
                    label: 'Largeur utile aux passages de roues (m)',
                    value: '1,228',
                    key: '0003',
                    order: 3,
                },
                {
                    label: 'Largeur aux coudes avant/arrière (m)',
                    value: '1,515 / 1,586 / 1,570',
                    key: '0016',
                    order: 18,
                },
            ],
            category: 'Dimensions intérieures utiles',
            key: '0014',
            order: 3,
        },
        {
            data: [
                {
                    label: "Largeur d'ouverture maxi des portes AR (m)",
                    value: '1,228',
                    key: '0005',
                    order: 5,
                },
                {
                    label: "Hauteur d'ouverture des portes AR (m)",
                    value: '1,177',
                    key: '0006',
                    order: 6,
                },
                {
                    label: "Largeur d'ouverture maxi de la PLC (m)",
                    value: '0,743',
                    key: '0007',
                    order: 7,
                },
                {
                    label: "Hauteur d'ouverture de la PLC (m)",
                    value: '1,180',
                    key: '0008',
                    order: 8,
                },
            ],
            category: 'Dimensions des ouvrants',
            key: '0015',
            order: 4,
        },
        {
            data: [
                {
                    label: 'Masse à vide  (en kg)',
                    value: '1634',
                    key: '0001',
                    order: 1,
                },
                {
                    label: 'Masse totale autorisée en charge (kg)',
                    value: '2650',
                    key: '0002',
                    order: 2,
                },
                {
                    label: 'Masse maxi remorquable freinée (kg)',
                    value: '1800',
                    key: '0003',
                    order: 3,
                },
            ],
            category: 'Poids et charges',
            key: '0004',
            order: 5,
        },
        {
            data: [
                {
                    label: 'Puissance administrative (CV)',
                    value: '6',
                    key: '0006',
                    order: 1,
                },
                {
                    label: 'Cylindrée (cm3)',
                    value: '1499',
                    key: '0001',
                    order: 2,
                },
                {
                    label: 'Nombre de cylindres / Disposition',
                    value: '4 en ligne',
                    key: '0002',
                    order: 3,
                },
                {
                    label: 'Nombre de soupapes par cylindre',
                    value: '2',
                    key: '0003',
                    order: 4,
                },
                {
                    label: 'Puissance maxi en kW CEE / ch CEE  à tr/min',
                    value: '88 à 3500',
                    key: '0004',
                    order: 5,
                },
                {
                    label: 'Couple maxi en Nm CEE à tr/min',
                    value: '300 à 1750',
                    key: '0005',
                    order: 7,
                },
                {
                    label: 'Norme de dépollution',
                    value: 'Euro 6.c',
                    key: '0007',
                    order: 8,
                },
                {
                    label: 'Alésage x course',
                    value: '75 X 84,8',
                    key: '0008',
                    order: 9,
                },
                {
                    label: 'Intervalle de maintenance',
                    value: '2 ans ou 40 000 KM',
                    key: '0010',
                    order: 11,
                },
            ],
            category: 'Motorisation',
            key: '0001',
            order: 1,
        },
        {
            data: [
                {
                    label: 'Vitesse maxi (en km/h)',
                    value: '160',
                    key: '0001',
                    order: 1,
                },
                {
                    label: '0-100 km/h (en secondes) conducteur seul',
                    value: '12',
                    key: '0002',
                    order: 2,
                },
                {
                    label: '1000 m départ arrêté ( en secondes) conducteur seul',
                    value: '34',
                    key: '0003',
                    order: 3,
                },
            ],
            category: 'Performances',
            key: '0005',
            order: 2,
        },
        {
            data: [
                {
                    label: 'Consommation urbaine (l/100km)',
                    value: '5,2',
                    key: '0001',
                    order: 1,
                },
                {
                    label: 'Consommation extra-urbaine (l/100km)',
                    value: '4,6',
                    key: '0002',
                    order: 2,
                },
                {
                    label: 'Consommation mixte',
                    value: '4,8',
                    key: '0003',
                    order: 3,
                },
                {
                    label: 'Emissions de CO2 (mixte) - g/km',
                    value: '128',
                    key: '0004',
                    order: 4,
                },
                {
                    label: 'Capacité du réservoir (litres)',
                    value: '69',
                    key: '0005',
                    order: 5,
                },
                {
                    label: 'Capacité du réservoir AdBlue® (litres)',
                    value: '20,6',
                    key: '0007',
                    order: 6,
                },
                {
                    label: 'Type de carburant',
                    value: 'Gasoil',
                    key: '0006',
                    order: 7,
                },
            ],
            category: 'Consommations (l/100km)',
            key: '0006',
            order: 9,
        },
        {
            data: [
                {
                    label: 'Type',
                    value: 'Manuelle',
                    key: '0001',
                    order: 1,
                },
                {
                    label: 'Nombre de rapports',
                    value: '6',
                    key: '0002',
                    order: 2,
                },
            ],
            category: 'Boîte de vitesses',
            key: '0022',
            order: 10,
        },
        {
            data: [
                {
                    label: 'Type de freins avant',
                    value: 'Disques ventilés',
                    key: '0001',
                    order: 1,
                },
                {
                    label: 'Type de freins arrière',
                    value: 'Disques',
                    key: '0002',
                    order: 2,
                },
            ],
            category: 'Freins',
            key: '0010',
            order: 12,
        },
        {
            data: [
                {
                    label: 'Type de suspension avant',
                    value: 'Pseudo Mc Pherson',
                    key: '0001',
                    order: 1,
                },
                {
                    label: 'Type de suspension arrière',
                    value: 'Train à bras tirés à triangles obliques',
                    key: '0002',
                    order: 2,
                },
            ],
            category: 'Suspensions',
            key: '0016',
            order: 13,
        },
    ],
    standardFeatures: [
        {
            data: [
                {
                    label: 'Alerte de temps de conduite',
                    value: '',
                    key: 'E801',
                    order: 10734,
                },
                {
                    label: 'Condamnation centralisée des portes avec télécommande',
                    value: '',
                    key: 'HL02',
                    order: 10749,
                },
                {
                    label: 'Projecteurs avec feux diurnes à ampoules intégrées',
                    value: '',
                    key: 'LA02',
                    order: 10783,
                },
                {
                    label: 'Airbags latéraux conducteur et passager tête et thorax',
                    value: '',
                    key: 'NF10',
                    order: 10798,
                },
                {
                    label: 'Airbags frontaux conducteur & passager',
                    value: '',
                    key: 'NN01',
                    order: 10802,
                },
                {
                    label: 'Projecteurs antibrouillard',
                    value: '',
                    key: 'PR01',
                    order: 10813,
                },
                {
                    label: 'Régulateur / Limiteur de vitesse',
                    value: '',
                    key: 'RG03',
                    order: 10826,
                },
                {
                    label: 'Filet Pieuvre',
                    value: '',
                    key: 'SP01',
                    order: 10832,
                },
                {
                    label: 'Détection de sous-gonflage Indirecte',
                    value: '',
                    key: 'UE05',
                    order: 10838,
                },
                {
                    label: 'ESC + aide au démarrage en côte',
                    value: '',
                    key: 'UF01',
                    order: 10839,
                },
            ],
            category: 'Sécurité',
            key: '0004',
            order: 1,
        },
        {
            data: [
                {
                    label: 'Lève vitres électriques impulsionnels côté conducteur et passager',
                    value: '',
                    key: 'LT03',
                    order: 10787,
                },
                {
                    label: 'Pack visibilité',
                    value: '',
                    key: 'NB08',
                    order: 10795,
                },
                {
                    label: 'Air conditionné à réglage automatique bizone',
                    value: '',
                    key: 'RE07',
                    order: 10825,
                },
                {
                    label: 'Retroviseurs extérieurs électriques et chauffants',
                    value: '',
                    key: 'RJ05',
                    order: 10830,
                },
                {
                    label: 'Aide au stationnement arrière',
                    value: '',
                    key: 'UB01',
                    order: 10833,
                },
            ],
            category: 'Confort',
            key: '0005',
            order: 2,
        },
        {
            data: [
                {
                    label: 'Vitres arrière surteintées',
                    value: '',
                    key: 'VD09',
                    order: 10846,
                },
                {
                    label: 'Vitre latérale coulissante rang 2',
                    value: '',
                    key: 'VG02',
                    order: 10850,
                },
                {
                    label: 'Volet arrière avec lunette chauffante et essuie-vitre',
                    value: '',
                    key: 'WO06',
                    order: 10873,
                },
                {
                    label: 'Porte latérale coulissante droite vitrée',
                    value: '',
                    key: 'WZ04',
                    order: 10877,
                },
            ],
            category: 'Vitres et Portes',
            key: '0011',
            order: 11,
        },
        {
            data: [
                {
                    label: 'Pack Look',
                    value: '',
                    key: 'PD22',
                    order: 10811,
                },
                {
                    label: 'Rideaux Pare-soleil rang 2',
                    value: '',
                    key: 'UD03',
                    order: 10837,
                },
            ],
            category: 'Style',
            key: '0014',
            order: 14,
        },
        {
            data: [
                {
                    label: 'Prédisposition attelage',
                    value: '',
                    key: 'QC03',
                    order: 10816,
                },
            ],
            category: 'Equipements  spécifiques',
            key: '0015',
            order: 15,
        },
        {
            data: [
                {
                    label: 'Assises rang 2 fractionnables 2/3 -1/3, amovibles, et coulissantes sur rails avec mise en tablette',
                    value: '',
                    key: 'AL39',
                    order: 10706,
                },
                {
                    label: 'Siége passager individuel',
                    value: '',
                    key: 'AX02',
                    order: 10718,
                },
            ],
            category: 'Assises',
            key: '0017',
            order: 17,
        },
    ],
    fuel: 'Diesel',
    engine: {
        specs: [
            {
                label: 'Puissance maxi en kW CEE / ch CEE  à tr/min',
                value: '88 à 3500',
                key: 'power',
            },
            {
                label: 'Cylindrée (cm3)',
                value: '1499',
                key: 'cubicCapacity',
            },
            {
                label: 'Type de carburant',
                value: 'Gasoil',
                key: 'fuel',
            },
            {
                label: 'Capacité du réservoir (litres)',
                value: '69',
                key: 'tankCapacity',
            },
            {
                label: 'Couple maxi en Nm CEE à tr/min',
                value: '300 à 1750',
                key: 'torque',
            },
            {
                label: 'Vitesse maxi (en km/h)',
                value: '160',
                key: 'topSpeed',
            },
            {
                label: '0-100 km/h (en secondes) conducteur seul',
                value: '12',
                key: 'acceleration',
            },
            {
                label: 'CO2',
                value: 128,
                key: 'emissions',
            },
            {
                label: 'Tax Band',
                value: 'C',
                key: 'taxBand',
            },
            {
                label: 'MPG',
                value: 4.8,
                key: 'consumption',
            },
        ],
        images: [''],
        description: 'BlueHDi 120 S&S',
        id: 'M0000234',
        title: 'BlueHDi 120 S&S',
        prices: [''],
    },
    gearboxTitle: 'Manuelle',
    brand: 'AP',
    language: 'fr',
    toggleTechnicalFeatures: () => {
        console.log('clicked');
    },
    technicalFeaturesVisible: true,
};

export const techData = {
    fuel: '/static/media/fuel.7c1b3ad3.svg',
    gearbox: '/static/media/manual.19d5ed2c.svg',
};

export const environmentalData: IKeyStringAbstract = {
    'engine.power': '96',
    'consumption.mix': 4.7,
    'consumption.co2': 122,
};

export const technicalDataPromoted = [
    {
        data: [
            {
                label: 'Puissance administrative (CV)',
                value: '6',
                key: '0006',
                order: 1,
            },
            {
                label: 'Cylindrée (cm3)',
                value: '1499',
                key: '0001',
                order: 2,
            },
            {
                label: 'Nombre de cylindres / Disposition',
                value: '4 en ligne',
                key: '0002',
                order: 3,
            },
            {
                label: 'Nombre de soupapes par cylindre',
                value: '2',
                key: '0003',
                order: 4,
            },
            {
                label: 'Puissance maxi en kW CEE / ch CEE  à tr/min',
                value: '88 à 3500',
                key: '0004',
                order: 5,
            },
            {
                label: 'Couple maxi en Nm CEE à tr/min',
                value: '300 à 1750',
                key: '0005',
                order: 7,
            },
            {
                label: 'Norme de dépollution',
                value: 'Euro 6.c',
                key: '0007',
                order: 8,
            },
            {
                label: 'Alésage x course',
                value: '75 X 84,8',
                key: '0008',
                order: 9,
            },
            {
                label: 'Intervalle de maintenance',
                value: '2 ans ou 40 000 KM',
                key: '0010',
                order: 11,
            },
        ],
        category: 'Motorisation',
        key: '0001',
        order: 1,
    },
    {
        data: [
            {
                label: 'Vitesse maxi (en km/h)',
                value: '160',
                key: '0001',
                order: 1,
            },
            {
                label: '0-100 km/h (en secondes) conducteur seul',
                value: '12',
                key: '0002',
                order: 2,
            },
            {
                label: '1000 m départ arrêté ( en secondes) conducteur seul',
                value: '34',
                key: '0003',
                order: 3,
            },
        ],
        category: 'Performances',
        key: '0005',
        order: 2,
    },
];
export const carItemList = [
    { imageInteriorSrc: UNAVAILABLE_CAR_IMAGE, imageExteriorSrc: UNAVAILABLE_CAR_IMAGE },
    { imageInteriorSrc: UNAVAILABLE_CAR_IMAGE, imageExteriorSrc: UNAVAILABLE_CAR_IMAGE },
    { imageInteriorSrc: UNAVAILABLE_CAR_IMAGE, imageExteriorSrc: UNAVAILABLE_CAR_IMAGE },
];
export const carConfiguratorColors = [
    {
        id: 1,
        parentId: [],
        name: 'Magic Red',
        color: '#b82525',
        price: 0,
    },
    {
        id: 11,
        parentId: [1],
        name: 'Magic Red Roof',
        color: '#b82525',
        price: 0,
    },
    {
        id: 12,
        parentId: [1],
        name: 'Light Red Roof',
        color: '#eb5959',
        price: 1000,
    },
    {
        id: 2,
        parentId: [],
        name: 'Evil Blue',
        color: '#598aeb',
        price: 1000000,
    },
    {
        id: 21,
        parentId: [2],
        name: 'Evil Blue Roof',
        color: '#598aeb',
        price: 0,
    },
    {
        id: 22,
        parentId: [2],
        name: 'Light Blue Roof',
        color: '#89abf0',
        price: 1000,
    },
    {
        id: 42,
        parentId: [1, 2],
        name: 'Obsidian Black',
        color: '#2e2a33',
        price: 5000,
    },
];

export const carConfiguratorOptions = [
    {
        id: 1,
        name: 'product 1',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit.Aenean euismod bibendum laoreet.Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.Proin sodales tempor.',
        price: 500,
        leadTime: 2,
        selected: false,
        type: 'Confort',
    },
    {
        id: 2,
        name: 'product 2',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit.Aenean euismod bibendum laoreet.Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.Proin sodales tempor.',
        price: 5000,
        leadTime: 3,
        selected: true,
        type: 'Securite',
    },
    {
        id: 3,
        name: 'product 3',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit.Aenean euismod bibendum laoreet.Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.Proin sodales tempor.',
        price: 200,
        leadTime: 2,
        selected: false,
        type: 'Style',
    },
    {
        id: 4,
        name: 'product 4',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit.Aenean euismod bibendum laoreet.Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.Proin sodales tempor.',
        price: 500,
        leadTime: 2,
        selected: false,
        type: 'Confort',
    },
    {
        id: 5,
        name: 'product 5',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit.Aenean euismod bibendum laoreet.Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.Proin sodales tempor.',
        price: 500,
        leadTime: 2,
        selected: true,
        type: 'Confort',
    },
    {
        id: 6,
        name: 'product 6',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit.Aenean euismod bibendum laoreet.Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.Proin sodales tempor.',
        price: 600,
        leadTime: 2,
        selected: false,
        type: 'Confort',
    },
];

export const carInteriorColors = [
    {
        id: 1,
        parentId: [1],
        name: 'Obsidian Black',
        color: 'linear-gradient(to top, rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(88, 87, 87), rgb(216, 201, 201))',
        price: 5000,
    },
    {
        id: 2,
        parentId: [2],
        name: 'Obsidian Red',
        color: 'linear-gradient(to top, rgb(255, 0, 0), rgb(200, 0, 0), rgb(150, 0, 0), rgb(88, 87, 87), rgb(216, 201, 201))',
        price: 5000,
    },
];

export const carMotorisations = [
    {
        parentId: [1],
        title: '1.2L Petrol',
        gear: '6 Speed manual',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipis cing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales tempor.',
        price: 250,
    },
    {
        parentId: [1],
        title: '1.4L Petrol',
        gear: '7 Speed manual',
        description:
            '2Lorem ipsum dolor sit amet, consectetur adipis cing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales tempor.',
        price: 350,
    },
    {
        parentId: [1],
        title: '1.4L Diesel',
        gear: '8 Speed automatic',
        description:
            '3Lorem ipsum dolor sit amet, consectetur adipis cing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales tempor.',
        price: 1000,
    },
];

export const basketPaymentDetailsData = {
    carName: '308',
    carBody: 'Active',
    carEngine: 'PURETECH 110 S&S',
    carGearbox: 'MANUAL',
    carEnergy: 'ESSENCE',
    dateFrom: '05/18/2020',
    dateTo: '06/15/2020',
    detailPoints: ['First rent: € 5,436.00', 'Personalized rent', 'Commitment over 36 months', '8,000 km per year'],
    benefits: ['Maintenance service offered', 'Hyper wheels', 'Multi-track drifting capability'],
    priceInfo: '300',
};

export const mockedBoxes = [
    {
        id: 1,
        title: 'Faites-le vôtre en réservant en ligne',
        text: "Vous pouvez choisir votre concessionnaire préféré et payer une petite somme pour réserver cette voiture aujourd'hui.",
        buttonText: 'Ajouter au panier',
        onClick: () => console.log('clicked'),
    },
    {
        id: 2,
        title: 'Vous aimez cette voiture, mais vous avez besoin de plus de temps pour décider?',
        text: 'Enregistrez-le sur votre compte en cliquant sur ce bouton',
        buttonText: 'Sauver cette voiture',
        onClick: () => console.log('clicked'),
    },
];

export const basketOrderSummary = {
    options: [
        {
            name: 'Alloy Wheels',
            price: '20.5',
        },
        {
            name: 'CD Player',
            price: '5.32',
        },
        {
            name: 'Detachable Tow-bar',
            price: '32.23',
        },
        {
            name: 'Entertainment Pack',
            price: '16.29',
        },
    ],
    optionsTotal: '1000',
    services: [
        {
            name: 'Service plan: 3 years',
            price: '500',
        },
    ],
    servicesTotal: '500',
    orderTotal: '21,420',
};

export const dealerPlaces = [
    {
        description: 'Montlucon, Montluçon, France',
        id: '4a4d2fe2f2177a852504abb68ae9c8985e7cdd34',
        matched_substrings: [
            {
                length: 9,
                offset: 0,
            },
        ],
        place_id: 'ChIJ7efxQyan8EcRm6Xqttexa_U',
        reference: 'ChIJ7efxQyan8EcRm6Xqttexa_U',
        structured_formatting: {
            main_text: 'Montlucon',
            main_text_matched_substrings: [
                {
                    length: 9,
                    offset: 0,
                },
            ],
            secondary_text: 'Montluçon, France',
        },
        terms: [
            {
                offset: 0,
                value: 'Montlucon',
            },
            {
                offset: 11,
                value: 'Montluçon',
            },
            {
                offset: 22,
                value: 'France',
            },
        ],
        types: ['shopping_mall', 'point_of_interest', 'establishment'],
    },
    {
        description: 'Lunel, France',
        id: 'a1043429380275ecbd6c7d9b89740ba60879d749',
        matched_substrings: [
            {
                length: 5,
                offset: 0,
            },
        ],
        place_id: 'ChIJH1yz-ImethIRCjfxtGQXr-8',
        reference: 'ChIJH1yz-ImethIRCjfxtGQXr-8',
        structured_formatting: {
            main_text: 'Lunel',
            main_text_matched_substrings: [
                {
                    length: 5,
                    offset: 0,
                },
            ],
            secondary_text: 'France',
        },
        terms: [
            {
                offset: 0,
                value: 'Lunel',
            },
            {
                offset: 7,
                value: 'France',
            },
        ],
        types: ['locality', 'political', 'geocode'],
    },
];
