import styled from 'styled-components';
import { rem } from 'polished';
import { ModalShareConfigurationBasketTemplate } from './ModalShareConfigurationBasketTemplate';

export const ModalShareConfigurationBasketStyled = styled(ModalShareConfigurationBasketTemplate)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: auto;
    padding-bottom: ${rem('34px')};

    .text,
    .textTitle {
        font-size: ${rem('18px')};
        line-height: ${rem('22px')};
        font-weight: bold;
        color: ${({ theme }) => theme.colors.black};
        margin-top: ${rem('25px')};
    }

    .text {
        padding-top: ${rem('34px')};
        font-weight: normal;
    }

    .paragraph {
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        max-width: ${rem('464px')};
        color: ${({ theme }) => theme.colors.black};
    }

    .blurry {
        color: #6e7175;
        font-size: ${rem('10px')};
    }

    .phone {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.primary};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        margin-top: ${rem('4px')};
        line-height: 1.36;
        font-weight: bold;
        cursor: pointer;
    }

    button {
        max-width: ${rem('290px')};
    }
`;
