import styled from 'styled-components';
import { InputRadioStyled } from '../InputRadioStyled';
import { rem } from 'polished';

export const InputRadioStyledOV = styled(InputRadioStyled)`
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
        border: 1px solid transparent;
        background: ${({ theme }) => theme.colors.primary};
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
        background: ${({ theme }) => theme.colors.black2};
    }
`;
