import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutDeliveryStyled } from '../CheckoutDeliveryStyled';
import { rem } from 'polished';

export const CheckoutDeliveryStyledAP = styled(CheckoutDeliveryStyled)`
    .mainTitle {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        text-align: center;

        @media (min-width: ${breakpoints.sm}px) {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${({ theme }) => theme.fontSizes.h2};
            padding: ${rem(10)} 0;
            text-align: left;
        }
    }

    .selectedDealer {
        &__header span {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            &:first-child {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yNCAxMkMyNCAxOC42Mjc0IDE4LjYyNzQgMjQgMTIgMjRDNS4zNzI1NiAyNCAwIDE4LjYyNzQgMCAxMkMwIDUuMzcyNTYgNS4zNzI1NiAwIDEyIDBDMTguNjI3NCAwIDI0IDUuMzcyNTYgMjQgMTJaIiBmaWxsPSIjMDBBM0UwIi8+CiAgICA8bWFzayBpZD0ibWFzazAiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjQiIHk9IjciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMSI+CiAgICAgICAgPHBhdGggZD0iTTQuMzk5OSAxMS42MzI3TDUuNTg4MDcgMTAuNDQ0NUwxMC4yNzA1IDE1LjEyOTNMMTguNDAyMSA3TDE5LjU4NzkgOC4xODgxN0wxMC4yNzA1IDE3LjUwMzJMNC4zOTk5IDExLjYzMjdaIiBmaWxsPSJ3aGl0ZSIvPgogICAgPC9tYXNrPgogICAgPGcgbWFzaz0idXJsKCNtYXNrMCkiPgogICAgICAgIDxwYXRoIGQ9Ik00LjM5OTkgMTEuNjMyN0w1LjU4ODA3IDEwLjQ0NDVMMTAuMjcwNSAxNS4xMjkzTDE4LjQwMjEgN0wxOS41ODc5IDguMTg4MTdMMTAuMjcwNSAxNy41MDMyTDQuMzk5OSAxMS42MzI3WiIgZmlsbD0id2hpdGUiLz4KICAgIDwvZz4KPC9zdmc+Cg==');
            }
        }

        &__info p {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.primaryBlack};
        }

        button {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            min-width: 100%;
        }
    }

    .dealerItem {
        &__footer {
            padding: ${rem(10)} ${rem(20)};
            .btnSelected {
                background-color: ${({ theme }) => theme.colors.primary};
                border-color: ${({ theme }) => theme.colors.primary};
            }
        }

        &__distance p {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
        }

        &__distance p {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
        }

        &__button {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            &:hover {
                border-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.primary};
                background: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .dealerList {
        > p {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }
    }

    .dealerSearch {
        &__input::before {
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2s3IiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI1IiB5PSI2IiB3aWR0aD0iMzAiIGhlaWdodD0iMjgiPgo8cGF0aCBkPSJNMzQuMzY4IDMwLjYyOEwyNi43MzYgMjQuMTM2QzI5LjgzODQgMTkuMDU2MyAyOC41MTU2IDEyLjQ0NTEgMjMuNjk3NSA4Ljk1MDE0QzE4Ljg3OTQgNS40NTUxOSAxMi4xODQyIDYuMjUwMzQgOC4zMTg3MiAxMC43NzY2QzQuNDUzMjMgMTUuMzAyOSA0LjcxNTczIDIyLjA0IDguOTIxNyAyNi4yNTE3QzEzLjEyNzcgMzAuNDYzNSAxOS44NjQ0IDMwLjczNTMgMjQuMzk2IDI2Ljg3NkwzMi4wMzIgMzMuMzcyTDM0LjM2OCAzMC42MjhaTTEwLjQ4IDI2LjA0NEM2LjY2OTg5IDIyLjg3NyA1LjY4NjYzIDE3LjQzMDEgOC4xNDg5MiAxMy4xMzA4QzEwLjYxMTIgOC44MzE0OCAxNS44MDY5IDYuOTIzMzUgMjAuNDY2NSA4LjYwNzA5QzI1LjEyNjEgMTAuMjkwOCAyNy45MDE5IDE1LjA3OTQgMjcuMDQ3MSAxOS45NTk2QzI2LjE5MjQgMjQuODM5OCAyMS45NTQ0IDI4LjQgMTcgMjguNEMxNC42MTgyIDI4LjQgMTIuMzExNiAyNy41NjY1IDEwLjQ4IDI2LjA0NFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrNykiPgo8cGF0aCBkPSJNMzQuMzY4IDMwLjYyOEwyNi43MzYgMjQuMTM2QzI5LjgzODQgMTkuMDU2MyAyOC41MTU2IDEyLjQ0NTEgMjMuNjk3NSA4Ljk1MDE0QzE4Ljg3OTQgNS40NTUxOSAxMi4xODQyIDYuMjUwMzQgOC4zMTg3MiAxMC43NzY2QzQuNDUzMjMgMTUuMzAyOSA0LjcxNTczIDIyLjA0IDguOTIxNyAyNi4yNTE3QzEzLjEyNzcgMzAuNDYzNSAxOS44NjQ0IDMwLjczNTMgMjQuMzk2IDI2Ljg3NkwzMi4wMzIgMzMuMzcyTDM0LjM2OCAzMC42MjhaTTEwLjQ4IDI2LjA0NEM2LjY2OTg5IDIyLjg3NyA1LjY4NjYzIDE3LjQzMDEgOC4xNDg5MiAxMy4xMzA4QzEwLjYxMTIgOC44MzE0OCAxNS44MDY5IDYuOTIzMzUgMjAuNDY2NSA4LjYwNzA5QzI1LjEyNjEgMTAuMjkwOCAyNy45MDE5IDE1LjA3OTQgMjcuMDQ3MSAxOS45NTk2QzI2LjE5MjQgMjQuODM5OCAyMS45NTQ0IDI4LjQgMTcgMjguNEMxNC42MTgyIDI4LjQgMTIuMzExNiAyNy41NjY1IDEwLjQ4IDI2LjA0NFoiIGZpbGw9IiMwMEEzRTAiLz4KPC9nPgo8L3N2Zz4K')
                no-repeat;
            background-size: 1.3em;
            margin-top: -${rem(3)};
        }
        input {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.secondary};
            width: 100%;
        }

        @media (max-width: ${breakpoints.sm}px) {
            display: block;

            input,
            button {
                width: 100%;
                margin: ${rem(5)} 0;
            }
        }
    }

    .dalerMapWrapper {
        padding-top: ${rem(50)};

        @media (min-width: ${breakpoints.sm}px) {
            padding-top: ${rem(0)};
        }
    }
`;
