import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalShareConfigurationDealerCodeStyled } from '../../ModalShareConfigurationDealerCodeStyled';

export const ModalShareConfigurationDealerCodeStyledOVGB = styled(ModalShareConfigurationDealerCodeStyled)`
    .share-wrapper {
        .callToAction {
            @media (min-width: ${breakpoints.md}px) {
                width: 70%;
            }
        }
    }
`;
