import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalTrimStyled } from '../../ModalTrimStyled';

export const ModalTrimStyledOV = styled(ModalTrimStyled)`

    .keyFeatures,
    .enginesAvailable,
    .stylesAvailable,
    .colors,
    .interiorsAvailable,
    .featuresAvailable {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }

        .sections {
            .engines,
            .styles,
            .interiors,
            .features {
                .engine,
                .style,
                .interior,
                .feature {
                    .details {
                        font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    }
                }
            }
        }
    }

    .availability {
        font-size: ${({ theme }) => theme.fontSizes.h6};
    }

    .options {
        .filter {
            button {
                background: ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.black};
                border-color: ${({ theme }) => theme.colors.black};
                font-size: ${({ theme }) => theme.fontSizes.h5};

                &.selected,
                &:hover {
                    color: ${({ theme }) => theme.colors.primary};
                    border-color: ${({ theme }) => theme.colors.primary};
                    background: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }

    .categories {
        border-top: 1px solid ${({ theme }) => theme.colors.grey25};

        @media (min-width: ${breakpoints.md}px) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};
        }
    }
`;
