import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import sessionInfoApi from '../services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import carDetailsDuck from '../redux/carDetails/carDetails.duck';
import { MOP_ID_COOKIE_NAME } from '../constants/main';

export const stepCodes = {
    dealer: 1210,
    login: 1100,
    preOrder: 1300,
    bank: 1410,
    financePending: 1450,
    cashPayment: 1400,
    cashPaymentKo: 1500,
    personalDetails: 1215,
};

const updateMopStep = async (stepCodeStatus?: string, stepCode?: number, mopId?: string) => {
    try {
        await sessionInfoApi.updateMopStep(stepCodeStatus, stepCode, getSessionIdCookie(), mopId);
    } catch (e: any) {
        console.error(e);
    }
};

export const useUpdateStepCode = (stepCodeStatus?: string, stepCode?: number) => {
    const mopIdDeal = useSelector((state) => carDetailsDuck.getOwnState(state)?.currentDeal?.mopId);
    const mopIdCookie = Cookies.get(MOP_ID_COOKIE_NAME);
    const mopId = mopIdDeal || mopIdCookie;

    useEffect(() => {
        if (mopId) {
            updateMopStep(stepCodeStatus, stepCode, mopId);
        }
    }, [mopId, stepCodeStatus, stepCode]);
};
