import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalDisableCheckoutTemplate from './ModalDisableCheckoutTemplate';

export const ModalDisableCheckoutStyled = styled(ModalDisableCheckoutTemplate)`
    width: 100%;
    
    .title {
        font-size: ${rem(22)};
        color: ${(props) => props.theme.colors.black};
    }
    
    .description {
        font-size: ${(props) => props.theme.fontSizes.textSupBase};
        color: ${(props) => props.theme.colors.black};
        padding: ${rem(10)} 0 ${rem(20)} 0;
    }
    
    .input {
        
        input {
            padding: 0 ${rem(10)};
            font-size: ${(props) => props.theme.fontSizes.textSupBase};
            height: ${rem(45)};
            border: 1px solid ${({ theme }) => theme.colors.primaryDisabled};
            width: 70%;
        }
        
        button {
            height: ${rem(45)};
            padding: 0 ${rem(20)};
            cursor: pointer;
            background: ${(props) => props.theme.colors.primary};
            font-size: ${(props) => props.theme.fontSizes.textSupBase};
            color: ${(props) => props.theme.colors.white};
            border: none;
            margin-left: ${rem(10)};
            text-transform: uppercase;
            
            &:hover {
                background: ${(props) => props.theme.colors.primaryHover};
            }
        }
    }
    
    .emailError {
        font-size: ${(props) => props.theme.fontSizes.textBase};
        color: ${(props) => props.theme.colors.danger};
    }
    
    .sent-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: auto;
        padding-bottom: ${rem('34px')};

        .text {
            padding: ${rem('34px')};
        }
    }
`;
