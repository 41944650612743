import { css } from 'src/styles/theme';
import { rem } from 'polished';

export const StyledOV = css`
    .description {
        font-size: ${rem(16)};
    }

    .save,
    .share {
        .link {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            color: ${({ theme }) => theme.colors.black2};
            margin-top: ${rem(10)};

            button {
                text-transform: uppercase;
            }

            svg {
                width: ${rem(12)};
                height: ${rem(10)};

                path {
                    fill: ${({ theme }) => theme.colors.black2};
                }
            }

            &:hover,
            &:focus-visible {
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        }

        .form {
            .emailInputComponent {
                background: ${({ theme }) => theme.colors.pureWhite};
            }

            input {
                border-radius: 0;
                border: 1px solid ${({ theme }) => theme.colors.grey18};
                color: ${({ theme }) => theme.colors.black2};
            }

            button {
                background: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.black2};
                border-radius: 0;

                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.black2};
                    color: ${({ theme }) => theme.colors.pureWhite};
                }
            }
        }
    }
`;
