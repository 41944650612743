export const localeToCurrencyFormatPrice = (language: string, price: number) => {
    let currency;
    switch (language) {
        case 'en-GB':
            currency = 'GBP';
            break;

        default:
            currency = 'EUR';
    }

    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};

export const localeToCurrency = (language: string) => {
    let currency;
    switch (language) {
        case 'en-GB':
            currency = 'GBP';
            break;

        default:
            currency = 'EUR';
    }

    return currency;
};
