import { rem } from 'polished';
import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import useTranslations from '@hooks/useTranslations';
import Button from '../Button';
import { POSTCODE_REGEX } from '@utils/checkoutInputsValidation';

interface IModalDeleteDeal {
    onSubmit: (postalCode: string) => void;
    onClose: () => void;
}

export const ModalDealerFeesPostalCode: React.FC<IModalDeleteDeal> = ({ onSubmit, onClose }) => {
    const inputRef = useRef<HTMLInputElement>();
    const { t } = useTranslations();

    const [isInvalid, setIsInvalid] = useState(false);

    const validPostalCode = useCallback(() => {
        const postalCode = inputRef?.current?.value || '';
        const isValid = POSTCODE_REGEX.test(postalCode);

        if (isValid) {
            setIsInvalid(false);
            onSubmit(postalCode);
            return;
        }

        setIsInvalid(true);
    }, [onSubmit]);

    return (
        <Wrapper>
            <Description>{t('basket.dealerFee.carteGrise.modal.description')}</Description>
            <InputWrapper>
                <input
                    ref={inputRef}
                    className={`${isInvalid ? 'invalid' : ''}`}
                    placeholder={t('basket.dealerFee.carteGrise.modal.placeholder')}
                />
                {isInvalid && <ErrorMessage>{t('basket.dealerFee.carteGrise.invalidPostalCode')}</ErrorMessage>}
                <Button onClick={validPostalCode} primary data-testid={`TESTING_DELETE_CONFIRMATION`}>
                    {t('basket.dealerFee.carteGrise.modal.button')}
                </Button>
            </InputWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    margin-top: -${rem('50px')};
    width: 90%;
    padding-bottom: ${rem('10px')};
`;
const Title = styled.h2`
    color: ${props => props.theme.colors.primaryBlack};
    text-transform: uppercase;
    font-size: 16px;
`;
const Description = styled.p`
    color: ${props => props.theme.colors.primaryBlack};
    line-height: 16px;
`;
const InputWrapper = styled.div`
    display: flex;
    position: relative;

    input {
        border-radius: unset;
        border: 1px solid ${props => props.theme.colors.primaryBlack};
        padding: 5px 10px;
        font-size: 12px;
        flex: 2;
    }

    .invalid {
        border: 1px solid ${props => props.theme.colors.danger};
    }

    button {
        width: 35%;
        margin-left: 5%;
    }
`;

const ErrorMessage = styled.span`
    color: ${props => props.theme.colors.primaryBlack};
    position: absolute;
    font-size: 10px;
    bottom: -${rem('15px')};
    color: ${props => props.theme.colors.danger};
`;
