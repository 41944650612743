import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { LinkStyledAC } from './AC/LinkStyled.AC';
import { LinkStyledAP } from './AP/LinkStyled.AP';
import { LinkStyledDS } from './DS/LinkStyled.DS';
import { LinkStyledOV } from './OV/LinkStyled.OV';
import { LinkStyledOV as LinkStyledOVGB } from './OV/GB/LinkStyled.OV';
import { LinkStyled } from './LinkStyled';
import { SC } from '../../styles/theme';

export interface LinkProps extends SC {
    withArrowIcon?: boolean;
    primary?: boolean;
    secondary?: boolean;
    label: string;
    dataTestId?: string;
    onClick?: any;
    href?: string;
    marginTop?: number;
    marginBottom?: number;
}

const Link = (props: LinkProps) => {
    if (BRAND === 'AC') return <LinkStyledAC {...props} />;
    if (BRAND === 'AP') return <LinkStyledAP {...props} />;
    if (BRAND === 'DS') return <LinkStyledDS {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <LinkStyledOVGB {...props} /> : <LinkStyledOV {...props} />;
    return <LinkStyled {...props} />;
};

export default Link;
