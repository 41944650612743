import { css } from 'styled-components';
import { breakpoints } from 'src/styles/theme';

import { rem } from 'polished';

export const RowCellMainStylesDS = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${({ theme }) => theme.lineHeights.textSupBig};

    &.motorization {
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .engine-title {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        color: ${({ theme }) => theme.colors.black};
    }

    .engine-price {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        margin-top: ${rem(20)};
        color: ${({ theme }) => theme.colors.black};
    }
`;

export const RowCellGearboxStylesDS = css`
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
`;

export const RowTitleStylesDS = css`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const CategoryTitleStylesDS = css`
    font-size: ${rem(24)};
    color: ${({ theme }) => theme.colors.black};
`;

export const LegalMentionsStylesDS = css`
    color: ${({ theme }) => theme.colors.grey29};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const TitleStylesDS = css`
    border-color: ${({ theme }) => theme.colors.grey16};
    font-size: ${rem(32)};

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${rem(24)};
    }
`;
