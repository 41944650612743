import styled from 'styled-components';
import { ModalShareConfigurationPersonalizeOfferStyled } from '../ModalShareConfigurationPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalShareConfigurationPersonalizeOfferStyledAP = styled(ModalShareConfigurationPersonalizeOfferStyled)`
    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.blue2};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
            }
        }
    }
`;
