import routes from '../constants/routes';
import { IMySavedDeal } from '../redux/user/user.duck.interface';
import { dealStatuses } from '../pages/checkout/my-details';
import Cookies from 'js-cookie';
import { gaCrossDomainParameter } from '@utils/gaCrossDomainParameter';
import { IConfigurableCar, IDeal } from '../interfaces/Car';
import { IDealerFee } from '@components/DealerFees/DealerFeesTemplate';
import { CARTE_GRISE_FEE, MALUS_FEE, REGISTRATION_FEE } from '../constants/main';
import { IPriceV2, IPriceV2Types, PROMO_CODE_DISCOUNT_KEYS } from '../services';
import { getTotalPriceObjByType } from '@utils/Price.utils';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';

export const constructPsaCheckoutUrl = (psaCheckoutUrl: string, mopId: string, isUserLoggedIn: boolean) => {
    const PSA_CHECKOUT_CREATE_ACCOUNT_PARAM_WITH_AMPERSAND = '&create_account=true';
    const consentParam = `&consent=${Cookies.get('_psac_gdpr_consent_purposes')}`;

    let constructedUrl = `${psaCheckoutUrl}${mopId}`;

    if (!isUserLoggedIn) {
        constructedUrl += PSA_CHECKOUT_CREATE_ACCOUNT_PARAM_WITH_AMPERSAND;
    }

    constructedUrl += consentParam;

    // DPM21959
    const cookieForClientId = document.cookie?.split('; ')?.filter((cookie: string) => cookie.includes('_ga='))?.[0];
    const cookieForStreamIdAndSessionId = document.cookie
        ?.split('; ')
        ?.filter((cookie: string) => cookie.includes('_ga_'))?.[0];

    if (cookieForClientId) {
        const clientId = cookieForClientId?.split('=')?.[1]?.slice(6);

        constructedUrl += `&clientid=${clientId}`;
    }

    if (cookieForStreamIdAndSessionId) {
        const streamId = cookieForStreamIdAndSessionId?.split('=')?.[0]?.replace('_ga_', '');
        const sessionId = cookieForStreamIdAndSessionId?.split('=')?.[1]?.split('.')?.[2];

        constructedUrl += `&streamid=${streamId}`;
        constructedUrl += `&sessionid=${sessionId}`;
    }

    const dyid = document.cookie
        ?.split('; ')
        ?.filter((cookie: string) => cookie.includes('_dyid='))?.[0]
        ?.replace('_dyid=', '');
    const dyjsession = document.cookie
        ?.split('; ')
        ?.filter((cookie: string) => cookie.includes('_dyjsession='))?.[0]
        ?.replace('_dyjsession=', '');

    if (dyid) {
        constructedUrl += `&_dyid=${dyid}`;
    }

    if (dyjsession) {
        constructedUrl += `&_dyjsession=${dyjsession}`;
    }

    return gaCrossDomainParameter(constructedUrl);
};

export const getRedirectDestinationOnActivateDeal = (
    { status, mopId, token }: IMySavedDeal | IDeal,
    isSummaryEnabled: boolean = false
): string => {
    if (isSummaryEnabled && status === dealStatuses.vehicleSummary) {
        return routes.SUMMARY;
    } else if (!status || status === dealStatuses.basket || !mopId) {
        return routes.BASKET;
    } else if (status === dealStatuses.checkoutDealer) {
        return routes.CHECKOUT_DELIVERY;
    } else if (
        [
            dealStatuses.checkoutMyDetails,
            dealStatuses.sentToCustomer,
            dealStatuses.checkoutLogin,
            dealStatuses.checkoutBrandId,
        ].includes(status)
    ) {
        return routes.CHECKOUT_MY_DETAILS;
    } else if ([dealStatuses.checkoutProforma, dealStatuses.cashPayment, dealStatuses.cashPaymentKo].includes(status)) {
        return routes.CHECKOUT_PREORDER;
    } else if (
        [dealStatuses.redirectedToLoanProvider, dealStatuses.financeApproved, dealStatuses.financeRejected].includes(
            status
        )
    ) {
        return `${routes.CHECKOUT_ORDER_REVIEW}?dealId=${token}&isGuest=1`;
    } else {
        console.error('There is not defined route for this deal status. Status: ' + status);
    }
};

export const skipPaymentDeposit = (deal: IMySavedDeal | IDeal<IConfigurableCar>) => {
    return !deal?.extraFields?.reservationFee || deal?.extraFields?.reservationFee === 0;
};

export const validateDealerId = (dealerId: string): boolean => {
    return /^\d+$/.test(dealerId);
};

export const hasMandatoryDealerFees = (dealerFees: IDealerFee[]): boolean => {
    return (
        dealerFees.filter(
            (dealerFee: IDealerFee) =>
                dealerFee.mandatory &&
                dealerFee.id !== CARTE_GRISE_FEE &&
                dealerFee.id !== REGISTRATION_FEE &&
                dealerFee.id != MALUS_FEE
        )?.length > 0
    );
};

export const hasOptionalDealerFees = (dealerFees: IDealerFee[]): boolean => {
    return (
        dealerFees.filter(
            (dealerFee: IDealerFee) =>
                !dealerFee.mandatory &&
                dealerFee.id !== CARTE_GRISE_FEE &&
                dealerFee.id !== REGISTRATION_FEE &&
                dealerFee.id != MALUS_FEE
        )?.length > 0
    );
};

export const hasSelectedOptionalDealerFees = (currentDeal: IDeal<IConfigurableCar>): boolean => {
    return (
        currentDeal?.fullProductConfiguration?.fees?.filter(
            (dealerFee: IDealerFee) =>
                !dealerFee.mandatory &&
                dealerFee.id !== CARTE_GRISE_FEE &&
                dealerFee.id !== REGISTRATION_FEE &&
                dealerFee.id != MALUS_FEE
        )?.length > 0
    );
};

export const hasSelectedOptionalAccessories = (currentDeal: IDeal<IConfigurableCar>): boolean => {
    return (currentDeal?.fullProductConfiguration?.accessories?.filter((accessory) => accessory.selected)).length > 0;
};

export const getPromoCodeDiscount = (currentDeal: IDeal<IConfigurableCar>, paymentJourney: string): number => {
    const parsedPromoCode = currentDeal?.extraFields?.promoCode && JSON.parse(currentDeal?.extraFields?.promoCode);
    const totalB2CPrice: IPriceV2 = getTotalPriceObjByType(
        currentDeal?.fullProductConfiguration?.pricesV2 || currentDeal?.fullProductConfiguration?.extraFields?.pricesV2,
        paymentJourney === PaymentJourneyTypes.FINANCE ? IPriceV2Types.TOTAL_B2C_FINANCE : IPriceV2Types.TOTAL_B2C_CASH
    );
    const promoCodeDiscount = totalB2CPrice?.discounts?.find(({ key }) => PROMO_CODE_DISCOUNT_KEYS.includes(key));
    return promoCodeDiscount?.absoluteAmount ?? promoCodeDiscount?.amount ?? parsedPromoCode?.discountAmount ?? 0.0;
};

export const getAgentDiscount = (currentDeal: IDeal<IConfigurableCar>): number => {
    return currentDeal?.extraFields?.agent_discount
        ? Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount)
        : 0;
};

export const getGeolocationExtraFileds = (deal: IDeal) => {
    const geolocation_lat = deal?.extraFields?.geolocation_lat;
    const geolocation_lng = deal?.extraFields?.geolocation_lng;

    return {
        ...(geolocation_lat ? { lat: geolocation_lat } : {}),
        ...(geolocation_lng ? { lng: geolocation_lng } : {}),
    };
};
