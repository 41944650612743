import styled from 'styled-components';
import { css, breakpoints } from '../../../styles/theme';
import { FilterButtonStyled } from '../FilterButtonStyled';
import { rem } from 'polished';

export const FilterButtonStyledDS = styled(FilterButtonStyled)`
    color: ${({ theme }) => theme.colors.grey1};
    font-style: normal;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    margin: 0.5rem 0.5rem 0 0;
    transition: 0.1s ease;
    cursor: pointer;
    min-width: ${rem(130)};
    min-height: ${rem(37)};
    border: 1px solid ${({ theme }) => theme.colors.grey5};
    color: ${({ theme }) => theme.colors.grey5};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${({ theme }) => theme.lineHeights.textBase};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `}
    :hover {
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
    }

    ${({ active, theme }) =>
        active &&
        css`
            border-color: ${({ theme }) => theme.colors.black1};
            color: ${({ theme }) => theme.colors.black1};
            background-position: calc(100% - 20px);
            padding-right: ${rem(10)};
            ${rem(9)};

            @media (max-width: ${breakpoints.sm}px) {
                background-position: calc(100% - 5px);
            }

            &:after {
                @media (min-width: ${breakpoints.sm}px) {
                    right: ${rem(36)};
                }
            }
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `};
`;
