import { AxiosPromise } from 'axios';
import { GetPricesListResponse, IGetPricesListRequestData, IPriceV2 } from './financePrices.types';
import coreApiService from '../core/coreApi.service';
import { BRAND, LANGUAGE, MARKET } from '../../constants/main';
import { IDeal } from '../../interfaces/Car';
import { IPossibleOption } from '../features/featureList.types';

const ENDPOINT_BASE: string = `spc-api/api/v1/${MARKET}/${LANGUAGE}/${BRAND}`;

const PROMOCODE_DISCOUNT_TYPE_TO_KEY_MAPPING: Record<string, string> = {
    absolute: 'promo_code',
    percentage_catalog: 'promo_code_percentage_catalog',
    percentage_internet: 'promo_code_percentage_internet',
};

const PROMOCODE_DISCOUNT_TYPE_TO_TYPE_MAPPING: Record<string, string> = {
    absolute: 'absolute',
    percentage_catalog: 'percentage',
    percentage_internet: 'percentage',
};

class FinancePricesService {
    public static getFinancePricesList = (data: IGetPricesListRequestData): AxiosPromise<GetPricesListResponse> => {
        if (data?.parameters) {
            Object.entries(data.parameters || {})?.forEach((financeWidgetParameter) =>
                financeWidgetParameter[1] === null ||
                (typeof financeWidgetParameter[1] === 'number' && isNaN(financeWidgetParameter[1]))
                    ? delete (data.parameters as { [k: string]: any })[financeWidgetParameter[0]]
                    : 0
            );
        }
        return coreApiService.post(`${ENDPOINT_BASE}/finance-prices-list`, data);
    };

    public static getLatestPrices = async ({
        extraFields,
        fullProductConfiguration,
    }: Pick<IDeal, 'fullProductConfiguration' | 'extraFields'>): Promise<IPriceV2[]> => {
        if (fullProductConfiguration) {
            try {
                let parsedPromoCode;
                try {
                    parsedPromoCode = JSON.parse(extraFields?.promoCode);
                } catch {}
                const carConfiguration = {
                    possibleOptions: [] as IPossibleOption[],
                    ...fullProductConfiguration,
                };
                const data = {
                    carConfiguration,
                    pricingContext: 'FINANCE_CALCULATOR',
                    ...(parsedPromoCode && {
                        discounts: [
                            {
                                key: PROMOCODE_DISCOUNT_TYPE_TO_KEY_MAPPING[parsedPromoCode?.discountType],
                                amount: Number(parsedPromoCode?.discountAmount),
                                type: PROMOCODE_DISCOUNT_TYPE_TO_TYPE_MAPPING[parsedPromoCode?.discountType],
                                journeyType: 'FINANCE',
                            },
                        ],
                    }),
                };
                const response = await coreApiService.post(`${ENDPOINT_BASE}/pricing-engine`, data);
                return response.data.pricesV2;
            } catch (e: any) {
                console.log(e);
            }
        }
        return [];
    };
}

export default FinancePricesService;
