import { rem } from 'polished';
import styled from 'styled-components';
import { CheckoutCarMainInfoStyled } from '../CheckoutCarMainInfoStyled';

export const CheckoutCarMainInfoStyledOV = styled(CheckoutCarMainInfoStyled)`
    .mainInfo {
        background: ${({ theme }) => theme.colors.pureWhite};
    }
    .carInfoHeader {
        .title {
            font-weight: 700;
            text-transform: uppercase;
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-size: ${({ theme }) => theme.fontSizes.h3};
            color: ${({ theme }) => theme.colors.black};
        }
    }
    .carInfo {
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    .separatorLine:last-child {
        display: none;
    }

    .availableInLabel {
        font-weight: normal;
    }

    .availableInDate {
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    }

    .availableInLabel,
    .availableInDate {
        color: ${({ theme }) => theme.colors.grey22};
        line-height: ${({ theme }) => theme.lineHeights.h5};
        font-size: ${({ theme }) => theme.fontSizes.h5};
        font-family: ${({ theme }) => theme.fonts.fontSecondary};
    }

    .paragraph {
        font-size: ${rem(16)};
        color: ${({ theme }) => theme.colors.black};
        font-family: ${({ theme }) => theme.fonts.fontSecondary};

        span:first-child {
            font-weight: 700;
        }
    }

    .detailsContent {
        font-family: ${({ theme }) => theme.fonts.fontSecondary};
        color: ${({ theme }) => theme.colors.black};
        .optionsWrapperTitle {
            text-transform: uppercase;
            font-size: ${rem(16)};
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .optionsWrapper {
        .detailsContent {
            .paragraph {
                span:first-child {
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                }
                span:last-child {
                    color: ${({ theme }) => theme.colors.grey1};
                    font-weight: ${({ theme }) => theme.fontWeights.textMedium};
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                }
            }
        }
    }

    .priceSummary {
        font-family: ${({ theme }) => theme.fonts.fontSecondary};

        .finalPrice {
            .priceInfoWrapper {
                .price {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }

        .priceInfoWrapper {
            font-size: ${rem(16)};
            line-height: ${({ theme }) => theme.lineHeights.h4};
            font-weight: 700;
            color: ${({ theme }) => theme.colors.black};

            .price {
                font-weight: ${({ theme }) => theme.fontWeights.textMedium};
                color: ${({ theme }) => theme.colors.grey1};
                font-family: ${({ theme }) => theme.fonts.fontBase};

                &.withoutTax {
                    color: ${({ theme }) => theme.colors.grey22};
                }
            }
        }
    }

    .mobileCarDelivery {
        border-bottom: none;
    }
`;
