import React, { FC, memo, useState, useEffect, InputHTMLAttributes, useCallback } from 'react';
import { HandledComponentError } from '@components/ErrorHandling';
import { SC } from 'src/styles/theme';
import { Input } from 'src/components/Input';
import { useRegex } from 'src/hooks/useRegex';

interface IProps extends SC, Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'onChange' | 'style'> {
    isFetching: boolean;
    onChange: (value: EmailInputValue) => void;
    placeholder: string;
    minLength?: number;
    ignoreValidity?: boolean;
}

export const EmailInputTemplate: FC<IProps> = memo(
    ({ className, onChange, placeholder, ignoreValidity, minLength = 0, isFetching, ...inputProps }) => {
        const [value, setValue] = useState<EmailInputValue>({ value: '', isValid: false });
        const { emailRegex } = useRegex();

        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const v = e.target.value;
                setValue({ value: v, isValid: ignoreValidity === true || emailRegex.test(v) });
            },
            [ignoreValidity, emailRegex]
        );

        useEffect(() => {
            if (value.value.length > minLength) onChange(value);
        }, [value, onChange, minLength]);

        try {
            return (
                <div className={`${className} emailInputComponent`}>
                    <Input
                        value={value.value}
                        isInvalid={value.value.length > minLength && !value.isValid}
                        onChange={handleChange}
                        placeholder={placeholder}
                        type="text"
                        {...inputProps}
                    />
                </div>
            );
        } catch (e: any) {
            return <HandledComponentError error={e} />;
        }
    }
);

EmailInputTemplate.displayName = 'EmailInputTemplate';
