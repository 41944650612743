/// <reference types="google.maps" />
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { ICoordinates, IGeoLocationInputProps } from '.';
import Select from '../Select';
import { createOption, formatCoordsString, generateOptions, validateCoords } from './utils';
import useTranslations from '@hooks/useTranslations';
import { useSsr } from '@hooks/useSsr';
import { theme } from '../../styles/theme';
import Icon, { Icons } from '../Icon';
import {
    DEFAULT_DISTANCE_OPTIONS_STEP,
    DISTANCE_OPTIONS,
    ENTER_KEY_CODE,
    GOOGLE_RESTRICTED_COUNTRY,
    MARKET,
} from '../../constants/main';
import FilterDuck from '../../redux/filters/filter.duck';
import { useDispatch, useSelector } from 'react-redux';
import { Redux } from 'src/redux/redux.interface';
import { useGTM } from '@hooks/useGTM';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import DealerDuck from '../../redux/dealer/dealer.duck';

const DEFAULT_DISTANCE_IN_KM = DEFAULT_DISTANCE_OPTIONS_STEP;
const DEFAULT_DISTANCE_STEP = DEFAULT_DISTANCE_OPTIONS_STEP;

export interface ISelectOption {
    value: number;
    label: string;
}

interface IGeoLocationInputTemplateProps extends IGeoLocationInputProps {
    className?: string;
    isModal?: boolean;
}

const GeoLocationInputTemplate: FC<IGeoLocationInputTemplateProps> = ({
    className,
    onSubmit,
    onClear,
    distanceInKm = DEFAULT_DISTANCE_IN_KM,
    geoLocation,
    getGeoLocationName,
    isStockSlice,
    showOnlyInput = false,
    reset,
    setUserInput: setParentUserInput,
    isModal = false,
    onEnterKey,
}) => {
    const { t } = useTranslations();
    const { pushUserAction } = useGTM();
    const defaultPlaceholder = t('GeoLocationInput.placeholder');

    const { isBrowser } = useSsr();

    const inputElement = useRef(null);
    const dispatch = useDispatch();

    const distanceRadiusValue = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));
    const dealerId = useSelector((state: Redux) => FilterDuck.getOwnState(state).dealerId);

    const [userInput, setUserInput] = useState(!!getGeoLocationName ? getGeoLocationName : '');
    const [userInputError, setUserInputError] = useState('');
    const [predictedPlaces, setPredictedPlaces] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [distanceRadius, setDistanceRadius] = useState(createOption(distanceInKm ?? DEFAULT_DISTANCE_STEP, t));
    const [coords, setCoords] = useState<ICoordinates>(geoLocation);
    const [placeholder, setPlaceholder] = useState(!!getGeoLocationName ? getGeoLocationName : defaultPlaceholder);

    const areCoordsDefined = (coordinates: ICoordinates | void): coordinates is ICoordinates => !!coordinates;

    const handleClear = () => {
        setUserInput('');
        setCoords(null);
        setPlaceholder(defaultPlaceholder);
        onClear?.();
    };

    useEffect(() => {
        if (reset) {
            handleClear();
        }
    }, [reset]);

    const geoCoderRef = useRef<google.maps.Geocoder | null>(null);
    const getPlacePredictions = useRef<(text: string) => Promise<void> | null>(null);

    useEffect(() => {
        if (isBrowser && typeof window.google === 'object' && typeof window.google.maps === 'object') {
            const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
            geoCoderRef.current = new window.google.maps.Geocoder();
            const autocompleteService = new window.google.maps.places.AutocompleteService();

            getPlacePredictions.current = debounce(async (text: string) => {
                const placePredictions = await autocompleteService.getPlacePredictions(
                    {
                        input: text,
                        componentRestrictions: { country: GOOGLE_RESTRICTED_COUNTRY.toLowerCase() },
                        sessionToken,
                    },
                    noop
                );
                setPredictedPlaces(placePredictions?.predictions?.length ? placePredictions.predictions : []);

                if (placePredictions?.predictions?.length) {
                    setShowDropdown(true);
                } else {
                    setShowDropdown(false);
                }
            }, 350);
        }
    }, [setPredictedPlaces, setShowDropdown, isBrowser]);

    const handleIconClick = () => {
        pushUserAction({
            action: 'Geolocation',
            label: 'Icon',
        });

        handleClear();
    };

    const handleSubmit = () => {
        try {
            validateCoords(coords, t);

            // @NOTE this condition is more for typescript related purposes than validation (that is done on the line above)
            if (areCoordsDefined(coords)) {
                onSubmit(coords, isStockSlice ? 0 : distanceRadius.value, userInput);
                setUserInputError('');
            }
        } catch (error: any) {
            setUserInputError(error.message);
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (placeholder !== defaultPlaceholder) {
            setPlaceholder(defaultPlaceholder);
        }

        if (areCoordsDefined(coords)) {
            setCoords(null);
        }

        setUserInput(value);
        if (setParentUserInput) {
            setParentUserInput(value);
        }
        if (value) {
            setUserInputError('');
            getPlacePredictions.current?.(value);
        } else {
            setPredictedPlaces([]);
            setShowDropdown(false);
        }
    };

    const handleSelectChange = (value: ISelectOption) => {
        setDistanceRadius(value);
        dispatch(FilterDuck.setDistanceRadius(isStockSlice ? 0 : value.value));
    };
    const { isStockJourney } = useCarStockJourneyCheck();

    useEffect(() => {
        if (!isStockSlice && !distanceRadiusValue) {
            setDistanceRadius(createOption(distanceInKm ?? DEFAULT_DISTANCE_STEP, t));
        }
    }, [distanceRadiusValue]);

    useEffect(() => {
        if (areCoordsDefined(coords) && distanceRadius) {
            handleSubmit();
        }
        if (!areCoordsDefined(coords)) {
            handleClear();
        }
    }, [coords, distanceRadius]);

    // @NOTE in case we need a controlled component
    useEffect(() => {
        if (!isStockSlice && distanceInKm && distanceInKm !== distanceRadius.value) {
            setDistanceRadius(createOption(distanceInKm, t));
        }
    }, [distanceInKm]);

    // @NOTE in case we need a controlled component
    useEffect(() => {
        if (!geoLocation && coords) {
            setCoords(null);
            setPlaceholder(defaultPlaceholder);
        } else if (geoLocation && (geoLocation.lat !== coords?.lat || geoLocation.lng !== coords?.lng)) {
            setCoords(geoLocation);
            setPlaceholder(getGeoLocationName ?? formatCoordsString(geoLocation.lat, geoLocation.lng));
        }
    }, [geoLocation]);

    const selectPlace = (place: any) => {
        const geoInputData = { placeId: place.place_id, region: MARKET };

        if (geoCoderRef.current?.geocode) {
            geoCoderRef.current.geocode(
                geoInputData,
                function (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const geoCodeResult = results?.[0];

                        if (!geoCodeResult || !geoCodeResult?.geometry) {
                            setUserInputError(t('GeoLocationInput.coordsNotFound'));
                            setCoords(null);
                        } else {
                            setUserInput(geoCodeResult.formatted_address);
                            setCoords({
                                lat: geoCodeResult?.geometry?.location?.lat(),
                                lng: geoCodeResult?.geometry?.location?.lng(),
                            });
                            setUserInputError('');
                        }
                        setTimeout(() => {
                            setShowDropdown(false);
                        }, 0);
                    } else {
                        setUserInputError(t('GeoLocationInput.noResultsFound', { address: userInput }));
                    }
                }
            );
        }
    };

    const options = useMemo(() => generateOptions(DISTANCE_OPTIONS ? DISTANCE_OPTIONS.split(',') : [], t), []);

    return (
        <div className={className}>
            <h3 className="geolocation-title">{t('GeoLocationInput.label')}</h3>
            <div className="geolocation-input--container">
                <div
                    className={`geolocation-input--textfield-container ${
                        userInputError ? 'geolocation-input--textfield-container-error' : ''
                    } ${isModal ? 'isModal' : ''}`}
                >
                    <input
                        id="geolocation-input"
                        placeholder={placeholder}
                        value={userInput}
                        tabIndex={0}
                        onChange={handleInputChange}
                        data-testid="TESTING_LOCATION_INPUT"
                        ref={inputElement}
                        onKeyUp={(e) => onEnterKey && e.keyCode === ENTER_KEY_CODE && onEnterKey()}
                        onBlur={() =>
                            pushUserAction({
                                action: 'Geolocation',
                                label: 'Bar',
                            })
                        }
                    />
                    {!areCoordsDefined(coords) && (
                        <Icon
                            dataTestId="TESTING_LOCATION_SEARCH_ICON"
                            width={25}
                            height={25}
                            className={`geolocation-input--textfield-icon`}
                            name={Icons.Search}
                        />
                    )}
                    {areCoordsDefined(coords) && (
                        <Icon
                            dataTestId="TESTING_LOCATION_CLEAR_ICON"
                            width={15}
                            height={15}
                            className={`geolocation-input--textfield-icon ${
                                userInputError ? 'geolocation-input--textfield-icon-error' : ''
                            }`}
                            name={Icons.Cancel}
                            onClick={handleIconClick}
                        />
                    )}
                    {predictedPlaces.length > 0 && showDropdown && (
                        <div className="dropDown">
                            <ul>
                                {predictedPlaces.map((place, index) => {
                                    return (
                                        <li
                                            tabIndex={0}
                                            onKeyUp={(e) => e.keyCode === ENTER_KEY_CODE && selectPlace(place)}
                                            onClick={() => selectPlace(place)}
                                            key={place.place_id}
                                            data-testid={`TESTING_LOCATION_PREDICTED_PLACE_${index}`}
                                        >
                                            {place.description}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
                {!showOnlyInput && (
                    <div
                        className={`geolocation-input--select-container ${
                            isStockSlice && 'is-stock-slice--select-container'
                        }`}
                    >
                        <Select
                            value={distanceRadius}
                            onChange={handleSelectChange}
                            options={[...options, { value: 0, label: t('geoLocationInput.allVehicles') }]}
                            arrowColor={theme.colors.white}
                            isSearchable={false}
                            isDisabled={dealerId || !isStockJourney}
                        />
                    </div>
                )}
            </div>
            {userInputError && <div className="geolocation-input--error-message">{userInputError}</div>}
        </div>
    );
};

export default GeoLocationInputTemplate;
