import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { rem } from 'polished';
import { MarketingStickerTemplate } from './MarketingStickerTemplate';

export const MarketingStickerStyled = styled(MarketingStickerTemplate)`
    display: inline-flex;
    justify-self: start;
    align-items: center;
    height: auto;
    padding: ${rem(10)};
    right: ${rem(10)};
    top: ${rem(10)};

    .infoText {
        padding-right: ${rem(10)};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        text-transform: initial;
    }

    .infoIcon {
        height: ${rem(20)};
        cursor: pointer;

        svg {
            height: ${rem(20)};
            width: ${rem(15)};

            g {
                circle,
                line {
                    stroke: ${({ theme }) => theme.colors.primaryBlack};
                }
            }
        }
    }
`;
