import React, { useCallback, useMemo, useState } from 'react';
import { useTranslations } from '@hooks/useTranslations';
import { useGTM } from '@hooks/useGTM';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';
import type { Props } from './index';
import sessionInfoApi from 'src/services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useRegex } from 'src/hooks/useRegex';
import { GtmData } from '../../../types/gtm';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { Pages } from '../../../types/pages';
import { ModalVersion } from '../../../constants/main';
import {usePlausible, UserEvent} from "@hooks/usePlausible";

export const useForm = ({ page, submit }: Props) => {
    const dispatch = useDispatch();
    const { emailRegex } = useRegex();
    const { t } = useTranslations();
    const { getVehicleBodyStyleLabel, pushShareConfigurationSuccess, pushToDataLayer, mapCarData } = useGTM();
    const { carDetails } = useCarDetailsDuck();

    const [email, setEmail] = useState<EmailInputValue>({ value: '', isValid: true });
    const [fetchError, setFetchError] = useState(false);
    const { plausibleUserEvent } = usePlausible();
    const [isFetching, setIsFetching] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const submitLabel = useMemo(() => t('shareConfiguration.submit'), [t]);

    const onSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (emailRegex.test(email.value)) {
                setIsFetching(true);

                try {
                    if (submit) {
                        submit({
                            email: email.value,
                            setFetchError,
                            setIsSuccess,
                            setIsFetching,
                        });
                        plausibleUserEvent(UserEvent.SendCarConfiguration);
                        pushToDataLayer({
                            eventCategory: 'Content',
                            eventAction: 'Validate::SaveMyCar',
                            event: 'uaevent',
                            eventLabel: mapCarData(carDetails)?.vehicleModelBodystyleLabel,
                        } as GtmData);

                        if (page === 'summary') {
                            pushToDataLayer({
                                event: 'uaevent',
                                eventCategory: 'SolRetail::Summary',
                                eventAction: `Validate::EmailOffer::${getVehicleBodyStyleLabel(carDetails)}`,
                                eventLabel: submitLabel,
                            } as GtmData);
                        }

                        //!!!! do not remove return, will break the share configuration email where we should NOT call sessionInfoApi.shareConfigurationEmail !!!!
                        return;
                    }

                    const res = await sessionInfoApi.shareConfigurationEmail(
                        email.value,
                        getSessionIdCookie(),
                        undefined,
                        undefined,
                        page
                    );
                    setFetchError(false);

                    if (res?.data?.success) {
                        pushToDataLayer({
                            eventCategory: 'Content',
                            eventAction: 'Validate::SaveMyCar',
                            event: 'uaevent',
                            eventLabel: mapCarData(carDetails)?.vehicleModelBodystyleLabel,
                        } as GtmData);

                        if (page === 'summary') {
                            pushToDataLayer({
                                event: 'uaevent',
                                eventCategory: 'SolRetail::Summary',
                                eventAction: `Validate::EmailOffer::${getVehicleBodyStyleLabel(carDetails)}`,
                                eventLabel: submitLabel,
                            } as GtmData);
                        }

                        if ([Pages.basketV2, Pages.basket, Pages.summary].includes(page as Pages)) {
                            dispatch(
                                UIDuck.openModal({
                                    modalType: UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_BASKET,
                                    data: {
                                        mopId: res?.data?.mopId,
                                        email: email?.value,
                                    },
                                    modalVersion: ModalVersion.v2,
                                })
                            );
                        } else {
                            dispatch(
                                UIDuck.openModal({
                                    modalType: UIDuck.MODAL_TYPES.SHARE_CONFIGURATION,
                                    data: { email: email.value },
                                    modalVersion: ModalVersion.v2,
                                })
                            );
                        }
                        pushShareConfigurationSuccess('basket', submitLabel);
                        setIsSuccess(true);
                    } else {
                        setFetchError(true);
                    }
                } catch (e: unknown) {
                    setFetchError(true);
                }

                setIsFetching(false);
            } else {
                setEmail((prevState) => ({ value: prevState.value, isValid: false }));
            }
        },
        [dispatch, pushShareConfigurationSuccess, email, submitLabel, pushToDataLayer]
    );

    const onChangeEmail = useCallback((value: EmailInputValue) => {
        setEmail(value);
    }, []);

    return { email, fetchError, isFetching, isSuccess, submitLabel, onChangeEmail, onSubmit };
};
