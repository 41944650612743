import { css } from 'src/styles/theme';
import { rem } from 'polished';

export const StyledAP = css`
    .description {
        font-size: ${rem(12)};
        color: black;
    }
    .share,
    .save {
        .title {
            font-size: ${rem(16)};
            font-weight: bold;
        }

        .label {
            font-size: ${rem(12)};
            line-height: 1.33;
        }
    }

    .share {
        .form {
            .emailInputComponent {
                background: ${({ theme }) => theme.colors.white};
            }

            input {
                display: block;
                margin: ${rem(16)} 0;
                border-radius: unset;
                border: 1px solid #73767a;
                height: ${rem(48)};
                padding: ${rem(15)};
                width: 100%;
            }

            .error {
                font-size: ${rem(12)};
                margin-top: -${rem(10)};
                margin-bottom: ${rem(10)};
                display: block;
                color: ${({ theme }) => theme.colors.danger};
            }
        }
    }

    .save {
        .link {
            margin-top: ${rem(15)};
        }
    }
`;
