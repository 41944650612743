import { useDispatch } from 'react-redux';
import { IS_DEV } from 'src/constants/main';
import styled from 'styled-components';
import React from 'react';
import Button from '../Button';
import { UIDuck } from 'src/redux/commonDucks/ui.duck';

const Cookie = require('js-cookie');

export interface IModalFinanceLegal {
    message: string;
    additionalMessage?: string;
    buttonText: string;
    onClick: () => void;
}

const StyledWrapper = styled.div`
    width: 100%;

    & .button-container {
        margin-top: 40px;
    }
`;

const ModalFinanceLegal = ({ message, additionalMessage, buttonText }: IModalFinanceLegal) => {
    const dispatch = useDispatch();

    const onClick = () => {
        (window as any).financeLegalClosedAt = Date.now();
        dispatch(UIDuck.closeModal());
    };

    return (
        <StyledWrapper>
            <p dangerouslySetInnerHTML={{ __html: message }} />
            {additionalMessage && <p dangerouslySetInnerHTML={{ __html: additionalMessage }} />}
            <div className="button-container">
                <Button id="TESTING_FINANCIAL_DISCLAIMER_BUTTON" onClick={onClick}>
                    {buttonText}
                </Button>
            </div>
        </StyledWrapper>
    );
};

export default ModalFinanceLegal;
