import React from 'react';

import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalDisableCheckoutStyledAP } from './AP/ModalDisableCheckoutStyled.AP';
import { ModalDisableCheckoutStyledDS } from './DS/ModalDisableCheckoutStyled.DS';
import { ModalDisableCheckoutStyledAC } from './AC/ModalDisableCheckoutStyled.AC';
import { ModalDisableCheckoutStyledOV } from './OV/ModalDisableCheckoutStyled.OV';
import { ModalDisableCheckoutStyledOVGB } from './OV/GB/ModalDisableCheckoutStyled.OV';
import { ModalDisableCheckoutStyled } from './ModalDisableCheckoutStyled';

export const ModalDisableCheckout = (props: any) => {
    if (BRAND === 'AP') return <ModalDisableCheckoutStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalDisableCheckoutStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalDisableCheckoutStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalDisableCheckoutStyledOVGB {...props} /> : <ModalDisableCheckoutStyledOV {...props} />;
    return <ModalDisableCheckoutStyled {...props} />;
};

export default ModalDisableCheckout;
