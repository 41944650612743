import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { rem } from 'polished';
import { GlobalTooltipStyled } from '../GlobalTooltipStyled';

export const GlobalTooltipStyledDS = styled(GlobalTooltipStyled)`
    .tooltip-box {
        background: ${({ theme }) => theme.colors.grey6};
        color: ${({ theme }) => theme.colors.white};
        border: 0;
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${rem(14)} !important;
        width: fit-content !important;
        padding: ${rem(16)} !important;
        line-height: ${rem(15)} !important;

        &::after {
            border: none !important;
            background-color: ${({ theme }) => theme.colors.grey6};
            top: ${rem(-7)};

            @media (min-width: ${breakpoints.md}) {
                right: ${rem(53)};
            }
        }
    }

    .place-left {
        &::after {
            border-left-color: ${({ theme }) => theme.colors.secondary} !important;
        }
    }
`;
