import React, { FC } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslations';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding: 0 0 10% 0;

    .modalRow {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .modalLabel {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        a {
            ${({ theme }) => theme.colors.primary};
        }
    }

    .modalTitle {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        text-transform: uppercase;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }
`;

export interface ModalOrderReviewErrorProps {
    onClose?: () => void;
}

export const ModalOrderReviewError: FC<ModalOrderReviewErrorProps> = () => {
    const { t } = useTranslations();
    return (
        <Wrapper>
            <div className="modalRow">
                <div className="modalLabel" dangerouslySetInnerHTML={{ __html: t('checkout.order.error.content') }} />
            </div>
        </Wrapper>
    );
};
