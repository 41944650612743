import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalRemovePxConfirmation } from './index';
import Button from '@components/Button';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';
import Spinner from '@components/Spinner';
import { C2S_WIDGET_QUERY_PARAMETER, DEAL_ID_QUERY_PARAMETER, isBrandAC } from '../../../constants/main';

export const ModalRemovePxConfirmationTemplate = ({ className, onConfirmClick }: IModalRemovePxConfirmation) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const onClose = async () => {
        dispatch(UIDuck.closeModal());
    };

    const onContinue = async () => {
        try {
            setIsLoading(true);
            await onConfirmClick();

            const url = new URL(window.location.href);
            url.searchParams.delete(DEAL_ID_QUERY_PARAMETER);
            url.searchParams.delete(C2S_WIDGET_QUERY_PARAMETER);
            window.history.pushState({}, '', url);
            window.location.reload();
            dispatch(UIDuck.closeModal());
        } catch {
            setIsLoading(false);
        }
    };

    return (
        <div className={className}>
            {isLoading && <Spinner size={25} border={2} color="black" />}
            {!isLoading && (
                <div className="button">
                    <Button onClick={onClose} primary={!isBrandAC} secondary={isBrandAC}>
                        {t('modal.removePx.button.close')}
                    </Button>
                    <Button onClick={onContinue} secondary withoutBackground={isBrandAC}>
                        {t('modal.removePx.button.continue')}
                    </Button>
                </div>
            )}
        </div>
    );
};
