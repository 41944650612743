import styled from 'styled-components';
import { ModalStyled } from '../ModalStyled';
import { rem } from 'polished';

export const ModalStyledAP = styled(ModalStyled)`
    .modalWindow__wrap {
        &__header__title {
            font-size: ${rem(16)};
            line-height: ${rem(18)};
        }
    }

    .form-field {
        font-size: ${rem(12)};

        p {
            span {
                font {
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-size: ${rem(12)};
                }
            }
        }

        h4 {
            font-size: ${rem(14)};
        }
    }

    .modalFinancialSummary {
        &--description {
            font-size: ${rem(11)} !important;
        }
    }

    .finance-summary--col-1 {
        font-size: ${rem(13)};
        padding: ${rem(10)} 0;
        flex: unset;
    }

    .finance-summary--col-2,
    .finance-summary--col-3 {
        font-size: ${rem(12)};
    }

    a {
        color: ${({ theme }) => theme.colors.primary};

        &:hover {
            color: ${({ theme }) => theme.colors.primaryBlack};
        }
    }

    .btn-primary {
        &:hover {
            background: ${({ theme }) => theme.colors.primaryBlack};
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
