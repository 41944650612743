import styled from 'styled-components';
import { rem } from 'polished';
import { ModalPersonalizeOfferTemplate } from './ModalPersonalizeOfferTemplate';

export const ModalPersonalizeOfferStyled = styled(ModalPersonalizeOfferTemplate)`
    margin-top: ${rem(20)};

    .isDisabled {
        pointer-events: none;
        opacity: 0.4;

        &_message {
            padding: 0 ${rem(30)} ${rem(20)} ${rem(30)};
        }
    }

    .isError {
        &_dealerDiscount {
            padding: 0 ${rem(30)} ${rem(20)} ${rem(30)};
            color: ${({ theme }) => theme.colors.danger};
        }
    }

    .sections {
        padding: 0 ${rem(30)};
        margin-bottom: ${rem(80)};
    }

    .section {
        border-top: 1px solid ${({ theme }) => theme.colors.grey12};
        padding: ${rem(24)} 0;

        &:last-child {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey12};
        }

        &.isOpen {
            &_title {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        &_title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                width: ${rem(14)};
                height: ${rem(14)};

                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }
        }

        .row {
            display: flex;
            flex-direction: row;
            padding: ${rem(16)} 0;
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            gap: ${rem(15)};
            align-items: flex-end;
            position: relative;

            &.without-flex {
                display: block;
            }

            &.center {
                justify-content: space-between;
            }

            &.margin-top {
                margin-top: ${rem(20)};
            }

            &.border-top {
                border-top: 1px solid ${({ theme }) => theme.colors.grey30};
            }

            &.border-bottom {
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey30};
            }

            &.without-padding-top {
                padding-top: 0;
            }

            &.without-padding-bottom {
                padding-bottom: 0;
            }

            &_label {
                &.bold {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }
            }

            &_value {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                white-space: nowrap;
            }

            &_error {
                font-size: ${({ theme }) => theme.fontSizes.textTiny};
                position: absolute;
                color: ${({ theme }) => theme.colors.danger};
                top: ${rem(90)};
            }
        }

        .datePickerSection {
            input {
                width: 100%;
                height: ${rem(46)};
                border: 1px solid ${({ theme }) => theme.colors.black};
                padding: ${rem(16)};
                color: ${({ theme }) => theme.colors.black};
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                min-width: ${rem(200)};
                margin: ${rem(5)} 0;
            }

            &.error input {
                border-color: ${({ theme }) => theme.colors.danger};
            }

            .validityError {
                font-size: ${({ theme }) => theme.fontSizes.textTiny};
                color: ${({ theme }) => theme.colors.danger};
            }
        }

        .option {
            background: ${({ theme }) => theme.colors.primaryLight};
            border: 2px solid ${({ theme }) => theme.colors.primary};
            padding: ${rem(24)};
            margin: ${rem(16)} 0;

            &_header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                label {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                svg {
                    width: ${rem(24)};
                    height: ${rem(24)};
                    cursor: pointer;
                }
            }

            &_detail {
                margin-left: ${rem(35)};
            }
        }

        .mandatory-label {
            color: ${({ theme }) => theme.colors.grey31};
            display: block;
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            padding-top: ${rem(25)};
        }

        .reset-button {
            display: flex;
            align-items: center;
            gap: ${rem(10)};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            background: none;
            border: none;
            padding: 0;
            text-transform: uppercase;
            margin-top: ${rem(20)};
            cursor: pointer;

            svg {
                width: ${rem(24)};
                height: ${rem(24)};
            }

            &.without-margin-top {
                margin-top: 0;
            }
        }
    }

    .notEditableMessage {
        margin-top: ${rem(20)};
    }

    .button {
        margin-top: ${rem(20)};

        svg {
            width: ${rem(20)} !important;
            height: ${rem(20)} !important;
            top: ${rem(11)} !important;
            left: ${rem(11)} !important;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        border-top: 1px solid ${({ theme }) => theme.colors.grey30};
        gap: ${rem(20)};
        padding: ${rem(15)} ${rem(60)};
        margin: ${rem(40)} 0;
        justify-content: center;
        position: sticky;
        bottom: 0;
        background: ${({ theme }) => theme.colors.white};
    }
  
    .charactersCount {
        font-size: ${rem(12)};
        padding-top: ${rem(5)};
        opacity: 0.6;
    }
`;
