import React, { FC } from 'react';
import { AprProps } from './';
import { FINANCE_DETAIL_TOKEN, isMarketGB } from '../../constants/main';
import useTranslations from '@hooks/useTranslations';
import { renderPromotionalText } from '@utils/Price.utils';
import parser from 'bbcode-to-react';

export const MonthlyPriceLabelTemplate: FC<AprProps> = ({
    className,
    aprValue,
    aprCustomTextKey,
    calculateSummaryData,
    shopOnlyApr,
    isSummary,
    financeDetailText,
    customFinanceDetailTextKey,
}) => {
    const { t } = useTranslations();

    const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

    return (
        <div className={className} data-testid="monthly-price-label">
            {isMarketGB &&
                aprValue >= 0 &&
                (aprCustomTextKey ? (
                    <p className="aprValue">
                        {renderPromotionalText(aprCustomTextKey, null, null, null, null, null, null, { apr: aprValue })}
                    </p>
                ) : (
                    <p className="aprValue">{`${aprValue} % ${t('landingPage.solCard.aprSuffix')}`}</p>
                ))}
            {!shopOnlyApr && (
                <button className="aprDescription" onClick={calculateSummaryData}>
                    <span data-testid="finance-detail-text">{financeDetailText ? parser.toReact(financeDetailText) : t('landingPage.solCard.bonus.link')}</span>
                </button>
            )}
        </div>
    );
};
