import styled from 'styled-components';
import { css } from '../../../../styles/theme';
import { IconButtonStyled } from '../../IconButtonStyled';
import { rem } from 'polished';

export const IconButtonStyledOV = styled(IconButtonStyled)`
    h2 {
        font-size: ${rem('16px')};
        color: ${({ theme }) => theme.colors.default};
        font-weight: ${({ theme }) => theme.fontWeights.textLight};
    }

    svg {
        rect {
            fill: ${({ theme }) => theme.colors.primary};
        }

        circle {
            stroke: transparent;
        }
    }

    .horizontal {
        margin: 0 ${rem(10)};
        border: ${rem(1)} solid ${({ theme }) => theme.colors.grey13};
        h2 {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            line-height: ${({ theme }) => theme.lineHeights.textMedium};
        }
    }

    .horizontal:hover .icon-wrapper path {
        stroke: ${({ theme }) => theme.colors.secondary};
    }
`;
