import React from 'react';
import {BRAND, isMarketGB} from '../../constants/main';
import { InputRadioStyledAC } from './AC/InputRadioStyled.AC';
import { InputRadioStyledOV } from './OV/InputRadioStyled.OV';
import { InputRadioStyledAP } from './AP/InputRadioStyled.AP';
import { InputRadioStyledDS } from './DS/InputRadioStyled.DS';
import { InputRadioStyledOV as InputRadioStyledOV_GB } from './OV/GB/InputRadioStyled.OV';
import { InputRadioStyled } from './InputRadioStyled';
import { SC } from '../../styles/theme';

export interface InputRadioProps extends SC {
    marginTop?: number;
    marginBottom?: number;
    label: string;
    isChecked: boolean;
    onClick: any;
}

const InputRadio = (props: InputRadioProps) => {
    if (BRAND === 'AC') return <InputRadioStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <InputRadioStyledOV_GB {...props} /> : <InputRadioStyledOV {...props} />;
    if (BRAND === 'AP') return <InputRadioStyledAP {...props} />;
    if (BRAND === 'DS') return <InputRadioStyledDS {...props} />;
    return <InputRadioStyled {...props} />;
};

export default InputRadio;
