import styled from 'styled-components';
import { ModalLeadFormStyled } from '../ModalLeadFormStyled';
import { rem } from 'polished';

export const ModalLeadFormStyledAP = styled(ModalLeadFormStyled)`
    .btn-primary {
        width: 100%;
        height: ${rem(48)};
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: unset;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-size: ${({ theme }) => theme.fontSizes.textTiny};
    }
    .legal_description {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        margin: ${rem(32)} 0;
    }
    .legal_agreement_title {
        font-size: ${({ theme }) => theme.fontSizes.textTiny};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        background: ${({ theme }) => theme.colors.grey7};
        padding: ${rem(16)};
        text-transform: uppercase;
        cursor: pointer;
        margin: 0;

        &.isOpen {
            color: ${({ theme }) => theme.colors.primary};

            svg {
                transform: rotate(-90deg);
              
                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }
        }

        &.isClose {
            svg {
                transform: rotate(90deg);
            }
        }
    }
    .legal_agreement_description {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        padding: 0 ${rem(16)} ${rem(10)} ${rem(16)};
    }
    .agreement1 div div,
    .agreement2 div div,
    .agreement3 div div {
        display: flex;
        gap: ${rem(30)};
    }
    .form-field {
        svg {
            width: ${rem(20)};
            height: ${rem(20)};
            position: absolute;
            right: ${rem(16)};
            top: ${rem(12)};

            path {
                fill: ${({ theme }) => theme.colors.primaryBlack};
                stroke: ${({ theme }) => theme.colors.grey7};
                stroke-width: 1;
            }
        }
    }
`;
