import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutMyDetailsStyled } from '../CheckoutMyDetailsStyled';
import { rem } from 'polished';

export const CheckoutMyDetailsStyledOV = styled(CheckoutMyDetailsStyled)`
    .address-section,
    .email-section,
    .personal-section,
    .email-section {
        &-row {
            @media (max-width: ${breakpoints.sm}px) {
                flex-direction: column;
            }
        }
    }
    .input-section {
        @media (max-width: ${breakpoints.sm}px) {
            width: 100%;
        }
    }
    .wrapper-title {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${rem(24)};
        line-height: ${rem(20)};
        text-transform: uppercase;
        color: black;
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        margin: 0;
        @media (min-width: ${breakpoints.xs}px) {
            margin: ${rem(20)} 0 ${rem(16)} ${rem(8)};
        }
    }
    .wrapper-space {
        margin-bottom: ${rem(20)};
    }
    .continue-button {
        padding-left: ${rem(16)};
        button {
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};
        }
    }
    .grid > .Collapsible > .Collapsible__trigger {
        padding-left: ${rem(32)};
    }
    .confirm-section {
        &:hover:enabled {
            background: ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.white};
        }
    }

    .wrapper-login {
        button {
            background: none;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            &:hover {
                border-bottom: 1px solid !important;
                color: ${({ theme }) => theme.colors.black};
            }
        }
    }
`;
