import React from 'react';
import parser from 'bbcode-to-react';

export const useRenderDescription = () => {
    function transformDescriptionToList(description: string): JSX.Element | string {
        const [standardText, ...listItems] = description.match(/(?:[^#]|#{2,})+/g).map((line) => line.trim());

        return listItems.length > 0 ? (
            <ul data-testId="renderDescriptionTestId">
                <li
                    style={description.charAt(0) !== '#' ? { listStyle: 'none', marginLeft: '-5%' } : {}}
                    className="firstListItem"
                >
                    {parser.toReact(standardText)}
                </li>
                {listItems.map((item, index) => {
                    const listItem = item?.split('##')?.shift()?.replace(/-/, '');
                    const subListItems = item
                        .match(/(?:[^#]|#{3,})+/g).map((line) => line.trim())
                        ?.map((line) => line.replace(/-/, '').trim())
                        ?.filter((line) => line !== listItem?.trim());

                    return (
                        <li style={{ marginTop: '5px' }} key={`renderDescription_${index}`}>
                            {parser?.toReact(listItem)}
                            <ul>
                                {subListItems?.map((subItem, index) => {
                                    const listItem1 = subItem?.split('###')?.shift()?.replace(/-/, '');
                                    const subListItems1 = subItem
                                        .split('###')
                                        ?.map((line) => line.replace(/-/, '').trim())
                                        ?.filter((line) => line !== listItem1?.trim());

                                    return (
                                        <li style={{ marginTop: '5px' }} key={`renderDescription_subItem_${index}`}>
                                            {parser?.toReact(listItem1)}
                                            <ul>
                                                {subListItems1?.map((subItem, index) => {
                                                    return (
                                                        <li style={{ marginTop: '5px' }} key={`renderDescription_subItem_${index}`}>
                                                            {parser?.toReact(subItem)}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        ) : (
            description
        );
    }

    const renderDescription = (description: string): JSX.Element | string => {
        const formattedDesc = description.includes('#') ? transformDescriptionToList(description) : description;

        return typeof formattedDesc === 'string' ? parser?.toReact(formattedDesc) : formattedDesc;
    };

    return {
        renderDescription,
        transformDescriptionToList,
    };
};
