import React from 'react';

import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalShareConfigurationPersonalizeOfferStyledAP } from './AP/ModalShareConfigurationPersonalizeOfferStyled.AP';
import { ModalShareConfigurationPersonalizeOfferStyledDS } from './DS/ModalShareConfigurationPersonalizeOfferStyled.DS';
import { ModalShareConfigurationPersonalizeOfferStyledAC } from './AC/ModalShareConfigurationPersonalizeOfferStyled.AC';
import { ModalShareConfigurationPersonalizeOfferStyledOV } from './OV/ModalShareConfigurationPersonalizeOfferStyled.OV';
import { ModalShareConfigurationDealerCodeStyledOVGB } from './OV/GB/ModalShareConfigurationPersonalizeOfferStyled.OV';
import { ModalShareConfigurationPersonalizeOfferStyled } from './ModalShareConfigurationPersonalizeOfferStyled';



export const ModalShareConfigurationPersonalizeOffer = (props: any) => {
    if (BRAND === 'AP') return <ModalShareConfigurationPersonalizeOfferStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalShareConfigurationPersonalizeOfferStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalShareConfigurationPersonalizeOfferStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalShareConfigurationDealerCodeStyledOVGB {...props} /> : <ModalShareConfigurationPersonalizeOfferStyledOV {...props} />;
    return <ModalShareConfigurationPersonalizeOfferStyled {...props} />;
};

export default ModalShareConfigurationPersonalizeOffer;
