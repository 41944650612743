import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalOfferExpired } from './index';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { formatDateByLang } from '@utils/Date.utils';
import { dealerApi } from '../../../services';
import { theme } from '../../../styles/theme';
import Spinner from '@components/Spinner';

export const ModalOfferExpiredTemplate = ({ className, rrdi7, expiredAt }: IModalOfferExpired) => {
    const { t } = useTranslations();

    const [dealer, setDealer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(true);

    useEffect(() => {
        if (rrdi7) {
            setIsLoading(true);

            dealerApi
                .getDealer(rrdi7)
                .then(({ data }) => {
                    setDealer(data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setHasError(true);
                });
        }
    }, []);

    const openingHoursByDay = dealer?.openingHours?.split('<br />');

    return (
        <div className={className}>
            {isLoading && <Spinner size={20} border={3} color={theme.colors.black} />}
            <p className="expiredAt">
                {t('modal.offerExpired.expiredAt.at')} {formatDateByLang(expiredAt)}.{' '}
                {t('modal.offerExpired.expiredAt.contact')}
            </p>
            {rrdi7 && dealer && (
                <div className="dealerSection">
                    <span className="dealerSection_title">{dealer?.name}</span>
                    <span className="dealerSection_address">
                        {dealer?.streetName}, {dealer?.postCode} {dealer?.city}
                    </span>
                    <span className="dealerSection_phone">{dealer?.contactNumber}</span>
                </div>
            )}
            {(!rrdi7 || (rrdi7 && !dealer)) && (
                <div className="eSellerSection">
                    <span className="eSellerSection_title">{t('modal.offerExpired.seller.title')}</span>
                    <p className="eSellerSection_description">{t('modal.offerExpired.seller.description')}</p>
                    <a href={`tel:${t('support.telephone')}`} className="eSellerSection_callUs">
                        {`${t('support.contactUs')} ${t('support.telephone')}`}{' '}
                        <IconTemplate name={Icons.ChevronRight} />
                    </a>
                </div>
            )}
        </div>
    );
};
