import React from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { LinkStyledOV } from './OV/LinkStyled.OV';
import { LinkStyledOVGB } from './OV/GB/LinkStyled.OVGB';
import { LinkStyledAC } from './AC/LinkStyled.AC';
import { LinkStyledAP } from './AP/LinkStyled.AP';
import { LinkStyledDS } from './DS/LinkStyled.DS';
import { LinkStyled } from './LinkStyled';
import { Icons } from '@components/Icon';

export type LinkProps = SC & {
    onClick?: any;
    txt: string | React.ReactElement;
    iconName?: Icons;
    iconClassName?: string;
    href?: string;
    id?: string;
    iconAltTxt?: string;
    iconAltId?: string;
    iconColor?: LinkColorType;
    iconHoverColor?: LinkColorType;
    dataTestid?: string;
    iconWidth?: number;
    disableIconStroke?: boolean;
    iconHeight?: number;
    isReversed?: boolean;
    isUnderline?: boolean;
    style?: object;
    isLoading?: boolean;
    loader?: React.ReactElement;
    children?: React.ReactElement;
    withParent?: boolean;
    tabIndex?: number;
    ignoreParent?: boolean;
};

export enum LinkColorType {
    BLACK = 'BLACK',
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export const Link = (props: LinkProps) => {
    if (BRAND === 'OV') return isMarketGB ? <LinkStyledOVGB {...props} /> : <LinkStyledOV {...props} />;
    if (BRAND === 'AC') return <LinkStyledAC {...props} />;
    if (BRAND === 'AP') return <LinkStyledAP {...props} />;
    if (BRAND === 'DS') return <LinkStyledDS {...props} />;
    return <LinkStyled {...props} />;
};
