import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { rem } from 'polished';
import { CheckoutTipsSidebarStyled } from '../CheckoutTipsSidebarStyled';
import { CheckoutTipsSidebarProps } from '../';

export const CheckoutTipsSidebarStyledDS = styled(CheckoutTipsSidebarStyled)<CheckoutTipsSidebarProps>`
    border: 0;

    .title {
        background-color: ${({ theme }) => theme.colors.grey14};
        text-transform: uppercase;
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    }

    .content {
        border: 3px solid ${({ theme }) => theme.colors.grey14};
    }

    .content li {
        color: ${({ theme }) => theme.colors.black3};

        p {
            position: relative;
            padding-left: ${rem(4)};
        }

        ${({ items }) =>
            !items[0].title &&
            css`
                & p::before {
                    content: '';
                    width: ${rem(8)};
                    height: ${rem(8)};
                    display: inline-block;
                    background: #9d8c83;
                    position: absolute;
                    left: ${rem(-7)};
                    top: ${rem(6)};
                }
            `}
    }
`;
