import styled from 'styled-components';
import { ModalPersonalizeOfferStyled } from '../ModalPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalPersonalizeOfferStyledDS = styled(ModalPersonalizeOfferStyled)`
    .section {
        border-color: ${({ theme }) => theme.colors.secondary};

        &:last-child {
            border-color: ${({ theme }) => theme.colors.secondary};
        }

        &_title {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        .row {
            font-size: ${({ theme }) => theme.fontSizes.h5};

            &_error {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .datePickerSection {
            input {
                width: 100%;
                border: 1px solid ${({ theme }) => theme.colors.black1};
                font-size: ${({ theme }) => theme.fontSizes.h5};
                border-radius: ${rem(4)};
            }

            .validityError {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .option {
            background: ${({ theme }) => theme.colors.grey4};
            border: 2px solid ${({ theme }) => theme.colors.secondary};
            border-radius: ${rem(12)};

            &_header {
                label {
                    font-size: ${({ theme }) => theme.fontSizes.h5};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                svg {
                    margin-bottom: ${rem(10)};
                }
            }
        }

        .mandatory-label {
            color: ${({ theme }) => theme.colors.grey29};
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        .reset-button {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                margin-top: ${rem(2)};
            }
        }
    }
`;
