import styled from 'styled-components';
import { ModalPersonalizeOfferStyled } from '../ModalPersonalizeOfferStyled';

export const ModalPersonalizeOfferStyledAP = styled(ModalPersonalizeOfferStyled)`
    .isDisabled {
        &_message {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }
    }

    .isError {
        &_dealerDiscount {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }
    }

    .notEditableMessage {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }
`;
