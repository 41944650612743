import React, { FC, memo } from 'react';
// eslint-disable-next-line no-restricted-imports
import router from 'next/router';
import { SC } from '../../../styles/theme';
import { Trans } from 'react-i18next';
import { useTranslations } from '@hooks/useTranslations';
import { useHandledError } from '../useHandledError';

interface IProps extends SC {
    error: any;
}
export const HandledComponentErrorTemplate: FC<IProps> = memo(({ className, error }) => {
    const { t } = useTranslations();
    const onRefresh = () => router.reload();

    useHandledError(error);

    return (
        <div className={className}>
            <Trans t={t} i18nKey="errorHandling.errorOccurred">
                Looks like an error occurred. Try <button onClick={onRefresh}>Refresh page</button>
            </Trans>
        </div>
    );
});
HandledComponentErrorTemplate.displayName = 'HandledComponentErrorTemplate';
