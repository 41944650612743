import React from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalShareConfigurationBasketStyledAC } from './AC/ModalShareConfigurationBasketStyled.AC';
import { ModalShareConfigurationBasketStyledAP } from './AP/ModalShareConfigurationBasketStyled.AP';
import { ModalShareConfigurationBasketStyledDS } from './DS/ModalShareConfigurationBasketStyled.DS';
import { ModalShareConfigurationBasketStyledOV } from './OV/ModalShareConfigurationBasketStyled.OV';
import { ModalShareConfigurationBasketStyledOV as ModalShareConfigurationBasketStyledOVGB } from './OV/GB/ModalShareConfigurationBasketStyled.OV';

export interface IModalShareConfigurationBasket extends SC {
    mopId?: string;
    email?: string;
}

export const ModalShareConfigurationBasket = (props: IModalShareConfigurationBasket) => {
    if (BRAND === 'AP') return <ModalShareConfigurationBasketStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalShareConfigurationBasketStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalShareConfigurationBasketStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? (
            <ModalShareConfigurationBasketStyledOVGB {...props} />
        ) : (
            <ModalShareConfigurationBasketStyledOV {...props} />
        );
};
