import { SelectProps } from '.';
import styled from 'styled-components';
import { SelectTemplate } from './SelectTemplate';
import { rem } from 'polished';

export const SelectStyled = styled(SelectTemplate)<SelectProps>`
    appearance: none;
    background: transparent;
    border: none;
    font-family: ${props => props.theme.fonts.fontBase};
    font-size: ${props => props.theme.fontSizes.textBase};
    line-height: ${props => props.theme.lineHeights.textMedium};

    .Select__control {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border: none;
        box-shadow: none;
        cursor: pointer;
        ${({ withBorder, theme }) =>
            withBorder && `
                border: 1px solid ${theme.colors.black};
                margin: 10px 0;
                padding: 0 10px;
                border-radius: 0;
                color: ${theme.colors.black};
                height: 45px;
        `}
        ${({ isDisabled }) => isDisabled && `opacity: 0.5;`};

        &:hover {
            border-color: unset;
        }
    }

    .Select__invalid {
        border: 1px solid ${props => props.theme.colors.red};
        :hover {
            border: 1px solid ${props => props.theme.colors.red};
        }
    }

    .Select__single-value {
        flex: 1;
    }

    .Select__option--is-focused {
        cursor: pointer;
        background-color: ${props => props.theme.colors.grey5};
    }

    .Select__option--is-selected {
        background-color: ${props => props.theme.colors.blue5};
    }

    .Select__value-container {
        flex: 1;
        flex-wrap: wrap;
        margin-right: 5px;
        align-items: center;
        text-align: right;
        padding: 0;
    }

    .Select__menu {
        border-radius: 0;
        padding: 0;
        z-index: 999999;
        margin-top: ${rem(1)};
    }

    .Select__menu-list {
        padding: 0;
        max-height: unset;
        overflow-y: unset;
    }

    .Select__indicators {
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 8px;
        align-items: center;
        align-self: stretch;
    }

    ${({ isMulti, arrowColor }) =>
        !isMulti &&
        `.Select__indicators > div {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' 
        viewBox='0 0 14 8' fill='none'%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' 
        d='M1.25 8L0 6.7392L7 0L14 6.7392L12.75 8L7 2.4L1.25 8Z' 
        fill='${arrowColor?.replace('#', '%23') ?? '%23AD9D91'}'/%3E %3C/svg%3E")
            no-repeat;
        background-size: 100%;
        background-position-y: 50%;
        transform: rotate(180deg);

        svg {
            display: none;
        }
    }`}

    .Select__indicator-separator {
        display: none;
    }
`;
