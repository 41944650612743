import { css } from 'styled-components';

export const RowCellMainStylesAP = css`
    font-family: ${({ theme }) => theme.fonts.fontV2};
    font-size: ${({ theme }) => theme.fontSizes.textSupBase};
    line-height: ${({ theme }) => theme.lineHeights.textSupBig};

    .engine-title {
        font-family: ${({ theme }) => theme.fonts.fontV2};
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        line-height: ${({ theme }) => theme.lineHeights.h5};
    }

    .engine-price {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        line-height: ${({ theme }) => theme.lineHeights.textMedium};
    }
`;

export const RowCellGearboxStylesAP = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${({ theme }) => theme.lineHeights.textMedium};
`;

export const RowTitleStylesAP = css`
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${({ theme }) => theme.lineHeights.textMedium};
`;

export const CategoryTitleStylesAP = css`
    font-size: ${({ theme }) => theme.fontSizes.textSupBase};
`;
