import styled from 'styled-components';
import { CheckoutMySummaryStyled } from '../CheckoutMySummaryStyled';
import { CheckoutMySummaryProps } from '../index';

export const CheckoutMySummaryStyledAP = styled(CheckoutMySummaryStyled)`
    .grid {
        & > .Collapsible {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }
    }
`;
