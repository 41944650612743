import React from 'react';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from '../../constants/main';
import { MonthlyPriceLabelStyledAC } from './AC/MonthlyPriceLabelStyled.AC';
import { MonthlyPriceLabelStyledOV } from './OV/MonthlyPriceLabelStyled.OV';
import { MonthlyPriceLabelStyledOV as AprStyledOVGB } from './OV/GB/MonthlyPriceLabelStyled.OV';
import { MonthlyPriceLabelStyledAP } from './AP/MonthlyPriceLabelStyled.AP';
import { MonthlyPriceLabelStyledDS } from './DS/MonthlyPriceLabelStyled.DS';
import { MonthlyPriceLabelStyled } from './MonthlyPriceLabelStyled';
import { SC } from '../../styles/theme';
import {IPromotionalText} from "../../interfaces/Price";

export interface AprProps extends SC {
    aprValue: number;
    aprCustomTextKey?: IPromotionalText;
    calculateSummaryData?: () => void;
    financeDetailText?: string;
    size: AprSizes,
    alignToRight?: boolean;
    withoutMarginTop?: boolean;
    customFinanceDetailTextKey?: string;
    withPrimaryColor?: boolean;
    shopOnlyApr?: boolean;
    isSummary?: boolean;
}

export enum AprSizes {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

const MonthlyPriceLabel = (props: AprProps) => {
    if (isBrandAC) return <MonthlyPriceLabelStyledAC {...props} />;
    if (isBrandOV) return isMarketGB ? <AprStyledOVGB {...props} /> : <MonthlyPriceLabelStyledOV {...props} />;
    if (isBrandAP) return <MonthlyPriceLabelStyledAP {...props} />;
    if (isBrandDS) return <MonthlyPriceLabelStyledDS {...props} />;
    return <MonthlyPriceLabelStyled {...props} />;
};

export default MonthlyPriceLabel;
