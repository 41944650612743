import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalOfferExpiredStyledAC } from './AC/ModalOfferExpiredStyled.AC';
import { ModalOfferExpiredStyledAP } from './AP/ModalOfferExpiredStyled.AP';
import { ModalOfferExpiredStyledDS } from './DS/ModalOfferExpiredStyled.DS';
import { ModalOfferExpiredStyledOV } from './OV/ModalOfferExpiredStyled.OV';
import { ModalOfferExpiredStyledOV as ModalNeedHelpStyledOVGB } from './OV/GB/ModalOfferExpiredStyled.OV';

export interface IModalOfferExpired extends SC {
    rrdi7: string;
    expiredAt: Date;
}

export const ModalOfferExpired = (props: IModalOfferExpired) => {
    if (BRAND === 'AP') return <ModalOfferExpiredStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalOfferExpiredStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalOfferExpiredStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalNeedHelpStyledOVGB {...props} /> : <ModalOfferExpiredStyledOV {...props} />;
};
