import styled from 'styled-components';
import { FAQsStyled } from '../FAQsStyled';

export const FAQsStyledAP = styled(FAQsStyled)`
    border-top: unset;

    .faqGroup {
        padding-bottom: 0.5rem;
    }
`;
