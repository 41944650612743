import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { ModalLeadFormStyledAC } from './AC/ModalLeadFormStyled.AC';
import { ModalLeadFormStyledAP } from './AP/ModalLeadFormStyled.AP';
import { ModalLeadFormStyledDS } from './DS/ModalLeadFormStyled.DS';
import { ModalLeadFormStyledOV } from './OV/ModalLeadFormStyled.OV';
import { ModalLeadFormStyledOV as ModalLeadFormStyledOVGB } from './OV/GB/ModalLeadFormStyled.OV';
import { ModalLeadFormStyled } from './ModalLeadFormStyled';
import { SC } from 'src/styles/theme';
import { Pages } from 'src/types/pages';

export interface IModalLeadForm extends SC {
    page?: Pages;
    leadFormId?: number;
    type?: string;
    mopId?: string;
    email?: string;
}

const ModalLeadForm = (props: IModalLeadForm) => {
    if (BRAND === 'OV') return isMarketGB ? <ModalLeadFormStyledOVGB {...props} /> : <ModalLeadFormStyledOV {...props} />;
    if (BRAND === 'AC') return <ModalLeadFormStyledAC {...props} />;
    if (BRAND === 'AP') return <ModalLeadFormStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalLeadFormStyledDS {...props} />;
    return <ModalLeadFormStyled {...props} />;
};

export default ModalLeadForm;
