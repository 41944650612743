import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslations } from '@hooks/useTranslations';
import { LegalTermsAndConditionsConsentProps } from './index';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import Icon, { Icons } from '@components/Icon';
import Collapsible from 'react-collapsible';
import { LEGAL_CONSENTS_KEYS, LEGAL_CONSENTS_VALUES } from '../../constants/enums';
import { isBrandOV, isMarketGB } from '../../constants/main';

const LegalTermsAndConditionsConsentTemplate: FC<LegalTermsAndConditionsConsentProps> = ({
    className,
    showRequiredErrors,
    setSelectedLegalValues,
    withoutDescription,
}) => {
    const { t } = useTranslations();

    const [openSections, setOpenSections] = useState([]);
    const [selectedValues, setSelectedValues] = useState([
        LEGAL_CONSENTS_KEYS.TOUCH,
        LEGAL_CONSENTS_KEYS.DEALS,
        LEGAL_CONSENTS_KEYS.PARTNERS,
    ]);
    const [isVisibleRequiredError, setIsVisibleRequiredError] = useState(false);

    useEffect(() => {
        setIsVisibleRequiredError(showRequiredErrors);
    }, [showRequiredErrors]);

    useEffect(() => {
        setSelectedLegalValues(selectedValues);
    }, [selectedValues]);

    const checkBoxes = useMemo(
        () => [
            {
                key: LEGAL_CONSENTS_KEYS.TOUCH,
                title: t('legalConsent.title1'),
                dataTestId: 'legalConsent.agreement1',
                description: t('legalConsent.description1'),
                onChange: (value: LEGAL_CONSENTS_VALUES) => {
                    setSelectedValues(
                        selectedValues.map((selectedValue: any) =>
                            selectedValue.includes(LEGAL_CONSENTS_KEYS.TOUCH)
                                ? `${LEGAL_CONSENTS_KEYS.TOUCH}:${value}`
                                : selectedValue
                        )
                    );
                },
            },
            {
                key: LEGAL_CONSENTS_KEYS.DEALS,
                title: t('legalConsent.title2'),
                dataTestId: 'legalConsent.agreement2',
                description: t('legalConsent.description2'),
                onChange: (value: LEGAL_CONSENTS_VALUES) => {
                    setSelectedValues(
                        selectedValues.map((selectedValue: any) =>
                            selectedValue.includes(LEGAL_CONSENTS_KEYS.DEALS)
                                ? `${LEGAL_CONSENTS_KEYS.DEALS}:${value}`
                                : selectedValue
                        )
                    );
                },
            },
            {
                key: LEGAL_CONSENTS_KEYS.PARTNERS,
                title: t('legalConsent.title3'),
                dataTestId: 'legalConsent.agreement3',
                description: t('legalConsent.description3'),
                onChange: (value: LEGAL_CONSENTS_VALUES) => {
                    setSelectedValues(
                        selectedValues.map((selectedValue: any) =>
                            selectedValue.includes(LEGAL_CONSENTS_KEYS.PARTNERS)
                                ? `${LEGAL_CONSENTS_KEYS.PARTNERS}:${value}`
                                : selectedValue
                        )
                    );
                },
            },
        ],
        [selectedValues]
    );

    const isChecked = (key: LEGAL_CONSENTS_KEYS, value: LEGAL_CONSENTS_VALUES) => {
        const selectedValue = selectedValues.find((value) => value.includes(key));
        const agreement = selectedValue?.split(':')?.[1];

        return agreement === value;
    };

    return (
        <div id="legal1" className={className}>
            {!withoutDescription && <p className={'legend'}>{t('legalConsent.description')}</p>}
            {checkBoxes.map((ch, key) => {
                const isOpen = openSections.includes(ch.key);

                return (
                    <div key={`section-${key}`} className="section">
                        <Collapsible
                            lazyRender={true}
                            open={isOpen}
                            trigger={
                                <AccessibleButton
                                    className="section_title"
                                    onClick={() =>
                                        isOpen
                                            ? setOpenSections(openSections.filter((openKey) => openKey !== ch.key))
                                            : setOpenSections([...openSections, ch.key])
                                    }
                                >
                                    <span className={isOpen ? 'isOpen' : 'isClose'}>{ch.title}</span>
                                    <Icon
                                        className={isOpen ? 'isOpen' : 'isClose'}
                                        name={
                                            isOpen
                                                ? isBrandOV
                                                    ? Icons.Minus
                                                    : Icons.ChevronUp
                                                : isBrandOV
                                                ? Icons.Plus
                                                : Icons.ChevronDown
                                        }
                                    />
                                </AccessibleButton>
                            }
                        >
                            <p className="section_description" dangerouslySetInnerHTML={{ __html: ch.description }} />
                        </Collapsible>
                        <div className="section_agreements">
                            <div
                                data-testid={ch.dataTestId}
                                className={`section_agreements_agreement ${
                                    isVisibleRequiredError &&
                                    !isChecked(ch.key, LEGAL_CONSENTS_VALUES.AGREE) &&
                                    !isChecked(ch.key, LEGAL_CONSENTS_VALUES.NOT_AGREE)
                                        ? 'error'
                                        : ''
                                }`}
                            >
                                <input
                                    onChange={() => ch.onChange(LEGAL_CONSENTS_VALUES.AGREE)}
                                    id={`toggle-${ch.key}-agree`}
                                    type="radio"
                                    name={`toggle-${ch.key}-agree`}
                                    checked={isChecked(ch.key, LEGAL_CONSENTS_VALUES.AGREE)}
                                />
                                <AccessibleButton tabIndex={0}>
                                    <label htmlFor={`toggle-${ch.key}-agree`}>{t('legalConsent.value.label1')}</label>
                                </AccessibleButton>
                            </div>
                            <div
                                className={`section_agreements_agreement ${
                                    isVisibleRequiredError &&
                                    !isChecked(ch.key, LEGAL_CONSENTS_VALUES.AGREE) &&
                                    !isChecked(ch.key, LEGAL_CONSENTS_VALUES.NOT_AGREE)
                                        ? 'error'
                                        : ''
                                }`}
                            >
                                <input
                                    onChange={() => ch.onChange(LEGAL_CONSENTS_VALUES.NOT_AGREE)}
                                    id={`toggle-${ch.key}-not_agree`}
                                    type="radio"
                                    name={`toggle-${ch.key}-agree`}
                                    checked={isChecked(ch.key, LEGAL_CONSENTS_VALUES.NOT_AGREE)}
                                />
                                <AccessibleButton tabIndex={0}>
                                    <label htmlFor={`toggle-${ch.key}-notAgree`}>
                                        {t('legalConsent.value.label2')}
                                    </label>
                                </AccessibleButton>
                            </div>
                        </div>
                        {isVisibleRequiredError &&
                            !isChecked(ch.key, LEGAL_CONSENTS_VALUES.AGREE) &&
                            !isChecked(ch.key, LEGAL_CONSENTS_VALUES.NOT_AGREE) && (
                                <span className="section_agreements_error">{t('legalConsent.mandatory.error')}</span>
                            )}
                    </div>
                );
            })}
        </div>
    );
};

export default LegalTermsAndConditionsConsentTemplate;
