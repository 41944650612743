import React, { FC } from 'react';

import { useMoneyFormatter } from '@hooks/useMoneyFormatter';

interface DataRendererProps {
    title?: string;
    data: { title: string; priceInclTax?: number | string }[];
    className: any;
}

const DataRenderer: FC<DataRendererProps> = ({ className, title, data }: DataRendererProps) => {
    const { formatMoney } = useMoneyFormatter();

    return (
        <div className={`${className} dataRender`}>
            {title && <div className="dataRender__title">{title}</div>}
            <ul className="dataRender__list">
                {data?.map(({ title: itemTitle, priceInclTax: itemPrice }, index) => (
                    <li key={index} className="dataRender__item">
                        <div>{itemTitle}</div>
                        {itemPrice && <div>{formatMoney(itemPrice)}</div>}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DataRenderer;
