import styled from 'styled-components';
import { GeoLocationInputStyled } from '../GeoLocationInputStyled';

export const GeoLocationInputStyledAP = styled(GeoLocationInputStyled)`
    .geolocation-title {
        font-weight: bold;
    }

    .Select__control {
        background: ${({ theme }) => theme.colors.black} !important;
        border: 1px solid ${({ theme }) => theme.colors.black} !important;
    }

    .geolocation-input--container {
        .geolocation-input--textfield-container {
            .dropDown ul li {
                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.grey7};
                }
            }

            input {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }
        }
    }
`;
