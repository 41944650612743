import { parseUrl } from 'query-string';

import { BRAND_TYPE, isBrandDS, isBrandOV, isMarketES } from '../constants/main';
import { UNAVAILABLE_CAR_IMAGE } from '../constants/images';
import { IStockCar, IConfigurableCar, ICarImage, CarImageDefaults } from '../interfaces/Car';
import { TranslateFunction } from '@hooks/useTranslations';
import { INameplateImage } from '../interfaces/IOfferItem';
import { ImageViewType } from '../constants/enums';
import { useMemo } from 'react';
import { IOption, IVehicleTransformed } from '../services/stock/models/stockItems.service.model';

export enum ImageSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    EXTRA_LARGE = 'extra_large',
}

export const SMALL_WIDTH: number = 450;
export const MEDIUM_WIDTH: number = 625;
export const LARGE_WIDTH: number = 768;
export const EXTRA_LARGE_WIDTH: number = 1280;

export const PARAM_WIDTH: string = '&width';
export const PARAM_HEIGHT: string = '&height';
export const FULL_WIDTH: string = '100%';
export const HEIGHT_CONFIG_PAGE: string = '100%';

export const extractViewFromImageUrl = (url: string) => {
    const defaultView = 1;

    const parsedUrl = parseUrl(url);
    return parseInt(parsedUrl.query.view as string) || defaultView;
};

export const createImageAltResolver =
    (brand: BRAND_TYPE, t: TranslateFunction) =>
    (view: number, car: IStockCar | IConfigurableCar | IVehicleTransformed) => {
        return `${car.model.title} ${car.bodyStyle.title} ${car.specPack.title} ${car.exteriorColour.title} ${
            car.interiorColour.title
        } ${car?.options?.length > 0 ? ':' : ''} 
        ${(car?.options as IOption[]).map((option: IOption) => option.title)?.join(',')}`;
    };

/**
 * Method set image quality based on a ImageSize enum constant (SMALL, MEDIUM, LARGE)
 * @param url
 * @param quality
 */
export function setImageQuality(url: string = '', quality: ImageSize = ImageSize.MEDIUM): string {
    if (!url && isBrandOV) {
        return '';
    }

    if (!url) {
        return UNAVAILABLE_CAR_IMAGE || '';
    }

    const startIndex: number = url.indexOf(PARAM_WIDTH);

    if (!~startIndex) return url; // Different format of image URLS from stock api

    let view = '';
    let endIndex: number = 0;
    let paramCount: number = isBrandOV && isMarketES ? 2 : 3;
    let updateSize: string = `${PARAM_WIDTH}=`;
    switch (quality) {
        case ImageSize.SMALL:
            updateSize += `${SMALL_WIDTH}`;
            updateSize += `${PARAM_HEIGHT}=${FULL_WIDTH}`;
            break;
        case ImageSize.MEDIUM:
            updateSize += `${MEDIUM_WIDTH}`;
            updateSize += `${PARAM_HEIGHT}=${FULL_WIDTH}`;
            break;
        case ImageSize.LARGE:
            updateSize += `${LARGE_WIDTH}`;
            updateSize += `${PARAM_HEIGHT}=${FULL_WIDTH}`;
            break;
        case ImageSize.EXTRA_LARGE:
            updateSize += `${EXTRA_LARGE_WIDTH}`;
            updateSize += `${PARAM_HEIGHT}=${HEIGHT_CONFIG_PAGE}`;
            break;
        default:
            break;
    }

    // If the URL doesn't contain the width and height params
    if (startIndex === -1) {
        return `${url}${updateSize}`;
    }

    for (let index: number = startIndex; index < url.length; index++) {
        const c: string = url.charAt(index);
        if (c === '&' && paramCount) {
            paramCount--;
            endIndex = index;
        }
    }

    const parsed = url.substring(0, startIndex) + updateSize + url.substring(endIndex);

    if (!parsed.includes('&view')) {
        const parsedUrl = parseUrl(url);
        view = parsedUrl.query.view ? `&view=${parsedUrl.query.view}` : '';
        return url.substring(0, startIndex) + view + updateSize + url.substring(endIndex);
    }

    return parsed;
}

export function useExteriorPreviewImage<T extends ICarImage[] | INameplateImage[]>(
    images: T | null,
    selector: T extends ICarImage[] ? CarImageDefaults : string,
    withResize: boolean = false
): string {
    const previewImage = useMemo(() => {
        const exteriorImages = (images || ([] as T)).filter((img) => img.type === ImageViewType.EXTERIOR);
        const image = exteriorImages.find((img) => img.default.includes(selector));
        if (!image) {
            return exteriorImages[isBrandDS ? 1 : 0];
        }
        return isBrandOV && withResize ? { ...image, url: image.url.replace('ngstd', 'ng380x214') } : image;
    }, [images, selector]);

    return setImageQuality(previewImage?.url, ImageSize.SMALL);
}
