import styled from 'styled-components';
import RedirectionModalTemplate from './RedirectionModalTemplate';
import { rem } from 'polished';

export const RedirectionModalStyled = styled(RedirectionModalTemplate)`
    .message {
        &Container {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 50;

            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
        &Content {
            background: ${({ theme }) => theme.colors.white};

            div {
                padding: ${rem(10)} ${rem(40)};
            }

            p {
                font-size: 1.4rem;
                text-align: center;
                margin: 0;
                padding: 2rem;
            }

            .container {
                padding: unset;
            }
        }

        &Button {
            text-align: center;
            button {
                width: 100%;
                text-transform: unset;
            }
        }
    }
`;
