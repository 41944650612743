import React, { FC } from 'react';
import { TextAreaProps } from './index';

const TextAreaTemplate: FC<TextAreaProps> = ({
    className,
    marginTop,
    marginBottom,
    label,
    placeholder,
    rows,
    defaultValue,
    onChange,
    maxLength,
}) => (
    <div className={className}>
        {label && <span>{label}</span>}
        <textarea
            defaultValue={defaultValue}
            onChange={(e) => onChange?.(e.target.value || null)}
            placeholder={placeholder}
            rows={rows}
            maxLength={maxLength}
        />
    </div>
);

export default TextAreaTemplate;
