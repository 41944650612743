import styled from 'styled-components';
import { ModalShareOfferSummaryStyled } from '../ModalShareOfferSummaryStyled';
import { rem } from 'polished';

export const ModalShareOfferSummaryStyledDS = styled(ModalShareOfferSummaryStyled)`
    .wrapper {
        & .form {
            & .formRow {
                input {
                    border-color: ${({ theme }) => theme.colors.grey5};
                    border-radius: ${rem(3)};
                }

                .error {
                    top: ${rem(65)};
                    font-size: ${({ theme }) => theme.fontSizes.h6};
                }
            }
        }

        .dealerShareFeature {
            font-size: ${({ theme }) => theme.fontSizes.h5};

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;
