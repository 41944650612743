import Input from '../../../../ui/Input';
import React, { useEffect, useState } from 'react';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import Button from '@components/Button';
import { SC } from '../../../../styles/theme';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { usePrices } from '@hooks/usePriceCalculator';
import PriceSuffix from '@components/PriceSuffix';
import useTranslations from '@hooks/useTranslations';
import Icon, { Icons } from '@components/Icon';
import { getStorePriceByPaymentMethod } from '@utils/Price.utils';
import { useJourneyType } from '@hooks/useJourneyType';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../../context/featureSwitchApp';
import { getAgentDiscount, getPromoCodeDiscount } from '@utils/Deal.utils';
import routes from '../../../../constants/routes';
import { useRouter } from 'next/router';

interface IDealerDiscount extends SC {
    amount: number;
    setAmount: any;
}

export const DealerDiscountTemplate = ({ amount, setAmount }: IDealerDiscount) => {
    const { t } = useTranslations();

    const router = useRouter();

    const { formatMoney } = useMoneyFormatter();

    const { carDetails, currentDeal } = useCarDetailsDuck();

    const { paymentJourneyType } = useJourneyType();

    const { hasSelectedPaymentJourney = false } = currentDeal?.extraFields ?? {};

    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);

    const isDealerFeesFsEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED);
    const isDealerFeesEnabled = !router.pathname.includes(routes.DEALER) && isDealerFeesFsEnabled;
    const { catalog } = usePrices(carDetails ?? null, false, null, true, !isDealerFeesEnabled);

    const storePriceByPaymentMethod = getStorePriceByPaymentMethod(
        currentDeal,
        hasSelectedPaymentJourney ? paymentJourneyType : null,
        isSOLEnabled
    );

    const [appliedDealerDiscount, setAppliedDealerDiscount] = useState(
        currentDeal?.extraFields?.agent_discount
            ? Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount)
            : null
    );
    const [inputDiscount, setInputDiscount] = useState(amount);
    const [previewDiscount, setPreviewDiscount] = useState(amount);

    useEffect(() => {
        setAppliedDealerDiscount(
            currentDeal?.extraFields?.agent_discount
                ? Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount)
                : null
        );
    }, [currentDeal]);

    const preview = () => {
        setAmount(inputDiscount);
        setPreviewDiscount(inputDiscount);
    };

    return (
        <div>
            {catalog?.totalPrice && (
                <div className="row center margin-top border-top">
                    <span className="row_label">{t('price.catalog.title')}</span>
                    <span className="row_value">{formatMoney(catalog.totalPrice, true)}</span>
                </div>
            )}
            <div className={`row center border-top ${catalog?.totalPrice ? '' : 'margin-top'}`}>
                <span className="row_label">{t('basket.summary.storePrice')}</span>
                <span className="row_value">
                    {formatMoney(
                        (getPromoCodeDiscount(currentDeal, paymentJourneyType)
                            ? storePriceByPaymentMethod + getPromoCodeDiscount(currentDeal, paymentJourneyType)
                            : storePriceByPaymentMethod) + getAgentDiscount(currentDeal),
                        false
                    )}
                </span>
            </div>
            <div className="row center border-top border-bottom">
                <span className="row_label">{t('modal.personalizeOffer.discount.discount')}</span>
                <span className="row_value">
                    {previewDiscount ? '- ' : ''}
                    {formatMoney(previewDiscount || 0, true)}
                </span>
            </div>
            <div className="row center border-bottom">
                <span className="row_label bold">{t('modal.personalizeOffer.discount.totalPrice')}</span>
                <span className="row_value">
                    {formatMoney(
                        (getPromoCodeDiscount(currentDeal, paymentJourneyType)
                            ? storePriceByPaymentMethod + getPromoCodeDiscount(currentDeal, paymentJourneyType)
                            : storePriceByPaymentMethod) +
                            appliedDealerDiscount -
                            (previewDiscount || 0)
                    )}{' '}
                    <PriceSuffix checkPrioritizedTranslation showOnlyPrioritizedTranslation />
                </span>
            </div>
            <div className="row without-padding-top">
                <Input
                    hasError={inputDiscount && !/^[0-9]+$/.test(String(inputDiscount))}
                    value={inputDiscount || ''}
                    onChange={(value: number) => setInputDiscount(value)}
                    label={t('modal.personalizeOffer.discount.input.label')}
                    placeholder={t('modal.personalizeOffer.discount.input.placeholder')}
                    marginTop={20}
                />
                <Button
                    onClick={preview}
                    secondary
                    disabled={!inputDiscount || !/^[0-9]+$/.test(String(inputDiscount))}
                >
                    {t('modal.personalizeOffer.discount.input.button')}
                </Button>
                {inputDiscount && !/^[0-9]+$/.test(String(inputDiscount)) && (
                    <div className="row_error">{t('modal.personalizeOffer.discount.input.error')}</div>
                )}
            </div>
            <button
                onClick={() => {
                    setAmount(null);
                    setPreviewDiscount(null);
                    setInputDiscount(null);
                }}
                className="reset-button without-margin-top"
            >
                <Icon name={Icons.Trash} />
                <span>{t('modal.personalizeOffer.discount.reset')}</span>
            </button>
        </div>
    );
};
