import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { CustomErrorTemplate } from './CustomErrorTemplate';
import { rem } from 'polished';

export const CustomErrorStyled = styled(CustomErrorTemplate)`
    min-height: ${rem(992)};

    @media (max-width: ${breakpoints.md}px) {
        min-height: ${rem(768)};
    }

    @media (max-width: ${breakpoints.sm}px) {
        min-height: ${rem(480)};
    }

    .errorPageWrapper {
        padding: 0;
        margin: 0;
    }

    .errorPage {
        &__content {
            padding: ${rem(40)};
            margin-top: ${rem(60)};
            max-width: ${rem(330)};
        }

        &__content-wrapper {
            width: 100%;
            max-width: ${rem(1440)};
            margin: 0 auto;

            @media (max-width: ${breakpoints.md}px) {
                display: flex;
                justify-content: center;
            }
        }

        &__title {
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            font-size: ${rem(40)};
            line-height: ${rem(36)};
            text-transform: uppercase;
        }

        &__description {
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            font-size: ${({ theme }) => theme.fontSizes.textSupMedium};
            line-height: ${({ theme }) => theme.lineHeights.textSupBig};
        }

        &__link a {
            text-decoration: none;
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            display: block;
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            line-height: ${({ theme }) => theme.lineHeights.textSupMedium};
            text-align: center;
            text-transform: uppercase;
            margin-top: ${rem(20)};
            padding: ${rem(16)} ${rem(22)};
            border: solid 1px;

            &:hover {
                opacity: 0.65;
            }
        }
    }
`;
