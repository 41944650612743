import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePrices } from '@hooks/usePriceCalculator';
import { useJourneyType } from '@hooks/useJourneyType';
import { useCreateDeal } from '@hooks/useCreateDeal';
import { useTranslationByAllowedJourney } from '@hooks/useTranslationByAllowedJourney';
import { useGTM } from '@hooks/useGTM';

import carDetailsDuck from 'src/redux/carDetails/carDetails.duck';
import { IS_B2C, LEAD_FORM_ID } from 'src/constants/main';
import { getTotalOptionOrColorPrice } from '@utils/Price.utils';
import { IOption } from 'src/services/stock/models/stockItems.service.model';
import { FINANCE } from 'src/services/filters/filters';
import { Pages } from 'src/types/pages';
import { GtmData } from 'src/types/gtm';
import DigitalForm from '../DigitalForm';
import { IModalLeadForm } from '.';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';

declare global {
    interface Window {
        getBodyStyle: string;
        getPage: string;
    }
}

const ModalLeadFormTemplate: React.SFC<IModalLeadForm> = (props) => {
    const { currentDeal } = useSelector((state) => {
        const { currentDeal, currentCarDetails } = carDetailsDuck.getOwnState(state);
        return { currentDeal, currentCarDetails };
    });

    const { carDetails, defaultConfiguration } = useCarDetailsDuck();
    const { pushToDataLayer, getBodyStyleLabel, isBasketPage, isTrimPage, isSummaryPage, isConfigPage } = useGTM();
    const { translationByAllowedJourney } = useTranslationByAllowedJourney();

    const {
        cash: { totalPrice },
        finance,
        financeDefault,
    } = usePrices();

    const { paymentJourneyType } = useJourneyType();
    const createDeal = useCreateDeal();

    const { leadFormId, type, mopId, email, page } = props;

    const eventLabel = useMemo(() => translationByAllowedJourney('link', 3, IS_B2C ? '.b2c' : ''), []);

    const currentCarDetails = carDetails || defaultConfiguration;
    const hasCurrectCarDetails = Object.keys(currentCarDetails)?.length > 0;

    const currentPage = useMemo(() => {
        if (isTrimPage) return Pages.trim;
        if (isConfigPage) return 'personnalization';
        if (isSummaryPage) return Pages.summary;
        return Pages.basket;
    }, [isTrimPage, isConfigPage, isSummaryPage]);

    useEffect(() => {
        window.getBodyStyle = getBodyStyleLabel(currentCarDetails);
        window.getPage = currentPage;
    }, [isBasketPage, page]);

    useEffect(() => {
        if (isBasketPage) {
            pushToDataLayer({
                eventCategory: 'SolRetail::Basket',
                eventAction: 'Display::CommercialOffer',
                eventLabel: eventLabel,
            } as GtmData);
        }
    }, [isBasketPage, eventLabel]);

    // When lead form modal opens and no deal is created
    useEffect(() => {
        if (!currentDeal?.token) {
            createDeal();
        }
    }, [currentDeal]);

    const {
        financeProperties: {
            deposit = financeDefault?.financeProperties?.deposit, // deposit taken from sticky bar (just for finance journey)
            duration = financeDefault?.financeProperties?.duration, // finance duration (just for finance journey)
            annualMileage = financeDefault?.financeProperties?.annualMileage, //finance km (just for finance journey)
        } = {},
        basePrice: finalPriceMonthly = financeDefault?.basePrice,
    } = finance || {};

    const financeData = {
        deposit,
        duration,
        annualMileage,
        finalPriceMonthly,
    };

    return (
        <DigitalForm
            formId={leadFormId ?? LEAD_FORM_ID}
            currentCarDetails={currentCarDetails}
            formData={{
                lcdv16: currentCarDetails?.lcdv16,
                vehicleTitle: currentCarDetails?.title,
                exterior: currentCarDetails?.exteriorColour?.title,
                exteriorPrice:
                    hasCurrectCarDetails &&
                    getTotalOptionOrColorPrice(currentCarDetails?.exteriorColour, paymentJourneyType),
                interior: currentCarDetails?.interiorColour?.title,
                interiorPrice:
                    hasCurrectCarDetails &&
                    getTotalOptionOrColorPrice(currentCarDetails?.interiorColour, paymentJourneyType),
                leadTime: currentCarDetails?.maxLeadTime,
                options: currentCarDetails?.options
                    ?.map(
                        (option: IOption) =>
                            `${option?.title}:${getTotalOptionOrColorPrice(option, paymentJourneyType)}`
                    )
                    ?.join('|'), // (pipeline separate values for all options - title + price)
                // E.g. Alarme périmétrique et volumétrique:350|Clima:130
                finalPrice: totalPrice, // Final price taken from sticky bar,
                journey: paymentJourneyType, //use 'cash' or 'finance'
                dealId: currentDeal?.token,
                ...(paymentJourneyType === FINANCE ? financeData : {}),
                type,
                mopId,
                email,
            }}
            {...props}
        />
    );
};

export default ModalLeadFormTemplate;
