import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import FAQsTemplate from './../FAQsTemplate';

export const FAQsStyledOV = styled(FAQsTemplate)`
    padding-top: 10px;
    border-top: none;
    background-color: #fff;

    @media (max-width: ${breakpoints.xs}px) {
        padding: 20px 20px 0 20px;
    }

    .categoryContent {
        p:not(:first-child) {
            margin-top: -1rem;
        }
    }
`;
