import styled from 'styled-components';
import { TextAreaStyled } from '../TextAreaStyled';
import { rem } from 'polished';

export const TextAreaStyledDS = styled(TextAreaStyled)`
    span {
        font-size: ${({ theme }) => theme.fontSizes.h5};
    }

    textarea {
        border: 1px solid ${({ theme }) => theme.colors.black1};
        font-size: ${({ theme }) => theme.fontSizes.h5};
        border-radius: ${rem(4)};
    }
`;
