import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalSessionChangeStyledAC } from './AC/ModalSessionChangeStyled.AC';
import { ModalSessionChangeStyledAP } from './AP/ModalSessionChangeStyled.AP';
import { ModalSessionChangeStyledDS } from './DS/ModalSessionChangeStyled.DS';
import { ModalSessionChangeStyledOV } from './OV/ModalSessionChangeStyled.OV';
import { ModalSessionChangeStyledOV as ModalSessionChangeStyledOVGB } from './OV/GB/ModalSessionChangeStyled.OV';
import { IDeal } from '../../../interfaces/Car';
import { ModalSessionChangeVersion } from '@components/Modal/ModalSessionChange/ModalSessionChangeTemplate';

export interface IModalSessionChange extends SC {
    continueWithNewConfiguration: () => void;
    changeWithPreviousConfiguration: () => void;
    newConfiguration: IDeal;
    previousConfiguration: IDeal;
    version: ModalSessionChangeVersion;
}

export const ModalSessionChange = (props: IModalSessionChange) => {
    if (BRAND === 'AP') return <ModalSessionChangeStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalSessionChangeStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalSessionChangeStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalSessionChangeStyledOVGB {...props} /> : <ModalSessionChangeStyledOV {...props} />;
};
