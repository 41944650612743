import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutMySummaryStyled } from '../CheckoutMySummaryStyled';
import { rem } from 'polished';

export const CheckoutMySummaryStyledOV = styled(CheckoutMySummaryStyled)`
    .grid > .Collapsible .Collapsible {
        &.checkout {
            background: white;
            display: flex;
            flex-direction: column;
            border: 2px solid #ececee;
        }
        &__trigger {
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
            color: ${({ theme }) => theme.colors.black};
            &.is-open {
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='14' viewBox='0 0 26 14' fill='none'%3E %3Cpath d='M1 13L13 1L25 13' stroke='%23000' stroke-width='2' stroke-linejoin='bevel'/%3E %3C/svg%3E");
                }
            }
            &.is-closed {
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='14' viewBox='0 0 26 14' fill='none'%3E %3Cpath d='M1 13L13 1L25 13' stroke='%23000' stroke-width='2' stroke-linejoin='bevel'/%3E %3C/svg%3E");
                }
            }
        }
        &__contentInner {
            @media (min-width: ${breakpoints.md}px) {
                padding: ${rem(20)} ${rem(30)};
            }
        }
    }
`;
