import { rem } from 'polished';
import styled from 'styled-components';
import ModalLeadFormTemplate from './ModalLeadFormTemplate';

export const ModalLeadFormStyled = styled(ModalLeadFormTemplate)`
    color: black;

    .btn-primary {
        border: none;
        white-space: nowrap;
        cursor: pointer;
        text-transform: uppercase;
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        display: block;
        text-align: center;
        max-width: ${rem(250)};
        margin: 1rem 0;
    }
    .checkbox-container,
    .radiocheckbox-container {
        margin-bottom: 0.75rem;
        display: block;
        text-align: justify;
        padding-left: ${rem(30)};
        position: relative;
    }
    .radiobtn-container {
        margin-right: ${rem(20)};
        display: inline-block;
    }
    input[type='text'],
    textarea {
        border: 1px solid ${({ theme }) => theme.colors.grey3};
        font-size: ${rem(14)};
        width: 100%;
        padding: ${rem(3)} ${rem(16)};
    }
    textarea {
        resize: none;
        padding: ${rem(6)} ${rem(16)};
    }
    input[type='text'] {
        height: ${rem(40)};
    }
    .form-field {
        margin-bottom: 1.5rem;
        position: relative;
    }
    .px-info-container {
        display: none;
    }
    .html9 > p {
        text-align: justify;
        color: ${({ theme }) => theme.colors.grey2};
    }
    b {
        font-weight: bold;
        color: black;
    }
    .editmydetail {
        display: none;
    }
    input[type='checkbox'],
    input[type='radio'] {
        appearance: none;
        vertical-align: middle;
        width: ${rem(20)};
        height: ${rem(20)};
        border: 1px solid ${({ theme }) => theme.colors.grey3};
        background-color: ${({ theme }) => theme.colors.white};
        left: 0;
        position: absolute;
        top: 0;

        &:checked::before {
            width: ${rem(14)};
            height: ${rem(14)};
            display: block;
            background-color: ${({ theme }) => theme.colors.primary};
            content: ' ';
            position: relative;
            top: ${rem(2)};
            left: ${rem(2)};
        }
    }

    input[type='radio'] {
        border-radius: 50%;
        &:checked::before {
            border-radius: 50%;
        }
    }
    h4 {
        margin-bottom: 0.5rem;
    }
    .requiredErrorMessage {
        position: relative;
        top: -1.3rem;
        font-size: ${rem(12)};
    }

    .field-block {
        select + .requiredErrorMessage {
            top: 0rem;
        }
    }
`;
