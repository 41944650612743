import { useDispatch, useSelector } from 'react-redux';
import { DealService } from '../services';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useMemo } from 'react';
import { IDealerFee } from '@components/DealerFees/DealerFeesTemplate';
import {CARTE_GRISE_FEE, REGISTRATION_FEES_VALUES} from '../constants/main';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import CarDetailsDuck from 'src/redux/carDetails/carDetails.duck';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import GlobalDuck from 'src/redux/global/global.duck';
import { Redux } from 'src/redux/redux.interface';
import {useGlobalDuck} from "@hooks/useGlobalDuck";

export const useCarteGrise = () => {
    const dispatch = useDispatch();
    const { currentDeal } = useCarDetailsDuck();

    const updateCarteGrise = async (postalCode: string, includeFees?: boolean, selectedFeesValue?: REGISTRATION_FEES_VALUES) => {
        dispatch(UIDuck.setCarteGriseLoading(true));
        dispatch(GlobalDuck.setCarteGriseError(false));
        await DealService.updateDeal(
            {
                postalCode,
                dealerFees: currentDeal?.fullProductConfiguration?.fees,
                ...(typeof includeFees === 'boolean' && { includeFees }),
                ...(selectedFeesValue && { extra_field: { selectedFeesValue } }),
            },
            getSessionIdCookie(),
            true
        )
            .then((response) => {
                dispatch(CarDetailsDuck.setCurrentCarDetails(response.data?.deal?.fullProductConfiguration));
                dispatch(CarDetailsDuck.setCurrentDeal(response.data?.deal));
                return response.data;
            })
            .catch((err) => {
                dispatch(GlobalDuck.setCarteGriseError(true));
            });

        dispatch(UIDuck.setCarteGriseLoading(false));
    };

    const carteGriseFee = useMemo(
        () =>
            currentDeal?.fullProductConfiguration?.fees?.find(
                (dealerFee: IDealerFee) => dealerFee.id === CARTE_GRISE_FEE
            ),
        [currentDeal?.fullProductConfiguration?.fees]
    );

    const { carteGriseError } = useGlobalDuck();
    return { updateCarteGrise, carteGriseError, carteGriseFee };
};
