/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SC, theme } from 'src/styles/theme';
import Button from '@components/Button';
import useTranslations from '@hooks/useTranslations';
import { GTM_DATA_VARIABLES } from 'src/constants/gtm';
import { useGTM } from '@hooks/useGTM';
import { Icon, Icons } from '@components/Icon';
import sessionInfoApi from 'src/services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { EMAIL_REGEX } from '@utils/checkoutInputsValidation';
import Spinner from '@components/Spinner';
import { GtmData } from 'src/types/gtm';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from 'src/context/featureSwitchApp';
import Select from '@components/Select';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { IModalDealerSearchState } from '@components/Modal/ModalDealerSearch/ModalDealerSearchTemplate';
import routes from 'src/constants/routes';
import { useCustomRouter } from '@hooks/useCustomRouter';
import Checkbox from '@components/Checkbox';
import { B2B_FILEDS, civilities, IS_B2B, LANGUAGE, LANGUAGES, ModalVersion } from 'src/constants/main';
import UserService from 'src/services/user/user.service';
import { useAffiliationGtm } from '@hooks/useAffiliationGtm';
import { useFeatureSwitch } from '@hooks/useFeatureSwitch';
import { useDealerDuck } from '@hooks/useDealerDuck';
import CarDetailsDuck from 'src/redux/carDetails/carDetails.duck';
import { B2B_FIELD } from 'src/constants/enums';
import LegalTermsAndConditionsConsent from '@components/LegalTermsAndConditionsConsent';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';
import { LEGAL_CONSENTS_VALUES } from 'src/constants/enums';
import { Pages } from 'src/types/pages';
import Link from '@components/Link';

const ModalIcon = styled(Icon)``;
const Row = styled.div<{ isSalesManIdEnabled?: boolean }>`
    display: flex;
    width: 100%;
    flex-direction: column;

    .row-inner {
        width: 100%;
    }
`;

const Cancel = styled(Button)``;

export interface ModalShareConfigurationDealerCodeProps extends SC {
    closeModal: () => void;
    previousState?: IModalDealerSearchState;
    page?: Pages;
}

export enum SalesManTypes {
    default = 0,
    nsc = 1,
    dealer = 2,
}

interface ISelectOption {
    value: SalesManTypes;
    label: string;
}

export const TESTING_PREFIX = 'modal-share-configuration-dealer-code-';

const ModalShareConfigurationDealerCodeTemplate: FC<ModalShareConfigurationDealerCodeProps> = ({
    closeModal,
    className,
    previousState,
}) => {
    const salesMan = useAffiliationGtm();
    const dealerCodeInputRef = useRef<HTMLInputElement>();
    const emailInputRef = useRef<HTMLInputElement>();
    const firstNameInputRef = useRef<HTMLInputElement>();
    const lastNameInputRef = useRef<HTMLInputElement>();
    const context = useContext(FeatureSwitchContext);
    const companyNameInputRef = useRef<HTMLInputElement>();
    const phoneInputRef = useRef<HTMLInputElement>();
    const salesmanInputRef = useRef<HTMLInputElement>();
    const { t } = useTranslations();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [isCopied, setIsCopied] = useState(false);
    const [salesmanType, setSalesmanType] = useState(SalesManTypes.default);
    const [contactByEmail, setContactByEmail] = useState(Boolean(previousState?.contactByEmail));
    const [contactByMessage, setContactByMessage] = useState(Boolean(previousState?.contactByMessage));
    const [contactByPhone, setContactByPhone] = useState(Boolean(previousState?.contactByPhone));
    const [agreement, setAgreement] = useState(Boolean(previousState?.agreement));
    const [civility, setCivility] = useState(previousState?.civility);
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [showLegalRequiredErrors, setShowLegalRequiredErrors] = useState(false);
    const [selectedLegalValues, setSelectedLegalValues] = useState([]);
    const router = useCustomRouter();

    const modalRef = useRef(null);

    const { isDealerChoiceEnabled } = useFeatureSwitch();

    const currentPath = router.asPath;
    const isBasketPage = currentPath.includes(routes.BASKET);
    const page = isBasketPage ? 'basket' : 'summary';

    const [dropdown, setDropdown] = useState<{ value: SalesManTypes; label: string }>({
        value: SalesManTypes.default,
        label: t('modal.dealerShareConfiguration.dropdownDefault'),
    });

    const [dropdownError, setDropdownError] = useState<boolean>(false);

    const [isSend, setIsSend] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const { pushToDataLayer } = useGTM();

    const [dealerCodeInvalid, setDealerCodeInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [firstNameRequired, setFirstNameRequired] = useState(false);
    const [lastNameRequired, setLastNameRequired] = useState(false);
    const [companyNameRequired, setCompanyNameRequired] = useState(false);
    const [civilityRequired, setCivilityRequired] = useState(false);
    const [salesmanInvalid, setSalesmanInvalid] = useState(false);

    const { dealer } = useDealerDuck();

    const { isStockJourney } = useCarStockJourneyCheck();
    const isSalesManIdEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_SALESMAN_ID_ENABLED);
    const sendLeadToCustomerFirstCrm = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_SEND_LEAD_TO_CUSTOMER_FIRST_CRM
    );
    const [salesmanId, setSalesmanId] = useState<string>('');

    const isDealerSearchEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_DEALER_SEARCH_SHARE_CONFIGURATION_ENABLED
    );

    const legalConsentVersion = getFeatureSwitch(FEATURES_LIST.FEATURE_SWITCH_LEGAL_CONSENT_VERSION, context);

    useEffect(() => {
        if (!isSend) {
            if (isStockJourney && !previousState?.selectedDealerId) {
                dealerCodeInputRef.current.value = currentDeal.fullProductConfiguration.dealers?.[0]?.siteGeo;
                return;
            }

            if (isDealerChoiceEnabled && dealer?.siteGeo) {
                dealerCodeInputRef.current.value = dealer.siteGeo;
                return;
            }

            dealerCodeInputRef.current.value = previousState?.selectedDealerId || '';
            if (salesmanInputRef?.current) {
                salesmanInputRef.current.value = previousState?.salesmanId || '';
            }
            emailInputRef.current.value = previousState?.email || '';
            firstNameInputRef.current.value = previousState?.firstNameInput || '';
            lastNameInputRef.current.value = previousState?.lastNameInput || '';
            if (IS_B2B) {
                companyNameInputRef.current.value = previousState?.companyNameInput || '';
            }
            phoneInputRef.current.value = previousState?.phoneInput || '';
            phoneInputRef.current.value = previousState?.phoneInput || '';
            dropdown.value = previousState?.personalFunction;
            setSalesmanType(previousState?.personalFunction);
            if (isSalesManIdEnabled) setSalesmanId(previousState?.salesmanId);
            dropdown.label = getPersonalFunctionLabelByValue(previousState?.personalFunction || SalesManTypes.default);
        }
    }, []);

    const { currentDeal } = useCarDetailsDuck();

    const copyToClipboard = async (text: string) => {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                return await navigator.clipboard.writeText(text);
            }
            console.error(
                'Error during inserting share link into clipboard. - navigator.clipboard or window.isSecureContext.',
                navigator.clipboard,
                window.isSecureContext
            );
        } catch (e: any) {
            console.error('Error during inserting share link into clipboard.', e);
        }
    };

    const checkSalesManDropdown = useCallback(() => {
        if (!dropdown.value) {
            setDropdownError(true);
            modalRef.current.scrollIntoView?.({ behavior: 'smooth' });
            return false;
        }

        setDropdownError(false);
        return true;
    }, [dropdown.value]);

    const validate = () => {
        const dealerCode = dealerCodeInputRef?.current?.value || '';
        const salesman = salesmanInputRef?.current?.value || '';

        const isInvalidateDealerCode =
            Boolean(isSalesManIdEnabled && salesmanId?.length && !(dealerCode.length <= 10)) ||
            Boolean(dealerCode.length && !(dealerCode.length <= 10)) ||
            Boolean(salesmanType === SalesManTypes.dealer && dealerCode === '');

        const isInvalidSalesManId =
            isSalesManIdEnabled && [SalesManTypes.nsc, SalesManTypes.dealer].includes(salesmanType) && salesman === '';

        return {
            isInvalidateDealerCode,
            isInvalidSalesManId,
            dealerCode,
        };
    };

    const submitAssistedLeadForm = async () => {
        let isValidationErrors = false;
        const { isInvalidateDealerCode, isInvalidSalesManId, dealerCode } = validate();

        setShowLegalRequiredErrors(true);

        setSalesmanInvalid(false);
        if (isInvalidSalesManId) {
            setSalesmanInvalid(true);
            isValidationErrors = true;
        }

        setDealerCodeInvalid(false);

        if (isInvalidateDealerCode) {
            setDealerCodeInvalid(true);
            isValidationErrors = true;
        }

        setCivilityRequired(false);
        if (!civility) {
            setCivilityRequired(true);
            isValidationErrors = true;
        }

        setEmailInvalid(false);
        const email = emailInputRef?.current?.value || '';
        if (!EMAIL_REGEX.test(email)) {
            setEmailInvalid(true);
            isValidationErrors = true;
        }
        if (email) setCustomerEmail(email);

        setFirstNameRequired(false);
        if (!firstNameInputRef?.current?.value) {
            setFirstNameRequired(true);
            isValidationErrors = true;
        }

        setLastNameRequired(false);
        if (!lastNameInputRef?.current?.value) {
            setLastNameRequired(true);
            isValidationErrors = true;
        }

        if (IS_B2B) {
            setCompanyNameRequired(false);
            if (!companyNameInputRef?.current?.value) {
                setCompanyNameRequired(true);
                isValidationErrors = true;
            }
        }

        if (!checkSalesManDropdown() || isValidationErrors) {
            return;
        }

        if (
            legalConsentVersion === ModalVersion.v2 &&
            selectedLegalValues.filter(
                (value) =>
                    !value.includes(LEGAL_CONSENTS_VALUES.AGREE) && !value.includes(LEGAL_CONSENTS_VALUES.NOT_AGREE)
            ).length > 0
        ) {
            return;
        }

        setIsLoading(true);

        const crmData = {
            salutation: civility,
            firstName: firstNameInputRef?.current?.value,
            lastName: lastNameInputRef?.current?.value,
            ...(IS_B2B && { companyName: companyNameInputRef?.current?.value }),
            mobilePersonalTelephone: phoneInputRef?.current?.value,
            email: emailInputRef?.current?.value,
            postalCode: '',
            ...(legalConsentVersion === ModalVersion.v2 && {
                checkBox1Meaning: 'MkgFCA',
                checkBox1Value: selectedLegalValues[0].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
                checkBox2Meaning: 'ProfFCA',
                checkBox2Value: selectedLegalValues[1].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
                checkBox3Meaning: 'MkgThirdPartyFCA',
                checkBox3Value: selectedLegalValues[2].split(':')[1] === LEGAL_CONSENTS_VALUES.AGREE,
            }),
            ...((!legalConsentVersion || legalConsentVersion != ModalVersion.v2) && {
                checkBox1Meaning: 'MkgBrandMail',
                checkBox1Value: !!contactByEmail,
                checkBox2Meaning: 'MkgBrandSMS',
                checkBox2Value: !!contactByMessage,
                checkBox3Meaning: 'MkgBrandPhone',
                checkBox3Value: !!contactByPhone,
            }),
            lcdvInterest: currentDeal.fullProductConfiguration.lcdv16,
            type: 'LEAD_ASSISTED_SALES_FORM',
        };
        try {
            const { data } = await sessionInfoApi.shareConfigurationEmail(
                email,
                getSessionIdCookie(),
                dealerCode,
                isSalesManIdEnabled ? salesmanId : false,
                page,
                salesmanType,
                true,
                crmData.salutation,
                crmData.firstName,
                crmData.lastName,
                crmData.companyName,
                crmData.mobilePersonalTelephone,
                crmData.checkBox1Meaning,
                crmData.checkBox1Value,
                crmData.checkBox2Meaning,
                crmData.checkBox2Value,
                crmData.checkBox3Meaning,
                crmData.checkBox3Value
            );

            if (sendLeadToCustomerFirstCrm) {
                await UserService.sendDataToCRM(getSessionIdCookie(), { ...crmData, mopId: data?.mopId });
            }

            if (!data?.success && isInvalidateDealerCode) {
                setDealerCodeInvalid(true);
                setIsLoading(false);
                return;
            }

            if (!data?.success) {
                setIsLoading(false);
                setIsFailed(true);
                setIsSend(true);
                return;
            }

            pushToDataLayer({
                ...GTM_DATA_VARIABLES.SEND_CTA_BUTTON,
                event: 'uaevent',
                eventLabel: t('modal.dealerShareConfiguration.confirm'),
                affiliation: salesMan,
            } as GtmData);

            batch(() => {
                dispatch(CarDetailsDuck.setCurrentDeal(data.deal));
                dispatch(CarDetailsDuck.setCurrentCarDetails(data.deal.fullProductConfiguration));
            });
            setIsLoading(false);
            setIsSend(true);
        } catch (e: any) {
            setIsLoading(false);
        }
    };

    const submitDealerShareForm = async () => {
        const { isInvalidateDealerCode, isInvalidSalesManId, dealerCode } = validate();
        let isValidationErrors = false;

        setSalesmanInvalid(false);
        if (isInvalidSalesManId) {
            setSalesmanInvalid(true);
            isValidationErrors = true;
        }

        setDealerCodeInvalid(false);
        if (isInvalidateDealerCode) {
            setDealerCodeInvalid(true);
            isValidationErrors = true;
        }

        // these fields are not needed for this kind of form apply
        setEmailInvalid(false);
        setFirstNameRequired(false);
        setLastNameRequired(false);
        setCompanyNameRequired(false);
        setCivilityRequired(false);

        if (!checkSalesManDropdown() || isValidationErrors) {
            return;
        }

        setIsLoading(true);
        try {
            const { data } = await sessionInfoApi.shareConfiguration(
                '',
                getSessionIdCookie(),
                dealerCode,
                isSalesManIdEnabled ? salesmanId : false,
                page,
                salesmanType,
                true
            );

            if (!data?.success) {
                setDealerCodeInvalid(true);
                setIsLoading(false);
                return;
            }

            pushToDataLayer({
                ...GTM_DATA_VARIABLES.SEND_CTA_BUTTON,
                event: 'uaevent',
                eventLabel: t('modal.dealerShareConfiguration.register'),
                affiliation: salesMan,
            } as GtmData);

            batch(() => {
                dispatch(CarDetailsDuck.setCurrentDeal(data.deal));
                dispatch(CarDetailsDuck.setCurrentCarDetails(data.deal.fullProductConfiguration));
                dispatch(UIDuck.closeModal());
            });
            setIsLoading(false);
            setIsSend(true);
        } catch (e: any) {
            setDealerCodeInvalid(true);
            setIsLoading(false);
        }
        return;
    };

    const copyLink = async (e: any) => {
        const { isInvalidateDealerCode, isInvalidSalesManId, dealerCode } = validate();
        if (isInvalidateDealerCode) {
            setDealerCodeInvalid(true);
            return;
        }

        if (isInvalidSalesManId) {
            setSalesmanInvalid(true);
            return;
        }

        if (!checkSalesManDropdown()) {
            return;
        }

        pushToDataLayer({
            ...GTM_DATA_VARIABLES.GET_THE_LINK_CTA,
            event: 'uaevent',
            eventLabel: t('modal.dealerShareConfiguration.copyLink.link'),
            affiliation: salesMan,
        } as GtmData);

        setDealerCodeInvalid(false);
        setSalesmanInvalid(false);
        setIsLoading(true);
        const { data } = await sessionInfoApi.getShareConfigurationLink(
            getSessionIdCookie(),
            dealerCode,
            salesmanId,
            salesmanType,
            true
        );

        if (!data.success) {
            setDealerCodeInvalid(true);
            setIsLoading(false);
            return;
        }
        await copyToClipboard(data?.link);
        setIsCopied(true);
        setIsLoading(false);
        return;
    };

    const b2bComponent = (fieldName: B2B_FIELD) => {
        if (fieldName === B2B_FIELD.COMPANY_NAME) {
            return (
                <Row>
                    <div className="input-section row-inner">
                        <input
                            ref={companyNameInputRef}
                            placeholder={t('modal.dealerShareConfiguration.companyName.placeholder')}
                            className={companyNameRequired ? 'error' : ''}
                        />
                        {companyNameRequired && (
                            <span className="companyNameError">
                                {t('modal.dealerShareConfiguration.companyName.required.error')}
                            </span>
                        )}
                    </div>
                </Row>
            );
        }

        return null;
    };

    const moveToSearchModal = () => {
        dispatch(
            UIDuck.openModal({
                modalType: UIDuck.MODAL_TYPES.DEALER_SEARCH,
                data: {
                    previousState: {
                        selectedDealerId: dealerCodeInputRef?.current?.value,
                        email: emailInputRef?.current?.value,
                        firstNameInput: firstNameInputRef?.current?.value,
                        lastNameInput: lastNameInputRef?.current?.value,
                        companyName: IS_B2B && companyNameInputRef?.current?.value,
                        phoneInput: phoneInputRef?.current?.value,
                        salesmanId: salesmanInputRef?.current?.value,
                        personalFunction: dropdown.value,
                        civility,
                        contactByEmail,
                        contactByMessage,
                        contactByPhone,
                        agreement,
                    },
                },
                modalVersion: ModalVersion.v2,
            })
        );
    };

    const getPersonalFunctionLabelByValue = (value: number) => {
        if (value === SalesManTypes.nsc) {
            return t('modal.dealerShareConfiguration.dropdownOne');
        }

        if (value === SalesManTypes.dealer) {
            return t('modal.dealerShareConfiguration.dropdownTwo');
        }

        return t('modal.dealerShareConfiguration.dropdownDefault');
    };

    const dealerCodePlaceholder = () =>
        t('modal.dealerShareConfiguration.dealerCode.placeholder').replace(!salesmanId?.length ? '*' : '', '');
    return (
        <div ref={modalRef} className={className} data-testid={`${TESTING_PREFIX}wrapper`}>
            {!isSend ? (
                <>
                    <div className="share-wrapper">
                        <p
                            className="description"
                            dangerouslySetInnerHTML={{ __html: t('modal.dealerShareConfiguration.description') }}
                        />
                        <div className="input-wrapper">
                            <div className="input-section">
                                <div className="section-header">{t('modal.dealerShareConfiguration.titleOne')}</div>
                                <Select
                                    className={`dropdown ${dropdownError ? 'dropdownHasError' : ''}`}
                                    value={dropdown}
                                    onChange={(e: ISelectOption) => {
                                        setDropdown(e);
                                        setDropdownError(false);
                                        setSalesmanType(e.value);
                                        dispatch(UIDuck.setSalesManType(e.value));
                                    }}
                                    options={[
                                        {
                                            value: SalesManTypes.nsc,
                                            label: getPersonalFunctionLabelByValue(SalesManTypes.nsc),
                                        },
                                        {
                                            value: SalesManTypes.dealer,
                                            label: getPersonalFunctionLabelByValue(SalesManTypes.dealer),
                                        },
                                    ]}
                                    arrowColor={theme.colors.white}
                                    isSearchable={false}
                                />
                                {dropdownError && (
                                    <span
                                        data-testid={`${TESTING_PREFIX}salesManTypeErrorMsg`}
                                        className="salesManTypeErrorMsg"
                                    >
                                        {t('modal.dealerShareConfiguration.salesManType.error')}
                                    </span>
                                )}
                            </div>
                            <Row isSalesManIdEnabled={isSalesManIdEnabled}>
                                {isSalesManIdEnabled && (
                                    <div className="input-section row-inner">
                                        <input
                                            ref={salesmanInputRef}
                                            onKeyUp={(e) => setSalesmanId((e.target as HTMLInputElement).value)}
                                            placeholder={t('modal.dealerShareConfiguration.dealerCode.salesmanID')}
                                            className={salesmanInvalid ? 'error' : ''}
                                        />
                                        {salesmanInvalid && (
                                            <span className="dealerCodeError">
                                                {t('modal.dealerShareConfiguration.salesMan.error')}
                                            </span>
                                        )}
                                    </div>
                                )}
                                <div className="input-section row-inner">
                                    <input
                                        disabled={isDealerChoiceEnabled || isStockJourney}
                                        ref={dealerCodeInputRef}
                                        placeholder={dealerCodePlaceholder()}
                                        className={dealerCodeInvalid ? 'error' : ''}
                                    />
                                    {!isDealerChoiceEnabled && !isStockJourney && isDealerSearchEnabled && (
                                        <button className="searchSection" onClick={moveToSearchModal}>
                                            <Icon
                                                width={30}
                                                height={30}
                                                name={dealerCodeInputRef?.current?.value ? Icons.edit : Icons.Search}
                                            />
                                        </button>
                                    )}
                                    {dealerCodeInvalid && (
                                        <span className="dealerCodeError">
                                            {t('modal.dealerShareConfiguration.dealerCode.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <Button
                                    data-testid={`${TESTING_PREFIX}submitDealerShareForm`}
                                    className="callToAction"
                                    onClick={submitDealerShareForm}
                                    primary
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <Spinner size={16} border={2} color="white" />
                                    ) : (
                                        <span>{t('modal.dealerShareConfiguration.register')}</span>
                                    )}
                                </Button>
                            </Row>
                            <div className="separator" />
                            <Row>
                                <div className="input-section row-inner">
                                    <div className="section-header">{t('modal.dealerShareConfiguration.titleTwo')}</div>
                                    {/*<div className="section-link">Download the PDF guide</div>*/}
                                    <div className="civilitySection">
                                        {civilities[LANGUAGE as LANGUAGES]?.map(
                                            (civilityLabel: string, index: number) => (
                                                <div key={index} className="civility">
                                                    <div
                                                        onClick={() => setCivility(civilityLabel)}
                                                        className={`check ${civilityRequired ? 'checkError' : ''}`}
                                                    >
                                                        {civility === civilityLabel && <div className="selected" />}
                                                    </div>
                                                    <span>{civilityLabel}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="civilitySectionError">
                                        {civilityRequired && (
                                            <span className="civilityError">
                                                {t('modal.dealerShareConfiguration.civility.required.error')}
                                            </span>
                                        )}
                                    </div>
                                    <input
                                        ref={emailInputRef}
                                        placeholder={t('modal.dealerShareConfiguration.email.placeholder')}
                                        className={emailInvalid ? 'error' : ''}
                                    />
                                    {emailInvalid && (
                                        <span className="emailError">
                                            {t('modal.dealerShareConfiguration.email.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <div className="input-section row-inner">
                                    <input
                                        ref={firstNameInputRef}
                                        placeholder={t('modal.dealerShareConfiguration.firstName.placeholder')}
                                        className={firstNameRequired ? 'error' : ''}
                                    />
                                    {firstNameRequired && (
                                        <span className="emailError">
                                            {t('modal.dealerShareConfiguration.firstName.required.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <div className="input-section row-inner">
                                    <input
                                        ref={lastNameInputRef}
                                        placeholder={t('modal.dealerShareConfiguration.lastName.placeholder')}
                                        className={lastNameRequired ? 'error' : ''}
                                    />
                                    {lastNameRequired && (
                                        <span className="emailError">
                                            {t('modal.dealerShareConfiguration.lastName.required.error')}
                                        </span>
                                    )}
                                </div>
                            </Row>
                            {IS_B2B &&
                                B2B_FILEDS &&
                                B2B_FILEDS?.length !== 0 &&
                                B2B_FILEDS.map((fieldName) => b2bComponent(fieldName))}
                            <Row>
                                <div className="input-section row-inner">
                                    <input
                                        ref={phoneInputRef}
                                        placeholder={t('modal.dealerShareConfiguration.phone.placeholder')}
                                    />
                                </div>
                            </Row>
                            {!legalConsentVersion ||
                                (legalConsentVersion === ModalVersion.v1 && (
                                    <>
                                        <div className="checkboxSection-title">
                                            <p>{t('modal.dealerShareConfiguration.contact.title')}</p>
                                        </div>
                                        <div className="checkboxSection">
                                            <Checkbox
                                                name="mail"
                                                checked={contactByEmail}
                                                onChange={() => setContactByEmail(!contactByEmail)}
                                            >
                                                {t('modal.dealerShareConfiguration.contact.email')}
                                            </Checkbox>
                                            <Checkbox
                                                name="sms"
                                                checked={contactByMessage}
                                                onChange={() => setContactByMessage(!contactByMessage)}
                                            >
                                                {t('modal.dealerShareConfiguration.contact.message')}
                                            </Checkbox>
                                            <Checkbox
                                                name="phone"
                                                checked={contactByPhone}
                                                onChange={() => setContactByPhone(!contactByPhone)}
                                            >
                                                {t('modal.dealerShareConfiguration.contact.phone')}
                                            </Checkbox>
                                        </div>
                                    </>
                                ))}
                            {legalConsentVersion === ModalVersion.v2 && (
                                <LegalTermsAndConditionsConsent
                                    showRequiredErrors={showLegalRequiredErrors}
                                    setSelectedLegalValues={setSelectedLegalValues}
                                />
                            )}
                            <div className="checkboxSection agreement">
                                <Checkbox
                                    name="agreement"
                                    checked={agreement}
                                    onChange={() => setAgreement(!agreement)}
                                >
                                    {t('modal.dealerShareConfiguration.contact.verify')}
                                </Checkbox>
                            </div>
                        </div>
                        <Button
                            className="callToAction"
                            onClick={submitAssistedLeadForm}
                            data-testid={`${TESTING_PREFIX}submitAssistedLeadForm`}
                            primary
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Spinner size={16} border={2} color="white" />
                            ) : (
                                <span>{t('modal.dealerShareConfiguration.confirm')}</span>
                            )}
                        </Button>
                        <div className="footer">
                            <div className="footer-wrapper">
                                <span className="footer-description">
                                    {t('modal.dealerShareConfiguration.copyLink.description')}
                                </span>
                                <Link
                                    marginTop={5}
                                    marginBottom={5}
                                    label={t('modal.dealerShareConfiguration.copyLink.link')}
                                    onClick={copyLink}
                                    withArrowIcon
                                    primary
                                    dataTestId={`${TESTING_PREFIX}copyLink_link`}
                                />
                            </div>
                            <p className="mandatory-description">{t('modal.dealerShareConfiguration.mandatory')}</p>
                        </div>
                    </div>
                    {isCopied && (
                        <div className="copy-link-text">
                            <Icon height={10} name={Icons.Check} />{' '}
                            {t('modal.dealerShareConfiguration.copyLink.success')}
                        </div>
                    )}
                </>
            ) : isFailed ? (
                <div className="sent-wrapper">
                    <ModalIcon name={Icons.AlertOutlined} width={72} height={72} />
                    <div className="text">{t('modal.personalizeShareConfiguration.message.error')}</div>
                    <Cancel onClick={closeModal}>{t('shareConfiguration.modal.closeBtn')}</Cancel>
                </div>
            ) : (
                <div className="sent-wrapper">
                    <ModalIcon name={Icons.ShareConfigurationModalIcon} width={72} height={72} />
                    <div className="text">{t('shareConfiguration.modal.message', { email: customerEmail || '' })}</div>
                    <Cancel onClick={closeModal}>{t('shareConfiguration.modal.closeBtn')}</Cancel>
                </div>
            )}
        </div>
    );
};

export default ModalShareConfigurationDealerCodeTemplate;
