import dynamic from 'next/dynamic';
import React, { FC, memo, useMemo } from 'react';
import { CONSUMPTION_CO2_KEY } from '../../constants/characteristics';
import { IConfigurableCar } from '../../interfaces/Car';
import { IInitializeWidgetConfig, IPxWidgetProps } from '../../partExchange/interfaces/PxWidget';
import { EMPLOYEE_PRICE_TYPE } from '../../services/filters/filters';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useJourneyType } from '@hooks/useJourneyType';
import { useIgnorePXBonus } from '../../partExchange/hooks';
import { FEATURES_LIST } from '../../context/featureSwitchApp';

const PxWidgetComponent = dynamic(() => import('../../partExchange/components/PxWidget'), { ssr: false });

export interface IPartExchangeWidgetProps extends Omit<IPxWidgetProps, 'config'> {
    car: IConfigurableCar;
    basket?: boolean;
    mopId?: string;
    partExchangeId?: string;
    selectionMode?: boolean;
}

const PartExchangeWidget: FC<IPartExchangeWidgetProps> = memo((props) => {
    const {
        car,
        dealId,
        mopId = null,
        basket = false,
        asMenuLink,
        asUploadButton,
        allowPxFinalization,
        hideAdditionalInfo,
        clearSession,
        partExchangeId,
        allowDeleteEstimation,
        selectionMode = false,
    } = props;
    const { engine, lcdv16: lcdv, fuelCode: energy, prices } = car;
    const co2: string = String(
        (engine.specs?.find((spec: { key: string }) => spec.key === CONSUMPTION_CO2_KEY) || {}).value!
    );

    const { shouldIgnorePXBonus: shouldIgnorePromo } = useIgnorePXBonus();
    const { paymentJourneyType } = useJourneyType();
    const isPxFinalizationEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_PX_FINALIZATION_ENABLED);

    const widgetConfig: IInitializeWidgetConfig = useMemo(
        () => ({
            co2,
            energy,
            lcdv,
            widgetMode: 'view',
            ncPrice: Number(prices.find((price) => price.type === EMPLOYEE_PRICE_TYPE).basePrice),
            budgetType: paymentJourneyType,
            basket,
            mopId,
            partExchangeId,
            ignorePromo: shouldIgnorePromo ? '1' : '0',
            allowPxFinalization: isPxFinalizationEnabled,
        }),
        [partExchangeId, isPxFinalizationEnabled, mopId, paymentJourneyType, shouldIgnorePromo]
    );

    return (
        <PxWidgetComponent
            dealId={dealId}
            config={widgetConfig}
            asMenuLink={asMenuLink}
            asUploadButton={asUploadButton}
            clearSession={clearSession}
            hideAdditionalInfo={hideAdditionalInfo}
            allowPxFinalization={allowPxFinalization && isPxFinalizationEnabled}
            allowDeleteEstimation={allowDeleteEstimation}
            selectionMode={selectionMode}
        />
    );
});
PartExchangeWidget.displayName = 'PartExchangeWidget';

export default PartExchangeWidget;
