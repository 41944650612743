import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { ButtonStyled } from '../ButtonStyled';
import { rem } from 'polished';

export const ButtonStyledOV = styled(ButtonStyled)`
    padding: 0 ${rem(24)};
    height: ${rem(48)};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    text-transform: uppercase;

    ${({ primary }) =>
        primary &&
        css`
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.black};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.black};
                color: ${({ theme }) => theme.colors.white};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.white};
                    }
                }
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            background: ${({ theme }) => theme.colors.pureWhite};
            border: 1px solid ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.black};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.white};
                background: ${({ theme }) => theme.colors.black};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.white};
                    }
                }
            }
        `}

    ${({ primary, disabled }) =>
        primary &&
        disabled &&
        css`
            color: ${({ theme }) => theme.colors.grey10};
            background: ${({ theme }) => theme.colors.grey11};
        `}

    ${({ secondary, disabled }) =>
        secondary &&
        disabled &&
        css`
            opacity: 0.4;
        `}
`;
