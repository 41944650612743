import { SalesManTypes } from '@components/Modal/ModalShareConfigurationDealerCode/ModalShareConfigurationDealerCodeTemplate';
import { useSelector } from 'react-redux';
import queryString from 'querystring';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import Cookies from 'js-cookie';

export const useAffiliationGtm = () => {
    const salesMan = useSelector((state) => UIDuck.getSalesManType(state));
    const salesmanType = Cookies.get('SESSION_EXTRA_FIELDS');

    switch (true) {
        case [Number(queryString.parse(salesmanType)?.salesmanType), salesMan].includes(SalesManTypes.dealer): {
            return 'Dealers Assisted';
        }
        case [Number(queryString.parse(salesmanType)?.salesmanType), salesMan].includes(SalesManTypes.nsc): {
            return 'Esaler Assisted';
        }
        default: {
            return undefined;
        }
    }
};
