export enum Pages {
    trim = 'trim',
    summary = 'summary',
    basket = 'basket',
    car = 'car',
    basketV2 = 'basketV2',
    myAccount = 'my-account',
    dealer = 'dealer',
    checkout = 'checkout',
    configurator = 'selector',
}
