import { PLAUSIBLE_HEAD_SCRIPT_ENABLED } from 'src/constants/main';
import { useCallback } from 'react';

export enum UserEvent {
    HelpNeeded = 'help_needed',
    ContactRequested = 'contact_requested',
    SaveCarSelector = 'save_car_selector',
    SendCarConfiguration = 'send_car_configuration',
    ContinueCtaBasket = 'continue_cta_basket',
    SaveCarBasket = 'save_car_basket',
    TrimComparatorOpen = 'trim_comparator_open',
    TrimComparatorSelectVehicle = 'trim_comparator_select_vehicle',
    TrimComparatorEquipmentDetail = 'trim_comparator_equipment_detail',
    EngineComparatorOpenFromConfigPage = 'engine_comparator_open_from_config_page',
    EngineComparatorOpenFromFeatures = 'engine_comparator_open_from_features',
    TrimDetailsOpen = 'trim_details_open',
    StandardEquipmentDetailsOpenFromConfigPage = 'standard_equipment_details_open_from_config_page',
    OpenLeadFormAfterShareBasket = 'open_lead_form_after_share_basket',
    SubmitLeadFormAfterShareBasket = 'submit_lead_form_after_share_basket',
    ShowOpeningHoursOnDealerPage = 'show_opening_hours_on_dealer_page',
    SelectAssistedBankJourneyType = 'select_assisted_bank_journey_type',
    SelectDirectBankJourneyType = 'select_direct_bank_journey_type',
    OpenFinanceWidgetFromConfigPage = 'open_finance_widget_from_config_page',
    SummaryPageOpen = 'summary_page_open',
    SelectUpsellOnSummaryPage = 'select_upsell_on_summary_page',
    CheckoutDisabledModalOpen = 'checkout_disabled_modal_open',
}

declare global {
    interface Window {
        plausible: (userEvent: UserEvent) => void;
    }
}

export const usePlausible = () => {
    const plausibleUserEvent = useCallback(
        (userEvent: UserEvent) => {
            if (PLAUSIBLE_HEAD_SCRIPT_ENABLED && window.plausible) {
                console.log('Plausible event', userEvent);
                window.plausible(userEvent);
            }

            return null;
        },
        [PLAUSIBLE_HEAD_SCRIPT_ENABLED]
    );

    return {
        plausibleUserEvent,
    };
};
