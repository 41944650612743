import React, { FC } from 'react';
import { SC } from '../../styles/theme';
import useTranslations from '@hooks/useTranslations';
import { useUserDuck } from '@hooks/useUserDuck';
import sessionInfoApi from '../../services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import UserDuck from '../../redux/user/user.duck';
import { IS_CLIENT } from '../../constants/main';
import { BRANDID_LOGOUT_URL, SAML_LOGOUT_URL, SESSION_COOKIE_NAME } from '../../constants/myAccount';
import routes from '../../constants/routes';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

interface AgentToolBarProps extends SC {}

const AgentToolBarTemplate: FC<AgentToolBarProps> = ({ className }) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const { userAuth } = useUserDuck();

    const logout = async () => {
        dispatch(UserDuck.setIsLoggingOut(true));
        await sessionInfoApi.logout(getSessionIdCookie());

        if (IS_CLIENT) {
            window.open(SAML_LOGOUT_URL, '_blank');
            Cookies.remove(SESSION_COOKIE_NAME);
            window.location.href = BRANDID_LOGOUT_URL.replace(
                '{redirectUrlLogout}',
                `${window.location.host}${routes.ROOT}`
            );
        }
        dispatch(UserDuck.actionSetUserLogged(false));
    };

    return (
        <div className={className}>
            <span className="message">{t('dealer.toolbar.welcome')}</span>
            <span className="name">
                {userAuth?.detail?.firstName} {userAuth?.detail?.lastName}
            </span>
            <button onClick={logout} className="logoutButton">
                {t('dealer.toolbar.disconnect')}
            </button>
        </div>
    );
};

export default AgentToolBarTemplate;
