import React, { Dispatch, FC, memo, SetStateAction } from 'react';
import { HandledComponentError } from '@components/ErrorHandling';
import { useTranslations } from 'src/hooks/useTranslations';
import { useTheme } from 'src/hooks/useTheme';
import Button from 'src/components/Button';
import { EmailInput } from 'src/components/EmailInput';
import { IncorrectEmailMessage, FetchErrorMessage, SuccessMessage } from './Messages';
import { useForm } from './useForm';
import Spinner from 'src/components/Spinner';
import { isBrandDS, isBrandOV, isMarketGB } from '../../../constants/main';

export type SubmitProps = {
    email: string;
    setFetchError: Dispatch<SetStateAction<boolean>>;
    setIsSuccess: Dispatch<SetStateAction<boolean>>;
    setIsFetching: Dispatch<SetStateAction<boolean>>;
};

export type Props = {
    page?: string;
    submit?: (props: SubmitProps) => void;
};

export const Form: FC<Props> = memo(({ page, submit }) => {
    const { t } = useTranslations();
    const theme = useTheme();

    const { email, fetchError, isFetching, isSuccess, submitLabel, onChangeEmail, onSubmit } = useForm({
        page,
        submit,
    });

    if (isSuccess) return <SuccessMessage />;

    try {
        return (
            <>
                <form className="form" onSubmit={onSubmit}>
                    <div className="formRow">
                        <EmailInput
                            onChange={onChangeEmail}
                            placeholder={t('shareConfiguration.placeholder')}
                            isFetching={isFetching}
                            ignoreValidity
                        />
                        {!isFetching && fetchError && <FetchErrorMessage />}
                        {!email.isValid && <IncorrectEmailMessage />}
                        <Button primary disabled={isFetching}>
                            {isFetching ? (
                                <Spinner
                                    size={14}
                                    border={3}
                                    color={isBrandOV && !isMarketGB ? theme.colors.black : theme.colors.white}
                                />
                            ) : (
                                <span>{submitLabel}</span>
                            )}
                        </Button>
                    </div>
                </form>
            </>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});

Form.displayName = 'ShareConfigurationForm';
