import styled from 'styled-components';
import TextAreaTemplate from './TextAreaTemplate';
import { rem } from 'polished';

export const TextAreaStyled = styled(TextAreaTemplate)`
    margin-top: ${(props) => (props.marginTop ? rem(props.marginTop) : 0)};
    margin-bottom: ${(props) => (props.marginBottom ? rem(props.marginBottom) : 0)};

    span {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        display: block;
        padding-bottom: ${rem(8)};
    }

    textarea {
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colors.black};
        padding: ${rem(16)};
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }
`;
