import React from 'react';
import { BRAND, isMarketGB } from '../../../constants/main';
import { CharacteristicsStyledAC } from './AC/CharacteristicsStyled.AC';
import { CharacteristicsStyledAP } from './AP/CharacteristicsStyled.AP';
import { CharacteristicsStyledDS } from './DS/CharacteristicsStyled.DS';
import { CharacteristicsStyledOV } from './OV/CharacteristicsStyled.OV';
import { CharacteristicsStyledOV as CharacteristicsPropsStyledOVGB } from './OV/GB/CharacteristicsStyled.OV';
import { CharacteristicsStyled } from './CharacteristicsStyled';
import { SC } from '../../../styles/theme';
import { ICarDetail } from '../../../redux/carDetails/carDetails.duck.interface';

export interface ITechnicalSpecifications {
    category: string;
    key: string;
    order: number;
    data: ITechnicalSpecificationsData[];
}

export interface ITechnicalSpecificationsData {
    label: string;
    value: string;
    description?: string;
    key: string;
    order: number;
    tires?: any;
    images?: { url: string }[];
}

export type CharacteristicsProps = SC & {
    carDetails?: ICarDetail;
};

const Characteristics = (props: CharacteristicsProps) => {
    if (BRAND === 'OV') return isMarketGB ? <CharacteristicsPropsStyledOVGB {...props} /> : <CharacteristicsStyledOV {...props} />;
    if (BRAND === 'AC') return <CharacteristicsStyledAC {...props} />;
    if (BRAND === 'AP') return <CharacteristicsStyledAP {...props} />;
    if (BRAND === 'DS') return <CharacteristicsStyledDS {...props} />;
    return <CharacteristicsStyled {...props} />;
};

export default Characteristics;
