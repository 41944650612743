import { rem } from 'polished';
import styled from 'styled-components';

export const CloseButton = styled.button`
    position: absolute;
    left: unset;
    background: none;
    border: none;
    top: 0;
    right: 0;
    padding: ${rem(6)};
    font-size: ${rem(20)};
    color: ${({ theme }) => theme.colors.black};
    z-index: 999999;
    cursor: pointer;
`;

export const BackButton = styled.button`
    position: absolute;
    top: ${rem(15)};
    left: ${rem(10)};
    display: flex;
    flex-direction: row;
    border: none;
    background: none;
    font-size: ${({ theme }) => theme.fontSizes.textTiny};
    padding: 0;
    text-transform: uppercase;
    cursor: pointer;
    align-items: center;

    svg {
        margin-top: ${rem(2)};
        margin-right: ${rem(6)};
        width: ${rem(25)};
        height: ${rem(25)};
        transform: rotate(-90deg);
    }
`;
