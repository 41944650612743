import styled from 'styled-components';
import LegalTermsAndConditionsConsentTemplate from './LegalTermsAndConditionsConsentTemplate';
import { rem } from 'polished';

export const LegalTermsAndConditionsConsentStyled = styled(LegalTermsAndConditionsConsentTemplate)`
    margin: ${rem(32)} 0 ${rem(38)} 0;

    .section {
        margin: ${rem(18)} 0;

        &_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100% !important;
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
            padding: ${rem(12)} ${rem(16)};

            span {
                font-size: ${rem(15)};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }

            .isOpen {
                svg {
                    path {
                        stroke-width: 4;
                    }
                }
            }

            svg {
                width: ${rem(20)};
                height: ${rem(20)};

                path {
                    stroke: ${({ theme }) => theme.colors.red};
                }
            }
        }

        &_description {
            color: ${({ theme }) => theme.colors.grey5};
            font-size: ${rem(16)};
            padding: ${rem(16)} ${rem(16)} 0 ${rem(16)};
            line-height: ${rem(18)};
        }

        &_agreements {
            display: flex;
            flex-direction: row;
            padding-top: ${rem(16)};

            &_agreement {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: ${rem(20)};

                input {
                    width: ${rem(20)} !important;
                    height: ${rem(20)} !important;
                    margin-right: ${rem(10)} !important;
                    border-color: ${({ theme }) => theme.colors.grey2};
                    accent-color: ${({ theme }) => theme.colors.blue2};
                    cursor: pointer;
                }

                &.error {
                    input {
                        border-color: ${({ theme }) => theme.colors.red1};
                    }
                }
            }

            &_error {
                font-size: ${rem(14)};
                color: ${({ theme }) => theme.colors.danger};
                margin-top: ${rem(8)};
                display: block;
            }
        }
    }

    .legend {
        color: ${({ theme }) => theme.colors.grey5};
        font-size: ${rem(16)};
        line-height: ${rem(18)};
        padding-bottom: ${rem(18)};
    }
`;
