import styled from 'styled-components';
import { rem } from 'polished';
import { ModalOfferExpiredTemplate } from './ModalOfferExpiredTemplate';

export const ModalOfferExpiredStyled = styled(ModalOfferExpiredTemplate)`
    .dealerSection,
    .eSellerSection {
        display: flex;
        flex-direction: column;
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        margin-top: ${rem(10)};
        line-height: ${rem(16)};

        &_title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            margin-bottom: ${rem(5)};
        }

        &_description {
            margin: ${rem(3)} 0 ${rem(10)} 0;
        }

        &_phone {
            margin-top: ${rem(2)};
        }

        &_callUs {
            cursor: pointer;

            svg {
                width: ${rem(12)};
                height: ${rem(12)};
                display: inline-block;
                margin-left: ${rem(5)};

                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};
                text-decoration: underline;
            }
        }
    }
`;
