import styled from 'styled-components';
import { MonthlyPriceLabelStyled } from '../MonthlyPriceLabelStyled';
import { AprSizes } from '@components/MonthlyPriceLabel';
import { rem } from 'polished';

export const MonthlyPriceLabelStyledDS = styled(MonthlyPriceLabelStyled)`
    .aprValue,
    .aprDescription {
        ${(prop) => (prop.size === AprSizes.LARGE ? `font-size: ${prop.theme.fontSizes.h5}` : '')};
        ${(prop) => (prop.size === AprSizes.MEDIUM ? `font-size: ${prop.theme.fontSizes.h6}` : '')};
        ${(prop) => (prop.size === AprSizes.SMALL ? `font-size: ${rem(12)}` : '')};
    }

    .aprDescription {
        text-decoration: none;
        color: #000000;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
