import styled from 'styled-components';
import { rem } from 'polished';
import { ModalNeedHelpStyled } from '../ModalNeedHelpStyled';

export const ModalNeedHelpStyledDS = styled(ModalNeedHelpStyled)`
    .description {
        font-size: ${rem(16)};
        color: black;
    }

    .phone {
        .icon {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black} !important;
                }
            }
        }
    }

    .request,
    .testDrive,
    .phone,
    .faq {
        .title {
            font-size: ${rem(20)};
            font-weight: bold;
        }

        .titleLabel {
            font-size: ${rem(16)};
        }
    }

    .faq {
        svg {
            .st0 {
                fill: ${({ theme }) => theme.colors.myAccountImageButtonDescriptionBackground} !important;
            }

            .st1 {
                fill: none !important;
            }
        }
    }
`;
