import styled from 'styled-components';
import { ModalPostalCodeStyled } from '../ModalPostalCodeStyled';
import { rem } from 'polished';

export const ModalPostalCodeStyledAP = styled(ModalPostalCodeStyled)`
    .description {
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
    }

    .type-select {
        .container {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.black};
        }

        .checkmark {
            border-color: ${({ theme }) => theme.colors.grey4};
            height: ${rem(20)};
            width: ${rem(20)};
        }

        .container .checkmark:after {
            top: ${rem(4)};
            left: ${rem(4)};
            width: ${rem(10)};
            height: ${rem(10)};
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.primary};
        }
    }

    .select {
        svg {
            path {
                fill: ${({ theme }) => theme.colors.black};
            }
        }

        .distance-select {
            &__single-value {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                color: ${({ theme }) => theme.colors.black};
            }

            &__option {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }

            &__option--is-focused {
                background: ${({ theme }) => theme.colors.grey7};
            }

            &__option--is-selected {
                background: ${({ theme }) => theme.colors.black};
                color: ${({ theme }) => theme.colors.white};
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }

            &__control {
                border-radius: 0;
                border: 1px solid ${({ theme }) => theme.colors.grey4};
            }
        }
    }

    .submit {
        button {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            text-transform: uppercase;

            &:disabled {
                background: ${({ theme }) => theme.colors.grey12};
            }
        }

        .home {
            border-color: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};

            svg {
                width: ${rem(12)};
                height: ${rem(12)};
                margin-top: ${rem(2)};

                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }

            &:hover:enabled,
            &:focus-visible:enabled {
                border-color: ${({ theme }) => theme.colors.black};
                color: ${({ theme }) => theme.colors.black};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.black};
                    }
                }
            }
        }

        .validate {
            &:hover:enabled,
            &:focus-visible:enabled {
                background: ${({ theme }) => theme.colors.black};
            }
        }
    }

    .selected-dealer {
        .label,
        .name,
        .address-line1,
        .address-line2 {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.black};
            padding: ${rem(2)} 0;
        }
    }
`;
