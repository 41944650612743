import styled from 'styled-components';
import { FooterStyled } from '../../FooterStyled';
import { rem } from 'polished';
import { defaultBreakpoints } from '../../../../styles/theme';
import { breakpoints } from '../../../../styles/theme';

export const FooterStyledOV = styled(FooterStyled)`
    .orderNowSection {
        color: ${({ theme }) => theme.colors.grey5};
        padding-top: ${rem(61)};
        padding-bottom: ${rem(41)};

        .orderNowTitle {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-size: ${rem(21)};
            line-height: ${rem(23)};
            color: ${({ theme }) => theme.colors.black};
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};
            padding-bottom: ${rem(30)};

            @media (min-width: ${breakpoints.xs}px) {
                font-size: ${rem(24)};
            }
        }

        .itemsWrapper {
            justify-content: center;

            .footerItem {
                text-transform: uppercase;

                .textWrap {
                    font-family: ${({ theme }) => theme.fonts.fontSecondary};
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    color: ${({ theme }) => theme.colors.black};
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    display: flex;
                    align-items: baseline;
                    line-height: ${rem(17)};

                    @media (min-width: ${defaultBreakpoints.sm}px) {
                        line-height: ${({ theme }) => theme.lineHeights.textBase};
                        font-size: ${({ theme }) => theme.fontSizes.h6};
                    }
                }

                @media (min-width: ${defaultBreakpoints.md}px) {
                    flex-direction: column-reverse;
                    border-right: none;
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    max-width: ${rem(260)};

                    :first-child {
                        .textWrap {
                            padding: 0 ${rem(40)};
                            display: block;
                        }
                    }

                    :nth-child(3) {
                        padding: 0 ${rem(20)};
                    }
                }

                .imageWrap {
                    padding-right: 0;
                    width: ${rem(65)};

                    @media (min-width: ${breakpoints.md}px) {
                        width: ${rem(50)};
                    }

                    svg {
                        rect {
                            stroke: ${({ theme }) => theme.colors.grey1};
                        }
                        path {
                            fill: ${({ theme }) => theme.colors.grey1};
                        }
                    }
                }
            }
        }
    }

    .footer {
        background: ${({ theme }) => theme.colors.grey14};
        padding: ${rem(29)} 0 ${rem(39)};

        ul {
            li {
                line-height: normal;
                border: none;

                @media (min-width: ${breakpoints.sm}px) {
                    position: relative;

                    &:not(:last-child) {
                        &::after {
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 0;
                            width: ${rem(1)};
                            height: 80%;
                            top: 50%;
                            transform: translateY(-40%);
                            background: ${({ theme }) => theme.colors.white};
                        }
                    }
                }

                a {
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    line-height: ${({ theme }) => theme.lineHeights.textMedium};
                    text-transform: capitalize;
                    font-weight: ${({ theme }) => theme.fontWeights.textMedium};
                    @media (min-width: ${breakpoints.sm}px) {
                        line-height: ${rem(20)};
                    }
                }
            }
        }

        .logo {
            display: none;
        }
    }

    .footerScrollToTopAnchor {
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        .icon-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        svg {
            path {
                stroke: ${({ theme }) => theme.colors.primary};
            }

            rect {
                stroke: initial;
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }
    .cookiesPreferences {
        text-transform: uppercase;
    }
`;
