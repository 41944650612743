import { FUEL_CODES } from '../constants/main';

export const getFuelTypeByCode = (fuelCode: string) => {
    const isBenzin = FUEL_CODES.BENZIN.split(';').includes(fuelCode);
    const isDiesel = FUEL_CODES.DIESEL.split(';').includes(fuelCode);
    const isHybrid = FUEL_CODES.HYBRID.split(';').includes(fuelCode);
    const isPlugInHybrid = FUEL_CODES.PLUG_IN_HYBRID.split(';').includes(fuelCode);

    return { isBenzin, isDiesel, isHybrid, isPlugInHybrid };
};
