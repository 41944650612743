import React, { FC, memo } from 'react';
import Main from '@components/Layout/Main';
import FAQs from '@components/FAQs';
import CustomError from '@components/CustomError';
import { useTranslations } from '@hooks/useTranslations';
import routes from '../../constants/routes';
import { useHandledError } from './useHandledError';
import { NeedHelpSection } from '@components/NeedHelpSection';

interface IProps {
    error: any;
}
export const HandledPageError: FC<IProps> = memo(({ error }) => {
    const { t } = useTranslations();
    const status = 503;

    useHandledError(error);

    return (
        <Main title={status}>
            <CustomError
                errorType={String(status)}
                returnLink={routes.ROOT}
                returnLinkLabel={t('errorPage.returnLink.label')}
                titleDescription={t('errorPage.notFound.description')}
            />
            <NeedHelpSection />
            <FAQs />
        </Main>
    );
});
HandledPageError.displayName = 'HandledPageError';
