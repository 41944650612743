import styled from 'styled-components';
import { InputStyled } from '../InputStyled';
import { rem } from 'polished';

export const InputStyledAC = styled(InputStyled)`
    span {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
    }

    input {
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colors.grey3};
        color: ${({ theme }) => theme.colors.grey1};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        border-radius: ${rem(12)};

        &.error {
            border-color: ${({ theme }) => theme.colors.danger};
        }
    }

    .currency {
        background: ${({ theme }) => theme.colors.grey3};
        border-top-left-radius: ${rem(10)};
        border-bottom-left-radius: ${rem(10)};
    }

    .inputToolTip {
        svg {
            g {
                circle {
                    stroke: ${({ theme }) => theme.colors.grey5} !important;
                    fill: ${({ theme }) => theme.colors.grey5};
                }
                line {
                    stroke: ${({ theme }) => theme.colors.white} !important;
                }
            }
        }
    }
`;
