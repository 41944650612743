import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import CheckoutTipsSidebarTemplate from './CheckoutTipsSidebarTemplate';
import { rem } from 'polished';

export const CheckoutTipsSidebarStyled = styled(CheckoutTipsSidebarTemplate)`
    width: 100%;
    border: 0.2rem solid ${({ theme }) => theme.colors.grey7};
    color: ${({ theme }) => theme.colors.blue7};
    margin: 2rem auto 3rem;

    .title {
        width: 100%;
        padding: ${rem(24)};
        text-transform: uppercase;
        background-color: ${({ theme }) => theme.colors.grey7};
        color: ${({ theme }) => theme.colors.blue7};
        font-size: ${rem(18)};
        line-height: ${rem(20)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        text-align: center;
        text-transform: uppercase;
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    .item {
        p {
            line-height: ${rem(22)};
            margin: 0;
        }

        &__title {
            font-weight: bold;
        }
    }

    .content {
        padding: ${rem(24)};
        word-wrap: break-word;
        text-align: left;
        font-size: 1.4rem;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                margin-bottom: 2.5rem;
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-size: ${rem(14)};
                line-height: 131%;
            }

            & li:first-child {
                margin-top: 0;
            }

            & li:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (min-width: ${breakpoints.sm}px) {
        margin: 3rem auto;
    }

    @media (min-width: ${breakpoints.lg}px) {
        max-width: ${rem(255)};
        margin: 0;
    }
`;
