import { useMemo, useState, useEffect } from 'react';
import useCustomRouter from './useCustomRouter';

export const useFAQCategoryFromHash = () => {
    const router = useCustomRouter();

    const [hashParam, setHashParam] = useState<string[]>([]);
    const [faqCategoryFromHash, setFaqCategoryFromHash] = useState<string>('');

    useEffect(() => {
        const hash = router.asPath.match(/#([a-z0-9]+)/gi) || [];
        setHashParam(hash);
        setFaqCategoryFromHash(hash?.[0]?.replace(/#(?=\S)/g, '')?.toLowerCase() || '');
    }, []);

    const showFAQ = useMemo(() => {
        return hashParam?.[0] === '#faqs' || hashParam?.[0] === '#faq';
    }, [hashParam]);

    return { faqCategoryFromHash, showFAQ };
};
