import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { Icon, Icons } from '@components/Icon';
import { ICarDetail } from '../../../redux/carDetails/carDetails.duck.interface';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import parser from 'bbcode-to-react';
import { useDispatch } from 'react-redux';

export interface IFeatureStyled {
    className: string;
    sectionKey: string;
    carDetails: ICarDetail;
    title: string;
    img: string;
    desc: string;
}

const ModalFeatureDetailTemplate = ({ className, sectionKey, carDetails, title, img, desc }: IFeatureStyled) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();

    const handleBackClick = () => {
        dispatch(
            UIDuck.openModal({
                data: { sectionKey, carDetails },
                callbacks: { },
                modalType: UIDuck.MODAL_TYPES.FEATURES,
            })
        );
    };

    return (
        <div className={className}>
            <div className="backButton" onClick={handleBackClick}>
                <Icon height={20} name={Icons.chevronLeftV2} />
            </div>
            <div className="title">
                {title}
            </div>
            <div className="image">
                <img src={img} />
            </div>
            <p className="description">
                {parser.toReact(desc)}
            </p>
        </div>
    );
};

export default ModalFeatureDetailTemplate;
