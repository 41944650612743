import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { Col } from 'react-styled-flexboxgrid';
import { ColProps } from '.';

export const ColStyled = styled(Col)<ColProps>`
    ${({ noPaddingMobile }) =>
        noPaddingMobile &&
        `
        @media (max-width: calc(${breakpoints.xs}px - 0.1px) ) {
            padding-left: 0rem;
            padding-right: 0rem;
        }
    `}
`;
