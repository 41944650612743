import styled from 'styled-components';
import { ModalShareConfigurationDealerCodeStyled } from '../ModalShareConfigurationDealerCodeStyled';
import { rem } from 'polished';

export const ModalShareConfigurationDealerCodeStyledDS = styled(ModalShareConfigurationDealerCodeStyled)`
    .share-wrapper {
        .input-wrapper {
            .dropdown,
            input {
                border-color: ${({ theme }) => theme.colors.grey5};
                border-radius: ${rem(3)};
                font-size: ${({ theme }) => theme.fontSizes.h5};

                .Select__control {
                    border-radius: ${rem(3)};
                }

                &:disabled {
                    background: ${({ theme }) => theme.colors.grey16};
                }
            }

            .input-section {
                .section-header {
                    font-size: ${({ theme }) => theme.fontSizes.h4};
                }
            }
        }
    }

    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.primary};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
            }
        }
    }
`;
