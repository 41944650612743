import React, { useMemo } from 'react';
import { IStepProps } from '../../../interfaces/Checkout';
import useTranslations from '@hooks/useTranslations';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { MySavedDealJourneyType } from 'src/redux/user/user.duck.interface';

const steps: any = Object.freeze({
    delivery: 'delivery',
    myDetails: 'my-details',
    preOrder: 'pre-order',
    payment: 'payment',
    myFinance: 'my-finance',
    orderReview: 'order-review',
    confirmation: 'confirmation',
    checkoutDeclined: 'checkout-declined',
    sepa: 'sepa',
});

const makeClassName: any = (
    isActive: boolean = false,
    isCompleted: boolean = false,
    stepAlias: string,
    paymentStatus: string,
    substep: string
): string[] => {
    const classList: string[] = [];
    const isConfirmation: boolean = substep === steps.confirmation;
    classList.push('progressBlock');
    if (isActive && !isConfirmation) {
        classList.push('progressBlockActive');
    }
    if (isCompleted) {
        classList.push('progressBlockCompleted');
    }
    if (isConfirmation) {
        classList.push('progressBlockFinished');
        classList.push('progressBlockCompleted');
    }
    return classList;
};

const CheckoutStep: React.FC<IStepProps> = (props: IStepProps) => {
    const {
        active,
        completed,
        stepAlias,
        stepNumber,
        paymentStatus,
        substep,
        dealerPreselected,
        hidePaymentDepositSection,
    } = props;
    const { t } = useTranslations();

    const { currentDeal } = useCarDetailsDuck();

    const checkoutType =
        currentDeal?.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH ? 'checkoutCash' : 'checkoutFinance';

    const stepTranslation = useMemo(() => {
        switch (stepAlias) {
            case steps.delivery:
                return t(`checkout.navigation.${checkoutType}.delivery`);
            case steps.orderReview:
                return t(`checkout.navigation.${checkoutType}.order-review`);
            case steps.preOrder:
                return t(`checkout.navigation.${checkoutType}.pre-order`);
            case steps.myDetails:
                return t(`checkout.navigation.${checkoutType}.my-details`);

            default:
                return stepAlias;
        }
    }, [stepAlias]);

    return (
        <div className={makeClassName(active, completed, stepAlias, paymentStatus, substep).join(' ')}>
            <div className="progressTimer">{stepNumber}.</div>
            <div className="progressTitle">
                {}
                {stepTranslation}
            </div>
            <div
                className={`progressIcon ${
                    stepNumber === 1
                        ? 'first'
                        : stepNumber === 2
                        ? dealerPreselected
                            ? 'center'
                            : 'second'
                        : stepNumber === 3
                        ? 'third'
                        : ''
                }`}
            />
            {(active || substep === 'confirmation') && <div className="progressCar progressCar-confirmation" />}
            <div className="progressStep">
                <span>
                    {t('checkout.navigation.step.name')}{' '}
                    {`${stepNumber}/${dealerPreselected || hidePaymentDepositSection ? 3 : 4}`}
                </span>
            </div>
        </div>
    );
};

export default CheckoutStep;
