import { BRAND, isMarketGB } from 'src/constants/main';
import { ModalFindDealerStyled } from './ModalFindDealerStyled';
import { SC } from 'src/styles/theme';
import React from 'react';
import { ModalFindDealerStyledAC } from './AC/ModalFindDealerStyledAC';
import { ModalFindDealerStyledAP } from './AP/ModalFindDealerStyledAP';
import { ModalFindDealerStyledDS } from './DS/ModalFindDealerStyledDS';
import { ModalFindDealerStyledOV } from './OV/ModalFindDealerStyledOV';
import { ModalFindDealerStyledOVGB } from './OV/ModalFindDealerStyledOVGB';

export interface ModalFindDealerProps extends SC {
    onClose: () => void;
    resetLocationInput: boolean;
}

export const ModalFindDealer = (props: ModalFindDealerProps) => {
    if (BRAND === 'AC') return <ModalFindDealerStyledAC {...props} />;
    if (BRAND === 'AP') return <ModalFindDealerStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalFindDealerStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalFindDealerStyledOVGB {...props} /> : <ModalFindDealerStyledOV {...props} />;
    return <ModalFindDealerStyled {...props} />;
};

export default ModalFindDealer;
