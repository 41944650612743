import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import CheckoutFinanceWidgetTemplate from './CheckoutFinanceWidgetTemplate';
import { rem } from 'polished';
import { Row } from '../Grid';

export const StyledRow = styled(Row)`
    @media (min-width: ${breakpoints.md}px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    @media (max-width: calc(${breakpoints.xs}px - 0.1px)) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const CheckoutFinanceWidgetStyled = styled(CheckoutFinanceWidgetTemplate)`
    .finance-summary {
        &--grid {
            width: 100%;
            padding-left: 0rem;
            padding-right: 0rem;
            color: ${({ theme }) => theme.colors.black};
            background: ${({ theme }) => theme.colors.white};
            div {
                max-width: 100%;
            }
        }
        &--row-1 {
            width: 100%;
            padding-bottom: 0.3rem;
            margin-left: 0rem;
            margin-right: 0rem;
            background-color: transparent;
        }
        &--col-1 {
            font-size: ${rem(16)};
            font-weight: normal;
        }
        &--row-2 {
            width: 100%;
            background: ${({ theme }) => theme.colors.white};
            padding: ${rem(18)} ${rem(10)};
            border: 1px solid ${({ theme }) => theme.colors.grey9};
            margin-left: 0;
            margin-right: 0;

            &.bold-style {
                margin-top: ${rem(28)};
            }
            &.indent-style {
                margin-top: 0;
                background: ${({ theme }) => theme.colors.grey16};
            }
        }
        &--row-margin {
            margin: ${rem(10)} 0;
        }

        &--col-2 {
            font-weight: normal;
            font-size: ${rem(16)};
            &.bold-style {
                font-weight: bold;
            }
            &.sized-up-style {
                font-size: 1rem;
            }
        }

        &--col-3 {
            font-weight: normal;
            font-size: ${rem(16)};
            text-align: right;
            &.bold-style {
                font-weight: bold;
            }
            &.sized-up-style {
                font-size: 1rem;
            }
        }

        &__legalTitle,
        &__legalText {
            width: 100%;
            text-align: left;
            font-size: ${({ theme }) => theme.fontSizes.textSmall};
            color: ${({ theme }) => theme.colors.grey2};
            line-height: ${({ theme }) => theme.lineHeights.textMedium};
        }

        &__legalTitle {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        &__legalText {
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            margin-top: ${rem(10)};
        }

        &__legalTextWrapper {
            margin-top: ${rem(28)};
            background: ${({ theme }) => theme.colors.grey7};
            padding: ${rem(20)};
        }
    }
`;
