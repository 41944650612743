import styled from 'styled-components';
import { rem } from 'polished';
import { ModalShareConfigurationBasketStyled } from '../ModalShareConfigurationBasketStyled';

export const ModalShareConfigurationBasketStyledAC = styled(ModalShareConfigurationBasketStyled)`
    .paragraph,
    .text,
    .textTitle {
        font-size: ${rem('24px')};
        color: ${({ theme }) => theme.colors.grey5};
    }

    .paragraph {
        font-size: ${({ theme }) => theme.fontSizes.h4};
    }

    .phone {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.h3};

        &:hover {
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .blurry {
        line-height: unset;
        margin: unset;
        color: ${({ theme }) => theme.colors.grey1};
        font-size: ${({ theme }) => theme.fontSizes.h6};
    }
`;
