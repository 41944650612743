import React from 'react';

import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalShareConfigurationDealerCodeStyledAP } from './AP/ModalShareConfigurationDealerCodeStyled.AP';
import { ModalShareConfigurationDealerCodeStyledDS } from './DS/ModalShareConfigurationDealerCodeStyled.DS';
import { ModalShareConfigurationDealerCodeStyledAC } from './AC/ModalShareConfigurationDealerCodeStyled.AC';
import { ModalShareConfigurationDealerCodeStyledOV } from './OV/ModalShareConfigurationDealerCodeStyled.OV';
import { ModalShareConfigurationDealerCodeStyledOVGB } from './OV/GB/ModalShareConfigurationDealerCodeStyled.OV';
import { ModalShareConfigurationDealerCodeStyled } from './ModalShareConfigurationDealerCodeStyled';



export const ModalShareConfigurationDealerCode = (props: any) => {
    if (BRAND === 'AP') return <ModalShareConfigurationDealerCodeStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalShareConfigurationDealerCodeStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalShareConfigurationDealerCodeStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalShareConfigurationDealerCodeStyledOVGB {...props} /> : <ModalShareConfigurationDealerCodeStyledOV {...props} />;
    return <ModalShareConfigurationDealerCodeStyled {...props} />;
};

export default ModalShareConfigurationDealerCode;
