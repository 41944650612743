import styled from 'styled-components';
import { CheckoutCarMainInfoStyled } from '../CheckoutCarMainInfoStyled';

export const CheckoutCarMainInfoStyledAP = styled(CheckoutCarMainInfoStyled)`
    .carInfoHeader {
        .title {
            color: ${({ theme }) => theme.colors.primaryBlack};
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .availableInLabel,
    .availableInDate {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        color: ${({ theme }) => theme.colors.grey12};
    }

    .availabilityStock {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        line-height: ${({ theme }) => theme.fontSizes.textBig};
        color: #787b80;

        .leadTimeDate {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .paragraph,
    .priceInfoWrapper,
    .optionsWrapperTitle,
    .detailsLink,
    .dealerInfoTitle {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .optionsWrapper .paragraph span {
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    }

    .priceSummary .priceInfoWrapper {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }
`;
