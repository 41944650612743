import styled from 'styled-components';
import { ReservationFeeStyled } from '../ReservationFeeStyled';

export const ReservationFeeStyledOV = styled(ReservationFeeStyled)`
    .paymentMethods {
        background: ${({ theme }) => theme.colors.white};
    }

    .label {
        letter-spacing: 0.9px;
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
    }

    .description {
        line-height: ${({ theme }) => theme.lineHeights.h4};
    }
`;
