import React, { ButtonHTMLAttributes } from 'react';
import { SC } from '../../styles/theme';
import { BRAND } from '../../constants/main';
import { FilterButtonStyled } from './FilterButtonStyled';
import { FilterButtonStyledDS } from './DS/FilterButtonStyled.DS';
import { FilterButtonStyledAP } from './AP/FilterButtonStyled.AP';
import { FilterButtonStyledAC } from './AC/FilterButtonStyled.AC';
import { FilterButtonStyledOV } from './OV/FilterButtonStyled.OV';

interface OwnProps extends SC {
    active?: boolean;
    disabled?: boolean;
    id?: string;
    isMobile?: boolean;
}

export type FilterButtonProps = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = (props: FilterButtonProps) => {
    if (BRAND === 'OV') return <FilterButtonStyledOV {...props} />;
    if (BRAND === 'AP') return <FilterButtonStyledAP {...props} />;
    if (BRAND === 'DS') return <FilterButtonStyledDS {...props} />;
    if (BRAND === 'AC') return <FilterButtonStyledAC {...props} />;
    return <FilterButtonStyled {...props} />;
};

export default Button;
