import React from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalPersonalizeOfferStyledAC } from './AC/ModalPersonalizeOfferStyled.AC';
import { ModalPersonalizeOfferStyledAP } from './AP/ModalPersonalizeOfferStyled.AP';
import { ModalPersonalizeOfferStyledDS } from './DS/ModalPersonalizeOfferStyled.DS';
import { ModalPersonalizeOfferStyledOV } from './OV/ModalPersonalizeOfferStyled.OV';
import { ModalPersonalizeOfferStyledOV as ModalPostalCodeStyledOVGB } from './OV/GB/ModalPersonalizeOfferStyled.OV';

export interface IPersonalizeOffer extends SC {
    openSection?: string;
}

export const ModalPersonalizeOffer = (props: IPersonalizeOffer) => {
    if (BRAND === 'AP') return <ModalPersonalizeOfferStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalPersonalizeOfferStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalPersonalizeOfferStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalPostalCodeStyledOVGB {...props} /> : <ModalPersonalizeOfferStyledOV {...props} />;
};
