import styled from 'styled-components';
import { GeoLocationInputStyled } from '../GeoLocationInputStyled';
import { rem } from 'polished';

export const GeoLocationInputStyledDS = styled(GeoLocationInputStyled)`
    .geolocation-title {
        font-size: ${rem('20px')};
        font-weight: normal;
    }

    .geolocation-input--container .geolocation-input--select-container {
        .Select__control {
            background: ${({ theme }) => theme.colors.black1};
            border-radius: ${rem(3)};
            font-size: ${rem(16)};
        }
    }

    .geolocation-input--container .geolocation-input--textfield-container {

        input {
            border-color: ${({ theme }) => theme.colors.grey5};
            font-size: ${rem(16)};
            color: ${({ theme }) => theme.colors.grey29};
            border-radius: ${rem(3)};
        }

        .dropDown ul li {
            font-size: ${rem(16)};
            padding: ${rem(10)};

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.grey4};
            }
        }
    }
`;
