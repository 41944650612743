import { CheckboxStyled } from '../CheckboxStyled';
import styled from 'styled-components';
import { rem } from 'polished';

export const CheckboxStyledFRDS = styled(CheckboxStyled)`
    .container label {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.h5};
    }

    .container input + label::before {
        border-color: ${({ theme }) => theme.colors.grey5};
        border-radius: ${rem(4)};
    }
`;
