import { useSelector } from 'react-redux';
import UserDuck from '../redux/user/user.duck';
import { useCallback } from 'react';

export const useUserDuck = () => {
    const summary = {
        accountStatus: useSelector((state) => UserDuck.getOwnState(state).myAccountSummary.status),
        accountError: useSelector((state) => UserDuck.getOwnState(state).myAccountSummary.error),
        totalOrders: useSelector((state) => UserDuck.getOwnState(state).myAccountSummary.myOrders),
        totalSaves: useSelector((state) => UserDuck.getOwnState(state).myAccountSummary.mySaves),
    };
    const isUserLoggedIn = useSelector((state) => UserDuck.isLoggedIn(state));
    const isLoggingOut = useSelector((state) => UserDuck.getOwnState(state).isLoggingOut);
    const myOrders = useSelector((state) => UserDuck.getOwnState(state).myOrders);
    const requestStatus = useSelector((state) => UserDuck.getOwnState(state).myOrders.status);
    const FetchMyAccountSummary = useCallback(() => UserDuck.actionFetchMyAccountSummary(), []);
    const FetchMyOrders = useCallback((page: number) => UserDuck.actionFetchMyOrders(page), []);
    const userAuth = useSelector((state) => UserDuck.getOwnState(state).userAuth);
    const userDetails = useSelector((state) => UserDuck.getOwnState(state).userDetails);
    const userAddress = useSelector((state) => UserDuck.getOwnState(state).userAddress);
    const isAgent = useSelector((state) => UserDuck.isAgentLogged(state));

    const page = myOrders.page;
    const perPage = myOrders.perPage;

    return {
        summary,
        isUserLoggedIn,
        isUserLoggedInAsDealer: isAgent,
        isLoggingOut,
        myOrders,
        requestStatus,
        FetchMyAccountSummary,
        FetchMyOrders,
        userAuth,
        userDetails,
        userAddress,
        page,
        perPage,
    };
};
