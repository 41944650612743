import { LinkStyled, colorHelper } from '@components/v2/link/LinkStyled';
import { rem } from 'polished';
import styled from 'styled-components';
import {isMarketIT} from "../../../../constants/main";

export const LinkStyledAC = styled(LinkStyled)`
    text-decoration: none !important;
    border-bottom: unset;
    padding: unset;
    background: unset;
    text-transform: unset;
    padding: unset;
    flex-direction: ${({ isReversed }) => isReversed && 'row-reverse'};
    .linkText {
        color: ${({ theme, isUnderline }) => (isUnderline ? theme.colors.grey5 : theme.colors.blue2)};
        font-weight: ${({ isUnderline }) => (!isUnderline ? 600 : 'normal')};
        border-bottom: 1px solid transparent;
        border-color: ${({ theme, isUnderline }) => (!isUnderline ? 'transparent' : `${theme.colors.grey5}`)};
        text-decoration: none;
        white-space: ${isMarketIT ? 'pre-wrap' : 'unset'};
        text-align: left;
        &:hover,
        &:focus-visible {
            color: ${({ theme, isUnderline }) => (isUnderline ? theme.colors.red : theme.colors.grey5)};
            border-color: ${({ theme, isUnderline }) => (isUnderline ? theme.colors.red : 'transparent')} !important;
        }
    }

    &:hover,
    &:focus-visible {
        border-bottom: unset;
        .linkText {
            color: ${({ theme, isUnderline }) => (isUnderline ? theme.colors.red : theme.colors.grey5)} !important;
        }
        .linkIcon {
            svg > path {
                fill: ${({ theme, iconHoverColor }) =>
                    colorHelper({ theme, type: iconHoverColor, defaultColor: theme.colors.grey5 })} !important;
                stroke: ${({ theme, disableIconStroke, iconHoverColor }) =>
                    !disableIconStroke &&
                    colorHelper({ theme, type: iconHoverColor, defaultColor: theme.colors.grey5 })} !important;
            }
        }
    }

    .linkIcon {
        svg > path {
            fill: ${({ theme, isUnderline, iconColor }) =>
                colorHelper({
                    theme,
                    type: iconColor,
                    defaultColor: isUnderline ? theme.colors.red : theme.colors.blue2,
                })} !important;
            stroke-width: ${rem(1)};
            stroke: ${({ theme, disableIconStroke, isUnderline, iconColor }) =>
                !disableIconStroke &&
                colorHelper({
                    theme,
                    type: iconColor,
                    defaultColor: isUnderline ? theme.colors.red : theme.colors.blue2,
                })} !important;
        }
    }
`;
