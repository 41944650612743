import styled from 'styled-components';
import { ModalPersonalizeOfferStyled } from '../../ModalPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalPersonalizeOfferStyledOV = styled(ModalPersonalizeOfferStyled)`
    .section {
        &_title {
            font-size: ${rem(16)};
        }

        .row {
            font-size: ${rem(16)};

            &_error {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .datePickerSection {
            input {
                width: 100%;
                border: 1px solid ${({ theme }) => theme.colors.grey16};
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            .validityError {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .option {
            background: ${({ theme }) => theme.colors.red7};
            border: 2px solid ${({ theme }) => theme.colors.primary};

            &_header {
                label {
                    font-size: ${rem(16)};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                svg {
                    margin-bottom: ${rem(10)};
                }
            }
        }

        .mandatory-label {
            color: ${({ theme }) => theme.colors.grey9};
            font-size: ${rem(16)};
        }

        .reset-button {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                margin-top: ${rem(2)};
            }
        }
    }
`;
