/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { useState, useRef, useEffect } from 'react';
import { EMAIL_REGEX } from '@utils/checkoutInputsValidation';
import useTranslations from '@hooks/useTranslations';
import Spinner from '@components/Spinner';
import sessionInfoApi from 'src/services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { Icon, Icons } from '@components/Icon';
import styled from 'styled-components';
import Button from '@components/Button';

export interface IDisableCheckout {
    className: string;
    closeModal: () => void;
}

const ModalIcon = styled(Icon)``;
const Cancel = styled(Button)``;

const ModalDisableCheckoutTemplate = ({ className, closeModal }: IDisableCheckout) => {
    const { t } = useTranslations();
    const [isLoading, setIsLoading] = useState(false);
    const emailInputRef = useRef<HTMLInputElement>();

    const [isSend, setIsSend] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [customerEmail, setCustomerEmail] = useState<string>('');

    const onClick = async () => {
        setIsLoading(true);

        const email = emailInputRef?.current?.value || '';
        if (!EMAIL_REGEX.test(email)) {
            setEmailInvalid(true);
            return;
        }

        setEmailInvalid(false);

        await sessionInfoApi.shareConfigurationEmail(email, getSessionIdCookie());

        setIsLoading(false);
        setIsSend(true);
        setCustomerEmail(email);
    };

    return (
        <div className={className}>
            {!isSend ? (
                <>
                    <div className="description">{t('checkoutDisable.modal.description')}</div>
                    <div className="input">
                        <input ref={emailInputRef} placeholder="Your email" />
                        <button onClick={onClick}>
                            {isLoading ? (
                                <Spinner size={12} border={2} color="white" />
                            ) : (
                                <span>{t('checkoutDisable.modal.button')}</span>
                            )}
                        </button>
                    </div>
                    {emailInvalid && (
                        <span className="emailError">{t('modal.dealerShareConfiguration.email.error')}</span>
                    )}
                </>
            ) : (
                <>
                    <div className="sent-wrapper">
                        <ModalIcon name={Icons.ShareConfigurationModalIcon} width={72} height={72} />
                        <div className="text">
                            {t('shareConfiguration.modal.message', { email: customerEmail || '' })}
                        </div>
                        <Cancel onClick={closeModal}>{t('shareConfiguration.modal.closeBtn')}</Cancel>
                    </div>
                </>
            )}
        </div>
    );
};

export default ModalDisableCheckoutTemplate;
