import React, { useEffect, useReducer, useState } from 'react';
import useTranslations from '../../../hooks/useTranslations';
import CheckoutDealerSearch from '../../../components/CheckoutDelivery/components/CheckoutDealerSearch';
import { DealerStateType, IDealerState } from '../../../interfaces/Dealer';
import { ACTIONS, IAction } from '@components/CheckoutDelivery';
import { GOOGLE_MAP_DEFAULT_LAT, GOOGLE_MAP_DEFAULT_LNG, ModalVersion } from '../../../constants/main';
import { dealerApi } from '../../../services';
import CheckoutDealerList from '@components/CheckoutDelivery/components/CheckoutDealerList';
import { useDispatch } from 'react-redux';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { theme } from '../../../styles/theme';
import Spinner from '@components/Spinner';

export interface IModalDealerSearchState {
    selectedDealerId?: string;
    personalFunction?: number;
    salesmanId?: string;
    email: string;
    firstNameInput: string;
    lastNameInput: string;
    companyNameInput: string;
    phoneInput: string;
    civility: string;
    contactByEmail: boolean;
    contactByMessage: boolean;
    contactByPhone: boolean;
    agreement: boolean;
}

interface IModalDealerSearch {
    onSubmit: () => void;
    previousState?: IModalDealerSearchState;
    className: string;
}

export const CheckoutModalDealerDispatch = React.createContext(null);

const reducer = (state: IDealerState, action: IAction) => {
    switch (action.type) {
        case ACTIONS.SET_ERROR:
            return { ...state, error: action.payload };
        case ACTIONS.SET_DEALERS:
            return { ...state, dealers: action.payload };
        case ACTIONS.SET_SELECTED_DEALER:
            return { ...state, selectedDealer: action.payload, type: DealerStateType.dealer };
        case ACTIONS.SET_SELECTED_PLACE:
            return { ...state, selectedPlace: action.payload, type: DealerStateType.place };
        default:
            return state;
    }
};

const initialState: IDealerState = {
    dealers: [],
    error: false,
    selectedPlace: {
        lat: Number(GOOGLE_MAP_DEFAULT_LAT),
        lng: Number(GOOGLE_MAP_DEFAULT_LNG),
    },
    selectedDealer: {
        externalId: '',
        city: '',
        distance: null,
        name: '',
        postCode: '',
        streetName: '',
    },
    type: DealerStateType.place,
};

const ModalDealerSearchTemplate: React.FC<IModalDealerSearch> = ({ className, previousState, onSubmit }) => {
    const { t } = useTranslations();
    const [state, dispatch] = useReducer(reducer, initialState);
    const rootDispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { lat, lng } = state.selectedPlace;

    useEffect(() => {
        dispatch({ type: ACTIONS.SET_SELECTED_DEALER, payload: { externalId: previousState?.selectedDealerId } });

        const modalBackBtn = document.getElementById('modalBackBtn');

        if (modalBackBtn) {
            modalBackBtn.onclick = function() { moveToShareModal() };
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                dispatch({ type: ACTIONS.SET_ERROR, payload: false });
                setIsLoading(true);
                const response = await dealerApi.getAllDealers(lat, lng);
                setIsLoading(false);

                if (response.status !== 200) {
                    throw { message: 'Dealers request error' };
                }

                dispatch({ type: ACTIONS.SET_DEALERS, payload: response.data.items.slice(0, 100) });
            } catch (err: any) {
                dispatch({ type: ACTIONS.SET_ERROR, payload: true });
            }
        })();
    }, [lat, lng]);

    const moveToShareModal = () => {
        rootDispatch(
            UIDuck.openModal({
                modalType: UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_DEALER_CODE,
                data: {
                    previousState: { ...previousState, selectedDealerId: state.selectedDealer.externalId },
                },
                modalVersion: ModalVersion.v2,
            })
        );
    };

    return (
        <div className={className}>
            <CheckoutModalDealerDispatch.Provider value={dispatch}>
                <CheckoutDealerSearch />
                {isLoading ? (
                    <Spinner size={30} border={3} color={theme.colors.black} />
                ) : (
                    <CheckoutDealerList
                        previousState={previousState}
                        moveToShareDealerCodeModal
                        dealers={state.dealers}
                    />
                )}
            </CheckoutModalDealerDispatch.Provider>
        </div>
    );
};

export default ModalDealerSearchTemplate;
