import React, { useEffect } from 'react';
import GeoLocationInput, { ICoordinates } from '../../GeoLocationInput';
import { useDispatch, useSelector } from 'react-redux';
import FilterDuck from '../../../redux/filters/filter.duck';
import { IFilterRule, IGeoLocation } from '../../../redux/filters/filter.duck.interface';
import { Redux } from 'src/redux/redux.interface';
import { modifySortFilter } from '../../../services/filters/utils/Filters.utils';
import { SortOrder, SortType } from '../../../services/stock/models/stockItems.service.model';
import DealerDuck from '../../../redux/dealer/dealer.duck';
import { DEALER_ID_IN_URL_ID } from '@components/Modal/ModalPostalCode/ModalPostalCodeTemplate';
import { useRouter } from 'next/router';
import Routes from '../../../constants/routes';
import { CarJourneyType, JOURNEY } from '../../../services';

interface ILocationInputProps {
    onChange?: (geoLocation: IGeoLocation, distanceRadius: number) => void;
    onClear?: () => void;
    reset?: boolean;
    isStockSlice?: boolean;
    showOnlyInput?: boolean;
    setUserInput?: any;
    callBack?: (args: {
        filters: IFilterRule[];
        geoLocation?: IGeoLocation;
        distanceRadius?: number;
        geoLocationName?: string;
        dealerId?: string;
    }) => void;
    isModal?: boolean;
    onEnterKey?: () => void;
}

const LocationInput = ({
    onChange,
    onClear,
    reset,
    isStockSlice,
    showOnlyInput = false,
    setUserInput,
    callBack,
    isModal = false,
    onEnterKey,
}: ILocationInputProps) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const filters = useSelector((state: Redux) => FilterDuck.getFilters(state));
    const storedGeoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
    const getGeoLocationName = useSelector((state: Redux) => FilterDuck.getGeoLocationName(state));
    const distanceRadius = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));

    const handleSubmit = (updatedGeoLocation: ICoordinates, updatedDistanceRadius: number, userInput: string) => {
        if (storedGeoLocation !== updatedGeoLocation || distanceRadius !== updatedDistanceRadius) {
            const filterValues = {
                filters: !storedGeoLocation ? modifySortFilter(filters, SortType.PROXIMITY, SortOrder.ASC) : filters,
                geoLocation: updatedGeoLocation,
                distanceRadius: isStockSlice ? 0 : updatedDistanceRadius,
                geoLocationName: userInput || getGeoLocationName,
            };

            dispatch(
                FilterDuck.setFilters(
                    filterValues.filters,
                    filterValues.geoLocation,
                    filterValues.distanceRadius,
                    filterValues.geoLocationName
                )
            );
            callBack?.(filterValues);
        }
    };

    const handleClear = () => {
        setUserInput?.(null);
        onClear?.();
        if (!router.pathname?.includes(CarJourneyType.STOCK)) {
            dispatch(DealerDuck.setDealer(null));
        }
        if (storedGeoLocation) {
            dispatch(FilterDuck.resetGeoLocationProperties());
        }
    };

    useEffect(() => {
        onChange?.(storedGeoLocation, distanceRadius);
    }, [storedGeoLocation, distanceRadius, onChange]);

    return (
        <GeoLocationInput
            reset={reset}
            onSubmit={handleSubmit}
            onClear={handleClear}
            geoLocation={storedGeoLocation}
            distanceInKm={distanceRadius}
            getGeoLocationName={getGeoLocationName}
            isStockSlice={isStockSlice}
            showOnlyInput={showOnlyInput}
            setUserInput={setUserInput}
            isModal={isModal}
            onEnterKey={onEnterKey}
        />
    );
};

export default LocationInput;
