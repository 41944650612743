import styled from 'styled-components';
import { GeoLocationInputStyled } from '../GeoLocationInputStyled';
import { rem } from 'polished';

export const GeoLocationInputStyledAC = styled(GeoLocationInputStyled)`
    .geolocation-input--container {
        .geolocation-input--textfield-container {
            input {
                font-size: ${({ theme }) => theme.fontSizes.h6};
                border-top-left-radius: ${rem(12)};
                border-bottom-left-radius: ${rem(12)};
                border-color: ${({ theme }) => theme.colors.black};

                &::placeholder {
                    color: ${({ theme }) => theme.colors.black};
                }
            }

            .geolocation-input--textfield-icon {
                color: ${({ theme }) => theme.colors.red};
            }

            .dropDown ul li {
                font-size: ${rem(14)};
                padding: ${rem(10)};

                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.grey7};
                }
            }
        }

        .geolocation-input--select-container {
            .Select__control {
                border-top-right-radius: ${rem(12)};
                border-bottom-right-radius: ${rem(12)};
                background: ${({ theme }) => theme.colors.blue2};
                border-color: ${({ theme }) => theme.colors.blue2};
            }
        }
    }
`;
