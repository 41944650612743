import styled from 'styled-components';
import { SelectStyled } from '../SelectStyled';
import { rem } from 'polished';

export const SelectStyledDS = styled(SelectStyled)`
    .Select__control {
        padding-right: 1.5rem;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;
    }

    .Select__menu-list {
        max-height: ${rem('300px')};
        overflow-y: auto;
        padding-bottom: ${rem('4px')};
        padding-top: ${rem('4px')};
        position: relative;
        box-sizing: border-box;
    }

    .Select__option {
        cursor: default;
        display: block;
        font-size: inherit;
        padding: ${rem('8px')} ${rem('12px')};
        width: 100%;
        user-select: none;
        box-sizing: border-box;
        color: ${({ theme }) => theme.colors.black};
    }

    .Select__option--is-focused {
        background-color: ${({ theme }) => theme.colors.black1};
        color: ${({ theme }) => theme.colors.white};
    }

    .Select__option:active {
        background-color: ${({ theme }) => theme.colors.red2};
    }

    .Select__option--is-selected {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }

    .Select__single-value {
        color: ${({ theme }) => theme.colors.grey29};
    }
`;
