import styled from 'styled-components';
import { rem } from 'polished';
import { ModalLogoutTemplate } from './ModalLogoutTemplate';

export const ModalLogoutStyled = styled(ModalLogoutTemplate)`
    .description {
        margin-bottom: ${rem(35)};
    }

    .button {
        width: 100%;
        display: flex;
    }
`;
