import styled from 'styled-components';
import { AgentToolBarStyled } from '../AgentToolBarStyled';

export const AgentToolBarStyledAP = styled(AgentToolBarStyled)`
    background: ${({ theme }) => theme.colors.primaryLight};
    color: ${({ theme }) => theme.colors.black};

    .logoutButton {
        color: ${({ theme }) => theme.colors.black};
        border-color: ${({ theme }) => theme.colors.black};
    }
`;
