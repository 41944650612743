import styled from 'styled-components';
import { InputStyled } from '../InputStyled';
import { rem } from 'polished';

export const InputStyledOV = styled(InputStyled)`
    span {
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
    }

    input {
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colors.grey18};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        border-radius: 0;

        &.error {
            border-color: ${({ theme }) => theme.colors.danger};
        }
    }

    .currency {
        background: ${({ theme }) => theme.colors.grey18};
    }

    .inputToolTip {
        svg {
            width: ${rem(22)};
            height: ${rem(22)};
            margin-bottom: -${rem(4)};

            path {
                fill: ${({ theme }) => theme.colors.black2};
            }
        }
    }
`;
