import React, { FC, memo } from 'react';

import Icon, { Icons } from '../Icon';
import { SC, theme } from '../../styles/theme';
import useTranslations from '@hooks/useTranslations';

type IProps = SC;

export const NeedHelpSectionTemplate: FC<IProps> = memo(({ className }) => {
    const { t } = useTranslations();
    return (
        <div className={className}>
            <h2>{t('errorPage.needHelpTitle')}</h2>
            <div className="needHelpPhone">
                <span className="needHelpPhoneTitle">{t('errorPage.needHelpPhoneTitle')} </span>
                <span>
                    <Icon name={Icons.Phone} color={theme.colors.secondary} width={39} height={24} />
                </span>{' '}
                <span className="needHelpPhoneNumber">{t('errorPage.needHelpPhoneNumber')}</span>
            </div>
            <div className="needHelpInfo">{t('errorPage.needHelpInfo')}</div>
        </div>
    );
});
NeedHelpSectionTemplate.displayName = 'NeedHelpSectionTemplate';
