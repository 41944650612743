import { BRAND } from '../../../constants/main';
import { InvalidConfigurationModalContentStyledAC } from './AC/InvalidConfigurationModalContentStyled.AC';
import { InvalidConfigurationModalContentStyledAP } from './AP/InvalidConfigurationModalContentStyled.AP';
import { InvalidConfigurationModalContentStyledDS } from './DS/InvalidConfigurationModalContentStyled.DS';
import { InvalidConfigurationModalContentStyled } from './InvalidConfigurationModalContentStyled';
import React from 'react';

export const InvalidConfigurationModalContent = (props: any) => {
    if (BRAND === 'AC') return <InvalidConfigurationModalContentStyledAC {...props} />;
    if (BRAND === 'AP') return <InvalidConfigurationModalContentStyledAP {...props} />;
    if (BRAND === 'DS') return <InvalidConfigurationModalContentStyledDS {...props} />;
    return <InvalidConfigurationModalContentStyled {...props} />;
};

export default InvalidConfigurationModalContent;
