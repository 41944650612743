import { rem } from 'polished';
import styled from 'styled-components';
import { CheckoutCarMainInfoStyled } from '../CheckoutCarMainInfoStyled';

export const CheckoutCarMainInfoStyledAC = styled(CheckoutCarMainInfoStyled)`
    .clock {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url('data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAGIAAABhCAYAAAAtMXSgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAARXSURBVHhe7Z0/LC1BGMX3vlZBL6Gm0JNQE9QKaho1CTUJNQU1CbV/PUJPQk2ip1C/9+bLnvvmXXft/7tn1/klkzO3ne+emW/+buvz8/N3ICrnV6iiYhQIEhQIEmo5Rry8vJi+vr6agqGhobAWBMPDw2GtHsgRJFA44v39PawFwcXFhen19bXpzc2NKVyQFjhjcnLSdGpqytQxOztrOjAwYFolcgQJlTgC//bj42PTo6Mj06pYWloyXVxcNPVd0yvkCBIUCBJK75r8QXZ9fd307OzMlJX5+fmwFgQ7OzumZafDcgQJpTkCAzBc4PDT1LqA1BbOwMBeNHIECYU7YmVlxbTqlLQs4IiDgwPTopAjSCjMEU13Qif+WFGEO+QIEhQIEnJ1TeiOHD+lS+pGEQO4HEFCJkfg3+87omywMupIstyAfQxoL4Ajskz65AgSUjkCC3gTExOmvVyyuLy8DFqtltWT7BdsbW2Zbm9vm/YCLIfc3d2ZplkolCNISBUIt4DninNCHRfwygbtgnZKgxxBggJBQqJAuM1+V9zOGvvuGgNop4eHh3aJQ44gIVEg3ATuJy9hZGVvb69d4pAjSIic0Pnp6eDgYFirjjpM6L7j7e3NNOp4pxxBQmQgzs/P20XkJ64t5QgSFAgSIgOBSRxObot8xLWlHEFCZCBub2/bReQnri3lCBIiA+F241BEfuLaUo4g4Usgki7bstPf32+FjShnyBEkKBAkfAlEUw4GrK6uWtnY2LDCgromchofiM3NTStXV1dWMIizDeRyBAlfdujQf42Ojpqy4B/sxQ3PLI+Z+OPf9PS06ePjo2kveHp6Mu08jilHkBC5Z93X1xfW+BgbGzPFMXj8zsra2prp/v6+aZn8be+w9j9yBAkKBAmRXdPIyEhY+/p2HgtFP8+A46T+TaiPj4+wlg+8N/j8/GzaiRxBQqQjlpeXw9q/l8bYKSrF9ZcgFhYWTPOmuLgDeHh4aNqJHEFCpCP8Q8e9vD1aFGwpbtyNUzmChEhHsB1CzkpRmRXaAzdquy1lf4cOIdcEBYKEyK7JB6lsXdLY78ia4mZ5j8p/tiIqbQVyBAmJHIHDszMzM6ZNIUmKm+cBmPv7+7AWnz7LESQkcgTAdL9pt4g6U1x84sCR5QGYubk509PTU9MkyBEkpHIEJjHj4+OmRS0Rs+FnUWmcgJMhGBv0TFANUSBISNU1gTwpXZM5OTkx9T97kBQ5goRMjgB13MUrg7jdtyTIESTkcoRPkxYGk5BmQS8JcgQJhTkCNN0ZRYwH3ZAjSFAgSCi8awKY9OEYiqOOa1NYP8KeRZbJWhLkCBJKcwTwj53AHez7GdhPcOzu7prqy4w/hNId0Q3sgWMcqTrVRUrq7mU78h7PzIIcQUIljujE3wXD+AHX4NM0WS/L4IJIt0/tYyxIcq6pbOQIEhQIEii6prQgJe48ke2nmGWnm0UjR5BQS0c0ETmCBAWCgiD4A3JjLdF0GHM2AAAAAElFTkSuQmCC')
            no-repeat;
        background-size: contain;
        padding-right: 1.5rem;
    }
    .viewMore {
        margin-top: unset;
    }
    .carInfoHeader {
        .title {
            text-transform: none;
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-size: ${rem(22)};
        }
    }

    .availabilityStock {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        line-height: ${({ theme }) => theme.lineHeights.h4};
        color: ${({ theme }) => theme.colors.grey13};
        flex-direction: row;
        .leadTimeDate {
            color: ${({ theme }) => theme.colors.grey13};
            margin-left: ${rem(5)};
        }
    }

    .carInfo {
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    .paragraph {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${({ theme }) => theme.fontSizes.h5};
        color: ${({ theme }) => theme.colors.grey5};
        span:first-child {
            font-weight: bold;
        }
    }

    .detailsContent {
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    .priceSummary {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        color: ${({ theme }) => theme.colors.grey5};
        .optionsWrapperTitle {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        .priceInfoWrapper {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            color: ${({ theme }) => theme.colors.grey5};

            &NoBold {
                font-weight: normal;
            }

            &.totalPriceInfo {
                font-size: ${rem(16)};
                line-height: ${rem(21)};
                font-weight: bold;
            }
        }
    }
`;
