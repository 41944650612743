import React, { FC, memo, useState, useMemo, useEffect, useContext } from 'react';
import PartExchangeWidget from '@components/PartExchange/PartExchangeWidget';
import { CheckoutMySummaryProps } from '.';
import useTranslations from '@hooks/useTranslations';
import Collapsible from 'react-collapsible';
import CheckoutFinanceWidget from '../CheckoutFinanceWidget';
import CarMainInfo from '../CheckoutCarMainInfo';
import { connect, useSelector } from 'react-redux';
import CarDetailsDuck from '../../redux/carDetails/carDetails.duck';
import DealerDuck from '../../redux/dealer/dealer.duck';
import { useIsMyAccount } from '@hooks/useIsMyAccount';
import { BRAND, isBrandOV, OFFER_DEALER_DISCOUNT_TYPE, DEAL_PRODUCT_TYPE, PxVersion } from '../../constants/main';
import CheckoutDealerInfo from '../CheckoutDealerInfo';
import { DealerInfo } from '../MySummary/DealerInfo';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import routes from '../../constants/routes';
import { PaymentStatuses } from '../../constants/paymentStatuses';
import DealerPartExchangeSummary from '@components/PartExchangeSummary';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from '../../context/featureSwitchApp';
import CheckoutMySummaryOfferTemplate from './components/CheckoutMySummaryOfferTemplate';
import { hasFinanceVariants } from '@utils/Price.utils';
import { PaymentJourneyTypes } from 'src/partExchange/interfaces/Default';
import ReservationFee from '../ReservationFee';
import { MySavedDealJourneyType } from '../../redux/user/user.duck.interface';
import PersonalDetails from '../CheckoutPersonalDetails';
import FinanceSummary from '@components/MySummary/FinanceSummary/FinanceSummary';
import { useExternalCheckout } from '@hooks/useExternalCheckout';
import { skipPaymentDeposit } from '@utils/Deal.utils';
import ConfiguratorDuck from 'src/redux/configurator/configurator.duck';
import FilterDuck from 'src/redux/filters/filter.duck';
import { IGlobalState } from 'src/redux/global/global.duck.interface';
import { useJourneyType } from '@hooks/useJourneyType';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';

const getDealerDiscount = (currentDeal: any, myAccountCarDetails: any) => {
    if (currentDeal?.extraFields?.discounts) {
        return JSON.parse(currentDeal.extraFields.discounts).find(
            (discount: { key: string; amount: string }) => discount.key === OFFER_DEALER_DISCOUNT_TYPE
        )?.amount;
    }

    if (myAccountCarDetails?.extraFields?.discounts) {
        return JSON.parse(myAccountCarDetails.extraFields.discounts).find(
            (discount: { key: string; amount: string }) => discount.key === OFFER_DEALER_DISCOUNT_TYPE
        )?.amount;
    }
};

const CheckoutMySummaryTemplate: FC<CheckoutMySummaryProps> = memo(
    ({
        className,
        carDetails,
        currentDeal,
        isSecondaryLayout,
        dispatch,
        dealer,
        isPxOpen,
        displayPx = false,
        journeyType,
        allowPxFinalization,
        hideAdditionalInfo,
        myAccountCarDetails,
        dealerId,
        showFinanceWidget = true,
        financeQuoteId,
        partExchangeId,
        isOpenedByDefault = false,
        offers,
        isCheckout = false,
        isMyOrderPage = false,
        deal,
        order,
        pxState,
    }) => {
        const { t } = useTranslations();
        const context = useContext(FeatureSwitchContext);
        const mopId = useSelector((state) => CarDetailsDuck.getOwnState(state)?.currentDeal?.mopId);
        const router = useRouter();
        const isMyAccountPage = useIsMyAccount();
        const isDealerLayerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED);
        const paymentJourney = useSelector((state) => FilterDuck.getPaymentJourneyType(state));
        const { isRent } = useJourneyType();
        const pxId = currentDeal?.partExchangeId || pxState?.id;
        const isAssistedFinance = currentDeal?.extraFields?.assistedFinance;

        useEffect(() => {
            if (
                myAccountCarDetails?.prices &&
                hasFinanceVariants(myAccountCarDetails.prices) &&
                paymentJourney !== PaymentJourneyTypes.FINANCE
            ) {
                dispatch(
                    FilterDuck.changeBudgetType(
                        hasFinanceVariants(myAccountCarDetails.prices)
                            ? PaymentJourneyTypes.FINANCE
                            : PaymentJourneyTypes.CASH
                    )
                );
            }
        }, [myAccountCarDetails?.prices]);

        const status = useMemo(
            () =>
                deal?.status || order?.sibling.extraFields.status || myAccountCarDetails?.status || currentDeal?.status,
            [deal, order, myAccountCarDetails, currentDeal]
        );

        const financeFileId = useMemo(
            () =>
                deal?.extraFields?.finance_file_id ||
                order?.sibling.extraFields?.finance_file_id ||
                currentDeal?.extraFields?.finance_file_id ||
                myAccountCarDetails?.extraFields?.finance_file_id,
            [deal, order, myAccountCarDetails, currentDeal]
        );

        const isAfterRecapPage = useMemo(
            () => [PaymentStatuses.financeApproved, PaymentStatuses.financeRejected].includes(status),
            [status]
        );

        const featureFinanceOverviewEnabled = useFeatureSwitchEnabled(
            FEATURES_LIST.FEATURE_SWITCH_CHECKOUT_FINANCE_ENABLED
        );
        const featureCheckoutContractEnabled = useFeatureSwitchEnabled(
            FEATURES_LIST.FEATURE_SWITCH_CHECKOUT_CONTRACT_ENABLED
        );

        const { isInternalCashJourneyEnabled } = useExternalCheckout();

        const isFinanceOverviewEnabled = useMemo(
            () => (isAfterRecapPage ? featureFinanceOverviewEnabled : true),
            [isAfterRecapPage, featureFinanceOverviewEnabled]
        );
        const isContractEnabled = useMemo(
            () => (isAfterRecapPage ? featureCheckoutContractEnabled : false),
            [isAfterRecapPage, featureCheckoutContractEnabled]
        );
        const [openOfferId, setOpenOfferId] = useState(null);

        const dealerDiscount = getDealerDiscount(currentDeal, myAccountCarDetails);

        const dealerPx = currentDeal?.extraFields?.px ?? myAccountCarDetails?.extraFields?.px;

        const comments = currentDeal?.extraFields?.comments ?? myAccountCarDetails?.extraFields?.comments;

        const checkIfIsParent = () => {
            // Child collapse
            if (
                currentDeal?.productType === DEAL_PRODUCT_TYPE.CHILD ||
                myAccountCarDetails?.productType === DEAL_PRODUCT_TYPE.CHILD
            ) {
                return false;
            }

            // Parent collapse
            if (isMyAccountPage || currentDeal?.productType === DEAL_PRODUCT_TYPE.PARENT) {
                return true;
            }
        };

        const hasDealerPx = myAccountCarDetails?.extraFields?.px || currentDeal?.extraFields?.px;
        const isPxEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_PX_ENABLED);
        const pxVersion = getFeatureSwitch(FEATURES_LIST.FEATURE_SWITCH_PX_VERSION, context);

        const displayDealerInfoIsOv: boolean = isBrandOV;
        const carConfigData = isMyAccountPage ? myAccountCarDetails : carDetails;
        const shouldShowCashPx =
            isPxEnabled &&
            !isRent &&
            displayPx &&
            !hasDealerPx &&
            currentDeal?.productType !== DEAL_PRODUCT_TYPE.PARENT &&
            myAccountCarDetails?.productType !== DEAL_PRODUCT_TYPE.PARENT;

        const shouldShowPersonalDetails =
            router.pathname === routes.CHECKOUT_PRE_ORDER ||
            (router.pathname === routes.CHECKOUT_CONFIRMATION && isAssistedFinance);

        const carInfo = (
            <CarMainInfo
                carConfigData={carConfigData}
                dealer={dealer}
                isAccordionOpen
                financeQuote={''}
                financeQuoteId={myAccountCarDetails?.financeQuoteId || currentDeal?.financeQuoteId}
                existingLanguage={'fr'}
                dispatch={dispatch}
                dealerId={dealerId}
                isCheckout={isCheckout || isMyOrderPage}
                dealerPx={dealerPx}
                displayPx={displayPx}
                journeyType={journeyType}
                comments={comments}
                dealerDiscount={dealerDiscount}
                isParent={checkIfIsParent()}
                order={order}
                displayDealerInfo={displayDealerInfoIsOv}
            />
        );

        return (
            <div className={className}>
                <div className="grid">
                    {isSecondaryLayout ? (
                        <Collapsible
                            className={'is-my-order'}
                            tabIndex={0}
                            open={
                                isOpenedByDefault || currentDeal?.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH
                            }
                            lazyRender={isMyAccountPage}
                            trigger={
                                isMyAccountPage
                                    ? t('myaccount.carSummary.title')
                                    : isDealerLayerEnabled
                                    ? t('checkout.carMainInfoEC.title')
                                    : t('checkout.carMainInfo.title')
                            }
                        >
                            {carInfo}
                        </Collapsible>
                    ) : (
                        carInfo
                    )}

                    {pxVersion === PxVersion.v2 && currentDeal?.partExchangeId && (
                        <Collapsible
                            tabIndex={0}
                            lazyRender={isMyAccountPage}
                            trigger={t('px.title')}
                            triggerWhenOpen={t('px.title')}
                        >
                            <DealerPartExchangeSummary
                                pxData={{
                                    vehicle: {
                                        makeName: pxState?.makeName,
                                        modelName: pxState?.modelName,
                                        regNumber: pxState?.licensePlate,
                                        registrationDate: pxState?.registrationDate,
                                        overallMilleage: pxState?.overallMilleage,
                                        vin: pxState?.vin,
                                    },
                                }}
                            />
                        </Collapsible>
                    )}

                    {pxVersion !== PxVersion.v2 &&
                        isMyAccountPage &&
                        myAccountCarDetails?.extraFields?.px &&
                        myAccountCarDetails?.financeQuoteId && (
                            <Collapsible
                                tabIndex={0}
                                lazyRender={isMyAccountPage}
                                trigger={t('px.title')}
                                triggerWhenOpen={t('px.title')}
                            >
                                <DealerPartExchangeSummary pxData={JSON.parse(myAccountCarDetails.extraFields.px)} />
                            </Collapsible>
                        )}

                    {pxVersion !== PxVersion.v2 &&
                        !isMyAccountPage &&
                        currentDeal?.extraFields?.px &&
                        currentDeal?.financeQuoteId && (
                            <Collapsible
                                tabIndex={0}
                                lazyRender={isMyAccountPage}
                                trigger={t('px.title')}
                                triggerWhenOpen={t('px.title')}
                            >
                                <DealerPartExchangeSummary pxData={JSON.parse(currentDeal?.extraFields?.px)} />
                            </Collapsible>
                        )}

                    {isContractEnabled && (
                        <Collapsible
                            tabIndex={0}
                            lazyRender={false}
                            trigger={t('myaccount.contractSummary.title')}
                            triggerWhenOpen={t('myaccount.contractSummary.title')}
                        >
                            {t('myaccount.contractSummary.text')} {financeFileId}
                        </Collapsible>
                    )}

                    {pxVersion !== PxVersion.v2 && shouldShowCashPx && (
                        <Collapsible trigger={t('checkout.partExchange.title')} tabIndex={0} open={isPxOpen}>
                            <PartExchangeWidget
                                car={carConfigData}
                                clearSession
                                allowPxFinalization={allowPxFinalization}
                                hideAdditionalInfo={hideAdditionalInfo}
                                mopId={mopId}
                                partExchangeId={pxId}
                                allowDeleteEstimation={!isMyAccountPage}
                            />
                        </Collapsible>
                    )}
                    {isFinanceOverviewEnabled &&
                        showFinanceWidget &&
                        (financeQuoteId || currentDeal?.financeQuoteId) && (
                            <Collapsible
                                tabIndex={0}
                                lazyRender={isMyAccountPage}
                                trigger={
                                    isCheckout && isDealerLayerEnabled
                                        ? t('checkout.financewidget.title.tertiary')
                                        : t('checkout.financewidget.title.primary')
                                }
                                triggerWhenOpen={
                                    isCheckout && isDealerLayerEnabled ? (
                                        t('checkout.financewidget.title.tertiary')
                                    ) : (
                                        <div style={{ display: 'block' }} data-testid="TESTING_FINANCE_INFO">
                                            <div>{t('checkout.financewidget.title.secondary')}</div>
                                            {BRAND !== 'OV' && BRAND !== 'DS' && (
                                                <>
                                                    <br />
                                                    <div className="secondaryText">
                                                        {t('checkout.financewidget.subtitle')}
                                                    </div>
                                                </>
                                            )}{' '}
                                            {/* change to isBrandOV when BE is ready */}
                                        </div>
                                    )
                                }
                            >
                                {isMyAccountPage || isRent ? (
                                    <FinanceSummary id={financeQuoteId} />
                                ) : (
                                    <CheckoutFinanceWidget />
                                )}
                            </Collapsible>
                        )}
                    {(!!dealer?.name || !!dealerId) && (
                        <Collapsible
                            tabIndex={0}
                            open={router.pathname === routes.CHECKOUT_PRE_ORDER}
                            trigger={t('checkout.myDealerDetails.title')}
                        >
                            <>
                                <div className="separatorLine" />
                                {(dealer?.name && <CheckoutDealerInfo dealer={dealer} displayTitle={false} />) ||
                                    (dealerId && <DealerInfo id={dealerId} />)}
                            </>
                        </Collapsible>
                    )}
                    {offers?.length > 0 &&
                        offers.map((offer) => {
                            if (!offer) {
                                return null;
                            }

                            return (
                                <CheckoutMySummaryOfferTemplate
                                    key={offer.token}
                                    dealer={dealer}
                                    dealerId={dealerId}
                                    offer={offer}
                                    journeyType={journeyType}
                                    displayPx={displayPx}
                                    onCollapsibleClick={setOpenOfferId}
                                    openOfferId={openOfferId}
                                />
                            );
                        })}
                    {shouldShowPersonalDetails && <PersonalDetails isOpenedByDefault />}
                    {currentDeal?.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH &&
                        isInternalCashJourneyEnabled &&
                        !skipPaymentDeposit(currentDeal) && (
                            <Collapsible
                                tabIndex={0}
                                trigger={t('checkout.reservationFee.title')}
                                triggerWhenOpen={t('checkout.reservationFee.title')}
                                open
                            >
                                <ReservationFee fee={currentDeal?.extraFields?.reservationFee} />
                            </Collapsible>
                        )}
                </div>
            </div>
        );
    }
);
CheckoutMySummaryTemplate.displayName = 'CheckoutMySummaryTemplate';

const mapStateToProps: (state: IGlobalState) => CheckoutMySummaryProps = (state) => ({
    carDetails: CarDetailsDuck.getOwnState(state).currentCarDetails,
    currentDeal: CarDetailsDuck.getOwnState(state).currentDeal,
    dealer: DealerDuck.getOwnState(state).dealer,
    pxState: ConfiguratorDuck.getOwnState(state).pxState,
});

export default connect(mapStateToProps)(CheckoutMySummaryTemplate);
