import styled from 'styled-components';
import { ModalShareConfigurationDealerCodeStyled } from '../ModalShareConfigurationDealerCodeStyled';
import { rem } from 'polished';

export const ModalShareConfigurationDealerCodeStyledAC = styled(ModalShareConfigurationDealerCodeStyled)`
    .searchSection {
        background: ${(props) => props.theme.colors.blue2};
        border: none;
        margin: unset;
        border-top-right-radius: ${rem(12)};
        border-bottom-right-radius: ${rem(12)};
        width: unset;
        top: ${rem('5px')};
        svg {
            path {
                fill: ${(props) => props.theme.colors.white};
            }
        }

        &:hover {
            background: ${(props) => props.theme.colors.grey5};
        }
    }

    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.grey2};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
                background: ${({ theme }) => theme.colors.blue2};
            }

            > .civility {
                color: ${({ theme }) => theme.colors.grey5};
            }
        }
    }
    .checkboxSection {
        button {
            color: ${({ theme }) => theme.colors.grey5};
        }
    }
    .share-wrapper {
        .title {
            color: ${({ theme }) => theme.colors.grey5};
            font-size: ${({ theme }) => theme.fontSizes.h3};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .footer {
            .footer-wrapper {
                align-items: center;

                .footer-description {
                    color: ${({ theme }) => theme.colors.grey5};
                }
            }
        }

        .mandatory-description {
            color: ${({ theme }) => theme.colors.grey1};
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        .input-wrapper {
            .input-section {
                .section-header {
                    font-size: ${(props) => props.theme.fontSizes.h4};
                    color: ${({ theme }) => theme.colors.grey5};
                }
            }

            .dropdown,
            input {
                border-color: ${(props) => props.theme.colors.grey3};
                border-radius: ${rem(12)};
                font-size: ${(props) => props.theme.fontSizes.h4};

                .Select__control {
                    border-radius: ${rem(12)};
                }

                .Select__indicators {
                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.blue2};
                        }
                    }
                }
            }
        }
    }
`;
