import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutMySummaryStyled } from '../CheckoutMySummaryStyled';

export const CheckoutMySummaryStyledDS = styled(CheckoutMySummaryStyled)`
    .grid {
        margin-top: ${rem(2)};

        > .Collapsible {
            border: none;

            > .Collapsible__trigger {
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey16};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                text-transform: unset;

                &:after {
                    background-color: ${({ theme }) => theme.colors.black1};
                }

                &.is-open {
                    &:after {
                        background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.7755 0.923828L13.4727 7.64771L12.6507 8.47257L6.77516 2.57361L0.896869 8.47257L0.0748959 7.64771L6.65068 1.0488L6.7755 0.923828Z' fill='%239D8C83'/%3E%3C/svg%3E ");
                    }
                }
            }
        }
    }
`;
