import styled from 'styled-components';
import { PromoCodeStyled } from '../PromoCodeStyled';
import { rem } from 'polished';
import { IPromoCode } from '../index';

export const PromoCodeStyledDS = styled(PromoCodeStyled)`
    .promoCode {
        &__title {
            ${prop =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          color: '#1A1B1B',
                          background: '#F1EFED',
                          'font-size': '17px',
                          'text-transform': 'uppercase',
                          'font-weight': 'normal',
                      }
                    : {
                          background: 'unset',
                      }};
        }

        &__content {
            ${prop =>
                (prop as IPromoCode).isBasketSummary
                    ? {
                          color: '#1D1717',
                          padding: '15px',
                      }
                    : {
                          padding: '10px 0',
                      }};
        }
    }
`;
