import React, { memo } from 'react';
import { Dispatch } from 'redux';
import { IPxValuation, IPxWidgetProps } from '../../partExchange/interfaces/PxWidget';
import { SC } from '../../styles/theme';
import { BRAND } from '../../constants/main';
import { CheckoutMySummaryStyledAC } from './AC/CheckoutMySummaryStyled.AC';
import { CheckoutMySummaryStyledOV } from './OV/CheckoutMySummaryStyled.OV';
import { CheckoutMySummaryStyledAP } from './AP/CheckoutMySummaryStyled.AP';
import { CheckoutMySummaryStyledDS } from './DS/CheckoutMySummaryStyled.DS';
import { CheckoutMySummaryStyled } from './CheckoutMySummaryStyled';
import { IDealer } from '../../interfaces/Dealer';
import { BrandTypes } from '../../types/brands';
import { IMyOrder } from '../../redux/user/user.duck.interface';

export interface CheckoutMySummaryProps extends SC, Pick<IPxWidgetProps, 'allowPxFinalization' | 'hideAdditionalInfo'> {
    carDetails?: any;
    currentDeal?: any;
    isSecondaryLayout?: boolean;
    dispatch?: Dispatch;
    dealer?: IDealer;
    displayPx?: boolean;
    isPxOpen?: boolean;
    myAccountCarDetails?: any;
    dealerId?: string;
    showFinanceWidget?: boolean;
    financeQuoteId?: string;
    partExchangeId?: string;
    isOpenedByDefault?: boolean;
    offers?: any[];
    isCheckout?: boolean;
    isMyOrderPage?: boolean;
    journeyType?: string;
    deal?: any;
    order?: IMyOrder;
    pxState?: IPxValuation;
}

const CheckoutMySummary = memo((props: CheckoutMySummaryProps) => {
    if (BRAND === BrandTypes.OV) return <CheckoutMySummaryStyledOV {...props} />;
    if (BRAND === BrandTypes.AC) return <CheckoutMySummaryStyledAC {...props} />;
    if (BRAND === BrandTypes.AP) return <CheckoutMySummaryStyledAP {...props} />;
    if (BRAND === BrandTypes.DS) return <CheckoutMySummaryStyledDS {...props} />;
    return <CheckoutMySummaryStyled {...props} />;
});
CheckoutMySummary.displayName = 'CheckoutMySummary';

export default CheckoutMySummary;
