import React from 'react';
import { SC, theme } from '../../../styles/theme';
import Spinner from '@components/Spinner';
import { useSelector } from 'react-redux';
import UIDuck from '../../../redux/commonDucks/ui.duck';

const AutobizModal: React.FC<SC> = ({ className }) => {
    const isLoading = useSelector((state) => !!UIDuck.getOwnState(state).loading);
    // html structure as required by Autobiz CSS & JS (IDs, classes too)
    return (
        <div className={`partExchange ${className}`}>
            <div
                id="myModal"
                className="modal fade reveal-modal"
                data-reveal=""
                data-options="closeOnBackgroundClick:false"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
                role="dialog"
                data-reset-on-close="true"
            >
                <div className="modal-dialog" role="document">
                    {isLoading && (
                        <div className="modal-loading">
                            <Spinner size={50} border={5} color={theme.colors.black} />
                        </div>
                    )}
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="modal-content" id="modal-content" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// AVOID html rerenders! as Autobiz JS has listeners there and could cause not registering click events etc..
export default React.memo(AutobizModal);
