import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { FilterButtonStyled } from '../FilterButtonStyled';
import { rem } from 'polished';

export const FilterButtonStyledAC = styled(FilterButtonStyled)`
    border: 2px solid ${({ theme }) => theme.colors.grey5};
    color: ${({ theme }) => theme.colors.grey5};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    border-radius: ${rem(12)};

    &:focus-visible {
        outline: 3px solid ${({ theme }) => theme.colors.focus} !important;
        color: ${({ theme }) => theme.colors.grey5};
        border-color: ${({ theme }) => theme.colors.grey5};
    }

    :hover {
        border-color: ${({ theme }) => theme.colors.blue2};
        color: ${({ theme }) => theme.colors.blue2};
    }

    ${({ active }) =>
        active &&
        css`
            border-color: ${({ theme }) => theme.colors.blue2};
            background: rgba(87, 113, 138, 0.1);
            color: ${({ theme }) => theme.colors.blue2};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            border-color: ${({ theme }) => theme.colors.grey5};
            background: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.grey5};
            opacity: 0.2;
        `};
`;
