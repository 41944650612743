import React, { useMemo } from 'react';
import Collapsible from 'react-collapsible';
import { formatDateByLang } from '@utils/Date.utils';
import CarMainInfo from '../../CheckoutCarMainInfo';
import { OFFER_DEALER_DISCOUNT_TYPE } from '../../../constants/main';
import isPast from 'date-fns/isPast';
import DealerPartExchangeSummary from '../../PartExchangeSummary';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { useIsMyAccount } from '@hooks/useIsMyAccount';
import { useDispatch } from 'react-redux';
import { usePrices } from '@hooks/usePriceCalculator';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';
import { PaymentStatuses } from '../../../constants/paymentStatuses';
import FinanceSummary from '@components/MySummary/FinanceSummary/FinanceSummary';

interface CheckoutMySummaryOfferTemplateProps {
    offer: any;
    dealer: any;
    dealerId: string;
    openOfferId: number | null;
    onCollapsibleClick: (token: string | null) => void;
    displayPx?: boolean;
    journeyType?: string;
}

const CheckoutMySummaryOfferTemplate = ({
    offer,
    dealer,
    dealerId,
    onCollapsibleClick,
    openOfferId,
    displayPx,
    journeyType,
}: CheckoutMySummaryOfferTemplateProps) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();
    const isMyAccountPage = useIsMyAccount();
    const dispatch = useDispatch();

    const dealerDiscount =
        offer?.extraFields?.discounts &&
        JSON.parse(offer.extraFields.discounts).find(
            (discount: { key: string; amount: string }) => discount.key === OFFER_DEALER_DISCOUNT_TYPE
        )?.amount;

    const dealerPx = offer?.extraFields?.px;

    const parsedDealerPX = dealerPx && JSON.parse(dealerPx);
    const pxDiscountPrice = parsedDealerPX
        ? (parsedDealerPX.prices.governmentContributionAmount
              ? Number(parsedDealerPX.prices.governmentContributionAmount)
              : 0) +
          (parsedDealerPX.prices.dealerBonusAmount ? Number(parsedDealerPX.prices.dealerBonusAmount) : 0) +
          (parsedDealerPX.prices.dealerValuation ? Number(parsedDealerPX.prices.dealerValuation) : 0)
        : 0;

    const {
        cash: { summary },
    } = usePrices(offer.fullProductConfiguration, false, dealerDiscount);

    const handleOpen = () => {
        onCollapsibleClick(offer.token);
    };

    const handleClose = () => {
        onCollapsibleClick(null);
    };

    const status = useMemo(() => offer?.status, [offer]);

    const financeFileId = useMemo(() => offer?.extraFields?.finance_file_id, [offer]);

    const isAfterRecapPage = useMemo(
        () => [PaymentStatuses.financeApproved, PaymentStatuses.financeRejected].includes(status),
        [status]
    );

    const featureFinanceOverviewEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_CHECKOUT_FINANCE_ENABLED
    );
    const featureCheckoutContractEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_CHECKOUT_CONTRACT_ENABLED
    );

    const isFinanceOverviewEnabled = useMemo(
        () => (isAfterRecapPage ? featureFinanceOverviewEnabled : true),
        [isAfterRecapPage, featureFinanceOverviewEnabled]
    );
    const isContractEnabled = useMemo(
        () => (isAfterRecapPage ? featureCheckoutContractEnabled : false),
        [isAfterRecapPage, featureCheckoutContractEnabled]
    );

    return (
        <>
            <Collapsible
                key={offer.token}
                className="offer"
                openedClassName="offer"
                open={false}
                onOpen={handleOpen}
                onClose={handleClose}
                lazyRender
                trigger={
                    <div className="offerTitle">
                        <div className="offerTitle__line" />
                        <div className="offerTitle__id">{`${t('offer')}: ${offer.token}`}</div>
                        {offer.monthlyPayment && (
                            <div className="offerTitle__price">
                                {formatMoney(offer.monthlyPayment)}
                                <span>{t('dealPreview.dealFinancePrice.monthlySuffix')}</span>
                            </div>
                        )}
                        {!offer.monthlyPayment && (
                            <div className="offerTitle__price">
                                {formatMoney(summary.finalPriceWithAccessoriesAndDiscounts - pxDiscountPrice)}
                            </div>
                        )}
                        <div className="offerTitle__expirationDate">
                            {t('expirationDate', {
                                date: formatDateByLang(offer.endDate),
                            })}
                        </div>
                    </div>
                }
            >
                <CarMainInfo
                    carConfigData={offer.fullProductConfiguration}
                    dealerDiscount={dealerDiscount}
                    dealer={dealer}
                    isAccordionOpen
                    financeQuote={''}
                    existingLanguage={'fr'}
                    dispatch={dispatch}
                    dealerId={dealerId}
                    dealerPx={dealerPx}
                    isExpired={isPast(offer.endDate)}
                    offerEnd={offer.endDate}
                    dealId={offer.token}
                    comments={offer?.extraFields?.comments}
                    isParent={false}
                    financeQuoteId={offer.financeQuoteId}
                    mopId={offer.mopId}
                    displayPx={displayPx}
                    journeyType={journeyType}
                    status={offer.status}
                />
            </Collapsible>
            {offer.token === openOfferId && (
                <>
                    {isContractEnabled && (
                        <Collapsible
                            lazyRender={false}
                            trigger={t('myaccount.contractSummary.title')}
                            triggerWhenOpen={t('myaccount.contractSummary.title')}
                        >
                            {t('myaccount.contractSummary.text')} {financeFileId}
                        </Collapsible>
                    )}
                    {offer?.extraFields?.px && offer.financeQuoteId && (
                        <Collapsible
                            lazyRender={isMyAccountPage}
                            trigger={t('px.title')}
                            triggerWhenOpen={t('px.title')}
                        >
                            <DealerPartExchangeSummary pxData={JSON.parse(offer.extraFields.px)} />
                        </Collapsible>
                    )}
                    {isFinanceOverviewEnabled && offer.financeQuoteId && (
                        <Collapsible
                            lazyRender={isMyAccountPage}
                            trigger={t('checkout.financewidget.title.primary')}
                            triggerWhenOpen={t('checkout.financewidget.title.tertiary')}
                        >
                            <FinanceSummary id={offer.financeQuoteId} />
                        </Collapsible>
                    )}
                </>
            )}
        </>
    );
};

export default CheckoutMySummaryOfferTemplate;
