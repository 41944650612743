import styled from 'styled-components';
import { breakpoints, defaultBreakpoints, theme } from '../../../styles/theme';
import { FooterStyled } from '../FooterStyled';
import { rem } from 'polished';

export const FooterStyledOV = styled(FooterStyled)`
    .orderNowSection {
        background: ${({ theme }) => theme.colors.pureWhite};
        color: ${({ theme }) => theme.colors.grey5};
        padding-top: ${rem(61)};
        padding-bottom: ${rem(41)};
        
        .orderNowTitle {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-size: ${({ theme }) => theme.fontSizes.h3}
            line-height: ${({ theme }) => theme.lineHeights.h2}
            color: ${({ theme }) => theme.colors.black};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            padding-bottom: ${rem(30)};
            text-transform: none;

            @media (min-width: ${breakpoints.xs}px) {
                font-size: ${rem(24)};
            }
        }

        .itemsWrapper {
            justify-content: center;

            .footerItem {
                .textWrap {
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-size: ${({ theme }) => theme.fontSizes.textSubMedium};
                    color: ${({ theme }) => theme.colors.black};
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    display: flex;
                    align-items: baseline;
                    line-height: ${rem(22)};
                    margin-top: ${rem(5)};
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal @media (min-width: ${defaultBreakpoints.sm}px) {
                        line-height: ${({ theme }) => theme.lineHeights.textBase};
                        font-size: ${({ theme }) => theme.fontSizes.h6};
                    }
                }

                @media (min-width: ${defaultBreakpoints.md}px) {
                    flex-direction: column;
                    border-right: none;
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    max-width: ${rem(260)};
                    padding: 0 ${rem(40)};
                }

                .imageWrap {
                    padding-right: 0;
                    width: ${rem(65)};

                    @media (min-width: ${breakpoints.md}px) {
                        width: ${rem(50)};
                    }

                    svg {
                        rect {
                            stroke: ${({ theme }) => theme.colors.grey1};
                        }
                        path {
                            fill: ${({ theme }) => theme.colors.grey1};
                        }
                    }
                    .icon-wrapper {
                        height: 100%;
                    }
                }
            }
        }
    }

    .footer {
        background: ${({ theme }) => theme.colors.black2};
        padding: ${rem(29)} 0 ${rem(39)};

        ul {
            li {
                line-height: normal;
                border: none;

                @media (min-width: ${breakpoints.sm}px) {
                    position: relative;

                    &:not(:last-child) {
                        &::after {
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 0;
                            width: ${rem(1)};
                            height: 80%;
                            top: 50%;
                            transform: translateY(-40%);
                            background: ${({ theme }) => theme.colors.white};
                        }
                    }
                }

                a {
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-size: ${({ theme }) => theme.fontSizes.textMedium};
                    line-height: ${({ theme }) => theme.lineHeights.textMedium};
                    text-transform: capitalize;
                    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                    @media (min-width: ${breakpoints.sm}px) {
                        line-height: ${rem(20)};
                    }
                }
            }
        }

        .logo {
            display: none;
        }
    }

    .footerScrollToTopAnchor {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        

        .icon-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        @media (max-width: ${breakpoints.xs}px) {
            border: 1px solid black; //todo
            height: ${rem('40px')};
        }

        svg {
            path {
                stroke: ${({ theme }) => theme.colors.black2};
            }

            rect {
                stroke: initial;
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }
    .cookiesPreferences {
        text-transform: uppercase;
    }
    
    .scrollToTopIcon {
	  	background-color: ${theme.colors.grey22} !important;
			
			svg {
				color: ${theme.colors.pureWhite} !important;
			}
		}

    .footerScrollToTopContainer {
        text-transform: none;
        padding-bottom: ${rem('50px')};
    }
    
`;
