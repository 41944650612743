import React, { FC } from 'react';
import useTranslations from '@hooks/useTranslations';
import { PriceSuffixProps } from './index';
import { MARKET, MARKETS } from '../../constants/main';
import { useIsShowPriceExcludedVAT } from '@hooks/useIsShowPriceExcludedVAT';

const PriceSuffixTemplate: FC<PriceSuffixProps> = ({
    className,
    isMonthlyPrice = false,
    checkPrioritizedTranslation,
    showOnlyPrioritizedTranslation = false,
    hideAsterisk = false,
    showPriceExcludedVat = false,
}) => {
    const { t, hasValue } = useTranslations();
    const isUKMarket = MARKET === MARKETS.GB;
    const isShowPriceExcludedVAT: boolean = useIsShowPriceExcludedVAT();
    const hasPrioritizedTranslationKey = (key: string) => {
        return checkPrioritizedTranslation && hasValue(`${key}.prioritized`);
    };

    const getTranslationKeyByPriority = (key: string, prefix: string = '') => {
        if (showOnlyPrioritizedTranslation) {
            return hasPrioritizedTranslationKey(key) ? t(`${key}.prioritized`) : '';
        }

        return checkPrioritizedTranslation && hasValue(`${key}.prioritized`)
            ? t(`${key}.prioritized`)
            : `${prefix}${t(key)}`;
    };

    const RenderPrice = () => {
        switch (true) {
            case !isShowPriceExcludedVAT && !showPriceExcludedVat && isMonthlyPrice: {
                return (
                    <span className="currencyLabel__suffix--TTC">
                        {!hasPrioritizedTranslationKey('price.monthly') ? t('price.TTC') : ''}
                        <span className="currencyLabel__suffix--TTC-monthly">
                            {getTranslationKeyByPriority('price.monthly', '/')}
                            {!hasPrioritizedTranslationKey('price.monthly') && !hideAsterisk && '*'}
                        </span>
                    </span>
                );
            }

            case !isShowPriceExcludedVAT &&
                !showPriceExcludedVat &&
                showOnlyPrioritizedTranslation &&
                !hasPrioritizedTranslationKey('price.TTC'): {
                return <span />;
            }

            case showPriceExcludedVat: {
                return <span className="currencyLabel__suffix--OTR">{t('vatExcluded')}</span>;
            }

            case isShowPriceExcludedVAT: {
                return <span className="currencyLabel__suffix--OTR">{t('vatIncluded')}</span>;
            }

            default: {
                return <span className="currencyLabel__suffix--OTR">{getTranslationKeyByPriority('price.TTC')}</span>;
            }
        }
    };

    if (isUKMarket) {
        const translationKeyByPriority = {
            otr: getTranslationKeyByPriority('price.OTR'),
            monthly: getTranslationKeyByPriority('price.monthly'),
        };

        return (
            <span className={className}>
                {isMonthlyPrice ? (
                    <span className="currencyLabel__suffix--PM">
                        {translationKeyByPriority.monthly}
                        {!hasPrioritizedTranslationKey('price.monthly') && !hideAsterisk && '*'}
                    </span>
                ) : (
                    <>
                        {!!translationKeyByPriority.otr && (
                            <span className="currencyLabel__suffix--OTR">{translationKeyByPriority.otr}</span>
                        )}
                    </>
                )}
            </span>
        );
    }

    return (
        <span className={className}>
            <RenderPrice />
        </span>
    );
};

export default PriceSuffixTemplate;
