export enum PaymentStatuses {
    completed = 'sepa_completed',
    offerExpired = 'offer_expired',
    failed = 'sepa_failed',
    redirected_to_sepa = 'redirected_to_sepa',
    redirected_to_loan_provider = 'redirected_to_loan_provider',
    financeApproved = 'finance_approved',
    financeRejected = 'finance_rejected',
    folder_complete = 'folder_complete',
    proceed_to_bank = 'proceed_to_bank',
    order_placed = 'order_placed',
}

export enum CashPaymentStatuses {
    accepted = 'accepted',
    declined = 'declined',
    cancelled = 'canceled',
    pending = 'pending',
    error = 'error',
}
