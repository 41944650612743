import styled from 'styled-components';
import { ModalFeaturesStyled } from '../ModalFeaturesStyled';
import { rem } from 'polished';

export const ModalFeaturesStyledAC = styled(ModalFeaturesStyled)`
    .engines,
    .colors,
    .interiors,
    .features {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }
    }

    .categories {
        .grid-button {
            min-width: ${rem(120)};
            padding: 0 ${rem(5)};

            .info-check {
                svg {
                    width: ${rem(18)};
                    margin-left: ${rem(4)};

                    g {
                        fill: ${({ theme }) => theme.colors.blue2};
                    }
                }
            }
        }

        .disp-inline {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span {
            all: unset;

            &:hover {
                all: unset;
            }
        }
    }
`;
