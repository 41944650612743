import React, { useEffect } from 'react';
import { ITrimItem } from '../../../../redux/trimSelector/trimSelector.duck.interface';
import { theme } from '../../../../styles/theme';
import {
    IAggregatedTrim,
    ICarDetail,
    ISelectedSpeckPackAndEngine,
} from '../../../../redux/carDetails/carDetails.duck.interface';
import UIDuck from '../../../../redux/commonDucks/ui.duck';
import { buildParameterizedPathname, extractParamsFromPathname } from '@utils/url.utils';
import routes from '../../../../constants/routes';
import { getCalculateSummary } from '../../../../services/calculateSummary/calculateSummary.service';
import { ModalVersion } from '../../../../constants/main';
import { CarJourneyType } from '../../../../services';
import { IFilterRule, IPriceRange } from '../../../../redux/filters/filter.duck.interface';
import { EContextPage, findPromoByPageAndType } from '@utils/Promo.utils';

export const KEY_ALL = 'all';
export const KEY_PROPERTIES = 'property';
export const KEY_TRIMS = 'trim';
export const KEY_COLORS = 'color';
export const KEY_INTERIORS = 'interior';
export const KEY_FEATURES = 'feature';
export const KEY_STRONG_POINTS = 'title';
export const KEY_ENGINES = 'engine';

export const KEY_STICKY_AVAILABILITY = 'SECTION_TRIM_AVAILABILITY';
export const KEY_STICKY_NAME = 'SECTION_TRIM_NAME';
export const KEY_STICKY_PRICE = 'SECTION_TRIM_PRICE';
export const KEY_STICKY_BUTTON = 'SECTION_TRIM_BUTTON';
export const KEY_STICKY_SELECT = 'SECTION_TRIM_SELECT';
export const KEY_STICKY_BACK_BUTTON = 'BACK_BUTTON';

export const SELECTED = 'selected';
export const SCROLL_ELEMENT_CLASS_NAME = 'trim-compare';

export const GEARBOX_SEARCH_KEY = 'type';

export const AVG_TITLE_HEIGHT = 25;
export const AVG_AVAILABILITY_HEIGHT = 45;
export const TESTING_PREFIX = 'trims-comparator-';
export const TRIM_MOBILE_WIDTH_WITH_PADDING = 175;
export const TRIM_DESKTOP_WIDTH_WITH_PADDING = 305;
export const ELEMENT_PADDING = 30;

export const MOBILE_AND_DESKTOP_VERSION_BREAKPOINT = 900;

export const hasTrimLabelOrDescriptionOrTitle = (selectedPacksAndEnginesForComparison: any, allTrims: any) => {
    const trimsWithOffers = [];
    const trimsWithLabel = [];
    const trimsWithDescription = [];
    const trimsWithFeatures = [];

    selectedPacksAndEnginesForComparison?.map((selectedSpecPackAndEngine: ISelectedSpeckPackAndEngine) => {
        const selectedTrim = findTrimBySelectedSpeckPackAndEngine(allTrims, selectedSpecPackAndEngine);

        if (findPromoByPageAndType(selectedTrim?.cheapestCar?.offers, EContextPage.trim)) {
            trimsWithOffers.push(selectedTrim);
        }

        if (selectedTrim?.cheapestCar?.trimContent?.title) {
            trimsWithLabel.push(selectedTrim);
        }

        if (selectedTrim?.cheapestCar?.trimContent?.description) {
            trimsWithDescription.push(selectedTrim);
        }

        if (selectedTrim?.cheapestCar?.seriesEquipment?.length) {
            trimsWithFeatures.push(selectedTrim);
        }
    });

    return {
        hasAnyTrimOffers: trimsWithOffers?.length > 0,
        hasAnyTrimLabel: trimsWithLabel?.length > 0,
        hasAnyTrimDescription: trimsWithDescription?.length > 0,
        hasAnyTrimMainFeatures: trimsWithFeatures?.length > 0,
    };
};

export const parseSelectedFiltersToRequest = (filters: IFilterRule[]) => {
    const pricesFilter = filters?.find((filter) => filter.parent === 'prices');

    const mtoFilters = {
        fuelTypeNames: filters
            ?.filter((filter) => filter?.parent === 'fuel' && filter?.value)
            ?.map((selectedFilter) => selectedFilter?.displayName),
        gearboxTypeNames: filters
            ?.filter((filter) => filter?.parent === 'gearbox' && filter?.value)
            ?.map((selectedFilter) => selectedFilter?.displayName),
    };

    return {
        ...(mtoFilters?.fuelTypeNames?.length > 0 && { fuelTypeNames: mtoFilters.fuelTypeNames }),
        ...(mtoFilters?.gearboxTypeNames?.length > 0 && { gearboxTypeNames: mtoFilters.gearboxTypeNames }),
        ...((pricesFilter?.value as IPriceRange)?.min && {
            minBudget: String((pricesFilter?.value as IPriceRange)?.min),
        }),
        ...((pricesFilter?.value as IPriceRange)?.max && {
            maxBudget: String((pricesFilter?.value as IPriceRange)?.max),
        }),
    };
};

export const setUniqueAndSelectedPacksAndEnginesForComparison = (
    allTrims: any,
    setUniquePacksAndEnginesForComparison: any,
    setSelectedPacksAndEnginesForComparison: any,
    maxCountOfVisibleTrimsForCurrentWidth: number
) => {
    const uniquePacksAndEngines = [];
    for (let index = 0; index < allTrims?.length; index++) {
        uniquePacksAndEngines.push({
            specPackId: allTrims?.[index]?.engines?.[0]?.cheapestCar?.specPack?.id,
            specPackTitle: allTrims?.[index]?.engines?.[0]?.cheapestCar?.specPack?.title,
            engines: allTrims?.[index]?.engines?.map(
                (engine: { cheapestCar: { engine: { id: string; title: string }; offers: {}[] } }) => ({
                    id: engine.cheapestCar.engine.id,
                    title: engine.cheapestCar.engine.title,
                    offers: engine.cheapestCar.offers,
                })
            ),
        });
    }
    setUniquePacksAndEnginesForComparison(uniquePacksAndEngines);

    const preSelectedPacksAndEngines = [];
    for (let index = 0; index < maxCountOfVisibleTrimsForCurrentWidth; index++) {
        preSelectedPacksAndEngines.push({
            specPackId: uniquePacksAndEngines[index].specPackId,
            specPackTitle: uniquePacksAndEngines[index].specPackTitle,
            engineId: uniquePacksAndEngines[index].engines?.[0].id,
            engineTitle: uniquePacksAndEngines[index].engines?.[0].title,
        });
    }
    setSelectedPacksAndEnginesForComparison(preSelectedPacksAndEngines);
};

export const findTrimBySelectedSpeckPackAndEngine = (
    allTrims: IAggregatedTrim[],
    selectedSpecPackAndEngine: {
        specPackId: string;
        engineId: string;
    },
    returnTrim?: boolean
) => {
    let selectedTrim: any;

    allTrims?.every((trim: IAggregatedTrim) => {
        if (selectedTrim) {
            return false;
        }

        trim.engines?.every((engine) => {
            if (
                engine.cheapestCar?.specPack.id === selectedSpecPackAndEngine.specPackId &&
                engine.cheapestCar?.engine?.id === selectedSpecPackAndEngine.engineId
            ) {
                selectedTrim = returnTrim ? { ...trim, engines: [engine] } : engine;
                return false;
            }

            return true;
        });

        return true;
    });

    return selectedTrim;
};

export const getMainImageUrl = (vehicle: ICarDetail): string | null => {
    const images = vehicle?.images?.filter((img: any) => img.type === 'Ext') ?? [{}];
    const mainImage = images?.splice(1, 1) ?? [{}];

    return mainImage[0]?.url;
};

export const redirectToConfigurator = (vehicle: ICarDetail, dispatch: any, router: any, carJourney: CarJourneyType) => {
    dispatch(UIDuck.closeModal());

    const currentPathParams = extractParamsFromPathname(router.asPath);
    const newPathParams = {
        nameplateBodyStyleSlug: currentPathParams.nameplateBodyStyleSlug,
        speckPackSlug: vehicle?.specPackSlug,
        engineGearboxFuelSlug: vehicle?.engineGearboxFuelSlug,
        exteriorColourSlug: vehicle?.exteriorColourSlug,
        interiorColourSlug: vehicle?.interiorColourSlug,
    };

    const modelURL = buildParameterizedPathname(routes.CAR, carJourney, newPathParams);

    return router.push(modelURL);
};

export const calculateSummaryData = (carDetails: ICarDetail, dispatch: any, countOfFilteredTrims: number) => {
    getCalculateSummary({
        carConfigId: carDetails.externalId,
    }).then((data) => {
        dispatch(
            UIDuck.openModal({
                data: {
                    financialData: data,
                },
                callbacks: {
                    openPreviousModalType: UIDuck.MODAL_TYPES.TRIM_COMPARE,
                    modalProperties: {
                        withoutPadding: true,
                        fullWidthOnDesktop: countOfFilteredTrims >= 4,
                        middleWidthOnDesktop: countOfFilteredTrims === 3,
                    },
                },
                modalType: UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY_CONFIGURATION,
                modalVersion: ModalVersion.v2,
            })
        );
    });
};

export const useStickyListenerDesktop = (trims: ITrimItem[]) => {
    useEffect(() => {
        if (trims) {
            const element = document?.getElementsByClassName(SCROLL_ELEMENT_CLASS_NAME)?.[0];

            const trimWidth = TRIM_DESKTOP_WIDTH_WITH_PADDING;

            const stickyAvailability = document.getElementById(KEY_STICKY_AVAILABILITY);

            const stickySelect = document.getElementById(KEY_STICKY_SELECT);
            const stickyName = document.getElementById(KEY_STICKY_NAME);
            const stickyPrice = document.getElementById(KEY_STICKY_PRICE);
            const stickyEngines = document.getElementsByClassName('trimEngine');

            element?.addEventListener('scroll', () => {
                stickySelect.style.top = `${element.scrollTop >= 75 ? AVG_AVAILABILITY_HEIGHT : 75}px`;
                stickyPrice.style.top = `${stickyName.offsetHeight + AVG_TITLE_HEIGHT}px`;

                if (element?.scrollTop > stickyName.offsetHeight + stickyPrice.offsetHeight + AVG_TITLE_HEIGHT) {
                    stickyPrice.style.boxShadow = `0 4px 6px -2px ${theme.colors.grey12}`;

                    for (let index = 0; index < stickyEngines.length; index++) {
                        (stickyEngines[index] as HTMLElement).style.display = 'block';
                    }
                } else {
                    stickyPrice.style.boxShadow = `unset`;

                    for (let index = 0; index < stickyEngines.length; index++) {
                        (stickyEngines[index] as HTMLElement).style.display = 'none';
                    }
                }

                stickyPrice.style.display =
                    element?.scrollTop > stickyName.offsetHeight + AVG_TITLE_HEIGHT && element.clientWidth < 480
                        ? 'none'
                        : 'block';

                const width = `${
                    (element as HTMLElement)?.clientWidth > (trims?.length + 1 || 1) * trimWidth + ELEMENT_PADDING
                        ? `${(element as HTMLElement)?.clientWidth}px`
                        : `${(trims?.length + 1 || 1) * trimWidth}px`
                }`;

                stickyAvailability.style.width = width;
                stickyName.style.width = width;
                stickyPrice.style.width = width;
            });
        }
    }, [trims]);
};

export const useStickyListenerMobile = (trims: ITrimItem[]) => {
    useEffect(() => {
        if (trims) {
            const element = document?.getElementsByClassName(SCROLL_ELEMENT_CLASS_NAME)?.[0];

            const stickySelect = document.getElementById(KEY_STICKY_SELECT);
            const stickyName = document.getElementById(KEY_STICKY_NAME);
            const stickyPrice = document.getElementById(KEY_STICKY_PRICE);
            const stickyButton = document.getElementById(KEY_STICKY_BUTTON);
            const stickyEngines = document.getElementsByClassName('trimEngine');

            element?.addEventListener('scroll', () => {
                if (
                    element?.scrollTop >
                    stickySelect.offsetHeight +
                        stickyName.offsetHeight +
                        stickyPrice.offsetHeight +
                        stickyButton.offsetHeight +
                        100
                ) {
                    stickyName.style.boxShadow = `0 4px 6px -2px ${theme.colors.grey12}`;
                    stickyName.style.paddingBottom = `20px`;

                    for (let index = 0; index < stickyEngines.length; index++) {
                        (stickyEngines[index] as HTMLElement).style.display = 'block';
                    }
                } else {
                    stickyName.style.boxShadow = `unset`;
                    stickyName.style.paddingBottom = `0`;

                    for (let index = 0; index < stickyEngines.length; index++) {
                        (stickyEngines[index] as HTMLElement).style.display = 'none';
                    }
                }
            });
        }
    }, [trims]);
};
