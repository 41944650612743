import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { ModalStyled } from '../ModalStyled';
import { rem } from 'polished';

export const ModalStyledAC = styled(ModalStyled)`
    .modalSidebar {
        &__wrap {
            color: ${({ theme }) => theme.colors.grey5};
          
            &__header {
                font-size: ${rem(26)};
                color: ${({ theme }) => theme.colors.grey5};

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(30)};
                }

                &__closeBtn {
                    color: ${({ theme }) => theme.colors.grey5};
                }
            }
        }
    }

    .modalFinancialSummary {
        &--title {
            font-size: ${rem(20)};
            color: ${({ theme }) => theme.colors.grey5};
        }
    }
  
    .modalWindow__wrap {
        &__header__closeBtn {
            color: ${({ theme }) => theme.colors.grey5};
        }
        &__header__title {
            color: ${({ theme }) => theme.colors.grey5};
        }
        &.finance-legal {
            .button-container {
                button {
                    background: ${({ theme }) => theme.colors.blue2};
                    border-radius: 24px;
                    font-size: ${({ theme }) => theme.fontSizes.h4};
                    
                    &:hover {
                        background: ${({ theme }) => theme.colors.grey5};
                    }
                }
            }
        }
    }
    
    .section {
        color: ${({ theme }) => theme.colors.grey5};
    }
    
    input[type='checkbox'],
    input[type='radio'] {
        &:checked::before {
            background: ${({ theme }) => theme.colors.blue2};
        }
    }    
    
    a {
        color: ${({ theme }) => theme.colors.grey5};
        
        &:hover {
            color: ${({ theme }) => theme.colors.red};
        }
    }
    
    .btn-primary {
        background: ${({ theme }) => theme.colors.blue2};
        border-radius: 12px;
        font-size: ${({ theme }) => theme.fontSizes.h4};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        
        &:hover {
            background: ${({ theme }) => theme.colors.grey5};
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
