import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutCarMainInfoStyled } from '../CheckoutCarMainInfoStyled';
import { media } from '../../../styles/media';

export const CheckoutCarMainInfoStyledDS = styled(CheckoutCarMainInfoStyled)`
    .dealerAddressTitle,
    .addressWrapper,
    .phoneWrapper,
    .mailWrapper {
        font-size: ${({ theme }) => theme.fontSizes.h6};

        p {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        a {
            color: ${({ theme }) => theme.colors.black};
        }

        svg {
            width: ${rem(20)};
            height: ${rem(20)};
        }

        .phoneIcon {
            svg {
                width: ${rem(30)};
                height: ${rem(30)};
                margin-top: -${rem(5)};
                margin-left: -${rem(5)};
                path {
                    fill: ${({ theme }) => theme.colors.secondary} !important;
                }
            }
        }

        .mapPointIcon path {
            stroke-width: 0;
            fill: ${({ theme }) => theme.colors.secondary};
        }

        .mailIcon {
            svg {
                width: ${rem(40)};
                height: ${rem(40)};
                margin-top: -${rem(10)};
                margin-left: -${rem(10)};
            }
        }
    }
    .carInfoHeader {
        .title {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-size: ${rem(32)};
            color: ${({ theme }) => theme.colors.black};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            line-height: ${rem(30)};
            letter-spacing: ${rem(0.5)};
            text-transform: uppercase;
        }

        .car-model {
            letter-spacing: ${rem(0.5)};
            font-size: ${rem(32)};
            display: inline-grid;
            font-style: normal;
            color: ${({ theme }) => theme.colors.black};
            font-family: ${({ theme }) => theme.fonts.fontBase};

            span:first-child,
            span:nth-child(2) {
                text-transform: uppercase;
            }

            span:first-child {
                font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            }

            span:nth-child(2) {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }
        }

        .availableInWrapperMobile {
            display: flex;
            align-items: center;
            ${({ theme }) => theme.colors.black};
        }
    }
    .carInfo {
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    .viewMore {
        margin-top: 0;
    }

    .mobileCarDelivery {
        border: none;
        ${media.sm`
            padding-bottom: ${rem(25)};
        `}
    }

    .separatorLine {
        border: 1px solid ${({ theme }) => theme.colors.grey16};
        margin-bottom: 0;
    }

    .carImageWrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        ${media.sm`
            flex-direction: row;
        `}
    }

    .availabilityStock {
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        line-height: ${({ theme }) => theme.fontSizes.h3};
        color: #787b80;

        .leadTimeDate {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .availableInLabel {
        font-size: ${rem(14)};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        color: ${({ theme }) => theme.colors.black1};
    }

    .availableInDate {
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        font-size: ${rem(14)};
    }

    .optionsWrapper {
        .paragraph {
            span {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular} !important;
            }
        }
    }

    .totalPrice {
        font-size: ${({ theme }) => theme.fontSizes.h5};
    }

    .detailsContent {
        font-family: ${({ theme }) => theme.fonts.fontBase};

        .paragraph {
            margin: ${rem(12)} 0;
            font-size: ${({ theme }) => theme.fontSizes.h6};
            color: ${({ theme }) => theme.colors.black};

            span:first-child {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }
    }

    .priceSummary {
        font-family: ${({ theme }) => theme.fonts.fontBase};

        .priceInfoWrapper {
            font-size: ${rem(14)};
            line-height: ${rem(21)};
            color: ${({ theme }) => theme.colors.black};

            span:first-child {
                margin-bottom: ${rem(10)};
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            &.totalPriceInfo {
                font-size: ${rem(16)};
                line-height: ${rem(21)};
                font-weight: bold;
            }
        }
    }
`;
