import {useExperimentalFeatureFlagEnabled, useFeatureSwitchEnabled} from 'src/hooks/useFeatureSwitchEnabled';
import {FEATURES_LIST} from 'src/context/featureSwitchApp';
import {EXPERIMENTAL_FEATURES_LIST} from '@utils/experiments/@types';

export const useFeatureSwitch = () => {
    const isBasketLegalTermsEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BASKET_LEGAL_TERMS_AND_CONDITIONS
    );

    const isBrandRecommendsJourneyAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_ENABLED
    );

    const isDealerSelectionCheckoutRedirectAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT
    );

    const isSaveBasketCTADisabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SAVE_BASKET_CTA_DISABLED);
    const isStockBasketSaveItemEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_STOCK_BASKET_SAVE_ITEM);

    const isShareConfigurationEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_SHARE_CONFIGURATION_ENABLED
    );
    const isStockDealerShareConfigurationEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_STOCK_DEALER_SHARE_CONFIGURATION_ENABLED
    );
    const isMTODealerShareConfigurationEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_MTO_DEALER_SHARE_CONFIGURATION_ENABLED
    );
    const isStockSummaryEnabledByPosthog = useExperimentalFeatureFlagEnabled(
        EXPERIMENTAL_FEATURES_LIST.SHOW_SUMMARY_BEFORE_BASKET_STOCK
    );
    const isStockSummaryEnabled =
        useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_STOCK_SUMMARY_ENABLED) ||
        isStockSummaryEnabledByPosthog;
    const isMTOSummaryEnabledByPosthog = useExperimentalFeatureFlagEnabled(
        EXPERIMENTAL_FEATURES_LIST.SHOW_SUMMARY_BEFORE_BASKET_MTO
    );
    const isMTOSummaryEnabled =
        useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_MTO_SUMMARY_ENABLED) || isMTOSummaryEnabledByPosthog;
    const isUpsellOptionsSectionEnabledOnSummaryPageByPosthod = useExperimentalFeatureFlagEnabled(
        EXPERIMENTAL_FEATURES_LIST.SHOW_UPSELL_OPTIONS_ON_SUMMARY_PAGE
    );
    const isUpsellOptionsSectionEnabledOnSummaryPage =
        useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_UPSELL_OPTIONS_SECTION_ENABLED) ||
        isUpsellOptionsSectionEnabledOnSummaryPageByPosthod;
    const isDealerChoiceEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED);
    const isReinsuranceInfoEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_REINSURANCE_INFO_ENABLED);

    const isDealerFeesEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED);
    const isCarteGriseEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_CARTE_GRISE_ENABLED);

    const isDealerLayerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED);

    const isMyAccountOrdersDisabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_MY_ACCOUNT_ORDERS_DISABLED);

    const isPosthogEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_POSTHOG_ENABLED);
    const isPosthogAutoCaptureEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_POSTHOG_IS_AUTOCAPTURE_ENABLED);

    return {
        isDealerSelectionCheckoutRedirectAllowed,
        isBasketLegalTermsEnabled,
        isBrandRecommendsJourneyAllowed,
        isSaveBasketCTADisabled,
        isStockBasketSaveItemEnabled,
        isShareConfigurationEnabled,
        isStockDealerShareConfigurationEnabled,
        isMTODealerShareConfigurationEnabled,
        isUpsellOptionsSectionEnabledOnSummaryPage,
        isStockSummaryEnabled,
        isMTOSummaryEnabled,
        isDealerChoiceEnabled,
        isReinsuranceInfoEnabled,
        isDealerFeesEnabled,
        isCarteGriseEnabled,
        isDealerLayerEnabled,
        isMyAccountOrdersDisabled,
        isPosthogEnabled,
    };
};
