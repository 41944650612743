import styled from 'styled-components';
import SpinnerTemplate from './SpinnerTemplate';
import { SpinnerProps } from '.';

export const SpinnerStyled = styled(SpinnerTemplate)<SpinnerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .spinner {
        display: inline-block;
        position: relative;
        width: ${props => `${props.size}` || '30'}px;
        height: ${props => `${props.size}` || '30'}px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: ${props => `${props.size}` || '30'}px;
            height: ${props => `${props.size}` || '30'}px;
            border: ${props => `${props.border}` || '6'}px solid ${({ theme }) => theme.colors.white};
            border-radius: 50%;
            animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: ${({ theme, color }) => `${color ?? theme.colors.white} transparent transparent transparent`};
        }

        div:nth-child(1) {
            animation-delay: -0.45s;
        }

        div:nth-child(2) {
            animation-delay: -0.3s;
        }

        div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
