import Input from '../../../../ui/Input';
import React from 'react';
import Icon, {Icons} from '@components/Icon';
import Button from '@components/Button';
import TextArea from '../../../../ui/TextArea';
import Checkbox from '@components/Checkbox';
import {IconTemplate} from '@components/Icon/IconTemplate';
import {SC} from '../../../../styles/theme';
import useTranslations from '@hooks/useTranslations';
import {INPUT_TYPES} from "../../../../constants/enums";
import {isBrandAC} from "../../../../constants/main";

export const AGENT_SOURCE_ID = 'agent-portal';

interface IOptions extends SC {
    dealerFees: { id: string; title: string; priceInclTax: number; label: string; source: string; selected: boolean }[];
    setDealerFees: any;
    hasRequiredError: boolean;
}

export const OptionsTemplate = ({ dealerFees, setDealerFees, hasRequiredError }: IOptions) => {
    const { t } = useTranslations();

    return (
        <div>
            {dealerFees?.map((dealerFee, key) => (
                <div key={`${dealerFee.title}-${key}`} className="option">
                    <div className="option_header">
                        <Checkbox
                            name={`${dealerFee.title}-${key}`}
                            checked={dealerFee?.selected}
                            onChange={() => {
                                setDealerFees(
                                    dealerFees.map((fee, index) => {
                                        if (index === key) {
                                            fee.selected = !dealerFee.selected;
                                        }

                                        return fee;
                                    })
                                );
                            }}
                            label={t('modal.personalizeOffer.supplements.new')}
                        />
                        <Icon
                            name={Icons.Trash}
                            onClick={() =>
                                setDealerFees(
                                    dealerFees.filter(
                                        (filteredDealerFee) => filteredDealerFee.title !== dealerFee.title
                                    )
                                )
                            }
                        />
                    </div>
                    <div className="option_detail">
                        <div className="row without-flex">
                            <Input
                                defaultValue={dealerFee.title}
                                onChange={(value: string) => {
                                    dealerFees[key].title = value;
                                    setDealerFees(dealerFees);
                                }}
                                label={t('modal.personalizeOffer.supplements.input.name.label')}
                                placeholder={t('modal.personalizeOffer.supplements.input.name.placeholder')}
                            />
                            {hasRequiredError && !dealerFee.title && (
                                <div className="row_error">
                                    {t('modal.personalizeOffer.supplements.input.name.error')}
                                </div>
                            )}
                        </div>
                        <div className="row without-flex">
                            <Input
                                defaultValue={dealerFee.priceInclTax}
                                type={INPUT_TYPES.NUMBER}
                                onChange={(value: number) => {
                                    dealerFees[key].priceInclTax = value;
                                    setDealerFees(dealerFees);
                                }}
                                label={t('modal.personalizeOffer.supplements.input.price.label')}
                                placeholder={t('modal.personalizeOffer.supplements.input.price.placeholder')}
                            />
                            {hasRequiredError && !dealerFee.priceInclTax && (
                                <div className="row_error">
                                    {t('modal.personalizeOffer.supplements.input.price.error')}
                                </div>
                            )}
                        </div>
                        <TextArea
                            defaultValue={dealerFee.label}
                            onChange={(value: string) => {
                                dealerFees[key].label = value;
                                setDealerFees(dealerFees);
                            }}
                            label={t('modal.personalizeOffer.supplements.input.description.label')}
                            placeholder={t('modal.personalizeOffer.supplements.input.description.placeholder')}
                            rows={4}
                            marginTop={15}
                        />
                        <span className="mandatory-label">{t('modal.personalizeOffer.supplements.mandatory')}</span>
                    </div>
                </div>
            ))}
            <div className="button">
                <Button
                    secondary
                    withoutBackground={isBrandAC}
                    fitContentWidth
                    hasEditIcon
                    onClick={() =>
                        setDealerFees([
                            ...dealerFees,
                            { title: '', priceInclTax: null, label: '', source: AGENT_SOURCE_ID, selected: true },
                        ])
                    }
                >
                    <IconTemplate name={Icons.Plus} />
                    <span>{t('modal.personalizeOffer.supplements.button')}</span>
                </Button>
            </div>
        </div>
    );
};
