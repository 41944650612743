import { rem } from 'polished';
import styled from 'styled-components';
import { CheckoutFinanceWidgetStyled } from '../CheckoutFinanceWidgetStyled';

export const CheckoutFinanceWidgetStyledOV = styled(CheckoutFinanceWidgetStyled)`
    color: ${({ theme }) => theme.colors.grey9};

    .finance-summary {
        &--grid {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            background: unset;
        }
        &--col-1 {
            &.SOL {
                line-height: ${({ theme }) => theme.lineHeights.h4};
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
                text-transform: uppercase;
            }
        }
        &--row-2 {
            border: unset;
            background: ${({ theme }) => theme.colors.theWhiteStripes};
            &.bold-style {
                margin-top: ${rem('4px')};
            }
            &.SOL {
                background: ${({ theme }) => theme.colors.theWhiteStripes};
                border: none;
            }
        }

        &--col-2 {
            &.SOL {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-family: ${({ theme }) => theme.fonts.fontSecondary};
                color: ${({ theme }) => theme.colors.black};
            }
        }

        &--col-3 {
            &.SOL {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-family: ${({ theme }) => theme.fonts.fontBase};
                color: ${({ theme }) => theme.colors.grey1};
                &.bold-style {
                    font-family: ${({ theme }) => theme.fonts.fontSecondary};
                }
            }
        }

        &--row-margin {
            margin: ${rem(5)} 0;
        }

        &__legalTextWrapper {
            background: ${({ theme }) => theme.colors.theWhiteStripes};
        }
    }
`;
