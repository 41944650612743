import React, { FC } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslations';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding: 10% 0;

    .modalRow {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .modalLabel {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        a {
            ${({ theme }) => theme.colors.primary};
        }
    }

    .modalTitle {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        text-transform: uppercase;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }
`;

export interface ModalOrderReviewRedirectErrorProps {
    onClose?: () => void;
}

export const ModalOrderReviewRedirectError: FC<ModalOrderReviewRedirectErrorProps> = () => {
    const { t } = useTranslations();
    return (
        <Wrapper>
            <div className="modalRow">
                <p className="modalTitle">{t('checkout.order.redirect.error')}</p>
            </div>
            <div className="modalRow">
                <div
                    className="modalLabel"
                    dangerouslySetInnerHTML={{ __html: t('checkout.order.redirect.error.content') }}
                />
            </div>
        </Wrapper>
    );
};
