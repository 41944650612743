import styled from 'styled-components';
import { ModalDealerSearchStyled } from '../ModalDealerSearchStyled';
import { rem } from 'polished';

export const ModalDealerSearchStyledAP = styled(ModalDealerSearchStyled)`    
    .dealerList {
        .dealerItem {
            
            &__footer {
                button {
                    font-size: ${(props) => props.theme.fontSizes.textTiny};
                    color: ${(props) => props.theme.colors.black};
                    background: ${(props) => props.theme.colors.white};
                    border: 1px solid ${(props) => props.theme.colors.black};
                    
                    &:hover {
                        color: ${(props) => props.theme.colors.white};
                        background: ${(props) => props.theme.colors.black};
                    }
                }
            }
        }
    }
    
    .dealerSearch {
        &__input {
            &::before {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2s3IiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI1IiB5PSI2IiB3aWR0aD0iMzAiIGhlaWdodD0iMjgiPgo8cGF0aCBkPSJNMzQuMzY4IDMwLjYyOEwyNi43MzYgMjQuMTM2QzI5LjgzODQgMTkuMDU2MyAyOC41MTU2IDEyLjQ0NTEgMjMuNjk3NSA4Ljk1MDE0QzE4Ljg3OTQgNS40NTUxOSAxMi4xODQyIDYuMjUwMzQgOC4zMTg3MiAxMC43NzY2QzQuNDUzMjMgMTUuMzAyOSA0LjcxNTczIDIyLjA0IDguOTIxNyAyNi4yNTE3QzEzLjEyNzcgMzAuNDYzNSAxOS44NjQ0IDMwLjczNTMgMjQuMzk2IDI2Ljg3NkwzMi4wMzIgMzMuMzcyTDM0LjM2OCAzMC42MjhaTTEwLjQ4IDI2LjA0NEM2LjY2OTg5IDIyLjg3NyA1LjY4NjYzIDE3LjQzMDEgOC4xNDg5MiAxMy4xMzA4QzEwLjYxMTIgOC44MzE0OCAxNS44MDY5IDYuOTIzMzUgMjAuNDY2NSA4LjYwNzA5QzI1LjEyNjEgMTAuMjkwOCAyNy45MDE5IDE1LjA3OTQgMjcuMDQ3MSAxOS45NTk2QzI2LjE5MjQgMjQuODM5OCAyMS45NTQ0IDI4LjQgMTcgMjguNEMxNC42MTgyIDI4LjQgMTIuMzExNiAyNy41NjY1IDEwLjQ4IDI2LjA0NFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrNykiPgo8cGF0aCBkPSJNMzQuMzY4IDMwLjYyOEwyNi43MzYgMjQuMTM2QzI5LjgzODQgMTkuMDU2MyAyOC41MTU2IDEyLjQ0NTEgMjMuNjk3NSA4Ljk1MDE0QzE4Ljg3OTQgNS40NTUxOSAxMi4xODQyIDYuMjUwMzQgOC4zMTg3MiAxMC43NzY2QzQuNDUzMjMgMTUuMzAyOSA0LjcxNTczIDIyLjA0IDguOTIxNyAyNi4yNTE3QzEzLjEyNzcgMzAuNDYzNSAxOS44NjQ0IDMwLjczNTMgMjQuMzk2IDI2Ljg3NkwzMi4wMzIgMzMuMzcyTDM0LjM2OCAzMC42MjhaTTEwLjQ4IDI2LjA0NEM2LjY2OTg5IDIyLjg3NyA1LjY4NjYzIDE3LjQzMDEgOC4xNDg5MiAxMy4xMzA4QzEwLjYxMTIgOC44MzE0OCAxNS44MDY5IDYuOTIzMzUgMjAuNDY2NSA4LjYwNzA5QzI1LjEyNjEgMTAuMjkwOCAyNy45MDE5IDE1LjA3OTQgMjcuMDQ3MSAxOS45NTk2QzI2LjE5MjQgMjQuODM5OCAyMS45NTQ0IDI4LjQgMTcgMjguNEMxNC42MTgyIDI4LjQgMTIuMzExNiAyNy41NjY1IDEwLjQ4IDI2LjA0NFoiIGZpbGw9IiMwMEEzRTAiLz4KPC9nPgo8L3N2Zz4K')
                no-repeat;
                background-size: ${rem(22)};
        }
        
        button {
            font-size: ${(props) => props.theme.fontSizes.textTiny};
        }
    }
`;
