import styled from 'styled-components';
import CheckoutPersonalDetailsTemplate from './CheckoutPersonalDetailsTemplate';
import { rem } from 'polished';

export const CheckoutPersonalDetailsStyled = styled(CheckoutPersonalDetailsTemplate)`
    .summaryContent {
        position: relative;
        text-align: left;
        padding: 0 ${rem(10)};
    }

    .grid {
        padding: 0;
    }

    .summaryRow {
        margin-bottom: 0.5rem;
        color: black;
        display: flex;
        justify-content: space-between;

        .left,
        .right {
            .summaryRowInfo {
                font-size: ${rem(16)};
                line-height: ${rem(24)};
                margin-bottom: ${rem(15)};
            }

            span {
                display: inline-block;
                width: 100%;
                font-size: ${rem(14)};
                line-height: ${rem(18)};
            }
        }

        .right {
            width: 35%;

            .summaryRowInfo {
                margin-bottom: 0;
            }
        }

        span {
            display: inline-block;
            vertical-align: top;
            font-size: 1.6rem;
            line-height: 2.4rem;

            span {
                width: 100%;
            }
        }
    }

    .editPersonalDetailsButton {
        border-color: gray;
        right: 2rem;
    }

    a.editPersonalDetailsButton {
        text-decoration: none;
        color: gray;
    }

    @media screen and (max-width: 640px) {
        .summaryContent {
            padding: 1.8rem 1.5rem 0.6rem;
        }

        .summaryRow {
            margin-bottom: 1.2rem;
            display: inline-block;

            .left,
            .right {
                width: 100%;
            }

            span {
                width: 100%;
                margin-bottom: 0.3rem;
                font-size: 1.4rem;
                line-height: 2.1rem;

                &:last-child {
                    margin-bottom: 0;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
            }
        }
    }
    .Collapsible {
        background: white;
        display: flex;
        flex-direction: column;
        border: 2px solid #ececee;
        margin-bottom: ${rem(20)};
        &__trigger {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: ${rem(15)} ${rem(60)} ${rem(15)} ${rem(30)};
            text-transform: uppercase;

            &.is-open {
                border-bottom: 2px solid #ececee;
                &:after {
                    transform: rotate(0deg);
                }
            }

            &:after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='14' viewBox='0 0 26 14' fill='none'%3E %3Cpath d='M1 13L13 1L25 13' stroke='%23CDCDCD' stroke-width='2' stroke-linejoin='bevel'/%3E %3C/svg%3E");
                background-repeat: no-repeat;
                position: absolute;
                right: ${rem(30)};
                height: ${rem(14)};
                width: ${rem(26)};
                display: block;
                transform: rotate(180deg);
            }
        }
        &__contentInner {
            padding: ${rem(20)};
        }
    }
`;
