import styled from 'styled-components';
import { CharacteristicsStyled } from '../../CharacteristicsStyled';
import { rem } from 'polished';

export const CharacteristicsStyledOV = styled(CharacteristicsStyled)`
    .title {
        font-size: ${rem(30)};
        padding-bottom: ${rem(15)};
        color: ${({ theme }) => theme.colors.black2};
    }

    .subTitle {
        font-size: ${rem(18)};
    }

    .sectionTitle {
        font-size: ${rem(18)};
        display: block;
        color: ${({ theme }) => theme.colors.black2};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        margin-top: ${rem(40)};
    }

    .sectionSubTitle {
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.black2};
        font-size: ${rem(16)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};

        svg {
            width: ${rem(18)};
            height: ${rem(18)};

            path {
                fill: ${({ theme }) => theme.colors.black2};
            }
        }
    }

    .primaryTechs {
        background: ${({ theme }) => theme.colors.grey7};
        font-size: ${rem(16)};
    }

    .secondaryTechs {
        font-size: ${rem(14)};
    }

    .detailTechs {
        font-size: ${rem(14)};
    }

    .characteristicsTechs {
        font-size: ${rem(14)};
    }

    .legalText {
        margin-top: ${rem(45)};
        font-size: ${rem(12)};
        color: ${({ theme }) => theme.colors.grey2};

        a {
            color: ${({ theme }) => theme.colors.primary};
            padding-left: ${rem(4)};
        }
    }
`;
