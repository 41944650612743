import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { TooltipProps } from '.';
import ReactDOM from 'react-dom';

export const GlobalTooltipTemplate: FC<TooltipProps> = ({ className, ...rest }): JSX.Element => {
    const tooltip = (
        <div className={className}>
            <ReactTooltip className="tooltip-box" {...rest} />
        </div>
    );

    if (typeof window === 'undefined') {
        return null;
    }

    const container = document.getElementById('tooltips-container');

    return container ? ReactDOM.createPortal(tooltip, container) : null;
};
