import React, { memo } from 'react';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV } from '../../constants/main';
import { CheckoutStepWizardAC } from './AC/CheckoutStepWizardStyled.AC';
import { CheckoutStepWizardAP } from './AP/CheckoutStepWizardStyled.AP';
import { CheckoutStepWizardDS } from './DS/CheckoutStepWizardStyled.DS';
import { CheckoutStepWizardOV } from './OV/CheckoutStepWizardStyled.OV';
import { CheckoutStepWizardStyled } from './CheckoutStepWizardStyled';
import { SC } from '../../styles/theme';

interface Props {
    steps?: any[];
    actualState?: string;
    stock?: boolean;
}

export type CheckoutStepWizardProps = SC & Props;

const CheckoutStepWizard = memo((props: CheckoutStepWizardProps) => {
    if (isBrandOV) return <CheckoutStepWizardOV {...props} />;
    if (isBrandAC) return <CheckoutStepWizardAC {...props} />;
    if (isBrandAP) return <CheckoutStepWizardAP {...props} />;
    if (isBrandDS) return <CheckoutStepWizardDS {...props} />;
    return <CheckoutStepWizardStyled {...props} />;
});
CheckoutStepWizard.displayName = 'CheckoutStepWizard';

export default CheckoutStepWizard;
