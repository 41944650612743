import styled from 'styled-components';
import { CheckoutDealerInfoStyled } from '../CheckoutDealerInfoStyled';
import { rem } from 'polished';

export const CheckoutDealerInfoStyledDS = styled(CheckoutDealerInfoStyled)`
    .Collapsible {
        border: 2px solid ${({ theme }) => theme.colors.grey4};
        .Collapsible__trigger {
            background: ${({ theme }) => theme.colors.grey4};

            &:after {
                transform: rotate(0deg);
                background-size: cover;
                height: ${rem(9)};
                width: ${rem(14)};
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.77284 8.47363L0.0756836 1.74975L0.897657 0.924891L6.77318 6.82385L12.6515 0.924891L13.4734 1.74975L6.89766 8.34866L6.77284 8.47363Z' fill='%231A1B1B'/%3E%3C/svg%3E%0A");
            }
            &.is-open {
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.7755 0.923828L13.4727 7.64771L12.6507 8.47257L6.77516 2.57361L0.896869 8.47257L0.0748959 7.64771L6.65068 1.0488L6.7755 0.923828Z' fill='%239D8C83'/%3E%3C/svg%3E ");
                }
            }
        }
    }

    .dealerAddressTitle,
    .addressWrapper,
    .phoneWrapper,
    .mailWrapper {
        font-size: ${({ theme }) => theme.fontSizes.h6};

        p {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }

        a {
            color: ${({ theme }) => theme.colors.black};
        }

        svg {
            width: ${rem(20)};
            height: ${rem(20)};
        }

        .phoneIcon {
            svg {
                width: ${rem(30)};
                height: ${rem(30)};
                margin-top: -${rem(5)};
                margin-left: -${rem(5)};
                path {
                    fill: ${({ theme }) => theme.colors.secondary} !important;
                }
            }
        }

        .mapPointIcon path {
            stroke-width: 0;
            fill: ${({ theme }) => theme.colors.secondary};
        }

        .mailIcon {
            svg {
                width: ${rem(40)};
                height: ${rem(40)};
                margin-top: -${rem(10)};
                margin-left: -${rem(10)};
            }
        }
    }
`;
