import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { FilterButtonStyled } from '../FilterButtonStyled';

export const FilterButtonStyledOV = styled(FilterButtonStyled)`
    border: 1px solid ${({ theme }) => theme.colors.grey21};
    color: ${({ theme }) => theme.colors.grey22};
    position: relative;
    font-family: ${({ theme }) => theme.fonts.fontBase};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.fontWeights.textMedium};

    ${({ active }) =>
        active &&
        css`
            background: ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.pureWhite};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            border-color: ${({ theme }) => theme.colors.primaryDisabled};
            background: ${({ theme }) => theme.colors.primaryDisabled};
            color: ${({ theme }) => theme.colors.grey2};
        `};

    :hover,
    :focus-visible {
        :not(:active),
        :not(:disabled) {
            color: ${({ theme }) => theme.colors.black};
            border-color: ${({ theme }) => theme.colors.primary};
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
