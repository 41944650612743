import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalShareConfigurationDealerCodeTemplate from './ModalShareConfigurationDealerCodeTemplate';

export const ModalShareConfigurationDealerCodeStyled = styled(ModalShareConfigurationDealerCodeTemplate)`
    width: 100%;

    .sent-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: auto;
        padding-bottom: ${rem('34px')};

        .text {
            padding: ${rem('34px')};
        }
    }
    .copy-link-text {
        position: absolute;
        bottom: ${rem(20)};
        width: 40%;
        text-align: center;
        font-size: ${(props) => props.theme.fontSizes.textTiny};
        left: 30%;
        background: rgba(0, 0, 0, 0.8);
        padding: ${rem(5)} ${rem(10)};
        border-radius: ${rem(20)};
        color: ${(props) => props.theme.colors.white};

        svg {
            g {
                fill: ${(props) => props.theme.colors.white};
            }
        }
    }
    .share-wrapper {
        max-width: 96%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: auto;
        position: relative;
        padding-bottom: ${rem(90)};

        .title {
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.black1};
            font-size: ${rem('22px')};
            width: 80%;
            line-height: 1.5;
        }

        .description {
            padding: 0 0 ${rem(15)} 0;
            color: ${(props) => props.theme.colors.black};
        }

        .input-wrapper {
            padding: 0 0 ${rem(10)} 0;
            width: 100%;

            .error {
                border-color: ${(props) => props.theme.colors.danger};
                margin-bottom: ${rem(14)};
            }

            .separator {
                height: ${rem('50px')};
                width: 100%;
            }

            .dropdown,
            input {
                width: 100%;
                border-radius: unset;
                border: 1px solid ${({ theme }) => theme.colors.primaryDisabled};
                margin: ${rem(5)} 0;
                font-size: ${(props) => props.theme.fontSizes.textBase};
                flex: 2;
                max-height: 55px;

                &:disabled {
                    background: ${({ theme }) => theme.colors.primaryDisabled};
                }

                .Select__menu {
                    width: calc(100% + ${rem(2)});
                    left: ${rem(-1)};
                }
            }

            input {
                padding: ${rem(20)};
            }

            .Select__control {
                padding: 0 ${rem(20)} 0 ${rem(20)};

                input {
                    padding: unset;
                }
                .Select__indicators {
                    transform: rotate(180deg);
                    svg {
                        display: block;

                        path {
                            fill: ${({ theme }) => theme.colors.primary};
                        }
                    }
                }
            }

            .dropdownHasError {
                border-color: ${({ theme }) => theme.colors.danger} !important;
            }

            .companyNameError,
            .salesManTypeErrorMsg,
            .dealerCodeError,
            .emailError {
                color: ${(props) => props.theme.colors.danger};
                position: absolute;
                font-size: ${(props) => props.theme.fontSizes.textTiny};
                bottom: 0;
                left: 0;
            }

            .salesManTypeErrorMsg {
                position: relative;
            }

            .input-section {
                position: relative;

                .section-header {
                    font-size: ${(props) => props.theme.fontSizes.textBase};
                    font-weight: bold;
                    color: ${(props) => props.theme.colors.black};
                    margin-bottom: ${rem('10px')};
                }

                .section-link {
                    font-size: ${(props) => props.theme.fontSizes.textBase};
                    color: ${(props) => props.theme.colors.black};
                    text-decoration: underline;
                    margin-bottom: ${rem('10px')};
                    cursor: pointer;
                }
            }
        }

        .mandatory-description {
            font-size: ${(props) => props.theme.fontSizes.textTiny};
            margin: ${rem(4)} 0;
        }

        .footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            height: ${rem(80)};
            padding: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            font-size: ${(props) => props.theme.fontSizes.textBase};

            .footer-wrapper {
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                .footer-description {
                    color: ${(props) => props.theme.colors.black};
                    padding: ${rem(4)} 0;
                    margin-right: ${rem('10px')};
                }
            }
        }

        .callToAction {
            margin: 20px auto 0 auto;
        }
    }

    .searchSection {
        width: ${rem(50)};
        height: ${rem(55)};
        right: 0;
        margin: ${rem(5)} 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.black};
        cursor: pointer;

        &:hover {
            background: ${(props) => props.theme.colors.black};

            svg {
                path {
                    fill: ${(props) => props.theme.colors.white};
                }
            }
        }
    }

    .checkboxSection {
        display: flex;

        &-title {
            p {
                color: ${(props) => props.theme.colors.black};
            }
        }

        .agreement {
            margin-top: ${rem(10)};
        }

        .container label {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${(props) => props.theme.colors.black};
            margin-right: ${rem('12px')};
        }

        button {
            width: 100%;
            display: flex;
            align-items: center;
            margin-right: ${rem(20)};
        }

        .checkboxWrapper input:checked + label::after {
            left: ${rem(8)} !important;
        }
    }

    .civilitySectionError {
        color: ${(props) => props.theme.colors.danger};
        font-size: ${(props) => props.theme.fontSizes.textTiny};
        margin-bottom: ${rem(10)};
    }

    .civilitySection {
        display: flex;
        padding: ${rem(10)} 0;

        .civility {
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.colors.black};
            margin-right: ${rem(10)};
            font-size: ${(props) => props.theme.fontSizes.textBase};
        }

        .check {
            cursor: pointer;
            width: ${rem(20)};
            height: ${rem(20)};
            border: 1px solid ${(props) => props.theme.colors.secondaryDisabled};
            display: inline-block;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: ${rem(10)};

            &.checkError {
                border-color: ${(props) => props.theme.colors.danger};
            }

            .selected {
                width: ${rem(10)};
                height: ${rem(10)};
                border-radius: 50%;
                background: ${(props) => props.theme.colors.primary};
            }
        }
    }
`;
