import { useCallback } from 'react';
import { CONSUMPTION, ENGINE } from '../constants/characteristics';
import { getEnvironmentalAndTechData } from '@utils/Characteristics.utils';
import { useTranslations } from './useTranslations';
import { IConfigurableCar } from '../interfaces/Car';
import { useMoneyFormatter } from './useMoneyFormatter';

export const useTechSpec = (carDetail: IConfigurableCar) => {
    const { t } = useTranslations();
    const { formatAmount } = useMoneyFormatter();

    const { engine } = carDetail;

    const { environmentalData = null } = engine?.specs ? getEnvironmentalAndTechData(engine.specs) : {};

    // APP-24364
    // '01': essence, petrol,
    // '02': mild-hybrid, mhev,
    // '03': diesel,
    // '04': turbo-diesel,
    // '05': electric,
    // '10': hybrid,
    const legalTextUrl = t(`basket.${carDetail.fuelCode}.legalTextUrl`);
    const fuelLegalText = t(`technicalFeatures.environmentalDetails.text.${carDetail.fuelCode}`);

    const getConsumptionText = useCallback(
        (consumptionDataKey: string) => {
            switch (consumptionDataKey as CONSUMPTION | ENGINE) {
                case ENGINE.FUEL:
                    return t('technicalFeatures.environmentalDetails.engine.fuel');
                case ENGINE.POWER:
                    return t('technicalFeatures.environmentalDetails.engine.power');
                case CONSUMPTION.CO2:
                    return t('technicalFeatures.environmentalDetails.consumption.co2');
                case CONSUMPTION.CO2_COMBINED:
                    return t('technicalFeatures.environmentalDetails.consumption.co2Combined');
                case CONSUMPTION.CONSUMPTION_COMBINED:
                    return t('technicalFeatures.environmentalDetails.consumption.consumptionCombined');
                case CONSUMPTION.CYCLE_E_HIGH:
                    return t('technicalFeatures.environmentalDetails.consumption.cycleEhigh');
                case CONSUMPTION.CYCLE_HIGH:
                    return t('technicalFeatures.environmentalDetails.consumption.cycleHigh');
                case CONSUMPTION.CYCLE_LOW:
                    return t('technicalFeatures.environmentalDetails.consumption.cycleLow');
                case CONSUMPTION.CYCLE_MID:
                    return t('technicalFeatures.environmentalDetails.consumption.cycleMid');
                case CONSUMPTION.ELECTRIC_CONSUMPTION:
                    return t('technicalFeatures.environmentalDetails.consumption.electricConsumption');
                case CONSUMPTION.ELECTRIC_RANGE:
                    return t('technicalFeatures.environmentalDetails.consumption.electricRange');
                case CONSUMPTION.FUEL:
                    return t('technicalFeatures.environmentalDetails.consumption.fuel');
                case CONSUMPTION.MIX:
                    return t('technicalFeatures.environmentalDetails.consumption.mix');
            }

            const consumption = environmentalData[consumptionDataKey];

            return t('technicalFeatures.environmentalDetails.consumption.literPerKM', {
                value: formatAmount(consumption, true),
            });
        },
        [environmentalData]
    );

    const getConsumptionLabel = useCallback((consumptionKey: string): string => {
        switch (consumptionKey) {
            case CONSUMPTION.ELECTRIC_RANGE:
                return t('technicalFeatures.environmentalDetails.consumption.electricRange.label');
            case CONSUMPTION.ELECTRIC_CONSUMPTION:
                return t('technicalFeatures.environmentalDetails.consumption.electricConsumption.label');
            case ENGINE.POWER:
                return t('technicalFeatures.environmentalDetails.engine.power.label');
            case CONSUMPTION.MIX:
                return t('technicalFeatures.environmentalDetails.consumption.mix.label');
            case CONSUMPTION.CO2:
                return t('technicalFeatures.environmentalDetails.consumption.co2.label');
            case CONSUMPTION.CO2_COMBINED:
                return t('technicalFeatures.environmentalDetails.consumption.co2Combined.label');

            default:
                return consumptionKey;
        }
    }, []);

    return { getConsumptionLabel, getConsumptionText, legalTextUrl, fuelLegalText };
};
