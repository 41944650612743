import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalShareConfigurationDealerCodeStyled } from '../ModalShareConfigurationDealerCodeStyled';
import { rem } from 'polished';

export const ModalShareConfigurationDealerCodeStyledOV = styled(ModalShareConfigurationDealerCodeStyled)`
    .share-wrapper {
        .callToAction {
            @media (min-width: ${breakpoints.md}px) {
                width: 70%;
            }
        }
        .input-wrapper .dropdown .Select__indicators div {
            background: none;

            svg path {
                fill: ${({ theme }) => theme.colors.black};
            }
        }
    }
    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.black2};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
                background: ${({ theme }) => theme.colors.black2};
            }
        }
    }
`;
