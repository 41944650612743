import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { ReservationFeeStyledAC } from './AC/ReservationFeeStyled.AC';
import { ReservationFeeStyledAP } from './AP/ReservationFeeStyled.AP';
import { ReservationFeeStyledDS } from './DS/ReservationFeeStyled.DS';
import { ReservationFeeStyledOV } from './OV/ReservationFeeStyled.OV';
import { ReservationFeeStyledOVGB } from './OV/GB/ReservationFeeStyled.OV';
import { ReservationFeeStyled } from './ReservationFeeStyled';
import { SC } from '../../styles/theme';

export interface ReservationFeeProps extends SC {
    fee: number;
}

const ReservationFee = (props: ReservationFeeProps) => {
    if (BRAND === 'AC') return <ReservationFeeStyledAC {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ReservationFeeStyledOVGB {...props} /> : <ReservationFeeStyledOV {...props} />;
    if (BRAND === 'AP') return <ReservationFeeStyledAP {...props} />;
    if (BRAND === 'DS') return <ReservationFeeStyledDS {...props} />;
    return <ReservationFeeStyled {...props} />;
};

export default ReservationFee;
