import React from 'react';
import { BRAND } from '../../constants/main';
import { AgentToolBarStyledAC } from './AC/AgentToolBarStyled.AC';
import { AgentToolBarStyledOV } from './OV/AgentToolBarStyled.OV';
import { AgentToolBarStyledAP } from './AP/AgentToolBarStyled.AP';
import { AgentToolBarStyledDS } from './DS/AgentToolBarStyled.DS';
import { AgentToolBarStyled } from './AgentToolBarStyled';

const AgentToolBar = () => {
    if (BRAND === 'AC') return <AgentToolBarStyledAC />;
    if (BRAND === 'OV') return <AgentToolBarStyledOV />;
    if (BRAND === 'AP') return <AgentToolBarStyledAP />;
    if (BRAND === 'DS') return <AgentToolBarStyledDS />;
    return <AgentToolBarStyled />;
};

export default AgentToolBar;
