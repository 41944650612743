import React from 'react';
import styled from 'styled-components';
import { NeedHelpSectionTemplate } from './NeedHelpSectionTemplate';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV } from '../../constants/main';
import { ACStyles } from './AC.styles';
import { APStyles } from './AP.styles';
import { OVStyles } from './OV.styles';
import { DSStyles } from './DS.styles';
import { rem } from 'polished';

export const NeedHelpSection = styled(NeedHelpSectionTemplate)`
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${rem('200px')};
    
    > h2 {
        fon-size: ${rem('16px')};
        text-transform: uppercase;
        margin-bottom: ${rem('16px')};
    }
    
    > div {
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        line-height: 140%;
        font-size: ${rem('13px')};
        
        &.needHelpPhone {
            font-size: ${rem('18px')};
        }
    }
   
    
    ${isBrandAC && ACStyles}
    ${isBrandAP && APStyles}
    ${isBrandDS && DSStyles}
    ${isBrandOV && OVStyles}
`;
