import styled from 'styled-components';
import { HandledComponentErrorTemplate } from './HandledComponentErrorTemplate';

export const HandledComponentError = styled(HandledComponentErrorTemplate)`
    padding: 1rem 1.6rem;
    margin: 0.5rem;
    color: red;
    font-weight: 400;
    background-color: white;
    border: 1px dotted red;

    button {
        color: inherit;
        font-weight: 600;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;
