import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { MarketingStickerStyled } from './MarketingStickerStyled';
import { MarketingStickerStyledAC } from './AC/MarketingStickerStyled.AC';
import { MarketingStickerStyledOV } from './OV/MarketingStickerStyled.OV';
import { MarketingStickerStyledOVGB } from './OV/GB/MarketingStickerStyled.OV';
import { MarketingStickerStyledAP } from './AP/MarketingStickerStyled.AP';
import { MarketingStickerStyledDS } from './DS/MarketingStickerStyled.DS';
import { SC } from '../../styles/theme';
import { IPromo } from '../../interfaces/IOfferItem';

export interface MarketingStickerProps extends SC {
    page: string;
    data: Array<IPromo>;
    fullWidth?: boolean;
}

const MarketingSticker = (props: MarketingStickerProps) => {
    if (BRAND === 'AC') return <MarketingStickerStyledAC {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <MarketingStickerStyledOVGB {...props} /> : <MarketingStickerStyledOV {...props} />;
    if (BRAND === 'AP') return <MarketingStickerStyledAP {...props} />;
    if (BRAND === 'DS') return <MarketingStickerStyledDS {...props} />;
    return <MarketingStickerStyled {...props} />;
};

export default MarketingSticker;
