import { css } from 'src/styles/theme';
import { rem } from 'polished';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from 'src/constants/main';
import { StyledAC } from './AC/Styled.AC';
import { StyledAP } from './AP/Styled.AP';
import { StyledDS } from './DS/Styled.DS';
import { StyledOV } from './OV/Styled.OV';
import { StyledOVGB } from './OV/Styled.OVGB';

export const GlobalStyled = css`
    .description {
        margin: 0 0 ${rem(32)} 0;
        line-height: ${rem(18)};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.black2};
    }

    .save,
    .share {
        position: relative;
        padding-left: ${rem(60)};
        margin: ${rem(10)} 0 ${rem(28)} 0;

        .icon {
            position: absolute;
            left: 0;
            top: -${rem(10)};

            svg {
                width: ${rem(48)};
            }
        }

        .title {
            font-size: ${rem(20)};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .label {
            padding-top: ${rem(12)};
        }

        .saved {
            padding-top: ${rem(12)};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .form {
            .emailInputComponent {
                background: ${({ theme }) => theme.colors.white};
            }

            input {
                display: block;
                margin: ${rem(16)} 0;
                border-radius: ${rem(12)};
                border: 1px solid ${({ theme }) => theme.colors.grey3};
                height: ${rem(48)};
                padding: ${rem(15)};
                width: 100%;
            }

            .error {
                font-size: ${rem(12)};
                margin-top: -${rem(10)};
                margin-bottom: ${rem(10)};
                display: block;
                color: ${({ theme }) => theme.colors.danger};
            }
        }
    }

    ${isBrandAC && StyledAC}
    ${isBrandAP && StyledAP}
    ${isBrandDS && StyledDS}
    ${isBrandOV && (isMarketGB ? StyledOVGB : StyledOV)};
`;
