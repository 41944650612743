import styled from 'styled-components';
import { rem } from 'polished';
import { CheckoutDealerInfoStyled } from '../../CheckoutDealerInfoStyled';

export const CheckoutDealerInfoStyledOVGB = styled(CheckoutDealerInfoStyled)`
    .mapPointIcon {
        svg {
            height: ${rem(25)};
            width: ${rem(25)};
            position: relative;
            bottom: ${rem(3)};
        }

        path[fill] {
            fill: ${({ theme }) => theme.colors.primary};
        }

        g {
            display: none;
        }
    }

    .phoneTitle {
        padding-right: ${rem(5)};
    }

    .phoneIcon {
        align-items: center;

        svg {
            height: ${rem(16)};
            width: ${rem(16)};

            & > * {
                stroke-width: ${rem(1)};
            }

            path {
                stroke: ${({ theme }) => theme.colors.primary} !important;
                fill: none !important;
            }
        }
    }

    .infoIcon {
        svg {
            height: ${rem(18)};
            width: ${rem(18)};
        }

        g > * {
            stroke: ${({ theme }) => theme.colors.primary};
            stroke-width: ${rem(1)};
        }

        g > line {
            stroke-width: ${rem(2)};
        }
    }
`;
