import styled from 'styled-components';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV } from 'src/constants/main';
import { StylesAC } from './Styles.AC';
import { StylesAP } from './Styles.AP';
import { StylesDS } from './Styles.DS';
import { StylesOV } from './Styled.OV';
import { IInputProps } from './Input';

export const Input = styled.input<IInputProps>`
    min-width: 50px;
    display: flex;
    flex: auto;
    color: ${({ theme }) => theme.colors.black};

    border-width: 1px;
    border-style: solid;
    border-color: ${({ isValid, isInvalid, theme }) =>
        isInvalid === true ? theme.colors.danger : isValid === true ? theme.colors.success : theme.colors.grey11};
    outline: none;

    ::placeholder {
        color: ${({ theme }) => theme.colors.grey11};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    ${isBrandAC && StylesAC};
    ${isBrandAP && StylesAP};
    ${isBrandDS && StylesDS};
    ${isBrandOV && StylesOV};
`;
