// As a result of APP-13253
// which was caused by incompatibility between external widgets/their dependencies
export const useWidgetCompatibility = () => {
    const saveWindowProperty = (property: string) => {
        if (window === undefined) return;

        const savedProperty = `save_${property}`;
        const w = window as any;
        if (!w[savedProperty]) w[savedProperty] = w[property];
    };

    const restoreWindowProperty = (property: string) => {
        if (window === undefined) return;

        const savedProperty = `save_${property}`;
        const w = window as any;
        w[property] = w?.[savedProperty] || w[property];
    };

    return { saveWindowProperty, restoreWindowProperty };
};
