import React from 'react';
import { TooltipProps as ReactTooltipProps } from 'react-tooltip';
import { BRAND } from '../../constants/main';
import { GlobalTooltipStyledAC } from './AC/GlobalTooltipStyled.AC';
import { GlobalTooltipStyledOV } from './OV/GlobalTooltipStyled.OV';
import { GlobalTooltipStyledAP } from './AP/GlobalTooltipStyled.AP';
import { GlobalTooltipStyledDS } from './DS/GlobalTooltipStyled.DS';
import { GlobalTooltipStyled } from './GlobalTooltipStyled';

export enum Place {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left',
}

export enum Effect {
    float = 'float',
    solid = 'solid',
}

export enum Event {
    mouseenter = 'mouseenter',
    click = 'click',
}

export type TooltipProps = ReactTooltipProps;

const GlobalTooltip = (props: TooltipProps) => {
    if (BRAND === 'AC') return <GlobalTooltipStyledAC {...props} />;
    if (BRAND === 'OV') return <GlobalTooltipStyledOV {...props} />;
    if (BRAND === 'AP') return <GlobalTooltipStyledAP {...props} />;
    if (BRAND === 'DS') return <GlobalTooltipStyledDS {...props} />;
    return <GlobalTooltipStyled {...props} />;
};

export default GlobalTooltip;
