import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalShareOfferSummaryTemplate from './ModalShareOfferSummaryTemplate';
import { isBrandOV } from '../../../constants/main';

export const ModalShareOfferSummaryStyled = styled(ModalShareOfferSummaryTemplate)`
    width: 100%;
    .title {
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.black1};
        font-size: ${rem('22px')};
        width: 80%;
        line-height: 1.5;
        margin-top: -${rem(30)};
        max-width: 96%;
    }

    .description {
        padding: 0 0 ${rem(15)} 0;
        color: ${(props) => props.theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: auto;
        position: relative;
        padding-bottom: ${rem(45)};
        & .dealerShareFeature {
            color: ${({ theme }) => theme.colors.black1};
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            cursor: pointer;
            text-decoration: underline;
            background: none;
            border: none;
            text-align: left;
            padding: 0;
        }

        & .form {
            display: flex;
            flex-direction: column;
            margin-bottom: ${rem('10px')};

            & .formRow {
                margin-top: ${rem('15px')};
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .error {
                    width: 100%;
                    text-align: left;
                    color: ${({ theme }) => theme.colors.red};
                    font-size: ${({ theme }) => theme.fontSizes.textSmall};
                    position: absolute;
                }

                .emailInputComponent {
                    width: 100%;
                }

                input {
                    font-size: ${rem('12px')};
                    height: ${rem(45)};
                    margin-bottom: ${rem(20)};
                    padding: ${rem(10)};
                }

                .fetching {
                    background: unset !important;
                }
            }
        }
    }
`;
