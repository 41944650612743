import React from 'react';
import { isMobile } from 'is-mobile';
import Button from '../../Button';
import { formatDateByLang, formatLeadTime } from '@utils/Date.utils';
import { BRAND, isBrandDS, isBrandOV } from '../../../constants/main';
import { createImageAltResolver, extractViewFromImageUrl } from '@utils/Image.utils';
import Icon, { Icons } from '../../Icon';
import useTranslations from '@hooks/useTranslations';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';
import { ImageViewType } from '../../../constants/enums';
import { Trans } from '../../../../i18n';
import { IConfigurableCar } from '../../../interfaces/Car';
import { Pages } from '../../../types/pages';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';

interface CarInfoHeaderProps {
    isCheckout: boolean;
    isParent: boolean;
    isMyOrderPage: boolean;
    isExpired: boolean;

    handleActivateDeal(): void;

    carConfigData: IConfigurableCar;
    offerEnd?: string;
}

const CarInfoHeader = React.memo(
    ({
        isCheckout,
        isMyOrderPage,
        isParent,
        isExpired,
        handleActivateDeal,
        carConfigData,
        offerEnd,
    }: CarInfoHeaderProps) => {
        const { t }: any = useTranslations();
        const isMobileDevice = isMobile();

        const { isStockJourney } = useCarStockJourneyCheck();

        const isDealerLayerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED);
        const resolveImageAlt = createImageAltResolver(BRAND, t);

        const {
            engine,
            gearbox,
            fuel,
            leadTimeDate,
            images,
            model,
            specPackSlug,
            displayGrBodyStyleLabel,
            specPack,
            bodyStyle,
            maxLeadTimeDate,
        } = carConfigData;

        const image =
            images.find((image) => image.type === ImageViewType.EXTERIOR && image.default.includes('list')) ??
            images[0];

        if (!isParent && !isCheckout && !isMyOrderPage) {
            return (
                <div className={`offerHeader`}>
                    {!isExpired && (
                        <Button primary onClick={handleActivateDeal} data-testid={`TESTING_CONTINUE_WITH_OFFER`}>
                            {t('offerDetail.continueButton')}
                        </Button>
                    )}
                    {leadTimeDate && !isExpired && (
                        <div className="offerHeader__leadTime">
                            <span className="availableInLabel">{t('offerDetail.leadTime')}</span>{' '}
                            <span className="availableInDate" data-testid={`TESTING_LEAD_TIME`}>
                                {formatDateByLang(leadTimeDate)}
                            </span>
                        </div>
                    )}
                    {isExpired && (
                        <div className="offerHeader__leadTime">
                            <span className="availableInLabel">{t('offerDetail.expired')}</span>
                            <span className="availableInDate">{formatDateByLang(offerEnd)}</span>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <>
                <div className="carInfoHeader">
                    {isBrandDS ? (
                        <div className="carInfoHeaderHolder">
                            <h2 className="car-model">
                                <span>
                                    {model.title} {fuel}
                                </span>
                                <span>{specPackSlug}</span>
                                <span className="title-engineInfo">
                                    {engine.title} {gearbox.title}{' '}
                                </span>
                            </h2>
                        </div>
                    ) : (
                        <div className="carInfoHeaderHolder">
                            <h2 className="title">
                                <span>
                                    {model.title} {displayGrBodyStyleLabel && bodyStyle.title}{' '}
                                </span>{' '}
                                <span className="title-specPack">{specPack.title}</span>
                            </h2>
                            {!isBrandOV && (
                                <span className="title-engineInfo">
                                    {engine.title} {gearbox.title}{' '}
                                </span>
                            )}
                        </div>
                    )}
                    {!isMobileDevice && (
                        <div className="desktop">
                            {carConfigData.stock ? (
                                <div className="availableInWrapper stock">
                                    <div className="availabilityStock">
                                        <span className="clockIconStock">
                                            {/* this condition is unfortunately needed, bcs DS clock svg provided by PSA is just a full circle, as you can see on homepage */}
                                            <Icon
                                                className="clockIconStockSvg"
                                                name={Icons.Clock}
                                                height={16}
                                                width={16}
                                            />
                                            <span className="availableToTitle">
                                                {formatLeadTime(leadTimeDate)}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                leadTimeDate && (
                                    <>
                                        {isDealerLayerEnabled && (
                                            <div className="availableInWrapper">
                                                <span className="clock" />
                                                <span className="availableIn">
                                                    {formatLeadTime(leadTimeDate, maxLeadTimeDate)}
                                                </span>
                                            </div>
                                        )}
                                        {!isDealerLayerEnabled && (
                                            <div className="mobileCarDelivery">
                                                <div className="availableInWrapperMobile">
                                                    <span className="clock" />
                                                    <span className="availableIn">
                                                        {formatLeadTime(leadTimeDate, maxLeadTimeDate)}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    )}
                </div>
                <div className="carImageWrapper">
                    <img
                        className="carImage"
                        src={image.url}
                        alt={resolveImageAlt(extractViewFromImageUrl(image.url), carConfigData)}
                    />
                </div>
                <div className="mobile">
                    {carConfigData.stock ? (
                        <div className="availableInWrapper stock">
                            <div className="availabilityStock">
                                    <span className="clockIconStock">
                                        {/* this condition is unfortunately needed, bcs DS clock svg provided by PSA is just a full circle, as you can see on homepage */}
                                        <Icon className="clockIconStockSvg" name={Icons.Clock} height={16} width={16} />
                                        <span className="availableToTitle">
                                            {formatLeadTime(leadTimeDate)}
                                        </span>
                                    </span>
                            </div>
                        </div>
                    ) : (
                        leadTimeDate && (
                            <>
                                {isDealerLayerEnabled && (
                                    <div className="availableInWrapper">
                                        <span className="clock" />
                                        <span className="availableIn">
                                            {formatLeadTime(leadTimeDate, maxLeadTimeDate)}
                                        </span>
                                    </div>
                                )}
                                {!isDealerLayerEnabled && (
                                    <div className="mobileCarDelivery">
                                        <div className="availableInWrapperMobile">
                                            <span className="clock" />
                                            <span className="availableIn">
                                                {formatLeadTime(leadTimeDate, maxLeadTimeDate)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    )}
                </div>
            </>
        );
    }
);

CarInfoHeader.displayName = 'CarInfoHeader';

export default CarInfoHeader;
