import styled from 'styled-components';
import React from 'react';
import { FC, memo } from 'react';
import { IButton } from '@components/Button/index';

export const AccessibleButtonStyled = styled.button<IAccessibleButton>`
    background: none;
    border: none;
    height: auto;
    padding: 0;
    margin: 0;
    width: auto;
    display: inline-flex;
    cursor: pointer;
    &.rounded {
        border-radius: 50%;
    }
`;

export interface IAccessibleButton extends IButton {
    onClick?: any;
    tabIndex?: number;
    'data-testid'?: string;
    ref?: any;
}

export const AccessibleButton: FC<IAccessibleButton> = (props) => {
    return <AccessibleButtonStyled {...props} />;
};

export default memo(AccessibleButton);
