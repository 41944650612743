import styled from 'styled-components';
import { css } from '../../styles/theme';
import { IconButtonTemplate } from './IconButtonTemplate';
import media from '../../styles/media';
import { rem } from 'polished';

export const IconButtonStyled = styled(IconButtonTemplate)`
    & > .infoIcon {
        display: flex;
        width: auto;
        cursor: pointer;

        h2 {
            text-transform: uppercase;
            font-style: normal;
            font-family: ${({ theme }) => theme.fonts.fontBase};
        }
    }

    .vertical {
        flex-direction: column;
        text-align: center;
        transition: 0.1s ease;

        h2 {
            margin-top: ${rem('5px')};
            color: ${({ theme }) => theme.colors.secondary};
            font-size: ${({ theme }) => theme.fontSizes.textSmall};
            line-height: ${({ theme }) => theme.lineHeights.textSmall};
        }
    }

    .horizontal {
        padding: ${rem('17px')} ${rem('16px')};
        height: ${rem('48px')};
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: ${rem('1px')} solid ${({ theme }) => theme.colors.grey3};
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.colors.white};

        h2 {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.grey1};
            line-height: ${({ theme }) => theme.lineHeights.textBase};
        }

        ${media.sm`
            display: none;
        `}

        .icon-wrapper {
            path {
                stroke: ${({ theme }) => theme.colors.primary};
            }
        }

        &:hover {
            h2 {
                color: ${({ theme }) => theme.colors.grey1};
            }

            .icon-wrapper {
                path {
                    stroke: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }
`;
