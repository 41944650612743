import styled from 'styled-components';
import { GlobalTooltipStyled } from '../GlobalTooltipStyled';
import { rem } from 'polished';

export const GlobalTooltipStyledAP = styled(GlobalTooltipStyled)`
    .tooltip-box {
        width: fit-content !important;
        padding: ${rem(16)} !important;
        margin-top: ${rem(8)} !important;
        background: ${({ theme }) => theme.colors.black};
        box-shadow: 0 3px 8px ${({ theme }) => theme.colors.grey1};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${rem(12)};

        &-basket {
            font-size: ${rem(10)} !important;
            margin-top: 0 !important;
            padding: ${rem(5)} ${rem(14)} !important;
            max-width: ${rem(160)} !important;
        }

        &::after {
            border-top: 1px solid ${({ theme }) => theme.colors.blue1} !important;
            border-right: 1px solid ${({ theme }) => theme.colors.blue1} !important;
            background-color: ${({ theme }) => theme.colors.black};
        }
    }

    .place-left {
        &::after {
            border-left-color: ${({ theme }) => theme.colors.blue1} !important;
        }
    }
`;
