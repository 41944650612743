import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CheckoutFinanceWidgetStyled } from '../CheckoutFinanceWidgetStyled';

export const CheckoutFinanceWidgetStyledDS = styled(CheckoutFinanceWidgetStyled)`
    color: ${({ theme }) => theme.colors.grey6};

    .finance-summary {
        &--row-2 {
            border: 2px solid ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.cream};
        }
        &--col-1 {
            padding-left: 0;
            text-transform: uppercase;
        }
        &__legalTitle,
        &__legalText {
            color: ${({ theme }) => theme.colors.grey1};
        }
        &__legalTitle,
        &__legalText {
            color: ${({ theme }) => theme.colors.grey1};
        }
        &__legalTextWrapper {
            background: #f1f3f2;
            b {
                font-weight: normal;
                text-transform: none;
            }
        }
    }
`;
