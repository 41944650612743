import React, { FC, memo } from 'react';
import useTranslations from '@hooks/useTranslations';
import LoadingButton from '../../LoadingButton';

interface CheckoutSelectedDealerProps {
    name: string;
    streetName: string;
    city: string;
    postCode: string;
    handleDealer: () => void;
}

const CheckoutSelectedDealer: FC<CheckoutSelectedDealerProps> = memo(function SelectedDealer({
    name,
    streetName,
    city,
    postCode,
    handleDealer,
}) {
    const { t } = useTranslations();

    return (
        <div className="selectedDealer" data-testid="TESTING_SELECTED_DEALER">
            <div className="selectedDealer__header" data-testid="TESTING_SELECTED_DEALER_HEADER">
                <span />
                <span>{t('checkout.delivery.dealer.selected.text')}</span>
            </div>
            <div className="selectedDealer__info" data-testid="TESTING_SELECTED_DEALER_INFO">
                <p>{name}</p>
                <p>{streetName}</p>
                <p>{city}</p>
                <p>{postCode}</p>
            </div>
            <LoadingButton
                className="selectedDealer__loadingButton"
                onClick={handleDealer}
                data-testid="TESTING_HANDLE_DEALER"
                primary
            >
                {t('checkout.delivery.nextButton')}
            </LoadingButton>
        </div>
    );
});
export default CheckoutSelectedDealer;
