import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { FaqItemTemplate } from './FaqItemTemplate';
import { rem } from 'polished';

const maxWidth: string = '900px';
const maxWidthInner: string = '870px';

export const FaqItemStyled = styled(FaqItemTemplate)`
    &.faqCategory,
    &.faqGroup {
        > .Collapsible {
            > .Collapsible__trigger {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                &:focus-visible {
                    > .faqHeader {
                        &:after {
                            outline: 1px dotted ${({ theme }) => theme.colors.accessibilityOutlinecolor};
                            outline-offset: 3px;
                        }
                    }
                }

                > .faqHeader {
                    padding-right: ${rem(32)};

                    &:after {
                        content: '';
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 50%;
                        display: block;
                    }
                }
            }

            > .Collapsible__contentOuter {
                > .Collapsible__contentInner {
                    div,
                    p {
                        line-height: ${rem(27)};
                    }
                }
            }
        }
    }
    &.faqCategory {
        > .Collapsible {
            > .Collapsible__trigger {
                max-width: ${rem(maxWidth)};
                margin: 0 auto;
                height: ${rem(60)};
                padding: 0 ${rem(16)};

                > .faqHeader {
                    &:after {
                        right: ${rem(28)};
                        height: ${rem(15)};
                        width: ${rem(15)};
                        margin-top: ${rem(-7)};

                        @media (min-width: ${breakpoints.sm}px) {
                            right: ${rem(16)};
                        }
                    }
                }
            }
            > .Collapsible__contentOuter {
                > .Collapsible__contentInner {
                    max-width: ${rem(maxWidthInner)};
                    margin: 0 auto;
                }
            }
        }
    }

    &.faqGroup {
        > .Collapsible {
            max-width: ${rem(maxWidth)};
            margin: 0 auto;
            padding-bottom: ${rem(32)};
        }
    }
`;
