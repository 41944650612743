import styled from 'styled-components';
import ReservationFeeTemplate from './ReservationFeeTemplate';
import { rem } from 'polished';

export const ReservationFeeStyled = styled(ReservationFeeTemplate)`
    .label {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    .paymentMethods {
        background: ${({ theme }) => theme.colors.grey7};
        padding: ${rem(20)};
        margin: ${rem(10)} 0;

        &_title {
            display: flex;
            align-items: center;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            text-transform: uppercase;

            img {
                padding: 0 ${rem(5)};
            }
        }

        &_description {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        color: ${({ theme }) => theme.colors.heavyMetal};
    }
`;
