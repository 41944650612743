import { useSelector } from 'react-redux';
import FilterDuck from 'src/redux/filters/filter.duck';
import GlobalDuck from 'src/redux/global/global.duck';
import { Redux } from 'src/redux/redux.interface';
import { CHECKOUT_CASH_URL, CHECKOUT_FINANCE_URL } from '../constants/main';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import { CarJourneyType } from '../services';
import { FINANCE } from '../services/filters/filters';
import { useFeatureSwitchEnabled } from './useFeatureSwitchEnabled';

export const useExternalCheckout = () => {
    const isStock = useSelector((state: Redux) => GlobalDuck.getOwnState(state).carJourney === CarJourneyType.STOCK);
    const isFinance = useSelector((state) => FilterDuck.getOwnState(state).budgetType === FINANCE);

    const stockCashEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_STOCK_CASH_USE_EXTERNAL_CHECKOUT);
    const stockFinanceEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_STOCK_FINANCE_USE_EXTERNAL_CHECKOUT
    );
    const mtoCashEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_MTO_CASH_USE_EXTERNAL_CHECKOUT);
    const mtoFinanceEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_MTO_FINANCE_USE_EXTERNAL_CHECKOUT);
    const brandRecommendsFinanceEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_FINANCE_JOURNEY_PSA_CHECKOUT
    );
    const dealerSelectionCheckoutEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT
    );
    const isBudgetChangeAllowed = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_BUDGET_CHANGE_ALLOWED);
    const isCashDisabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_CASH_DISABLED);

    const mtoFinanceOrBrOrDealerSelection =
        mtoFinanceEnabled || brandRecommendsFinanceEnabled || dealerSelectionCheckoutEnabled;
    const mtoCashOrDealerSelection = mtoCashEnabled || dealerSelectionCheckoutEnabled;

    const externalCheckoutEnabled =
        (isStock && isFinance && stockFinanceEnabled) ||
        (isStock && !isFinance && stockCashEnabled) ||
        (!isStock && isFinance && mtoFinanceOrBrOrDealerSelection) ||
        (!isStock && !isFinance && mtoCashOrDealerSelection);

    const isInternalCashJourneyEnabled = isBudgetChangeAllowed && !isCashDisabled;

    const checkoutUrl = isFinance ? CHECKOUT_FINANCE_URL : CHECKOUT_CASH_URL;

    return {
        externalCheckoutEnabled,
        checkoutUrl,
        isInternalCashJourneyEnabled,
    };
};
