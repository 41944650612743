import React, { FC, useRef } from 'react';

import { FooterProps } from './';
import Icon, { Icons } from '../Icon';
import { theme } from '../../styles/theme';
import { Grid } from '../Grid';
import useTranslations from '@hooks/useTranslations';
import { useIsMyAccount } from '@hooks/useIsMyAccount';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { useDispatch, useSelector } from 'react-redux';
import Link from '../CustomNextLink';
import FilterDuck from '../../redux/filters/filter.duck';
import { Redux } from 'src/redux/redux.interface';
import { CarJourneyType } from '../../services/carList/carList.types';
import GlobalDuck from '../../redux/global/global.duck';
import { buildParameterizedPathname } from '@utils/url.utils';
import routes from '../../constants/routes';
import { isBrandAP, ROLES } from '../../constants/main';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import {useGlobalDuck} from "@hooks/useGlobalDuck";

const footerCookiePolicyClassName: string = '_psaihm_app_oppen_params';

function goToTop(): void {
    (window as any).scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

const handleCookiePreferenceClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    const policyElement = document.getElementsByClassName(footerCookiePolicyClassName)[0] as HTMLAnchorElement;
    if (policyElement) {
        policyElement.click();
    }
};

export const FooterTemplate: FC<FooterProps> = ({ className, items, checkout = false }) => {
    const { t } = useTranslations();
    const footerRef = useRef<HTMLDivElement>();
    const isMyAccount = useIsMyAccount();
    const dispatch = useDispatch();

    const { carJourney } = useGlobalDuck();
    const path = buildParameterizedPathname(routes.ROOT, carJourney);

    const handleLogoClick = () => {
        // Reset loading state so that in case of unfinished requests homepage doesn't get stuck with loading
        dispatch(UIDuck.resetLoading());
    };

    const iconWidth = isBrandAP ? 50 : 35;
    const iconHeight = isBrandAP ? 50 : 35;

    return (
        <div className={className}>
            {!checkout && !isMyAccount && (
                <div className="orderNowSection">
                    <Grid>
                        <div className="orderNowTitle">{t('footer.header')}</div>
                        <div className="itemsWrapper">
                            <div className="footerItem" data-testid="TESTING_FOOTER_ITEM_CAR">
                                <div className="imageWrap">
                                    <Icon
                                        name={Icons.Car}
                                        color={theme.colors.secondary}
                                        width={35}
                                        height={35}
                                        role={ROLES.IMG}
                                    />
                                </div>
                                <div className="textWrap">{t('footer.price')}</div>
                            </div>
                            <div className="footerItem" data-testid="TESTING_FOOTER_ITEM_CALENDAR">
                                <div className="imageWrap">
                                    <Icon
                                        name={Icons.Calendar}
                                        width={iconWidth}
                                        height={iconHeight}
                                        role={ROLES.IMG}
                                    />
                                </div>
                                <div className="textWrap">{t('footer.warranty')}</div>
                            </div>
                            <div className="footerItem" data-testid="TESTING_FOOTER_ITEM_SECURE_PAYMENT">
                                <div className="imageWrap" style={{ paddingTop: '3px' }}>
                                    <Icon
                                        name={Icons.SecurePayment}
                                        color={theme.colors.secondary}
                                        width={iconWidth}
                                        height={iconHeight}
                                        role={ROLES.IMG}
                                    />
                                </div>
                                <div className="textWrap">{t('footer.security')}</div>
                            </div>
                            <div className="footerItem" data-testid="TESTING_FOOTER_ITEM_STORE">
                                <div className="imageWrap">
                                    <Icon
                                        name={Icons.CarStore}
                                        color={theme.colors.secondary}
                                        width={iconWidth}
                                        height={iconHeight}
                                        role={ROLES.IMG}
                                    />
                                </div>
                                <div className="textWrap">{t('footer.support')}</div>
                            </div>
                        </div>
                    </Grid>
                </div>
            )}

            <div ref={footerRef} className="footer">
                <div className="logo">
                    <Link href={path}>
                        <a onClick={handleLogoClick}>
                            <Icon name={Icons.Logo} width={80} height={57}></Icon>
                        </a>
                    </Link>
                </div>
                <ul>
                    {items &&
                        items.map(({ id, label: { title, file, href, type } }, index) => (
                            <div key={id}>
                                <li className="footer-link-item">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={type === 'file' ? file : href}
                                        id={`TESTING_FOOTER_${index}`}
                                    >
                                        {title}
                                    </a>
                                </li>

                                <li key={`divider-${id}`} className="divider">
                                    {isBrandAP ? '|' : '_'}
                                </li>
                            </div>
                        ))}

                    <li className="cookiesPreferences">
                        {/* APP-5227 - NICE WORKAROUND */}
                        <a href="#move-page-nowhere" onClick={handleCookiePreferenceClick}>
                            {t('footer.cookies')}
                        </a>
                    </li>
                </ul>

                <AccessibleButton className="scrollToTopIcon" onClick={goToTop} id="TESTING_FOOTER_ARROW_UP">
                    <Icon
                        altId="svgImgGoToTop"
                        altText={t('footer.scrollToTop.anchor')}
                        role={ROLES.BUTTON}
                        name={Icons.ArrowUp}
                        height={25}
                    />
                </AccessibleButton>
            </div>

            <div className="footerScrollToTopContainer" onClick={goToTop}>
                <a className="footerScrollToTopAnchor" target="#logo">
                    {/* change after BE done to isBrandOV */}
                    <div className="text">{t('footer.scrollToTop.anchor')}</div>
                    <Icon
                        altId="svgImgGoToTop"
                        altText={t('footer.scrollToTop.anchor')}
                        name={Icons.ChevronUp}
                        height={20}
                    />
                </a>
            </div>
        </div>
    );
};
