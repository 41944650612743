import styled from 'styled-components';
import { HeaderStyled } from '../HeaderStyled';
import { rem } from 'polished';

export const HeaderStyledDS = styled(HeaderStyled)`
    .header {
        .desktopRightPart {
            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.white};
                    }
                }

                .label {
                    svg {
                        margin-left: ${rem(4)};
                        width: ${rem(12)};
                        height: ${rem(12)};
                    }
                }
            }
        }
    }
`;
