import styled from 'styled-components';
import { CheckoutTipsSidebarStyled } from '../CheckoutTipsSidebarStyled';

export const CheckoutTipsSidebarStyledAC = styled(CheckoutTipsSidebarStyled)`
    border: 4px solid ${({ theme }) => theme.colors.brown1};

    .title {
        background-color: ${({ theme }) => theme.colors.brown1};
    }
`;
