import styled from 'styled-components';
import FAQsTemplate from '../../FAQsTemplate';

export const FAQsStyledOV = styled(FAQsTemplate)`
    border-top: 2px solid ${({ theme }) => theme.colors.grey6};

    .categoryContent {
        p:not(:first-child) {
            margin-top: -1rem;
        }
    }
`;
