import React from 'react';
import { FeatureSwitcherStyled } from './FeatureSwitcherStyled';
import { SC } from '../../styles/theme';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { FeatureSwitchType, IFeatureSwitchValue, ISelectFSOption } from '@utils/featureSwitches.utils';
import { OptionsType } from 'react-select';

export interface IFeatureSwitcherProps extends SC {
    switcherValue: boolean | string[] | string;
    switcherName: FEATURES_LIST;
    btnLabel: string;
    onValueChange: <ValueType = boolean>(fsObj: IFeatureSwitchValue<ValueType>) => void;
    switchType?: FeatureSwitchType;
    onValidate?: (value: boolean) => void;
    options?: OptionsType<ISelectFSOption>;
    isRequired?: boolean;
}

const FeatureSwitcher = (props: IFeatureSwitcherProps) => {
    return <FeatureSwitcherStyled {...props} />;
};

export default FeatureSwitcher;
