import { useEffect } from 'react';
import { IS_DEV } from '../../constants/main';
import { captureException } from '@utils/sentry.utils';

export const useHandledError = (error: any) => {
    useEffect(() => {
        console.groupCollapsed(`%c HANDLED ERROR`, 'background: red; color: white; padding: 9px 6px;');
        console.log(error);
        console.groupEnd();

        if (!IS_DEV) {
            captureException(error);
        }
    }, [error]);
};
