import styled from 'styled-components';
import { ModalDealerSearchStyled } from '../ModalDealerSearchStyled';
import { rem } from 'polished';

export const ModalDealerSearchStyledOV = styled(ModalDealerSearchStyled)`    
    .dealerSearch {
        &__input {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='49' height='44' viewBox='0 0 49 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.5 27C18.364 27 15 23.635 15 19.5C15 15.364 18.364 12 22.5 12C26.635 12 30 15.364 30 19.5C30 23.635 26.635 27 22.5 27ZM36.743 32.536L29.083 24.875C30.28 23.411 31 21.54 31 19.5C31 14.805 27.194 11 22.5 11C17.805 11 14 14.805 14 19.5C14 24.194 17.805 28 22.5 28C24.794 28 26.873 27.086 28.402 25.609L36.036 33.243C36.133 33.34 36.261 33.389 36.389 33.389C36.517 33.389 36.645 33.34 36.743 33.243C36.938 33.047 36.938 32.731 36.743 32.536Z' fill='%23142032'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='14' y='11' width='23' height='23'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.5 27C18.364 27 15 23.635 15 19.5C15 15.364 18.364 12 22.5 12C26.635 12 30 15.364 30 19.5C30 23.635 26.635 27 22.5 27ZM36.743 32.536L29.083 24.875C30.28 23.411 31 21.54 31 19.5C31 14.805 27.194 11 22.5 11C17.805 11 14 14.805 14 19.5C14 24.194 17.805 28 22.5 28C24.794 28 26.873 27.086 28.402 25.609L36.036 33.243C36.133 33.34 36.261 33.389 36.389 33.389C36.517 33.389 36.645 33.34 36.743 33.243C36.938 33.047 36.938 32.731 36.743 32.536Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 -3H50V47H0V-3Z' fill='%23796F6E'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }
        
        button {
            font-size: ${(props) => props.theme.fontSizes.h6};
        }
    }
    
    .dealerList {
        .dealerItem {
            &__icon {
                background: url("data:image/svg+xml,%3Csvg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0C3.13359 0 0 3.1244 0 6.97947C0 11.7342 5.36484 13.4682 6.53242 20.8348C6.60898 21.3092 6.61445 21.6364 7 21.6364C7.38555 21.6364 7.39102 21.3092 7.46758 20.8348C8.63516 13.4682 14 11.7342 14 6.97947C14 3.1244 10.8664 0 7 0Z' fill='%23000'/%3E%3C/svg%3E%0A")
                    top center no-repeat;
            }
            &__footer button {
                border: 1px solid ${(props) => props.theme.colors.black};
            }
        }
    }    
`;
