import { useSelector } from 'react-redux';
import { ConfiguratorDuck } from '../redux/configurator/configurator.duck';
import { IConfiguratorOption } from '../redux/configurator/configurator.duck.interface';
import { IFeature } from '../services/features/featureList.types';
import { useMemo } from "react";

export const useConfiguratorDuck = () => {
    const externalId = useSelector(state => ConfiguratorDuck.getOwnState(state).externalId);
    const selectedOptions = useSelector(state => ConfiguratorDuck.getOwnState(state).selectedOptions);
    const availableOptions = useSelector(state => ConfiguratorDuck.getOwnState(state).availableOptions);
    const allCarOptions = useSelector(state => ConfiguratorDuck.getOwnState(state).options) as IConfiguratorOption[];
    const exteriorColor = useSelector(state => ConfiguratorDuck.getOwnState(state).exteriorColor);
    const exteriorColors = useSelector(state => ConfiguratorDuck.getOwnState(state).exteriorColors);
    const interiorColor = useSelector(state => ConfiguratorDuck.getOwnState(state).interiorColor);
    const interiorColors = useSelector(state => ConfiguratorDuck.getOwnState(state).interiorColors);
    const motorization = useSelector(state => ConfiguratorDuck.getOwnState(state).motorization) as IFeature;
    const motorizations = useSelector(state => ConfiguratorDuck.getOwnState(state).motorizations) as IFeature[];
    const gearbox = useSelector(state => ConfiguratorDuck.getOwnState(state).gearbox);
    const configuratorState = useSelector(state => ConfiguratorDuck.getOwnState(state));
    const lastPartSelected = useSelector(state => ConfiguratorDuck.getOwnState(state).lastPartSelected);

    const filteredCarOptions = useMemo(() => {
        // @ts-ignore
        return allCarOptions.filter(({ id }: { id: string }) => availableOptions.includes(id));
    }, [allCarOptions, availableOptions]);

    return {
        externalId,
        selectedOptions,
        carOptions: filteredCarOptions,
        exteriorColor,
        exteriorColors,
        interiorColor,
        interiorColors,
        motorization,
        motorizations,
        gearbox,
        configuratorState,
        lastPartSelected
    };
};
