import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalShareConfigurationPersonalizeOfferTemplate from '././ModalShareConfigurationPersonalizeOfferTemplate';

export const ModalShareConfigurationPersonalizeOfferStyled = styled(ModalShareConfigurationPersonalizeOfferTemplate)`
    width: 100%;

    .sent-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: auto;
        padding-bottom: ${rem('34px')};

        .text {
            padding: ${rem('34px')};
        }
    }

    .share-wrapper {
        max-width: 96%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: auto;
        position: relative;
        padding-bottom: ${rem(90)};

        .input-wrapper {
            padding: 0 0 ${rem(10)} 0;
            width: 100%;

            .error {
                border-color: ${(props) => props.theme.colors.danger};
                margin-bottom: ${rem(14)};
            }

            .dropdown,
            input {
                width: 100%;
                border-radius: unset;
                border: 1px solid ${({ theme }) => theme.colors.primaryDisabled};
                padding: 20px;
                margin: ${rem(5)} 0;
                font-size: ${(props) => props.theme.fontSizes.textBase};
                flex: 2;
                max-height: 55px;

                &:disabled {
                    background: ${({ theme }) => theme.colors.primaryDisabled};
                }
            }

            .emailError {
                color: ${(props) => props.theme.colors.danger};
                position: absolute;
                font-size: ${(props) => props.theme.fontSizes.textTiny};
                bottom: 0;
                left: 0;
            }

            .validityError {
                color: ${(props) => props.theme.colors.danger};
                position: relative;
                font-size: ${(props) => props.theme.fontSizes.textTiny};
                bottom: ${rem(8)};
            }

            .input-section {
                position: relative;

                &-label {
                    font-size: ${(props) => props.theme.fontSizes.textBase};
                    margin-top: ${rem(16)};
                    display: block;
                    padding-bottom: ${rem(2)};
                }
            }
        }

        .mandatory {
            font-size: ${(props) => props.theme.fontSizes.textTiny};
            margin: ${rem(4)} 0;
        }

        .submit {
            margin: 20px auto 0 auto;

            div {
                align-items: center;
            }
        }

        .footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: ${rem(20)};
        }

        .copiedLinkText {
            display: flex;
            justify-content: center;
            margin-top: ${rem(15)};

            svg {
                path {
                    fill: ${(props) => props.theme.colors.black};
                }
            }
        }
    }

    .checkboxSection {
        display: flex;

        &-title {
            margin-top: ${rem(20)};

            p {
                color: ${(props) => props.theme.colors.black};

                &:first-child {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                &:last-child {
                    margin-bottom: ${rem(20)};
                }
            }
        }

        .agreement {
            margin-top: ${rem(10)};
        }

        .container label {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${(props) => props.theme.colors.black};
            margin-right: ${rem('12px')};
        }

        button {
            width: 100%;
            display: flex;
            align-items: center;
            margin-right: ${rem(20)};
        }

        .checkboxWrapper input:checked + label::after {
            left: ${rem(8)} !important;
        }
    }

    .civilitySectionError {
        color: ${(props) => props.theme.colors.danger};
        font-size: ${(props) => props.theme.fontSizes.textTiny};
        margin-bottom: ${rem(10)};
    }

    .datePickerSection {
        &.error {
            input {
                border-color: ${(props) => props.theme.colors.danger};
            }
        }
    }

    .civilitySection {
        display: flex;
        padding: ${rem(10)} 0;

        .civility {
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.colors.black};
            margin-right: ${rem(10)};
            font-size: ${(props) => props.theme.fontSizes.textBase};
        }

        .check {
            cursor: pointer;
            width: ${rem(20)};
            height: ${rem(20)};
            border: 1px solid ${(props) => props.theme.colors.secondaryDisabled};
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: ${rem(10)};

            &.checkError {
                border-color: ${(props) => props.theme.colors.danger};
            }

            .selected {
                width: ${rem(10)};
                height: ${rem(10)};
                border-radius: 50%;
                background: ${(props) => props.theme.colors.primary};
            }
        }
    }

    .react-datepicker__day--selected {
        background: ${(props) => props.theme.colors.primary};
    }
`;
