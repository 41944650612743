import styled from 'styled-components';
import { ModalLeadFormStyled } from '../ModalLeadFormStyled';
import { rem } from 'polished';

export const ModalLeadFormStyledAC = styled(ModalLeadFormStyled)`
    .btn-primary {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        border: none;
        border-radius: ${rem(24)};
        padding: ${rem(5)} ${rem(21)};
        background: ${({ theme }) => theme.colors.blue2};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};

        &:hover {
            background: ${({ theme }) => theme.colors.grey5};
        }
    }
    .legal_description {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
        margin: ${rem(32)} 0;
    }
    .legal_agreement_title {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
        padding: ${rem(16)};
        cursor: pointer;
        margin: 0;

        &.isOpen {
            svg {
                transform: rotate(-90deg);
            }
        }

        &.isClose {
            svg {
                transform: rotate(90deg);
            }
        }
    }
    .legal_agreement_description {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
        padding: 0 ${rem(16)};
    }
    .agreement1 div div,
    .agreement2 div div,
    .agreement3 div div {
        display: flex;
        gap: ${rem(30)};
    }
    .form-field {
        svg {
            width: ${rem(20)};
            height: ${rem(20)};
            position: absolute;
            right: 0;
            top: ${rem(12)};

            path {
                fill: ${({ theme }) => theme.colors.red};
            }
        }
    }
`;
