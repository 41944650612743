import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import InputTemplate from './InputTemplate';
import { rem } from 'polished';

export const InputStyled = styled(InputTemplate)`
    margin-top: ${(props) => (props.marginTop ? rem(props.marginTop) : 0)};
    margin-bottom: ${(props) => (props.marginBottom ? rem(props.marginBottom) : 0)};
    width: 100%;
    position: relative;

    span {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        display: block;
        padding-bottom: ${rem(8)};

        .tooltip {
            display: inline-block;

            button {
                background: none;
                border: none;
                cursor: pointer;

                svg {
                    g {
                        circle,
                        line {
                            stroke: ${({ theme }) => theme.colors.primary};
                        }
                    }
                }
            }
        }
    }

    .currency {
        position: absolute;
        height: ${rem(44)};
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem(40)};
        padding: 0;
        background: ${({ theme }) => theme.colors.grey12};
        bottom: ${rem(1)};
        left: ${rem(1)};
    }

    input {
        width: 100%;
        height: ${rem(46)};
        border: 1px solid ${({ theme }) => theme.colors.black};
        padding: ${rem(16)};
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        min-width: ${rem(200)};

        @media (min-width: ${breakpoints.xs}px) {
            min-width: ${rem(280)};
        }

        &.withCurrency {
            padding-left: ${rem(50)};
        }

        &.error {
            border-color: ${({ theme }) => theme.colors.danger};

            &:focus-visible {
                outline: none;
            }
        }
    }
`;
