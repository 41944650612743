import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import routes from '../constants/routes';
import { TBudgetType } from '../interfaces/IFilters';
import UIDuck from '../redux/commonDucks/ui.duck';
import { CarJourneyType } from '../services/carList/carList.types';
import { buildParameterizedPathname } from '@utils/url.utils';
import useCustomRouter from './useCustomRouter';
import { useCarStockJourneyCheck } from './useCarStockJourneyCheck';

export const getStockModelUrl = (asPath: string, nameplateBodyStyleSlug: string, paymentJourney: TBudgetType) => {
    const pathname = buildParameterizedPathname(routes.SELECTOR, CarJourneyType.STOCK, {
        nameplateBodyStyleSlug: nameplateBodyStyleSlug,
    });

    return pathname;
};

export const useCheckStockCarAvailabilityCheck = (
    modelTitle: string,
    nameplateBodyStyleSlug: string,
    paymentJourney: TBudgetType,
    isBooked: boolean
) => {
    const dispatch = useDispatch();
    const router = useCustomRouter();
    const modelUrl = getStockModelUrl(router.asPath, nameplateBodyStyleSlug, paymentJourney);
    const { isStockJourney } = useCarStockJourneyCheck();

    useEffect(() => {
        if (isStockJourney && isBooked) {
            dispatch(
                UIDuck.openModal({
                    data: {
                        modelName: modelTitle,
                        modelUrl: modelUrl,
                    },
                    modalType: UIDuck.MODAL_TYPES.STOCK_CAR_UNAVAILABLE,
                })
            );
        }
    }, [isStockJourney, isBooked, modelTitle, modelUrl]);
};
