type Address = {
    zipCode: string;
    city: string;
    line1: string;
};
export interface IDealer {
    attachedNVActorCode?: string;
    externalId?: string;
    city: string;
    distance?: number;
    name?: string;
    dealerName?: string;
    postCode?: string;
    zipCode?: string;
    streetName?: string;
    street?: string;
    address?:
        | {
              [key: string]: Address;
          }
        | Address;
    siteGeo?: string;
    region?: any;
    language?: any;
    latitude?: number;
    longitude?: number;
    discount?: any;
    contactNumber?: string;
    country?: string;
    department?: any;
    numSiret?: string;
    email?: string;
    openingHours?: string;
    phoneNumber?: string;
    coordinates?: {
        lat: number;
        lon: number;
    };
    siretNumber?: string;
}

export interface IDealerLocation {
    lat: number;
    lng: number;
}

export enum DealerStateType {
    place = 'place',
    dealer = 'dealer',
}

export interface IDealerState {
    error: boolean;
    selectedPlace: IDealerLocation;
    dealers: IDealer[];
    selectedDealer: IDealer;
    type: DealerStateType;
}

export interface IPredictionedPlace {
    description: string;
    id: string;
    place_id: string;
    [key: string]: any;
}
