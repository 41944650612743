import axios, { AxiosAdapter, AxiosRequestConfig } from 'axios';
// @ts-ignore
import jsonpAdapter from 'axios-jsonp';

import { DIGITAL_URL, NEXT_PUBLIC_API_URL } from '../../constants/main';
import { getDefaultHeaders } from '../apiLayer/apiLayer.utils';

export default {
    getForm: (params: any) => {
        const headers = { ...getDefaultHeaders() };
        return axios.get(`${DIGITAL_URL}/index.php?route=extension/module/form_builder_pro/loadJs`, {
            callbackParamName: 'callback',
            adapter: jsonpAdapter as AxiosAdapter,
            params,
            headers,
        } as AxiosRequestConfig);
    },

    sendForm: (params: any) => {
        const headers = { ...getDefaultHeaders() };
        return axios.post(`${NEXT_PUBLIC_API_URL}/digital-api/siebel/update`, {
            callbackParamName: 'callback',
            adapter: jsonpAdapter as AxiosAdapter,
            params,
            headers,
        } as AxiosRequestConfig);
    },
};
