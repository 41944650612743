import { Pages } from '../types/pages';
import { useFeatureSwitch } from '@hooks/useFeatureSwitch';
import { useJourneyType } from '@hooks/useJourneyType';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import { CarJourneyType } from '../services';

export const useGetMainStepByPage = () => {
    const isDealerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED);
    const { isStockSummaryEnabled, isMTOSummaryEnabled } = useFeatureSwitch();
    const { carJourney } = useJourneyType();

    const isStock = carJourney === CarJourneyType.STOCK;

    const getBasketValue = (summaryEnabled: boolean) => {
        if (isStock) {
            return summaryEnabled ? 3 : 2;
        }

        return summaryEnabled ? (isDealerEnabled ? 5 : 4) : isDealerEnabled ? 4 : 3;
    };

    const getMainStepByPage = (page: Pages) => {
        const summaryEnabled = isStock ? isStockSummaryEnabled : isMTOSummaryEnabled;

        const stepByPage: { [key: string]: number } = {
            [Pages.trim]: 1,
            [Pages.configurator]: 2,
        };

        if (summaryEnabled) {
            stepByPage[Pages.summary] = isStock ? 2 : 3;
            stepByPage[Pages.dealer] = 4;
        } else {
            stepByPage[Pages.dealer] = 3;
        }

        stepByPage[Pages.basket] = getBasketValue(summaryEnabled);

        return stepByPage[page];
    };

    return {
        getMainStepByPage,
        getBasketValue,
    };
};
