import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import CheckoutDeliveryTemplate from './CheckoutDeliveryTemplate';
import { rem } from 'polished';
import media from '../../styles/media';

export const CheckoutDeliveryStyled = styled(CheckoutDeliveryTemplate)`
    background-color: #ffffff;
    margin-bottom: auto;
    .wrapper {
        margin: ${rem(40)} auto;
        max-width: 100%;

        @media (min-width: ${breakpoints.lg}px) {
            width: ${rem(1140)};
        }
    }

    .mainTitle {
        font-size: ${rem(28)};
        text-transform: uppercase;
        margin-bottom: ${rem(10)};
    }

    .widgetSidebarHandler {
        display: flex;
        flex-direction: row;

        & > div:first-child {
            flex: 3;
        }

        & > div:last-child {
            flex: 1;
        }
    }

    .col {
        width: 100%;
    }

    .dealerSpinner {
        .spinner {
            margin: 0 auto;
            div {
                border-color: ${({ theme }) => theme.colors.grey7} transparent transparent transparent;
            }
        }

        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .dalerMapWrapper {
        display: flex;
        flex-direction: column;

        & > div:first-child {
            flex: none;
        }

        & > div:last-child {
            flex: none;
            order: -1;
        }

        ${media.sm`
            flex-direction: row;
            & > div:first-child {
                flex: 1;
                order: 1;
            }

            & > div:last-child {
                flex: 2;
                order: 2;
            }
        `}
    }
    .map {
        height: ${rem(515)};
        width: 100%;
    }

    .dealerList {
        border: 2px solid ${({ theme }) => theme.colors.grey7};
        box-sizing: border-box;
        height: ${rem(515)};
        overflow-y: hidden;
        margin: 0 ${rem(20)} 0 0;
        padding: ${rem(20)} 0 0 ${rem(10)};
        color: ${({ theme }) => theme.colors.blue7};
        width: 100%;

        & > p {
            margin: 0 0 ${rem(15)};
            padding: 0;
            font-size: ${rem(14)};
            line-height: ${rem(21)};
        }

        &__scrollable {
            height: 90%;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    .dealerItem {
        margin-bottom: ${rem(30)};

        &__header {
            display: flex;
            align-items: baseline;
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${rem(10)};

            .btnSelected {
                background-color: ${({ theme }) => theme.colors.blue9};
                color: ${({ theme }) => theme.colors.white};
            }
        }

        &__address {
            p:first-child {
                font-weight: bold;
            }

            p {
                margin: 0;
                line-height: ${rem(18)};
                font-size: ${rem(12)};
            }
        }

        &__icon {
            background: url("data:image/svg+xml,%3Csvg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0C3.13359 0 0 3.1244 0 6.97947C0 11.7342 5.36484 13.4682 6.53242 20.8348C6.60898 21.3092 6.61445 21.6364 7 21.6364C7.38555 21.6364 7.39102 21.3092 7.46758 20.8348C8.63516 13.4682 14 11.7342 14 6.97947C14 3.1244 10.8664 0 7 0Z' fill='%2300A3E0'/%3E%3C/svg%3E%0A")
                top center no-repeat;
            font-size: ${rem(11)};
            width: ${rem(25)};
            height: ${rem(24)};
            text-align: center;
            color: ${({ theme }) => theme.colors.white};
            padding-top: ${rem(2)};
            flex-shrink: 0;
        }

        &__distance {
            p {
                text-align: right;
                margin: 0;
                line-height: ${rem(16)};
                font-size: ${rem(12)};
            }
        }

        &__button {
            width: auto;
            min-width: 100px;
            display: inline-block;
            padding: ${rem(11)};
            border: 1px solid ${({ theme }) => theme.colors.blue9};
            background-color: ${({ theme }) => theme.colors.white};
            text-align: center;
            text-transform: uppercase;
            font-size: ${rem(10)};
            color: ${({ theme }) => theme.colors.blue9};
            cursor: pointer;

            &:hover {
                border-color: ${({ theme }) => theme.colors.primary};
                background-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .selectedDealer {
        margin-bottom: ${rem(50)};
        &__header {
            margin-top: ${rem(50)};
            display: flex;
            align-items: center;
            margin-bottom: ${rem(7)};

            span {
                color: ${({ theme }) => theme.colors.blue7};
                line-height: ${rem(21)};
                font-size: ${rem(14)};
            }

            span:first-child {
                width: ${rem(24)};
                height: ${rem(24)};
                display: inline-block;
                margin-right: ${rem(8)};
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHoSURBVHgBrVXLTcNAEH27cYKEEAodGAkQ3EgFQAXQAVABzo1LyJpcuJEOoAToACpIboACIiVYiBP5DDN2SGLWiWwn72DZO+s3s7MzbxTmwWuVsb52AcIhFLmAciODCvi7jSEe0es/4GavO4tCJa5evrgoOndsPUQakLpHr+cnOdLW5quPC5SKrdTkYZh0hpLTQu3Vs0xx8k6dnwaLweB627cdSOQYNrEM0KCKxm5z4kByLmkBlbEcBPjpV+ROojsoFutLJBeUscJFAjlBGL3zidygLvTgCAPnhNluY6av7w3N5XiC3BiRGy7PxjbnfOjHzOurnuZCPUYuTJGPoSm2RekDzU2yP4MhQBbypBIn5eqEyw04kAo0V4EQ5SWP9rp2JxNVYXbaIYEQxZxkIY+g7Sj1wfg15iQ7OSPQUKobWxJdqb3fWU6yk0sTtBWu3niTrltGUcjG1nnij2k1iyWDy7SUrD//T5KVXFCgBw2zycMDT6mcZCFXnAFOqRN99M9BM8QudNIpczl0ebJ5SAcOuhd29USuax3P0pK8IFQj6ZieaNGCj8Xh/5EL7JkcnkTlke+AI4+RJzsQGJbwYcGw+RRpQFwkBb5HYw99NfdHcSQ6L4pLEFEcnYq7mrhBFZ6hv5swlZnC+AtwwNgkb8XqywAAAABJRU5ErkJggg==)
                    no-repeat;
                background-size: contain;
            }
        }

        &__info {
            margin-bottom: ${rem(35)};
            margin-left: ${rem(36)};

            p {
                margin: 0;
                color: ${({ theme }) => theme.colors.blue7};
                line-height: ${rem(21)};
                font-size: ${rem(14)};
            }

            p:first-child {
                font-weight: bold;
            }
        }

        button {
            width: auto;
            min-width: ${rem(290)};
        }
    }

    .dealerSearch {
        height: ${rem(48)};
        display: flex;
        justify-content: space-between;
        margin-bottom: ${rem(20)};

        &__dropDown {
            border: 1px solid ${({ theme }) => theme.colors.black};
            position: absolute;
            top: ${rem(45)};
            left: 0;
            right: 0;
            background-color: ${({ theme }) => theme.colors.white};
            z-index: 100;
            width: 97%;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            li {
                padding: ${rem(10)};
                color: ${({ theme }) => theme.colors.blue7};
                cursor: pointer;
                font-size: ${rem(10)};
                background-color: ${({ theme }) => theme.colors.white};

                :hover {
                    background-color: ${({ theme }) => theme.colors.brown2};
                }
            }
        }

        &__input {
            flex-grow: 1;
            position: relative;

            ::before {
                content: '';
                width: ${rem(16)};
                height: ${rem(16)};
                display: inline-block;
                position: absolute;
                top: 50%;
                left: ${rem(10)};
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF3SURBVHgBnVMxUsMwELyTq2RSaIbMhNAgfuDAA+J0dOQHhBcwvCCTH5AXBDpaKkqcjooxPxAVLlKYJlAQH6dYcuxgPJNcY+lOt7u6tRC24kD5QwTRJwBpU/MVpGGiIw0VgW7RVn5AIGacUNuHGExzfrrQr7fbNS9jPWNWfMKclSICfEGgmDmUzZ83ZReWyce8pEAqX3kgng0zATETXS10FLoDWR25jirLpINiXQgQQyd7BVQqmjB353zPgFvOcbHOAHRhZT/+NyjOJ6zg3gL4JYCNNIygNtLQLqQ86R3nALBPEH3mACmAZaZ+bQ+Iy+xL2lwpB0BIp9kSg7Y6HVc1sxM+D3pkz4XFmrdMYt2Uh4Hxm/dBQ3ZVS3beOR8bC1vy6FoAPrgGJrwxPZs9VHlddxVgW9OBc2w9ROc1Lycbv0tNd+zCxDKufzxDavd/I3sXILmY/PCQ3dD4oY34oc2KShB2jCKIUezBjvGVxFFDdt5Y/DcrmP4CDJqZbeuFeVUAAAAASUVORK5CYII=)
                    no-repeat;
                background-size: contain;
            }
        }

        input {
            width: 97%;
            height: 100%;
            padding: ${rem(10)} ${rem(18)} ${rem(10)} ${rem(33)};
            border: 1px solid ${({ theme }) => theme.colors.grey4};
            background: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.blue9};
            height: ${rem(39)};
            line-height: ${rem(18)};
            font-size: ${rem(13)};
        }
    }

    .Collapsible {
        margin-top: ${rem(20)};
    }
`;
