import React, { useContext, useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from '../../../context/featureSwitchApp';
import { getModelTitle } from '../../../services/carList/utils/Car.utils';
import { getTotalPriceObjByType } from '@utils/Price.utils';
import { CarDetailsService, IFeature, IPriceV2Types } from '../../../services';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import Spinner from '@components/Spinner';
import useAccessoryMonthlyPrices from '@hooks/useAccessoryPrices';
import PriceSuffix from '@components/PriceSuffix';
import { useCompareEngines } from '@hooks/useCompareEngines';
import { ICarDetail } from '../../../redux/carDetails/carDetails.duck.interface';
import { batch, useDispatch } from 'react-redux';
import { orderBy } from 'lodash';
import { useConfiguratorDuck } from '@hooks/useConfiguratorDuck';
import { Icon, Icons } from '@components/Icon';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB, ModalVersion, ROLES } from '../../../constants/main';
import { isMobile } from 'is-mobile';
import FilterButton from '@components/FilterButton';
import { STANDARD_FEATURES_FS_PREFIX } from '../../../constants/characteristics';
import { ICarFeature } from '../../../interfaces/Car';
import { IconTemplate } from '@components/Icon/IconTemplate';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import CarDetailsDuck from 'src/redux/carDetails/carDetails.duck';
import ConfiguratorDuck from 'src/redux/configurator/configurator.duck';
import TrimSelectorDuck from 'src/redux/trimSelector/trimSelector.duck';
import Link from '@components/Link';
import IconButton from '@components/IconButton';
import { theme } from '../../../styles/theme';
import { useRenderDescription } from '@hooks/useRenderDescription';

const KEY_ALL = 'all';
const KEY_ENGINES = 'engine';
const KEY_STYLES = 'styles';
const KEY_EQUIPMENTS = 'equipments';
const KEY_COLORS = 'color';
const KEY_INTERIORS = 'interior';
const KEY_FEATURES = 'feature';
const KEY_STRONG_POINTS = 'point';

export interface IFeaturesStyled {
    className: string;
    carDetails: ICarDetail;
    sectionKey?: string;
}

const TRIM_SECTION_IMAGE_LABEL = 'trim-section-image';

const ModalFeaturesTemplate = ({ className, carDetails: trim, sectionKey }: IFeaturesStyled) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();
    const { getOptionMonthlyPrice } = useAccessoryMonthlyPrices();
    const { carDetails: selectedCarDetails, defaultConfiguration } = useCarDetailsDuck();
    const dispatch = useDispatch();

    const [activeSection, setActiveSection] = useState(KEY_ALL);
    const [activeFeature, setActiveFeature] = useState(KEY_ALL);
    const [exteriorColors, setExteriorColors] = useState(null);
    const [interiorColors, setInteriorColors] = useState(null);
    const [styles, setStyles] = useState(null);
    const [equipments, setEquipments] = useState(null);
    const { motorizations } = useConfiguratorDuck();
    const [isLoading, setIsLoading] = useState(false);
    const [carDetails, setCarDetails] = useState(trim);

    const { openEngineModal } = useCompareEngines(true, true);

    const { renderDescription } = useRenderDescription();

    const excludedCategoriesStr =
        useContext(FeatureSwitchContext)?.FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES ?? '';
    const isEngineComparatorEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_ENGINE_COMPARATOR);
    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);
    const isTiresLabellingEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_TIRES_LABELLING_ENABLED);

    useEffect(() => {
        setIsLoading(true);

        CarDetailsService.getCarDetailsWithAllOptions(
            carDetails.nameplateBodyStyleSlug,
            carDetails.specPackSlug,
            carDetails.engineGearboxFuelSlug,
            carDetails.exteriorColourSlug,
            carDetails.interiorColourSlug,
            [],
            true
        ).then(({ vehicle, features }) => {
            setExteriorColors(features.exteriorColors);
            setInteriorColors(features.interiorColors);
            setStyles(vehicle.trimAttributes?.filter((att: { category: string }) => att.category === 'style'));
            setEquipments(vehicle.trimAttributes?.filter((att: { category: string }) => att.category === 'equipments'));
            dispatch(ConfiguratorDuck.setMotorizations(features.engines));
            setIsLoading(false);

            if (sectionKey) {
                const element = document.getElementById(sectionKey);

                return element.scrollIntoView();
            }
        });
    }, []);

    const handleColorClick = async (selectedColorSlug: string) => {
        setIsLoading(true);

        const { vehicle, features } = await CarDetailsService.getCarDetailsWithAllOptions(
            carDetails.nameplateBodyStyleSlug,
            carDetails.specPackSlug,
            carDetails.engineGearboxFuelSlug,
            selectedColorSlug,
            carDetails.interiorColourSlug,
            []
        );
        setExteriorColors(features.exteriorColors);
        setInteriorColors(features.interiorColors);
        setCarDetails(vehicle);
        batch(() => {
            dispatch(ConfiguratorDuck.setMotorizations(features.engines));
            dispatch(CarDetailsDuck.setCurrentCarDetails(vehicle));
            dispatch(TrimSelectorDuck.setIsTrimCustomized(true));
        });

        setIsLoading(false);
    };

    const handleDetailClick = (sectionKey: string, title: string, img: string, desc: string) => {
        dispatch(
            UIDuck.openModal({
                data: { sectionKey, carDetails: trim, title, img, desc },
                callbacks: {},
                modalType: UIDuck.MODAL_TYPES.FEATURE,
            })
        );
    };

    const selectedCarConfiguration = defaultConfiguration ? defaultConfiguration : selectedCarDetails;
    const images = selectedCarConfiguration?.images?.filter((img: any) => img.type === 'Ext') ?? [{}];
    const mainImage = images?.splice(1, 1) ?? [{}];

    const hasVisibleStyleSection = styles?.length > 0;
    const hasVisibleEquipmentSection = equipments?.length > 0;
    const hasVisibleFeatureSection =
        (!styles || styles?.length === 0) &&
        (!equipments || equipments?.length === 0) &&
        carDetails?.extraFields?.seriesEquipment?.length > 0;

    const parseSectionImage = (data: IFeature[]) =>
        data.find((att: IFeature) => att.type === TRIM_SECTION_IMAGE_LABEL)?.imageURL || styles[0].imageURL;

    const filters = [
        {
            section: KEY_ALL,
            value: t('modal.features.keys.all'),
        },
        {
            section: KEY_ENGINES,
            value: t('modal.features.keys.engine'),
        },
        {
            section: KEY_STYLES,
            value: t('modal.features.keys.styles'),
        },
        {
            section: KEY_EQUIPMENTS,
            value: t('modal.features.keys.equipments'),
        },
        {
            section: KEY_STRONG_POINTS,
            value: t('modal.features.keys.point'),
        },
        {
            section: KEY_COLORS,
            value: t('modal.features.keys.color'),
        },
        {
            section: KEY_INTERIORS,
            value: t('modal.features.keys.interior'),
        },
        {
            section: KEY_FEATURES,
            value: t('modal.features.keys.feature'),
        },
    ];

    return (
        <div className={className}>
            <div className="title">
                <span className="pack" data-testid="TESTING_FEATURES_MODEL_PACK">
                    {carDetails?.specPack?.title}
                </span>
            </div>
            <div className="keys" data-testid="TESTING_FEATURES_MODEL_CATEGORIES">
                <span className="label">{t('modal.features.keys.title')}</span>
                <div className="categories">
                    {filters.map(({ section, value }, k) => {
                        if (section === KEY_EQUIPMENTS && !hasVisibleEquipmentSection) return;
                        if (section === KEY_STYLES && !hasVisibleStyleSection) return;
                        if (section === KEY_STRONG_POINTS && !hasVisibleFeatureSection) return;

                        if (!isBrandAC) {
                            return (
                                <span
                                    key={`${1 + k}`}
                                    className={activeSection === section ? 'selected' : ''}
                                    onClick={() => setActiveSection(section)}
                                    data-testid={`TESTING_TRIM_FEATURES_CATEGORY_TITLE_${k}`}
                                >
                                    {value}
                                </span>
                            );
                        }

                        return (
                            <FilterButton
                                key={`${1 + k}`}
                                className={`grid-button ${activeSection === section ? 'selected' : ''}`}
                                active={activeSection === section}
                                data-testid={`TESTING_TRIM_FEATURES_CATEGORY_TITLE_${k}`}
                                onClick={() => {
                                    setActiveSection(section);
                                }}
                                isMobile={isMobile()}
                            >
                                <div className="disp-inline">
                                    {value}

                                    {activeSection === section && <Icon className="info-check" name={Icons.Check} />}
                                </div>
                            </FilterButton>
                        );
                    })}
                </div>
            </div>
            {(activeSection === KEY_ALL || activeSection === KEY_ENGINES) && (
                <div className="engines" id={KEY_ENGINES}>
                    <span className="label">
                        <span data-testid={`TESTING_TRIM_FEATURES_${KEY_ENGINES.toUpperCase()}`}>
                            {t('modal.features.engine.title')}
                        </span>
                        {isEngineComparatorEnabled && motorizations.length > 1 && (
                            <span className="compareLink" onClick={openEngineModal}>
                                <Link
                                    label={t('configurator.engineComparator.button')}
                                    primary
                                    withArrowIcon
                                    marginTop={10}
                                />
                            </span>
                        )}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="configurations">
                            {motorizations?.map((motorization: IFeature, key: number) => {
                                const getPower = () =>
                                    motorization?.characteristics?.find(
                                        (char: { key: string }) => char.key === 'maximumPowerKW'
                                    )?.value;

                                const enginePrice = getOptionMonthlyPrice(motorization);

                                return (
                                    <div key={key}>
                                        <span className="configuration">{motorization.title}</span>
                                        <span className="engine">
                                            {`${motorization.fuel}, ${motorization.gearboxTitle} ${
                                                motorization?.characteristics?.length >= 1
                                                    ? `, ${getPower()} ${t('configurator.info.power.label')}`
                                                    : ''
                                            }`}
                                        </span>
                                        <span className="price">
                                            {formatMoney(
                                                isSOLEnabled
                                                    ? getTotalPriceObjByType(
                                                          motorization.pricesV2,
                                                          IPriceV2Types.TOTAL_B2C_CASH
                                                      )?.breakdown.finalCustomerCashPriceOnTheRoad
                                                    : getTotalPriceObjByType(
                                                          motorization.pricesV2,
                                                          IPriceV2Types.TOTAL_B2C_CASH
                                                      )?.finalPriceInclTax,
                                                true
                                            )}{' '}
                                            {(enginePrice.price || enginePrice.defaultPrice) && (
                                                <span>
                                                    <span className="or">{t('configurator.optionPrice.suffix')}</span>{' '}
                                                    {formatMoney(enginePrice.price || enginePrice.defaultPrice, true)}{' '}
                                                    <PriceSuffix isMonthlyPrice hideAsterisk />
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
            {(activeSection === KEY_ALL || activeSection === KEY_STRONG_POINTS) && hasVisibleFeatureSection && (
                <div className="interiors">
                    <span className="label" data-testid={`TESTING_TRIM_FEATURES_${KEY_STRONG_POINTS.toUpperCase()}`}>
                        {t('modal.features.point.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="configurations">
                            {carDetails?.extraFields?.seriesEquipment.map((seriesEquipment: ICarFeature) => {
                                const data = seriesEquipment.data;

                                return Array.isArray(data)
                                    ? data.map((equipment) => <span key={equipment.label}>{equipment.label}</span>)
                                    : Object.keys(data).map((key) => (
                                          <span key={data[key].label}>{data[key].label}</span>
                                      ));
                            })}
                        </div>
                    )}
                </div>
            )}
            {(activeSection === KEY_ALL || activeSection === KEY_STYLES) && styles?.length > 0 && (
                <div className="styles" id={KEY_STYLES}>
                    <span className="label" data-testid={`TESTING_TRIM_FEATURES_${KEY_STYLES.toUpperCase()}`}>
                        {t('modal.features.styles.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="configurations">
                            <img src={parseSectionImage(styles)} />
                            {styles?.map(
                                (att: { title: string; imageURL: string; description: string }, key: number) => (
                                    <span
                                        key={key}
                                        className="link"
                                        onClick={() =>
                                            handleDetailClick(KEY_STYLES, att.title, att.imageURL, att.description)
                                        }
                                    >
                                        {att.title} <IconTemplate name={Icons.ChevronRight} />
                                    </span>
                                )
                            )}
                        </div>
                    )}
                </div>
            )}
            {(activeSection === KEY_ALL || activeSection === KEY_EQUIPMENTS) && equipments?.length > 0 && (
                <div className="equipments" id={KEY_EQUIPMENTS}>
                    <span className="label" data-testid={`TESTING_TRIM_FEATURES_${KEY_EQUIPMENTS.toUpperCase()}`}>
                        {t('modal.features.equipments.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="configurations">
                            <img src={parseSectionImage(equipments)} />
                            {equipments?.map(
                                (att: { title: string; imageURL: string; description: string }, key: number) => (
                                    <span
                                        key={key}
                                        className="link"
                                        onClick={() =>
                                            handleDetailClick(KEY_EQUIPMENTS, att.title, att.imageURL, att.description)
                                        }
                                    >
                                        {att.title} <IconTemplate name={Icons.ChevronRight} />
                                    </span>
                                )
                            )}
                        </div>
                    )}
                </div>
            )}
            {(activeSection === KEY_ALL || activeSection === KEY_COLORS) && (
                <div className="colors" id={KEY_COLORS}>
                    <span className="label" data-testid={`TESTING_TRIM_FEATURES_${KEY_COLORS.toUpperCase()}`}>
                        {t('modal.features.color.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="configurations">
                            {mainImage && <img className="carImage" src={mainImage[0]?.url} />}
                            <div>
                                {exteriorColors?.map(
                                    (
                                        clr: { id: string; images: { url: string }[]; exteriorColourSlug: string },
                                        key: number
                                    ) => {
                                        return (
                                            <button
                                                className={`colorButton enabled ${
                                                    selectedCarConfiguration?.exteriorColourSlug ===
                                                    clr.exteriorColourSlug
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                role="button"
                                                key={key}
                                                style={{ backgroundImage: `url('${clr.images[0]?.url}')` }}
                                                onClick={() => handleColorClick(clr.exteriorColourSlug)}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {(activeSection === KEY_ALL || activeSection === KEY_INTERIORS) && (
                <div className="interiors" id={KEY_INTERIORS}>
                    <span className="label" data-testid={`TESTING_TRIM_FEATURES_${KEY_INTERIORS.toUpperCase()}`}>
                        {t('modal.features.interior.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="configurations">
                            {interiorColors?.map((interior: { title: string }, key: number) => (
                                <span key={key}>{interior.title}</span>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {(activeSection === KEY_ALL || activeSection === KEY_FEATURES) && (
                <div className="features" id={KEY_FEATURES}>
                    <span className="title" data-testid={`TESTING_TRIM_FEATURES_${KEY_FEATURES.toUpperCase()}`}>
                        {t('modal.features.feature.title')}
                    </span>
                    <p className="description">{t('modal.features.feature.description')}</p>
                    <div className="categories">
                        {!isBrandAC ? (
                            <span
                                className={activeFeature === KEY_ALL ? 'selected' : ''}
                                onClick={() => setActiveFeature(KEY_ALL)}
                                data-testid={`TESTING_TRIM_FEATURES_${KEY_FEATURES.toUpperCase()}_CATEGORY_ALL`}
                            >
                                {t('modal.features.feature.all')}
                            </span>
                        ) : (
                            <FilterButton
                                className={`grid-button ${activeFeature === KEY_ALL ? 'selected' : ''}`}
                                active={activeFeature === KEY_ALL}
                                onClick={() => {
                                    setActiveFeature(KEY_ALL);
                                }}
                                isMobile={isMobile()}
                                data-testid={`TESTING_TRIM_FEATURES_${KEY_FEATURES.toUpperCase()}_CATEGORY_ALL`}
                            >
                                <div className="disp-inline">
                                    {t('modal.features.feature.all')}

                                    {activeFeature === KEY_ALL && <Icon className="info-check" name={Icons.Check} />}
                                </div>
                            </FilterButton>
                        )}

                        {orderBy(
                            [
                                ...carDetails?.standardFeatures?.filter(
                                    (feature: { key: string }) =>
                                        !excludedCategoriesStr?.includes(
                                            `${STANDARD_FEATURES_FS_PREFIX}:${feature.key}`
                                        )
                                ),
                            ],
                            ['category'],
                            ['asc']
                        ).map((feature: { key: string; category: string }, key: number) => {
                            if (!isBrandAC) {
                                return (
                                    <span
                                        key={key}
                                        className={activeFeature === feature.key ? 'selected' : ''}
                                        onClick={() => setActiveFeature(feature.key)}
                                        data-testid={`TESTING_TRIM_FEATURES_${KEY_FEATURES.toUpperCase()}_CATEGORY_TITLE_${
                                            feature.key
                                        }`}
                                    >
                                        {feature.category}
                                    </span>
                                );
                            }

                            return (
                                <FilterButton
                                    key={key}
                                    className={`grid-button ${activeFeature === feature.key ? 'selected' : ''}`}
                                    active={activeFeature === feature.key}
                                    onClick={() => {
                                        setActiveFeature(feature.key);
                                    }}
                                    isMobile={isMobile()}
                                    data-testid={`TESTING_TRIM_FEATURES_${KEY_FEATURES.toUpperCase()}_CATEGORY_TITLE_${
                                        feature.key
                                    }`}
                                >
                                    <div className="disp-inline">
                                        {feature.category}

                                        {activeFeature === feature.key && (
                                            <Icon className="info-check" name={Icons.Check} />
                                        )}
                                    </div>
                                </FilterButton>
                            );
                        })}
                    </div>
                    <div className="configurations">
                        {carDetails?.standardFeatures
                            ?.filter(
                                (feature: { key: string }) =>
                                    !excludedCategoriesStr?.includes(`${STANDARD_FEATURES_FS_PREFIX}:${feature.key}`)
                            )
                            .map(
                                (
                                    feature: {
                                        key: string;
                                        category: string;
                                        data: {
                                            label: string;
                                            description: string;
                                            tires: any;
                                            images: { url: string }[];
                                        }[];
                                    },
                                    key: number
                                ) => {
                                    if (activeFeature === KEY_ALL || activeFeature === feature.key) {
                                        return (
                                            <div key={key} className="configuration">
                                                <span
                                                    className="label"
                                                    data-testid={`TESTING_TRIM_FEATURES_${KEY_FEATURES.toUpperCase()}_CATEGORY_DESCRIPTION_LABEL_${
                                                        feature.key
                                                    }`}
                                                >
                                                    {feature.category}
                                                </span>
                                                <div className="options">
                                                    {feature.data.map((configuration, key) => {
                                                        return (
                                                            <span
                                                                key={`${configuration.label}-${key}`}
                                                                className="option"
                                                            >
                                                                {configuration.label}
                                                                {(configuration.description ||
                                                                    (isTiresLabellingEnabled &&
                                                                        configuration.tires)) && (
                                                                    <IconButton
                                                                        className="infoIcon tires-icon"
                                                                        icon={
                                                                            isBrandAC ||
                                                                            isBrandAP ||
                                                                            isBrandDS ||
                                                                            (isBrandOV && !isMarketGB)
                                                                                ? Icons.InfoCircle
                                                                                : Icons.Info
                                                                        }
                                                                        color={theme.colors.primary}
                                                                        width={16}
                                                                        height={16}
                                                                        role={ROLES.IMG}
                                                                        onClick={() =>
                                                                            dispatch(
                                                                                UIDuck.openModal({
                                                                                    data: {
                                                                                        withBackButton: true,
                                                                                        category: feature.category,
                                                                                        image: configuration
                                                                                            .images?.[0]?.['url'],
                                                                                        description: renderDescription(
                                                                                            configuration.description
                                                                                        ),
                                                                                        title: configuration.label,
                                                                                        ...(isTiresLabellingEnabled &&
                                                                                            configuration.tires && {
                                                                                                tiresSubtitle: t(
                                                                                                    'technicalFeatures.tiresDetailModal.title'
                                                                                                ),
                                                                                                tires: configuration
                                                                                                    .tires?.data,
                                                                                                tiresDescription: t(
                                                                                                    'technicalFeatures.tiresDetailModal.subtitle'
                                                                                                ),
                                                                                            }),
                                                                                    },
                                                                                    modalType:
                                                                                        UIDuck.MODAL_TYPES
                                                                                            .CAR_OPTION_DETAIL,
                                                                                    modalVersion: ModalVersion.v2,
                                                                                    callbacks: {
                                                                                        openPreviousModalType:
                                                                                            UIDuck.MODAL_TYPES.FEATURES,
                                                                                        data: {
                                                                                            carDetails,
                                                                                            sectionKey: KEY_FEATURES,
                                                                                        },
                                                                                    },
                                                                                })
                                                                            )
                                                                        }
                                                                        dataTestId="SVG_IMG_TYRES_TECHCHARS"
                                                                        altId="svgImgTyresTechChars"
                                                                        altText={t('option.detail.shortText.info')}
                                                                    />
                                                                )}
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalFeaturesTemplate;
