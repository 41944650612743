import styled from 'styled-components';
import React from 'react';
import media from '../../styles/media';
import { rem } from 'polished';
import useTranslations from '@hooks/useTranslations';

export interface IModalFinancialSummary {
    monthlyPayment: number;
    lengthOfTerm: number;
    mileage: number;
}

const FinancialSummaryModal = styled.div`
    section {
        margin: ${rem('10px')} 0;

        ${media.sm`
            margin: 40px 0;
        `}
    }
    .main {
        margin: ${rem('20px')} 0;

        h3 {
            margin-bottom: 20px;
        }

        &__row {
            display: flex;
            justify-content: space-between;
            background-color: ${({ theme }) => theme.colors.grey3};
            font-size: 18px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.black};
            padding: ${rem('5px')};

            ${media.sm`
                padding: 10px;
            `}

            &:nth-child(even) {
                background-color: ${({ theme }) => theme.colors.primary};
                color: white;
            }
        }
    }

    .footer {
        padding-top: ${rem('10px')};
        border-top: ${rem('1px')} solid ${({ theme }) => theme.colors.grey2};

        span {
            font-size: ${rem('12px')};
        }

        p {
            font-size: ${rem('10px')};
        }
    }
`;

const ModalFinancialSummary = (financialSummaryData: IModalFinancialSummary) => {
    const { t } = useTranslations();

    if (financialSummaryData.monthlyPayment !== -1) {
        return (
            <FinancialSummaryModal>
                <section className="header">
                    <p>
                        La location longue durée vous permet de louer une voiture neuve. Si vous souhaitez changer de
                        véhicule réguliérement, sans vous préoccuper des conditions de sa revente, cette solution est
                        faite pour vous !
                    </p>
                </section>
                <section className="main">
                    <h3>EN LOCATION LONGUE DUREE</h3>
                    <div className="main__row">
                        <div>Layers</div>
                        <div>{financialSummaryData.monthlyPayment} €</div>
                    </div>
                    <div className="main__row">
                        <div>Durée</div>
                        <div>{financialSummaryData.lengthOfTerm} Mois</div>
                    </div>
                    <div className="main__row">
                        <div>Kilométrage annuel</div>
                        <div>{financialSummaryData.mileage}</div>
                    </div>
                </section>
                <section className="footer">
                    <span>{t('modal.financial.legalNotice')}</span>
                    <p>
                        Offre de location longue durée réservée aux Collaborateurs du Gruppe PSA, actifs et retroités,
                        résidant en France métropolitaine, pour la location du véhicule sélectionné a usage privé. Sous
                        réserrve d&apos;acceptation par CREDIPAR, SA au capital de 138.517.008 €, RCS Nanterre 317 425
                        981, 9 rue Henri Barbusse 92230 Gennevilliers, n ORIAS 07004921 (www.orias.fr).
                    </p>
                </section>
            </FinancialSummaryModal>
        );
    } else {
        console.error('FinancialSummary data:' + JSON.stringify(financialSummaryData));
        return (
            <div>
                <p>Excusez-moi, pas de données disponibles.</p>
            </div>
        );
    }
};

export default ModalFinancialSummary;
