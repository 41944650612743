import React from 'react';
import { Dispatch } from 'redux';
import { SC } from '../../styles/theme';
import { BRAND } from '../../constants/main';
import { CheckoutPersonalDetailsStyledAC } from './AC/CheckoutPersonalDetailsStyled.AC';
import { CheckoutPersonalDetailsStyledAP } from './AP/CheckoutPersonalDetailsStyled.AP';
import { CheckoutPersonalDetailsStyledDS } from './DS/CheckoutPersonalDetailsStyled.DS';
import { CheckoutPersonalDetailsStyledOV } from './OV/CheckoutPersonalDetailsStyled.OV';
import { CheckoutPersonalDetailsStyled } from './CheckoutPersonalDetailsStyled';
import { IConfigurableButton } from '../CheckoutMySummary/interfaces';
import { IUser, IUserAddress } from '../../interfaces/User';

interface IPersonalDetails {
    user?: IUser;
    isOpenedByDefault?: boolean;
    userAddress?: IUserAddress;
    userDetails?: IUser;
    personalDetailsButton?: IConfigurableButton;
    dispatch?: Dispatch;
}

export type Props = SC & IPersonalDetails;

const CheckoutPersonalDetails = (props: Props) => {
    if (BRAND === 'OV') return <CheckoutPersonalDetailsStyledOV {...props} />;
    if (BRAND === 'AC') return <CheckoutPersonalDetailsStyledAC {...props} />;
    if (BRAND === 'AP') return <CheckoutPersonalDetailsStyledAP {...props} />;
    if (BRAND === 'DS') return <CheckoutPersonalDetailsStyledDS {...props} />;
    return <CheckoutPersonalDetailsStyled {...props} />;
};

export default CheckoutPersonalDetails;
