import styled from 'styled-components';
import { rem } from 'polished';
import { ModalFindDealerStyled } from '../ModalFindDealerStyled';
export const ModalFindDealerStyledDS = styled(ModalFindDealerStyled)`
    .geolocation-input--container > .geolocation-input--textfield-container > input {
        border-radius: unset;
    }

    .modalDealerSelect {
        .Select__control {
            border-radius: unset;
        }
    }

    .modalContent {
        margin-top: ${rem('24px')};
        line-height: 1.38;

        .modalAddr {
            font-size: ${rem('16px')};
            margin-bottom: ${rem('16px')};

            .name {
                font-weight: bold;
            }
        }
    }

    .modalOpeningHours {
        font-size: ${rem('16px')};
        line-height: 1.38;
    }
`;
