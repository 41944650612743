import styled from 'styled-components';
import { rem } from 'polished';
import { ModalRemovePxConfirmationTemplate } from './ModalRemovePxConfirmationTemplate';

export const ModalRemovePxConfirmationStyled = styled(ModalRemovePxConfirmationTemplate)`
    .description {
        margin-bottom: ${rem(35)};
    }

    .button {
        width: 100%;
        display: flex;
        gap: ${rem(15)};
        margin-top: ${rem(15)};
        justify-content: center;
    }
`;
