import { rem } from 'polished';
import styled from 'styled-components';
import { CheckoutFinanceWidgetStyled } from '../CheckoutFinanceWidgetStyled';

export const CheckoutFinanceWidgetStyledAC = styled(CheckoutFinanceWidgetStyled)`
    color: ${({ theme }) => theme.colors.grey2};

    .finance-summary {
        &--grid {
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
        }
        &--col-1 {
            &.SOL {
                text-align: center;
                text-transform: uppercase;
            }
        }
        &--row-2 {
            &.bold-style {
                margin-top: ${rem(16)};
            }
            &.SOL {
                background: ${({ theme }) => theme.colors.grey16};
                border: none;
            }
        }

        &--col-2 {
            &.SOL {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-family: ${({ theme }) => theme.fonts.fontBase};
            }
        }

        &--col-3 {
            &.SOL {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-family: ${({ theme }) => theme.fonts.fontBase};
            }
        }
    }
`;
