import styled from 'styled-components';
import { ModalPostalCodeStyled } from '../ModalPostalCodeStyled';
import { rem } from 'polished';

export const ModalPostalCodeStyledDS = styled(ModalPostalCodeStyled)`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.h3};
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.h5};
    }

    .input {
        button {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            font-size: ${({ theme }) => theme.fontSizes.h5};
            border-radius: ${rem(24)};
            padding: ${rem(15)} ${rem(24)};
            background: ${({ theme }) => theme.colors.black};

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .type-select {
        .container {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            color: ${({ theme }) => theme.colors.black};
        }

        .checkmark {
            border-color: ${({ theme }) => theme.colors.grey5};
        }

        .container .checkmark:after {
            background: ${({ theme }) => theme.colors.primary};
        }
    }

    .select {
        svg {
            path {
                fill: ${({ theme }) => theme.colors.black};
            }
        }

        .distance-select {
            &__single-value {
                font-size: ${({ theme }) => theme.fontSizes.h5};
                color: ${({ theme }) => theme.colors.grey29};
            }

            &__option {
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            &__option--is-focused {
                background: ${({ theme }) => theme.colors.grey4};
            }

            &__option--is-selected {
                background: ${({ theme }) => theme.colors.black};
                color: ${({ theme }) => theme.colors.white};
                font-size: ${({ theme }) => theme.fontSizes.h5};
            }

            &__control {
                border-radius: ${rem(3)};
                border: 1px solid ${({ theme }) => theme.colors.grey5};
            }
        }
    }

    .submit {
        button {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            border-radius: ${rem(24)};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};

            &:disabled {
                background: ${({ theme }) => theme.colors.grey16};
            }
        }

        .home {
            border: 1px solid ${({ theme }) => theme.colors.black1};
            color: ${({ theme }) => theme.colors.black1};

            svg {
                width: ${rem(12)};
                height: ${rem(12)};

                path {
                    fill: ${({ theme }) => theme.colors.black1};
                }
            }

            &:hover:enabled,
            &:focus-visible:enabled {
                background: ${({ theme }) => theme.colors.grey4};
                border-color: ${({ theme }) => theme.colors.primary};
            }
        }

        .validate {
            background: ${({ theme }) => theme.colors.black1};

            &:hover:enabled,
            &:focus-visible:enabled {
                background: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .selected-dealer {
        .label,
        .name,
        .address-line1,
        .address-line2 {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            color: ${({ theme }) => theme.colors.black};
        }
    }
`;
