export const ecologicalBonusDiscountValue: number = 6000;

/**
 * This method will check if car has one of the known electric types
 *
 * @param fuel
 */
export function isCarElectric(fuel: string): boolean {
    const electriqueTypes: string[] = ['Electrique'];

    return electriqueTypes.includes(fuel);
}
