import React, { FC, useContext, useRef, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { HeaderProps } from '@components/v2/Header/index';
import Icon, { Icons } from '@components/Icon';
import Link from '@components/CustomNextLink';
import { Link as RelativeLink } from '../../../../i18n';
import { buildLanguagePathname, buildParameterizedPathname } from '@utils/url.utils';
import routes from '../../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useGTM } from '@hooks/useGTM';
import {
    B2B_URL,
    B2C_URL,
    IS_B2B,
    IS_B2C,
    IS_CLIENT,
    isBrandAC,
    isBrandAP,
    isBrandDS,
    isBrandOV,
    ModalVersion,
} from '../../../constants/main';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import sessionInfoApi from '../../../services/core/seassionInfoApi.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import UserDuck from '../../../redux/user/user.duck';
import { BRANDID_LOGOUT_URL, SAML_LOGOUT_URL, SESSION_COOKIE_NAME } from '../../../constants/myAccount';
import { useRouter } from 'next/router';
import { GtmData } from '../../../types/gtm';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useCreateDeal } from '@hooks/useCreateDeal';
import { useUserDuck } from '@hooks/useUserDuck';
import { EUserLoginType } from '../../../interfaces/User';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';
import { IsHomepageContext } from '../../../contexts/IsHomepageContext';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { useUIDuck } from '@hooks/useUIDuck';
import { IconTemplate } from '@components/Icon/IconTemplate';
import Cookies from 'js-cookie';
import { useGlobalDuck } from '@hooks/useGlobalDuck';
import { PaymentJourneyTypes } from '../../../partExchange/interfaces/Default';
import FilterDuck from '../../../redux/filters/filter.duck';

export const HeaderTemplate: FC<HeaderProps> = ({ className }) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();
    const router = useRouter();
    const { showBasketInHeader } = useUIDuck();
    const { pushToDataLayer } = useGTM();
    const isHomepage = useContext(IsHomepageContext);
    const createDeal = useCreateDeal();
    const { isUserLoggedInAsDealer } = useUserDuck();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isLogoutBoxOpen, setIsLogoutBoxOpen] = useState(false);

    const logoutRef = useRef();

    const { isStockJourney } = useCarStockJourneyCheck();

    const { isUserLoggedIn, userAuth } = useUserDuck();

    const { carJourney } = useGlobalDuck();

    const paymentJourney = useSelector((state) => FilterDuck.getPaymentJourneyType(state));

    const currentPath = router.asPath;
    const isTrimPage = currentPath.includes(routes.SELECTOR);
    const isBasketPage = currentPath.includes(routes.BASKET);
    const isConfigPage = currentPath.includes(routes.CAR);
    const isDealerPage = currentPath.includes(routes.DEALER);

    const isEnvSwitcherEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_ENV_SWITCHER_ENABLED);
    const isContactDetailEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_CONTACT_DETAILS_ENABLED);

    let path = buildParameterizedPathname(routes.ROOT, carJourney);

    // deeplink functionality - reset filter after click on logo
    path = `${path}${path.includes('?') ? '&' : '?'}resetFilters=true`;

    const headerTitle = `${t('header.title')}`;
    const headerSubtitle =
        paymentJourney === PaymentJourneyTypes.CASH
            ? `${t('header.subtitle.cash')}`
            : `${t('header.subtitle.finance')}`;

    const handleSwitchClick = () => {
        pushToDataLayer({
            event: 'uaevent',
            eventCategory: 'Header',
            eventAction: 'Redirection::External',
            eventLabel: IS_B2C ? 'Professionnel' : 'Particulier',
        });

        return (window.location.href = IS_B2C ? B2B_URL : B2C_URL);
    };

    const handleLogoClick = () => {
        pushToDataLayer({
            event: 'uaevent',
            eventCategory: 'Logo',
            eventAction: 'Redirection::Internal',
            eventLabel: 'Home Page',
        });
        // Reset loading state so that in case of unfinished requests homepage doesn't get stuck with loading
        dispatch(UIDuck.resetLoading());
    };

    const handleMyAccountClick = (openLogoutBox?: boolean) => {
        if (openLogoutBox) setIsLogoutBoxOpen(true);
        pushToDataLayer({
            event: 'uaevent',
            eventCategory: 'Header',
            eventAction: 'Redirection::Internal',
            eventLabel: 'My Account',
        } as GtmData);
    };

    const handleNeedHelpClick = () => {
        dispatch(
            UIDuck.openModal({
                data: {},
                modalType: UIDuck.MODAL_TYPES.NEED_HELP,
                modalVersion: ModalVersion.v2,
                callbacks: { onClose: () => setIsMobileMenuOpen(false) },
            })
        );
        pushToDataLayer({
            event: 'uaevent',
            eventCategory: 'Content',
            eventAction: 'Display::ContactUs',
            eventLabel: t('header.nav.needHelp'),
        } as GtmData);
    };

    const handleBasketRedirect = async () => {
        pushToDataLayer({
            eventCategory: 'Navigation',
            eventAction: 'ViewBasket',
            eventLabel: isStockJourney ? t('header.nav.stock.myBasket') : t('header.nav.myBasket'),
        } as GtmData);
        // when customer clicks icon on config page it should create deal as it is same as continue button
        if (isConfigPage) {
            try {
                createDeal();
            } catch (e: any) {
                /* creating deal can fail without any trouble here - empty basket */
            }
        }
        router.push(routes.BASKET);
    };

    const logoutUser = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsLogoutBoxOpen(false);
        await sessionInfoApi.logout(getSessionIdCookie());
        dispatch(UserDuck.actionSetUserLogged(false));

        if (IS_CLIENT) {
            let redirectUrl = window.location.host;

            if (router.pathname.includes(routes.LOGIN)) {
                redirectUrl = `${redirectUrl}${routes.LOGIN}`;
            } else if (
                router.pathname.includes(routes.BASKET) ||
                router.pathname.includes(routes.CHECKOUT_MY_DETAILS) ||
                router.pathname.includes(routes.CHECKOUT_PRE_ORDER)
            ) {
                redirectUrl = `${redirectUrl}${routes.ROOT}`;
            } else {
                redirectUrl = window.location.href;
            }

            if (isUserLoggedInAsDealer) {
                window.open(SAML_LOGOUT_URL, '_blank');
                Cookies.remove(SESSION_COOKIE_NAME);
            }

            window.location.href = BRANDID_LOGOUT_URL.replace('{redirectUrlLogout}', redirectUrl);
        }
    };

    const renderB2BAndB2CSwitcher = () =>
        isEnvSwitcherEnabled && (
            <div className="toggle-switch-container">
                <div className="b2b-switch switch-vertical">
                    <input onChange={handleSwitchClick} id="toggle-a" type="radio" name="switch" checked={IS_B2C} />
                    <AccessibleButton tabIndex={0}>
                        <label htmlFor="toggle-a">{t('toggle.B2C')}</label>
                    </AccessibleButton>
                    <input onChange={handleSwitchClick} id="toggle-b" type="radio" name="switch" checked={IS_B2B} />
                    <AccessibleButton tabIndex={0}>
                        <label htmlFor="toggle-b">{t('toggle.B2B')}</label>
                    </AccessibleButton>
                    <span className="toggle-outside">
                        <button onClick={handleSwitchClick} className="toggle-inside" />
                    </span>
                </div>
            </div>
        );

    const renderLogoutBox = () =>
        isLogoutBoxOpen && (
            <div ref={logoutRef} className={`logoutBox ${isUserLoggedInAsDealer ? 'without-account' : ''}`}>
                {!isUserLoggedInAsDealer && (
                    <Link
                        href={
                            isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST
                                ? buildLanguagePathname(routes.MY_ACCOUNT)
                                : buildLanguagePathname(routes.LOGIN)
                        }
                    >
                        <a onClick={() => setIsLogoutBoxOpen(false)} className="accountStatement">
                            {t('header.nav.accountStatement')}
                        </a>
                    </Link>
                )}
                <a href="#" onClick={logoutUser} className="logoutButton">
                    {t('header.nav.logout')}
                </a>
            </div>
        );

    useOnClickOutside(logoutRef, () => setIsLogoutBoxOpen(false), []);

    return (
        <div className={className}>
            <div className="header header-container">
                <div className="mobileMenuIcon" onClick={() => setIsMobileMenuOpen(true)}>
                    <Icon name={Icons.Menu} />
                </div>
                <div className={`mobileMenu ${isMobileMenuOpen ? 'isOpen' : 'isClose'}`}>
                    <button className="close" onClick={() => setIsMobileMenuOpen(false)}>
                        &#10005;
                    </button>
                    <Link href={path}>
                        <a className="logo" onClick={() => handleLogoClick()}>
                            <Icon name={Icons.Logo} />
                        </a>
                    </Link>
                    {B2B_URL && B2C_URL && renderB2BAndB2CSwitcher()}
                    <div className="navigation">
                        {isContactDetailEnabled && (
                            <button className="help" onClick={handleNeedHelpClick}>
                                <Icon name={Icons.Phone} />
                                <span className="label">
                                    {t('header.nav.needHelp')}
                                    <span className="phone">{t('support.telephone')}</span>
                                </span>
                            </button>
                        )}
                        <div className="account">
                            <Icon name={Icons.Account} />
                            {!isUserLoggedInAsDealer && (
                                <Link
                                    href={
                                        isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST
                                            ? buildLanguagePathname(routes.MY_ACCOUNT)
                                            : buildLanguagePathname(routes.LOGIN)
                                    }
                                >
                                    <a onClick={() => handleMyAccountClick} className="label">
                                        {isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST
                                            ? t('header.nav.accountStatement')
                                            : t('header.nav.myAccount')}
                                    </a>
                                </Link>
                            )}
                            {isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST && (
                                <span className="link" onClick={logoutUser}>
                                    {t('header.nav.logout')}
                                </span>
                            )}
                        </div>
                        {showBasketInHeader && (
                            <button className="basket" onClick={handleBasketRedirect}>
                                <Icon name={Icons.Basket} />
                                <span className="label">
                                    {isStockJourney ? t('header.nav.stock.myBasket') : t('header.nav.myBasket')}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
                {(isBrandDS || isBrandAC) && !isMobileMenuOpen && B2B_URL && B2C_URL && (
                    <div className="desktopLeftPart">{renderB2BAndB2CSwitcher()}</div>
                )}
                <div className="desktopCenterPart">
                    <div className="logoWrapper">
                        <Link href={path}>
                            <a className="logo" onClick={() => handleLogoClick()}>
                                <Icon name={Icons.Logo} />
                            </a>
                        </Link>
                        {(isBrandAP || isBrandOV) && (
                            <div className="titles">
                                <span>{headerTitle}</span>
                                <h1>{headerSubtitle}</h1>
                            </div>
                        )}
                    </div>
                </div>
                <div className="desktopRightPart">
                    {!isBrandDS && !isBrandAC && isEnvSwitcherEnabled && IS_B2B && B2C_URL && (
                        <RelativeLink href={B2C_URL}>
                            <a className="pro">
                                <Icon name={isBrandDS ? Icons.StoreCarSolid : Icons.StoreCar} />
                                <span className="label">{t('header.nav.b2c')}</span>
                            </a>
                        </RelativeLink>
                    )}
                    {!isBrandDS && !isBrandAC && isEnvSwitcherEnabled && IS_B2C && B2B_URL && (
                        <RelativeLink href={B2B_URL}>
                            <a className="pro">
                                <Icon name={isBrandDS ? Icons.StoreCarSolid : Icons.StoreCar} />
                                <span className="label">{t('header.nav.b2b')}</span>
                            </a>
                        </RelativeLink>
                    )}
                    {isContactDetailEnabled && (
                        <button className="help" onClick={handleNeedHelpClick}>
                            <Icon width={32} height={32} name={isBrandDS ? Icons.HeadsetV2 : Icons.Phone} />
                            <span className={`label ${isBrandDS ? 'isBold' : ''}`}>
                                <span>
                                    {t('header.nav.needHelp')}
                                    {isBrandDS && <IconTemplate width={12} height={12} name={Icons.ChevronRight} />}
                                </span>
                                <span className="phone">{t('support.telephone')}</span>
                            </span>
                        </button>
                    )}
                    <div className="account">
                        {isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST ? (
                            <a onClick={() => handleMyAccountClick(true)}>
                                <Icon name={Icons.Account} />
                                <span className="label">{t('header.nav.myAccount')}</span>
                            </a>
                        ) : (
                            <a href={buildLanguagePathname(routes.LOGIN)} onClick={() => handleMyAccountClick}>
                                <Icon name={Icons.Account} />
                                <span className="label">{t('header.nav.myAccount')}</span>
                            </a>
                        )}
                        {isUserLoggedIn && userAuth?.loginType !== EUserLoginType.GUEST && renderLogoutBox()}
                    </div>
                    {showBasketInHeader && (
                        <button className="basket" onClick={handleBasketRedirect}>
                            <Icon name={Icons.Basket} />
                            <span className="label">
                                {isStockJourney ? t('header.nav.stock.myBasket') : t('header.nav.myBasket')}
                            </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
