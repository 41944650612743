import styled from 'styled-components';
import LoaderOverlayTemplate from './LoaderOverlayTemplate';

const FINANCE_WIDGET_OVERLAY_COLOR = 'rgba(21, 41, 70, 0.9)';

export const LoaderOverlayStyled = styled(LoaderOverlayTemplate)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    background: rgba(200, 200, 200, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .loader,
    .loader:before,
    .loader:after {
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1s infinite ease-in-out;
        animation: load7 1s infinite ease-in-out;
    }
    .loader {
        color: ${({ theme }) => theme.colors.secondary};
        font-size: 10px;
        margin: 80px auto;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
        content: '';
        position: absolute;
        top: 0;
    }
    .loader:before {
        left: -2.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .loader:after {
        left: 2.5em;
    }
    @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
    @keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
`;
