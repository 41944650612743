import styled from 'styled-components';
import { breakpoints, css, theme } from '../../../styles/theme';
import { LegalTermsAndConditionsConsentStyled } from '../LegalTermsAndConditionsConsentStyled';
import { rem } from 'polished';

export const LegalTermsAndConditionsConsentStyledAP = styled(LegalTermsAndConditionsConsentStyled)`
    .section {
        &_title {
            background: ${({ theme }) => theme.colors.grey7};
            span {
                font-size: ${({ theme }) => theme.fontSizes.textTiny};
                text-transform: uppercase;
            }

            .isOpen {
                svg {
                    path {
                        stroke-width: 2;
                        fill: transparent;
                        stroke: ${({ theme }) => theme.colors.primary};
                    }
                }

                color: ${({ theme }) => theme.colors.primary};
            }

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                    stroke-width: 0;
                }
            }
        }

        &_description {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.black};
            line-height: ${rem(14)};
        }

        &_agreements {
            &_agreement {
                input[type='radio'] {
                    width: ${rem(20)} !important;
                    height: ${rem(20)} !important;
                    border-radius: 50% !important;
                }

                input[type='radio']:before {
                    content: '';
                    display: block;
                    width: ${rem(12)};
                    height: ${rem(12)};
                    margin: 20% auto;
                    border-radius: 50%;
                }

                input[type='radio']:checked:before {
                    background: ${({ theme }) => theme.colors.primary};
                }

                input[type='radio']:checked {
                    border-color: ${({ theme }) => theme.colors.primary};
                }

                button {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    color: ${({ theme }) => theme.colors.black};
                }
            }

            &_error {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }
        }
    }

    .legend {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        color: ${({ theme }) => theme.colors.black};
        line-height: ${rem(14)};
    }
`;
