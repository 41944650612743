import styled from 'styled-components';
import { InputRadioStyled } from '../InputRadioStyled';
import { rem } from 'polished';

export const InputRadioStyledAC = styled(InputRadioStyled)`
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.h4};
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
        border: 1px solid ${({ theme }) => theme.colors.grey17};
        background: ${({ theme }) => theme.colors.white};
        width: ${rem(25)};
        height: ${rem(25)};
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
        background: ${({ theme }) => theme.colors.blue2};
        width: ${rem(15)};
        height: ${rem(15)};
    }
`;
