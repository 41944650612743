import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalTrimStyled } from '../ModalTrimStyled';
import { rem } from 'polished';

export const ModalTrimStyledDS = styled(ModalTrimStyled)`
    .trims {
        .sections {
            .trim {
                .trimName {
                    font-size: ${rem(20)};
                }

                .trimLabel {
                    font-size: ${rem(14)};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                .trimDescription {
                    color: ${({ theme }) => theme.colors.black};
                }

                .trimPrice {
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }
            }
        }

        .title {
            font-size: ${rem(30)};
        }
    }

    .keyFeatures,
    .enginesAvailable,
    .stylesAvailable,
    .colors,
    .interiorsAvailable,
    .featuresAvailable {
        .label {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        .sections {
            .keyFeaturesConfig,
            .engines,
            .styles,
            .interiors,
            .features {
                .config,
                .engine,
                .style,
                .interior,
                .feature {
                    .engineDetails {
                        .name {
                            color: ${({ theme }) => theme.colors.grey29};
                        }
                    }
                    .configDetails {
                        color: ${({ theme }) => theme.colors.black};
                    }

                    .name {
                        color: ${({ theme }) => theme.colors.black};
                        line-height: ${rem(18)};
                    }
                }
            }
        }
    }

    .availability {
        font-size: ${rem(14)};

        .icon {
            &.available {
                background: ${({ theme }) => theme.colors.black};
                border-color: ${({ theme }) => theme.colors.black};
            }

            &.optional {
                background: ${({ theme }) => theme.colors.white};
                border-color: ${({ theme }) => theme.colors.black};
            }
        }
    }

    .options {
        .label {
            font-size: ${rem(24)};
        }

        .filter {
            button {
                background: ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.black1};
                border-color: ${({ theme }) => theme.colors.black};
                font-size: ${({ theme }) => theme.fontSizes.h5};
                border-radius: ${rem(24)};
                height: ${rem(48)};

                &.selected {
                    background: ${({ theme }) => theme.colors.grey4};
                    color: ${({ theme }) => theme.colors.black1};
                    border-color: ${({ theme }) => theme.colors.secondary};
                }

                &:hover,
                &:focus-visible {
                    background: ${({ theme }) => theme.colors.grey4};
                    color: ${({ theme }) => theme.colors.primary};
                    border-color: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }

    .iconAvailability {
        &.available {
            background: ${({ theme }) => theme.colors.black};
        }
    }

    .categories {
        border-color: ${({ theme }) => theme.colors.grey9};
    }
`;
