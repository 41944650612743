import React, { FC } from 'react';
import { ReservationFeeProps } from '.';
import useTranslations from '@hooks/useTranslations';
import { publicAssets } from '@utils/url.utils';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';

const ReservationFeeTemplate: FC<ReservationFeeProps> = ({ className, fee }) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();

    return (
        <div className={className}>
            <div className="label">
                <span>
                    {t('checkout.reservationFee.label')} {formatMoney(fee, true)}
                </span>
            </div>
            <div className="paymentMethods">
                <p className="paymentMethods_title">
                    <span>{t('checkout.reservationFee.paymentMethod.title')}</span>
                    <img src={publicAssets(`/images/visa_logo.png`)} />
                    <img src={publicAssets(`/images/mastercard_logo.png`)} />
                </p>
                <p className="paymentMethods_description">{t('checkout.reservationFee.paymentMethod.description')}</p>
            </div>
            <div className="description">{t('checkout.reservationFee.description')}</div>
        </div>
    );
};

export default ReservationFeeTemplate;
