import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import ModalFeatureDetailTemplate from './ModalFeatureDetailTemplate';

export const ModalFeatureDetailStyled = styled(ModalFeatureDetailTemplate)`
    .backButton {
        margin-top: -${rem(50)};
        cursor: pointer;
        
        svg {
            width: ${rem(40)};
            height: ${rem(40)};
        }
    }
    
    .title {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${rem(18)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        padding: ${rem(25)} 0;
    }
    
    .description {
        font-size: ${rem(14)};
        color: ${({ theme }) => theme.colors.grey31};
    }
    
    .image {
        img {
            width: 100%;
        }
    }
`;
