import styled from 'styled-components';
import { ModalLeadFormStyled } from '../../ModalLeadFormStyled';
import { rem } from 'polished';

export const ModalLeadFormStyledOV = styled(ModalLeadFormStyled)`
    .btn-primary {
        width: 100%;
        text-decoration: none;
        padding: ${rem(6)} 0;

        &:hover,
        &:focus-visible {
            background: ${({ theme }) => theme.colors.secondary};
            color: ${({ theme }) => theme.colors.white};
        }
    }

    .legal_description {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        line-height: ${rem(18)};
        margin: ${rem(32)} 0 ${rem(16)} 0;
    }
    .legal_agreement_title {
        font-size: ${rem(16)};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey11};
        padding: ${rem(16)};
        cursor: pointer;
        margin: 0;

        &.isOpen {
            .isOpen {
                display: block;
            }

            .isClose {
                display: none;
            }
        }

        &.isClose {
            .isOpen {
                display: none;
            }

            .isClose {
                display: block;
            }
        }
    }
    .legal_agreement_description {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        padding: 0 ${rem(16)} ${rem(20)} ${rem(16)};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey11};
    }
    .agreement1 div div,
    .agreement2 div div,
    .agreement3 div div {
        display: flex;
        gap: ${rem(30)};
    }
    .form-field {
        svg {
            width: ${rem(20)};
            height: ${rem(20)};
            position: absolute;
            right: ${rem(16)};
            top: ${rem(12)};

            path {
                fill: ${({ theme }) => theme.colors.primary};4
            }
        }
    }

    .radiocheckbox-container {
        input[type='radio']:checked {
            background: ${({ theme }) => theme.colors.white};
            border-color: ${({ theme }) => theme.colors.primary};

            &::before {
                background-color: ${({ theme }) => theme.colors.primary};
                width: ${rem(10)};
                height: ${rem(10)};
                top: ${rem(4)};
                left: ${rem(4)};
            }
        }

        input[type='checkbox']:checked {
            background: ${({ theme }) => theme.colors.primary};
            border-color: ${({ theme }) => theme.colors.primary};

            &::before {
                content: '';
                border: 2px solid;
                border-left: 0;
                border-top: 0;
                height: ${rem(12)};
                left: ${rem(6)};
                position: absolute;
                top: ${rem(2)};
                transform: rotate(45deg);
                transition: opacity 0.2s ease-in-out;
                width: ${rem(6)};
                color: ${({ theme }) => theme.colors.white};
            }
        }
    }
`;
