import { css } from 'src/styles/theme';

export const StylesAC = css`
    background: ${({ theme }) => theme.colors.grey6};

    button {
        border-color: ${({ theme }) => theme.colors.primary} !important;
        color: ${({ theme }) => theme.colors.primary};
    }
`;
