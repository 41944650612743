import styled from 'styled-components';
import { rem } from 'polished';
import { ModalPersonalizeOfferStyled } from '../ModalPersonalizeOfferStyled';

export const ModalPersonalizeOfferStyledAC = styled(ModalPersonalizeOfferStyled)`
    .section {
        border-color: ${({ theme }) => theme.colors.grey2};

        &:last-child {
            border-color: ${({ theme }) => theme.colors.grey2};
        }

        &_title {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            color: ${({ theme }) => theme.colors.grey5};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.grey5};
                }
            }
        }

        .row {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            color: ${({ theme }) => theme.colors.grey5};

            &_error {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .datePickerSection {
            input {
                width: 100%;
                border: 1px solid ${({ theme }) => theme.colors.grey3};
                color: ${({ theme }) => theme.colors.grey1};
                font-size: ${({ theme }) => theme.fontSizes.h4};
                border-radius: ${rem(12)};
            }

            .validityError {
                font-size: ${({ theme }) => theme.fontSizes.h6};
            }
        }

        .option {
            background: #ebf4f9;
            border: 2px solid ${({ theme }) => theme.colors.blue2};
            border-radius: ${rem(12)};

            &_header {
                label {
                    font-size: ${({ theme }) => theme.fontSizes.h4};
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                }

                svg {
                    margin-bottom: ${rem(10)};
                }
            }
        }

        .mandatory-label {
            color: ${({ theme }) => theme.colors.grey1};
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }

        .reset-button {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            color: ${({ theme }) => theme.colors.grey5};
        }
    }
`;
