import styled from 'styled-components';
import { breakpoints, css, theme } from '../../../styles/theme';
import { LegalTermsAndConditionsConsentStyled } from '../LegalTermsAndConditionsConsentStyled';
import { rem } from 'polished';

export const LegalTermsAndConditionsConsentStyledAC = styled(LegalTermsAndConditionsConsentStyled)`
    .legend {
        padding-bottom: ${rem(4)};
    }
`;
