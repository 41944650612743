import React from 'react';

import { BRAND, isMarketGB } from '../../../constants/main';
import { ModalFeatureDetailStyledAP } from './AP/ModalFeatureDetailStyled.AP';
import { ModalFeatureDetailStyledDS } from './DS/ModalFeatureDetailStyled.DS';
import { ModalFeatureDetailStyledAC } from './AC/ModalFeatureDetailStyled.AC';
import { ModalFeatureDetailStyledOV } from './OV/ModalFeatureDetailStyled.OV';
import { ModalFeatureDetailStyledOV as ModalFeatureDetailStyledOVGB } from './OV/GB/ModalFeatureDetailStyled.OV';
import { ModalFeatureDetailStyled } from './ModalFeatureDetailStyled';

export const ModalFeature = (props: any) => {
    if (BRAND === 'AP') return <ModalFeatureDetailStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalFeatureDetailStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalFeatureDetailStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <ModalFeatureDetailStyledOVGB {...props} /> : <ModalFeatureDetailStyledOV {...props} />;
    return <ModalFeatureDetailStyled {...props} />;
};

export default ModalFeature;
