import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalRemovePxConfirmationStyledAC } from './AC/ModalRemovePxConfirmationStyled.AC';
import { ModalRemovePxConfirmationStyledAP } from './AP/ModalRemovePxConfirmationStyled.AP';
import { ModalRemovePxConfirmationStyledDS } from './DS/ModalRemovePxConfirmationStyled.DS';
import { ModalRemovePxConfirmationStyledOV } from './OV/ModalRemovePxConfirmationStyled.OV';
import { ModalRemovePxConfirmationStyledOV as ModalIncompletePxStyledOVGB } from './OV/GB/ModalRemovePxConfirmationStyled.OV';

export interface IModalRemovePxConfirmation extends SC {
    onConfirmClick: any;
}

export const ModalRemovePxConfirmation = (props: IModalRemovePxConfirmation) => {
    if (BRAND === 'AP') return <ModalRemovePxConfirmationStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalRemovePxConfirmationStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalRemovePxConfirmationStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalIncompletePxStyledOVGB {...props} /> : <ModalRemovePxConfirmationStyledOV {...props} />;
};
