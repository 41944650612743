import styled from 'styled-components';
import { CheckoutDeliveryStyled } from '../CheckoutDeliveryStyled';
import { rem } from 'polished';
import { breakpoints } from '../../../styles/AC/theme';

export const CheckoutDeliveryStyledDS = styled(CheckoutDeliveryStyled)`
    .dealerSearch {
        input {
            width: 100%;
        }

        button {
            font-size: ${rem(14)};
            border: 1px solid ${({ theme }) => theme.colors.primary};
            padding: ${rem(8)} ${rem(15)};

            :hover {
                background: ${({ theme }) => theme.colors.brown4};
                border-color: ${({ theme }) => theme.colors.brown4};
            }
        }

        &__input {
            font-size: ${rem(14)};

            ::before {
                background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjJwdCIgaGVpZ2h0PSIyMnB0IiB2aWV3Qm94PSIwIDAgMjIgMjIiIHZlcnNpb249IjEuMSI+CjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoNjEuNTY4NjI3JSw1NC45MDE5NjElLDUxLjM3MjU0OSUpO2ZpbGwtb3BhY2l0eToxOyIgZD0iTSAyMS44NTU0NjkgMjEuMTY0MDYyIEwgMTQuNDI5Njg4IDEzLjczNDM3NSBDIDE1LjcyNjU2MiAxMi4yNzczNDQgMTYuNTE5NTMxIDEwLjM2MzI4MSAxNi41MTk1MzEgOC4yNjE3MTkgQyAxNi41MTk1MzEgMy43MDcwMzEgMTIuODE2NDA2IDAgOC4yNjE3MTkgMCBDIDMuNzA3MDMxIDAgMCAzLjcwNzAzMSAwIDguMjYxNzE5IEMgMCAxMi44MTY0MDYgMy43MDcwMzEgMTYuNTE5NTMxIDguMjYxNzE5IDE2LjUxOTUzMSBDIDEwLjM1OTM3NSAxNi41MTk1MzEgMTIuMjczNDM4IDE1LjcyNjU2MiAxMy43MzQzNzUgMTQuNDI5Njg4IEwgMjEuMTYwMTU2IDIxLjg1NTQ2OSBDIDIxLjI1NzgxMiAyMS45NTMxMjUgMjEuMzgyODEyIDIyIDIxLjUwNzgxMiAyMiBDIDIxLjYzMjgxMiAyMiAyMS43NjE3MTkgMjEuOTUzMTI1IDIxLjg1NTQ2OSAyMS44NTU0NjkgQyAyMi4wNDY4NzUgMjEuNjY0MDYyIDIyLjA0Njg3NSAyMS4zNTU0NjkgMjEuODU1NDY5IDIxLjE2NDA2MiBaIE0gOC4yNjE3MTkgMTUuNTM5MDYyIEMgNC4yNDYwOTQgMTUuNTM5MDYyIDAuOTgwNDY5IDEyLjI3MzQzOCAwLjk4MDQ2OSA4LjI2MTcxOSBDIDAuOTgwNDY5IDQuMjQ2MDk0IDQuMjQ2MDk0IDAuOTgwNDY5IDguMjYxNzE5IDAuOTgwNDY5IEMgMTIuMjczNDM4IDAuOTgwNDY5IDE1LjUzOTA2MiA0LjI0NjA5NCAxNS41MzkwNjIgOC4yNjE3MTkgQyAxNS41MzkwNjIgMTAuMDg5ODQ0IDE0Ljg1NTQ2OSAxMS43NjE3MTkgMTMuNzM0Mzc1IDEzLjA0Mjk2OSBDIDEzLjUxOTUzMSAxMy4yODkwNjIgMTMuMjg5MDYyIDEzLjUxOTUzMSAxMy4wNDI5NjkgMTMuNzM0Mzc1IEMgMTEuNzYxNzE5IDE0Ljg1NTQ2OSAxMC4wODk4NDQgMTUuNTM5MDYyIDguMjYxNzE5IDE1LjUzOTA2MiBaIE0gOC4yNjE3MTkgMTUuNTM5MDYyICIvPgo8L3N2Zz4=')
                    no-repeat;
                background-size: contain;
            }
        }
    }

    .dealerItem {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
        margin-right: ${rem(10)};
        margin-bottom: ${rem(15)};
        padding-bottom: ${rem(10)};

        &__button {
            width: ${rem(125)};
            padding: ${rem(5)};
            border: 1px solid ${({ theme }) => theme.colors.grey15};
            font-size: ${rem(14)};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            color: ${({ theme }) => theme.colors.grey15};

            &:hover {
                background-color: ${({ theme }) => theme.colors.grey14};
                border: 1px solid ${({ theme }) => theme.colors.grey14};
                color: ${({ theme }) => theme.colors.grey15};
            }
        }

        &__button {
            font-size: ${rem(10)};
            font-family: DSAutomobiles;
            color: ${({ theme }) => theme.colors.black3};
        }

        &__footer {
            .btnSelected {
                color: black;
                font-weight: bold;
                background-color: ${({ theme }) => theme.colors.white};
                border: 2px solid ${({ theme }) => theme.colors.primary};
                position: relative;

                &::after {
                    content: '✓';
                    position: absolute;
                    top: 50%;
                    right: ${rem(5)};
                    transform: translateY(-50%);
                    width: 15px;
                    height: 15px;
                    font-size: ${rem(15)};
                    color: ${({ theme }) => theme.colors.lozenge};
                }
            }
        }

        &__icon {
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxNCAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMEMzLjEzMzU5IDAgMCAzLjEyNDQgMCA2Ljk3OTQ3QzAgMTEuNzM0MiA1LjM2NDg0IDEzLjQ2ODIgNi41MzI0MiAyMC44MzQ4QzYuNjA4OTggMjEuMzA5MiA2LjYxNDQ1IDIxLjYzNjQgNyAyMS42MzY0QzcuMzg1NTUgMjEuNjM2NCA3LjM5MTAyIDIxLjMwOTIgNy40Njc1OCAyMC44MzQ4QzguNjM1MTYgMTMuNDY4MiAxNCAxMS43MzQyIDE0IDYuOTc5NDdDMTQgMy4xMjQ0IDEwLjg2NjQgMCA3IDBaIiBmaWxsPSIjOUQ4QzgzIi8+Cjwvc3ZnPg==')
                top center no-repeat;
        }

        &__address {
            p {
                margin: 0;
                line-height: ${rem(18)};
                font-size: ${rem(14)};
            }

            p:first-child {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                margin-bottom: ${rem(10)};
            }
        }

        &__distance {
            p {
                text-align: center;
                font-size: ${rem(14)};
                line-height: ${rem(16)};
            }

            p:first-child {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }
    }

    .selectedDealer {
        &__header {
            span:first-child {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjY2OTYgMTEuMzAxNkwwIDQuNjMyTDEuMTg4IDMuNDQ0TDYuNjY5NiA4LjkyOEwxNS42IDBMMTYuNzg1NiAxLjE4OEw2LjY2OTYgMTEuMzAxNloiIGZpbGw9IiNCRDE4MjYiLz4KPC9zdmc+Cg==')
                    no-repeat;
            }
        }

        &__header {
            span {
                font-size: ${rem(16)};
            }

            span:first-child {
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjY2OTYgMTEuMzAxNkwwIDQuNjMyTDEuMTg4IDMuNDQ0TDYuNjY5NiA4LjkyOEwxNS42IDBMMTYuNzg1NiAxLjE4OEw2LjY2OTYgMTEuMzAxNloiIGZpbGw9IiNCRDE4MjYiLz4KPC9zdmc+Cg==')
                    no-repeat;
                display: inline-block;
                margin-top: ${rem(15)};
            }
        }

        &__loadingButton.SOL {
            margin-left: ${rem(36)};
            font-size: ${rem(16)};
        }

        &__loadingButton,
        &__loadingButton button {
            @media (max-width: ${breakpoints.xs}px) {
                width: 100%;
            }
        }
    }

    .dealerSearch {
        @media (max-width: ${breakpoints.xs}px) {
            flex-direction: column;
            margin-bottom: ${rem(50)};
        }

        &__input {
            @media (max-width: ${breakpoints.xs}px) {
                margin-bottom: ${rem(10)};
            }
        }
    }
`;
