import React, { FC, memo } from 'react';
import { HandledComponentError } from '@components/ErrorHandling';
import { useTranslations } from '@hooks/useTranslations';

export const IncorrectEmailMessage: FC = memo(() => {
    const { t } = useTranslations();
    try {
        return (
            <div className="formRow">
                <span className="error">{t('shareConfiguration.wrongEmail')}</span>
            </div>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});

IncorrectEmailMessage.displayName = 'IncorrectEmailMessage';
