import React from 'react';
import { SC } from '../../styles/theme';
import { BRAND, isMarketGB } from '../../constants/main';
import { CheckoutCarMainInfoStyledAC } from './AC/CheckoutCarMainInfoStyled.AC';
import { CheckoutCarMainInfoStyledOV } from './OV/CheckoutCarMainInfoStyled.OV';
import { CheckoutCarMainInfoStyledGBOV } from './OV/GB/CheckoutCarMainInfoStyled.OV';
import { CheckoutCarMainInfoStyledAP } from './AP/CheckoutCarMainInfoStyled.AP';
import { CheckoutCarMainInfoStyledDS } from './DS/CheckoutCarMainInfoStyled.DS';
import { CheckoutCarMainInfoStyled } from './CheckoutCarMainInfoStyled';
import { ICarComment, IConfigurableCar, IDeal } from '../../interfaces/Car';
import { Dispatch } from 'redux';
import { IDealer } from '../../interfaces/Dealer';
import { IMyOrder } from '../../redux/user/user.duck.interface';
import { AxiosPromise } from 'axios';

export interface ICarMainInfoProps {
    carConfigData: IConfigurableCar;
    isAccordionOpen?: boolean;
    isExtendedViewOpen?: boolean;
    financeQuote: any;
    existingLanguage: string;
    dispatch?: Dispatch;
    isPreOrder?: boolean;
    dealer: IDealer;
    myAccountCarDetails?: any;
    dealerId?: string;
    isExpired?: boolean;
    offerEnd?: string;
    isCheckout?: boolean;
    isMyOrderPage?: boolean;
    dealerDiscount?: number;
    dealId?: string;
    actionSetActiveDeal?: (dealToken: string) => AxiosPromise<IDeal<IConfigurableCar>>;
    comments?: ICarComment[];
    isParent?: boolean;
    financeQuoteId?: string;
    mopId?: string;
    dealerPx?: string;
    displayPx?: boolean;
    journeyType?: string;
    status?: string;
    order?: IMyOrder;
    displayDealerInfo?: boolean;
}

export type Props = SC & ICarMainInfoProps;

const CheckoutCarMainInfo = (props: Props) => {
    if (BRAND === 'OV')
        return isMarketGB ? <CheckoutCarMainInfoStyledGBOV {...props} /> : <CheckoutCarMainInfoStyledOV {...props} />;
    if (BRAND === 'AC') return <CheckoutCarMainInfoStyledAC {...props} />;
    if (BRAND === 'AP') return <CheckoutCarMainInfoStyledAP {...props} />;
    if (BRAND === 'DS') return <CheckoutCarMainInfoStyledDS {...props} />;
    return <CheckoutCarMainInfoStyled {...props} />;
};

export default CheckoutCarMainInfo;
