import styled from 'styled-components';
import { breakpoints, css, theme } from '../../../styles/theme';
import { LegalTermsAndConditionsConsentStyled } from '../LegalTermsAndConditionsConsentStyled';
import { rem } from 'polished';

export const LegalTermsAndConditionsConsentStyledDS = styled(LegalTermsAndConditionsConsentStyled)`
    .section {
        &_title {
            padding: ${rem(12)} 0;
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};

            span {
                text-transform: uppercase;
            }

            .isOpen {
                svg {
                    path {
                        stroke-width: 2;
                        fill: transparent;
                        stroke: ${({ theme }) => theme.colors.black};
                    }
                }
            }

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                    stroke-width: 0;
                }
            }
        }

        &_description {
            color: ${({ theme }) => theme.colors.black};
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
            padding: ${rem(8)} ${rem(16)} ${rem(16)} ${rem(16)};
        }

        &_agreements {
            &_agreement {
                input {
                    accent-color: ${({ theme }) => theme.colors.primary};
                }

                button {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }
    }

    .legend {
        color: ${({ theme }) => theme.colors.black};
    }
`;
