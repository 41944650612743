import React, { FunctionComponent } from 'react';

import { ICarDetailsFinanceQuoteOptionsProps } from './ICarDetailsOptions';
import { ICarOptionFinanceQuote } from '../../CheckoutMySummary/interfaces';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';

const CheckoutCarDetailsFinanceQuoteOptions: FunctionComponent<ICarDetailsFinanceQuoteOptionsProps> = ({
    financeQuote,
    className,
}: ICarDetailsFinanceQuoteOptionsProps) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();

    return (
        <div className={className}>
            <div className="optionsWrapper">
                <div className="detailsContent">
                    {Object.keys(financeQuote.options)?.length > 0 && (
                        <>
                            <p className="optionsWrapperTitle">{t('checkout.carConfig.options')}</p>
                            {Object.keys(financeQuote.options).map((key: string, index: number) => {
                                const option: ICarOptionFinanceQuote = financeQuote.options[key];

                                return (
                                    <p key={index} className="paragraph">
                                        <span>{option.title}</span>

                                        {option.priceAdjustment &&
                                        typeof option.priceAdjustment.amountInclTax !== 'undefined' ? (
                                            <span>
                                                {formatMoney(option.priceAdjustment.amountInclTax, true)}
                                                {t('checkout.label.currency.per.month')}
                                            </span>
                                        ) : (
                                            <span>{t('checkout.carConfig.price.undefined')}</span>
                                        )}
                                    </p>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CheckoutCarDetailsFinanceQuoteOptions;
