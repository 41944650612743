import styled from 'styled-components';
import { css } from '../../../styles/theme';
import { LinkStyled } from '../LinkStyled';
import { rem } from 'polished';

export const LinkStyledAP = styled(LinkStyled)`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    font-weight: ${({ theme }) => theme.fontWeights.textRegular};

    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.primary};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.black};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.black};
                    }
                }
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            color: ${({ theme }) => theme.colors.black};
            text-decoration: underline;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        `}

    svg {
        width: ${rem(9)};
        height: ${rem(9)};
    }
`;
