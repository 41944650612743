import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslations';
import Button from '../Button';
import {useMoneyFormatter} from "@hooks/useMoneyFormatter";
import {useCarDetailsDuck} from "@hooks/useCarDetailsDuck";
import {useGetNotEscapedTranslationMarkup} from "@hooks/useGetNotEscapedTranslationMarkup";
import {isBrandAC} from "../../constants/main";

interface IModalScrappageDeal {
    onSubmit: (isWithScrappage: boolean) => void;
    onlyInfo?: boolean;
}

export const ModalScrappage: React.FC<IModalScrappageDeal> = ({ onSubmit, onlyInfo }) => {
    const { t } = useTranslations();

    const { getNotEscapedTranslationMarkup } = useGetNotEscapedTranslationMarkup();

    const { formatMoney } = useMoneyFormatter();

    const { carDetails } = useCarDetailsDuck();

    const brandEcoBonus = carDetails.importInputs.brandEcologicalBonus;
    const ecoBonusWithScrappage = carDetails.importInputs.ecologicalBonusScrappage;
    const ecoBonusWithoutScrappage = carDetails.importInputs.ecologicalBonus - carDetails.importInputs.ecologicalBonusScrappage;

    return (
        <Wrapper>
            {!onlyInfo &&
                <>
                    <Price>
                        <span>{t('basket.scrappage.ecoBonus.value')}</span>
                        <span className="price">{formatMoney(ecoBonusWithoutScrappage + brandEcoBonus, true)}</span>
                    </Price>
                    <Price>
                        <span>{t('basket.scrappage.scrappage.value')}</span>
                        <span className="price">{formatMoney(ecoBonusWithScrappage, true)}</span>
                    </Price>
                    <Total>
                        <span>{t('basket.scrappage.total.value')}</span>
                        <span className="price">{formatMoney(ecoBonusWithScrappage + ecoBonusWithoutScrappage + brandEcoBonus, true)}</span>
                    </Total>
                </>
            }
            <Description
                dangerouslySetInnerHTML={getNotEscapedTranslationMarkup(
                    'dialog.scrappage.description'
                )}
            />
            {!onlyInfo && <Agreement>{t('dialog.scrappage.agreement')}</Agreement>}
            {!onlyInfo &&
                <ButtonWrapper>
                    <Button onClick={() => onSubmit(false)} primary={isBrandAC} secondary={!isBrandAC} withoutBackground={isBrandAC}>{t('dialog.scrappage.withoutScrappage')}</Button>
                    <Button onClick={() => onSubmit(true)} primary>
                        {t('dialog.scrappage.withScrappage')}
                    </Button>
                </ButtonWrapper>
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${rem(15)};

    button {
        width: ${rem(100)};
    }
`;
const Agreement = styled.p`
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${rem(14)};
    padding-bottom: ${rem(24)};
`;
const Description = styled.p`
    font-size: ${rem(12)};
    padding-bottom: ${rem(20)};
`;
const Price = styled.p`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-top: 1px solid ${({ theme }) => theme.colors.grey25};
    padding-top: ${rem(15)};
`;
const Total = styled(Price)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};
    padding-bottom: ${rem(15)};
    font-weight: 700;
    margin-bottom: ${rem(30)};
`;
