import styled from 'styled-components';
import { ModalSessionChangeStyled } from '../ModalSessionChangeStyled';
import { rem } from 'polished';

export const ModalSessionChangeStyledAP = styled(ModalSessionChangeStyled)`
    .buttons {
        .spec {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
        }

        .name {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        }

        .image {
            width: ${rem(220)};
        }
    }

    .title {
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }
`;
