import React, { FC } from 'react';
import { connect } from 'react-redux';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { LoadingButtonProps } from '.';
import Button from '../Button';
import Spinner from '../Spinner';
import { Redux } from 'src/redux/redux.interface';
import { isBrandOV, isMarketES, isMarketFR } from 'src/constants/main';

const LoadingButtonTemplate: FC<LoadingButtonProps> = ({ className, isLoading, disabled = false, ...props }) => (
    <div className={className}>
        {isLoading && (
            <div className="loading">
                <Spinner size={26} border={5} color={(isMarketES || isMarketFR) && isBrandOV ? 'black' : 'white'} />
            </div>
        )}
        <Button {...props} disabled={isLoading || disabled} />
    </div>
);

const mapStateToProps = (state: Redux) => ({
    isLoading: UIDuck.isLoading(state),
});

export default connect(mapStateToProps)(LoadingButtonTemplate);
