import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import GeoLocationInputTemplate from './GeoLocationInputTemplate';
import { rem } from 'polished';

export const GeoLocationInputStyled = styled(GeoLocationInputTemplate)`
    position: relative;

    @media (min-width: ${breakpoints.sm}px) {
        padding: 0;
    }

    .geolocation-title {
        text-transform: uppercase;
        line-height: ${rem(20)};
        padding-bottom: ${rem(10)};
        display: block;

        @media (min-width: ${breakpoints.md}px) {
            display: none;
            padding: 0;
        }
    }

    .geolocation-input--container {
        display: flex;
        align-items: stretch;
        min-height: 3rem;

        .geolocation-input--textfield-container {
            display: flex;
            flex: 1 1 auto;
            position: relative;

            input {
                width: 100%;
                border: 1px solid ${({ theme }) => theme.colors.grey4};
                padding-right: ${rem(40)};
                padding-left: ${rem(10)};
                font-size: ${rem('14px')};
            }

            .dropDown {
                position: absolute;
                top: ${rem(48)};
                background: ${({ theme }) => theme.colors.white};
                z-index: 9;
                right: 0;
                width: 100%;

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    border: 1px solid ${({ theme }) => theme.colors.grey4};

                    li {
                        cursor: pointer;
                        padding: ${rem(15)} ${rem(10)};
                        color: ${({ theme }) => theme.colors.blue7};
                        cursor: pointer;
                        font-size: ${rem(12)};
                        background-color: ${({ theme }) => theme.colors.white};

                        :hover,
                        :focus-visible {
                            background-color: ${({ theme }) => theme.colors.brown2};
                        }
                    }
                }
            }

            &.isModal {
                display: flex;
                flex-direction: column;

                input {
                    height: 48px;
                }

                .dropDown {
                    position: relative;
                    top: 0;
                    max-height: 200px;
                    overflow-y: scroll;
                }
            }

            .geolocation-input--textfield-icon {
                color: ${({ theme }) => theme.colors.primary};
                position: absolute;
                right: ${rem(10)};
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;

                &-error {
                    color: ${({ theme }) => theme.colors.red1};
                }
            }

            &-error input {
                border: 1px solid ${({ theme }) => theme.colors.red1};
            }
        }

        .is-stock-slice--select-container > div {
            min-width: ${rem('160px')};
        }

        .geolocation-input--select-container {
            display: flex;
            flex: 0 0 ${rem(100)};

            & > * {
                padding-right: 0;
                width: 100%;
            }

            .Select__control {
                background: ${({ theme }) => theme.colors.primary};
                border: 1px solid ${({ theme }) => theme.colors.primary};
                border-radius: 0;
                height: 100%;
                display: flex;
                justify-content: space-between;
                padding: ${rem(5)};
            }

            .Select__indicators {
                padding: 0;
                margin-right: ${rem(8)};
            }

            .Select__value-container {
                display: flex;
                justify-content: center;
            }

            .Select__single-value {
                color: ${({ theme }) => theme.colors.white};
                margin: 0;
            }

            .Select__indicator {
                padding: ${rem(5)};
                color: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .geolocation-input--error-message {
        margin-top: ${rem(5)};
        font-size: 0.8rem;
        color: ${({ theme }) => theme.colors.red1};
        margin-left: ${rem(120)};
    }
`;
