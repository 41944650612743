import React, { FC, memo, useRef, useEffect, useState } from 'react';
import FaqItem from './FaqItem';
import { useDispatch } from 'react-redux';
import { IFAQ } from 'src/redux/content/content.duck.interface';
import UIDuck from 'src/redux/commonDucks/ui.duck';

interface IFAQCategory {
    category: IFAQ;
    isOpen?: boolean;
    index: number;
}

export const FAQCategory: FC<IFAQCategory> = memo(({ category, isOpen, index }) => {
    const dispatch = useDispatch();
    const elementRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (isOpen && elementRef) {
            dispatch(UIDuck.setScrollPosition(elementRef));
        }
    }, [category.id, isOpen, elementRef, dispatch]);

    const [opened, setOpened] = useState(isOpen);

    const handleOpen = () => {
        setOpened(true);
    };

    const handleClose = () => {
        setOpened(false);
    };

    const applyAccessibility = (content: string = ''): string => {
        return content.replace(/\<a\s/gi, `<a tabindex="${opened ? 0 : -1}" `);
    };

    return (
        <FaqItem
            tabIndex={0}
            trigger={category.title}
            className="faqCategory"
            id={`TESTING_FAQS_TITLE_${index}`}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            isRoot={true}
        >
            <div
                ref={elementRef}
                className="categoryContent"
                dangerouslySetInnerHTML={{ __html: applyAccessibility(category.content) }}
                data-testid={`TESTING_FAQS_DESCRIPTION_${index}`}
            />
            {category.items.map(
                (item, itemIndex: number) =>
                    item.title && (
                        <FaqItem
                            tabIndex={opened ? 0 : -1}
                            key={`${category.id}_${index}`}
                            trigger={item.title}
                            id={`TESTING_FAQS_SUBTITLE_${index}_${itemIndex}`}
                            open={isOpen && index === 0}
                        >
                            <div dangerouslySetInnerHTML={{ __html: applyAccessibility(item.content) }} />
                        </FaqItem>
                    )
            )}
        </FaqItem>
    );
});
FAQCategory.displayName = 'FAQCategory';
