import { BRAND } from '../../../constants/main';
import { ModalDealerSearchStyledAP } from './AP/ModalDealerSearchStyled.AP';
import { ModalDealerSearchStyledDS } from './DS/ModalDealerSearchStyled.DS';
import { ModalDealerSearchStyledAC } from './AC/ModalDealerSearchStyled.AC';
import { ModalDealerSearchStyledOV } from './OV/ModalDealerSearchStyled.OV';
import { ModalDealerSearchStyled } from './ModalDealerSearchStyled';
import React from 'react';

export const ModalDealerSearch = (props: any) => {
    if (BRAND === 'AP') return <ModalDealerSearchStyledAP {...props} />;
    if (BRAND === 'DS') return <ModalDealerSearchStyledDS {...props} />;
    if (BRAND === 'AC') return <ModalDealerSearchStyledAC {...props} />;
    if (BRAND === 'OV') return <ModalDealerSearchStyledOV {...props} />;
    return <ModalDealerSearchStyled {...props} />;
};

export default ModalDealerSearch;
