import styled from 'styled-components';
import AlertTemplate from './AgentToolBarTemplate';
import { rem } from 'polished';

export const AgentToolBarStyled = styled(AlertTemplate)`
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    height: ${rem(36)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    top: 0;
    position: sticky;
    z-index: 9999999;

    .message {
        padding-right: ${rem(6)};
    }

    .name {
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    .logoutButton {
        background: none;
        border: none;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.white};
        margin-left: ${rem(12)};
        padding: 0 0 ${rem(2)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    }
`;
