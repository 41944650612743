import styled from 'styled-components';
import { CheckoutPersonalDetailsStyled } from '../CheckoutPersonalDetailsStyled';

export const CheckoutPersonalDetailsStyledAP = styled(CheckoutPersonalDetailsStyled)`
    .Collapsible__trigger {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .summaryRow {
        .left,
        .right {
            .summaryRowInfo,
            span {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }

            .summaryRowLabel {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }
    }
`;
