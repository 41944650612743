import styled from 'styled-components';
import { breakpoints, theme } from '../../styles/theme';
import { FooterTemplate } from './FooterTemplate';
import media from '../../styles/media';
import { rem } from 'polished';

export const FooterStyled = styled(FooterTemplate)`
    .orderNowSection {
        background: ${({ theme }) => theme.colors.accordionSecondaryBg};
        padding: 2.5rem 0 3rem;

        ${media.sm`
            padding: 4.5rem 0;
        `}

        .orderNowTitle {
            margin: 0;
            padding-bottom: 2rem;
            text-align: center;
            text-transform: uppercase;
            font-size: ${({ theme }) => theme.fontSizes.h3};
            line-height: ${({ theme }) => theme.lineHeights.h3};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: normal;

            @media (min-width: ${breakpoints.sm}px) {
                padding-bottom: 3.7rem;
                font-size: ${rem(32)};
                line-height: ${rem(36)};
            }
        }

        .itemsWrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .footerItem {
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                flex-basis: 100%;
                align-items: center;

                @media (min-width: ${breakpoints.md}px) {
                    flex-direction: column;
                    flex-basis: ${rem(200)};
                    max-width: ${rem(150)};
                }

                .imageWrap {
                    padding-right: 1.8rem;
                    width: ${rem(50)};
                    height: ${rem(50)};
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;

                    ${media.md`
                        padding-right: 0;
                    `}

                    img {
                        min-height: 5rem;
                    }
                }

                .textWrap {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    text-align: left;
                    align-items: flex-end;
                    display: flex;
                    flex: 1 1;

                    ${media.sm`
                        text-align: center;
                    `}
                }
            }
        }
    }

    .scrollToTopIcon {
        display: none;
        background: ${({ theme }) => theme.colors.primary};
        width: ${rem(60)};
        height: ${rem(60)};
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        right: ${rem(30)};
        top: ${rem(-30)};

        svg {
            width: ${rem(25)};
            height: ${rem(25)};
            color: ${theme.colors.white};
        }

        ${media.sm`
            display: flex;
        `}
    }

    .footerScrollToTopContainer {
        display: block;
        width: auto;
        background: ${({ theme }) => theme.colors.white};
        position: relative;
        text-align: center;
        text-transform: uppercase;
        padding: ${rem(15)};
        cursor: pointer;
        ${media.sm`
            display:none;
        `}
    }
    .footerScrollToTopAnchor {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        position: relative;
        font-size: ${({ theme }) => theme.fontSizes.h5};

        svg {
            height: ${rem(7)};
            width: ${rem(12)};
            margin-left: ${rem(10)};

            path {
                stroke: ${({ theme }) => theme.colors.blue7};
                stroke-linejoin: miter;
                stroke-width: ${rem(4.5)};
                fill: none;
            }
        }
    }

    .footer {
        background: ${({ theme }) => theme.colors.default};
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        position: relative;

        .logo {
            display: none;
        }

        ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0;
            margin: 0;
            flex-direction: column;

            ${media.sm`
                flex-direction: row;
            `}

            li {
                text-align: center;
                list-style: none;
                margin: 0;
                padding: 0;
                border-right: 0 solid ${({ theme }) => theme.colors.white};

                ${media.sm`
                    display: block;
                    border-right-width: 1px;
                    margin: 0 1rem 0 0;
                    padding: 0 1rem 0 0;
                `}

                &:last-of-type {
                    border-right: none;
                }

                &.divider {
                    color: ${({ theme }) => theme.colors.white};
                    padding-bottom: 0.35rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: ${rem(4)};
                    margin-bottom: ${rem(3)};

                    ${media.sm`
                        display:none;
                    `}
                }

                a {
                    font-family: ${({ theme }) => theme.fonts.fontBase};
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    line-height: ${({ theme }) => theme.lineHeights.textBase};
                    color: ${({ theme }) => theme.colors.white};
                    text-decoration: none;
                }
                ._psaihm_app_oppen_params {
                    text-transform: uppercase;
                }
            }
        }
    }
`;
