import React, { FC } from 'react';
import { InputProps } from './index';
import Icon, { Icons } from '@components/Icon';
import Tooltip, { Effect } from '@components/GlobalTooltip';
import useTranslations from '@hooks/useTranslations';

const InputTemplate: FC<InputProps> = ({
    className,
    marginTop,
    marginBottom,
    label,
    id,
    placeholder,
    defaultValue,
    value,
    onChange,
    hasError,
    tooltip,
    type,
    withCurrency,
}) => {
    const { t } = useTranslations();

    return (
        <div className={className}>
            {label && (
                <span>
                    {label}
                    {tooltip && (
                        <div className="tooltip">
                            <button
                                className="inputToolTip"
                                data-for="inputToolTip"
                                data-html="true"
                                data-tip={`<div style="text-align: left">${tooltip}</div>`}
                            >
                                <Icon className="infoIcon" name={Icons.InfoCircle} width={14} height={14} />
                            </button>
                        </div>
                    )}
                </span>
            )}
            <Tooltip
                id="inputToolTip"
                effect={Effect.solid}
                scrollHide={false}
                event="click"
                eventOff="mouseout blur"
                delayHide={300}
            />
            {withCurrency && <span className="currency">{t('price.currency.sign')}</span>}
            <input
                className={`${hasError ? 'error' : ''} ${withCurrency ? 'withCurrency' : ''}`}
                defaultValue={defaultValue}
                data-symbol="test"
                value={value}
                id={id}
                type={type}
                onChange={(e) => onChange?.(e.target.value)}
                placeholder={placeholder}
            />
        </div>
    );
};

export default InputTemplate;
