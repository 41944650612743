import React from 'react';
import { BRAND } from '../../constants/main';
import { LoaderOverlayStyledAC } from './AC/LoaderOverlayStyled.AC';
import { LoaderOverlayStyledAP } from './AP/LoaderOverlayStyled.AP';
import { LoaderOverlayStyledDS } from './DS/LoaderOverlayStyled.DS';
import { LoaderOverlayStyledOV } from './OV/LoaderOverlayStyled.OV';
import { LoaderOverlayStyled } from './LoaderOverlayStyled';
import { SC } from '../../styles/theme';

export interface LoaderOverlayProps extends SC {
    size?: number;
    loading?: boolean;
    selectionMode?: boolean;
}

const LoaderOverlay = (props: LoaderOverlayProps) => {
    if (BRAND === 'OV') return <LoaderOverlayStyledOV {...props} />;
    if (BRAND === 'AC') return <LoaderOverlayStyledAC {...props} />;
    if (BRAND === 'AP') return <LoaderOverlayStyledAP {...props} />;
    if (BRAND === 'DS') return <LoaderOverlayStyledDS {...props} />;
    return <LoaderOverlayStyled {...props} />;
};

export default LoaderOverlay;
