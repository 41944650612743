import React, { FC, useEffect } from 'react';
import { CustomErrorProps } from '.';
import { useGTM } from '@hooks/useGTM';
import { useJourneyType } from '@hooks/useJourneyType';

export const CustomErrorTemplate: FC<CustomErrorProps> = ({
    className,
    titleDescription,
    errorType,
    errorTitle,
    returnLink,
    returnLinkLabel,
}) => {
    const { paymentJourneyType } = useJourneyType();
    const { pushErrorPageLoad } = useGTM();
    useEffect(() => {
        pushErrorPageLoad(Number(errorType), paymentJourneyType);
    }, []);

    const title = errorTitle ? errorTitle : `Error ${errorType}`;
    return (
        <div className={`errorPage ${className}`}>
            <div className="errorPage__content-wrapper">
                <div className="errorPage__content">
                    <h1 className="errorPage__title">{title}</h1>
                    {titleDescription && <p className="errorPage__description">{titleDescription}</p>}
                    {returnLink && (
                        <a className="errorPage__link" href={returnLink}>
                            {returnLinkLabel}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
