import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { HeaderStyled } from '../HeaderStyled';
import { rem } from 'polished';

export const HeaderStyledAC = styled(HeaderStyled)`
    .header {
        background: ${({ theme }) => theme.colors.white};

        .logo {
            svg {
                width: ${rem(70)};
                height: ${rem(80)};
            }

            @media (min-width: ${breakpoints.md}px) {
                svg {
                    width: ${rem(120)};
                    height: ${rem(120)};
                }
            }
        }

        .desktopRightPart {
            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                .label {
                    color: ${({ theme }) => theme.colors.grey5};

                    .phone {
                        color: ${({ theme }) => theme.colors.blue2};
                    }
                }
            }
        }

        .mobileMenu {
            background: ${({ theme }) => theme.colors.white};

            .link-wrapper,
            .help,
            .account,
            .basket,
            .pro {
                .label {
                    color: ${({ theme }) => theme.colors.grey5};

                    .phone {
                        color: ${({ theme }) => theme.colors.blue2};
                        text-align: left;
                    }
                }
            }

            .close {
                color: ${({ theme }) => theme.colors.grey5};
            }
        }

        .mobileMenuIcon {
            svg {
                g {
                    path {
                        fill: ${({ theme }) => theme.colors.grey5} !important;
                    }
                }
            }
        }
    }

    .b2b-switch input:checked + button {
        label {
            color: ${({ theme }) => theme.colors.blue2};

            &:hover {
                color: ${({ theme }) => theme.colors.grey5};
            }
        }
    }

    .b2b-switch .toggle-inside {
        border-color: ${({ theme }) => theme.colors.blue2};
        background: ${({ theme }) => theme.colors.blue2};
    }

    .switch-vertical label {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey1};
    }
`;
