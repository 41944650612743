import React from 'react';
import { SC } from '../../styles/theme';
import { PriceSuffixStyled } from './PriceSuffixStyled';

export interface PriceSuffixProps extends SC {
    isMonthlyPrice?: boolean;
    hideAsterisk?: boolean;
    checkPrioritizedTranslation?: boolean;
    showOnlyPrioritizedTranslation?: boolean;
    showPriceExcludedVat?: boolean;
}

const PriceSuffix = (props: PriceSuffixProps) => <PriceSuffixStyled {...props} />;

export default PriceSuffix;
