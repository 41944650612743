import styled from 'styled-components';
import { rem } from 'polished';
import ModalFindDealerTemplate from './ModalFindDealerTemplate';
export const ModalFindDealerStyled = styled(ModalFindDealerTemplate)`
    display: flex;
    flex-direction: column;

    .modalOpeningHours {
        display: flex;
        flex-direction: column;
        margin-top: ${rem('16px')};
        line-height: 1.38;

        .openingHoursTitle {
            font-weight: bold;
            margin-bottom: ${rem('8px')};
        }

        .hours {
            line-height: ${rem(25)};

            .hour {
                display: grid;
                grid-template-columns: 1fr 2fr;
            }
        }
    }

    .modalContent {
        display: flex;
        flex-direction: column;
        margin-top: ${rem('24px')};

        .modalAddr {
            display: flex;
            flex-direction: column;
            font-size: ${rem('16px')};
            margin-bottom: ${rem('16px')};
            text-transform: capitalize;

            .name {
                font-weight: bold;
            }
        }

        .map {
            height: ${rem('240px')};
        }
    }

    .geolocation-input--textfield-container {
        .dropDown {
            position: absolute !important;
            top: ${rem('48px')} !important;
        }
    }

    input {
        border-radius: ${rem('12px')};
    }

    .modalTxt {
        font-size: ${rem('16px')};
        line-height: 1.38;
        margin-top: ${rem('16px')};
    }

    .icon-wrapper {
        svg {
            path {
                fill: black;
            }
        }
    }

    .modalInputs {
        margin-top: ${rem('32px')};

        .geolocation-title {
            display: none;
        }
    }

    .modalDealerSelect {
        padding-right: unset;
        margin-top: ${rem('16px')};

        .Select__control {
            border: 1px solid ${({ theme }) => theme.colors.black};
            border-radius: ${rem('12px')};
            min-height: ${rem('48px')};

            .Select__single-value {
                padding-left: ${rem('10px')};
            }
        }
    }

    .headerWrapper {
        width: calc(100% + ${rem('60px')});
        background: white;
        margin-left: ${rem('-30px')};
        display: flex;
        justify-content: center;
        position: sticky;
        top: 0;
        z-index: 999999;
        padding-bottom: ${rem('10px')};

        &.scrolled {
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
        }

        .modalHeader {
            width: calc(100% - 60px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: ${rem('32px')};

            .modalTitle {
                font-weight: bold;
                font-size: ${rem('30px')};
                color: #3b3938;
            }

            .icon-wrapper {
                cursor: pointer;

                svg {
                    transform: rotate(45deg);
                }
            }
        }
    }
`;
