import styled from 'styled-components';
import { TextAreaStyled } from '../../TextAreaStyled';
import { rem } from 'polished';

export const TextAreaStyledOV = styled(TextAreaStyled)`
    span {
        font-size: ${rem(16)};
    }

    textarea {
        border: 1px solid ${({ theme }) => theme.colors.grey16};
        font-size: ${rem(16)};
    }
`;
