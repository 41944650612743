import styled from 'styled-components';
import { ModalShareConfigurationDealerCodeStyled } from '../ModalShareConfigurationDealerCodeStyled';
import { rem } from 'polished';

export const ModalShareConfigurationDealerCodeStyledAP = styled(ModalShareConfigurationDealerCodeStyled)`
    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.blue2};

            .selected {
                width: ${rem(12)};
                height: ${rem(12)};
            }
        }
    }
`;
