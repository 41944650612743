import styled from 'styled-components';
import { css } from '../../../../styles/theme';
import { ButtonStyled } from '../../ButtonStyled';
import { rem } from 'polished';

export const ButtonStyledOV = styled(ButtonStyled)`
    padding: 0 ${rem(24)};
    height: ${rem(48)};
    font-size: ${({ theme }) => theme.fontSizes.h5};
    text-transform: uppercase;

    ${({ primary }) =>
        primary &&
        css`
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.white};
                }
            }

            &:hover,
            &:focus-visible {
                background: ${({ theme }) => theme.colors.secondary};
            }
        `}

    ${({ secondary, withoutBackground }) =>
        secondary &&
        css`
            background: ${({ theme }) => (withoutBackground ? 'transparent' : theme.colors.white)};
            color: ${({ theme }) => theme.colors.primary};
            border: 2px solid ${({ theme }) => theme.colors.primary};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primary};
                }
            }

            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.black2};
                border-color: ${({ theme }) => theme.colors.black2};
                background: ${({ theme }) => theme.colors.white};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.black2};
                    }
                }
            }
        `}

  ${({ primary, disabled }) =>
        primary &&
        disabled &&
        css`
            background: ${({ theme }) => theme.colors.grey11};
        `}

  ${({ secondary, disabled }) =>
        secondary &&
        disabled &&
        css`
            opacity: 0.4;
        `}
`;
