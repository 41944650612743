import React, { FC, memo } from 'react';
import { HandledComponentError } from '@components/ErrorHandling';
import { useTranslations } from '@hooks/useTranslations';

export const SuccessMessage: FC = memo(() => {
    const { t } = useTranslations();
    try {
        return (
            <div className="formRow">
                <span className="success">{t('shareConfiguration.success')}</span>
            </div>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});

SuccessMessage.displayName = 'SuccessMessage';
