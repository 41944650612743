import styled from 'styled-components';
import { LinkStyled } from '../LinkStyled';
import { css } from '../../../styles/theme';
import { rem } from 'polished';

export const LinkStyledOV = styled(LinkStyled)`
    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.black};
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }

            &:hover,
            &:focus-visible {
                text-decoration: underline;
            }
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            color: ${({ theme }) => theme.colors.black};
            text-decoration: underline;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }
        `}

    svg {
        width: ${rem(9)};
        height: ${rem(9)};
    }
`;
