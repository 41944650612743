import { css } from 'styled-components';
import { rem } from 'polished';

export const APStyles = css`
    background: ${({ theme }) => theme.colors.white};
    padding-bottom: ${rem(20)};
    height: ${rem(150)};

    h2 {
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        margin-bottom: 0;
    }

    > div.needHelpPhone {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        text-transform: uppercase;
        padding-bottom: ${rem(10)};

        svg {
            height: ${rem(35)};
        }
    }

    > div.needHelpInfo {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }
`;
