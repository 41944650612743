import styled from 'styled-components';
import { CheckoutMyDetailsStyled } from '../CheckoutMyDetailsStyled';
import { rem } from 'polished';

export const CheckoutMyDetailsStyledDS = styled(CheckoutMyDetailsStyled)`
    .wrapper {
        &-title {
            font-size: ${rem('28px')};
            margin-left: 0;
        }
    }
`;
