import styled from 'styled-components';
import { RedirectionModalStyled } from '../RedirectionModalStyled';

export const RedirectionModalStyledOV = styled(RedirectionModalStyled)`
    .message {
        &Container {
            background-color: rgba(0, 0, 0, 0.7);

            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                background-color: rgba(0, 0, 0, 0.7);
                -webkit-backdrop-filter: none;
                backdrop-filter: none;
            }
        }
        &Content {
            padding: 30px;
            text-align: center;

            p {
                font-size: 1.4rem;
                text-align: center;
                margin: 0;
                padding: 1rem 2rem 2rem;
            }
        }

        &Button {
            text-align: center;
            button {
                width: 100%;
                text-transform: unset;
            }
        }
    }
`;
