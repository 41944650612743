import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import LanguageSwitcherTemplate from './LanguageSwitcherTemplate';
import { rem } from 'polished';

export const LanguageSwitcherStyled = styled(LanguageSwitcherTemplate)`
    z-index: 1; /* to be above .skip-content button */
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    text-transform: uppercase;

    ul {
        display: flex;
        margin: 0;
        justify-content: flex-end;

        @media (min-width: ${breakpoints.md}px) {
            margin: 0 ${rem(20)};
        }

        li {
            list-style: none;
            padding: ${rem(9)};
            cursor: pointer;
            width: ${rem(47)};
            text-align: center;
        }

        .default {
            background: ${({ theme }) => theme.colors.white};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            span {
                border-bottom: 1px solid ${({ theme }) => theme.colors.black};
            }
        }
    }
`;
