import styled from 'styled-components';
import { LanguageSwitcherStyled } from '../LanguageSwitcherStyled';

export const LanguageSwitcherStyledDS = styled(LanguageSwitcherStyled)`
    background-color: ${({ theme }) => theme.colors.grey4};

    ul {
        li {
            color: ${({ theme }) => theme.colors.black};
        }
`;
