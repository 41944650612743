import { rem } from 'polished';
import {isBrandAC, isBrandDS} from 'src/constants/main';
import React from 'react';
import styled, { css } from 'styled-components';
import useTranslations from '@hooks/useTranslations';
import Button from '../Button';

interface IModalDeleteDeal {
    onSubmit: () => void;
    onClose: () => void;
}

export const ModalDeleteDeal: React.FC<IModalDeleteDeal> = ({ onSubmit, onClose }) => {
    const { t } = useTranslations();

    return (
        <Wrapper>
            <Button onClick={onSubmit} primary data-testid={`TESTING_DELETE_CONFIRMATION`}>
                {t('dialog.deleteDeal.submit')}
            </Button>
            <Button onClick={onClose} secondary>{t('dialog.deleteDeal.cancel')}</Button>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex: auto;
    margin-top: ${rem('35px')};
    justify-content: space-between;
`;
