import React, { FC, useState, useEffect, useCallback } from 'react';
import { ModalFindDealerProps } from '.';
import Icon, { Icons } from '@components/Icon';
import LocationInput from '@components/FilterBox/Components/LocationInput';
import { dealerApi } from 'src/services';
import { theme } from 'src/styles/theme';
import Select from '@components/Select';
import useTranslations from '@hooks/useTranslations';
import Spinner from '@components/Spinner';
import Maps from '@components/CheckoutDelivery/components/CheckoutMap';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { getDealerInformation } from '@utils/getStockDealerInformations';
import { useSelector } from 'react-redux';
import DealerDuck from 'src/redux/dealer/dealer.duck';
import { useUserDuck } from '@hooks/useUserDuck';
import { EUserLoginType } from 'src/interfaces/User';

function convertMetersToKilometers(meters: number): number {
    const kilometers = meters / 1000;
    return Math.round(kilometers * 10) / 10;
}

type SelectedProp = {
    latitude: number;
    longitude: number;
    contactNumber: string;
    name: string;
    streetName: string;
    postCode: string;
    city: string;
    openingHours: string;
};

function addCommaIfRequired(input: string): string {
    if (input.startsWith('<span>')) {
        const afterSpan = input?.split('</span>')?.[1]?.trim();

        const regex = /\d{2}:\d{2}-\d{2}:\d{2}/g;

        const matches = afterSpan?.match(regex);

        if (matches && matches?.length > 1) {
            return input.replace(' ', ', ');
        }
    }
    return input;
}

const ModalFindDealerTemplate: FC<ModalFindDealerProps> = ({ className, onClose, resetLocationInput }) => {
    const { carDetails } = useCarDetailsDuck();
    const { t } = useTranslations();
    const [isLoading, setIsLoading] = useState(false);
    const { isStockJourney } = useCarStockJourneyCheck();
    const [dealers, setDealers] = useState([]);
    const { userAuth } = useUserDuck();
    const dealerFromState = useSelector((state) => DealerDuck.getOwnState(state).dealer);
    const dealerInformation = getDealerInformation(carDetails, dealerFromState);
    const [dealer, setDealer] = useState({ value: '', label: t('modalFindDealerSelectDealer') });
    const [selected, setSelected] = useState<SelectedProp>({
        latitude: dealerInformation?.coordinates?.lat || null,
        longitude: dealerInformation?.coordinates?.lon || null,
        contactNumber: dealerInformation?.phoneNumber || '',
        name: dealerInformation?.dealerName || '',
        streetName: dealerInformation?.street || '',
        postCode: dealerInformation?.zipCode || '',
        city: dealerInformation?.city || '',
        openingHours: dealerInformation?.openingHours || '',
    });
    const [isScrolled, setIsScrolled] = useState(false);
    const { latitude, longitude, contactNumber, name, streetName, postCode, city, openingHours } = selected;

    useEffect(() => {
        const handleScroll = () => {
            const el = document.querySelector('.modalSidebar__wrap');
            if (el) {
                const isContentScrolled = el.scrollTop > 0;
                setIsScrolled(isContentScrolled);
            }
        };

        const el = document.querySelector('.modalSidebar__wrap');
        el?.addEventListener('scroll', handleScroll);

        return () => {
            el?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (resetLocationInput && !isStockJourney) {
            setSelected({
                latitude: null,
                longitude: null,
                contactNumber: '',
                name: '',
                streetName: '',
                postCode: '',
                city: '',
                openingHours: '',
            });
            setDealers([]);
            setDealer({ value: '', label: t('modalFindDealerSelectDealer') });
        }
    }, [resetLocationInput]);

    const handleSelect = (e: { value: string; label: string }) => {
        setDealer(e);
        setSelected(dealers.find(({ postCode }) => postCode === e.value));
    };

    const openingHoursByDay = openingHours?.split('<br />');

    return (
        <div className={className}>
            <div className={`${isScrolled ? 'scrolled' : ''} headerWrapper`}>
                <div className="modalHeader">
                    <span className="modalTitle">{t('modalFindDealerTitle')}</span>
                    <Icon name={Icons.PlusIcon} height={20} width={20} onClick={onClose} />
                </div>
            </div>
            <span className="modalTxt">{t('modalFindDealerDescription')}</span>
            {!isStockJourney && (
                <div className="modalInputs">
                    <LocationInput
                        reset={resetLocationInput}
                        onClear={() => {
                            setSelected({
                                latitude: null,
                                longitude: null,
                                contactNumber: '',
                                name: '',
                                streetName: '',
                                postCode: '',
                                city: '',
                                openingHours: '',
                            });
                            setDealers([]);
                            setDealer({ value: '', label: t('modalFindDealerSelectDealer') });
                        }}
                        showOnlyInput
                        callBack={async (data) => {
                            if (Boolean(Object.values(data.geoLocation).length)) {
                                const { lat, lng } = data.geoLocation;

                                if (lat && lng) {
                                    setIsLoading(true);
                                    const response = await dealerApi.getAllDealers(
                                        String(lat),
                                        String(lng),
                                        userAuth.loginType === EUserLoginType.DEALER ? userAuth?.detail?.dealerCode : ''
                                    );

                                    if (response.status !== 200) {
                                        throw { message: 'Dealers request error' };
                                    }

                                    setDealers(response.data.items);
                                    setIsLoading(false);
                                }
                            }
                        }}
                        isModal
                    />
                    {isLoading ? (
                        <Spinner size={25} border={2} color={theme.colors.grey10} />
                    ) : (
                        <Select
                            className="modalDealerSelect"
                            value={dealer}
                            onChange={handleSelect}
                            options={[
                                ...dealers.map(
                                    ({
                                        postCode,
                                        name,
                                        city,
                                        distance,
                                    }: {
                                        postCode: string;
                                        name: string;
                                        city: string;
                                        distance: number;
                                    }) => ({
                                        value: postCode,
                                        label: `${city} (${convertMetersToKilometers(distance)} km) - ${name}`,
                                    })
                                ),
                                { value: '', label: 'Select dealer' },
                            ]}
                            arrowColor={theme.colors.black}
                            isSearchable={false}
                            isDisabled={!dealers.length}
                        />
                    )}
                </div>
            )}
            <div className="modalContent">
                {contactNumber && name && streetName && postCode && city && (
                    <div className="modalAddr">
                        <span className="name">{name.toLowerCase()}</span>
                        <span className="streetName">{streetName.toLowerCase()}</span>
                        <span className="zipCode">
                            {postCode} {city.toLowerCase()}
                        </span>
                        <span className="contactNumber">{contactNumber}</span>
                    </div>
                )}
                {latitude && longitude && <Maps latitude={latitude} longitude={longitude} zoom={15} />}
            </div>
            {openingHours && (
                <div className="modalOpeningHours">
                    <span className="openingHoursTitle">{t('modalFindDealerOpenningHours')}</span>
                    <div className="hours">
                        {openingHoursByDay.map((hour: string, index: number) => {
                            const formatted = `<span>${hour.trimStart().replace(':', ':</span>')}`;

                            return (
                                <div
                                    key={index}
                                    className="hour"
                                    dangerouslySetInnerHTML={{ __html: addCommaIfRequired(formatted) }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
export default ModalFindDealerTemplate;
