import styled from 'styled-components';
import { CheckoutCarMainInfoStyledOV } from '../CheckoutCarMainInfoStyled.OV';

export const CheckoutCarMainInfoStyledGBOV = styled(CheckoutCarMainInfoStyledOV)`
    .availableInWrapperMobile {
        display: flex;
        justify-content: flex-start;
        
        .availableIn {
            text-align: left;
        }
    }
    
    .availableInLabel,
    .availableInDate {
        color: ${({ theme }) => theme.colors.black};
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }
`;
