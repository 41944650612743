import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { ModalStyledAC } from './AC/ModalStyled.AC';
import { ModalStyledAP } from './AP/ModalStyled.AP';
import { ModalStyledDS } from './DS/ModalStyled.DS';
import { ModalStyledOV } from './OV/ModalStyled.OV';
import { ModalStyledOV as ModalStyledOV_GB } from './OV/GB/ModalStyled.OV';
import { ModalStyled } from './ModalStyled';

const Modal = () => {
    if (BRAND === 'AC') return <ModalStyledAC />;
    if (BRAND === 'OV') return isMarketGB ? <ModalStyledOV_GB /> : <ModalStyledOV />;
    if (BRAND === 'AP') return <ModalStyledAP />;
    if (BRAND === 'DS') return <ModalStyledDS />;
    return <ModalStyled />;
};

export default Modal;
