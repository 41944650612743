import React, { FC } from 'react';
import { SpinnerProps } from '.';

const SpinnerTemplate: FC<SpinnerProps> = ({ className }) => (
    <div className={className}>
        <div className="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default SpinnerTemplate;
