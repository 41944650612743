import styled from 'styled-components';
import { ModalShareOfferSummaryStyled } from '../ModalShareOfferSummaryStyled';
import { rem } from 'polished';

export const ModalShareOfferSummaryStyledAC = styled(ModalShareOfferSummaryStyled)`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.h3};
        color: ${({ theme }) => theme.colors.grey5};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        padding-bottom: ${rem(15)};
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
    }

    .wrapper {
        .linkText {
            color: ${({ theme }) => theme.colors.grey5};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            margin-top: ${rem(10)};
        }

        & .form {
            & .formRow {
                .emailInputComponent {
                    background: ${({ theme }) => theme.colors.white};
                }

                .linkButton {
                    &:hover {
                        .linkText {
                            color: ${({ theme }) => theme.colors.red};
                        }
                    }
                }

                input {
                    font-size: ${({ theme }) => theme.fontSizes.h4};
                    border-color: ${({ theme }) => theme.colors.grey3};
                    color: ${({ theme }) => theme.colors.grey1};
                    border-radius: ${rem(12)};
                    padding: ${rem(10)};
                }
            }
        }
    }
`;
