import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalIncompletePxStyledAC } from './AC/ModalIncompletePxStyled.AC';
import { ModalIncompletePxStyledAP } from './AP/ModalIncompletePxStyled.AP';
import { ModalIncompletePxStyledDS } from './DS/ModalIncompletePxStyled.DS';
import { ModalIncompletePxStyledOV } from './OV/ModalIncompletePxStyled.OV';
import { ModalIncompletePxStyledOV as ModalIncompletePxStyledOVGB } from './OV/GB/ModalIncompletePxStyled.OV';

export interface IModalModalIncompletePx extends SC {
    onContinueClick: any;
    description: string;
    backButton: string;
    continueButton: string;
    withoutDelete?: boolean;
}

export const ModalIncompletePx = (props: IModalModalIncompletePx) => {
    if (BRAND === 'AP') return <ModalIncompletePxStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalIncompletePxStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalIncompletePxStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalIncompletePxStyledOVGB {...props} /> : <ModalIncompletePxStyledOV {...props} />;
};
