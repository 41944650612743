import styled from 'styled-components';
import { TextAreaStyled } from '../TextAreaStyled';
import { rem } from 'polished';

export const TextAreaStyledOV = styled(TextAreaStyled)`
    span {
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
    }

    textarea {
        border: 1px solid ${({ theme }) => theme.colors.grey18};
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        border-radius: 0;
    }
`;
