import { FC } from 'react';
import { Icons } from '../Icon';
import React from 'react';
import Icon from '../Icon';
import Tooltip, { Effect } from '../GlobalTooltip';
import { MarketingStickerProps } from './index';
import { IPromo } from '../../interfaces/IOfferItem';

export const MarketingStickerTemplate: FC<MarketingStickerProps> = ({ className, data, fullWidth = false }) => {
    const promo = (data as unknown) as IPromo;

    return (
        <div data-testid="marketing-sticker" id="marketing-sticker" style={{ visibility: !promo?.title ? 'hidden' : 'visible' }} className={className}>
            <span className="infoText">{promo?.title}</span>
            {promo?.desc && (
                <div className="infoIcon">
                    <div className="vertical" data-tip={promo.desc} data-for="priceToolTip">
                        <Icon name={Icons.InfoCircle} height={12} width={12} />
                    </div>
                    <Tooltip
                        id="priceToolTip"
                        effect={Effect.solid}
                        scrollHide={false}
                        event="click"
                        eventOff="mouseout"
                        delayHide={300}
                    />
                </div>
            )}
        </div>
    );
};
