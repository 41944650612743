import React, { useEffect } from 'react';
import TextArea from '../../../../ui/TextArea';
import { SC } from '../../../../styles/theme';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import useTranslations from '@hooks/useTranslations';

interface IComment extends SC {
    comment: string;
    setComment: any;
}

const MAX_CHARACTERS_LENGTH = 200;

export const CommentTemplate = ({ comment, setComment }: IComment) => {
    const { t } = useTranslations();

    const { currentDeal } = useCarDetailsDuck();

    useEffect(() => {
        if (currentDeal?.extraFields?.comments) {
            setComment(JSON.parse(currentDeal?.extraFields?.comments)?.[0]?.text);
        }
    }, [currentDeal]);
    return (
        <div>
            <div className="row without-flex">
                <TextArea
                    defaultValue={comment}
                    onChange={setComment}
                    maxLength={MAX_CHARACTERS_LENGTH}
                    label={t('modal.personalizeOffer.textArea.label')}
                    placeholder={t('modal.personalizeOffer.textArea.placeholder')}
                    rows={4}
                />
                <div className="charactersCount">
                    {MAX_CHARACTERS_LENGTH - (comment?.length || 0)} {t('modal.personalizeOffer.textArea.maxLength')}
                </div>
            </div>
        </div>
    );
};
