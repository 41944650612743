import styled from 'styled-components';
import { SelectStyled } from '../SelectStyled';
import { rem } from 'polished';

export const SelectStyledAP = styled(SelectStyled)`
    .Select__option--is-focused {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
    }

    .Select__option:active {
        background-color: ${props => props.theme.colors.primary};
    }

    .Select__option--is-selected {
        background-color: ${props => props.theme.colors.black};
    }
`;
