import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { ModalShareConfigurationPersonalizeOfferStyled } from '../ModalShareConfigurationPersonalizeOfferStyled';
import { rem } from 'polished';

export const ModalShareConfigurationPersonalizeOfferStyledOV = styled(ModalShareConfigurationPersonalizeOfferStyled)`
    .share-wrapper {
        .submit {
            @media (min-width: ${breakpoints.md}px) {
                width: 70%;
            }
        }

        .input-wrapper {
            .dropdown,
            input {
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
            }

            .input-section-label {
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
            }
        }

        .mandatory {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            color: ${({ theme }) => theme.colors.grey22};
        }
    }
    .checkboxSection {
        &-title p {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
        }

        .container label {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
        }
    }
    .civilitySection {
        .check {
            border-color: ${({ theme }) => theme.colors.black2};
            background: ${({ theme }) => theme.colors.primary};

            .selected {
                width: ${rem(8)};
                height: ${rem(8)};
                background: ${({ theme }) => theme.colors.black2};
            }
        }
    }
`;
