import { FAQsProps, faqsTemplateId } from '.';
import React, { FC, memo, useState, useEffect } from 'react';
import { FAQCategory } from './FaqAccordion/FAQCategory';
import { useContentDuck } from '@hooks/useContentDuck';
import { useFAQCategoryFromHash } from '@hooks/useFAQCategoryFromHash';

export const FAQsTemplate: FC<FAQsProps> = memo(({ className }) => {
    const { faqs } = useContentDuck();
    const { faqCategoryFromHash } = useFAQCategoryFromHash();

    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) return null;

    return (
        <div className={className} id={faqsTemplateId}>
            <div className="container">
                {faqs.map(
                    (category, index) =>
                        category.title && (
                            <FAQCategory
                                key={category.id}
                                category={category}
                                index={index}
                                isOpen={
                                    faqCategoryFromHash !== '' &&
                                    faqCategoryFromHash ===
                                        category.title.toLowerCase().substr(0, faqCategoryFromHash.length)
                                }
                            />
                        )
                )}
            </div>
        </div>
    );
});
FAQsTemplate.displayName = 'FAQsTemplate';
export default FAQsTemplate;
