import React from 'react';
import { BRAND, isBrandOV, isMarketGB } from '../../../constants/main';
import { HeaderStyledAC } from './AC/HeaderStyled.AC';
import { HeaderStyledAP } from './AP/HeaderStyled.AP';
import { HeaderStyledDS } from './DS/HeaderStyled.DS';
import { HeaderStyledOV } from './OV/HeaderStyled.OV';
import { HeaderStyledOVGB } from './OV/GB/HeaderStyled.OV.GB';
import { HeaderStyled } from './HeaderStyled';
import { SC } from '../../../styles/theme';
import {TBudgetType} from "../../../services";
import {IHeaderItem} from "../../../redux/content/content.duck.interface";

export type HeaderProps = SC & {
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    paymentJourney?: TBudgetType;
    links?: IHeaderItem[];
};

const Header = (props: HeaderProps) => {
    if (isBrandOV) return isMarketGB ? <HeaderStyledOVGB {...props} /> : <HeaderStyledOV {...props} />;
    if (BRAND === 'AC') return <HeaderStyledAC {...props} />;
    if (BRAND === 'AP') return <HeaderStyledAP {...props} />;
    if (BRAND === 'DS') return <HeaderStyledDS {...props} />;
    return <HeaderStyled {...props} />;
};

export default Header;
