import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import ModalTemplate from './ModalTemplate';
import media from '../../styles/media';
import { rem } from 'polished';

export const FocusPlaceholder = styled.input`
    background: transparent;
    border: transparent;
    &:focus,
    &:focus-visible {
        outline: unset !important;
    }
`;

export const ModalStyled = styled(ModalTemplate)`
    .modalSidebar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        justify-content: flex-end;
        z-index: 999999999;

        @media (max-width: ${breakpoints.sm}px) {
            overflow-y: scroll;
        }

        &.center {
            @media (min-width: ${breakpoints.sm}px) {
                justify-content: center;
                align-items: center;
            }
        }

        &__wrap {
            background: ${({ theme }) => theme.colors.white};
            width: 100%;

            &.isOpen {
                transform: translateX(0);
                opacity: 1;
                transition: transform 0.5s, opacity 0.5s;
            }

            &.isClose {
                transform: translateX(100%);
                opacity: 0;
                transition: transform 1s, opacity 1s;
            }

            &.center {
                @media (min-width: ${breakpoints.sm}px) {
                    height: fit-content;
                }
            }

            &.fullWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(1565)};
                }
            }

            &.middleWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(1280)};
                }
            }

            &.featureCustomWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(640)};
                }
            }

            &.postalCodeCustomWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(640)};
                }
            }

            &.sessionChangeCustomWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(540)};
                }
            }

            &.trimCompareCustomWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(950)};
                }
            }

            &.defaultCustomWidth {
                @media (min-width: ${breakpoints.sm}px) {
                    max-width: ${rem(495)};
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                font-size: ${rem(20)};
                padding: ${rem(16)};
                position: sticky;
                background: ${({ theme }) => theme.colors.white};
                top: 0;
                left: 0;
                z-index: 9999;

                @media (min-width: ${breakpoints.sm}px) {
                    padding: ${rem(30)} ${rem(30)} ${rem(20)} ${rem(30)};
                }

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(25)};
                }

                &__title {
                    display: flex;
                    font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    padding-right: ${rem(10)};
                }

                &__backBtn {
                    cursor: pointer;
                    background: none;
                    border: none;
                    padding: 0;
                    height: ${rem(25)};
                }

                &__closeBtn {
                    background: none;
                    padding: 0;
                    border: none;
                    cursor: pointer;
                }
            }

            &__modalBody {
                &.withPadding {
                    padding: 0 ${rem(16)} ${rem(16)} ${rem(16)};

                    @media (min-width: ${breakpoints.sm}px) {
                        padding: ${rem(0)} ${rem(30)} ${rem(30)} ${rem(30)};
                    }
                }
            }

            .spinner {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    .modalWindow {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999999999;

        &.no-overlay {
            position: relative;
        }

        &__wrap {
            background-color: white;
            border-radius: 0.5rem;
            padding: 0.5rem;
            width: 85%;
            color: ${({ theme }) => theme.colors.grey2};
            overflow-y: auto;
            max-height: 95vh;
            position: relative;

            ${media.sm`
                width: 60%;
            `}

            ${media.lg`
                width: 40%;
            `}

            /* small mobile devices queries */

            @media (max-height: 700px) {
                height: 85vh;
            }

            @media (max-width: calc(${breakpoints.xs}px - 0.1px)) {
                width: 96%;
            }

            &.engines {
                height: 100vh;
                padding: 0;
                width: 100vw !important;
                max-height: 100vh !important;
                border-radius: 0;

                .modalWindow__wrap {
                    &__header {
                        > input {
                            position: absolute;
                        }

                        &__back {
                            background: unset;
                            border: none;
                            cursor: pointer;
                            position: relative;

                            svg {
                                position: absolute;
                                top: 25px;
                                left: 25px;
                            }
                        }

                        &__closeBtn {
                            color: ${({ theme }) => theme.colors.black};
                            display: grid;
                            font-size: ${rem(32)};
                            height: ${rem(24)};
                            line-height: ${rem(24)};
                            padding: 0;
                            place-items: center;
                            position: absolute;
                            left: auto;
                            right: ${rem(40)};
                            top: ${rem(40)};
                            width: ${rem(24)};

                            @media (max-width: ${breakpoints.sm}px) {
                                background: ${({ theme }) => theme.colors.black};
                                color: ${({ theme }) => theme.colors.white};
                                height: ${rem(24)};
                                font-size: ${rem(18)};
                                position: fixed;
                                right: 0;
                                top: 0;
                                width: ${rem(24)};
                                z-index: 10;
                            }
                        }
                    }
                    &__modalBody {
                        padding: 0;
                    }
                }
            }

            &.tires-detail {
                max-width: ${rem('320px')};
                border-radius: 0;

                .modalWindow__wrap__header {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &__title {
                        color: ${({ theme }) => theme.colors.black};
                        text-transform: uppercase;
                        margin-top: ${rem(10)};
                    }
                    &__closeBtn {
                        padding: 0 ${rem(10)};
                        font-size: ${rem(24)};
                        background: none;
                        border: none;
                    }
                }
            }

            &.finance-legal {
                width: 100%;
                border-radius: 0;
                bottom: 0;
                position: fixed;
                box-shadow: 0 1.25rem 1.875rem 0 ${({ theme }) => theme.colors.primaryBlack};

                .modalWindow__wrap {
                    &__header {
                        &__title {
                            text-align: center;
                            text-transform: uppercase;
                            margin-top: ${rem(10)};
                            color: ${({ theme }) => theme.colors.primaryBlack};
                            width: 100%;
                        }
                        &__closeBtn {
                            display: none;
                            background: none;
                            border: none;
                        }
                    }
                    &__modalBody {
                        font-size: ${rem(16)};
                        color: ${({ theme }) => theme.colors.primaryBlack};
                        p {
                            font-size: ${rem(16)};
                            color: ${({ theme }) => theme.colors.primaryBlack};
                            line-height: 1.1;
                            @media (max-width: calc(${breakpoints.xs}px)) {
                                font-size: ${rem(14)};
                            }
                        }
                    }
                }
                .button-container {
                    border-top: 1px solid ${({ theme }) => theme.colors.primaryBlack};
                    margin-left: ${rem(-25)};
                    margin-right: ${rem(-25)};
                    padding-top: ${rem(20)};
                    flex-direction: column;
                    display: flex;
                    align-items: flex-end;
                    button {
                        width: auto;
                        margin-right: ${rem(25)};
                        background: ${({ theme }) => theme.colors.primary};
                        color: ${({ theme }) => theme.colors.white};
                        text-transform: none;
                        padding-left: ${rem(20)};
                        padding-right: ${rem(20)};
                        &:hover {
                            background: ${({ theme }) => theme.colors.primaryBlack};
                            color: ${({ theme }) => theme.colors.white};
                        }
                    }
                }
            }

            &.finance-legal {
                width: 100%;
                border-radius: 0;
                bottom: 0;
                position: fixed;
                box-shadow: 0 1.25rem 1.875rem 0 ${({ theme }) => theme.colors.primaryBlack};

                @media (max-height: 700px) {
                    height: auto;
                }

                .modalWindow__wrap {
                    &__header {
                        &__title {
                            text-align: center;
                            text-transform: uppercase;
                            margin-top: ${rem(10)};
                            color: ${({ theme }) => theme.colors.primaryBlack};
                            width: 100%;
                        }
                        &__closeBtn {
                            display: none;
                        }
                    }
                    &__modalBody {
                        font-size: ${rem(16)};
                        color: ${({ theme }) => theme.colors.primaryBlack};
                        p {
                            font-size: ${rem(16)};
                            color: ${({ theme }) => theme.colors.primaryBlack};
                            line-height: 1.1;
                            @media (max-width: calc(${breakpoints.xs}px)) {
                                font-size: ${rem(14)};
                            }
                        }
                    }
                }
                .button-container {
                    border-top: 1px solid ${({ theme }) => theme.colors.primaryBlack};
                    margin-left: ${rem(-25)};
                    margin-right: ${rem(-25)};
                    padding-top: ${rem(20)};
                    flex-direction: column;
                    display: flex;
                    align-items: flex-end;
                    button {
                        width: auto;
                        margin-right: ${rem(25)};
                        background: ${({ theme }) => theme.colors.primary};
                        color: ${({ theme }) => theme.colors.white};
                        text-transform: none;
                        padding-left: ${rem(20)};
                        padding-right: ${rem(20)};
                        &:hover {
                            background: ${({ theme }) => theme.colors.primaryBlack};
                            color: ${({ theme }) => theme.colors.white};
                        }
                    }
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__title {
                    font-size: 24px;
                    line-height: 28px;
                    margin: 0;
                    margin-left: 1rem;
                    font-weight: bold;

                    ${media.sm`
                        font-size: 2rem;
                    `}
                }

                &__closeBtn {
                    color: ${({ theme }) => theme.colors.grey2};
                    font-size: ${rem(16)};
                    margin: 0;
                    padding: 0.1rem 0.3rem;
                    cursor: pointer;
                    background: none;
                    border: none;
                }
            }

            &__modalBody {
                padding: 0.5rem 1rem;
                display: flex;
                flex-wrap: wrap;

                .financialSummary__legalInfo {
                    padding-top: 2rem;

                    .financialSummary__legalText,
                    .financialSummary__legalTitle {
                        width: 100%;
                        text-align: left;
                        font-size: ${({ theme }) => theme.fontSizes.textSmall};
                        line-height: ${({ theme }) => theme.lineHeights.textMedium};
                    }

                    .financialSummary__legalText {
                        font-weight: ${({ theme }) => theme.fontWeights.textLight};
                        margin-top: ${rem('10px')};
                    }

                    .financialSummary__legalTitle {
                        font-weight: ${({ theme }) => theme.fontWeights.textBold};
                    }
                }

                .featureSwitches {
                    &__wrapper {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        flex: auto;
                        margin-top: ${rem('35px')};
                    }
                    &__btns-wrapper {
                        width: 100%;
                        display: flex;
                        flex: auto;
                        flex-direction: row-reverse;
                        margin-top: ${rem('35px')};
                        &__saveButton {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    .modalFinancialSummary {
        &--description {
            margin-top: 0;
        }

        &--title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${rem(14)};
            color: ${({ theme }) => theme.colors.black};
            width: 95%;
            padding: 0 0 ${rem(10)} 0;
            margin: ${rem(10)} ${rem(10)} 0 ${rem(10)};
            border-bottom: 1px solid #adaeb2;
        }

        &--block {
            border-bottom: 1px solid #adaeb2;
            padding: 10px 0;
        }
    }

    .financialSummary__legalInfo {
        padding-top: ${rem(30)};
        color: ${({ theme }) => theme.colors.grey17};
    }

    .financialSummary__legalText {
        line-height: ${rem(14)};
    }

    .financialSummary__legalText:nth-child(1) {
        padding-bottom: ${rem(10)};
        color: ${({ theme }) => theme.colors.black};
    }
`;
