import { rem } from 'polished';
import CheckboxTemplate from './CheckboxTemplate';
import styled from 'styled-components';

export const CheckboxStyled = styled(CheckboxTemplate)`
    .container {
        position: relative;
        margin-bottom: ${rem(15)};
        height: ${rem(25)};
        display: flex;
        align-items: center;

        &.withoutPointerEvents {
            pointer-events: none;
        }
    }

    .container label {
        display: block;
        padding-left: ${rem(35)};
        cursor: pointer;
    }

    .container input {
        height: ${rem(25)};
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: ${rem(25)};
    }

    .container input + label::before {
        border: 1px solid;
        content: '';
        height: ${rem(25)};
        left: 0;
        position: absolute;
        top: 0;
        width: ${rem(25)};
    }

    .container input + label::after {
        content: '';
        border: 2px solid;
        border-left: 0;
        border-top: 0;
        height: ${rem(16)};
        left: ${rem(9)};
        opacity: 0;
        position: absolute;
        top: ${rem(2)};
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
        width: ${rem(8)};
        color: ${({ theme }) => theme.colors.white};
    }

    .container input:focus-visible + label::before {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary};
    }

    .container input:checked + label::before {
        background: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
    }

    .container input:checked + label::after {
        opacity: 1;
    }
`;
